import { Switch } from '@headlessui/react'
import React from 'react';
import PropTypes from 'prop-types';

function Toogle({ enabled, setEnabled, disabled, text, blue }) {
    return (
        <Switch
            checked={enabled}
            onChange={setEnabled}
            disabled={disabled}
            className={`relative inline-flex h-6 w-11 flex-shrink-0 ${disabled ? 'cursor-default' : 'cursor-pointer'}  rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 ${blue ? 'focus:ring-primary' : 'focus:ring-button-orange'}  focus:ring-offset-2 ${enabled ? (blue ? 'bg-primary' : 'bg-button-orange') : 'bg-gray-200'}`}
        >
            <span className="sr-only">{text}</span>
            <span
                className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${enabled ? 'translate-x-5' : 'translate-x-0'}`
                }
            >
                <span
                className={`absolute inset-0 flex h-full w-full items-center justify-center transition-opacity ${enabled ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in'}`}
                    aria-hidden="true"
                >
                    <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                        <path
                            d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </span>
                <span
                className={`absolute inset-0 flex h-full w-full items-center justify-center transition-opacity ${enabled ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out'}`}
                    aria-hidden="true"
                >
                    <svg className={`h-3 w-3 ${blue ? 'text-primary': 'text-button-orange'}`} fill="currentColor" viewBox="0 0 12 12">
                        <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                    </svg>
                </span>
            </span>

        </Switch>
    )
}

Toogle.propTypes = {
    enabled: PropTypes.bool,
    setEnabled: PropTypes.func,
    disabled: PropTypes.bool,
    text: PropTypes.string, 
    blue: PropTypes.bool,
}
Toogle.defaultProps = {
    blue: false
}
export default Toogle;