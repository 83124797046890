import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import useFormatDate from '../../../../hooks/useFormatDate';
import { XMarkIcon } from '@heroicons/react/20/solid';
import Modal from '../../../../tailwindUI/Modal';
import ButtonOrange from '../../../../tailwindUI/ButtonOrange';
import EmptyState from '../../../../tailwindUI/EmptyState';
import SecondaryLoader from '../../../../hooks/SecondaryLoader';
import AdditionalService from './AdditionalService';
import { getAditionalServices, reportServicePayment } from '../../../../apiClient/operations/collectionOperations';
import DatePicker from 'react-modern-calendar-datepicker';
import useConvertDatePicker from '../../../../hooks/useConvertDatePicker';
import useGetDate from '../../../../hooks/useGetDate';
import Input from '../../../../tailwindUI/Input';
import SkeletonLoader from '../../../../tailwindUI/SkeletonLoader';
import { ROLES } from '../../../../cognito/permissions/permission-maps';
import { AlertContext } from '../../../../context/AlertContext';
import LongAlert from '../../../../tailwindUI/LongAlert';

function ContractedServices({ user, account, services, setServices }) {
    const { formatDate } = useFormatDate();
    const { getDate } = useGetDate();
    const { convertDatePicker } = useConvertDatePicker();
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingButton, setIsLoadingButton] = useState(false);
    const [showEmptyState, setShowEmptyState] = useState(false);
    const { alert, setAlert } = useContext(AlertContext);
    const [serviceList, setServiceList] = useState(services);
    const [serviceToReport, setServiceToReport] = useState(null);
    const [selectedDate, setSelectedDate] = useState(convertDatePicker(formatDate(getDate(), 'YYYY-MM-DD')));

    useEffect(() => {
        if (!serviceList || !setServices) return;

        setServices(serviceList);
    }, [serviceList])

    useEffect(() => {
        if (!account || serviceList) {
            setIsLoading(false);
            return;
        }

        setIsLoading(true);

        const getData = async () => {
            try {
                const services = await getAditionalServices(account?.id);
                setServiceList(services?.data);
                setIsLoading(false);
            } catch (e) {
                console.log(e);
                setIsLoading(false);
                setShowEmptyState(true);
            }
        }

        getData();
    }, []);

    const handleReportServicePayment = async (e) => {
        e.preventDefault();
        setIsLoadingButton(true);
        try {
            const stringDate = e.target.date?.value;
            const date = new Date(stringDate).getTime();
            const amount = (e.target.amount?.value || 0) * 100;
            const response = await reportServicePayment(serviceToReport?.id, date, amount);
            const serviceUpdated = response.data;
            setServiceList(serviceList?.map(service => {
                if (service.id == serviceUpdated?.id) {
                    return serviceUpdated;
                }
                return service;
            }))
            setIsLoadingButton(false);
            setServiceToReport(null);
        } catch (error) {
            setIsLoadingButton(false);
            setAlert({ active: true, type: 'error', message: error.message });
        }
    }

    return (
        <>
            {isLoadingButton && <SecondaryLoader />}
            <div className={`space-y-4 ${showEmptyState && 'hidden'}`}>
                <div className='flex flex-row justify-between items-center'>
                    <h2 className='text-gray-900 font-medium text-xl flex gap-2 items-center'>
                        Servicios contratados
                    </h2>
                </div>

                {!isLoading ? (
                    <>
                        {serviceList?.length > 0 ? (
                            <div className='space-y-4 mt-2'>
                                {serviceList?.map((service) => (
                                    <AdditionalService
                                        key={service.id}
                                        service={service}
                                        onReportPaymentClick={
                                            user?.roles?.some(role => [ROLES.root, ROLES.help_desk]?.includes(role)) ?
                                                (service) => setServiceToReport(service)
                                                :
                                                undefined
                                        }
                                    />
                                ))}
                            </div>
                        ) : (
                            <EmptyState title='Sin servicios' text='Este contribuyente no cuenta con servicios contratados.' />
                        )}
                    </>
                ) : (
                    <>
                        {[...Array(2).keys().map(i => (
                            <div key={i} className='w-full bg-white rounded-md border border-gray-300 overflow-hidden'>
                                <div className='flex flex-col md:flex-row justify-between gap-4 p-3 md:px-6 h-[60px]'>
                                    <div className='flex md:items-center gap-3 w-full'>
                                        <SkeletonLoader />
                                    </div>
                                    <div className='flex items-center gap-4 w-1/3'>
                                        <SkeletonLoader />
                                    </div>
                                </div>
                                <div className='border-t border-gray-300 flex flex-col md:flex-row justify-between gap-4 p-3 md:p-6 h-[90px]'>
                                    <div className='w-full'>
                                        <div className='flex justify-between items-end w-1/4'>
                                            <SkeletonLoader />
                                        </div>
                                        <div className="overflow-hidden rounded-full bg-gray-200 mt-2 h-2 animate-pulse bg-gray-300 w-full rounded-full" />
                                    </div>
                                </div>
                                <div className=''>
                                    <div
                                        className='border-tb border-indigo-300 bg-indigo-50 flex items-center justify-between gap-4 p-3 md:px-6 cursor-pointer'>
                                        <div className='text-gray-900 w-1/6'>
                                            <SkeletonLoader />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))]}
                    </>

                )}
            </div>

            <Modal show={serviceToReport != null} setShow={() => setServiceToReport(null)} className='min-w-full sm:min-w-[500px] !overflow-visible'>
                <div className="py-2 px-2 space-y-3">
                    <div className='relative'>
                        <XMarkIcon className='absolute w-7 h-7 -right-3 -top-3 cursor-pointer text-gray-500' onClick={() => setServiceToReport(null)} />
                    </div>
                    <h3 className="text-lg text-center font-medium leading-6 text-gray-900">
                        Reportar pago de servicio
                    </h3>
                    {alert?.active &&
                        <div className="relative -mx-4 mt-0 min-h-[3.5rem]">
                            <LongAlert
                                show={alert?.active}
                                onClose={() => setAlert({ active: false })}
                                type={alert.type}
                                title={alert.message} />
                        </div>
                    }
                    <p className="font-medium leading-6 text-gray-900">
                        {serviceToReport?.name || "Servicio"}
                    </p>
                    <form onSubmit={handleReportServicePayment} className='flex flex-col items-center w-full gap-4'>
                        <div className="w-full space-y-4 mt-4">
                            <div className='w-full'>
                                <Input
                                    label='Monto pagado'
                                    type='text'
                                    onChange={(e) => e.target.value = e.target.value.match(/^\d+\.?\d{0,2}/)}
                                    inputMode='numeric'
                                    name='amount'
                                    required
                                    autoComplete='off'
                                    leftIcon="$" />
                            </div>
                            <div className='w-full'>
                                <label className="block text-sm font-medium text-gray-700">
                                    Fecha de pago
                                </label>
                                <div className='w-full relative my-1'>
                                    <DatePicker
                                        id="date"
                                        inputName='date'
                                        value={selectedDate}
                                        onChange={setSelectedDate}
                                        locale='en'
                                        wrapperClassName='block z-10 responsive-calendar'
                                        inputClassName='w-full !px-4 !py-2 border-gray-300 text-gray-800 placeholder:text-gray-300 focus:ring-v2-blue-text-login focus:border-v2-blue-text-login !sm:text-sm rounded-md responsive-calendar'
                                        calendarClassName='block font-normal text-v2-input-text'
                                        calendarPopperPosition='top'
                                        colorPrimary='#2169AC'
                                        maximumDate={convertDatePicker(formatDate(getDate(), 'YYYY-MM-DD'))}
                                    />
                                </div>
                            </div>
                        </div>
                        <ButtonOrange isFullWidth={true} type='submit'>
                            Reportar pago
                        </ButtonOrange>
                    </form>
                </div>
            </Modal>
        </>
    )
}

ContractedServices.propTypes = {
    user: PropTypes.object,
    account: PropTypes.object,
    services: PropTypes.array,
    setServices: PropTypes.func
}

export default ContractedServices;