import useGetMonthName from "./useGetMonthName";

const useConstructDates = dates => {
    const { getMonthName } = useGetMonthName();
    const createMonthID = id => id.substr(5, 2);
    const createYearID = id => id.substr(0, 4);

    const createID = (yearID, monthID) => {
        let monthId = monthID < 10 ? `0${monthID}` : `${monthID}`;
        const id = (`${yearID}-${monthId}-01`);
        return id;
    }

    const constructRange = (start, end) => {
        let ans = [];
        for (let i = start; i <= end; i++) {
            ans.push(i);
        }
        return ans;
    }

    const createRanges = arr => {
        try {
            const arrDates = arr.sort((a, b) => a - b);
            let length = 1;
            let list = [];
            for (let i = 1; i <= arrDates.length; i++) {
                if (i == arrDates.length || arrDates[i].id - arrDates[i - 1].id != 1) {
                    if (length == 1) {
                        list.push({ id: parseInt(`${arrDates[i - length].id}`), monthNumber: arrDates[i - length].monthNumber, month: arrDates[i - length].month, year: arrDates[i - 1].year, active: true, tag: `${arrDates[i - length].month} ${arrDates[i - 1].year}` });

                    } else {
                        list.push({ id: parseInt(`${arrDates[i - length].id}${arrDates[i - 1].id}`), tag: `${arrDates[i - length].month} a ${arrDates[i - 1].month} ${arrDates[i - 1].year}`, year: arrDates[i - 1].year, range: constructRange(parseInt(arrDates[i - length].monthNumber), parseInt(arrDates[i - 1].monthNumber)) });
                    }
                    length = 1;
                } else {
                    length++;
                }
            }
            return list;
        } catch (e) {
            console.log(e);
        }
    }

    const createDatesInd = arr => {
        const datesArr = arr.sort((a, b) => a - b);
        let newDates = [];
        for (let i = 0; i < datesArr.length; i++) {
            if (datesArr[i].range != undefined) {
                for (const element of datesArr[i].range) {
                    newDates.push({ id: createID(datesArr[i].year, element), month: getMonthName(element), monthNumber: element, year: datesArr[i].year, active: true, tag: datesArr[i].tag })
                }
            } else {
                newDates.push({ id: createID(arr[i].year, arr[i].monthNumber), month: arr[i].month, monthNumber: arr[i].monthNumber, year: arr[i].year, active: true });
            }
        }
        return newDates;
    }

    const list = dates.map(date => {
        const str = date.toString();
        return {
            id: parseInt((str.substr(0, 4) + str.substr(5, 2))),
            monthNumber: parseFloat(createMonthID(str)),
            month: getMonthName(createMonthID(str)),
            year: parseInt(createYearID(str)),
            active: true
        }
    });
    const display = createRanges(list);
    const datesInd = createDatesInd(display);

    return datesInd
}

export default useConstructDates;