import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import Badge from "../../tailwindUI/Badge";
import SkeletonLoader from "../../tailwindUI/SkeletonLoader";
import StatusMenuButton from "../../tailwindUI/StatusMenuButton";
import ClipboardText from "../../tailwindUI/ClipboardText";

import AlertError from "../../hooks/Alert";
import { useOrder } from "../../hooks/asf/useOrder";
import { useWorkflow } from "../../hooks/asf/useWorkflow";
import { useAppointment } from "../../hooks/asf/useAppointment";
import { useConnection } from "../../hooks/asf/useConnection";

import { AuthContext } from "../../cognito/AuthProvider";
import { AlertContext } from "../../context/AlertContext";
import { getLead } from "../../apiClient/operations/leadsOperations";

import { UpdateConnectionStatusModal } from "./UpdateConnectionStatusModa";
import { UpdateAppointmentStatusModal } from "./UpdateAppointmentStatusModal";
import { UpdateOrderAsfModal } from "./UpdateOrderAsfModal";
import { SatCredentialsInfo } from "./SatCredentialsInfo";
import SecondaryButton from "../../tailwindUI/SecondaryButton";
import ButtonOrange from "../../tailwindUI/ButtonOrange";

import { ROLES } from "../../cognito/permissions/permission-maps";

const allowedRolesToReleaseASF = [ROLES.root, ROLES.help_desk];

function AsfInfo({ account }) {
  const { alert, setAlert } = useContext(AlertContext);
  const { user } = useContext(AuthContext);
  const [showAlert, setShowAlert] = useState(false);

  const { workflow, setWorkflow, isLoadingWorkflow, workflowError } = useWorkflow({
    accountId: account?.id,
  });

  const orderId = workflow?.asfOrder?.source?.id;
  const appointmentId = workflow?.asfAppointment?.source?.id;
  const { order, updateOrder, isLoadingOrder } = useOrder({ orderId });
  const {
    efirma,
    ciec,
    confirmedBy,
    statusCredentials,
    updateConnectionStatus,
    isLoadingConnection,
  } = useConnection({
    accountId: account?.id,
    initialStatus: workflow?.asfSatCredentials?.source?.status,
  });

  const { appointment, updateAppointment, isLoadingAppointment } =
    useAppointment({ appointmentId });

  const [asfPaylink, setAsfPaylink] = useState(null);
  const [currentModal, setCurrentModal] = useState(null);
  const [statusModal, setStatusModal] = useState("");

  const linkAsfIsVisible =
    user?.roles.some((role) => allowedRolesToReleaseASF.includes(role)) ||
    order?.createdBy?.email === user.email ||
    order?.status === "paid";

  const userCanReleaseAsf =
    user?.roles.some((role) => allowedRolesToReleaseASF.includes(role)) ||
    order?.createdBy?.email === user.email;

  const Empty = ({ text, placeholder = "placeholder" }) => {
    if (!text) {
      return (
        <dd className="mt-1 text-sm font-normal text-gray-500">
          {placeholder}
        </dd>
      );
    }
    return <dd className="mt-1 text-sm text-gray-900 font-normal">{text}</dd>;
  };

  useEffect(() => {
    if (account) {
      getLead(account.lead).then((data) => setAsfPaylink(data?.asf_paylink));
    }
  }, [account]);

  useEffect(() => {
    if (appointment) {
      setStatusModal(appointment.status);
    }
  }, [appointment]);

  useEffect(() => {
    if (workflowError) {
      setShowAlert(true);
      setAlert({ active: true, type: "error", message: workflowError });
    }
  }, [workflowError]);

  useEffect(() => {
    if (statusCredentials == "confirmed" && workflow?.asfSatCredentials?.status == "current") {
      setWorkflow({
        ...workflow,
        "asfSatCredentials": {
          ...workflow?.asfSatCredentials,
          "status": "completed"
        },
        "asfAppointment": {
          ...workflow?.asfAppointment,
          "status": "current"
        },
      })
    } else if (statusCredentials == "in_review" && workflow?.asfSatCredentials?.status == "completed") {
      setWorkflow({
        ...workflow,
        "asfSatCredentials": {
          ...workflow?.asfSatCredentials,
          "status": "current"
        },
        "asfAppointment": {
          ...workflow?.asfAppointment,
          "status": "pending"
        },
      })
    }
  }, [statusCredentials]);

  return (
    <>
      {showAlert && (
        <div className="xs:w-[102%] md:w-[94%] md:flex justify-center flex-col items-center">
          <div className="w-full flex justify-center">
            <AlertError type={alert.type} message={alert.message} />
          </div>
        </div>
      )}
      <div className="space-y-8">
        {isLoadingOrder || isLoadingWorkflow ? (
          <section className="flex flex-col gap-y-8 pb-8 bg-white border border-gray-100 shadow-md rounded-lg">
            <header className="flex items-center h-[64px] px-6 border-b-2 border-gray-100 font-medium text-lg text-gray-900">
              <h3 className="flex-1">Link ASF</h3>
            </header>
            <main className="px-6">
              <dl className="grid grid-cols-1 gap-y-8">
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Vendedor
                  </dt>
                  <SkeletonLoader width="w-64" />
                </div>
                <div>
                  <dt className="text-sm font-medium text-gray-500">Link</dt>
                  <dd className="mt-2">
                    <SkeletonLoader width="w-full" />
                  </dd>
                </div>
              </dl>
            </main>
          </section>
        ) : (
          <section className="flex flex-col gap-y-8 pb-8 bg-white border border-gray-100 shadow-md rounded-lg">
            <header className="flex items-center h-[64px] px-6 border-b-2 border-gray-100 font-medium text-lg text-gray-900">
              <h3 className="flex-1">Link ASF</h3>
            </header>
            <main className="px-6">
              <dl className="grid grid-cols-1 gap-y-8">
                {order?.createdBy && (
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Vendedor
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 font-normal">
                      {order.createdBy.name}
                    </dd>
                  </div>
                )}
                <div>
                  <dt className="text-sm font-medium text-gray-500">Link</dt>
                  <dd className="mt-2">
                    <ClipboardText
                      isDisabled={!linkAsfIsVisible || !asfPaylink}
                      text={asfPaylink}
                    />
                  </dd>
                </div>
                <div className="flex justify-end">
                  {order?.createdBy && userCanReleaseAsf && (
                    <SecondaryButton
                      onClick={() => {
                        setCurrentModal("orderASF");
                        setStatusModal("release");
                      }}
                      disabled={order?.status === "paid"}
                    >
                      Liberar ASF
                    </SecondaryButton>
                  )}
                  {!order?.createdBy && (
                    <ButtonOrange
                      onClick={() => {
                        setCurrentModal("orderASF");
                        setStatusModal("assign");
                      }}
                      disabled={order?.status === "paid" || !order}
                    >
                      Asignarme ASF
                    </ButtonOrange>
                  )}
                </div>
              </dl>
            </main>
          </section>
        )}
        {isLoadingOrder || isLoadingWorkflow ? (
          <section className="flex flex-col gap-y-8 pb-8 bg-white border border-gray-100 shadow-md rounded-lg">
            <header className="flex items-center h-[64px] px-6 border-b-2 border-gray-100 font-medium text-lg text-gray-900">
              <span className="flex items-center justify-center pr-6 mr-6 border-r-2 border-gray-100 h-full">
                1
              </span>
              <h3 className="flex-1">Orden</h3>
              <SkeletonLoader width="w-24" />
            </header>
            <main className="px-6">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Nombre</dt>
                  <SkeletonLoader width="w-64" />
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Monto</dt>
                  <SkeletonLoader width="w-64" />
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Fecha de pago
                  </dt>
                  <SkeletonLoader width="w-64" />
                </div>
              </dl>
            </main>
          </section>
        ) : (
          <section className="flex flex-col gap-y-8 pb-8 bg-white border border-gray-100 shadow-md rounded-lg">
            <header className="flex items-center h-[64px] px-6 border-b-2 border-gray-100 font-medium text-lg text-gray-900">
              <span className="flex items-center justify-center pr-6 mr-6 border-r-2 border-gray-100 h-full">
                1
              </span>
              <h3 className="flex-1">Orden</h3>
              <Badge text={workflow?.asfOrder?.status} />
            </header>
            <main className="px-6">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Nombre</dt>
                  <dd className="mt-1 text-sm text-gray-900 font-normal">
                    {workflow?.asfAccount?.name}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Monto</dt>
                  <Empty text={order?.amount} placeholder="Monto pendiente" />
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Fecha de pago
                  </dt>
                  <Empty text={order?.date} placeholder="Fecha pendiente" />
                </div>
              </dl>
            </main>
          </section>
        )}

        {isLoadingConnection || isLoadingWorkflow ? (
          <section className="flex flex-col gap-y-8 pb-8 bg-white border border-gray-100 shadow-md rounded-lg">
            <header className="flex items-center h-[64px] px-6 border-b-2 border-gray-100 font-medium text-lg text-gray-900">
              <span className="flex items-center justify-center pr-6 mr-6 border-r-2 border-gray-100 h-full">
                2
              </span>
              <h3 className="flex-1">Credenciales</h3>
              <SkeletonLoader width="w-24" />
            </header>
            <main className="px-6">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Tipo de credenciales
                  </dt>
                  <SkeletonLoader width="w-64" />
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">RFC</dt>
                  <SkeletonLoader width="w-64" />
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Fecha de subida
                  </dt>
                  <SkeletonLoader width="w-64" />
                </div>
              </dl>
            </main>
          </section>
        ) : (
          <section className="flex flex-col gap-y-8 pb-8 bg-white border border-gray-100 shadow-md rounded-lg">
            <header className="flex items-center h-[64px] px-6 border-b-2 border-gray-100 font-medium text-lg text-gray-900">
              <span className="flex items-center justify-center pr-6 mr-6 border-r-2 border-gray-100 h-full">
                2
              </span>
              <h3 className="flex-1">Credenciales</h3>
              <Badge text={workflow?.asfSatCredentials?.status} />
            </header>

            <SatCredentialsInfo
              data={{ efirma, ciec, confirmedBy, statusCredentials }}
              setAlert={setAlert}
            />

            {(efirma || ciec) && (
              <footer className="self-end px-6 space-x-4">
                <StatusMenuButton
                  status={statusCredentials}
                  disabled={false}
                  updateStatus={(status) => {
                    if (status === statusCredentials) return;
                    setCurrentModal("credentials");
                    setStatusModal(status);
                  }}
                  customStatus={["confirmed", "in_review", "invalid"]}
                  itemsPosition="top"
                />
              </footer>
            )}
          </section>
        )}

        {isLoadingAppointment || isLoadingWorkflow ? (
          <section className="flex flex-col gap-y-8 pb-8 bg-white border border-gray-100 shadow-md rounded-lg">
            <header className="flex items-center h-[64px] px-6 border-b-2 border-gray-100 font-medium text-lg text-gray-900">
              <span className="flex items-center justify-center pr-6 mr-6 border-r-2 border-gray-100 h-full">
                3
              </span>
              <h3 className="flex-1">Cita ASF</h3>
              <SkeletonLoader width="w-24" />
            </header>
            <main className="px-6">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Fecha de la sesión
                  </dt>
                  <SkeletonLoader width="w-64" />
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Hora de la sesión
                  </dt>
                  <SkeletonLoader width="w-64" />
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Ubicación
                  </dt>
                  <dd className="mt-1 space-y-2">
                    <SkeletonLoader width="w-64" />
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Última actualización
                  </dt>
                  <dd className="mt-1 space-y-1 text-sm text-gray-900 font-normal">
                    <SkeletonLoader width="w-64" />
                  </dd>
                </div>
                <span className="h-[1px] col-span-full bg-gray-200" />
                <div className="col-span-full">
                  <h3 className="text-sm font-medium text-gray-900">
                    Preguntas de la cita
                  </h3>
                  <div className="mt-3 w-full space-y-4">
                    <SkeletonLoader width="w-72" />
                    <SkeletonLoader width="w-64" />
                  </div>
                </div>
              </dl>
            </main>
            <footer className="self-end px-6 space-x-4">
              <SkeletonLoader width="w-64" />
            </footer>
          </section>
        ) : (
          <section className="flex flex-col gap-y-8 pb-8 bg-white border border-gray-100 shadow-md rounded-lg">
            <header className="flex items-center h-[64px] px-6 border-b-2 border-gray-100 font-medium text-lg text-gray-900">
              <span className="flex items-center justify-center pr-6 mr-6 border-r-2 border-gray-100 h-full">
                3
              </span>
              <h3 className="flex-1">Cita ASF</h3>
              <Badge text={workflow?.asfAppointment?.status} />
            </header>
            <main className="px-6">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Fecha de la sesión
                  </dt>
                  <Empty
                    text={appointment?.date}
                    placeholder="Fecha pendiente"
                  />
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Hora de la sesión
                  </dt>
                  <Empty
                    text={appointment?.time}
                    placeholder="Hora pendiente"
                  />
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Ubicación
                  </dt>
                  <dd className="mt-1 space-y-2">
                    <Empty
                      text={appointment?.type}
                      placeholder="Ubicación pendiente"
                    />
                    {appointment?.location && (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={appointment.location}
                        className="text-sm text-orange-300 hover:underline hover:underline-offset-2 font-medium mt-1"
                      >
                        Unirse ahora
                      </a>
                    )}
                  </dd>
                </div>

                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Última actualización
                  </dt>
                  {appointment?.lastUpdate ? (
                    <dd className="mt-1 space-y-1 text-sm text-gray-900 font-normal">
                      <p>{appointment.lastUpdate.name}</p>
                      <p>{appointment.lastUpdate.date}</p>
                    </dd>
                  ) : (
                    <dd className="text-sm font-normal text-gray-500">
                      No hay actualizaciones
                    </dd>
                  )}
                </div>
                {appointment?.questionsAnswers?.length > 0 && (
                  <div className="col-span-full">
                    <h3 className="text-sm font-medium text-gray-900">
                      Preguntas de la cita
                    </h3>
                    {appointment.questionsAnswers.map((question) => (
                      <div key={question.position} className="mt-3 w-full">
                        <dt className="text-sm font-medium text-gray-500">
                          P. {question.question}
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 font-normal">
                          R. {question.answer}
                        </dd>
                      </div>
                    ))}
                  </div>
                )}
                {appointment?.lastUpdate?.reason && (
                  <div>
                    <dt className="text-sm font-medium text-gray-500">
                      Motivo de la cancelación
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 font-normal">
                      {appointment.lastUpdate.reason}
                    </dd>
                  </div>
                )}
                {appointment?.questionsAnswers?.length > 0 && (
                  <span className="h-[1px] col-span-full bg-gray-200" />
                )}
              </dl>
            </main>
            {appointment && (
              <footer className="self-end px-6 space-x-4">
                <StatusMenuButton
                  status={appointment.status}
                  disabled={appointment.status !== "active"}
                  updateStatus={(status) => {
                    if (status === "active") return;
                    setCurrentModal("appointment");
                    setStatusModal(status);
                  }}
                  customStatus={["active", "completed", "canceled", "no_show"]}
                  itemsPosition="top"
                />
              </footer>
            )}
          </section>
        )}
      </div>

      <UpdateOrderAsfModal
        orderId={orderId}
        operation={statusModal}
        updateOrder={(data) => updateOrder(data)}
        showModal={currentModal === "orderASF"}
        setShowModal={setCurrentModal}
        setAlert={setAlert}
      />

      <UpdateConnectionStatusModal
        newStatus={statusModal}
        showModal={currentModal === "credentials"}
        setShowModal={setCurrentModal}
        setAlert={setAlert}
        updateConnectionStatus={updateConnectionStatus}
      />
      <UpdateAppointmentStatusModal
        appointmentId={appointmentId}
        status={statusModal}
        showModal={currentModal === "appointment"}
        setShowModal={setCurrentModal}
        setAlert={setAlert}
        setAppointment={() => updateAppointment()}
      />
    </>
  );
}

AsfInfo.propTypes = {
  account: PropTypes.object,
  text: PropTypes.string,
  placeholder: PropTypes.string,
};

export default AsfInfo;
