import { useState } from 'react'

const useCopyToClipboard = () => {
    const [copiedText, setCopiedText] = useState(null);

    const copy = async text => {
        if (!navigator?.clipboard) {
            console.log('Clipboard not supported');
            return false;
        }

        try {
            await navigator.clipboard.writeText(text);
            setCopiedText(text);
            setTimeout(() => {
                setCopiedText(null);
            }, 5000);
            return true;
        } catch (error) {
            console.log('Copy failed', error);
            setCopiedText(null);
            return false;
        }
    }

    return [copiedText, copy]
}

export default useCopyToClipboard
