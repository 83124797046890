import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useFormatDate from '../../hooks/useFormatDate';
import SkeletonLoader from '../../tailwindUI/SkeletonLoader';
import { Transition } from '@headlessui/react';
import Table from '../../tailwindUI/Table';
import { EllipsisVerticalIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { getLead } from '../../apiClient/operations/leadsOperations';
import Modal from '../../tailwindUI/Modal';
import LinkInput from '../../tailwindUI/LinkInput';
import MenuButton from '../../tailwindUI/MenuButton';
import ButtonOrange from '../../tailwindUI/ButtonOrange';
import { useForm } from 'react-hook-form';
import { resetPasswordTaxpayerPortal } from '../../apiClient/operations/linksOperations';
import EmptyState from '../../tailwindUI/EmptyState';
import PrimaryButton from '../../tailwindUI/PrimaryButton';
import { createTaxpayerAnualDeclaration, getTaxpayerDeclarations } from '../../apiClient/operations/anualDeclarationsOperations';
import { AlertContext } from '../../context/AlertContext';
import { useContext } from 'react';
import SecondaryLoader from '../../hooks/SecondaryLoader';
import { ROLES } from '../../cognito/permissions/permission-maps';
import PermissionsGate from '../../cognito/permissions/PermissionsGate';

const declarationStatusList = {
    "pending": "Pendiente",
    "calculation_in_process": "En proceso de cálculo",
    "taxpayer_authorization_pending": "Autorización pendiente",
    "in_taxpayer_review": "Revisión del contribuyente",
    "authorized_by_taxpayer": "Autorizada por el contribuyente",
    "in_declaration_process": "En proceso de declaración",
    "tax_authorization_out_of_date": "Autorización fuera de tiempo",
    "rejected_documentation": "Documentación rechazada",
    "acuses_sent": "Acuses enviados",
    "create_new_acuses": "Crear nuevos acuses",
    "line_capture_expired": "Línea de captura expirada",
    "finished": "Terminada"
}

const tableColumns = [
    { heading: 'Fecha de Creación', value: 'created', main: true },
    { heading: 'Año fiscal', value: 'start_date' },
    { heading: 'Regimenes', value: 'regimes_labels' },
    { heading: 'Estatus', value: 'last_status' },
    { heading: 'Contador', value: 'accountant' },
    { heading: 'Manager', value: 'fixat_manager' }
]

function TaxpayerPortalInfo({ account }) {
    const { formatDate } = useFormatDate();
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingLink, setIsLoadingLink] = useState(false);
    const [isLoadingButton, setIsLoadingButton] = useState(false);
    const [hasDeclarations, setHasDeclarations] = useState(false);
    const [declarations, setDeclarations] = useState([]);
    const [lead, setLead] = useState(undefined)
    const [showLinkModal, setShowLinkModal] = useState(false);
    const [showEmptyState, setShowEmptyState] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const { setAlert } = useContext(AlertContext);
    const [link, setLink] = useState('');
    const {
        register,
        handleSubmit,
        reset
    } = useForm();

    const onSubmit = async (data) => {
        setIsLoadingLink(true)
        const type = lead?.portal_access ? 'recover' : 'create'
        resetPasswordTaxpayerPortal(account?.email, data?.send_email, type).then((response) => {
            setLink(response?.url)
            setIsLoadingLink(false)
            setIsSubmit(true)
        }).catch((error) => {
            console.log(error)
            setIsLoadingLink(false)
            setIsSubmit(true)
        })
    }

    useEffect(() => {
        setIsLoading(true)
        getLead(account?.lead).then((response) => {
            handleTaxpayerDeclarationList()
            setLead(response)
            setShowEmptyState(!response?.portal_allowed)
        }).catch((error) => {
            console.log(error)
            setIsLoading(false)
            setShowEmptyState(true)
        })
    }, []);

    useEffect(() => {
        if (showLinkModal == true) {
            setIsSubmit(false)
        }
    }, [showLinkModal]);

    const portalActions = [
        {
            id: 1,
            name: lead?.portal_access ? 'Crear link de recuperación de accesos' : 'Crear link de acceso al Portal',
            action: () => createConnectionsLink(account?.id, 'sat_credentials_efirma')
        }
    ];

    const createConnectionsLink = () => {
        setShowLinkModal(true);
    }

    const closeModal = () => {
        setShowLinkModal(false)
        reset();
    }

    const handleTaxpayerDeclaration = () => {
        setIsLoadingButton(true)
        createTaxpayerAnualDeclaration(account?.id).then((response) => {
            const newDeclarationFormat = declarationTableFormat(response)
            setDeclarations(allDeclarations => [...allDeclarations, newDeclarationFormat])
            setHasDeclarations(true)
            setIsLoadingButton(false)
            setAlert({ active: true, type: 'success', message: 'La declaración ha sido creada correctamente' })
        }).catch((error) => {
            setIsLoadingButton(false)
            if (error.name == "monday_warning" || error.name == "contract_error") {
                console.log(error.message)
                setAlert({ active: true, type: 'error', message: error.message })
            }
            if (error.message == "'item_id'") {
                setAlert({ active: true, type: 'error', message: 'El contribuyente no tiene regímenes registrados, por ello no es posible crear su declaración anual.' })
            }
        })
    }

    const getRegimenLabels = (declaration) => {
        let regimenLabels = "Sin regimenes "
        if (declaration?.regimes.length > 0) {
            if (declaration?.regimes.length == 1) {
                regimenLabels = declaration?.regimes[0]?.short_name
            } else {
                regimenLabels = ""
                declaration.regimes.map((regimen, i) => {
                    regimenLabels = `${regimenLabels}${regimen?.short_name}${i < declaration.regimes.length - 1 ? ', ' : ""}`
                })
            }
        }
        return regimenLabels
    }

    const handleTaxpayerDeclarationList = () => {
        getTaxpayerDeclarations(account?.id).then((response) => {
            let responseDeclarations = []
            response?.declaration.sort((a, b) => b.created - a.created).map((declaration) => {
                const dec = declarationTableFormat(declaration)
                responseDeclarations.push(dec)
            })
            setDeclarations(responseDeclarations)
            setIsLoading(false)
            setHasDeclarations(true)
        }).catch((error) => {
            setIsLoading(false)
            setHasDeclarations(false)
            console.log(error)
        })
    }

    const declarationTableFormat = (declaration) => {
        const declarationFormat = {
            ...declaration,
            created: formatDate(declaration?.created, 'DD MMMM YYYY'),
            start_date: formatDate(declaration?.start_date, 'YYYY'),
            accountant: declaration?.accountants ? declaration?.accountants[0]?.name : "Sin asignar",
            fixat_manager: declaration?.fixat_managers ? declaration?.fixat_managers[0]?.name : "Sin asignar",
            last_status: declarationStatusList[declaration?.status[declaration?.status.length - 1]?.status],
            regimes_labels: getRegimenLabels(declaration)
        }
        return declarationFormat
    }

    return (
        <>
            {isLoadingButton && <SecondaryLoader />}
            {showEmptyState && <EmptyState title='Contribuyente sin acceso al portal' text='Este contribuyente no tiene acceso al portal debido a que está en proceso de migración de información.' />}
            {(account != undefined) && <>
                <div className={`overflow-hidden bg-white shadow rounded-lg border border-gray-200 ${showEmptyState && 'hidden'}`}>
                    <div className="px-4 py-5 sm:px-6 flex justify-between">
                        <div>
                            <div className='flex'>
                                <>
                                    {isLoading ?
                                        <div className='w-[30vw]'>
                                            <SkeletonLoader />
                                        </div> :
                                        <h3 className="text-lg font-medium leading-6 text-gray-900">Información del Portal del Contribuyente</h3>
                                    }
                                </>
                            </div>
                        </div>
                        <div className='flex gap-2'>
                            {(!isLoading && lead?.portal_allowed) &&
                                <>
                                    <MenuButton
                                        items={portalActions}
                                        itemW={280}>
                                        <EllipsisVerticalIcon className="w-6 h-6 text-gray-900" />
                                    </MenuButton>
                                </>
                            }
                        </div>
                    </div>
                    <Transition
                        show={true}
                        enter="transition-all ease-in"
                        enterFrom="max-h-0 opacity-0"
                        enterTo="max-h-[50rem] opacity-100"
                        leave="transition-all ease-out"
                        leaveFrom="max-h-[50rem] opacity-100"
                        leaveTo="max-h-0 opacity-0">
                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                <div className={`sm:col-span-1`}>
                                    {isLoading ?
                                        <SkeletonLoader />
                                        :
                                        <>
                                            <dt className={`${!lead?.portal_allowed && 'hidden'} text-sm font-medium text-gray-400`}>Correo electrónico de acceso:</dt>
                                            <dd className={`${!lead?.portal_allowed && 'hidden'} mt-1 text-sm text-gray-900`}>
                                                {lead?.email || ''}
                                            </dd>
                                        </>
                                    }
                                </div>
                                <div className={`sm:col-span-1`}>
                                    {isLoading ?
                                        <SkeletonLoader />
                                        :
                                        <>
                                            <dt className={`text-sm font-medium text-gray-400 ${!lead?.portal_allowed && 'hidden'}`}>Fecha de registro:</dt>
                                            <dd className={`mt-1 text-sm text-gray-900 ${!lead?.portal_allowed && 'hidden'}`}>
                                                {(lead?.portal_access && lead?.portal_access?.date) ? formatDate(lead?.portal_access.date, 'DD MMMM YYYY HH:mm') + " hrs" : "No ha accedido aún"}
                                            </dd>
                                        </>
                                    }
                                </div>
                                <div className={`sm:col-span-1 `}>
                                    {isLoading ?
                                        <SkeletonLoader />
                                        :
                                        <>
                                            <dt className={`text-sm font-medium text-gray-400 ${!lead?.portal_allowed && 'hidden'}`}>Último inicio de sesión:</dt>
                                            <dd className={`mt-1 text-sm text-gray-900 ${!lead?.portal_allowed && 'hidden'}`}>
                                                {(lead?.portal_access?.latest_access) ? formatDate(lead?.portal_access.latest_access, 'DD MMMM YYYY HH:mm') + " hrs" : "No ha iniciado sesión aún"}
                                            </dd>
                                        </>
                                    }
                                </div>
                            </dl>
                        </div>
                    </Transition>
                </div>
                <div className={`space-y-4 ${showEmptyState && 'hidden'}`}>
                    <div className='flex flex-row justify-between items-center'>
                        <h2 className='text-gray-900 font-medium text-xl flex gap-2 items-center ml-2'>
                            Declaraciones anuales
                        </h2>
                    </div>

                    {(!isLoading && !hasDeclarations) &&
                        <EmptyState title='Sin declaración anual' text='Este contribuyente no cuenta con una declaración anual.'>
                            <div className='w-full flex items-center justify-center mt-4'>
                                <div className='w-full sm:w-1/2 md:w-1/4'>
                                    <PermissionsGate allowedRoles={[ROLES.root, ROLES.help_desk, ROLES.administrators, ROLES.collections, ROLES.collections_manager]}>
                                        <PrimaryButton isFullWidth onClick={handleTaxpayerDeclaration}>
                                            <span className='text-[15px] md:text-sm py-0.5 md:py-1 font-medium'>Crear declaración</span>
                                        </PrimaryButton>
                                    </PermissionsGate>
                                </div>
                            </div>
                        </EmptyState>
                    }
                    {(isLoading || hasDeclarations) &&
                        <>
                            <Table
                                title='Declaraciones'
                                data={declarations.length > 0 ? declarations : []}
                                isLoadingData={isLoading}
                                columns={tableColumns} />
                        </>
                    }
                </div>
            </>}

            <Modal show={showLinkModal} setShow={setShowLinkModal} className='min-w-full sm:min-w-[500px]'>
                <Transition
                    show={true}
                    enter="transition-all ease-in"
                    enterFrom="max-h-0 opacity-0"
                    enterTo="max-h-[50rem] opacity-100"
                    leave="transition-all ease-out"
                    leaveFrom="max-h-[50rem] opacity-100"
                    leaveTo="max-h-0 opacity-0">
                    <div className="py-2 px-2 text-center space-y-3">
                        <div className='relative'>
                            <XMarkIcon className='absolute w-7 h-7 -right-3 -top-3 cursor-pointer text-gray-500' onClick={closeModal} />
                        </div>
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                            Link de recuperación de accesos
                        </h3>
                        {!isSubmit ? <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col items-center w-full gap-4'>
                            <div className="relative flex items-start">
                                <div className="flex h-6 items-center">
                                    <input
                                        autoFocus={false}
                                        id="send_email"
                                        name="send_email"
                                        type="checkbox"
                                        {...register("send_email")}
                                        className="h-4 w-4 rounded border-gray-300 focus:ring-transparent"
                                    />
                                </div>
                                <div className="ml-3 text-sm leading-6">
                                    <label htmlFor="send_email" className="font-normal text-gray-900">
                                        Enviar link por correo al contribuyente
                                    </label>
                                </div>
                            </div>
                            {!isLoadingLink ?
                                <ButtonOrange isFullWidth={true} type='submit'>
                                    Crear Link
                                </ButtonOrange> :
                                <ButtonOrange isFullWidth={true} type='submit' disabled>
                                    Creando...
                                </ButtonOrange>}
                        </form> :
                            <><p className="max-w-xl text-sm text-gray-500">{!lead?.portal_access ? 'Comparte este link al cliente para que cree sus accessos' : 'Comparte este link al cliente para que recupere sus accessos'}</p>
                                <div className="max-w-xl text-sm text-gray-500">
                                    <LinkInput link={link} />
                                </div></>
                        }


                    </div>
                </Transition>

            </Modal>
        </>
    )
}

TaxpayerPortalInfo.propTypes = {
    contract: PropTypes.object,
    account: PropTypes.object,
    paymentBalance: PropTypes.object,
    setPaymentBalance: PropTypes.func
}

export default TaxpayerPortalInfo;