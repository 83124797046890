import React, { useState } from "react";
import PropTypes from "prop-types";

// import { updateConnectionConfirmation } from "../../apiClient/operations/selfServiceWorkflowOperations";

import { XMarkIcon } from "@heroicons/react/24/solid";

import Modal from "../../tailwindUI/Modal";
import PrimaryButton from "../../tailwindUI/PrimaryButton";
import SecondaryButton from "../../tailwindUI/SecondaryButton";

export function UpdateConnectionStatusModal({
  newStatus,
  showModal,
  setShowModal,
  setAlert,
  updateConnectionStatus,
}) {
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);

  const translateStatus = {
    confirmed: "Confirmado",
    in_review: "En revisión",
    invalid: "Inválido",
  };

  const handleUpdateCredentials = async (e) => {
    try {
      setIsUpdatingStatus(true);
      e.preventDefault();
      await updateConnectionStatus(newStatus);
      setAlert({
        active: true,
        type: "success",
        message: "Estado de las credenciales actualizado correctamente",
      });
    } catch (error) {
      console.log("UPDATE CONNECTION ERROR", error);
      setAlert({ active: true, type: "error", message: error.message });
    } finally {
      setShowModal(false);
      setIsUpdatingStatus(false);
    }
  };

  return (
    <Modal
      show={showModal}
      setShow={setShowModal}
      className="min-w-full sm:min-w-[500px]"
    >
      <div className="py-2 px-2 space-y-3">
        <div className="relative">
          <XMarkIcon
            className="absolute w-7 h-7 -right-3 -top-3 cursor-pointer text-gray-500"
            onClick={() => setShowModal(false)}
          />
        </div>
        <form onSubmit={handleUpdateCredentials}>
          <h3 className="text-lg text-center font-medium leading-6 text-gray-900">
            ¿Estás seguro de que deseas marcar como {translateStatus[newStatus]}
            ?
          </h3>
          <div className="w-fit mt-6 ml-auto space-x-2">
            <SecondaryButton
              type="button"
              onClick={() => setShowModal(false)}
              disabled={isUpdatingStatus}
            >
              Cancelar
            </SecondaryButton>
            <PrimaryButton type="submit" disabled={isUpdatingStatus}>
              Confirmar
            </PrimaryButton>
          </div>
        </form>
      </div>
    </Modal>
  );
}

UpdateConnectionStatusModal.propTypes = {
  newStatus: PropTypes.string,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  setAlert: PropTypes.func,
  updateConnectionStatus: PropTypes.func,
};
