import React, { useEffect, useState } from 'react';
import Badge from '../../tailwindUI/Badge';
import { formatPeso } from '../../util';
import TableTimeline from '../../tailwindUI/TableTimeline';
import { TagIcon } from '@heroicons/react/20/solid';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ClockIcon } from '@heroicons/react/24/solid';
import { getTimeline } from '../../apiClient/operations/collectionOperations';
import PropTypes from 'prop-types';

const tableColumns = [
    { heading: 'Servicio', value: 'service', main: true },
    { heading: 'Ene', value: 'january' },
    { heading: 'Feb', value: 'february' },
    { heading: 'Mar', value: 'march' },
    { heading: 'Abr', value: 'april' },
    { heading: 'May', value: 'may' },
    { heading: 'Jun', value: 'june' },
    { heading: 'Jul', value: 'july' },
    { heading: 'Ago', value: 'august' },
    { heading: 'Sep', value: 'september' },
    { heading: 'Oct', value: 'october' },
    { heading: 'Nov', value: 'november' },
    { heading: 'Dic', value: 'december' },
];
const defaultPeriods = {
    january: "-",
    february: "-",
    march: "-",
    april: "-",
    may: "-",
    june: "-",
    july: "-",
    august: "-",
    september: "-",
    october: "-",
    november: "-",
    december: "-",
}
const defaultStatus = [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    ""
]

const defaultDiscount = Array(13).fill(false)
const monthName = ["january", "february", "march", "april", "may", "june", "july",
        "august", "september", "october", "november", "december"];

export const Timeline = ({ accountId, minYear }) => {
    const now = new Date();
    const [year, setYear] = useState(now)
    const [dataTime, setDataTime] = useState([])
    const [isLoading, setIsLoading] = useState([])
    const [statusData, setStatusData] = useState([])
    const [discountData, setDiscountData] = useState([])
    const [balance, setBalance] = useState([])

    useEffect(() => {
        setIsLoading(true)
        getTimeline(accountId, year.getFullYear()).then((response) => {
            setIsLoading(false)
            let info = [];
            let infoStatus = [];
            let infoDiscount = [];

            setBalance(response?.overview)
            response.data.map((item) => {
                let itemInfo = {
                    service: item?.name,
                    ...defaultPeriods
                }
                let itemStatus = [
                    ...defaultStatus
                ];
                let itemDiscount = [
                    ...defaultDiscount
                ]
                item.periods.map((period) => {
                    const m = period.date.split("-")
                    const month = parseInt(m[1]);
                    itemInfo[monthName[month - 1]] = formatPeso(period.amount / 100)
                    itemDiscount[month] = period.amount !== item.unit_price
                    itemStatus[month] = period.status 
                })
                infoStatus.push(itemStatus)
                infoDiscount.push(itemDiscount)
                info.push(itemInfo)
            })
            setDataTime(info)
            setStatusData(infoStatus)
            setDiscountData(infoDiscount)
        }).catch((error) => {
            setIsLoading(false)
            console.log(error)
        })
    }, [year]);

    
    return (
        <>
            <div className="overflow-hidden bg-white shadow rounded-lg border border-gray-200">
                <div className="px-4 py-5 sm:px-6 flex justify-between">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">Timeline</h3>
                </div>
                <div className="border-t flex flex-col gap-4 border-gray-200 px-4 py-5 sm:px-6">
                    <div className='flex justify-end'>
                        <div className='relative w-[14%] 2xl:w-[10%]'>
                            <span className="pointer-events-none absolute inset-y-0 top-[0.25rem] -left-1 ml-3 flex flex-col z-30 items-center pr-2">
                                <ClockIcon className="min-h-[1.5rem] min-w-[1.5rem] text-gray-300 mt-1" aria-hidden="true" />
                            </span>
                            <DatePicker
                                // disabled={isLoadingDeclaration}
                                selected={year}
                                onChange={(date) => setYear(date)}
                                dateFormat="yyyy"
                                className='w-full pl-9 border-gray-300  rounded-md text-gray-900 py-2.5 text-sm cursor-pointer'
                                wrapperClassName='w-full'
                                disabledKeyboardNavigation
                                onFocus={e => e.target.blur()}
                                showYearPicker
                                minDate={new Date(minYear, 0, 1)}
                                maxDate={new Date(now.getFullYear(), 11, 31)}
                                yearItemNumber={4}
                            />
                        </div>

                    </div>


                    <div className='flex flex-row justify-end gap-3'>
                        <span className={`inline-flex items-center rounded-full border border-gray-200 text-[#065F46] px-3 py-0.5 text-sm font-semibold text-center`}>
                            <TagIcon className='w-5 h-5 text-green-500' /> Convenio
                        </span>
                        <Badge text={'Pagado'} type={'Success'} />
                        <Badge text={'Morosidad temprana'} type={'Warning'} />
                        <Badge text={'Morosidad tardía'} type={'Error'} />
                        {/* <Badge text={'Activo'} type={'Success'}/> */}
                    </div>
                    <TableTimeline year={year.getFullYear()} isLoadingData={isLoading} statusData={statusData} title='' data={dataTime} discountData={discountData} columns={tableColumns} />

                    {dataTime.length > 0 ? (
                        <div className='w-full flex items-end flex-col mt-6'>
                            <table className='w-1/2'>
                                <thead className='py-3 pl-4 pr-3 text-left text-xs uppercase font-medium tracking-wide bg-gray-50 text-gray-500'>
                                    <tr>
                                        <th scope="col" className="px-3 py-3.5">
                                            Servicio
                                        </th>
                                        <th scope="col" className="text-right px-3 py-3.5">
                                            Saldo anterior
                                        </th>
                                        <th scope="col" className="text-blue-sky text-right px-3 py-3.5">
                                            <div className='flex justify-end items-center gap-1'>
                                                <div className='w-2 h-2 bg-blue-sky rounded-full'></div>Mes actual
                                            </div>

                                        </th>
                                    </tr>
                                </thead>
                                <tbody >
                                    {balance.map((item, index) => (
                                        <tr key={index} className='border-b border-gray-200'>
                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-2">
                                                {item.name}
                                            </td>
                                            <td className="whitespace-nowrap text-right px-3 py-4 text-xs text-gray-500">{formatPeso(parseInt(item?.debt_amount / 100))}</td>
                                            <td className="whitespace-nowrap text-right px-3 py-4 text-xs text-gray-500">{formatPeso(parseInt(item?.mensual_charge / 100))}</td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-2"></td>
                                        <td className="whitespace-nowrap text-right px-3 py-4 text-2xl font-normal text-gray-900">{balance.length > 0 && formatPeso(balance.reduce((accumulator, currentBalance) => accumulator + (parseInt(currentBalance?.debt_amount / 100)), 0, 0))}</td>
                                        <td className="whitespace-nowrap text-right px-3 py-4 text-2xl font-normal text-gray-900">{balance.length > 0 && formatPeso(balance.reduce((accumulator, currentBalance) => accumulator + (parseInt(currentBalance?.mensual_charge / 100)), 0, 0))}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div className='h-2' />
                    )
                }

                </div>
            </div>
        </>
    )
}
Timeline.propTypes = {
    accountId: PropTypes.string,
    minYear: PropTypes.Date,
}