import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useFormatterCurrency from '../../hooks/useFormatterCurrency';
import useFormatDate from '../../hooks/useFormatDate';
import Table from '../../tailwindUI/Table';
import SlideOver from '../../tailwindUI/SlideOver';
import Badge from '../../tailwindUI/Badge';
import EmptyState from '../../tailwindUI/EmptyState';
import moment from 'moment';
import { getAccountSubs, getBalances } from '../../apiClient/operations/subscriptionOperations';
import { useLocation } from 'react-router';
import SkeletonLoader from '../../tailwindUI/SkeletonLoader';
import LinkButton from '../../tailwindUI/LinkButton';
import { Transition } from '@headlessui/react';
import { ReceiptRefundIcon } from '@heroicons/react/20/solid';
import CancelModal from './CancelModal';
import PermissionsGate from '../../cognito/permissions/PermissionsGate';
import { ROLES } from '../../cognito/permissions/permission-maps';

const tableColumns = [
    { heading: 'Fecha de creación', value: 'charged_formatted', subvalue: 'hour_formated', main:true },
    { heading: 'Concepto', value: 'name', subvalue:'provider', align: 'center'},
    { heading: 'Monto por periodo', value: 'formated_amount', subvalue:'periodicity' ,align: 'center'},
    { heading: 'Periodo actual', value: 'periodo', align: 'center' },
    { heading: 'Próxima Fecha de cobro', value: 'charged_formatted', subvalue: 'from_now', align: 'center' },
    { heading: 'Estatus', value: 'status' , badge: true, align: 'center' }
];

const chargesColumns = [
    { heading: 'Fecha de Pago', value: 'formated_date', subvalue:'hour_formated',main:true},
    { heading: 'Monto pagado', value: 'formated_amount', align: 'center'},
    { heading: 'Estatus', value: 'status' , badge: true, description:'failureMessage' ,align: 'center',},
];

function SubscriptionInfo({ account }) {
    const { formatterCurrency } = useFormatterCurrency();
    const { formatDate } = useFormatDate();
    const { state } = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const [openSlide, setOpenSlide] = useState(false);
    const [balancesList, setBalancesList] = useState([]);
    const [loadingBalance, setLoadingBalance] = useState(true);
    const [paymentDetails, setPaymentDetails] = useState(null);
    const [handleUpdate, setHandleUpdate] = useState(false);
    const [cancelModal, setCancelModal] = useState(false);
    const [subsData, setSubsData] = useState([]);
    const [recentSub, setRecentSub] = useState([]);
    /*useEffect(async () => {
        if (state?.subscription) {
            setOpenSlide(true);
            var findPaymentDetails = balancesList.find(item => item?.id == state?.subscription?.id);
            setPaymentDetails(findPaymentDetails);
            await handlerBalances(state?.subscription?.account_id, state?.subscription?.order_id)
            return;
        }
    }, [state]);*/

    const handlerBalances= async (account_id, order_id) => {
        try {
        setLoadingBalance(true)
        const currentBalance = await getBalances(account_id, order_id)
        currentBalance.data.forEach(element => {
            element.formated_amount = formatterCurrency(element.amount/100)
            element.payment_date = element?.payment_date||element?.created
            element.formated_date = formatDate(element?.payment_date, 'DD MMMM YYYY'),
            element.hour_formated= formatDate(element?.payment_date, 'HH:mm')+" hrs",
            //element.hour = formatDate(element.payment_date, 'HH:mm:ss')
            element.failureMessage = element?.failure?.message||"Pago realizado con éxito"
            element.status = element?.status||"Pagado"
        });
        setBalancesList(currentBalance.data.reverse())
        setLoadingBalance(false)
    } catch (error) {
        setBalancesList([])
        setLoadingBalance(false)
        }
    
    }
    const handleGetSubscriptions = async () => {
        setIsLoading(true);
        try {
            const res = await getAccountSubs(account.id);
            const response = res.map(item => {
                var total_charges = (item?.expiry_count==0 || item?.expiry_count==9999999)? "":"de "+(item?.total_payments||item?.expiry_count)
                var periodicity = {
                    "month1":"Mensual",
                    "month2":"Bimestral",
                    "month3":"Trimestral",
                    "month6":"Semestral",
                    "month12":"Anual",
                    "year1":"Anual",
                }
                var next_date = item?.charge_date||moment(item?.created).add(item?.trial_days, "days").valueOf();
                var current_date = moment().valueOf();
                var is_today = (formatDate(next_date, 'DD MMMM YYYY')==formatDate(current_date, 'DD MMMM YYYY') || moment(next_date).fromNow().includes('horas'))
                var charges = (item?.number_charge||0);
                (item?.total_payments==item?.expiry_count+1||item?.apply_charge==true) && (charges+=1);
                return {
                    ...item,
                    full_formated: formatDate(item?.created, 'DD MMMM YYYY HH:mm')+" hrs",
                    hour_formated: formatDate(item?.created, 'HH:mm')+" hrs",
                    created_formatted: formatDate(next_date, 'DD MMMM YYYY'),
                    charged_formatted: formatDate(next_date, 'DD MMMM YYYY'),
                    from_now : is_today ? "Hoy":moment(next_date).fromNow(),
                    formated_amount : formatterCurrency(item.amount/100),
                    periodicity:periodicity[item?.interval+item?.frequency]||"mensual",
                    periodo:`${charges} ${total_charges}`,
                    account:account,
                    provider: item?.subscription_info?.provider||"Sin proveedor",
                    ...(item?.completed_at&&{formated_completed:formatDate(item?.completed_at, 'DD MMMM YYYY HH:mm')+" hrs",}),
                    ...(item?.canceled_at&&{formated_canceled:formatDate(item?.canceled_at, 'DD MMMM YYYY HH:mm')+" hrs",})
                }
            });
            if (state?.subscription) {
                setOpenSlide(true);
                var findPaymentDetails = response.find(item => item?.id == state?.subscription?.id);
                setPaymentDetails(findPaymentDetails);
                await handlerBalances(state?.subscription?.account_id, state?.subscription?.order_id)
            }
            setRecentSub(response.reverse().shift())
            setSubsData(response);
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        handleGetSubscriptions();
        }, [handleUpdate]);

    const handleView = async item => {
        setPaymentDetails(item);
        setOpenSlide(true);
        await handlerBalances(item?.account_id, item?.order_id)
    }

    const columnActions = [
        {
            id: 1,
            name: 'Detalles',
            type: 'secondary',
            action: handleView,
        }
    ];


    return (
        <>
            {(recentSub != [] && recentSub != undefined ) &&<div className="overflow-hidden bg-white shadow rounded-lg border border-gray-200">
                <div className="px-4 py-5 sm:px-6 flex justify-between">
                    <div>
                        {isLoading ? (
                            <div className='w-[30vw]'>
                                <SkeletonLoader />
                            </div>
                        ) : (
                            <div className='flex'>
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Suscripción mas reciente</h3>
                            <div className="ml-2 sm:col-span-1">
                                <Badge text={recentSub?.status} />
                            </div>
                            </div>
                        )}
                    </div>
                    <div className='flex gap-2'>
                        {!isLoading &&
                            <>
                                    <LinkButton onClick={() => handleView(recentSub)}>
                                        Detalles
                                    </LinkButton>
                            </>
                        }
                    </div>
                </div>
                <Transition
                    show={true}
                    enter="transition-all ease-in"
                    enterFrom="max-h-0 opacity-0"
                    enterTo="max-h-[50rem] opacity-100"
                    leave="transition-all ease-out"
                    leaveFrom="max-h-[50rem] opacity-100"
                    leaveTo="max-h-0 opacity-0">
                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            <div className="sm:col-span-1">
                                {isLoading ?
                                    <SkeletonLoader />
                                    :
                                    <>
                                        <dt className="text-sm font-medium text-gray-400">Fecha de creación:</dt>
                                        <dd className={`mt-1 text-sm text-gray-900`}>
                                                {recentSub?.full_formated || 'Sin definir'}
                                        </dd>
                                    </>
                                }
                            </div>
                            <div className="sm:col-span-1">
                                {isLoading ?
                                    <SkeletonLoader />
                                    :
                                    <>
                                        <dt className="text-sm font-medium text-gray-400">Proveedor de la suscripción:</dt>
                                        <dd className={`mt-1 text-sm text-gray-900`}>
                                                {recentSub?.subscription_info?.provider || 'Sin proveedor'}
                                        </dd>
                                    </>
                                }
                            </div>
                            <div className="sm:col-span-1">
                                {isLoading ?
                                    <SkeletonLoader />
                                    :
                                    <>
                                        <dt className="text-sm font-medium text-gray-400">Concepto:</dt>
                                        <dd className={`mt-1 text-sm text-gray-900`}>
                                                {recentSub?.name || 'Concepto no añadido'}
                                        </dd>
                                    </>
                                }
                            </div>
                            <div className="sm:col-span-1">
                                {isLoading ?
                                    <SkeletonLoader />
                                    :
                                    <>
                                        <dt className="text-sm font-medium text-gray-400">Monto por periodo:</dt>
                                        <dd className={`mt-1 text-sm text-gray-900`}>
                                                {recentSub?.formated_amount+"/"+recentSub?.periodicity || 'Sin definir'}
                                        </dd>
                                    </>
                                }
                            </div>
                            <div className="sm:col-span-1">
                                {isLoading ?
                                    <SkeletonLoader />
                                    :
                                    <>
                                        <dt className="text-sm font-medium text-gray-400">Periodo actual:</dt>
                                        <dd className={`mt-1 text-sm text-gray-900`}>
                                                {recentSub?.periodo || 'Sin definir'}
                                        </dd>
                                    </>
                                }
                            </div>
                            <div className="sm:col-span-1">
                                {isLoading ?
                                    <SkeletonLoader />
                                    :
                                    <>
                                        <dt className="text-sm font-medium text-gray-400">Próxima fecha de cobro:</dt>
                                        <dd className={`mt-1 text-sm text-gray-900`}>
                                                {recentSub?.created_formatted || 'Fecha sin especificar'}
                                        </dd>
                                    </>
                                }
                            </div>
                            
                        </dl>
                    </div>
                </Transition>
            </div>}
            <div className='space-y-4'>
                <div className='space-y-4'>
                    {(recentSub != [] && recentSub != undefined && (isLoading || subsData?.length > 0)) && <h2 className='text-gray-900 font-medium text-xl flex gap-2 items-center'>
                        Otras suscripciones
                    </h2>}
                    {(!isLoading && (subsData?.length == 0) && (recentSub == [] || recentSub == undefined)) &&
                        <EmptyState title='Sin suscripciones' text='No se ha suscrito a ningun servicio todavía.' />
                    }
                    {(recentSub != [] && recentSub != undefined && (isLoading || subsData?.length > 0)) &&
                        <>
                            <Table title='Suscripciones' data={ subsData } isLoadingData={ isLoading } columns={ tableColumns } actions={ columnActions } />
                        </>
                    }
                </div>
            </div>
            <SlideOver open={openSlide} setOpen={setOpenSlide} title="Detalles de la suscripción:">
                <div className='w-full divide-y divide-gray-200'>
                    <dl className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                            {paymentDetails?.created_by && paymentDetails?.created_by?.name && <div className="sm:col-span-1 pb-2">
                                <dt className="text-sm font-medium text-gray-500">Suscripción creada por:</dt>
                                <dd className="mt-1 text-sm text-gray-900">{paymentDetails?.created_by?.name}</dd>
                            </div>}
                            {(paymentDetails?.status == 'active' || paymentDetails?.status == 'in_trial' || paymentDetails?.status == 'trial') && 
                            <div className=''>
                                <PermissionsGate allowedRoles={[ROLES.root, ROLES.collections_manager, ROLES.collections]}>
                                    <LinkButton onClick={() => {setCancelModal(true)}}>
                                        Cancelar suscripción<ReceiptRefundIcon className='w-5 h-5 ml-2 inline-block' />
                                    </LinkButton>
                                </PermissionsGate>
                            </div>
                            }
                    </dl>
                    <div className='py-2'>
                            
                        <dl className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Estatus:</dt>
                                <Badge text={paymentDetails?.status} />
                            </div>
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Concepto:</dt>
                                <dd className="mt-1 text-sm text-gray-900">{paymentDetails?.name}</dd>
                            </div> 
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Proveedor:</dt>
                                <dd className="mt-1 text-sm text-gray-900">{paymentDetails?.subscription_info?.provider}</dd>
                            </div>
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Monto por periodo:</dt>
                                <dd className="mt-1 text-sm text-gray-900">{paymentDetails?.formated_amount+"/"+paymentDetails?.periodicity}</dd>
                            </div>
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Periodo actual:</dt>
                                <dd className="mt-1 text-sm text-gray-900">{paymentDetails?.periodo}</dd>
                            </div>
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Proxima fecha de cobro:</dt>
                                <dd className="mt-1 text-sm text-gray-900">{paymentDetails?.charged_formatted}</dd>
                            </div>
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Fecha de creación:</dt>
                                <dd className="mt-1 text-sm text-gray-900">{paymentDetails?.full_formated}</dd>
                            </div>
                            {paymentDetails?.completed_at && <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Fecha de completado:</dt>
                                <dd className="mt-1 text-sm text-gray-900">{paymentDetails?.formated_completed}</dd>
                            </div> }
                        </dl>              
                    </div>
                    <div className='py-3'>
                        <h3 className="font-medium leading-6 text-gray-900 mb-2">Cancelación:</h3>
                        <dl className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                        {paymentDetails?.canceled_by && paymentDetails?.created_by?.name && <div className="sm:col-span-1 pb-2">
                                <dt className="text-sm font-medium text-gray-500">Suscripción cancelada por:</dt>
                                <dd className="mt-1 text-sm text-gray-900">{paymentDetails?.canceled_by?.name}</dd>
                            </div>}
                        {paymentDetails?.canceled_at &&<div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Fecha de cancelación:</dt>
                                <dd className="mt-1 text-sm text-gray-900">{paymentDetails?.formated_canceled}</dd>
                            </div> }
                            {paymentDetails?.cancel_reason && <div className="sm:col-span-2">
                                <dt className="text-sm font-medium text-gray-500">Razón de cancelación:</dt>
                                <dd className="mt-1 text-sm text-gray-900">{paymentDetails?.cancel_reason}</dd>
                            </div> }
                        </dl>
                    </div>
                    <div className='py-3'>
                        <h3 className="font-medium leading-6 text-gray-900 mb-4">Cargos de la suscripción:</h3>
                        {(!loadingBalance && balancesList?.length == 0) ?
                        <div className='mt-4'>
                            <EmptyState title='No se encontraron cargos' text='Para visualizar la información, deben existir primero en el sistema, verifícalo.' />
                        </div>:
                        <Table title='Cargos' data={ balancesList } isLoadingData={ loadingBalance } columns={ chargesColumns } />
                        }
                    </div>
                </div>
            </SlideOver>
            <CancelModal handleUpdate={setHandleUpdate} paymentDetails={paymentDetails} setPaymentDetails={setPaymentDetails} showRefund={cancelModal} setShowRefund={setCancelModal}/>
        </>
    )
}

SubscriptionInfo.propTypes = {
    contract: PropTypes.object,
    account: PropTypes.object,
    paymentBalance: PropTypes.object,
    setPaymentBalance: PropTypes.func
}

export default SubscriptionInfo;