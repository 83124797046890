import React from 'react';
import PropTypes from 'prop-types';

function SecondaryLoader({ message }) {
    return (
        <div className="w-screen h-screen fixed top-0 left-0 flex justify-center flex-row items-center z-[60] backdrop-filter backdrop-blur-lg">
        <div className="w-11/12 md:w-7/12 relative bg-transparent">
            <div className="w-full float-left mb-14 flex justify-center ani_img_botxi">
                <img 
                src='https://cdn.fixat.mx/intranet/botxi.png'
                alt="Procesando..."
                title="Procesando..."
                height="175px"
                width="139px"
                />
            </div>
            <div className="w-full flex justify-center">
                <h2 className="mt-4 text-4xl text-center text-v2-input-text font-bold">
                    {message}
                </h2>
            </div>
        </div>
    </div>
    )
}

SecondaryLoader.propTypes = {
    message: PropTypes.string
}

SecondaryLoader.defaultProps = {
    message: "Procesando..."
}

export default SecondaryLoader