import React, { Fragment, useState, useEffect } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon, TrashIcon } from '@heroicons/react/20/solid';
import PropTypes from 'prop-types';
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar, utils } from "react-modern-calendar-datepicker";
import moment from 'moment';
import 'moment-timezone';
import PrimaryButton from './PrimaryButton';

function DateIntervalV2({ filters, openSlide, setFilters, setOpenSlide, setFiltersApplied }) {
    const [selectedDayRange, setSelectedDayRange] = useState({
        from: filters?.start ? { day: moment(filters?.start).date(), month: moment(filters?.start).month() + 1, year: moment(filters?.start).year() } : null,
        to: filters?.end ? { day: moment(filters?.end).date(), month: moment(filters?.end).month() + 1, year: moment(filters?.end).year() } : null
    });
    const [parsedDate, setParsedDate] = useState()

    useEffect(() => {
        var fromDate = (filters.start != null) ? moment(filters.start).format("DD/M/YYYY") : moment().format("DD/M/YYYY");
        var toDate = (filters.end != null) ? moment(filters.end).format("DD/M/YYYY") : moment().format("DD/M/YYYY");
        if (fromDate == toDate) {
            setParsedDate(fromDate)
        } else {
            setParsedDate(fromDate + ' - ' + toDate)
        }
    }, [selectedDayRange, filters])

    const handleOpenResume = () => {
        var open_filter = {
            ...filters,
            open: !filters.open
        }
        setFilters(open_filter);
    }

    const handleUpdateFilters = () => {
        const startDate = moment(selectedDayRange.from.year + "/" + selectedDayRange.from.month + "/" + selectedDayRange.from.day, "YYYY/MM/DD").startOf('day')
        const endDate = moment(selectedDayRange.to.year + "/" + selectedDayRange.to.month + "/" + selectedDayRange.to.day, "YYYY/MM/DD").endOf('day')
        setFilters(
            {
                ...filters,
                start: startDate,
                end: endDate
            }
        )
    }

    const handleResetFilters = () => {
        setSelectedDayRange({
            from: null,
            to: null
        });
        setFilters({
            ...filters,
            start: null,
            end: null,
        });
        setFiltersApplied(false);
    }

    const onSubmit = (e) => {
        e.preventDefault();
        handleUpdateFilters();
        setFiltersApplied(true);
    }

    const onSubmitMobile = (e) => {
        e.preventDefault();
        handleUpdateFilters();
        setOpenSlide(false);
        setFiltersApplied(true);
    }

    return (
        <>
            <Popover.Group className={`${openSlide ? 'hidden' : 'flex'} items-baseline md:space-x-6`}>
                <Popover as="div" key={filters.name} id={`desktop-menu-date-interval`} className="relative inline-block text-left">
                    <div>
                        <Popover.Button className="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                            <span>{
                                filters.start != null ? parsedDate : filters.name}</span>
                            <ChevronDownIcon className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                        </Popover.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Popover.Panel className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {({ close }) => (
                                <form className="space-y-4" onSubmit={(e) => onSubmit(e, filters.id)}>
                                    <Calendar
                                        locale='en'
                                        maximumDate={utils().getToday()}
                                        wrapperClassName='block z-10 responsive-calendar'
                                        inputClassName='w-full z-10 appearance-none cursor-pointer text-left py-2 px-4 font-normal border border-v2-border-time rounded-md text-base text-v2-input-text outline-none responsive-calendar'
                                        calendarClassName='block font-normal text-v2-input-text'
                                        calendarPopperPosition='bottom'
                                        colorPrimary='#2169AC'
                                        colorPrimaryLight='#D9E2EC'
                                        value={selectedDayRange}
                                        onChange={setSelectedDayRange}
                                    />
                                    {//<button className={`w-full py-1.5 px-3 text-white text-sm rounded-md bg-gray-300 cursor-not-allowed}`} onClick={ () => close() }>
                                    }
                                    <div className='flex flex-row gap-1'>
                                        <button className={`w-full py-1.5 px-3 text-white text-sm rounded-md ${selectedDayRange?.from ? "bg-v2-blue-text-login cursor-pointer" : "bg-gray-300 cursor-not-allowed"} }`} onClick={() => { selectedDayRange?.from && close() }}>
                                            Aplicar
                                        </button>
                                        {filters?.start && <PrimaryButton onClick={() => { handleResetFilters() }}>
                                            {<TrashIcon className="h-5 w-5text-white" aria-hidden="true" />}
                                        </PrimaryButton>}
                                    </div>
                                </form>
                            )}
                        </Popover.Panel>
                    </Transition>
                </Popover>
            </Popover.Group>
            {openSlide &&
                <form className="w-screen max-w-lg mt-4 md:hidden relative -ml-4 sm:-ml-6" onSubmit={onSubmitMobile}>
                    <div className='w-full overflow-y-scroll scroller'>
                        <div className='w-full border-t border-gray-200 px-4 py-4'>
                            <div className='w-full flex justify-between'>
                                <div>
                                    <span className="text-sm font-medium text-gray-900">{filters.name}</span>
                                </div>
                                <span className="ml-6 flex items-center text-gray-400">
                                    <ChevronDownIcon className={`${filters.open ? '-rotate-180' : 'rotate-0'} 'h-5 w-5 transform duration-200`} onClick={() => handleOpenResume(filters.id)} />
                                </span>
                            </div>
                            <div className={`${filters.open ? 'block' : 'hidden'} space-y-6 mt-4`}>
                                <Calendar
                                    locale='en'
                                    maximumDate={utils().getToday()}
                                    wrapperClassName='block z-10 responsive-calendar'
                                    inputClassName='w-full z-10 appearance-none cursor-pointer text-left py-2 px-4 font-normal border border-v2-border-time rounded-md text-base text-v2-input-text outline-none responsive-calendar'
                                    calendarClassName='block font-normal text-v2-input-text'
                                    calendarPopperPosition='bottom'
                                    colorPrimary='#2169AC'
                                    colorPrimaryLight='#D9E2EC'
                                    value={selectedDayRange}
                                    onChange={setSelectedDayRange}
                                />
                                <div className='flex flex-row gap-1'>
                                    <PrimaryButton isFullWidth={true} disabled={!selectedDayRange?.from}>
                                        Aplicar
                                    </PrimaryButton>
                                    {filters?.start && <PrimaryButton onClick={() => { handleResetFilters() }}>
                                        {<TrashIcon className="h-5 w-5text-white" aria-hidden="true" />}
                                    </PrimaryButton>}</div>
                            </div>
                        </div>

                    </div>
                </form>
            }
        </>
    )
}

DateIntervalV2.propTypes = {
    filters: PropTypes.object,
    openSlide: PropTypes.bool,
    setFilters: PropTypes.func,
    setOpenSlide: PropTypes.func,
    setFiltersApplied: PropTypes.func
}

DateIntervalV2.defaultProps = {
    openSlide: false
}

export default DateIntervalV2