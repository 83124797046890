import apiClient from "../apiClient";
import { apiBaseUrl } from "../../config";

export const getOrder = async (order_id = "") => {
  return apiClient
    .get(`${apiBaseUrl}/orders/admin/${order_id}`)
    .then((response) => {
      return response;
    });
};

export const assignASF = async ({ orderId, name, email }) => {
  return apiClient
    .put(`${apiBaseUrl}/orders/${orderId}/assignment/asf`, {
      created_by: {
        name,
        email,
      },
    })
    .then((response) => {
      return response;
    });
};

export const releaseASF = async ({ orderId }) => {
  return apiClient
    .put(`${apiBaseUrl}/orders/${orderId}/release/asf`)
    .then((response) => {
      return response;
    });
};
