import moment from 'moment';
import 'moment/locale/es';
moment().local('es');

const useFormatPhoneNumber = () => {
    const formatPhoneNumber = (number) => {
        const lastTen = number?.slice(-10);
        const firstPart = lastTen.slice(0, 3);
        const secondPart = lastTen.slice(3,6);
        const thirdPart = lastTen.slice(6,10);
        const formatedNumber = `+52 ${firstPart} ${secondPart} ${thirdPart}` || number
        return formatedNumber
    };
    return { formatPhoneNumber }
}

export default useFormatPhoneNumber