import { useEffect, useState } from "react";
import { getASFWorkflow } from "../../apiClient/operations/selfServiceWorkflowOperations";

export function useWorkflow({ accountId }) {
  const [workflow, setWorkflow] = useState(null);

  const [isLoadingWorkflow, setIsLoadingWorkflow] = useState(false);
  const [errorWorkflow, setErrorWorkflow] = useState(false);

  const getTransformedData = (data) => {
    return {
      asfAccount: data.account,
      asfOrder: data.asf_workflow.data[0],
      asfSatCredentials: data.asf_workflow.data[1],
      asfAppointment: data.asf_workflow.data[2],
    };
  };

  const getAppointmentFormatted = async () => {
    try {
      setIsLoadingWorkflow(true);
      const response = await getASFWorkflow(accountId);
      const transformedData = getTransformedData(response);
      setWorkflow(transformedData);
    } catch (error) {
      console.log("GET APPOINTMENT ERROR WORKFLOW: ", error);
      setErrorWorkflow(error.message);
    } finally {
      setIsLoadingWorkflow(false);
    }
  };

  useEffect(() => {
    if (accountId) getAppointmentFormatted();
  }, [accountId]);

  return { workflow, setWorkflow, isLoadingWorkflow, errorWorkflow };
}
