export const ROLES = {
    root: "root",
    administrators: "administrators",
    executives: "executives",
    sales: "sales",
    operations: "operations",
    collections: "collections",
    collections_manager: "collections_manager",
    customerService: "customer_service",
    acquisitions: "acquisitions",
    acquisitionsCollection: "acquisitions_collection",
    operationsAtc: "operations_atc",
    guests: "guests",
    all: "all", 
    help_desk: "help_desk"
};

export const SCOPES = {
    canCreate: "can-create",
    canEdit: "can-edit",
    canDelete: "can-delete",
    canView: "can-view"
};

export const PERMISSIONS = {
    [ROLES.root]: [
        SCOPES.canView,
        SCOPES.canEdit,
        SCOPES.canCreate,
        SCOPES.canDelete
    ],
    [ROLES.executives]: [
        SCOPES.canView,
        SCOPES.canEdit,
        SCOPES.canCreate,
        SCOPES.canDelete
    ],
    [ROLES.operations]: [
        SCOPES.canView,
        SCOPES.canEdit,
        SCOPES.canCreate,
        SCOPES.canDelete
    ],
    [ROLES.customerService]: [
        SCOPES.canView,
        SCOPES.canEdit,
    ],
    [ROLES.guests]: [
        SCOPES.canView,
        SCOPES.canEdit,
    ],
    [ROLES.sales]: [
        SCOPES.canView,
        SCOPES.canEdit,
    ],
    [ROLES.all]: [
        SCOPES.canView,
        SCOPES.canEdit,
        SCOPES.canCreate,
        SCOPES.canDelete
    ],
};