import React, { createContext } from "react";
import PropTypes from 'prop-types';
import jwt_decode from "jwt-decode";
import cookie from "js-cookie";
import { validateSession, logout } from '../apiClient/operations/sessionOperations';
import { ROLES, SCOPES, PERMISSIONS } from "./permissions/permission-maps";

const getRoles = (groups) => {
    const filteredGroups = groups?.filter(group => !group.toLowerCase().includes("google"));
    if (filteredGroups?.length == 0) return [ROLES.guests];
    return filteredGroups?.map(group => group.toLowerCase());
}

const getPermissions = (roles) => {
    let permissions = {};
    roles?.forEach(role => {
        for (const scope in SCOPES) {
            permissions[scope] = PERMISSIONS[role]?.includes(SCOPES[scope]);
        }
    })
    return permissions;
}

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

    let user = null;
    const query = new URLSearchParams(new URL(window.location.href).hash);
    let error = query.get('#error_description');
    const idToken = query.get('id_token') || cookie.get("id_token");
    const accessToken = query.get('#access_token') || cookie.get("access_token");

    if (idToken && accessToken) {
        const { email_verified, name, given_name, family_name, picture, email } = jwt_decode(idToken);
        const groups = jwt_decode(idToken)['cognito:groups'];
        if (email_verified) {
            if (!cookie.get("access_token")) {
                cookie.set("access_token", accessToken, { secure: true });
            }
            if (!cookie.get("id_token")) {
                cookie.set("id_token", idToken, { secure: true });
            }
            validateSession();
            const roles = getRoles(groups);
            const permissions = getPermissions(roles);
            user = {
                name: name,
                givenName: given_name,
                familyName: family_name,
                picture: picture,
                email: email,
                roles: roles,
                permissions: permissions
            };
        } else {
            error = "not_authorized";
            logout(true);
        }
    }

    return (
        <AuthContext.Provider value={{ user, error }}>
            {children}
        </AuthContext.Provider>
    );

};

AuthProvider.propTypes = {
    children: PropTypes.element,
};