import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AlertContext } from '../../../context/AlertContext';
import useFormatterCurrency from '../../../hooks/useFormatterCurrency';
import useFormatDate from '../../../hooks/useFormatDate';
import Stats from '../../../tailwindUI/Stats';
import Badge from '../../../tailwindUI/Badge';
import { getBalanceOverview, getBalanceCharges } from '../../../apiClient/operations/balanceOperations';
import PrimaryButton from '../../../tailwindUI/PrimaryButton';
import { CreditCardIcon, PresentationChartBarIcon, UsersIcon } from '@heroicons/react/24/solid';
import { formatPeso } from '../../../util';
import Tabs from '../../../tailwindUI/Tabs';
import { useMemo } from 'react';
import { Timeline } from '../../billing/Timeline';
import AdditionalService from '../../billing/AdditionalService';
import SecondaryButton from '../../../tailwindUI/SecondaryButton';
import { LinksHistory } from '../../billing/LinksHistory';
import { AccountContext } from '../../../context/AccountContext';
import SkeletonLoader from '../../../tailwindUI/SkeletonLoader';
import { getAditionalServices, getCollectioClient, getTimelineYears } from '../../../apiClient/operations/collectionOperations';
import PaymentsBalance from './PaymentsBalance';
import { getPlanService } from '../../../apiClient/operations/accountsOperations';

const paymentTypes = {
    'unique': 'Pago único',
    'reimbursement': 'Devolución',
    'subscription': 'Suscripción',
    'deferred': 'Pago diferido'
}

const paymentMethods = {
    'credit': 'Tarjeta de crédito',
    'debit': 'Tarjeta de débito',
    'card': 'Tarjeta de crédito/débito',
    'paypal': 'Paypal',
    'cash': 'Efectivo',
    'oxxo': 'Efectivo',
    'transfer': 'Transferencia',
    'spei': 'Transferencia'
}

const periodicityTranslate = {
    "monthly": "mensual",
    "bimonthly": "bimestral",
    "quarterly": "trimestral",
    "biannual": "semestral",
    "yearly": "anual"
}
const changeStringDate = (dateString) => {
    if (dateString != undefined) {
        const dateParts = dateString.split("/");
        return `${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`
    }

}
function BillingInfo({ account, contract, accountId, paymentBalance, setPaymentBalance }) {
    const { formatterCurrency } = useFormatterCurrency();
    const { setAccount } = useContext(AccountContext);
    const history = useHistory();
    const [additionalServices, setAdditionalServices] = useState(null);
    const [links, setLinks] = useState([]);
    const { formatDate } = useFormatDate();
    const { setAlert } = useContext(AlertContext);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingLink, setIsLoadingLinks] = useState(false);
    const [overview, setOverview] = useState(null);
    const [clientInfo, setClientInfo] = useState(null);
    const [plan, setPlan] = useState(null);
    const [portofolio, setPortofolio] = useState(null);
    const [percentage, setPercentage] = useState(0);
    const [openSlideLinks, setOpenSlideLinks] = useState(false);
    const [tabs, setTabs] = useState([
        { id: 1, name: 'Timeline', icon: PresentationChartBarIcon, current: true },
        { id: 2, name: 'Historial de pagos', icon: CreditCardIcon, current: false }
    ]);
    const [stats, setStats] = useState([
        { id: 1, name: 'Saldo Anterior', stat: '' },
        { id: 2, name: 'Cargo del mes', stat: '' },
        { id: 3, name: 'Abonos realizados del mes', stat: '' },
        { id: 4, name: 'Total de deuda', stat: '' }
    ]);

    const [minYear, setMinYear] = useState(null)

    const getCharges = async (accountId, sort) => {
        let charges = await getBalanceCharges(accountId, sort);
        return charges?.data?.map(item => {
            return {
                ...item,
                formatted_paid_at: formatDate(item.payment_date, 'DD/MM/YY HH:mm:ss'),
                formatted_created_at: formatDate(item.created_at, 'DD/MM/YY HH:mm:ss'),
                formatted_amount: formatterCurrency(item.amount / 100),
                translated_type: paymentTypes[item.type],
                translated_method: paymentMethods[item.payment_method_type]
            }
        });
    }

    useEffect(() => {   
        if(!contract) return;
        getTimelineYears(contract?.id)
        .then(response => setMinYear(response.min_year))
        .catch(error => console.log(error))
    }, [contract])

    useEffect(() => {
        if(!accountId) return;

        getPlanService(accountId)
        .then(response => { setPlan(response.data) })
        .catch(error => console.log({planService: error}))
    }, [accountId])

    useEffect(() => {
        if(!accountId) return;
        getBalanceOverview(accountId)
        .then(response => { setOverview(response) })
        .catch(error => {
            console.log(error)
            setAlert({ active: true, type: 'error', message: 'Error al obtener Balance Overview' })
        })
        
    }, [accountId])

    useEffect(() => {
        if(!accountId) return;

        setIsLoadingLinks(true)
        getAditionalServices(accountId).then((response) => {
            setAdditionalServices(response?.data?.filter(service => !service.required))
            setLinks(response?.payment_links)
            setIsLoadingLinks(false)
        }).catch(() => {
            setIsLoadingLinks(false)
            setAdditionalServices([])
            setAlert({ active: true, type: 'error', message: 'Error al obtener Additional Services' })
        })
    }, [accountId]);

    useEffect(() => {
        // if(!overview || !plan || !contract) return;
       
       const convertTimestampToDateString = (timestamp) => {
           const date = new Date(timestamp);
           const day = String(date.getDate()).padStart(2, '0');
           const month = String(date.getMonth() + 1).padStart(2, '0');
           const year = date.getFullYear();
           return `${day}/${month}/${year}`;
        };

        setClientInfo({
            activation_date: changeStringDate(convertTimestampToDateString(contract?.activation_date)),
            valid_from: plan?.valid_from,
            latest_payment_date: changeStringDate(plan?.next_payment?.latest_payment_date),
            next_payment_date: overview?.next_payment_date,
            service_date: overview?.service_date,
        })
    }, [overview, plan, contract])

    useEffect(() => {
        if(!accountId) return;

        setIsLoading(true);
        getCollectioClient(accountId).then((response) => {
            setIsLoading(false);
            setPortofolio(response)
        }).catch((error) => {
            console.log(error)
            setAlert({ active: true, type: 'error', message: 'Error al obtener Collection Client' })
            setIsLoading(false);
        })
    }, [accountId]);

    useEffect(() => {
        const fetchCharges = async () => {
            if (!accountId || !overview) return;
            try {
                const overviewData = account?.account_type == 'taxpayer' ? overview : {}
                const charges = await getCharges(accountId);
                const balance = {
                    overview: overviewData,
                    charges
                };
                if (setPaymentBalance) setPaymentBalance(balance);
            } catch (e) {
                console.log(e);
                setAlert({ active: true, type: 'error', message: 'Error al obtener Balance Charges' })
            }
        }
        fetchCharges()
    }, [accountId, overview]);


    useEffect(() => {
        if (!overview) return;

        setPercentage(Math.round((overview?.amount?.total_paid_amount) * 100 / (overview?.amount?.total_amount)))

    }, [overview]);

    useEffect(() => {
        if (!portofolio) return;
        const lastPeriodAmount = (portofolio?.amount?.previous_amount/ 100) || 0;
        const currentAmount = (portofolio?.amount?.total_amount / 100) || 0;
        const paidAmount = (portofolio?.amount?.paid_amount / 100) || 0;
        const dueAmount = (portofolio?.amount?.due_amount / 100) || 0;
        setStats([
            { id: 1, name: 'Saldo anterior', stat: formatterCurrency(lastPeriodAmount) },
            { id: 2, name: 'Cargo del mes', stat: formatterCurrency(currentAmount) },
            { id: 3, name: 'Abonos realizados del mes', stat: formatterCurrency(paidAmount) },
            { id: 4, name: 'Total de deuda', stat: formatterCurrency(dueAmount) }
        ])

        setPercentage(Math.round((overview?.amount?.total_paid_amount) * 100 / (overview?.amount?.total_amount)))

    }, [portofolio]);


    const renderTab = useMemo(() => {
        switch (tabs.find(tab => tab.current)?.id) {
            case 1: return <Timeline accountId={accountId} minYear={minYear}/>;
            case 2: return <PaymentsBalance account={contract.account} contract={contract} accountId={contract?.account?.id} paymentBalance={paymentBalance} setPaymentBalance={setPaymentBalance} />;
            default: return null;
        }
    }, [tabs, clientInfo, minYear]);


    return (
        <>
            <div className='w-full space-y-4 mb-32 md:mb-16 mt-4'>
                <div className='flex flex-col gap-4'>
                    <Stats items={stats} cols='sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-4' isLoading={isLoading} />

                </div>

                <div className="overflow-hidden bg-white shadow rounded-lg border border-gray-200">
                    <div className="px-4 py-5 sm:px-6 flex justify-between">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Deudas según contrato del cliente</h3>
                    </div>
                    <div className="border-t flex flex-col gap-4 border-gray-200 px-4 py-5 sm:px-6">
                        <div className='flex w-full justify-start'>
                            <div className='flex flex-col items-start'>
                                {isLoading ?
                                    <SkeletonLoader /> :
                                    <p className="text-xl font-semibold text-gray-900">
                                        {formatPeso(overview ? overview?.amount?.total_amount / 100 : 0)}
                                    </p>
                                }

                                <p className={`truncate text-sm font-medium text-gray-500`}>
                                    Deuda según contrato
                                </p>
                            </div>
                        </div>
                        {isLoading ?
                            <SkeletonLoader /> :
                            <div className="overflow-hidden rounded-full bg-red-500">
                                <div className="transition-all duration-300 h-2 rounded-full bg-green-500"
                                    style={{
                                        maxWidth: `${percentage}%`
                                    }} />
                            </div>
                        }

                        <div className='flex w-full justify-between mb-4'>
                            <div className='flex flex-col items-end'>
                                <div className='flex w-full items-center gap-2'>
                                    {isLoading ?
                                        <SkeletonLoader /> :
                                        <><div className='w-4 h-4 rounded-full bg-green-500'></div>
                                            <p className="text-xl font-semibold text-gray-900">
                                                {formatPeso(overview ? overview?.amount?.total_paid_amount / 100 : 0)}
                                            </p></>
                                    }

                                </div>

                                <p className={`truncate text-sm font-medium text-gray-500`}>
                                    Monto pagado
                                </p>
                            </div>
                            <div className='flex flex-col items-end'>
                                <div className='flex w-full items-center justify-end gap-2'>
                                    {isLoading ?
                                        <SkeletonLoader /> :
                                        <><div className='w-4 h-4 rounded-full bg-red-500'></div>
                                            <p className="text-xl font-semibold text-gray-900">
                                                {formatPeso(overview ? overview?.amount?.total_due_amount / 100 : 0)}
                                            </p></>
                                    }

                                </div>

                                <p className={`truncate text-sm font-medium text-gray-500`}>
                                    Restante del contrato
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="border-t flex flex-col gap-4 border-gray-200 px-4 py-5 sm:px-6">
                        <div className='flex w-full items-start md:flex-row flex-col gap-4 md:gap-0 md:justify-between mb-4'>
                            <div className='flex flex-col gap-2'>
                                <p className={`truncate text-sm font-medium text-gray-500`}>
                                    Total otorgado en descuentos
                                </p>
                                <div className='flex w-full md:items-center gap-2'>
                                    {isLoading ?
                                        <SkeletonLoader /> :
                                        <><UsersIcon className="h-6 w-6 text-gray-900" aria-hidden="true" />
                                            <p className="text-xl font-semibold text-gray-900">
                                                {formatPeso(7401)}
                                            </p></>
                                    }

                                </div>
                            </div>
                            <div className='flex flex-col gap-2 items-start md:items-end'>
                                <p className={`truncate text-sm font-medium text-gray-500`}>
                                    Contribuyente desde
                                </p>
                                {isLoading ?
                                    <SkeletonLoader /> :
                                    <p className="text-xl font-semibold text-gray-900">
                                        {formatDate(clientInfo?.activation_date, "DD MMMM YYYY")}
                                    </p>
                                }

                            </div>
                        </div>
                    </div>

                </div>

                <div className="overflow-hidden bg-white shadow rounded-lg border border-gray-200">
                    <div className="flex md:flex-row flex-col gap-4 px-4 sm:px-6">
                        <div className='flex w-full md:w-1/2 flex-col pr-4 py-5 border-b md:border-r border-gray-200 gap-2'>
                            <div className='flex justify-between'>
                                <h3 className="font-bold text-lg text-gray-900">{plan?.name}</h3>
                                {isLoading ?
                                    <div className='w-1/5'><SkeletonLoader /></div> :
                                    < >
                                       <Badge text={plan?.status || "Sin plan"} />     
                                    </>
                                }

                            </div>
                            <div className='w-full'>
                                {isLoading ?
                                    <div className='w-1/2'><SkeletonLoader /></div> :
                                    < >
                                        <span className={`inline-block text-5xl`}>
                                            {formatPeso(plan ? plan?.price?.unit_price / 100 : 0)}
                                        </span>
                                        <span className='text-base inline-block'>
                                            /{periodicityTranslate["monthly"]}
                                        </span>
                                    </>
                                }
                            </div>
                            {isLoading ?
                                <div className='w-1/3'><SkeletonLoader /></div> :
                                < >
                                    <span className='text-base font-light'>
                                        descuento:  {formatPeso(plan?.price?.discount > 0 ? (plan?.price?.discount_amount /100) : 0)}
                                    </span>
                                </>
                            } 
                        </div>
                        <div className='flex  w-full md:w-1/2 flex-col py-5 gap-2'>
                            <div className='flex justify-between'>
                                
                                <h3 className="font-bold text-lg text-gray-900">Saldo deudor SCM</h3>

                                {isLoading ?
                                    <div className='w-1/5'><SkeletonLoader /></div> :
                                    < >

                                        <Badge text={portofolio?.status} />
                                    </>
                                }
                            </div>
                            <div className='w-full'>
                                {isLoading ?
                                    <div className='w-1/2'><SkeletonLoader /></div> :
                                    < >
                                        <span className={`inline-block text-5xl`}>
                                            {formatPeso(portofolio ? portofolio?.amount?.total_amount / 100 : 0)}
                                        </span>
                                    </>
                                }

                            </div>
                            {/* {isLoading ?
                                <div className='w-1/2'><SkeletonLoader /></div> :
                                < >
                                    <span className='text-base font-light'>
                                        Meses de adeudo:  3
                                    </span>
                                </>
                            } */}

                        </div>
                    </div>
                    <div className="border-t flex flex-col gap-4 border-gray-200 px-4 py-5 sm:px-10">
                        <div className='flex w-full md:flex-row flex-col gap-4 md:gap-0 md:justify-between mb-4'>
                            <div className='flex flex-col gap-1 items-center'>
                                <p className={`truncate text-sm font-medium text-gray-500`}>
                                    Cobertura inicial:
                                </p>
                                {isLoading ?
                                    <SkeletonLoader /> :
                                    <p className="text-base font-semibold text-gray-900">

                                        {formatDate(clientInfo?.valid_from, "DD MMMM YYYY")}
                                    </p>
                                }

                            </div>
                            <div className='flex flex-col gap-1 items-center'>
                                <p className={`truncate text-sm font-medium text-gray-500`}>
                                    Cobertura final:
                                </p>
                                {isLoading ?
                                    <SkeletonLoader /> :
                                    <p className="text-base font-semibold text-gray-900">
                                        {formatDate(clientInfo?.service_date, "DD MMMM YYYY")}
                                    </p>
                                }

                            </div>
                            <div className='flex flex-col gap-1 items-center'>
                                <p className={`truncate text-sm font-medium text-gray-500`}>
                                    Último mes pagado:
                                </p>
                                {isLoading ?
                                    <SkeletonLoader /> :
                                    <p className="text-base font-semibold text-gray-900">
                                        {formatDate(clientInfo?.latest_payment_date, "MMMM YYYY")}
                                    </p>
                                }

                            </div>
                            <div className='flex flex-col gap-1 items-center'>
                                <p className={`truncate text-sm font-medium text-gray-500`}>
                                    Próximo pago:
                                </p>
                                {isLoading ?
                                    <SkeletonLoader /> :
                                    <p className="text-base font-semibold text-gray-900">
                                        {formatDate(clientInfo?.next_payment_date, "DD MMMM YYYY")}
                                    </p>
                                }

                            </div>
                        </div>
                    </div>
                </div>

                <Tabs items={tabs} setItems={setTabs} />
                {renderTab}

                {additionalServices?.length > 0 && !isLoading && (
                    <div className='mt-5'>
                        <h2 className='text-gray-900 font-medium text-xl flex gap-2 items-center'>
                            Servicios Adicionales
                        </h2>
                        <div className='space-y-4 mt-2'>
                            {additionalServices?.map((service) => (
                                <AdditionalService
                                    key={service.id}
                                    service={service}
                                    onAdvancePaymentClick={() => { }} />
                            ))}
                        </div>
                    </div>
                )}
            </div>
            <div className="fixed left-0 w-full flex justify-end bottom-0 bg-white shadow rounded-lg border border-gray-200">
                <div className="md:w-1/2 w-full px-4 py-4 flex flex-col sm:flex-row justify-end gap-4">
                    <SecondaryButton
                        type="button"
                        isFullWidth
                        onClick={() => setOpenSlideLinks(true)}
                    >
                        Historial de links de cobro
                    </SecondaryButton>
                    <PrimaryButton
                        type='submit'
                        isFullWidth
                        onClick={() => {
                            setAccount(account);
                            history.push({
                                state: accountId,
                                pathname: `/portfolio/collecting/createLink`,
                            })
                        }}
                    >
                        Generar link de cobro
                    </PrimaryButton>
                </div>
            </div>

            <LinksHistory isLoading={isLoadingLink} links={links} openSlideLinks={openSlideLinks} setOpenSlideLinks={setOpenSlideLinks} />
        </>
    )
}

BillingInfo.propTypes = {
    account: PropTypes.object,
    contract: PropTypes.object,
    accountId: PropTypes.string,
    paymentBalance: PropTypes.object,
    setPaymentBalance: PropTypes.func,
}

export default BillingInfo;