import apiClient from '../apiClient';
import { apiBaseUrl } from '../../config';

export const searchBalances = async (page=1, limit=10, search="", filters="", sort="") => {
    const escapedSearch = encodeURIComponent(search);
    return apiClient.get(`${apiBaseUrl}/balance-taxpayer/?page=${page}&limit=${limit}&q=wildCard~${escapedSearch!="null" ? escapedSearch:''}${filters || ''}&${ sort || 'field=updated_at&order=-1'}`)
    .then(response => { return response })
}


export const getReport = async (search="", filters="", sort="") => {
    const escapedSearch = encodeURIComponent(search);
    var wildcard = escapedSearch!="null" ? "wildCard~"+escapedSearch:""
    return apiClient.get(`${apiBaseUrl}/balance-taxpayer/report?q=${wildcard}${filters || ''}&${ sort || 'field=update_date&order=-1'}`)
    .then(response => { return response })
}

export const getUpdatedReport = async (page=1, limit=10, search="", filters="", sort="") => {
    const escapedSearch = encodeURIComponent(search);
    return apiClient.get(`${apiBaseUrl}/collections-report/?page=${page}&limit=${limit}&q=wildCard~${escapedSearch!="null" ? escapedSearch:''}${filters || ''}&${ sort || 'field=update_date&order=-1'}`)
    .then(response => { return response })
}

export const getReportCSV = async (search="", filters="", sort="", request) => {
    const escapedSearch = encodeURIComponent(search);
    var wildcard = escapedSearch!="null" ? "wildCard~"+escapedSearch:""
    return apiClient.post(`${apiBaseUrl}/collections-report/send?q=${wildcard}${filters || ''}&${ sort || 'field=update_date&order=-1'}`, request)
    .then(response => { return response })
}

export const sendRefund = async (charge_id, request) => {
    return apiClient.post(`${apiBaseUrl}/balance-taxpayer/refund/${charge_id}`, request)
    .then(response => { return response });
}

export const sendReimbursment = async (account_id, request) => {
    return apiClient.post(`${apiBaseUrl}/balance-taxpayer/reimburse/${account_id}`, request)
    .then(response => { return response });
}