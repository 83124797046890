export const formatPeso = (amount) => {
    const minimumFractionDigits = amount % 1 == 0 ? 0 : 2;
    const maximumFractionDigits = 2;
    const formatterPeso = new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN',
        minimumFractionDigits: minimumFractionDigits,
        maximumFractionDigits: maximumFractionDigits
    });
    return formatterPeso.format(amount)
};