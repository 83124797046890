import apiClient from '../apiClient';
import { apiBaseUrl } from '../../config';

export const getTaxStatusList = async (accountId) => {
    return apiClient.get(`${apiBaseUrl}/fiscal/tax/status/get/${accountId}`, {})
    .then(response => { return response });
}

export const getTaxStatus = async (accountId, taxStatusId) => {
    return apiClient.get(`${apiBaseUrl}/fiscal/tax/status/get/${accountId}/${taxStatusId}`, {})
    .then(response => { return response });
}

export const getTaxComplianceList = async (accountId) => {
    return apiClient.get(`${apiBaseUrl}/fiscal/tax/compliance/get/${accountId}`, {})
    .then(response => { return response });
}

export const getTaxCompliance = async (accountId, taxComplianceId) => {
    return apiClient.get(`${apiBaseUrl}/fiscal/tax/compliance/get/${accountId}/${taxComplianceId}`, {})
    .then(response => { return response });
}

export const updateTaxStatus = async (accountId, sendTo) => {
    return apiClient.post(`${apiBaseUrl}/fiscal/tax/status/status/${accountId}`, {
        send_to: sendTo
    })
    .then(response => { return response });
}

export const updateTaxCompliance = async (accountId, sendTo) => {
    return apiClient.post(`${apiBaseUrl}/fiscal/tax/compliance/status/${accountId}`, {
        send_to: sendTo
    })
    .then(response => { return response });
}

export const updateTaxStatusFrequency = async (accountId, data) => {
    return apiClient.put(`${apiBaseUrl}/fiscal/tax/status/update/frequency/${accountId}`, data)
    .then(response => { return response });
}

export const updateTaxComplianceFrequency = async (accountId, data) => {
    return apiClient.put(`${apiBaseUrl}/fiscal/tax/compliance/update/frequency/${accountId}`, data)
    .then(response => { return response });
}

export const getAccountRegimes = async (accountId) => {
    return apiClient.get(`${apiBaseUrl}/fiscal/taxpayer/regimes/get/admin/${accountId}`)
    .then(response => { return response });
}