import apiClient from '../apiClient';
import { apiBaseUrl } from '../../config';


export const updateNextDate = async (body) => {
    const url = `${apiBaseUrl}/fiscal/fiscal-documents/dates`;
    // const url = `${apiBaseUrl}/fiscal/taxpayers/update-next-date/admin`;
    return apiClient.put(url, body)
    .then(response => { return response });
}

export const updateTaxStatus = async () => {
    const url = `${apiBaseUrl}/fiscal/fiscal-documents/tax-status`;
    return apiClient.put(url)
    .then(response => { return response });
}

export const updateTaxCompliance = async () => {
    const url = `${apiBaseUrl}/fiscal/fiscal-documents/tax-compliance`;
    return apiClient.put(url)
    .then(response => { return response });
}