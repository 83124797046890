import React from 'react';
import PropTypes from 'prop-types';

function Dropdown( {defaultTitle, data, dropChange, className } ) {
    const [values, setValues]=React.useState(defaultTitle)
    const handler =(item)=>{
        setValues(item)
        dropChange(item)
    }

  return (
    <div className={`${className ? className : 'mt-2.5'}`}>
        <select className="form-select cursor-pointer block w-full py-2 px-1.5 text-base text-gray-700 bg-white border border-solid border-light-gray rounded-5 transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
        value={values}
        onChange={(values)=>{handler(values.target.value)}}>
            {data.map((options, index)=>{
            return(
                <option value={options} key={index}>
                    {options}
                </option>)
            })}
        </select>
    </div>
  )
}

Dropdown.propTypes = {
  defaultTitle: PropTypes.any,
  data: PropTypes.array,
  dropChange: PropTypes.func,
  className: PropTypes.string
}

export default Dropdown