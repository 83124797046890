import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign, faPercent, faPenToSquare, faTag } from '@fortawesome/free-solid-svg-icons';
import Button from './Button';
import Modal from './Modal';
import PropTypes from 'prop-types';
import useFormatterCurrency from './useFormatterCurrency';
import ResumeAmounts from './ResumeAmounts';

function PacksServices({ services, setServices, resumeAmounts, setResumeAmounts, packActive, setPackActive }) {
    const { formatterCurrency } = useFormatterCurrency();
    const [openModal, setOpenModal] = useState(false);
    const [servicesPack, setServicesPack] = useState([]);
    const [resumePack, setResumePack] = useState({});

    useEffect(() => {
        setServicesPack(services);
        setResumePack(resumeAmounts);
    }, []);

    const calculateDiscount = discount => {
        const totalDiscount = discount.map( service => service.pack_discount_amount).reduce((prev, curr) => (isNaN(prev) ? prev = 0 : prev) + (isNaN(curr) ? curr = 0 : curr), 0);
        const total = discount.map( service => service.pack_total).reduce((prev, curr) => (isNaN(prev) ? prev = 0 : prev) + (isNaN(curr) ? curr = 0 : curr), 0 );
        setResumePack({...resumePack, totalPack: total, totalPackDiscount: totalDiscount });
    }

    const calculateDiscountTotal = discountTotal => {
        const totalDiscount = discountTotal.map( service => service.pack_discount_amount).reduce((prev, curr) => (isNaN(prev) ? prev = 0 : prev) + (isNaN(curr) ? curr = 0 : curr), 0);
        const total = discountTotal.map( service => service.pack_total).reduce((prev, curr) => (isNaN(prev) ? prev = 0 : prev) + (isNaN(curr) ? curr = 0 : curr), 0 );
        setResumeAmounts({...resumeAmounts, totalPack: total, totalPackDiscount: totalDiscount });
    }

    const calculateTotalAmountPerService = (index, modifier) => {
        const totalAmount = modifier.map( service => {
        if (index == service.index) {
            return {
            ...service,
            pack_total: (service.pack_discount_option == 'direct' ? ((service.amount * service.quantity) - service.pack_discount) : ((service.amount * service.quantity) - ((service.amount * service.quantity) * (service.pack_discount / 100)))),
            pack_discount_amount: service.pack_discount_option == 'direct' ? parseFloat(service.pack_discount) : ((service.amount * service.quantity) * (service.pack_discount / 100)),
            pack_subtotal: (service.amount * service.quantity)
            }
        }
        return service
        });
        setServicesPack(totalAmount);
        calculateDiscount(totalAmount);
    }

    const handleUpdateDiscount = (discount, index) => {
        const modifiedDiscount = servicesPack.map( service => {
        if (index == service.index) {
            return {
                ...service,
                pack_discount: isNaN(discount) ? '' : parseFloat(discount)
            }
        }
        return service
        });
        setServicesPack(modifiedDiscount);
        calculateTotalAmountPerService(index, modifiedDiscount);
    }

    const handleChangeDiscountOption = (option, index) => {
        const modifiedOption = servicesPack.map( service => {
            if(service.index == index){
                return {
                    ...service,
                    pack_discount_option: option == 'direct' ? 'percent' : 'direct'
                }
            }
            return service
        });
        setServicesPack(modifiedOption);
        calculateTotalAmountPerService(index, modifiedOption);
    }

    const saveData = () => {
        setServices(servicesPack);
        calculateDiscountTotal(servicesPack);
    }

    const handleDiscountPack = () => {
        calculateDiscount(servicesPack);
        setOpenModal(!openModal);
    }

    const handleAccept = () => {
        saveData();
        setPackActive(true);
        setOpenModal(!openModal);
    }

    return (
        <>
            <div className='w-full border border-v2-gray-border-tables rounded-10 bg-white'>
                { packActive && resumeAmounts.totalPackDiscount > 0 ?
                    <div className='w-full xs:px-2 md:px-4 py-2 border-b border-v2-gray-border-tables flex items-center'>
                        <div className="xs:w-11 md:w-10">
                            <div className='rounded-full w-8 h-8 bg-v2-red-discount-tag text-1.5xl text-white flex justify-center items-center'>
                                <FontAwesomeIcon icon={ faTag } className='leading-3 pl-0.5 pt-[1px]' />
                            </div>
                        </div>
                        <div className='xs:w-8/12 md:w-7/12 text-xl font-semibold text-v2-input-text'>
                            Precio paquete
                        </div>
                        <div className="xs:w-4/12 md:w-5/12 justify-end items-center text-v2-yellow-edit-info">
                            <div className="w-full flex items-center justify-end">
                                <label className="flex items-center justify-end cursor-pointer" onClick={ handleDiscountPack }>
                                    <span className="text-2xl text-v2-yellow-edit-info leading-3 flex items-center"><FontAwesomeIcon icon={ faPenToSquare } /> 
                                        
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                    :
                    <div className='w-full px-9 pb-4 pt-6 text-center text-xl text-button-orange leading-7 opacity-60 font-bold'>
                        ¿Deseas activar el <span className='block'>precio por paquete?</span>
                    </div>
                }
                { packActive && resumeAmounts.totalPackDiscount > 0 ?
                    <div className='w-full'>
                        <div className='w-full bg-v2-gray-packs-services px-4 py-3 flex items-center text-v2-input-text text-xl font-semibold'>
                            <div className='xs:w-7/12 md:w-2/3'>
                                Ticket
                            </div>
                            <div className='xs:w-5/12 md:w-1/3'>
                                Total
                            </div>
                        </div>
                        { services.map((service, i) => (
                            <div key={ i } className="w-full">
                                <div className="w-full xs:px-3 md:px-4 py-2 border-t border-v2-gray-border-tables flex items-center">
                                    <div className="xs:w-7/12 md:w-2/3 text-v2-text-bar-steps font-semibold">
                                        { service.name }
                                    </div>
                                    <div className="xs:w-5/12 md:w-1/3 flex items-center">
                                        { service.pack_total - (service.amount * service.quantity) == 0 ?
                                            <div className="xs:w-5/6 md:w-2/3 text-base">
                                                <div className="text-v2-input-text opacity-60">
                                                    { formatterCurrency(service.amount * service.quantity) }
                                                </div>
                                            </div>
                                        :
                                            <div className="xs:w-5/6 md:w-2/3 text-base">
                                                <div className="text-v2-red-discount-tag leading-6">
                                                    { formatterCurrency(service.pack_total) } <FontAwesomeIcon icon={ faTag } />
                                                </div>
                                                <div className="text-v2-input-text opacity-60 line-through">
                                                    { formatterCurrency(service.amount * service.quantity) }
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        ))}
                        <ResumeAmounts subtotal={ resumeAmounts.subtotalAmount } discount={ resumeAmounts.totalPackDiscount } totalAmount={services.map( service => service.pack_total ).reduce((prev, curr) => prev + curr, 0)} amountServices={ resumeAmounts.numberServices } inside={true} bg='bg-v2-red-discount-tag' />
                    </div>
                    :
                    <div className='w-full my-5 pb-4 flex justify-center'>
                        <Button heigth='h-12' width='w-2/3' bg='bg-white' border='border-v2-blue-text-login' textColor='text-v2-blue-text-login' onClick={ () => setOpenModal(!openModal) }>
                            ACTIVAR
                        </Button>
                    </div>
                }
            </div>
            <Modal modalOpen={openModal} modalOpenChange={setOpenModal} hasTitle={ false }>
                <div className='w-full '>
                    <div className='w-full xs:px-2 md:px-4 py-2 flex items-center'>
                        <div className="xs:w-11 md:w-10">
                            <div className='rounded-full w-8 h-8 bg-v2-red-discount-tag text-1.5xl text-white flex justify-center items-center'>
                                <FontAwesomeIcon icon={ faTag } className='leading-3 pl-0.5 pt-[1px]' />
                            </div>
                        </div>
                        <div className='xs:w-8/12 md:w-7/12 text-xl font-semibold text-v2-input-text'>
                            Precio paquete
                        </div>
                    </div>
                </div>
                <div className="w-full xs:hidden md:block">
                    <div className='w-full md:px-2 lg:px-7 py-2 bg-v2-gray-packs-services'>
                        <div className='w-full flex items-center text-v2-input-text font-semibold gap-2'>
                            <div className='w-24 text-base'>
                                CANTIDAD
                            </div>
                            <div className='w-3/12 text-base'>
                                SERVICIO
                            </div>
                            <div className='w-4/12 text-base'>
                                DESCUENTO
                            </div>
                            <div className='w-4/12 flex gap-2 text-base'>
                                <div className='w-1/2'>
                                    PRECIO REGULAR
                                </div>
                                <div className='w-1/2'>
                                    PRECIO PAQUETE
                                </div>
                            </div>
                        </div>
                    </div>
                    { servicesPack.map((service, i) => (
                        <div key={i} className='w-full md:px-2 lg:px-7 py-3 border-b last:border-none border-dashed border-v2-gray-border-tables'>
                            <div className='w-full flex items-center text-v2-text-bar-steps gap-2'>
                                <span className='w-24 text-sm'>
                                    { service.quantity }
                                </span>
                                <span className='w-3/12 text-sm'>
                                    { service.name }
                                </span>
                                <div className='w-4/12'>
                                    <div className="w-full text-base text-v2-text-bar-steps outline-none flex py-1">
                                        <div className={`${service.pack_discount_option == 'percent' ? 'bg-v2-blue-text-login text-white' : 'bg-white text-v2-gray-text-amount cursor-pointer border-l border-y border-v2-border-time' } w-1/5 text-2xl leading-3 rounded-l-md flex items-center justify-center py-2`} onClick={ () => handleChangeDiscountOption('direct', service.index) }>
                                            <FontAwesomeIcon icon={ faPercent } />
                                        </div>
                                        <div className={`${service.pack_discount_option == 'direct' ? 'bg-v2-blue-text-login text-white z-10' : 'bg-white text-v2-gray-text-amount cursor-pointer border-y border-r border-v2-border-time z-10'} w-1/5 text-2xl leading-3 flex items-center justify-center py-2`} onClick={ () => handleChangeDiscountOption('percent', service.index) }>
                                            <FontAwesomeIcon icon={ faDollarSign } />
                                        </div>
                                        <div className={`${ service.pack_discount_option == 'percent' && service.pack_discount > 100 || service.pack_discount_option == 'direct' && service.pack_discount > (service.amount * service.quantity) || service.pack_discount < 0 || isNaN(service.pack_discount) ? 'border-2 border-v2-red-error-services-selected z-20 pl-2 -ml-0.5' : 'border-r border-y border-v2-border-time -ml-1.5 pl-3' } w-3/5 flex items-center rounded-r-md`}>
                                            <span className="text-v2-gray-title-client pl-2 text-base">{service.pack_discount_option == 'direct' ? '$' : '%'}</span>
                                            <input 
                                                type='number'
                                                inputMode='numeric'
                                                value = { isNaN(service.pack_discount) ? '' : service.pack_discount }
                                                onInput= { e => handleUpdateDiscount(e.target.value, service.index) }
                                                className='w-full outline-none text-base pl-2'
                                                />
                                        </div>
                                        
                                    </div>
                                    { service.pack_discount_option == 'percent' && service.pack_discount > 100 && <span className='block text-sm text-v2-red-error-services-selected'>¡Ojo!, el descuento máximo es el total del servicio</span>}
                                    { service.pack_discount_option == 'direct' && service.pack_discount > (service.amount * service.quantity) && <span className='block text-sm text-v2-red-error-services-selected'>¡Ojo!, el descuento máximo es el total del servicio</span>}
                                    { service.pack_discount < 0 || isNaN(service.pack_discount) && <span className='block text-sm text-v2-red-error-services-selected'>Ingresa una cantidad válida.</span>}
                                    { service.pack_discount < 0 && <span className='block mt-1 text-sm text-v2-red-error-services-selected'>¡Ojo!, no puedes ingresar descuentos negativos.</span>}
                                </div>
                                <div className='w-4/12 flex gap-2 text-base'>
                                    <div className={`${ service.pack_discount > 0 ? 'line-through' : '' } w-1/2 font-normal opacity-50`}>
                                        { formatterCurrency(service.amount * service.quantity) }
                                    </div>
                                    <div className='w-1/2 text-button-orange font-semibold'>
                                        { isNaN(service.pack_total) && isNaN(service.pack_discount) ? 0 : isNaN(service.pack_total) && isNaN(service.pack_discount) ? formatterCurrency(service.amount) : isNaN(service.amount) ? formatterCurrency(-service.pack_discount) : formatterCurrency(service.pack_total)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                    }
                    <div className='w-full px-7 py-4 flex items-center text-xl bg-button-orange text-white -mt-0.5'>
                        <div className='w-1/2 font-semibold'>
                            TOTAL
                        </div>
                        <div className='w-1/2 flex justify-end items-center font-bold'>
                            { resumePack.totalPackDiscount > 0 && <span className='text-base pr-4 pt-0.5 line-through font-normal'>{ formatterCurrency(resumePack.subtotalAmount) }</span>}
                            { formatterCurrency(resumePack.totalPack) }
                        </div>
                    </div>
                    <div className='w-full mt-8 mb-5 px-7 py-4 flex items-center'>
                        <div className='w-1/2 flex justify-end text-v2-blue-links underline opacity-60 cursor-pointer' onClick={ () => setOpenModal(!openModal) }>
                            Cancelar
                        </div>
                        <div className='w-1/2 flex justify-end'>
                            <Button heigth='h-12' width='w-3/4' bg='bg-v2-blue-text-login' disabled={ resumePack.totalPack > 0 && servicesPack.map(service => service).every(x => x.pack_discount >= 0) && servicesPack.map(service => service).every(i => i.pack_total >= 0) ? false : true }  onClick={ handleAccept }>
                                Aceptar
                            </Button>
                        </div>
                    </div>
                </div>
                <div className='w-full xs:block md:hidden mb-3'>
                    <div className='w-full px-3 py-4 bg-v2-gray-packs-services'>
                        <div className='w-full flex items-center text-v2-input-text font-semibold gap-1'>
                            <div className='w-5/12 text-base'>
                                SERVICIO
                            </div>
                            <div className='w-7/12 text-base'>
                                DESCUENTO
                            </div>
                        </div>
                    </div>
                    <div className='w-full overflow-auto h-5/6 max-h-[350px]'>
                        { servicesPack.map((service, i) => (
                            <div key={i}>
                                <div className='w-full px-3 py-4 border-b border-dashed border-v2-gray-border-tables'>
                                    <div className='w-full flex items-center text-v2-text-bar-steps gap-1'>
                                        <div className='w-5/12 text-sm'>
                                            { service.name }
                                        </div>
                                        <div className='w-7/12'>
                                            <div className="w-full text-base text-v2-text-bar-steps outline-none flex">
                                                <div className={`${service.pack_discount_option == 'percent' ? 'bg-v2-blue-text-login text-white' : 'bg-white text-v2-gray-text-amount cursor-pointer border-l border-y border-v2-border-time' } w-1/6 text-2xl leading-3 rounded-l-md flex items-center justify-center py-2`} onClick={ () => handleChangeDiscountOption('direct', service.index) }>
                                                    <FontAwesomeIcon icon={ faPercent } />
                                                </div>
                                                <div className={`${service.pack_discount_option == 'direct' ? 'bg-v2-blue-text-login text-white z-10' : 'bg-white text-v2-gray-text-amount cursor-pointer border-y border-r border-v2-border-time z-10'} w-1/6 text-2xl leading-3 flex items-center justify-center py-2`} onClick={ () => handleChangeDiscountOption('percent', service.index) }>
                                                    <FontAwesomeIcon icon={ faDollarSign } />
                                                </div>
                                                <div className={`${ service.pack_discount_option == 'percent' && service.pack_discount > 100 || service.pack_discount_option == 'direct' && service.pack_discount > (service.amount * service.quantity) || service.pack_discount < 0 || isNaN(service.pack_discount) ? 'border-2 border-v2-red-error-services-selected z-20 pl-2 -ml-0.5' : 'border-r border-y border-v2-border-time -ml-1.5 pl-3' } w-4/6 flex items-center rounded-r-md`}>
                                                    <span className="text-v2-gray-title-client pl-2 text-base">{service.pack_discount_option == 'direct' ? '$' : '%'}</span>
                                                    <input 
                                                        type='number'
                                                        inputMode='numeric'
                                                        value = { isNaN(service.pack_discount) ? '' : service.pack_discount }
                                                        onInput= { e => handleUpdateDiscount(e.target.value, service.index) }
                                                        className='w-full outline-none text-base pl-2'
                                                        />
                                                </div>
                                            </div>
                                            { service.pack_discount_option == 'percent' && service.pack_discount > 100 && <span className='block text-xs text-v2-red-error-services-selected'>¡Ojo!, el descuento máximo es el total del servicio</span>}
                                            { service.pack_discount_option == 'direct' && service.pack_discount > (service.amount * service.quantity) && <span className='block text-xs text-v2-red-error-services-selected'>¡Ojo!, el descuento máximo es el total del servicio</span>}
                                            { service.pack_discount < 0 || isNaN(service.pack_discount) && <span className='block text-xs text-v2-red-error-services-selected'>Ingresa una cantidad válida.</span>}
                                            { service.pack_discount < 0 && <span className='block mt-1 text-xs text-v2-red-error-services-selected'>¡Ojo!, no puedes ingresar descuentos negativos.</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className='w-full flex border-b border-v2-gray-border-tables '>
                                    <div className='w-1/3 border-r border-dashed border-v2-gray-border-tables text-center text-xs text-v2-text-bar-steps py-2'>
                                        Cantidad
                                        <span className='block text-sm'>
                                            { service.quantity }
                                        </span>
                                    </div>
                                    <div className='w-1/3 text-center text-xs text-v2-text-bar-steps py-2'>
                                        Precio regular
                                        <span className={`${ service.pack_discount > 0 ? 'line-through' : '' } block text-sm opacity-50`}>
                                            { formatterCurrency(service.quantity * service.amount) }
                                        </span>
                                    </div>
                                    <div className='w-1/3 border-l border-dashed border-v2-gray-border-tables text-center text-xs text-v2-text-bar-steps py-2'>
                                        Precio paquete
                                        <span className='block text-sm text-button-orange text-sm font-semibold'>
                                            { isNaN(service.pack_total) && isNaN(service.pack_discount) ? 0 : isNaN(service.pack_total) && isNaN(service.pack_discount) ? formatterCurrency(service.amount) : isNaN(service.amount) ? formatterCurrency(-service.pack_discount) : formatterCurrency(service.pack_total)}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div className='w-full flex items-center px-4 py-3 bg-button-orange text-white'>
                            <div className='w-1/2 font-semibold text-xl'>
                                TOTAL
                            </div>
                            <div className='w-1/2 flex justify-end font-bold text-xl'>
                            { resumePack.totalPackDiscount > 0 && <span className='text-sm pr-4 pt-0.5 line-through font-normal'>{ formatterCurrency(resumePack.subtotalAmount) }</span>}
                                { formatterCurrency(resumePack.totalPack) }
                            </div>
                        </div>
                        <div className='w-full mt-3 mb-1'>
                            <div className='w-full text-center text-v2-blue-links underline opacity-60 cursor-pointer pb-2' onClick={ () => setOpenModal(!openModal) }>
                                Cancelar
                            </div>
                            <div className='w-full px-3 my-1'>
                                <Button heigth='h-12' bg='bg-v2-blue-text-login' disabled={ resumePack.totalPack > 0 && servicesPack.map(service => service).every(x => x.pack_discount >= 0) && servicesPack.map(service => service).every(i => i.pack_total >= 0) ? false : true } onClick={ handleAccept }>
                                    Aceptar
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

PacksServices.propTypes = {
    services: PropTypes.any,
    setServices: PropTypes.any,
    resumeAmounts: PropTypes.any, 
    setResumeAmounts: PropTypes.any,
    id: PropTypes.any,
    packActive: PropTypes.any,
    setPackActive: PropTypes.any
}

export default PacksServices