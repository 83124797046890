import React from 'react';
import { faCalendar, faCircle } from '@fortawesome/free-solid-svg-icons';
import useFormatDate from './useFormatDate';
import PropTypes from 'prop-types';
import HeadersDataTables from './HeadersDataTables';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useGetStatusExpired from './useGetStatusExpired';

function ServiceValidity({ date }) {
    const { formatDate } = useFormatDate();
    const { getStatusExpired } = useGetStatusExpired();
    return (
        <div className="w-full border border-v2-gray-border-tables bg-white rounded-10">
            <HeadersDataTables icon={faCalendar} text="Vigencia" background={true} padding={0.5} bgColor="bg-v2-blue-icons" textColor="text-white" />
            <div className="w-full px-4 py-2">
                <div className='w-full py-1 text-xs font-bold text-v2-gray-title-client'>
                    Vigencia ahora:
                    <span className={`${(() => {
                        if (getStatusExpired(date) == 'expired') return 'text-v2-red-expired-date';
                        if (getStatusExpired(date) == 'expires-soon') return 'text-button-orange';
                        return getStatusExpired(date) == 'up-to-date' ? 'text-v2-green-status' : '';
                    })()} text-base font-normal block pt-2`}>
                        <FontAwesomeIcon icon={faCircle} className="text-sm leading-3 pr-1 pb-[0.5px]" /> {formatDate(date, 'DD [de] MMMM YYYY')}
                    </span>
                </div>
            </div>
            {/* <div className="w-full px-4 py-2">
                <div className='w-full py-1 text-xs font-bold text-v2-gray-title-client'>
                    Es Renovación:
                    <span className={`text-base font-normal block pt-2`}>
                        {isRenewal ? 'Sí': 'No'}
                    </span>
                </div>
            </div> */}
        </div>
    )
}

ServiceValidity.propTypes = {
    date: PropTypes.any,
    // isRenewal: PropTypes.any
}

export default ServiceValidity