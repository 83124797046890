import { faArrowLeftLong, faCircleCheck, faCode, faCopy, faEllipsisVertical, faFileCirclePlus, faTools, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect, Fragment } from 'react';
import Nav from '../../hooks/Nav';
import SecondaryButton from '../../tailwindUI/SecondaryButton';
import SecondaryLoader from '../../hooks/SecondaryLoader';
import Notification from '../../tailwindUI/Notification';
import { Menu, Transition } from '@headlessui/react';
import SelectCustom from '../../tailwindUI/SelectCustom';
import ButtonOrange from '../../tailwindUI/ButtonOrange';

function CreateTicket() {
    const user = JSON.parse(localStorage.getItem('user')) || {};
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }
    const [secondaryLoader, setSecondaryLoader] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [currentBoard, setCurrentBoard] = useState('');
    const [areas, setAreas] = useState([
        {
            id: 1,
            area: 'Fidelización',
            color: 'primary',
            icon: faUser,
            boards: [
                {
                    id: 1,
                    name: 'Incidencias de Fidelización',
                    link: 'https://forms.monday.com/forms/816c46656463b3ac7ca85e75c2c05313?r=use1',
                    embed_link: 'https://forms.monday.com/forms/embed/816c46656463b3ac7ca85e75c2c05313?r=use1',
                    current: false,
                    copied_link: false,
                },
                {
                    id: 2,
                    name: 'Cancelación de Servicios FIXAT',
                    link: 'https://forms.monday.com/forms/389378119b62a8b5d4c21a9cbf9a82c9?r=use1',
                    embed_link: 'https://forms.monday.com/forms/embed/389378119b62a8b5d4c21a9cbf9a82c9?r=use1',
                    current: false,
                    copied_link: false,
                },
                {
                    id: 3,
                    name: 'Solicitud de Cobranza',
                    link: 'https://forms.monday.com/forms/dfc75abe013adde2b1cfad771603eab8?r=use1',
                    embed_link: 'https://forms.monday.com/forms/embed/dfc75abe013adde2b1cfad771603eab8?r=use1',
                    current: false,
                    copied_link: false,
                }

            ],
            active: true
        },
        {
            id: 2,
            area: 'Operaciones',
            color: 'button-orange',
            icon: faTools,
            boards: [
                {
                    id: 1,
                    name: 'Incidencias del Servicio',
                    link: 'https://forms.monday.com/forms/e07ae7eda404ece5fb3f2a5b94d58efb?r=use1',
                    embed_link: 'https://forms.monday.com/forms/embed/e07ae7eda404ece5fb3f2a5b94d58efb?r=use1',
                    current: false,
                    copied_link: false,
                },
                {
                    id: 2,
                    name: 'Incidencias de FIXAT',
                    link: 'https://forms.monday.com/forms/3584a2a08a38fcf7f7cfe37ef7e7d8df?r=use1',
                    embed_link: 'https://forms.monday.com/forms/embed/3584a2a08a38fcf7f7cfe37ef7e7d8df?r=use1',
                    current: false,
                    copied_link: false,
                },
                {
                    id: 3,
                    name: 'Carga de Accesos del SAT',
                    link: 'https://forms.monday.com/forms/ad37ae5c691d82baa5876e7f159a4fda?r=use1',
                    embed_link: 'https://forms.monday.com/forms/embed/ad37ae5c691d82baa5876e7f159a4fda?r=use1',
                    current: false,
                    copied_link: false,
                }
            ],
            active: true
        },
        {
            id: 3,
            area: 'TI',
            color: 'v2-green-status',
            icon: faCode,
            boards: [
                {
                    id: 1,
                    name: 'Incidencias de Sistemas',
                    link: 'https://forms.gle/CC6REroB2a6M5MYB8',
                    embed_link: 'https://docs.google.com/forms/d/e/1FAIpQLSfi41P7fceP_J5y9Jz9X0A3Zo0Y5xAOKPsYmssXPaHyidV29g/viewform?embedded=true',
                    current: false,
                    copied_link: false,
                }
            ],
            active: true
        },
    ]);

    useEffect(() => {
        setCurrentBoard(areas.map(item => item.area)[0]);
    }, [])

    const desactivateCopyLink = () => {
        const newAreas = areas.map(area => {
            const newBoard = area.boards.map(board => {
                return {
                    ...board,
                    copied_link: false
                }
            });
            return {
                ...area,
                boards: newBoard
            }
        });
        setAreas(newAreas);
    }

    const updateCopiedLink = (id, subId, status) => {
        const newAreas = areas.map(area => {
            const newBoard = area.boards.map(board => {
                if (area.id === id && subId === board.id) {
                    return {
                        ...board,
                        copied_link: status
                    }
                } else {
                    return {
                        ...board,
                        copied_link: false
                    }
                }
            });
            return {
                ...area,
                boards: newBoard
            }
        });
        setAreas(newAreas);
    }

    const handleSelectArea = (id, subId) => {
        if (id == 3) {
            const areaLink =areas.find((area) => area.id == 3)?.boards[0]?.link
            window.open(areaLink, "_blank", "noreferrer")
        }
        setSecondaryLoader(true);
        const newAreas = areas.map(area => {
            const newSubAreas = area.boards.map(board => {
                if (area.id === id && subId === board.id) {
                    return {
                        ...board,
                        current: true
                    }
                } else {
                    return {
                        ...board,
                        current: false
                    }
                }
            });
            return {
                ...area,
                boards: newSubAreas
            }
        });
        setAreas(newAreas);
        setTimeout(() => {
            setSecondaryLoader(false)
        }, 2000);
    }

    const copyLinkToClipboard = async (text) => {
        if ('clipboard' in navigator) {
            return navigator.clipboard.writeText(text);
        }
    }

    const handleCopyLink = (id, subId, url) => {
        copyLinkToClipboard(url).then(() => {
            setShowNotification(true);
            updateCopiedLink(id, subId, true);
            setTimeout(() => {
                desactivateCopyLink();
                setShowNotification(false);
            }, 5000);
        })
    }
    return (
        <div className="w-full h-full">
            {secondaryLoader && <SecondaryLoader />}
            <div className="w-full xs:h-full md:h-full">
                <div className="w-full bg-white xs:h-auto md:h-1/12">
                    <Nav user={user}>
                        <div className="w-full">
                            <span className="text-3xl text-white font-bold">
                                Incidencias
                            </span>
                        </div>
                    </Nav>
                </div>
                <Notification show={showNotification} message='¡Link copiado con éxito!' />
                <div className='w-full p-4 mt-16 md:mt-0'>
                    <div className='w-full block lg:flex justify-between gap-4 items-start'>
                        <div className='w-full lg:w-2/5 max-w-[520px] hidden lg:block'>
                            <div className='md:text-2xl xl:text-3xl text-[#111827] font-bold hidden md:block'>
                                Reporte de incidencias
                            </div>
                            <div className='w-full bg-white rounded-md hidden md:block p-3 mt-6 max-h-[calc(100vh-156px)] overflow-y-auto scroller'>
                                {areas.map(area => (
                                    <div key={area.id} className='border border-[#E5E7EB] rounded-xl my-3 pt-2'>
                                        <div className='w-full px-3 flex justify-between items-center'>
                                            <div>
                                                <div className='text-xl font-medium text-[#111827]'>
                                                    {area.area}
                                                </div>
                                            </div>
                                            <div className={`min-w-[30px] min-h-[30px] bg-${area.color} flex justify-center items-center rounded-full`}>
                                                <FontAwesomeIcon icon={area.icon} className='text-white text-base' />
                                            </div>
                                        </div>
                                        <div>
                                            {area.boards.map(board => (
                                                <div key={board.id} className='w-full rounded-lg border-b last:border-none border-[#E5E7EB] px-2 py-1.5'>
                                                    <div className='text-[#4B5563]'>
                                                        {board.name}
                                                    </div>
                                                    <div className='w-full flex justify-between gap-2 mt-1 mb-1'>
                                                        <div className='w-2/3'>
                                                            <ButtonOrange isFullWidth={true} onClick={() => handleSelectArea(area.id, board.id)}>
                                                                <FontAwesomeIcon icon={faFileCirclePlus} className='mr-2' /> Incidencia
                                                            </ButtonOrange>
                                                        </div>
                                                        <div className='w-1/3'>
                                                            <SecondaryButton isFullWidth={true} onClick={() => handleCopyLink(area.id, board.id, board.link)} disabled={board.copied_link ? true : false}>
                                                                <FontAwesomeIcon icon={faCopy} className='mr-2' /> Link
                                                            </SecondaryButton>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                            }
                                        </div>
                                        {area.boards.some(area => area.copied_link) &&
                                            <div className='w-full text-sm text-[#22C55E] px-2 flex justify-center items-center font-medium pb-1'>
                                                <FontAwesomeIcon icon={faCircleCheck} className='mr-1' /> ¡Link copiado con éxito!
                                            </div>
                                        }
                                    </div>
                                ))
                                }
                            </div>
                        </div>
                        <div className='w-full lg:hidden mb-4'>
                            <SelectCustom value={currentBoard} setValue={setCurrentBoard} listOptions={areas} />
                            <div className='mt-3 bg-white rounded-md py-2 border border-[#E5E7EB] shadow-md'>
                                <span className='px-2 pb-2 font-medium text-[#111827]'>Tableros:</span>
                                {areas.filter(item => item.area === currentBoard).map(area => (
                                    area.boards.map(board => (
                                        <div key={board.id} className='w-full flex items-center justify-between py-1.5 px-2 border-b last:border-none border-[#E5E7EB]'>
                                            <div className='text-[#374151] text-sm'>
                                                {board.name}
                                            </div>
                                            <div>
                                                <Menu as="div" className="-ml-px relative block z-1">
                                                    <Menu.Button>
                                                        <FontAwesomeIcon icon={faEllipsisVertical} className='text-xl pr-2 pt-1.5 text-[#374151]' />
                                                    </Menu.Button>
                                                    <Transition
                                                        as={Fragment}
                                                        enter="transition ease-out duration-100"
                                                        enterFrom="transform opacity-0 scale-95"
                                                        enterTo="transform opacity-100 scale-100"
                                                        leave="transition ease-in duration-75"
                                                        leaveFrom="transform opacity-100 scale-100"
                                                        leaveTo="transform opacity-0 scale-95">
                                                        <Menu.Items className="w-full origin-top-right z-50 absolute right-0 mt-2 -mr-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                            <div className="py-1" >
                                                                <Menu.Item>
                                                                    {({ active }) => (
                                                                        <button className={classNames(active ? 'bg-[#F3F4F6]' : '', 'w-full block px-4 py-2 text-sm text-[#374151] text-left')} onClick={() => handleSelectArea(area.id, board.id)}>
                                                                            Crear incidencia
                                                                        </button>
                                                                    )}
                                                                </Menu.Item>
                                                                <Menu.Item>
                                                                    {({ active }) => (
                                                                        <button className={classNames(active ? 'bg-[#F3F4F6]' : '', 'w-full block px-4 py-2 text-sm text-[#374151] text-left')} onClick={() => handleCopyLink(area.id, board.id, board.link)}>
                                                                            Copiar link
                                                                        </button>
                                                                    )}
                                                                </Menu.Item>
                                                            </div>
                                                        </Menu.Items>
                                                    </Transition>
                                                </Menu>
                                            </div>
                                        </div>
                                    ))
                                ))
                                }
                            </div>
                        </div>
                        {areas.flatMap(board => board.boards).some(area => area.current) ?
                            areas.flatMap(board => board.boards).filter(area => area.current).map(area => (
                                <div key={area.id} className='w-full lg:w-3/5 flex justify-center'>
                                    <iframe src={`${area.embed_link}`} className="rounded-lg shadow-lg w-full h-screen md:h-[calc(100vh-6rem)]"></iframe>
                                </div>
                            ))
                            :
                            <div className='w-full lg:w-2/3 flex justify-center bg-white h-screen md:h-[calc(100vh-6rem)] rounded-md p-4'>
                                <div className='w-full mt-12 text-center'>
                                    <div className='w-full mx-auto'>
                                        <img
                                            alt='Botxi - crea una incidencia'
                                            src='https://cdn.fixat.mx/intranet/botxi.png'
                                            className='w-[105px] h-[132px] mx-auto'
                                        />
                                    </div>
                                    <div className='mt-8 text-4xl font-bold text-[#111827]'>
                                        Aún no has seleccionado un área
                                    </div>
                                    <div className='mt-4 text-xl mx-auto max-w-[500px] text-[#4B5563]'>
                                        De lado izquierdo, encontrarás las áreas en las que podrás reportar una incidencia. Selecciona la corespondiente para habilitar el formulario.
                                    </div>
                                    <div className='mt-4'>
                                        <FontAwesomeIcon icon={faArrowLeftLong} className='text-5xl text-[#4B5563]' />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateTicket