export const emailRegex = (value) => {
    return /^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(value);
}

export const rfcRegex = (value) => {
    return /^([A-ZÑ&]{3,4})(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01]))([A-Z\d]{2})([A\d])$/.test(value.toUpperCase());
}

export const zipRegex = (value) => {
    return /^\d{5}(?:[- ]?\d{4})?$/.test(value);
}

export const phoneRegex = value => {
    return /^\d{10}$/.test(value);
}

export const nameRegex = value => {
    return /^[0-9 A-Za-zÁÉÍÓÚáéíóúÜüÑñ,. -']{3,45}$|^$/.test(value);
}