import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { AlertContext } from '../../context/AlertContext';
import { AuthContext } from '../../cognito/AuthProvider';
import Nav from '../../hooks/Nav';
import TitleHeader from '../../tailwindUI/TitleHeader';
import StepBar from '../../tailwindUI/StepBar';
import SecondaryLoader from '../../hooks/SecondaryLoader';
import DataCard from '../../tailwindUI/DataCard';
import SelectedServicesCard from '../../tailwindUI/SelectedServicesCard';
import GeneralData from './Steps/GeneralData';
import PaymentAgreements from './Steps/PaymentAgreements';
import SelectServices from './Steps/SelectServices';
import useScrollPosition from '../../hooks/useScrollPosition';
import PaymentInstructions from './Steps/PaymentInstructions';
import { createContract, updateContract } from '../../apiClient/operations/contractsOperations';
import BreadCrumb from '../../tailwindUI/BreadCrumb';

function CreateContract() {

    const setScrollPosition = useScrollPosition();
    const history = useHistory();
    const location = useLocation();
    const { user } = useContext(AuthContext);
    const { setAlert } = useContext(AlertContext);
    const [isNewContract, setIsNewContract] = useState(true);
    const [accountData, setAccountData] = useState({});
    const [services, setServices] = useState([]);
    const [isLoading, setIsloading] = useState(false);
    const [servicesToPay, setServicesToPay] = useState([]);
    const [servicesDisplay, setServicesDisplay] = useState([]);
    const [paymentInstructions, setPaymentInstructions] = useState([]);
    const [numberServicesAdded, setNumberServicesAdded] = useState(0);
    const [step, setStep] = useState(1);
    const [steps, setSteps] = useState([
        { id: 1, name: 'Datos generales', status: 'current', route: '/view-quote' },
        { id: 2, name: 'Servicios', status: 'upcoming' },
        { id: 3, name: 'Acuerdo de pago', status: 'upcoming' },
        { id: 4, name: 'Resumen de contrato', status: 'upcoming' },
    ]);
    const roadMap = [
        { name: 'Cotización', url: '/view-quote', current: false },
        { name: 'Generando contrato', url: '/contracts/create', current: true },
    ];

    const getAllSelected = services => {
        const allServicesSelected = services.map(service => {
            return {
                ...service,
                'selected': true,
                'completed': false,
                'resume_open': false
            }
        })
        return allServicesSelected
    }

    useEffect(() => {
        setScrollPosition(0);
    }, [step]);

    useEffect(() => {
        setIsloading(true);
        const state = location.state;
        if (!state) {
            setIsloading(false);
            setAlert({ active: true, type: 'error', message: 'Error al procesar tu solicitud' })
            return;
        }
        if (state?.contract) {
            setIsNewContract(false);
        }
        setAccountData(state.account);
        setServices(getAllSelected(state.serviceItems.services));
        setServicesToPay(getAllSelected(state.serviceItems.services));
        setServicesDisplay(state.serviceItems.datesDisplay);
        setIsloading(false);
    }, [location]);

    useEffect(async () => {
        if (step == 4) {
            const completedServiceToPay = servicesToPay.map(service => {
                return {
                    ...service,
                    completed: true
                }
            });
            const servicesIncluded = services.filter(service => servicesToPay.some(ser => ser.sku == service.sku));
            const servicesExcluded = services.filter(service => !servicesToPay.some(ser => ser.sku == service.sku));
            const completedServices = servicesIncluded.map(service => {
                return {
                    ...service,
                    completed: true
                }
            });

            setServices([...completedServices, ...servicesExcluded]);
            setNumberServicesAdded(numberServicesAdded + completedServiceToPay.length);
        }
    }, [step]);

    const create = async () => {
        setIsloading(true);
        try {
            const { contract, priceType } = location.state;
            const request = {
                ...(contract && { folio: contract?.folio }),
                ...((contract) ? { 
                    modified_by: {
                        name: user?.name,
                        email: user?.email
                    },
                    created_by: {
                        name: contract?.created_by?.name,
                        email: contract?.created_by?.email
                    }
                } : {
                    created_by: {
                        name: user?.name,
                        email: user?.email
                    }
                }),
                quote_id: location?.state?.quoteId,
                account_id: accountData?.id,
                contractor: {
                    id: accountData.id,
                    name: accountData.name,
                    first_name: accountData.first_name,
                    last_name: accountData.last_name,
                    email: accountData.email,
                    phone_number: accountData.phone_number,
                    taxpayer: accountData.taxpayer,
                    legal_type: accountData.legal_type,
                    state: accountData.state,
                    occupancy: accountData.occupancy,
                },
                billing: accountData?.billing,
                payment_instructions: paymentInstructions,
                price_type: priceType
            }
            let newContract;
            if (contract) {
                newContract = await updateContract(request, contract?.id);
            } else {
                newContract = await createContract(request);
            }
            setScrollPosition(0);
            history.push(`view/${newContract.id}`, { contract: newContract });
            setIsloading(false);
        } catch (e) {
            const message = e.name === 'contract_already_created' ? 'El contribuyente ya tiene un contrato activo' : e.message;
            setIsloading(false);
            setAlert({ active: true, type: 'error', message: message || 'Error al procesar tu solicitud' })
        }
    }

    const handleStep = newStep => {
        if (step == 4 && newStep == 3 && paymentInstructions.length > 0) {
            const lastInstruction = paymentInstructions.findLast(instruction => instruction.draft);
            deleteInstruction(lastInstruction?.id);
        }
        setStep(newStep);
    }

    const deleteInstruction = instructionId => {
        if (!instructionId) return;
        const newPaymentPlans = paymentInstructions.filter(payment => payment.id !== instructionId);
        const paymentServices = paymentInstructions.find(payment => payment.id == instructionId).services;
        const servicesIncluded = services.filter(service => paymentServices.some(ser => ser.sku == service.sku));
        const servicesExcluded = services.filter(service => !paymentServices.some(ser => ser.sku == service.sku));
        const newServs = servicesIncluded.map(item => {
            return {
                ...item,
                completed: false
            }
        });
        const modifiedServices = [...servicesExcluded, ...newServs];
        setPaymentInstructions(newPaymentPlans);
        setServices(modifiedServices);
        setNumberServicesAdded(modifiedServices.filter(service => service.completed).length);
    }
    return (
        <>
            {isLoading && <SecondaryLoader />}
            <div className='w-full'>
                <div className="w-full">
                    <Nav user={user}>
                        <TitleHeader steps={steps} setStep={handleStep} currentStep={step} />
                    </Nav>
                </div>
                <div className="w-full px-4">
                    <div className="w-full">
                        <div className='mt-20 lg:mt-4'>
                            <BreadCrumb roadMap={roadMap} />
                        </div>
                        <div className='hidden lg:flex w-full my-4'>
                            <div className="w-full text-3xl text-v2-input-text font-bold flex justify-between items-center gap-4">
                                <div className="w-1/2">
                                    <TitleHeader steps={steps} setStep={handleStep} currentStep={step} />
                                </div>
                                <div className="w-1/2 block">
                                    <div className="w-full text-center">
                                        <StepBar steps={steps} setSteps={setSteps} currentStep={step} setCurrentStep={handleStep} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full my-4 flex flex-col gap-4">
                            <div className="block lg:hidden w-full">
                                <div className="w-full text-center">
                                    <StepBar steps={steps} setSteps={setSteps} currentStep={step} setCurrentStep={handleStep} />
                                </div>
                            </div>
                            {step > 1 &&
                                <div className='w-full block lg:flex items-center gap-4'>
                                    <div className='w-full lg:w-1/2 mb-2 lg:mb-0'>
                                        <DataCard title='Contribuyente' primaryText={accountData?.name || `${accountData?.first_name} ${accountData?.last_name}`} secondaryText={accountData?.taxpayer?.tax_id} />
                                    </div>
                                    <div className='w-full lg:w-1/2'>
                                        <SelectedServicesCard
                                            selected={numberServicesAdded}
                                            total={services.length}
                                            hideDetails={step > 3}
                                            instructions={paymentInstructions}
                                            services={services}
                                            setServices={setServices}
                                            setServicesToPay={setServicesToPay}
                                            setStep={setStep}
                                            setPaymentInstructions={setPaymentInstructions}
                                            setNumberServicesAdded={setNumberServicesAdded} />
                                    </div>
                                </div>
                            }
                            {step == 1 &&
                                <GeneralData account={accountData} setAccount={setAccountData} setStep={setStep} />
                            }
                            {step === 2 &&
                                <SelectServices
                                    accountData={accountData}
                                    numberServicesAdded={numberServicesAdded}
                                    servicesToPay={servicesToPay}
                                    services={services}
                                    setServices={setServices}
                                    setStep={setStep}
                                    setServicesToPay={setServicesToPay} />
                            }
                            {step == 3 &&
                                <PaymentAgreements
                                    servicesToPay={servicesToPay}
                                    servicePeriods={servicesDisplay}
                                    setStep={setStep}
                                    setPaymentInstruction={(instruction) => setPaymentInstructions([instruction, ...paymentInstructions])} />
                            }
                            {step === 4 &&
                                <PaymentInstructions
                                    data={paymentInstructions}
                                    setData={setPaymentInstructions}
                                    servicesDisplay={servicesDisplay}
                                    setServices={setServices}
                                    services={services}
                                    setNumberServicesAdded={setNumberServicesAdded}
                                    numberServicesAdded={numberServicesAdded}
                                    setServicesToPay={setServicesToPay}
                                    setStep={setStep}
                                    onCreate={create}
                                    isNewContract={isNewContract} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CreateContract;