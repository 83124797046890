import React, { useEffect, useState } from 'react';
import { formatPeso } from '../../util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import LongAlert from '../../tailwindUI/LongAlert';
import Badge from '../../tailwindUI/Badge';
import { ChevronRightIcon, TagIcon } from '@heroicons/react/24/solid';
import { datesDisplayArray } from './services';
import useFormatterCurrency from '../../hooks/useFormatterCurrency';
import useFormatDate from '../../hooks/useFormatDate';
import PropTypes from 'prop-types';
import SlideOverReturn from '../../tailwindUI/SlideOverReturn';
import SkeletonLoader from '../../tailwindUI/SkeletonLoader';

const interval = {
    "month": "mes",
    "week": "semanal",
    "half_month": "quicenal",
    "year": "anual"
}
const allowedPayment = {
    "card": "Tarjeta", "paypal": "Paypal", "cash": "Efectivo", "transfer": "Transferencia"
}

const typeLink = {
    "payment_contract_renovation": "Pago único con renovación"
}

export const LinksHistory = ({ openSlideLinks, setOpenSlideLinks, links, isLoading }) => {
    const { formatterCurrency } = useFormatterCurrency();
    const [shortLinkCopied, setShortLinkCopied] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlert] = useState(false);
    const [linkInfo, setLinkInfo] = useState(null);
    const [paymentLinks, setPaymentLinks] = useState([]);
    const [datesDisplay, setDatesDisplay] = useState([]);
    const { formatDate } = useFormatDate();

    useEffect(() => {
        setPaymentLinks(links)
    }, [links]);

    const copyLinkToClipboard = async (text) => {
        if ('clipboard' in navigator) {
            return navigator.clipboard.writeText(text);
        }
    }

    const handleCopyShortLink = (url) => {
        copyLinkToClipboard(url).then(() => {
            setShortLinkCopied(true);
            setTimeout(() => {
                setShortLinkCopied(false);
            }, 3000);
        })
    }

    useEffect(() => {
        setDatesDisplay(datesDisplayArray);
    }, []);

    useEffect(() => {
        !openSlideLinks && setLinkInfo(false)
    }, [openSlideLinks]);

    useEffect(() => {
        shortLinkCopied ? setAlert({ active: true, type: 'success', message: 'Link copiado con éxito' }) : setAlert({ active: false })
    }, [shortLinkCopied]);

    useEffect(() => {
        alert.active ? setShowAlert(true) : setShowAlert(false)
    }, [alert]);
    
    return (
        <SlideOverReturn open={openSlideLinks} setOpen={setOpenSlideLinks} title={`${linkInfo ? formatDate(linkInfo?.created, "DD MMMM YYYY") : 'Historial de links de pago'}`} back={linkInfo ? true : false} onClickButton={() => setLinkInfo(null)} subtitle={linkInfo ? '' : `Total de convenios de 2024: ${links?.length}`}>
            {showAlert &&
                <div className="w-full z-50 fixed top-5 left-0">
                    <LongAlert show={showAlert} onClose={setShowAlert} type={alert.type} title={alert.message} />
                </div>
            }
            <div className='mt-4 -mx-5 sm:-mx-4'>
                {linkInfo
                    ? <div className='px-4 flex w-full flex-col'>
                        <div className='flex w-full justify-between'>
                            <div className='text-sm'><b>Concepto:</b> {linkInfo?.name}</div>
                            <Badge text={linkInfo?.status} />
                        </div>
                        <h2 className="text-base font-medium text-gray-900 my-4">
                            Servicios incluidos:
                        </h2>
                        <ul role="list" className="divide-y divide-gray-200 text-sm text-gray-900">
                            {linkInfo?.order_template?.line_items?.map((service, i) => {
                                const total = service.price?.total_amount / 100;
                                return (
                                    <div key={i} className={`w-full border-t  border-gray-200 py-2`} >
                                        <div className='w-full flex  justify-between items-center gap-2'>
                                            <div className="text-sm text-gray-600 font-medium">
                                                {service?.name}
                                            </div>
                                            <div className="flex justify-end items-center xs:gap-2 md:gap-6">
                                                {service.price?.discount == 0 ?
                                                    <div className="text-sm">
                                                        <div className="text-gray-600 font-medium flex items-center gap-1">
                                                            {formatterCurrency(total)}
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="text-sm">
                                                        <div className="text-v2-blue-text-login font-medium flex items-center gap-1">
                                                            {formatterCurrency(total - (service?.price?.discount_amount / 100))} <TagIcon className='w-4 h-4 min-w-[16px] min-h-[16px]' />
                                                        </div>
                                                        <div className="text-gray-400 line-through">
                                                            {formatterCurrency(total)}
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="w-full py-2">
                                            <div className="w-full">
                                                {datesDisplay.filter(date => date.sku == service.sku).map((ser, i) => (
                                                    <div key={i} className="w-full flex gap-2 flex-wrap max-w-[100%]">
                                                        {ser.dates_display.map((date, i) => (
                                                            <div key={i} className="w-auto bg-v2-blue-text-login/20 text-v2-blue-text-login py-2 text-xs rounded flex items-center px-2">
                                                                <div className="w-full text-center min-w-[40px]">
                                                                    {service.periodicity == 'yearly' ? date.year : date.tag}
                                                                </div>
                                                            </div>
                                                        ))
                                                        }
                                                    </div>
                                                ))}
                                            </div>
                                            {service?.subscription_renovation && (
                                                <div className='mt-3 mb-2 rounded-xl  px-4 py-2 bg-blue-50 text-sm flex gap-1 items-center text-primary font-medium '>
                                                    <span>Suscripción renovada por</span>
                                                    <span>{formatPeso(service?.price?.subscription_price / 100)}/{interval[linkInfo?.subscription_info?.interval]}</span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )
                            })}
                        </ul>
                        {
                            linkInfo?.order_template?.line_items?.find(service => service?.price?.discount > 0) != undefined && <div className='flex mt-4 gap-2 items-center'>
                                <TagIcon className='text-green-500 w-4 h-4 inline-block' />
                                <span className='font-semibold text-sm'>Descuento aplicado</span>
                            </div>
                        }
                        <dl className="space-y-4 pt-6 text-sm font-medium text-gray-900 block">
                            <div className="flex items-center justify-between">
                                <dt className="text-gray-600">Subtotal</dt>
                                <dd>{formatterCurrency(linkInfo?.price?.subtotal_amount / 100)}</dd>
                            </div>
                            <div className="flex items-center justify-between">
                                <dt className="text-gray-600"> Descuento</dt>
                                <dd>{formatterCurrency(linkInfo?.price?.discount_amount / 100)}</dd>
                            </div>
                            <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                                <dt className="text-base">Total</dt>
                                <dd className="text-base">{formatterCurrency((linkInfo?.price?.subtotal_amount / 100) - (linkInfo?.price?.discount_amount / 100))}</dd>
                            </div>
                        </dl>
                        <div className='divide-x divide-dashed border-y border-dashed border-gray-200 flex flex-row py-4 mt-4 justify-center'>
                            <div className='flex flex-col items-star gap-2 w-1/3'>
                                <p className={`truncate text-sm font-medium text-gray-900`}>
                                    Métodos de pago
                                </p>
                                <p className="text-sm font-light text-gray-900">
                                    {
                                        linkInfo?.allowed_payment_methods?.map(payment => (
                                            <><span>{allowedPayment[payment]}</span><br></br></>
                                        ))
                                    }
                                </p>
                            </div>
                            <div className='flex flex-col pl-4 items-star gap-2 w-1/3'>

                                <p className={`truncate text-sm font-medium text-gray-900`}>
                                    {linkInfo.status == 'paid' ? 'Fecha de pago' : 'Tipo de cobro'}

                                </p>
                                <p className="text-sm font-light text-gray-900">
                                    {linkInfo.status == 'paid' ? formatDate(linkInfo?.updated, "DD MMMM YYYY") : typeLink[linkInfo?.type]}
                                </p>
                            </div>
                            <div className='flex flex-col pl-4 items-star gap-2 w-1/3'>
                                <p className={`truncate text-sm font-medium text-gray-900`}>
                                    Tipo de pago
                                </p>
                                <p className="text-sm font-light text-gray-900">
                                    {linkInfo.monthly_installments_enabled ? '' : 'Un solo pago'}
                                </p>
                            </div>
                        </div>
                        <div className='flex flex-col mt-4 gap-2'>
                            <p className='text-base font-semibold'>Comentarios del descuento</p>
                            <div className='w-full rounded-xl bg-gray-100 p-4'>
                                <p className='text-gray-900 text-sm'>{linkInfo?.notes ? linkInfo?.notes : "-"}</p>
                            </div>
                        </div>
                        <div className="w-full my-4 flex justify-between items-center p-2">
                            <div className="text-xs text-v2-gray-title-client font-bold w-10/12 truncate">
                                Link corto:
                                <input className="block rounded-md border-gray-400 text-base text-v2-input-text font-normal outline-none w-full" value={linkInfo.short_url} readOnly />
                            </div>
                            <div className="cursor-pointer mt-3 h-10 w-14 rounded-5 bg-button-orange text-white flex justify-center items-center" onClick={() => handleCopyShortLink(linkInfo.short_url)}>
                                <FontAwesomeIcon icon={faCopy} />
                            </div>
                        </div>
                    </div>
                    : <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                            <tr>
                                <th scope="col" className="hidden px-3 pb-3 text-left text-sm font-medium text-gray-500 sm:table-cell">
                                    Fecha
                                </th>
                                <th
                                    scope="col"
                                    className="px-3 pb-3 text-left text-sm font-medium text-gray-500 sm:table-cell">
                                    Nº servicios
                                </th>
                                <th
                                    scope="col"
                                    className="hidden px-3 pb-3 text-right text-sm font-medium text-gray-500 sm:table-cell">
                                    Monto
                                </th>
                                <th
                                    scope="col"
                                    className="hidden px-3 pb-3 text-right text-sm font-medium text-gray-500 sm:table-cell">
                                    Link de pago
                                </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">

                            <>
                                {isLoading ? (
                                    <>
                                        {Array.from({ length: 4 }).map((_, i) => (
                                            <tr key={i} className='odd:bg-gray-50'>
                                                <td className="hidden px-3 py-4 text-sm text-gray-900 sm:table-cell capitalize">
                                                    <SkeletonLoader />
                                                </td>
                                                <td className="hidden px-3 py-4 text-sm text-gray-900 sm:table-cell capitalize">
                                                    <SkeletonLoader />
                                                </td>
                                                <td className="hidden px-3 py-4 text-sm text-gray-900 sm:table-cell text-right font-semibold">
                                                    <SkeletonLoader />
                                                </td>
                                                <td className="hidden px-3 py-4 text-sm text-gray-900 sm:table-cell text-right font-semibold">
                                                    <SkeletonLoader />
                                                </td>
                                            </tr>
                                        ))}
                                    </>
                                )

                                    : <>
                                        {paymentLinks.map((item, index) => (
                                            <tr key={index} className='odd:bg-gray-50'>
                                                <td className="w-full max-w-0 py-2 px-4 text-sm text-gray-900 sm:w-auto sm:max-w-none">
                                                    <div className='gap-1 flex flex-col'>
                                                        <span className='text-sm w-full flex gap-1 items-center overflow-hidden relative'>
                                                            <span className='w-9/12 z-10'> {formatDate(item.created, "DD MMM YYYY")} </span>
                                                            <button onClick={() => setLinkInfo(item)} className='w-4 h-4 flex items-center justify-center rounded-full bg-button-orange text-white'> <ChevronRightIcon className='w-3 h-3 text-white' /> </button>
                                                        </span>
                                                        <div className='w-fit'>
                                                            <Badge text={item.status} />
                                                        </div>

                                                    </div>
                                                </td>
                                                <td className="hidden px-3 py-2 text-sm text-gray-900 sm:table-cell capitalize text-center">
                                                    {item?.order_template?.line_items?.length}
                                                </td>
                                                <td className="hidden px-3 py-2 text-sm text-gray-900 sm:table-cell text-right font-semibold">
                                                    {formatPeso(item?.price?.total_amount / 100)}
                                                </td>
                                                <td className="hidden px-3 py-2 text-sm text-gray-900 sm:table-cell text-center font-semibold">
                                                    <div className='flex w-full justify-center'>
                                                        <button className="cursor-pointer h-10 w-12 rounded-5 bg-button-orange text-white flex justify-center items-center" onClick={() => handleCopyShortLink(item?.url)} >
                                                            <FontAwesomeIcon icon={faCopy} />
                                                        </button>
                                                    </div>

                                                </td>
                                            </tr>
                                        ))
                                        }
                                    </>
                                }

                            </>

                        </tbody>
                    </table>
                }
            </div>
        </SlideOverReturn>
    )
}

LinksHistory.propTypes = {
    openSlideLinks: PropTypes.bool,
    setOpenSlideLinks: PropTypes.func,
    links: PropTypes.array,
    isLoading: PropTypes.bool,
}