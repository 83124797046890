import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
//import useFormatterCurrency from '../../hooks/useFormatterCurrency';
import PrimaryButton from '../../tailwindUI/PrimaryButton';
import { ArchiveBoxXMarkIcon } from '@heroicons/react/24/solid';
import ModalV2 from '../../tailwindUI/Modal';
import LinkButton from '../../tailwindUI/LinkButton';
import SecondaryLoader from '../../hooks/SecondaryLoader';
import LongAlert from '../../tailwindUI/LongAlert';
import { AuthContext } from '../../cognito/AuthProvider';
import { useForm } from 'react-hook-form';
import { cancelSubscription } from '../../apiClient/operations/subscriptionOperations';
import useFormatDate from '../../hooks/useFormatDate';

function CancelModal({showRefund, setShowRefund, paymentDetails, setPaymentDetails, handleUpdate}) {
    
    const { user } = React.useContext(AuthContext);
    const [ alert, setAlert ] = useState();
    const [showAlert, setShowAlert] = useState(false);
    const [loading, setLoading] = useState(false);
    const { formatDate } = useFormatDate();
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm();

    useEffect(() => {
        setShowAlert(alert?.active)
    }, [alert]);

    const handleCancelSub = async (data) => {
        try {
            const { refund_reason} = data;
            setLoading(true)
            var request = {
                "subscription_id":paymentDetails.id,
                "provider":paymentDetails?.subscription_info?.provider,
                "account":paymentDetails.account_id,
                'cancel_reason':refund_reason,
                "canceled_by":{
                    "name": user.name,
                    "email": user.email
                }
            }
            var cancelation = await cancelSubscription(request)
            console.log(cancelation)
            setLoading(false)
            setAlert({ active: true, type: 'success', message: 'Cancelación exitosa' })
            handleUpdate()
            reset()
            setPaymentDetails({ ...paymentDetails, 
                status:cancelation?.status, 
                canceled_by:cancelation?.canceled_by, 
                cancel_reason:cancelation?.cancel_reason, 
                canceled_at:cancelation?.canceled_at, 
                completed_at:cancelation?.completed_at,
                ...(cancelation?.canceled_at&&{formated_canceled:formatDate(cancelation?.canceled_at, 'DD MMMM YYYY HH:mm')+" hrs",}),
                ...(cancelation?.completed_at&&{formated_canceled:formatDate(cancelation?.completed_at, 'DD MMMM YYYY HH:mm')+" hrs",})
            })
            setShowRefund(false)
        }catch(error){
            setAlert({ active: true, type: 'error', message: error.message })
            setLoading(false)
        }
        
    }

    return (<>
            <ModalV2 show={showRefund} setShow={setShowRefund} className='w-full sm:max-w-[30rem]'>
            <div className='w-full divide-y divide-gray-200'>
            <form onSubmit={handleSubmit(handleCancelSub)} className="w-full divide-y divide-gray-200">
                    <div className='pb-3'>
                        <dl className="grid grid-cols-1 gap-4 sm:grid-cols-1">
                            <div className="flex flex-row items-center">
                                <dd className="mr-2 text-xl font-bold text-gray-900">Cancelar suscripción</dd>
                            </div>
                        </dl>
                    </div>
                    <div className='py-3'>
                        <dl className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Suscriptor:</dt>
                                <dd className="mt-1 text-sm text-gray-900">{paymentDetails?.account?.name}</dd>
                            </div>
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Monto de la suscripción</dt>
                                <dd className="mt-1 text-sm text-gray-900">{paymentDetails?.formated_amount+"/"+paymentDetails?.periodicity}</dd>
                            </div>
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Concepto:</dt>
                                <dd className="mt-1 text-sm text-gray-900">{paymentDetails?.name}</dd>
                            </div>
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Próxima fecha de pago</dt>
                                <dd className="mt-1 text-sm text-gray-900">{paymentDetails?.charged_formatted}</dd>
                            </div>
                        </dl>
                    </div>
                    <div className='py-3'>
                        <div className="w-full py-2">
                            <div className="w-full text-sm font-medium text-gray-500">
                                Motivo de la cancelación:
                                <textarea
                                    type="text"
                                    placeholder="Escribe el motivo de la cancelación"
                                    maxLength={250}
                                    className={`w-full font-normal appearance-none h-[75px] py-2.5 mt-1.5 px-4 border rounded-md text-base text-v2-text-bar-steps outline-none flex items-center justify-center ${errors.refund_reason ? "border-red-500":"border-v2-border-time"}`}
                                    {...register ("refund_reason", { required: true })}
                                />
                                {errors.refund_reason && <span className="text-xs text-red-500">Este campo es requerido</span>}
                        </div>
                        </div>
                    <div className='w-full flex items-center justify-end pt-2'>
                    <div className='w-[7rem]'>
                                    <LinkButton type="button" onClick={() => {setShowRefund(false)}}>
                                        Cerrar
                                    </LinkButton>
                    </div>
                    <div className='w-[15rem]'>
                                 <PrimaryButton   disabled={false} isFullWidth>
                                    Cancelar suscripción<ArchiveBoxXMarkIcon className='w-5 h-5 ml-2 inline-block' />
                                </PrimaryButton>
                    </div>
                    </div>
                    </div>
                    
                </form>
                </div>
            </ModalV2>         
            {loading && <SecondaryLoader/>}
            {showAlert && <div className="w-1/2 h-screen fixed top-12 left-1/4 z-[60] ">
                <LongAlert show={ showAlert } onClose={()=>setShowAlert(false) } type={ alert.type } title={ alert.message } />
            </div> } 
            </>
    )
}
CancelModal.propTypes = {
    handleUpdate: PropTypes.func,
    paymentDetails: PropTypes.object,
    orderDetails: PropTypes.object,
    showRefund: PropTypes.bool,
    setShowRefund: PropTypes.func,
    setAlert: PropTypes.func,
    setPaymentDetails: PropTypes.func,
}

export default CancelModal;