import React, { useRef, useEffect, useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AlertContext } from '../context/AlertContext';
import { uploadS3, deleteS3, getObjectS3 } from '../apiClient/operations/s3UploadOperations';
import { faCirclePlus, faTrash, faFileCirclePlus, faCloudArrowUp, faFileZipper } from '@fortawesome/free-solid-svg-icons';
import SecondaryLoader from './SecondaryLoader';
import { updateDocument } from '../apiClient/operations/accountsOperations';
import PropTypes from 'prop-types';


function UploadFileS3({selectedFiles, setSelectedFiles, rfc, dataClient}) {
    const fileInputRef = useRef();
    const fileInputRef2 = useRef();

    const { setAlert } = useContext(AlertContext);

    const [secondaryLoader, setSecondaryLoader] = useState(false);
    const [error, setError] = useState(false);
    const [nameFile, setNameFile] = useState('');

    const getObject = async () => {
        try {
            const resS3 = await getObjectS3({'name': rfc == '' ? 'RFCX009900ABC' : rfc});
            if(resS3.fileName != undefined ){
                setSelectedFiles([{name: `${resS3.fileName}`}])
                setNameFile(resS3.fileName)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getObject();
    }, [])

    const isValidFile = (fileName) => ( /\.(zip|rar)$/i.test(fileName))

    const isValidSize = fileSize => fileSize <= 1000000 ? true : false

    const uploadToS3 = async (file) => {
        try {
            setSecondaryLoader(true);
            let name;
            let rfcValid = rfc == '' ? 'RFCX009900ABC' : rfc;
            name = file[0].type == 'application/zip' ? `${rfcValid}_ASF.zip` : `${rfcValid}_ASF.rar`;
            setNameFile(name)
            const formData = new FormData();
            formData.set('file', file[0], name);
            await uploadS3(formData);
            const obj = await uploadS3(formData);
            const { key, Bucket } = obj;
            await updateDocument({path: key, bucket: Bucket, tags: ['asf']} ,dataClient.id);
            setSecondaryLoader(false);
        } catch (error) {
            setSecondaryLoader(false);
            setAlert({active: true, type: 'error', message: 'Error al procesar tu solicitud.'});
        }
    }

    const deleteS3File = async (name) => {
        try {
            setSecondaryLoader(true);
            await deleteS3({'name': name});
            setSecondaryLoader(false);
        } catch (error) {
            setSecondaryLoader(false);
            setAlert({active: true, type: 'error', message: 'Error al procesar tu solicitud.'});
        }
    }

    const handleFiles = (files, e) => {
        for (const file of files) {
            if (isValidFile(file.name)) {
                if(isValidSize(file.size)){
                    if (dataClient.rfc !== undefined) {
                        setSelectedFiles([...selectedFiles, ...[file]]);
                        uploadToS3([...selectedFiles, ...[file]]);
                        setError(false);
                    }else {
                        setAlert({active: true, type: 'error', message: 'Primero agrega un RFC válido.'});
                        setError(true);
                        e.nativeEvent.target.value = '';
                    }
                } else {
                    setAlert({active: true, type: 'error', message: '¡Oh oh! Archivo con peso mayor al permitido.'});
                    setError(true);
                    e.nativeEvent.target.value = '';
                }
            } else {
                setAlert({active: true, type: 'error', message: '¡Oh oh! Archivo inválido. Solo puedes agregar archivos ZIP'});
                setError(true);
                e.nativeEvent.target.value = '';
            }
        }
    }

    const filesSelected2 = (e) => {
        if (fileInputRef2.current.files.length) {
            handleFiles(fileInputRef2.current.files, e);
        }
    }

    const filesSelected = (e) => {
        if (fileInputRef.current.files.length) {
            handleFiles(fileInputRef.current.files, e);
        }
    }

    const handleDeleteFile = (name) => {
        const selectedFileIndex = selectedFiles.findIndex(e => e.name === name);
        selectedFiles.splice(selectedFileIndex, 1);
        setSelectedFiles([...selectedFiles]);
        deleteS3File(name);
    }
    return (
        <>
        {secondaryLoader && <SecondaryLoader />}
            <div className="w-full mt-2.5 mb-3">
                <div className="w-full h-auto border border-v2-gray-border-tables rounded-10 bg-white xs:mt-4 md:mt-0">
                    <div className='w-full xs:px-2 md:px-4 py-2 border-b border-v2-gray-border-tables flex items-center'>
                        <div className="xs:w-11 md:w-10">
                            <div className='rounded-full w-8 h-8 bg-v2-blue-icons text-lg text-white flex justify-center items-center'>
                                <FontAwesomeIcon icon={ faFileCirclePlus } className='leading-3 pl-1' />
                            </div>
                        </div>
                        <div className='xs:w-8/12 md:w-7/12 text-xl font-semibold text-v2-input-text'>
                            Agrega ASF
                        </div>
                        {selectedFiles.length == 0 && 
                            <div className="xs:w-4/12 md:w-5/12 justify-end items-center text-v2-yellow-edit-info">
                                <div className="w-full flex items-center justify-end">
                                    <label className="flex items-center justify-end cursor-pointer">
                                        <span className="text-3xl text-v2-yellow-edit-info leading-3 flex items-center"><FontAwesomeIcon icon={ faCirclePlus } /> 
                                            <span className="xs:hidden underline md:inline-block text-base pl-3">
                                                Agregar documento
                                            </span>
                                        </span>
                                        <input ref={fileInputRef2} type='file' className="hidden" accept="application/zip, application/vnd.rar" onChange={ e => filesSelected2(e) }/>
                                    </label>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="w-full">
                        { selectedFiles.length == 0 &&
                            <div className="w-full h-[300px] flex justify-center items-center">
                                <div className="xs:w-5/6 md:w-1/2 text-center"> 
                                    <div className={`${error ? 'text-v2-red-error-services-selected' : 'text-v2-gray-light-plus-circle'} text-5xl leading-3`}>
                                        <FontAwesomeIcon icon={ faCloudArrowUp } />
                                    </div>
                                    <div className={`${error ? 'text-v2-red-error-services-selected' : 'text-button-orange opacity-60'} text-xl font-bold text-center mt-5`}>
                                        Sube tu archivo comprimido. 
                                    </div>
                                    <div className="block mt-2 text-base text-v2-text-bar-steps">
                                        ZIP o RAR con un tamaño máximo de 1MB
                                    </div>
                                    <div className="block mt-8 w-full flex justify-center">
                                        <label className={`${error ? 'border border-v2-red-error-services-selected text-v2-red-error-services-selected' : 'border border-v2-blue-text-login text-v2-blue-text-login'} flex xs:w-full md:w-3/4 lg:w-4/6 h-12 content-['Agregar'] justify-center items-center text-base outline-none select-none cursor-pointer rounded-md p-3`}>
                                            <span className="text-base leading-3">Agregar</span>
                                            <input ref={fileInputRef} type='file' className="hidden" name='file' accept="application/zip, application/vnd.rar" onChange={ e => filesSelected(e)}/>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        }
                        { selectedFiles.length > 0 &&
                            <div className="w-full px-7 py-4">
                                <div className="w-full flex items-center py-2">
                                    <div className="w-3/4 flex items-center">
                                        <FontAwesomeIcon icon={ faFileZipper } className="text-v2-gray-light-plus-circle text-3xl leading-3 "/>
                                        <span className="text-v2-text-bar-steps text-[15px] pl-7">
                                            { nameFile}
                                        </span>
                                    </div>
                                    <div className="w-1/4 flex justify-end text-v2-yellow-edit-info">
                                        <FontAwesomeIcon icon={ faTrash } className="leading-3 text-2xl cursor-pointer" onClick={ () => handleDeleteFile(nameFile) }/>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

UploadFileS3.propTypes = {
    selectedFiles: PropTypes.any,
    setSelectedFiles: PropTypes.any,
    rfc: PropTypes.any,
    dataClient: PropTypes.any
}

export default UploadFileS3