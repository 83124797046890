import React, { createContext, useState } from "react";
import PropTypes from 'prop-types';

export const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
    const [alert, setAlert ] = useState({});
    return (
        <AlertContext.Provider value={{
            alert,
            setAlert
        }}>
            {children}
        </AlertContext.Provider>
    )
}
AlertProvider.propTypes = {
    children : PropTypes.any
}