import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

function Tooltip({ show = false, onClose, message, arrowPosition }) {

    const positions = {
        'left': 'after:left-[10px]',
        'middle': 'after:left-[47%]',
        'right': 'after:right-[10px]'
    };

    const sizes = ["xs", "sm", "md", "lg", "xl", "2xl"];

    let arrowInset = "";

    if (typeof arrowPosition === 'string') {
        arrowInset = positions[arrowPosition] || positions["middle"]
    } else if (typeof arrowPosition === 'object') {
        for (const size in arrowPosition) {
            if (sizes.includes(size) && positions[arrowPosition[size]] != null) {
                arrowInset += `${size}:${positions[arrowPosition[size]]} `;
            }
        }
    } else {
        arrowInset = positions["middle"]
    }

    useEffect(() => {
        document.addEventListener("click", onClose(), true);
        return () => {
            document.removeEventListener("click", onClose(), false)
        }
    }, []);

    return (
        <>
            {show && (
                <div className={`rounded shadow-lg p-2 z-50 w-full max-w-[14em] text-center bg-v2-input-text text-xs font-normal text-white ${arrowInset} after:content-[''] after:-bottom-2 after:w-4 after:h-4 after:absolute after:bg-v2-input-text after:rotate-45 after:z-40`}>
                    <span className='block'>{message}</span>
                </div>
            )}
        </>
    )
}

Tooltip.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    message: PropTypes.string,
    arrowPosition: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ])
}

export default Tooltip