const useConvertUnixTime = () => {
    const convertUnixTime = date => {
        const result = new Date(date);
        result.setDate(result.getDate() + 1);
        const ts = Math.floor( new Date(result).getTime())-1;
        return ts;
    }   
    return { convertUnixTime }
}

export default useConvertUnixTime;