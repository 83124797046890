import React from "react";
import PropTypes from "prop-types";

import {
  ClipboardDocumentCheckIcon,
  ClipboardDocumentIcon,
} from "@heroicons/react/24/outline";
import PermissionsGate from "../../cognito/permissions/PermissionsGate";
import { ROLES } from "../../cognito/permissions/permission-maps";
import useCopyToClipboard from "../../hooks/useCopyToClipboard";
import DocumentList from "../../tailwindUI/DocumentList";
import { s3Bucket } from "../../config";
import useFormatDate from "../../hooks/useFormatDate";

const allowedRolesToPassword = [
  ROLES.root,
  ROLES.help_desk,
  ROLES.administrators,
  ROLES.operations,
  ROLES.customerService,
  ROLES.collections,
  ROLES.collections_manager,
  ROLES.operationsAtc,
];

export function SatCredentialsInfo({ data, setAlert }) {
  const { formatDate } = useFormatDate();
  const { efirma, ciec, confirmedBy, statusCredentials } = data;
  const [ciecPasswordCopied, copyCiecPassword] = useCopyToClipboard();
  const [efirmaPasswordCopied, copyEfirmaPassword] = useCopyToClipboard();

  const downloadError = (e) => {
    setAlert({ active: true, type: "error", message: e.message });
  };

  /* eslint-disable react/prop-types */
  const Empty = ({ text, placeholder = "placeholder" }) => {
    if (!text) {
      return (
        <dd className="mt-1 text-sm font-normal text-gray-500">
          {placeholder}
        </dd>
      );
    }
    return <dd className="mt-1 text-sm text-gray-900 font-normal">{text}</dd>;
  };
  /* eslint-enable react/prop-types */

  return (
    <main className="px-6">
      {!efirma && !ciec && (
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              Tipo de credenciales
            </dt>
            <Empty text={null} placeholder="Tipo Pendiente" />
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">RFC</dt>
            <Empty text={null} placeholder="RFC pendiente" />
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              Fecha de subida
            </dt>
            <Empty text={null} placeholder="Fecha pendiente" />
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              Confirmado por
            </dt>
            <Empty text={null} placeholder="Confirmación pendiente" />
          </div>
        </dl>
      )}
      {efirma && (
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              Tipo de credenciales
            </dt>
            <Empty text="e.firma" placeholder="Tipo Pendiente" />
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">RFC</dt>
            <Empty
              text={efirma?.credentials?.rfc}
              placeholder="RFC pendiente"
            />
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              Fecha de subida
            </dt>
            <Empty
              text={
                efirma?.created
                  ? formatDate(new Date(efirma?.created), "D MMMM YYYY, h:mm a")
                  : null
              }
              placeholder="Fecha pendiente"
            />
          </div>
          {efirma?.credentials?.expire_date && (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Fecha de expiración
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {formatDate(efirma?.credentials?.expire_date, "DD MMMM YYYY")}
              </dd>
            </div>
          )}
          {statusCredentials !== "invalid" && confirmedBy?.confirmed && (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Confirmado por
              </dt>
              <Empty
                text={confirmedBy?.name}
                placeholder="Confirmación pendiente"
              />
              {confirmedBy?.updated && (
                <Empty
                  text={formatDate(
                    new Date(confirmedBy?.updated),
                    "D MMMM YYYY, h:mm a"
                  )}
                  placeholder=""
                />
              )}
            </div>
          )}
          <PermissionsGate allowedRoles={allowedRolesToPassword}>
            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">
                Contraseña
                <span className="text-xs text-v2-blue-text-login ml-2 cursor-pointer hidden">
                  Copiar
                </span>
              </dt>
              <dd className="mt-1 text-sm text-gray-900 hidden">
                {efirma?.credentials?.password?.replaceAll(
                  /[a-zA-Z0-9\W|_]/g,
                  "*"
                )}
              </dd>
              <dd className="mt-1 text-sm text-gray-900">
                <div className="flex rounded-md shadow-sm">
                  <div className="relative flex flex-grow items-stretch focus-within:z-10">
                    <input
                      type="password"
                      value={efirma?.credentials?.password}
                      className={`transition-all block w-full rounded-none rounded-l-md border-gray-300 focus:border-v2-blue-text-login focus:ring-v2-blue-text-login sm:text-sm`}
                      readOnly
                    />
                  </div>
                  <button
                    type="button"
                    onClick={() =>
                      copyEfirmaPassword(efirma?.credentials?.password)
                    }
                    className={`${
                      efirmaPasswordCopied
                        ? "bg-green-500 border-green-500"
                        : "bg-v2-blue-text-login border-v2-blue-text-login"
                    } transition-all relative w-14 -ml-px inline-flex items-center justify-center space-x-2 rounded-r-md border px-4 py-2 text-sm font-medium text-white`}
                  >
                    <div className="relative w-full h-full">
                      <ClipboardDocumentIcon
                        className={`${
                          !efirmaPasswordCopied ? "opacity-100" : "opacity-0"
                        } inset-0 absolute transition-all h-5 w-5 text-white`}
                        aria-hidden="true"
                      />
                      <ClipboardDocumentCheckIcon
                        className={`${
                          efirmaPasswordCopied ? "opacity-100" : "opacity-0"
                        } inset-0 absolute transition-all h-5 w-5 text-white`}
                        aria-hidden="true"
                      />
                    </div>
                  </button>
                </div>
              </dd>
            </div>

            {efirma?.credentials?.cer && efirma?.credentials?.key && (
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">
                  Certificados
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <DocumentList
                    documents={["cer", "key"].map((key) => {
                      const document = efirma?.credentials[key];
                      const ext = document.object_name?.split(".")?.pop();
                      const documentName = `${efirma?.credentials?.rfc}.${ext}`;
                      return {
                        bucket: document.bucket,
                        key: document.object_name,
                        name: documentName,
                      };
                    })}
                    onError={downloadError}
                  />
                </dd>
              </div>
            )}

            {efirma?.credentials?.fiel && (
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">
                  Certificados
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <DocumentList
                    documents={[
                      {
                        bucket: s3Bucket,
                        key: efirma?.credentials?.fiel,
                      },
                    ]}
                    onError={downloadError}
                  />
                </dd>
              </div>
            )}
          </PermissionsGate>
        </dl>
      )}

      {efirma && ciec && <div className="h-[1px] w-full bg-gray-200 my-8" />}

      {ciec && (
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              Tipo de credenciales
            </dt>
            <Empty
              text={ciec.credentials?.type?.toUpperCase()}
              placeholder="Tipo Pendiente"
            />
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">RFC</dt>
            <Empty text={ciec?.credentials?.rfc} placeholder="RFC pendiente" />
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              Fecha de subida
            </dt>
            <Empty
              text={
                ciec?.created
                  ? formatDate(new Date(ciec?.created), "D MMMM YYYY, h:mm a")
                  : null
              }
              placeholder="Fecha pendiente"
            />
          </div>
          {statusCredentials !== "invalid" && confirmedBy?.confirmed && (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Confirmado por
              </dt>
              <Empty
                text={confirmedBy?.name}
                placeholder="Confirmación pendiente"
              />

              <Empty
                text={formatDate(
                  new Date(confirmedBy?.updated),
                  "D MMMM YYYY, h:mm a"
                )}
                placeholder=""
              />
            </div>
          )}
          <PermissionsGate allowedRoles={allowedRolesToPassword}>
            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">
                Contraseña
                <span className="text-xs text-v2-blue-text-login ml-2 cursor-pointer hidden">
                  Copiar
                </span>
              </dt>
              <dd className="mt-1 text-sm text-gray-900 hidden">
                {ciec?.credentials?.password?.replaceAll(
                  /[a-zA-Z0-9\W|_]/g,
                  "*"
                )}
              </dd>
              <dd className="mt-1 text-sm text-gray-900">
                <div className="flex rounded-md shadow-sm">
                  <div className="relative flex flex-grow items-stretch focus-within:z-10">
                    <input
                      type="password"
                      value={ciec?.credentials?.password}
                      className={`transition-all block w-full rounded-none rounded-l-md border-gray-300 focus:border-v2-blue-text-login focus:ring-v2-blue-text-login sm:text-sm`}
                      readOnly
                    />
                  </div>
                  <button
                    type="button"
                    onClick={() =>
                      copyCiecPassword(ciec?.credentials?.password)
                    }
                    className={`${
                      ciecPasswordCopied
                        ? "bg-green-500 border-green-500"
                        : "bg-v2-blue-text-login border-v2-blue-text-login"
                    } transition-all relative w-14 -ml-px inline-flex items-center justify-center space-x-2 rounded-r-md border px-4 py-2 text-sm font-medium text-white`}
                  >
                    <div className="relative w-full h-full">
                      <ClipboardDocumentIcon
                        className={`${
                          !ciecPasswordCopied ? "opacity-100" : "opacity-0"
                        } inset-0 absolute transition-all h-5 w-5 text-white`}
                        aria-hidden="true"
                      />
                      <ClipboardDocumentCheckIcon
                        className={`${
                          ciecPasswordCopied ? "opacity-100" : "opacity-0"
                        } inset-0 absolute transition-all h-5 w-5 text-white`}
                        aria-hidden="true"
                      />
                    </div>
                  </button>
                </div>
              </dd>
            </div>
          </PermissionsGate>
        </dl>
      )}
    </main>
  );
}

SatCredentialsInfo.propTypes = {
  data: PropTypes.object,
  setAlert: PropTypes.func,
};

export default SatCredentialsInfo;
