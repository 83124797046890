import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

import { XMarkIcon } from "@heroicons/react/24/solid";

import Modal from "../../tailwindUI/Modal";
import PrimaryButton from "../../tailwindUI/PrimaryButton";
import SecondaryButton from "../../tailwindUI/SecondaryButton";

import { AuthContext } from "../../cognito/AuthProvider";
import {
  assignASF,
  releaseASF,
} from "../../apiClient/operations/ordersOperations";

export function UpdateOrderAsfModal({
  orderId,
  operation,
  updateOrder,
  showModal,
  setShowModal,
  setAlert,
}) {
  const { user } = useContext(AuthContext);
  const [isUpdating, setIsUpdating] = useState(false);

  const copieConfig = {
    assign: {
      title: "¿Estás seguro de que deseas asignarte el ASF?",
      confirm: "Asignarme ASF",
    },
    release: {
      title: "¿Estás seguro de que deseas liberar el ASF?",
      confirm: "Liberar ASF",
    },
  };

  const handleOrderASPOperation = async (e) => {
    try {
      e.preventDefault();
      setIsUpdating(true);

      let message = "";

      if (operation === "assign") {
        const newOrder = await assignASF({
          orderId,
          name: user.name,
          email: user.email,
        });
        message = "ASF asignado correctamente";
        updateOrder(newOrder);
      } else if (operation === "release") {
        const newOrder = await releaseASF({ orderId });
        message = "ASF liberado correctamente";
        updateOrder(newOrder);
      }
      setAlert({
        active: true,
        type: "success",
        message,
      });
    } catch (error) {
      setAlert({
        active: true,
        type: "error",
        message: error.message || "Error al procesar tu solicitud",
      });
    } finally {
      setShowModal(false);
      setIsUpdating(false);
    }
  };

  return (
    <Modal
      show={showModal}
      setShow={setShowModal}
      className="min-w-full sm:min-w-[500px]"
    >
      <div className="py-2 px-2 space-y-3">
        <div className="relative">
          <XMarkIcon
            className="absolute w-7 h-7 -right-3 -top-3 cursor-pointer text-gray-500"
            onClick={() => setShowModal(false)}
          />
        </div>
        <form onSubmit={handleOrderASPOperation}>
          <h3 className="text-lg text-center font-medium leading-6 text-gray-900">
            {copieConfig[operation]?.title}
          </h3>
          <div className="w-fit mt-6 ml-auto space-x-2">
            <SecondaryButton
              type="button"
              onClick={() => setShowModal(false)}
              disabled={isUpdating}
            >
              Cancelar
            </SecondaryButton>
            <PrimaryButton type="submit" disabled={isUpdating}>
              {copieConfig[operation]?.confirm}
            </PrimaryButton>
          </div>
        </form>
      </div>
    </Modal>
  );
}

UpdateOrderAsfModal.propTypes = {
  orderId: PropTypes.string,
  operation: PropTypes.string,
  updateOrder: PropTypes.func,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  setAlert: PropTypes.func,
};
