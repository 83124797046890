import apiClient from '../apiClient';
import { apiBaseUrl } from '../../config';
import { translateEmail } from './accountsOperations';

export const createContract = async (request) => {
    return apiClient.post(`${apiBaseUrl}/contracts/`, request)
    .then(response => { return response });
}

export const updateContract = async (request, contractId) => {
    return apiClient.put(`${apiBaseUrl}/contracts/${contractId}`, request)
    .then(response => { return response });
}
export const activeContract = async (request, contractId) => {
    return apiClient.post(`${apiBaseUrl}/contracts/${contractId}/active`, request)
    .then(response => { return response });
}

export const getContracts = async (page, limit, search, filters, sort) => {
    const searchString = search?.includes('@') ? translateEmail(search) : search;
    return apiClient.get(`${apiBaseUrl}/contracts/search?page=${page}&limit=${limit}&q=wildCard~${searchString || ''}${filters || ''}&${ sort || 'field=created&order=-1'}`)
    .then(response => { return response })
}

export const getContract = async (contractId) => {
    return apiClient.get(`${apiBaseUrl}/contracts/${contractId}`)
    .then(response => { return response })
}

export const getActiveContract = async (accountId) => {
    return apiClient.get(`${apiBaseUrl}/contracts/contract/${accountId}`)
    .then(response => { return response })
}