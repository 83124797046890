import apiClient from '../apiClient';
import { apiBaseUrl } from '../../config';

export const getConnections = async (accountId) => {
    return apiClient.get(`${apiBaseUrl}/connections/admin/${accountId}/connections`, {})
    .then(response => { return response });
}

export const getConnectionsLink = async (accountId) => {
    return apiClient.get(`${apiBaseUrl}/connections/link/${accountId}`, {})
    .then(response => { return response });
}

export const retryCiecValidation = async (accountId, connectionId) => {
    return apiClient.post(`${apiBaseUrl}/connections/admin/${accountId}/validate/${connectionId}`, {})
    .then(response => { return response });
}