import { apiNodeBaseUrl } from "../../config";

export const retrieveContactByEmail = async (email) => {
    try {
        const formatedEmail = email ? email.replace('@', '%40') : '';
        const fetchOperation = await fetch(`${apiNodeBaseUrl}active-campaign/retrieve-contact/${formatedEmail}`, {
            method: 'GET',
        });
        const res = await fetchOperation.json();
        return res
    } catch (e) {
        console.log(e);
        return {contacts: []}
    }
}