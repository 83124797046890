import React, { useState, useEffect, useContext } from 'react';
import { ChevronDownIcon, EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Transition } from '@headlessui/react';
import PropTypes from 'prop-types';
import LinkButton from '../../../tailwindUI/LinkButton';
import PrimaryButton from '../../../tailwindUI/PrimaryButton';
import SecondaryButton from '../../../tailwindUI/SecondaryButton';
import SlideOver from '../../../tailwindUI/SlideOver';
import Input from '../../../tailwindUI/Input';
import SelectCustom from '../../../tailwindUI/SelectCustom';
import SecondaryLoader from '../../../hooks/SecondaryLoader';
import SkeletonLoader from '../../../tailwindUI/SkeletonLoader';
import { AlertContext } from '../../../context/AlertContext';
import { getTaxRegimeList } from '../../../apiClient/operations/sat';
import { rfcRegex, phoneRegex, emailRegex, zipRegex, nameRegex } from '../../../hooks/useRegex';
import { getAccount, updateGeneralData, updateInvoicingData } from '../../../apiClient/operations/accountsOperations';
import useSeparateFullname from '../../../hooks/useSeparateFullname';
import { changeStage } from '../../../apiClient/operations/stagesOperations';
import { useHistory } from "react-router-dom";
import Modal from '../../../tailwindUI/Modal';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import MenuButton from '../../../tailwindUI/MenuButton';
import PermissionsGate from '../../../cognito/permissions/PermissionsGate';
import { ROLES } from '../../../cognito/permissions/permission-maps';
import { AuthContext } from '../../../cognito/AuthProvider';
import LongAlert from '../../../tailwindUI/LongAlert';

const legalTypeList = [
    { id: 1, title: 'Persona Física', type: 'personal' },
    { id: 2, title: 'Persona Moral', type: 'enterprise' }
]

function GeneralData({ account, setAccount, setStep }) {

    return (
        <div className='flex flex-col gap-4'>
            <ContractorData data={account} setData={setAccount} />
            <InvoicingData accountData={account} data={account?.billing} setData={setAccount} />
            <div className='flex justify-end pb-12 md:pb-4'>
                <div className='w-full md:w-1/3'>
                    <PrimaryButton isFullWidth onClick={() => setStep(2)}>
                        Siguiente
                    </PrimaryButton>
                </div>
            </div>
        </div>
    )
}

export function ContractorData({ data, setData, editable, expandible, isLoadingData, isTaxpayer }) {
    const { alert, setAlert } = useContext(AlertContext);
    const [showAlert, setShowAlert] = useState(false);
    //const [alert, setAlert] = useState(false);
    const { separateFullname } = useSeparateFullname();
    const [openSlide, setOpenSlide] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [modifyEmail, setModifyEmaill] = useState(false);
    const [errors, setErrors] = useState(null);
    const [isLoading, setIsloading] = useState(false);
    const [secondaryLoader, setSecondaryLoader] = useState(false);
    const [legalType, setLegalType] = useState(null);
    const [regimeList, setRegimeList] = useState(null);
    const [regime, setRegime] = useState(null);
    const [regimeOptionsList, setRegimeOptionsList] = useState(null);
    const [expanded, setExpanded] = useState(!expandible);
    const history = useHistory();
    const { user } = useContext(AuthContext);
    const leadActions = [
        {
            id: 1,
            name: 'Editar',
            action: () => setOpenSlide(true)
        },
        {
            id: 2,
            name: 'Convertir en prospecto',
            action: () => changeToProspect()
        },
    ];

    useEffect(() => {
        if (!openSlide || regimeList) return;
        const getListTaxRegime = async () => {
            try {
                const regimeList = await getTaxRegimeList();
                setRegimeList(regimeList);
                setRegimeOptionsList(filterRegimeList(regimeList));
            } catch (error) {
                setAlert({ active: true, type: 'error', message: 'Error al procesar tu solicitud' })
            }
        }

        if (!editable) return;
        getListTaxRegime();
        return () => {
            setRegimeOptionsList([]);
        }
    }, [openSlide, regimeList]);

    useEffect(() => {
        if (!openSlide) {
            setModifyEmaill(false);
            setErrors(null);
            setLegalType(legalTypeList.find(item => item.type == data?.legal_type)?.title);
            setRegime(data?.taxpayer?.regime);
            setAlert({ active: false })
        }
    }, [openSlide])

    useEffect(() => {
        if (!data) return;
        setErrors(null);
        setLegalType(legalTypeList.find(item => item.type == data.legal_type)?.title);
        setRegime(data?.taxpayer?.regime);
        setRegimeOptionsList(filterRegimeList(regimeList, data.legal_type));
    }, [data, regimeList])

    useEffect(() => {
        if (!regimeList || !legalType) return;
        if (errors?.rfc) setErrors({ ...errors, rfc: null });
        if (errors?.legalType) setErrors({ ...errors, legalType: null });
        const taxPersonType = legalTypeList.find(item => item.title == legalType)?.type;
        setRegimeOptionsList(filterRegimeList(regimeList, taxPersonType));
        const valid = regimeList.find(item => item.descripcion == regime && (taxPersonType == 'personal' && item.fisica == 'Sí' || taxPersonType == 'enterprise' && item.moral == 'Sí'));
        if (regime && taxPersonType && !valid) setRegime(null);
    }, [legalType])

    useEffect(() => {
        if (regime && errors?.regime) setErrors({ ...errors, regime: null });
    }, [regime])

    useEffect(() => {
        alert.active ? setShowAlert(true) : setShowAlert(false)
    }, [alert]);

    const filterRegimeList = (regimeList, taxPersonType) => {
        if (!regimeList) return null;
        if (taxPersonType) {
            return regimeList.reduce((filtered, item) => {
                const validRegime = taxPersonType == 'personal' && item.fisica == 'Sí' || taxPersonType == 'enterprise' && item.moral == 'Sí';
                if (validRegime) {
                    filtered.push({
                        id: item.id,
                        title: item.descripcion
                    });
                }
                return filtered;
            }, []);
        } else {
            return regimeList.map((regime) => ({
                id: regime.id,
                title: regime.descripcion
            }))
        }
    }

    const handleName = e => {
        const name = e.target.value;
        if (!errors || !errors.name) return;
        if (nameRegex(name)) setErrors({ ...errors, name: null });
    }

    const handleEmail = e => {
        const email = e.target.value;
        if (email != data?.email) {
            setModifyEmaill(true)
        } else {
            setModifyEmaill(false)
        }
        if (!errors || !errors.email) return;
        if (emailRegex(email)) setErrors({ ...errors, email: null })
    }

    const handleRepeatEmail = e => {
        const email = e.target.value;
        if (!errors || !errors.repeat_email) return;
        if (emailRegex(email)) setErrors({ ...errors, repeat_email: null })
    }

    const handleRfc = e => {
        const rfc = e.target.value.toUpperCase();
        e.target.value = rfc;

        if (!errors || !errors.rfc) return;
        let taxPersonType = legalTypeList.find(item => item.title == legalType)?.type;
        if ((taxPersonType == 'enterprise' && rfc.length == 12) ||
            (taxPersonType == 'personal' && rfc.length == 13) ||
            rfcRegex(rfc)) {
            setErrors({ ...errors, rfc: null });
        }
    }

    const handlePhone = e => {
        const phone = e.target.value;
        if (!errors || !errors.phone) return;
        if (phoneRegex(phone)) setErrors({ ...errors, phone: null })
    }

    const handleCity = e => {
        const city = e.target.value;
        if (!errors || !errors.city) return;
        if (nameRegex(city)) setErrors({ ...errors, city: null });
    }

    const handleoccupation = e => {
        const occupation = e.target.value;
        if (!errors || !errors.occupation) return;
        if (nameRegex(occupation)) setErrors({ ...errors, occupation: null });
    }

    const changeToProspect = () => {
        setSecondaryLoader(true)
        changeStage(data.id, "prospect").then((response) => {
            console.log(response)
            history.push(`/prospects/view/${data.id}`)
            getAccount(data.id).then((response) => {
                setData(response)
                setSecondaryLoader(false);
                setOpenModal(true)
                setOpenSlide(false)
            }).catch((e) => {
                setSecondaryLoader(false);
                console.log(e)
                setAlert({ active: true, type: 'error', message: 'Error al procesar tu solicitud' })
            })
        }).catch((error) => {
            console.log(error)
            setSecondaryLoader(false)
            setAlert({ active: true, type: 'error', message: 'Error al procesar tu solicitud' })
        })
    }

    const modifyDataClient = async (e) => {
        e.preventDefault();
        setErrors(null);

        const name = e.target.elements.name.value;
        const email = e.target.elements.email?.value;
        const repeatEmail = modifyEmail ? e.target.elements.repeat_email?.value : "";
        const rfc = e.target.elements.rfc.value;
        const phone = e.target.elements.phone.value;
        const city = e.target.elements.city.value;
        const occupation = e.target.elements.occupation.value;

        let err = {};

        if (name.length < 3) {
            err.name = "Ingresa correctamente el nombre";
        } else if (!nameRegex(name)) {
            err.name = "Ingresa un nombre válido"
        }
        
        if (email) {
            if (!emailRegex(email)) {
                err.email = "Ingresa un email válido"
            } else if (repeatEmail != email && modifyEmail) {
                err.repeat_email = "Los emails no coinciden"
            }
        }

        let taxPersonType = legalTypeList.find(item => item.title == legalType)?.type;
        if ((taxPersonType == 'enterprise' ? rfc.length != 12 :
            (taxPersonType == 'personal' && rfc.length != 13)) ||
            !rfcRegex(rfc)) {
            err.rfc = "Ingresa correctamente el RFC";
        }

        if (legalType == null || legalType == "") err.legalType = "Selecciona el tipo de persona fiscal";

        if (isTaxpayer) {
            if (regime == null || regime == "") err.regime = "Selecciona el régimen fiscal";

            if (!phoneRegex(phone)) {
                err.phone = "Ingresa correctamente el número";
            }

            if (city.length == 0) {
                err.city = "Ingresa correctamente la ciudad";
            } else if (!nameRegex(city)) {
                err.city = "Ingresa correctamente la ciudad";
            }

            if (occupation.length == 0) {
                err.occupation = "Ingresa correctamente la ocupación";
            } else if (!nameRegex(occupation)) {
                err.occupation = "Ingresa correctamente la ocupación";
            }
        }

        if (Object.keys(err).length === 0) {
            const { name: firstName, lastName: middleName, secondLastName } = separateFullname(name);
            const lastName = middleName + (secondLastName ? ` ${secondLastName}` : '');
            const accountdata = {
                name,
                first_name: firstName,
                last_name: lastName,
                legal_type: taxPersonType,
                phone_number: `+52${phone}`,
                state: city,
                occupancy: occupation,
                taxpayer: {
                    regime,
                    tax_id: rfc
                },
                ...(email && {
                    email: email,
                    confirm_email: repeatEmail,
                })
            }
            try {
                setIsloading(true);
                await updateGeneralData(accountdata, data?.id);
                if (setData) setData({
                    ...data,
                    ...accountdata
                });
                setOpenSlide(false);
                setIsloading(false);
            } catch (e) {
                setIsloading(false);
                setAlert({ active: true, type: 'error', message: e.message })
            }
        } else {
            setErrors(err);
        }
    }

    return (
        <>
            {secondaryLoader && <SecondaryLoader />}
            <div className="overflow-hidden bg-white shadow rounded-lg border border-gray-200">
                <div className="px-4 py-5 sm:px-6 flex justify-between">
                    <div>
                        {isLoadingData ? (
                            <div className='w-[30vw]'>
                                <SkeletonLoader />
                            </div>
                        ) : (
                            <h3 className="text-lg font-medium leading-6 text-gray-900">{isTaxpayer ? 'Contribuyente' : 'Cliente'}</h3>
                        )}
                    </div>
                    <div className='flex gap-2'>

                        {!isLoadingData && (
                            <>
                                {editable && data?.account_type == 'lead' && (user?.roles?.includes(ROLES.root) || user?.roles?.includes(ROLES.administrators) || user?.roles?.includes(ROLES.help_desk)) ?

                                    (
                                        <PermissionsGate allowedRoles={[ROLES.root, ROLES.administrators, ROLES.help_desk]}>
                                            <MenuButton
                                                items={leadActions}>
                                                <EllipsisVerticalIcon className="min-w-[1.25rem] w-6 h-6 text-gray-900" />
                                            </MenuButton>
                                        </PermissionsGate>
                                    )
                                    :
                                    (
                                        <LinkButton onClick={() => setOpenSlide(true)}>
                                            Editar
                                        </LinkButton>
                                    )}
                            </>
                        )}
                        {expandible && (
                            <button onClick={() => setExpanded(!expanded)}>
                                <ChevronDownIcon
                                    className={`${expanded ? '-rotate-180' : 'rotate-0'} h-6 w-6 transform transition`}
                                    aria-hidden="true"
                                />
                            </button>
                        )}
                    </div>
                </div>
                <Transition
                    show={expanded}
                    enter="transition-all ease-in"
                    enterFrom="max-h-0 opacity-0"
                    enterTo="max-h-[50rem] opacity-100"
                    leave="transition-all ease-out"
                    leaveFrom="max-h-[50rem] opacity-100"
                    leaveTo="max-h-0 opacity-0">
                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            <div className="sm:col-span-1">
                                {isLoadingData ?
                                    <SkeletonLoader />
                                    :
                                    <>
                                        <dt className="text-sm font-medium text-gray-500">Nombre</dt>
                                        <dd className={`mt-1 text-sm ${data?.name || data?.first_name ? 'text-gray-900' : 'text-gray-400'}`}>
                                            {editable ?
                                                data?.name || data?.first_name ? `${data?.first_name || ''} ${data?.last_name || ''}` : 'Agregar nombre'
                                                :
                                                data?.name || data?.first_name ? `${data?.first_name || ''} ${data?.last_name || ''}` : 'Nombre no añadido'
                                            }
                                        </dd>
                                    </>
                                }
                            </div>
                            <div className="sm:col-span-1">
                                {isLoadingData ?
                                    <SkeletonLoader />
                                    :
                                    <>
                                        <dt className="text-sm font-medium text-gray-500">RFC</dt>
                                        <dd className={`mt-1 text-sm ${data?.taxpayer?.tax_id ? 'text-gray-900' : 'text-gray-400'}`}>
                                            {editable ?
                                                data?.taxpayer?.tax_id || 'Agregar RFC'
                                                :
                                                data?.taxpayer?.tax_id || 'RFC no añadido'
                                            }
                                        </dd>
                                    </>
                                }
                            </div>
                            <div className="sm:col-span-1">
                                {isLoadingData ?
                                    <SkeletonLoader />
                                    :
                                    <>
                                        <dt className="text-sm font-medium text-gray-500">Tipo de persona fiscal</dt>
                                        <dd className={`mt-1 text-sm ${data?.legal_type ? 'text-gray-900' : 'text-gray-400'}`}>
                                            {editable ?
                                                legalTypeList.find(item => item.type == data?.legal_type)?.title || 'Agregar tipo de persona fiscal'
                                                :
                                                legalTypeList.find(item => item.type == data?.legal_type)?.title || 'Tipo de persona fiscal no añadido'
                                            }
                                        </dd>
                                    </>
                                }
                            </div>
                            <div className="sm:col-span-1">
                                {isLoadingData ?
                                    <SkeletonLoader />
                                    :
                                    <>
                                        <dt className="text-sm font-medium text-gray-500">Régimen fiscal</dt>
                                        <dd className={`mt-1 text-sm ${data?.taxpayer?.regime ? 'text-gray-900' : 'text-gray-400'}`}>
                                            {editable ?
                                                data?.taxpayer?.regime || 'Agregar régimen fiscal'
                                                :
                                                data?.taxpayer?.regime || 'Régimen fiscal no añadido'
                                            }
                                        </dd>
                                    </>
                                }
                            </div>
                            <div className="sm:col-span-1">
                                {isLoadingData ?
                                    <SkeletonLoader />
                                    :
                                    <>
                                        <dt className="text-sm font-medium text-gray-500">Correo</dt>
                                        <dd className={`mt-1 text-sm ${data?.email ? 'text-gray-900' : 'text-gray-400'}`}>
                                            {editable ?
                                                data?.email || 'Agregar correo'
                                                :
                                                data?.email || 'Correo no añadido'
                                            }
                                        </dd>
                                    </>
                                }
                            </div>
                            <div className="sm:col-span-1">
                                {isLoadingData ?
                                    <SkeletonLoader />
                                    :
                                    <>
                                        <dt className="text-sm font-medium text-gray-500">Móvil</dt>
                                        <dd className={`mt-1 text-sm ${data?.phone_number ? 'text-gray-900' : 'text-gray-400'}`}>
                                            {editable ?
                                                data?.phone_number || 'Agregar número celular'
                                                :
                                                data?.phone_number || 'Número celular no añadido'
                                            }
                                        </dd>
                                    </>
                                }
                            </div>
                            <div className="sm:col-span-1">
                                {isLoadingData ?
                                    <SkeletonLoader />
                                    :
                                    <>
                                        <dt className="text-sm font-medium text-gray-500">Ciudad de residencia</dt>
                                        <dd className={`mt-1 text-sm ${data?.state ? 'text-gray-900' : 'text-gray-400'}`}>
                                            {editable ?
                                                data?.state || 'Agregar ciudad de residencia'
                                                :
                                                data?.state || 'Ciudad de residencia no añadida'
                                            }
                                        </dd>
                                    </>
                                }
                            </div>
                            <div className="sm:col-span-1">
                                {isLoadingData ?
                                    <SkeletonLoader />
                                    :
                                    <>
                                        <dt className="text-sm font-medium text-gray-500">Ocupación</dt>
                                        <dd className={`mt-1 text-sm ${data?.occupancy ? 'text-gray-900' : 'text-gray-400'}`}>
                                            {editable ?
                                                data?.occupancy || 'Agregar ocupación'
                                                :
                                                data?.occupancy || 'Ocupación no añadida'
                                            }
                                        </dd>
                                    </>
                                }
                            </div>
                        </dl>
                    </div>
                </Transition>
            </div>
            <SlideOver open={openSlide} setOpen={setOpenSlide} title={`Editar ${isTaxpayer ? 'contribuyente' : 'cliente'}`}>
                <form onSubmit={modifyDataClient} className="h-full">
                    <div className='w-full h-full flex flex-col justify-between gap-6'>
                        {showAlert &&
                            <div className="w-full sticky top-20 z-[30] mt-0 mb-14">
                                <LongAlert show={showAlert} onClose={setShowAlert} type={alert.type} title={alert.message} className="px-0" />
                            </div>
                        }
                        <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6">
                                <Input
                                    label="Nombre"
                                    defaultValue={data?.name || data?.first_name ? `${data?.first_name} ${data?.last_name}` : null}
                                    inputMode="text"
                                    type="text"
                                    id="name"
                                    name="name"
                                    autoComplete="off"
                                    onChange={handleName}
                                    needed
                                    error={errors?.name} />
                            </div>
                            <PermissionsGate allowedRoles={[ROLES.root, ROLES.help_desk, ROLES.acquisitions]}>
                                <div className={`col-span-6 flex flex-col`}>
                                    <div className="col-span-6">
                                        <Input
                                            label="Email"
                                            defaultValue={data?.email}
                                            inputMode="email"
                                            type="email"
                                            id="email"
                                            name="email"
                                            autoComplete="off"
                                            onChange={handleEmail}
                                            needed
                                            error={errors?.email} />
                                    </div>
                                    <div className={`col-span-6 ${modifyEmail ? 'input-visible' : 'input-invisible'} `}>
                                        <Input
                                            label="Confirma el email"
                                            defaultValue={""}
                                            inputMode="email"
                                            type="email"
                                            id="repeat_email"
                                            name="repeat_email"
                                            autoComplete="off"
                                            onChange={handleRepeatEmail}
                                            needed
                                            error={errors?.repeat_email}
                                            onPaste={(e) => {
                                                e.preventDefault();
                                                return false;
                                            }}
                                            onCopy={(e) => {
                                                e.preventDefault();
                                                return false;
                                            }}
                                            onDrag={(e) => {
                                                e.preventDefault();
                                                return false;
                                            }}
                                        />
                                    </div>
                                </div>
                            </PermissionsGate>

                            <div className="col-span-6">
                                <Input
                                    label="RFC"
                                    defaultValue={data?.taxpayer?.tax_id}
                                    inputMode="text"
                                    type="text"
                                    id="rfc"
                                    name="rfc"
                                    autoComplete="off"
                                    onChange={handleRfc}
                                    needed
                                    maxLength={13}
                                    error={errors?.rfc} />
                            </div>

                            <div className="col-span-6">
                                <SelectCustom
                                    label="Tipo de persona fiscal"
                                    id="legal_type"
                                    name="legal_type"
                                    listOptions={legalTypeList}
                                    value={legalType}
                                    setValue={value => setLegalType(value)}
                                    needed
                                    error={errors?.legalType}
                                />
                            </div>

                            <div className="col-span-6">
                                <SelectCustom
                                    label="Régimen fiscal"
                                    id="regime"
                                    name="regime"
                                    listOptions={regimeOptionsList}
                                    value={regime}
                                    setValue={value => setRegime(value)}
                                    needed={isTaxpayer}
                                    error={errors?.regime} />
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                                <Input
                                    label="Móvil"
                                    defaultValue={data?.phone_number && data.phone_number.substr(-10)}
                                    inputMode="tel"
                                    id="phone"
                                    name="phone"
                                    autoComplete="off"
                                    onChange={handlePhone}
                                    maxLength={10}
                                    type="tel"
                                    needed={isTaxpayer}
                                    error={errors?.phone} />
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                                <Input
                                    label="Ciudad de residencia"
                                    defaultValue={data?.state}
                                    inputMode="text"
                                    id="city"
                                    name="city"
                                    autoComplete="off"
                                    onChange={handleCity}
                                    type="text"
                                    needed={isTaxpayer}
                                    error={errors?.city} />
                            </div>

                            <div className="col-span-6">
                                <Input
                                    label="Ocupación"
                                    defaultValue={data?.occupancy}
                                    inputMode="text"
                                    id="occupation"
                                    name="occupation"
                                    autoComplete='off'
                                    onChange={handleoccupation}
                                    type="text"
                                    needed={isTaxpayer}
                                    error={errors?.occupation} />
                            </div>
                        </div>
                        <div className={`w-full flex justify-end gap-4 pb-6`}>
                            <SecondaryButton isFullWidth type="button" onClick={() => setOpenSlide(false)}>
                                Cancelar
                            </SecondaryButton>
                            <PrimaryButton isFullWidth type="submit">
                                Guardar
                            </PrimaryButton>
                        </div>
                        {/* {
                            data?.account_type == 'lead' &&
                            <div className="w-full flex justify-end pb-6">
                                <ButtonOrange isFullWidth={true} type="button" onClick={changeToProspect}>
                                    Hacer prospecto
                                </ButtonOrange>
                            </div>
                        } */}
                    </div>
                </form>
            </SlideOver>
            <Modal show={openModal} setShow={setOpenModal} className='min-w-full sm:min-w-[500px]'>
                <div className="py-2 px-2 text-center">
                    <div className='w-full text-gray-400 flex justify-end'><XMarkIcon className='w-7 h-7 cursor-pointer' onClick={() => setOpenModal(false)} /></div>
                    <div className='flex justify-center'>
                        <CheckCircleIcon className='w-20 h-20 text-green-600' />
                    </div>
                    <h3 className="text-lg font-medium leading-6 text-gray-900">Prospecto creado</h3>
                    <div className="mt-2 max-w-xl text-sm text-gray-500">
                        <p>El lead ha sido pasado a prospecto éxitosamente</p>
                    </div>
                </div>
            </Modal>
            {isLoading && <SecondaryLoader message="Guardando información..." />}
        </>
    )
}



export function InvoicingData({ accountData, data, setData, editable, expandible, isLoadingData }) {

    const { setAlert } = useContext(AlertContext);
    const [openSlide, setOpenSlide] = useState(false);
    const [errors, setErrors] = useState(null);
    const [isLoading, setIsloading] = useState(false);
    const [legalType, setLegalType] = useState(null);
    const [regimeList, setRegimeList] = useState(null);
    const [regime, setRegime] = useState(null);
    const [regimeOptionsList, setRegimeOptionsList] = useState(null);
    const [expanded, setExpanded] = useState(!expandible);

    useEffect(() => {
        if (!openSlide || regimeList) return;
        const getListTaxRegime = async () => {
            try {
                const regimeList = await getTaxRegimeList();
                setRegimeList(regimeList);
                setRegimeOptionsList(filterRegimeList(regimeList));
            } catch (error) {
                setAlert({ active: true, type: 'error', message: 'Error al procesar tu solicitud' })
            }
        }

        if (!editable) return;
        getListTaxRegime();
        return () => {
            setRegimeOptionsList([]);
        }
    }, [openSlide, regimeList]);

    useEffect(() => {
        if (!openSlide) {
            setErrors(null);
            setLegalType(legalTypeList.find(item => item.type == data?.legal_type)?.title);
            setRegime(data?.tax_regime);
        }
    }, [openSlide])

    useEffect(() => {
        if (!data) return;
        setErrors(null);
        setLegalType(legalTypeList.find(item => item.type == data.legal_type)?.title);
        setRegime(data?.tax_regime);
        setRegimeOptionsList(filterRegimeList(regimeList, data.legal_type));
    }, [data, regimeList])

    useEffect(() => {
        if (!regimeList || !legalType) return;
        if (errors?.rfc) setErrors({ ...errors, rfc: null });
        if (errors?.legalType) setErrors({ ...errors, legalType: null });
        const taxPersonType = legalTypeList.find(item => item.title == legalType)?.type;
        setRegimeOptionsList(filterRegimeList(regimeList, taxPersonType));
        const valid = regimeList.find(item => item.descripcion == regime && (taxPersonType == 'personal' && item.fisica == 'Sí' || taxPersonType == 'enterprise' && item.moral == 'Sí'));
        if (regime && taxPersonType && !valid) setRegime(null);
    }, [legalType])

    useEffect(() => {
        if (regime && errors?.regime) setErrors({ ...errors, regime: null });
    }, [regime])

    const filterRegimeList = (regimeList, taxPersonType) => {
        if (!regimeList) return null;
        if (taxPersonType) {
            return regimeList.reduce((filtered, item) => {
                const validRegime = taxPersonType == 'personal' && item.fisica == 'Sí' || taxPersonType == 'enterprise' && item.moral == 'Sí';
                if (validRegime) {
                    filtered.push({
                        id: item.id,
                        title: item.descripcion
                    });
                }
                return filtered;
            }, []);
        } else {
            return regimeList.map((regime) => ({
                id: regime.id,
                title: regime.descripcion
            }))
        }
    }

    const handleName = e => {
        const name = e.target.value;
        if (!errors || !errors.name) return;
        if (nameRegex(name)) setErrors({ ...errors, name: null });
    }

    const handleRfc = e => {
        const rfc = e.target.value.toUpperCase();
        e.target.value = rfc;

        if (!errors || !errors.rfc) return;
        let taxPersonType = legalTypeList.find(item => item.title == legalType)?.type;
        if ((taxPersonType == 'enterprise' && rfc.length == 12) ||
            (taxPersonType == 'personal' && rfc.length == 13) ||
            rfcRegex(rfc)) {
            setErrors({ ...errors, rfc: null });
        }
    }

    const handleEmail = e => {
        const email = e.target.value;
        if (!errors || !errors.email) return;
        if (emailRegex(email)) setErrors({ ...errors, email: null })
    }

    const handleZip = e => {
        const zip = e.target.value;
        if (!errors || !errors.zip) return;
        if (zipRegex(zip)) setErrors({ ...errors, zip: null });
    }

    const modifyDataClient = async (e) => {
        e.preventDefault();
        setErrors(null);

        const name = e.target.elements.name.value;
        const rfc = e.target.elements.rfc.value;
        const email = e.target.elements.email.value;
        const zip = e.target.elements.zip.value;

        let err = {};

        if (name.length < 3) {
            err.name = "Ingresa correctamente el nombre";
        } else if (!nameRegex(name)) {
            err.name = "Ingresa un nombre válido";
        }

        let taxPersonType = legalTypeList.find(item => item.title == legalType)?.type;
        if (((taxPersonType == 'enterprise' ? rfc.length != 12 :
            (taxPersonType == 'personal' && rfc.length != 13)) ||
            !rfcRegex(rfc))) {
            err.rfc = "Ingresa correctamente el RFC";
        }

        if (legalType == null || legalType == "") err.legalType = "Selecciona el tipo de persona fiscal";
        if (regime == null || regime == "") err.regime = "Selecciona el régimen fiscal";

        if (!emailRegex(email)) {
            err.email = "Ingresa correctamente el correo";
        }

        if (!zipRegex(zip)) {
            err.zip = "Ingresa correctamente el código postal";
        }


        if (Object.keys(err).length === 0) {
            const billingData = {
                name,
                tax_id: rfc,
                tax_regime: regime,
                legal_type: taxPersonType,
                email,
                postal_code: zip
            }
            try {
                setIsloading(true);
                const billing = await updateInvoicingData(billingData, accountData?.id);
                if (setData) setData(billing);
                setOpenSlide(false);
                setIsloading(false);
            } catch (e) {
                setIsloading(false);
                setAlert({ active: true, type: 'error', message: e.message })
            }
        } else {
            setErrors(err);
        }
    }

    return (
        <>
            <div className="overflow-hidden bg-white shadow rounded-lg border border-gray-200">
                <div className="px-4 py-5 sm:px-6 flex justify-between">
                    <div>
                        {isLoadingData ? (
                            <div className='w-[30vw]'>
                                <SkeletonLoader />
                            </div>
                        ) : (
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Datos de facturación</h3>
                        )}
                    </div>
                    <div className='flex gap-2'>
                        {!isLoadingData &&
                            <>
                                {editable && (
                                    <LinkButton onClick={() => setOpenSlide(true)}>
                                        Editar
                                    </LinkButton>
                                )}
                            </>
                        }
                        {expandible && (
                            <button onClick={() => setExpanded(!expanded)}>
                                <ChevronDownIcon
                                    className={`${expanded ? '-rotate-180' : 'rotate-0'} h-6 w-6 transform transition`}
                                    aria-hidden="true"
                                />
                            </button>
                        )}
                    </div>
                </div>
                <Transition
                    show={expanded}
                    enter="transition-all ease-in"
                    enterFrom="max-h-0 opacity-0"
                    enterTo="max-h-[50rem] opacity-100"
                    leave="transition-all ease-out"
                    leaveFrom="max-h-[50rem] opacity-100"
                    leaveTo="max-h-0 opacity-0">
                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            <div className="sm:col-span-1">
                                {isLoadingData ?
                                    <SkeletonLoader />
                                    :
                                    <>
                                        <dt className="text-sm font-medium text-gray-500">Nombre o razón social</dt>
                                        <dd className={`mt-1 text-sm ${data?.name ? 'text-gray-900' : 'text-gray-400'}`}>
                                            {editable ?
                                                data?.name || 'Agregar nombre o razón social'
                                                :
                                                data?.name || 'Nombre o razón social no añadida'
                                            }
                                        </dd>
                                    </>
                                }
                            </div>
                            <div className="sm:col-span-1">
                                {isLoadingData ?
                                    <SkeletonLoader />
                                    :
                                    <>
                                        <dt className="text-sm font-medium text-gray-500">RFC</dt>
                                        <dd className={`mt-1 text-sm ${data?.tax_id ? 'text-gray-900' : 'text-gray-400'}`}>
                                            {editable ?
                                                data?.tax_id || 'Agregar RFC'
                                                :
                                                data?.tax_id || 'RFC no añadido'
                                            }
                                        </dd>
                                    </>
                                }
                            </div>
                            <div className="sm:col-span-1">
                                {isLoadingData ?
                                    <SkeletonLoader />
                                    :
                                    <>
                                        <dt className="text-sm font-medium text-gray-500">Tipo de persona fiscal</dt>
                                        <dd className={`mt-1 text-sm ${data?.legal_type ? 'text-gray-900' : 'text-gray-400'}`}>
                                            {editable ?
                                                legalTypeList.find(item => item.type == data?.legal_type)?.title || 'Agregar tipo de persona fiscal'
                                                :
                                                legalTypeList.find(item => item.type == data?.legal_type)?.title || 'Tipo de persona fiscal no añadido'
                                            }
                                        </dd>
                                    </>
                                }
                            </div>
                            <div className="sm:col-span-1">
                                {isLoadingData ?
                                    <SkeletonLoader />
                                    :
                                    <>
                                        <dt className="text-sm font-medium text-gray-500">Régimen fiscal</dt>
                                        <dd className={`mt-1 text-sm ${data?.tax_regime ? 'text-gray-900' : 'text-gray-400'}`}>
                                            {editable ?
                                                data?.tax_regime || 'Agregar régimen fiscal'
                                                :
                                                data?.tax_regime || 'Régimen fiscal no añadido'
                                            }
                                        </dd>
                                    </>
                                }
                            </div>
                            <div className="sm:col-span-1">
                                {isLoadingData ?
                                    <SkeletonLoader />
                                    :
                                    <>
                                        <dt className="text-sm font-medium text-gray-500">Correo</dt>
                                        <dd className={`mt-1 text-sm ${data?.email ? 'text-gray-900' : 'text-gray-400'}`}>
                                            {editable ?
                                                data?.email || 'Agregar correo'
                                                :
                                                data?.email || 'Correo no añadido'
                                            }
                                        </dd>
                                    </>
                                }
                            </div>
                            <div className="sm:col-span-1">
                                {isLoadingData ?
                                    <SkeletonLoader />
                                    :
                                    <>
                                        <dt className="text-sm font-medium text-gray-500">Código postal</dt>
                                        <dd className={`mt-1 text-sm ${data?.postal_code ? 'text-gray-900' : 'text-gray-400'}`}>
                                            {editable ?
                                                data?.postal_code || 'Agregar código postal'
                                                :
                                                data?.postal_code || 'Código postal no añadido'
                                            }
                                        </dd>
                                    </>
                                }
                            </div>
                        </dl>
                    </div>
                </Transition>
            </div>
            <SlideOver open={openSlide} setOpen={setOpenSlide} title="Editar datos de facturación">
                <form onSubmit={modifyDataClient} className="h-full">
                    <div className='w-full h-full flex flex-col justify-between gap-6'>
                        <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6">
                                <Input
                                    label="Nombre o razón social"
                                    defaultValue={data?.name}
                                    inputMode="text"
                                    type="text"
                                    id="name"
                                    name="name"
                                    autoComplete="off"
                                    onChange={handleName}
                                    needed
                                    error={errors?.name} />
                            </div>

                            <div className="col-span-6">
                                <Input
                                    label="RFC"
                                    defaultValue={data?.tax_id}
                                    inputMode="text"
                                    type="text"
                                    id="rfc"
                                    name="rfc"
                                    autoComplete="off"
                                    onChange={handleRfc}
                                    needed
                                    maxLength={13}
                                    error={errors?.rfc} />
                            </div>

                            <div className="col-span-6">
                                <SelectCustom
                                    label="Tipo de persona fiscal"
                                    id="legal_type"
                                    name="legal_type"
                                    listOptions={legalTypeList}
                                    value={legalType}
                                    setValue={value => setLegalType(value)}
                                    needed
                                    error={errors?.legalType}
                                />
                            </div>

                            <div className="col-span-6">
                                <SelectCustom
                                    label="Régimen fiscal"
                                    id="regime"
                                    name="regime"
                                    listOptions={regimeOptionsList}
                                    value={regime}
                                    setValue={value => setRegime(value)}
                                    needed
                                    error={errors?.regime} />
                            </div>

                            <div className="col-span-6">
                                <Input
                                    label="Correo"
                                    defaultValue={data?.email}
                                    inputMode="email"
                                    id="email"
                                    name="email"
                                    autoComplete="off"
                                    onChange={handleEmail}
                                    minLength={10}
                                    type="email"
                                    needed
                                    error={errors?.email} />
                            </div>

                            <div className="col-span-6">
                                <Input
                                    label="Código postal"
                                    defaultValue={data?.postal_code}
                                    inputMode="numeric"
                                    id="zip"
                                    name="zip"
                                    autoComplete="off"
                                    onChange={handleZip}
                                    type="text"
                                    needed
                                    maxLength={5}
                                    error={errors?.zip} />
                            </div>
                        </div>
                        <div className="w-full flex justify-end gap-4 pb-6">
                            <SecondaryButton isFullWidth type="button" onClick={() => setOpenSlide(false)}>
                                Cancelar
                            </SecondaryButton>
                            <PrimaryButton isFullWidth type="submit">
                                Guardar
                            </PrimaryButton>
                        </div>
                    </div>
                </form>
            </SlideOver>
            {isLoading && <SecondaryLoader message="Guardando información..." />}
        </>
    )
}

GeneralData.propTypes = {
    account: PropTypes.object,
    setAccount: PropTypes.func,
    setStep: PropTypes.func
}



ContractorData.propTypes = {
    data: PropTypes.object,
    setData: PropTypes.func,
    editable: PropTypes.bool,
    expandible: PropTypes.bool,
    isLoadingData: PropTypes.bool,
    isTaxpayer: PropTypes.bool
}

ContractorData.defaultProps = {
    editable: true,
    expandible: false,
    isLoadingData: false,
    isTaxpayer: true
}



InvoicingData.propTypes = {
    accountData: PropTypes.object,
    data: PropTypes.object,
    setData: PropTypes.func,
    editable: PropTypes.bool,
    expandible: PropTypes.bool,
    isLoadingData: PropTypes.bool
}

InvoicingData.defaultProps = {
    editable: true,
    expandible: false,
    isLoadingData: false
}

export default GeneralData;