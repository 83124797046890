import React, { useContext, useEffect, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";

import { uploadTaxpayersWorkflow } from "../../apiClient/operations/accountsOperations";
import SecondaryLoader from "../../hooks/SecondaryLoader";
import Button from "../../hooks/Button";
import Modal from "../../hooks/Modal";
import { rfcRegex } from "../../hooks/useRegex";
import { AlertContext } from "../../context/AlertContext";

import ButtonOrange from "../../tailwindUI/ButtonOrange";
import PrimaryButton from "../../tailwindUI/PrimaryButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import CSVReader from "react-csv-reader";
import { CSVLink } from "react-csv";
import ReactTooltip from "react-tooltip";

import {
  faCircleXmark,
  faCircleCheck,
  faTrash,
  faFileArchive,
} from "@fortawesome/free-solid-svg-icons";
import {
  DocumentArrowDownIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/solid";
import useFormatDate from "../../hooks/useFormatDate";

function Invoices() {
  const fileInputRef = useRef();
  const { setAlert } = useContext(AlertContext);
  const [selectedFile, setSelectedFile] = useState([]);
  const [showTooltip, setShowTooltip] = useState(true);
  const [secondaryLoader, setSecondaryLoader] = useState(false);
  const [taxpayerIdsError, setTaxpayerIdsError] = useState([]);
  const [errors, setErrors] = useState(null);
  const [warning, setWarning] = useState(null);
  const [tooltipTitle, setTooltipTitle] = useState("Ninguno");
  const [taxpayers, setTaxpayers] = useState(null);
  const [openConfirmUpdate, setOpenConfirmUpdate] = useState(false);
  const [loadingCSV, setLoadingCSV] = useState(false);
  const [dataCsv, setDataCsv] = useState([]);

  const headers = ["RFC", "Correo", "Periodo", "SKU", "Estado"];
  const { formatDate } = useFormatDate();
  const verifyTaxIds = (data) => {
    const taxIdPosition = data[0].findIndex(
      (header) => header.toLowerCase() == "rfc"
    );
    let taxIds = [];
    let taxIdsErr = [];
    let errosTaxId = 0;
    for (let index = 1; index < data.length; index++) {
      const taxId = data[index][taxIdPosition];
      !rfcRegex(taxId) ? errosTaxId++ : taxIds.push(taxId);
      !rfcRegex(taxId) && taxIdsErr.push(taxId);
    }
    setTaxpayerIdsError(taxIdsErr);
    return errosTaxId;
  };

  const formatDataToJson = (data) => {
    const headers = data[0].map((header) => header.toLowerCase());
    const taxIndex = headers.findIndex(
      (header) => header.toLowerCase() === "rfc"
    );
    const emailIndex = headers.findIndex(
      (header) => header.toLowerCase() === "correo"
    );
    const periodIndex = headers.findIndex(
      (header) => header.toLowerCase() === "periodo"
    );
    const skuIndex = headers.findIndex(
      (header) => header.toLowerCase() === "sku"
    );
    const rows = data.slice(1);

    const newTaxpayers = [...Array(rows.length).keys()].map((index) => {
      return {
        tax_id: rows[index][taxIndex],
        email: rows[index][emailIndex],
        period: rows[index][periodIndex],
        sku: rows[index][skuIndex],
      };
    });

    setTaxpayers(newTaxpayers);
  };

  useEffect(() => {
    if (taxpayerIdsError.length > 0) {
      let title = (
        <>
          {taxpayerIdsError.map((item, index) => (
            <>
              {index + 1}.- {item}
              <br></br>
            </>
          ))}
        </>
      );
      setTooltipTitle(title);
    }
  }, [taxpayerIdsError]);

  const hasEmptyValues = (data, numHeaders) => {
    let isEmpty = false;
    for (const row of data) {
      if (row.length !== numHeaders || row.some((value) => value === "")) {
        isEmpty = true;
        break;
      }
    }
    return isEmpty;
  };

  const uploadFile = (data, fileInfo, originalFile) => {
    console.log(fileInfo);
    setSelectedFile([originalFile]);
    const headers = data[0].map((header) => header.toLowerCase());

    if (data.length <= 1) {
      setErrors({ selectedFile: "El archivo no contiene información" });
    } else if (!headers.includes("rfc")) {
      setErrors({ selectedFile: "El archivo no contiene ningún campo RFC" });
    } else if (!headers.includes("correo")) {
      setErrors({ selectedFile: "El archivo no contiene ningún campo correo" });
    } else if (!headers.includes("periodo")) {
      setErrors({
        selectedFile: "El archivo no contiene ningún campo periodo",
      });
    } else if (!headers.includes("sku")) {
      setErrors({ selectedFile: "El archivo no contiene ningún campo sku" });
    } else if (hasEmptyValues(data.slice(1), headers.length)) {
      setErrors({ selectedFile: "El archivo contiene datos faltantes" });
    } else {
      const countErrors = verifyTaxIds(data);
      countErrors > 0 &&
        setWarning({
          selectedFile: `Hay ${countErrors} RFC ${
            countErrors > 1 ? "incorrectos" : "incorrecto"
          }, considere esto si desea continuar con el proceso.`,
        });
      formatDataToJson(data);
    }
  };

  const handleDeleteFile = () => {
    setSelectedFile([]);
    setErrors(null);
    setWarning(null);
    fileInputRef.current.value = "";
  };

  const generateCSV = (data) => {
    let rows = [];
    taxpayerIdsError.map((item) => {
      const row = [item, "", "", "", "RFC incorrecto"];
      rows.push(row);
    });
    data.map((item, index) => {
      const row = [
        item?.tax_id,
        item?.email,
        item?.period,
        item?.sku,
        item?.result,
      ];
      rows.push(row);
      index === data.length - 1 && setLoadingCSV(false);
    });

    setDataCsv(rows);
  };

  const updateInfo = async () => {
    setSecondaryLoader(true);
    try {
      const response = await uploadTaxpayersWorkflow(taxpayers);
      generateCSV(response.taxplayers);
      setOpenConfirmUpdate(true);
    } catch (error) {
      setAlert({
        active: true,
        type: "error",
        message: `Error al procesar tu solicitud`,
      });
    } finally {
      setSecondaryLoader(false);
    }
  };

  const tooltipRFC = (title) => (
    <>
      {showTooltip && (
        <ReactTooltip id={"errorRfc"} place="top" effect="solid" />
      )}
      <InformationCircleIcon
        className="cursor-pointer w-5 h-5 text-gray-300"
        data-for={"errorRfc"}
        data-html={true}
        data-tip={ReactDOMServer.renderToString(title)}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => {
          setShowTooltip(false);
          setTimeout(() => setShowTooltip(true), 50);
        }}
      />
    </>
  );

  return (
    <>
      {secondaryLoader && <SecondaryLoader />}
      <div className="w-full h-full">
        <div className="w-full xs:h-full md:h-full">
          <div className="mt-10 md:mt-0 w-full px-5 py-5  xs:h-auto md:h-1/12">
            <div className=" justify-between items-center w-full">
              <h1 className="hidden md:flex text-4xl font-bold text-left">
                Facturas
              </h1>
            </div>
          </div>

          <div className="w-full h-full border border-v2-gray-border-tables rounded-10 bg-white xs:mt-0 md:mt-3 ">
            <div className="w-full xs:px-2 lg:px-4 py-4 border-b border-v2-gray-border-tables flex items-center ">
              <div className="xs:w-11/12 md:w-10/12 text-xl font-semibold text-v2-input-text">
                Actualización facturas
              </div>
            </div>

            <div className="flex flex-col items-center gap-8 w-full max-w-lg mx-auto px-4 py-4 pb-10">
              <CSVReader
                onFileLoaded={uploadFile}
                ref={fileInputRef}
                cssClass="hidden"
              />
              {selectedFile.length == 0 ? (
                <div className="xs:mt-0 md:mt-2 w-full flex flex-col gap-1">
                  <label
                    htmlFor="email"
                    className="text-base text-v2-input-text"
                  >
                    Seleccionar csv
                  </label>
                  <ButtonOrange
                    onClick={() => {
                      fileInputRef.current.click();
                    }}
                  >
                    Subir CSV
                  </ButtonOrange>
                </div>
              ) : (
                <div className="w-full px-7 py-4 items-center mt-4">
                  <div className="w-full flex items-center py-2">
                    <div className="w-3/4 flex items-center">
                      <FontAwesomeIcon
                        icon={faFileArchive}
                        className="text-v2-gray-light-plus-circle text-3xl leading-3 "
                      />
                      <span className="text-v2-text-bar-steps text-[15px] pl-7">
                        {selectedFile[0].name}
                      </span>
                    </div>
                    <div className="w-1/4 flex justify-end text-v2-yellow-edit-info">
                      <FontAwesomeIcon
                        icon={faTrash}
                        className="leading-3 text-2xl cursor-pointer"
                        onClick={handleDeleteFile}
                      />
                    </div>
                  </div>
                  {errors?.selectedFile && (
                    <span className="block pt-0.5 text-v2-red-error-services-selected">
                      {errors?.selectedFile}
                    </span>
                  )}
                  {warning?.selectedFile && (
                    <div className="flex flex-row gap-1 pt-0.5 text-yellow-800">
                      <span className="w-11/12">{warning?.selectedFile}</span>{" "}
                      {tooltipRFC(tooltipTitle)}
                    </div>
                  )}
                </div>
              )}
              <Button
                disabled={!(selectedFile.length > 0 && errors == null)}
                heigth="h-12"
                bg="bg-v2-blue-text-login"
                onClick={updateInfo}
              >
                Actualizar
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        hasTitle={false}
        modalOpen={openConfirmUpdate}
        modalOpenChange={setOpenConfirmUpdate}
        maxWidth="max-w-lg"
      >
        <div className="w-full pt-2 pb-5 px-[9px]">
          <div className="w-full flex justify-end">
            <span
              className="w-full flex justify-end text-v2-gray-light-plus-circle leading-3 text-2xl cursor-pointer"
              onClick={() => setOpenConfirmUpdate(!openConfirmUpdate)}
            >
              <FontAwesomeIcon icon={faCircleXmark} />
            </span>
          </div>
          <div className="w-full mt-4">
            <span className="flex justify-center text-v2-border-alert-success leading-3 text-5xl">
              <FontAwesomeIcon icon={faCircleCheck} />
            </span>
          </div>
          <div className="w-full mt-5">
            <span className="w-full flex justify-center text-v2-gray-title-service-modal text-xl text-center font-bold">
              <span className="xs:w-full md:w-2/4">
                Facturas actualizadas correctamente
              </span>
            </span>
            <span className="w-full flex justify-center text-v2-gray-title-service-modal text-base text-center font-normal mt-3">
              <span className="sm:w-5/6 w-2/4">
                El proceso termino exitosamente, para ver si todos los datos
                fueron actualizados descarga el CSV.
              </span>
            </span>
          </div>
          <div className="mt-6 px-4">
            <CSVLink
              data={dataCsv}
              headers={headers}
              filename={`Actualización_fecha(${formatDate(
                new Date(),
                "DD-MM-YYYY"
              )}).csv`}
            >
              {!loadingCSV ? (
                <PrimaryButton isFullWidth>
                  Descargar CSV{" "}
                  <DocumentArrowDownIcon className="w-5 h-5 ml-2 inline-block" />
                </PrimaryButton>
              ) : (
                <PrimaryButton disabled isFullWidth>
                  Cargando...{" "}
                  <DocumentArrowDownIcon className="w-5 h-5 ml-2 inline-block" />
                </PrimaryButton>
              )}
            </CSVLink>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Invoices;
