import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Nav from '../../hooks/Nav';
import useFormatDate from '../../hooks/useFormatDate';
import SlideOver from '../../tailwindUI/SlideOver';
import Sort from '../../tailwindUI/Sort';
import Filters from '../../tailwindUI/Filters';
import SearchBar from '../../tailwindUI/SearchBar';
import EmptyState from '../../tailwindUI/EmptyState';
import Table from '../../tailwindUI/Table';
import { AdjustmentsHorizontalIcon, TrashIcon, BanknotesIcon } from '@heroicons/react/24/outline';
import Pagination from '../../tailwindUI/Pagination';
import usePagination from '../../tailwindUI/usePagination';
import useSort from '../../tailwindUI/useSort';
import useFilter from '../../tailwindUI/useFilter';
import useScrollPosition from '../../hooks/useScrollPosition';
import Notification from '../../tailwindUI/Notification';
import { getReport, searchSubscription } from '../../apiClient/operations/subscriptionOperations';
import moment from 'moment';
import useFormatterCurrency from '../../hooks/useFormatterCurrency';
import { DocumentArrowDownIcon } from '@heroicons/react/24/solid';
import { CSVLink } from "react-csv";
import PrimaryButton from '../../tailwindUI/PrimaryButton';
import LongAlert from '../../tailwindUI/LongAlert';


const tableColumns = [
    { heading: 'Fecha de creación', value: 'created_formated',subvalue:'hour_formated' },
    { heading: 'Nombre', value: 'account.name' ,   subvalue: 'account.email', main: true,},
    { heading: 'Monto por periodo', value: 'formated_amount', subvalue:'periodicity' ,align: 'center'},
    { heading: 'Periodo actual', value: 'periodo', align: 'center' },
    { heading: 'Próxima Fecha de cobro', value: 'charge_formatted', subvalue: 'charge_now', align: 'center' },
    { heading: 'Estatus', value: 'status' , badge: true, align: 'center' }
];

function useFirstRender() {
    const firstRender = useRef(true);
  
    useEffect(() => {
      firstRender.current = false;
    }, []);
  
    return firstRender.current;
  }
  

function Subscriptions() {
    const user = JSON.parse(localStorage.getItem('user')) || {};
    const paginate = usePagination();
    const { formatDate } = useFormatDate();
    const { formatterCurrency } = useFormatterCurrency();

    const sortItem = useSort();
    const filterItem = useFilter();
    const setScrollPosition = useScrollPosition();
    const [sortOptions, setSortOptions] = useState([
        { id: 1, name: 'Los más nuevos', field: 'created', sort: 'reverse', active: false },
        { id: 2, name: 'Los más antiguos', field: 'created', sort: 'direct', active: false },
        { id: 3, name: 'Nombre descendente', field: 'account.name', sort: 'reverse', active: false },
        { id: 4, name: 'Nombre ascendente', field: 'account.name', sort: 'direct', active: false }
    ]);
    const [filters, setFilters] = useState([
        {
            id: 1,
            name: 'Estatus',
            value: 'status',
            open: false,
            options: [
                { id: 1, value: 'active', label: 'Activo', applied: false, checked: false, filter_id: 1 },
                { id: 2, value: 'canceled|cancelled', label: "Cancelado", applied: false, checked: false, filter_id: 1 },
                { id: 3, value: 'trial|in_trial', label: "En prueba", applied: false, checked: false, filter_id: 1 },
                { id: 4, value: 'past_due|unpaid', label: "Vencida", applied: false, checked: false, filter_id: 1 },
                { id: 5, value: 'completed', label: 'Completado', applied: false, checked: false, filter_id: 1 },
            ],
        },
    ]);
    const history = useHistory();
    const [searchInput, setSearchInput] = useState(null);
    const [sortString, setSortString] = useState('');
    const [filterString, setFilterString] = useState('');
    const [openSlide, setOpenSlide] = useState(false);
    const [pagination, setPagination] = useState(null);
    const [currentPage, setCurrenPage] = useState(1);
    const [filtersApplied, setFiltersApplied] = useState(false);
    const [subscriptions, setSubscriptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [reportLoading, setReportLoading] = useState(false);
    const [error, setError] = useState(null);
    const [notification, setNotification] = useState(false);
    const [dataCsv, setDataCsv] = useState([]);
    var first_render= useFirstRender();

    const csvHeaders = [
        "Provider",
        "Email",
        "Nombre",
        "Cargo por periodo",
        "Fecha de cobro",
        "Frecuencia de pago",
        "Status",
        "Periodo actual",
        "Periodos totales",
        "Suscripción creada por",
        "id de account"
    ]
    var periodicity = {
        "month1":"Mensual",
        "month2":"Bimestral",
        "month3":"Trimestral",
        "month6":"Semestral",
        "month12":"Anual",
        "year1":"Anual",
    }


    const handleView = item => {
        history.push(`taxpayers/view/${item.account.id}#subscription`,{
                subscription: item
            }
        )
    }

    const columnActions = [
        {
            id: 1,
            name: 'Ver detalle',
            type: 'primary',
            icon: <BanknotesIcon className='w-5 h-5 text-gray-600 lg:text-white'/>,
            action: handleView,
        }
    ];

    const generateCSV = async () => {
        if(!subscriptions) return;
        setReportLoading(true);
        const res = await getReport();
        setDataCsv(res);
        setReportLoading(false);
    }
    useEffect(() => {
        generateCSV();
    },[])

    const handleGetSubscriptions = async (page) => {
        setIsLoading(true);
        try {
            setScrollPosition(0);
            setCurrenPage(page);
            const res = await searchSubscription(page, 10, searchInput, filterString, sortString);
            setPagination(paginate(res.total_items, 10, Math.ceil(res.total_items/res.limit)));
            const response = res.data.map(item => {
                var total_charges = (item?.expiry_count==0 || item?.expiry_count==9999999)? "":"de "+(item?.total_payments||item?.expiry_count)
                var next_date = item?.charge_date||moment(item?.created).add(item?.trial_days, "days").valueOf();
                var current_date = moment().valueOf();
                var is_today = (formatDate(next_date, 'DD MMMM YYYY')==formatDate(current_date, 'DD MMMM YYYY') || moment(next_date).fromNow().includes('horas'))
                var charges = (item?.number_charge||0);
                (item?.total_payments==item?.expiry_count+1||item?.apply_charge==true) && (charges+=1);
                return {
                    ...item,
                    full_formated: formatDate(item?.created, 'DD MMMM YYYY HH:mm')+" hrs",
                    charged_formatted: formatDate(next_date, 'DD MMMM YYYY'),
                    created_formated: formatDate(item?.created, 'DD MMMM YYYY'),
                    hour_formated: formatDate(item?.created, 'HH:mm')+" hrs",
                    charge_formatted: formatDate(next_date, 'DD MMMM YYYY'),
                    charge_now:is_today ? "Hoy":moment(next_date).fromNow(),
                    formated_amount : formatterCurrency(item.amount/100),
                    periodicity:periodicity[item?.interval+item?.frequency]||"mensual",
                    periodo:`${charges} ${total_charges}`,
                    ...(item?.completed_at&&{formated_completed:formatDate(item?.completed_at, 'DD MMMM YYYY HH:mm')+" hrs",}),
                    ...(item?.canceled_at&&{formated_canceled:formatDate(item?.canceled_at, 'DD MMMM YYYY HH:mm')+" hrs",})
                }
            });
            setSubscriptions(response);
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            setError(e.message || 'Hubo un error al obtener la información.');
        }
    }

    const handleCleanFilters = () => {
        const newFilters = filters.map(filter => {
            const newOptions = filter.options.map(option => {
                return {
                    ...option,
                    applied: false,
                    checked: false
                }
            });
            return {
                ...filter,
                options: newOptions
            }
        });
        setFilters(newFilters);
        setFiltersApplied(false);
        setFilterString('');
        setNotification(true);
        setTimeout(() => {
            setNotification(false);
        }, 5000);
    }
    
    const handleSort = item => setSortString(sortItem(item));

    const handlePasteSearchInput = event => setSearchInput(event.clipboardData.getData('text'));

    const handleApplyFilters = () => {
        setFilterString(filterItem(filters));
        setFiltersApplied(false);
    }

    useEffect(() => {
    handleGetSubscriptions(1);
        return () => {
            setSubscriptions([]);
        }
    }, []);

    useEffect(() => {
        if (!first_render) {
        handleGetSubscriptions(1);
        setScrollPosition(0);
            return () => {
                setSubscriptions([]);
            }
    }
    }, [filterString, sortString]);

    useEffect(() => {
        if (!first_render) {
        handleGetSubscriptions(currentPage);
    setScrollPosition(0);
        return () => {
            setSubscriptions([]);
        }}
    }, [currentPage]);

    useEffect(() => {
    filtersApplied && handleApplyFilters();
    }, [filtersApplied]);

    useEffect(() => {
        if (!first_render) {
    searchInput !== null && handleGetSubscriptions(1);
        return () => {
            setSubscriptions([]);
        }}
    }, [searchInput]);

    useEffect(() => {
    const activeOptions = sortOptions.filter(option => option.active);
    activeOptions.length > 0 && handleSort(activeOptions[0]);
    }, [sortOptions]);
    return (
        <>
            <div className="w-full">
                <Nav user={user}>
                    <div className="w-full">
                        <span className="text-3xl text-white font-bold">
                            Suscripciones
                        </span>
                    </div>
                </Nav>
            </div>
            <div className='min-h-full md:min-h-[calc(100vh-4rem)]'>
                {error &&
                    <div className='w-full sticky top-[68px] lg:top-1 mt-20 lg:mt-4 z-[35]'>
                        <LongAlert title={error} show={error != null} onClose={() => setError(null)} />
                    </div>
                }
                <Notification show={notification} message='Filtros limpiados correctamente' />
                <div className="w-full px-4 pt-4">
                    <div className="hidden lg:flex justify-between items-center w-full">
                        <h1 className="text-4xl font-bold text-left text-gray-900">
                            Suscripciones
                        </h1>
                    </div>
                </div>
                <div className={`w-full px-4 py-0 md:py-4 mb-16 lg:mb-8 ${error != null ? 'mt-0' : 'mt-16 lg:mt-0' } `}>
                    {isLoading ?
                        <>
                            <div className='w-full h-12 rounded-full bg-gray-300 animate-pulse'></div>
                            <div className='w-full flex justify-between'>
                                <div className='w-24 h-6 rounded-full bg-gray-300 animate-pulse my-4'/>
                                <div className='w-24 h-6 rounded-full bg-gray-300 animate-pulse my-4'/>
                            </div>
                        </>
                        :
                        <>
                            <SearchBar value={ searchInput } setValue={ setSearchInput } placeholder_mobile='Nombre o correo' placeholder_desktop='Nombre o correo' onPaste={ handlePasteSearchInput } />
                            <section aria-labelledby="filter-heading" className='pt-4'>
                                <div className="flex items-center justify-between">
                                    <Sort options={ sortOptions } title='Ordenar' setOptions={ setSortOptions } />
                                    <div className='flex items-center gap-6'>
                                        {filterString !== '' && <span className='cursor-pointer text-sm font-medium text-gray-700 hover:text-gray-900 flex gap-1 pt-0 md:pt-[1px]' onClick={handleCleanFilters}>Limpiar filtros <TrashIcon className='h-5 h-5' /></span>}
                                        <button type="button" className="flex items-center gap-1 text-sm font-medium text-gray-700 md:hidden" onClick={() => setOpenSlide(true)}>
                                            Filtros <span><AdjustmentsHorizontalIcon className='w-[18px] h-[18px]'/></span>
                                        </button>
                                        <Filters filters={ filters } setFilters={ setFilters } setFiltersApplied={setFiltersApplied} />
                                    </div>
                                </div>
                            </section>
                        </>
                    }
                    { isLoading ?
                            <Table title='Suscripciones' data={ subscriptions } isLoadingData={ isLoading } columns={ tableColumns } actions={ columnActions }/>
                        :
                        <>
                            {subscriptions.length > 0 ?
                                <div className='mt-4 space-y-4'>
                                    <Table title='Suscripciones' data={ subscriptions } isLoadingData={ isLoading } columns={ tableColumns } actions={ columnActions } />
                                    <div className='flex flex-col-reverse lg:flex-row justify-between'>
                                    <div className='flex w-full justify-center mt-2 lg:justify-start lg:mt-0'>
                                        {!reportLoading ? <div className='w-full lg:w-auto lg:max-w-sm items-center'>
                                        <CSVLink data={dataCsv} headers={csvHeaders} filename={`Reporte.csv`}>
                                            <PrimaryButton isFullWidth>
                                                Descargar Reporte <DocumentArrowDownIcon className='w-5 h-5 ml-2 inline-block' />
                                            </PrimaryButton>
                                        </CSVLink>
                                        </div>:<div className='w-full lg:w-auto lg:max-w-sm items-center animate-pulse'>
                                            <PrimaryButton isFullWidth>
                                                Descargar Reporte <DocumentArrowDownIcon className='w-5 h-5 ml-2 inline-block' />
                                            </PrimaryButton>
                                        </div>}
                                    </div>
                                    <Pagination pages={pagination?.pages?.length} currentPage={currentPage} setCurrentPage={setCurrenPage}/>
                                    </div>
                                </div>
                                :
                                <div className='mt-4'>
                                    <EmptyState title='No se encontraron suscripciones' text='Para visualizar la información, deben existir primero en el sistema, verifícalo.' />
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
            <SlideOver open={ openSlide } setOpen={ setOpenSlide } title='Filtros'>
                <Filters filters={ filters } openSlide={ openSlide } setFilters={ setFilters } setOpenSlide={ setOpenSlide } setFiltersApplied={setFiltersApplied}/>
            </SlideOver>
        </>
    )
}

export default Subscriptions;