import React, { Fragment, useEffect, useState,/* useEffect */} from 'react';
import { Popover, Transition } from '@headlessui/react';
//import { ChevronDownIcon, TrashIcon } from '@heroicons/react/20/solid';
import PropTypes from 'prop-types';
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar, utils } from "react-modern-calendar-datepicker";
//import moment from 'moment';
import 'moment-timezone';
import moment from 'moment';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import PrimaryButton from './PrimaryButton';

function DateTimePicker({register, setValue}) {
    const [selectedTime, setSelectedTime] = useState({
        day: moment().get('date'),
        month: moment().get('month')+1,
        year: moment().get('year'),
        hour: moment().get('hour'),
        minute: moment().get('minute')
    })
    const [fullDate, setFullDate] = useState(moment())
    const [timeError, setTimeError] = useState({
        minute: false,
        hour: false
    })

    useEffect(()=>{
            setTimeError({...timeError, minute:(selectedTime.minute > 59) , hour: (selectedTime.hour > 24)})
        },[selectedTime.hour, selectedTime.minute])

    useEffect(()=>{
        setFullDate(moment(selectedTime.year+"/"+selectedTime.month+"/"+selectedTime.day+" "+selectedTime.hour+":"+selectedTime.minute,"YYYY/MM/DD HH:mm"))
        setValue("return_date",moment(selectedTime.year+"/"+selectedTime.month+"/"+selectedTime.day+" "+selectedTime.hour+":"+selectedTime.minute,"YYYY/MM/DD HH:mm"))
    },[selectedTime])

    const handleNumber = (value, type, direction) => {
        if (type == 'hour') {
            if (direction == 'up') {
                setSelectedTime({...selectedTime, hour: value >= 23 ? 0 : parseInt(value)+1})
            }else{
                setSelectedTime({...selectedTime, hour: value <= 0 ? 23 : parseInt(value)-1})
            }
        }else if (type == 'minute') {
            if (direction == 'up') {
                setSelectedTime({...selectedTime, minute: value >= 59 ? 0 : parseInt(value)+1})
            }else{
                setSelectedTime({...selectedTime, minute: value <= 0 ? 59 : parseInt(value)-1})
            }

        }
            
    }

    return (
        <>
            <Popover.Group className="flex items-baseline space-x-6">
                    <Popover as="div" id={`desktop-menu-date-interval`} className="w-full relative inline-block text-left">
                            <div className='w-full'>
                            <Popover.Button className="w-full">
                            <input
                                value={fullDate.format("YYYY/MM/DD HH:mm")}
                                placeholder={"Fecha de devolución"}
                                type="text"
                                inputMode='text'
                                className={`font-normal w-full rounded-md px-2 h-[46px] py-2 border ${fullDate.isValid() === false ? "border-red-500":"border-v2-border-time"} focus:border-v2-blue-text-login focus:shadow-md focus:shadow-v2-blue-text-login/30 text-v2-input-text outline-none`}
                                {...register("return_date", {
                                    required: true
                                })}
                                    />
                                {fullDate.isValid() === false && <span className='w-full flex flex-row justify-start text-xs text-red-500'>La fecha seleccionada no es válida.</span>}
                            </Popover.Button>
                        </div>
                        <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                            <Popover.Panel className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {() => (
                                <div className='w-full flex flex-col items-center sm:flex-row gap-4'>
                                    <div className='w-auto'>
                                    <dt className="mb-2 text-sm font-medium text-gray-500">Fecha:</dt>
                                    <div className='w-full'>
                                    <Calendar
                                    locale='en'
                                    wrapperClassName='block z-10 responsive-calendar'
                                    inputClassName='w-full z-10 appearance-none cursor-pointer text-left py-2 px-4 font-normal border border-v2-border-time rounded-md text-base text-v2-input-text outline-none responsive-calendar'
                                    calendarClassName='block font-normal text-v2-input-text'
                                    calendarPopperPosition='bottom'
                                    colorPrimary='#2169AC'
                                    colorPrimaryLight='#D9E2EC'
                                    value={selectedTime}
                                    maximumDate={utils().getToday()}
                                    onChange={(item)=>{
                                        setSelectedTime({...selectedTime, day: item.day, month: item.month, year: item.year})
                                       //close()
                                    }}/>
                                    </div>
                                    </div>
                                    <div className='w-[15rem]'>
                                    
                                    <dt className="mb-2 text-sm font-medium text-gray-500">Hora:</dt>
                                    <div className='pb-3 w-full justify-center flex flex-row gap-2 items-center z-20'>
                                        <div className='w-full flex flex-col items-center'>
                                        <div className={`w-full pr-2 h-[46px] border ${timeError.hour? "border-v2-red-error-services-selected":"border-v2-border-time"} rounded-md text-base text-v2-text-bar-steps outline-none flex items-center justify-center `}>
                                        <input type="number"
                                            maxLength={2}
                                            onKeyDown={(event) => {
                                                if (!/[0-9]/.test(event.key)&& event.key !== "Backspace") {
                                                event.preventDefault();
                                                }
                                            }}
                                            value={selectedTime.hour}
                                            onChange={(e)=>{setSelectedTime({...selectedTime, hour: e.target.value})}}
                                            defaultValue={moment().get('hour')}
                                            className='w-full text-center'
                                            min={0}
                                            max={24}
                                            style={{"-webkit-appearance": "none",
                                                "margin": 0,
                                                "-moz-appearance": "textfield"}}
                                            />
                                            <div className='flex flex-col items-center justify-center'>
                                            <ChevronUpIcon onClick={()=>{handleNumber(selectedTime.hour, 'hour', 'up')}} className='w-4 h-4 text-v2-text-bar-steps hover:bg-slate-200'/>
                                            <ChevronDownIcon onClick={()=>{handleNumber(selectedTime.hour, 'hour', 'down')}} className='w-4 h-4 text-v2-text-bar-steps hover:bg-slate-200'/>
                                            </div>
                                            
                                        </div>
                                        <span className='text-md'>Hora</span>
                                        </div>
                                        <div className='pb-6 flex flex-col items-center'>
                                            <span className='text-2xl'>:</span>
                                        </div>

                                            <div className='w-full flex flex-col items-center'>
                                        <div className={`w-full pr-2 h-[46px] border ${timeError.minute? "border-v2-red-error-services-selected":"border-v2-border-time"} rounded-md text-base text-v2-text-bar-steps outline-none flex items-center justify-center`}>
                                            <input type="number"
                                            maxLength={2}
                                            onKeyDown={(event) => {
                                                if (!/[0-9]/.test(event.key)&& event.key !== "Backspace") {
                                                  event.preventDefault();
                                                }
                                              }}
                                            value={selectedTime.minute}
                                            onChange={(e)=>{setSelectedTime({...selectedTime, minute: e.target.value})}}
                                            defaultValue={moment().get('minute')}
                                            className='w-full text-center'
                                            min={0}
                                            max={60}
                                            style={{"-webkit-appearance": "none",
                                                "margin": 0,
                                                "-moz-appearance": "textfield"}}
                                            />
                                            <div className='flex flex-col items-center justify-center'>
                                            <ChevronUpIcon onClick={()=>{handleNumber(selectedTime.minute, 'minute', 'up')}} className='w-4 h-4 text-v2-text-bar-steps hover:bg-slate-200'/>
                                            <ChevronDownIcon onClick={()=>{handleNumber(selectedTime.minute, 'minute', 'down')}} className='w-4 h-4 text-v2-text-bar-steps hover:bg-slate-200'/>
                                            </div>
                                        </div>
                                        <span className='text-md'>Minutos</span>
                                        </div>

                                    </div>
                                    <Popover.Button className="w-full">
                                            <PrimaryButton type="button"  disabled={(timeError.minute || timeError.hour)} isFullWidth>
                                                Aceptar
                                            </PrimaryButton>
                                            </Popover.Button>
                                    </div>
                                    


                                </div>
                                )}
                            </Popover.Panel>
                        </Transition>
                    </Popover>
            </Popover.Group>
        </>
    )
}

DateTimePicker.propTypes = {
    setValue: PropTypes.func,
    register: PropTypes.func,
    filters: PropTypes.array,
    openSlide: PropTypes.bool,
    setFilters: PropTypes.func,
    setOpenSlide: PropTypes.func,
    setFiltersApplied: PropTypes.func
}

DateTimePicker.defaultProps = {
    openSlide: false
}

export default DateTimePicker