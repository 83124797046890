const useGetInfoServices = () => {
    const getInfoServices = (services, packActive) => {
        console.log(packActive);
        var isUniqueService = services.length == 1
        const convertServices = services.map(service => {
            const obj = {
                id: service.id,
                name: service.name,
                category: service.category,
                quantity: service.quantity,
                price: {
                    unit_price: service.amount.toFixed(2) * 100,
                    discount: service.discount_option == "percent" ? service.discount : service.discount.toFixed(2) * 100,
                    discount_type: service.discount_option,
                    discount_amount: service.discount_amount.toFixed(2) * 100,
                    special_price: {
                        unit_price: service.amount,
                        discount: isUniqueService ? 0: (service.pack_discount_option == 'percent' ? service.pack_discount : service.pack_discount.toFixed(2) * 100),
                        discount_type: service.pack_discount_option,
                        discount_amount: isUniqueService ? 0:service.pack_discount_amount.toFixed(2) * 100,
                    }
                },
                recurrent: service.recurrent,
                periods: [],
                periodicity: service.periodicity,
                notes: service.notes,
            }
            const convertDates = service.dates.map(date => {
                return date.id
            });
            obj.periods = convertDates;
            return obj;
        });
        return convertServices
    }
    return { getInfoServices }
}

export default useGetInfoServices;