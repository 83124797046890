import apiClient from "../apiClient";
import { apiBaseUrl } from "../../config";

export const getASFWorkflow = async (accountId) => {
  return apiClient
    .get(`${apiBaseUrl}/workflow/admin/asf/${accountId}`)
    .then((response) => {
      return response;
    });
};

export const updateConnectionConfirmation = async (
  accountId,
  connectionId,
  body
) => {
  return apiClient
    .post(`${apiBaseUrl}/connections/admin/${accountId}/${connectionId}/confirm`, body)
    .then((response) => {
      return response;
    });
};

export const getAppointment = async (appointmentId) => {
  return apiClient
    .get(`${apiBaseUrl}/appointments/admin/${appointmentId}`)
    .then((response) => {
      return response;
    });
};

export const updateAppointmentStatus = async (appointmentId, data) => {
  return apiClient
    .put(`${apiBaseUrl}/appointments/admin/${appointmentId}/status`, data)
    .then((response) => {
      return response;
    });
};
