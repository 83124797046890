import React, { useState, useEffect, useContext } from 'react';
import { getAccounts } from '../../apiClient/operations/accountsOperations'; 
import { useHistory } from 'react-router-dom';
import Nav from '../../hooks/Nav';
import Timer from '../../hooks/Timer';
import useConvertUnixToDate from '../../hooks/useConvertUnixToDate';
import useFormatDate from '../../hooks/useFormatDate';
import SecondaryLoader from '../../hooks/SecondaryLoader';
import { AlertContext } from '../../context/AlertContext';
import useScrollPosition from '../../hooks/useScrollPosition';
import Table from '../../tailwindUI/Table';
import { DocumentIcon } from '@heroicons/react/24/outline';
import EmptyState from '../../tailwindUI/EmptyState';
import SearchBar from '../../tailwindUI/SearchBar';
import Pagination from '../../tailwindUI/Pagination';
import usePagination from '../../tailwindUI/usePagination';
import Sort from '../../tailwindUI/Sort';
import useSort from '../../tailwindUI/useSort';
import useFormatPhoneNumber from '../../hooks/useFormatPhoneNumber';

const tableColumns = [
    { heading: 'Fecha', value: 'created_formated' },
    { heading: 'Cliente', value: 'name'},
    { heading: 'Contacto', value: 'email', main: true, subvalue: 'phone_number_formated' },
    { heading: 'Tipo persona', value: 'legal_type_formated'},
];

function Quoter() {
    let history = useHistory();
    const user = JSON.parse(localStorage.getItem('user')) || {};
    const { formatDate } = useFormatDate();
    const { convertUnixToDate } = useConvertUnixToDate();
    const setScrollPosition = useScrollPosition();
    const paginate = usePagination();
    const sortItem = useSort();
    const { formatPhoneNumber } = useFormatPhoneNumber();
    const { setAlert } = useContext(AlertContext);
    const [secondaryLoader, setSecondaryLoader] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [term, setTerm] = useState(null);
    const [accounts, setAccounts] = useState([])
    const [pagination, setPagination] = useState(null);
    const [sortString, setSortString] = useState('');
    const [sortOptions, setSortOptions] = useState([
        { id: 1, name: 'Los más nuevos', field: 'created', sort: 'reverse', active: false },
        { id: 2, name: 'Los más antiguos', field: 'created', sort: 'direct', active: false },
        { id: 3, name: 'Nombre descendente', field: 'name', sort: 'reverse', active: false },
        { id: 4, name: 'Nombre ascendente', field: 'name', sort: 'direct', active: false }
    ]);

    const handleView = (item) => {
        setSecondaryLoader(true);
        localStorage.setItem('account', JSON.stringify({account_info: item}));
        history.push('/new-quote');
        setScrollPosition(0);
        setSecondaryLoader(false);
    }

    const columnActions = [
        {
            id: 1,
            name: 'Cotizar',
            type: 'primary',
            icon: <DocumentIcon className='w-5 h-5 text-gray-600 lg:text-white'/>,
            action: handleView,
        }
    ];

    const handleSort = item => setSortString(sortItem(item));
    
    const getData = async (page) => {
        try {
            setIsLoading(true);
            setScrollPosition(0);
            setCurrentPage(page);
            const res = await getAccounts(page, 10, term, sortString, ['prospect', 'taxpayer', 'customer']);
            setPagination(paginate(res.total_items, 10, Math.ceil(res.total_items/res.limit)));
            const data = res.data.map (obj => {
                return {
                    ...obj,
                    created_formated: formatDate(convertUnixToDate(obj.created), 'DD MMMM YYYY'),
                    legal_type_formated: obj.legal_type == 'personal' ? 'Persona física' : 'Persona moral',
                    phone_number_formated: formatPhoneNumber(obj.phone_number)
                }
            });
            setAccounts(data);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setAccounts([]);
            setAlert({active: true, type: 'error', message: 'No se pudieron obtener los contribuyentes'});
        }
    }

    const handlePasteSearchInput = event => setTerm(event.clipboardData.getData('text'));
    
    useEffect(() => {
        getData(1);
        return () => {
            setAccounts([]);
        }
    }, []);

    useEffect(() => {
        getData(currentPage);
        return () => {
            setAccounts([]);
        }
    }, [currentPage, sortString])
    
    useEffect(() => {
        term !== null && getData(1);
        return () => {
            setAccounts([]);
        }
    }, [term]);

    useEffect(() => {
        const activeOptions = sortOptions.filter(option => option.active);
        activeOptions.length > 0 && handleSort(activeOptions[0]);
    }, [sortOptions]);
    
    return (
        <div className="w-full h-full">
            {secondaryLoader && <SecondaryLoader />}
            <div className={`xs:hidden w-full bg-white h-16 md:flex justify-end items-center px-8 z-1`}>
                    <div className="w-full">
                    <Timer />
                </div>
            </div>
            <div className="w-full xs:h-full md:h-full">
                <div className="w-full bg-white xs:h-auto md:h-full">
                    <Nav user={user}>
                        <div className="w-full">
                            <span className="text-3xl text-white font-bold">
                                Cotizador
                            </span>
                        </div>
                    </Nav>
                </div>
                <div className="w-full px-4 py-3">
                    <div className="hidden md:flex justify-start">
                        <h1 className="text-4xl font-bold">
                            Cotizador
                        </h1>
                    </div>
                </div>
                {isLoading ?
                    <div className='px-4 mt-16 md:mt-2'>
                        <div className='w-full h-10 rounded-full bg-gray-300 animate-pulse'></div>
                        <div className='w-24 h-6 rounded-full bg-gray-300 animate-pulse mt-4'/>
                    </div>
                    :
                    <div className='w-full px-4 mt-16 md:mt-2 space-y-3'>
                        <SearchBar value={ term } setValue={ setTerm } placeholder_mobile='Ingresa un contribuyente' placeholder_desktop='¿Qué contribuyente deseas buscar?' onPaste={ handlePasteSearchInput }/>
                        <Sort options={sortOptions}  title='Ordenar' setOptions={setSortOptions}/>
                    </div>
                }
                <div className='w-full p-4 mb-12 lg:mb-8'>
                    { isLoading ?
                        <Table title='Contribuyentes' data={ accounts } isLoadingData={ isLoading } columns={ tableColumns } actions={ columnActions } />
                        :
                        <>
                            { accounts.length > 0 ?
                                <div className='space-y-4'>
                                    <Table title='Contribuyentes' data={ accounts } isLoadingData={ isLoading } columns={ tableColumns } actions={ columnActions } />
                                    <Pagination pages={ pagination?.pages?.length } currentPage={ currentPage } setCurrentPage={ setCurrentPage } />
                                </div>
                                :
                                <EmptyState title='No se encontraron contribuyentes' text='Verifica la información antes de continuar.' />
                            }
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default Quoter