import { saveAs } from 'file-saver';
import { apiNodeBaseUrl } from '../../config';

export const deleteS3 = async (fileName) => {
    try {
        const fetchS3 = await fetch(`${apiNodeBaseUrl}upload-s3/operations/delete`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8'
            },
            body : JSON.stringify(fileName)
        });
        const response = await fetchS3.json();
        return response;
    } catch (e) {
        console.log(e);
    }
}

export const uploadS3 = async (formData) => {
    try {
        const fetchS3 = await fetch(`${apiNodeBaseUrl}upload-s3`, {
            method: 'POST',
            body : formData
        });
        const response = await fetchS3.json();
        return response;
    } catch (e) {
        console.log(e);
    }
}

export const getObjectS3 = async (fileName) => {
    try {
        const fetchS3 = await fetch(`${apiNodeBaseUrl}upload-s3/operations/get-object`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8'
            },
            body : JSON.stringify(fileName)
        });
        const response = await fetchS3.json();
        return response;
    } catch (e) {
        console.log(e);
    }
}

export const downloadObjectS3 = async (fileName) => {
    try{
        const fetchS3 = await fetch(`${apiNodeBaseUrl}upload-s3/operations/download/${fileName}`, {
            method: 'GET'
        });
        const res = await fetchS3.blob()
        saveAs(res, fileName);
        return res
    }catch(e){
        console.log(e);
    }
}

export const getQuoteS3 = async (key) => {
    try {
        const fetchS3 = await fetch(`${apiNodeBaseUrl}upload-s3/operations/get-quote`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8'
            },
            body : JSON.stringify(key)
        });
        const res = await fetchS3.blob();
        return res
    } catch (e) {
        console.log(e);
    }
}