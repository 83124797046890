import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faX, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import useFormatDate from './useFormatDate';
import useGetDate from './useGetDate';
import Button from '../hooks/Button';
import Modal from './Modal';
import PropTypes from 'prop-types';


function DateYearRangePicker({ index, setServices, services, servicesDisplay, setServicesDisplay, resumeAmounts, setResumeAmounts, time }) {
    const { formatDate } = useFormatDate();
    const { getDate } = useGetDate();
    const year = parseInt(formatDate(getDate(), 'YYYY'));
    const [years, setYears] = useState();    
    let yearsHelper = [];
    const [isOpen, setIsOpen] = useState(false);
    const [dates, setDates] = useState([]);
    const [datesDisplay, setDatesDisplay] = useState([]);
    const month = parseInt(formatDate(getDate(), 'M'));
    const [timePeriods, setTimePeriods] = useState(time);
    
    useEffect(() => {
        if( month > 4 && (services.filter(service => service.index == index)[0].sku === 'DCA-001' || services.filter(service => service.index == index)[0].sku === 'DCA-002')){
            setTimePeriods('gt');
        } else if( month > 4 && (services.filter(service => service.index == index)[0].periodicity === 'yearly' && services.filter(service => service.index == index)[0].category === 'regularization')){
            setTimePeriods('lte');
        } 
    }, []);

    const timeYears = {
        'lt': {initialValue: year - 12, operation: year - 1},
        'lte': {initialValue: year - 11, operation: year},
        'gt': {initialValue: year + 1, operation: year + 12},
        'gte': {initialValue: year - 1, operation: year + 10}
    }

    const chargeDates = () => {
        if(services != ''){
            const serviceId = services.filter(service => service.index == index);
            const serviceDisplayId = servicesDisplay.filter(service => service.index == index);
            setDates(serviceId[0].dates);
            setDatesDisplay(serviceDisplayId[0].dates_display);
        }
    } 

    useEffect(() => {
        chargeDates();
        return () => {
            setDates();
            setDatesDisplay();
        }
    }, [services, servicesDisplay]);

    useEffect(() => {
        let actualYears = [];
        if(timePeriods){
            for (let i = timeYears[timePeriods].initialValue; i <= timeYears[timePeriods].operation; i++) {
                actualYears.push(i);
            }
        } else {
            for (let i = year; i <= year + 11; i++) {
                actualYears.push(i);
            }
        }
        setYears(actualYears);
        return () => {
            setYears();
        }
    }, [services, servicesDisplay, timePeriods]);

    const createID = (yearID) => {
        return (`${yearID}-01-01`);
    }

    const handlePreviousYears = () => {
        for (let i = years[0]-12; i < years[0]; i++) {
            yearsHelper.push(i);
        }
        setYears(yearsHelper);
    }

    const handleNextYears = () => {
        for (let i = years.at(-1)+1; i < years.at(-1)+13; i++) {
            yearsHelper.push(i);
        }
        setYears(yearsHelper);
    }

    const updateDisp = arr => {
        const totalDisplay = servicesDisplay.map( date => {
            if (index == date.index) {
                return {
                ...date,
                dates_display: arr
                }
            }
            return date
        });
        setServicesDisplay(totalDisplay);
    }

    const calculateDiscount = discount => {
        const totalDiscount = discount.map( service => service.discount_amount).reduce((prev, curr) => prev + curr, 0 );
        const total = discount.map( service => service.total).reduce((prev, curr) => prev + curr, 0 );
        const sub = discount.map( service => service.subtotal).reduce((prev, curr) => prev + curr, 0 );
        const pack_total_discount = discount.map( service => service.pack_discount_amount).reduce((prev, curr) => prev + curr, 0 );
        const pack_total = discount.map( service => service.pack_total).reduce((prev, curr) => prev + curr, 0 );
        setResumeAmounts({...resumeAmounts, discountAmount: totalDiscount, totalAmount: total, subtotalAmount: sub, totalPack: pack_total, totalPackDiscount: pack_total_discount });
    }

    const updateDates = (arrDates, number) => {
        const totalDates = services.map( date => {
            if (index == date.index) {
                return {
                ...date,
                dates: arrDates,
                quantity: number,
                total: (date.discount_option == 'direct' ? ((date.amount * number) - date.discount) : ((date.amount * number) - ((date.amount * number) * (date.discount / 100)))),
                subtotal: date.amount * number,
                discount_amount: date.discount_option == 'direct' ? parseFloat(date.discount) : ((date.amount * number) * (date.discount / 100)),
                pack_total: (date.pack_discount_option == 'direct' ? ((date.amount * number) - date.pack_discount) : ((date.amount * number) - ((date.amount * number) * (date.pack_discount / 100)))),
                pack_discount_amount: date.pack_discount_option == 'direct' ? parseFloat(date.pack_discount) : ((date.amount * number) * (date.pack_discount / 100)),
                pack_subtotal: (date.amount * number)
                }
            }
            return date
        });
        setServices(totalDates);
        calculateDiscount(totalDates);
    }

    const handleUpdateDates = (arr, yearSelected) => {
        const otherYears = dates.filter(date => date.year != yearSelected);
        setDates([...otherYears, arr]);
        updateDates([...otherYears, arr], [...otherYears, arr].length);
    }

    const handleCreateRange = year => {
        let newDates = {id:createID(year), year: year, active:true};
        handleUpdateDates(newDates, year);
    }

    const handleSelectMonth = year => {
        const newYear = dates.find( date => date.year == year );
        if (newYear === undefined) {
            handleCreateRange(year);
            setDatesDisplay([...datesDisplay, {id:year, year: year, active:true}]);
            updateDisp([...datesDisplay, {id:year, year: year, active:true}]);
        } else {
            const newDates = dates.filter( date => date.year != year );
            const deleteDatesDisplay = datesDisplay.filter(date => date.year != year);
            setDates(newDates);
            setDatesDisplay(deleteDatesDisplay);
            updateDisp(deleteDatesDisplay);
            updateDates(newDates, newDates.length);
        }
    }

    const handleDeleteDate = year => {
        const deleteDate = dates.filter(date => date.year != year);
        const deleteDatesDisplay = datesDisplay.filter(date => date.year != year);
        setDates(deleteDate);
        setDatesDisplay(deleteDatesDisplay);
        updateDisp(deleteDatesDisplay);
        updateDates(deleteDate, deleteDate.length);
    }

    return (
        <>
            <div className="w-full appearance-none block py-2 mt-1.5 px-4 border border-v2-border-time rounded-md text-base text-v2-text-bar-steps outline-none flex justify-center cursor-pointer" onClick={ () => setIsOpen(!isOpen) }>
                    <div className="xs:w-10/12 md:w-11/12">
                        <div className="w-auto flex flex-wrap gap-2">
                            {datesDisplay.map((date, i) => (
                                <div key={ i } className="w-auto cursor-default" onClick={ e => e.stopPropagation() }>
                                    <div className="w-full bg-accent-100 text-accent-base py-2 text-2xs rounded flex items-center px-4">
                                        <div className="w-5/6 text-center w-full min-w-fit pr-3">
                                            <span className="w-full select-none">
                                                { date.year }
                                            </span>
                                        </div>
                                        <div className="w-1/6 cursor-pointer text-center text-xs font-bolder" onClick={ () => handleDeleteDate(date.year) }>
                                            <FontAwesomeIcon icon={ faX } className="leading-3 font-bold"/>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="xs:w-2/12 md:w-1/12 text-v2-yellow-edit-info text-2xl leading-3 text-center cursor-pointer xs:pt-0 md:pt-1 flex items-end flex-col items-end" onClick={ () => setIsOpen(!isOpen) }>
                        <FontAwesomeIcon icon={ faCalendar } />
                    </div>
                </div>
            <div className="w-full flex justify-end">
                { isOpen &&
                    <>
                        <div className="xs:block md:hidden">
                            <Modal hasTitle={ false } modalOpen={ isOpen } modalOpenChange={ setIsOpen } marginY='my-full' >
                                <div className="h-full">
                                    <div className="w-full border border-v2-border-time rounded-md py-3.5 px-4 bg-white">
                                        <div className="w-full flex items-center border-b border-dashed border-v2-border-time pb-3 select-none">
                                            <div className="w-1/6 flex justify-start">
                                                <Button width="w-7" heigth="h-6 shadow-lg" bg="bg-white" disabled={ years.some( x => x == year-36) || timePeriods === 'gt' || timePeriods === 'gte' ? true : false } onClick={ handlePreviousYears }>
                                                    <FontAwesomeIcon icon={ faAngleLeft } className="text-v2-gray-intense-years" />
                                                </Button>
                                            </div>
                                            <div className="w-4/6 text-center text-v2-gray-intense-years text-base select-none">
                                                Seleccionar año
                                            </div>
                                            <div className="w-1/6 flex justify-end">
                                                <Button width="w-7" heigth="h-6 shadow-lg" bg="bg-white" disabled={ years.some( x => x == year-1 || x == year ) ? true : false } onClick={ handleNextYears }>
                                                    <FontAwesomeIcon icon={ faAngleRight } className="text-v2-gray-intense-years" />
                                                </Button>
                                            </div>
                                        </div>
                                        <div className="w-full grid grid-cols-3 lg:grid-cols-4 gap-2 text-2xs text-center uppercase mt-4">
                                            {years.map( (year, id) => (
                                                <div key={ id } className={`${ datesDisplay.some(date => date.year == year && date.active) ? 'bg-v2-blue-text-login text-white' : 'bg-accent-100 text-accent-base' } rounded flex justify-center items-center p-3 cursor-pointer`} onClick={ () => handleSelectMonth(year) }>
                                                    { year }
                                                </div>
                                            ))}
                                        </div>
                                        <div className='w-full flex justify-end mt-2'>
                                            <div className='w-1/2'>
                                                <Button heigth='h-10' onClick={ () => setIsOpen(!isOpen) }>
                                                    Aceptar
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                        </div>
                        <div className="xs:hidden md:block max-w-full w-1/2 absolute border border-v2-border-time rounded-md py-3.5 px-4 bg-white mt-1 z-40">
                            <div className="w-full flex items-center border-b border-dashed border-v2-border-time pb-3 select-none">
                                <div className="w-1/6 flex justify-start">
                                    <Button width="w-7" heigth="h-6 shadow-lg" bg="bg-white" disabled={ years.some( x => x == year-36) || timePeriods === 'gt' || timePeriods === 'gte' ? true : false } onClick={ handlePreviousYears }>
                                        <FontAwesomeIcon icon={ faAngleLeft } className="text-v2-gray-intense-years" />
                                    </Button>
                                </div>
                                <div className="w-4/6 text-center text-v2-gray-intense-years text-base select-none">
                                    Seleccionar año
                                </div>
                                <div className="w-1/6 flex justify-end">
                                    <Button width="w-7" heigth="h-6 shadow-lg" bg="bg-white" disabled={ years.some( x => x == year-1 || x == year) ? true : false } onClick={ handleNextYears }>
                                        <FontAwesomeIcon icon={ faAngleRight } className="text-v2-gray-intense-years" />
                                    </Button>
                                </div>
                            </div>
                            <div className="w-full grid grid-cols-3 lg:grid-cols-4 gap-2 text-2xs text-center uppercase mt-4">
                                {years.map((year, i) => (
                                    <div key={ i } className={`${ datesDisplay.some(date => date.year == year && date.active) ? 'bg-v2-blue-text-login text-white' : 'bg-accent-100 text-accent-base' } rounded flex justify-center items-center p-3 cursor-pointer select-none`} onClick={ () => handleSelectMonth(year) }>
                                        { year }
                                    </div>
                                ))}
                            </div>
                            <div className='w-full flex justify-end mt-2'>
                                <div className='w-1/2'>
                                    <Button heigth='h-10' onClick={ () => setIsOpen(!isOpen) }>
                                        Aceptar
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </>
    )
}

DateYearRangePicker.propTypes = {
    index: PropTypes.any,
    setServices: PropTypes.any,
    services: PropTypes.any,
    servicesDisplay: PropTypes.any,
    setServicesDisplay: PropTypes.any,
    setResumeAmounts: PropTypes.any,
    resumeAmounts: PropTypes.any,
    time: PropTypes.string,
}

DateYearRangePicker.defaultValues = {
    time: 'gte'
}

export default DateYearRangePicker