import React, { useState, useEffect, useContext, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AlertContext } from '../context/AlertContext';
import { faUserCircle, faPenToSquare, faFileZipper, faCircleXmark, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { downloadObjectS3 } from '../apiClient/operations/s3UploadOperations';
import { useForm } from 'react-hook-form';
import Modal from './Modal';
import Button from './Button';
import PropTypes from 'prop-types';
import useSeparateFullname from './useSeparateFullname';
import { updateGeneralData, updateInvoicingData } from '../apiClient/operations/accountsOperations';
import { getTaxRegimeList } from '../apiClient/operations/sat';

function GeneralDataClient({ dataClient, setDataClient, edit, selectedFiles, setSecondaryLoader, setDataInvoicing, dataInvoicing }) {
    const { separateFullname } = useSeparateFullname();
    const { setAlert } = useContext(AlertContext);
    const ref = useRef(dataClient.legal_type == undefined ? '' : dataClient.legal_type);
    const regimeRef = useRef(dataClient.regime == undefined ? '' : dataClient.regime);
    const [openClientInformation, setOpenClientInformation] = useState(false);
    const [openConfirmUpdate, setOpenConfirmUpdate] = useState(false);
    const [nameFile, setNameFile] = useState('');
    const [listTaxRegime, setListTaxRegime] = useState([]);
    const [dataLegalType, setDataLegalType] = useState({ prevLegal: dataClient.legal_type == undefined ? undefined : dataClient.legal_type, prevRegime: dataClient.regime == undefined ? undefined : dataClient.regime });
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [loading, setLoading] = useState(false);

    const chargeListTaxRegime = async () => {
        try {
            const getData = await getTaxRegimeList();
            setListTaxRegime(getData);
        } catch (error) {
            setAlert({ active: true, type: 'error', message: 'Error al procesar tu solicitud' })
        }
    }
    useEffect(() => {
        chargeListTaxRegime();
        return () => {
            setListTaxRegime([]);
        }
    }, []);

    const modifyDataClient = async (data) => {
        setOpenClientInformation(!openClientInformation);
        setSecondaryLoader(true);
        const { clientName, clientRfc, clientPhone, clientCity, clientOccupation, clientLegalType, clientTaxRegime } = data;
        const nameObject = separateFullname(clientName);
        const firstName = nameObject.name;
        const lastName = `${nameObject.lastName} ${nameObject.secondLastName}`;
        const accountData = { 'first_name': firstName, 'last_name': lastName, 'phone_number': `52${clientPhone}`, 'occupancy': clientOccupation, 'state': clientCity, 'legal_type': clientLegalType, taxpayer: { tax_id: clientRfc, regime: clientTaxRegime } }
        const obj = {
            tax_id: dataInvoicing.rfcInvoicing,
            name: dataInvoicing.businessName,
            tax_regime: clientTaxRegime
        }
        await updateGeneralData(accountData, dataClient.id);
        await updateInvoicingData(obj, dataClient.id);
        setDataClient({ ...dataClient, first_name: firstName, last_name: lastName, rfc: clientRfc, phone_number: `+52${clientPhone}`, city: clientCity, occupation: clientOccupation, legal_type: clientLegalType, regime: clientTaxRegime });
        setDataInvoicing({ ...dataInvoicing, tax_regime: clientTaxRegime })
        setSecondaryLoader(false);
        setOpenConfirmUpdate(!openConfirmUpdate);
    }

    const getName = () => {
        if (selectedFiles !== undefined) {
            if (selectedFiles.length > 0) {
                const type = selectedFiles[0].type;
                if (type != undefined) {
                    if (type == 'application/zip') {
                        setNameFile(`${dataClient.rfc}_ASF.zip`);
                    } else {
                        setNameFile(`${dataClient.rfc}_ASF.rar`);
                    }
                } else {
                    setNameFile(selectedFiles[0].name);
                }
            }
        }
    }

    const handleUpper = (e) => {
        const rfcUpper = document.querySelector('#clientRfc');
        let rfcInput = e.target.value;
        rfcUpper.value = rfcInput.toUpperCase();
    }

    const handlePaste = (e) => {
        const rfcUpper = document.querySelector('#clientRfc');
        const text = e.clipboardData.getData('Text');
        let rfcInput = text.split(" ").join("").toUpperCase();
        rfcUpper.value = rfcInput;
    }

    useEffect(() => {
        getName();
        return () => {
            setNameFile('');
        }
    }, []);

    useEffect(() => {
        reset();
    }, [openClientInformation]);

    const handleDownloadFile = async (name) => {
        setLoading(true);
        try {
            await downloadObjectS3(name);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setAlert({ active: true, type: 'error', message: 'Error al procesar tu solicitud' });
        }

    }

    const handleChangeLegal = val => {
        try {
            ref.current = val;
            regimeRef.current = '';
            setDataLegalType({ ...dataLegalType, prevLegal: val, prevRegime: undefined });
        } catch (error) {
            console.log(error);
        }
    }

    const handleChangeRegime = val => {
        regimeRef.current = val;
        setDataLegalType({ ...dataLegalType, prevRegime: val });
    }

    const handleCancelOperation = () => {
        reset();
        setDataLegalType({ prevLegal: dataClient.legal_type, prevRegime: dataClient.regime });
        setOpenClientInformation(!openClientInformation);
    }

    return (
        <>
            <div className="w-full h-full border border-v2-gray-border-tables rounded-10 bg-white xs:mt-4 md:mt-0">
                <div className='w-full xs:px-2 lg:px-4 py-[7px] border-b border-v2-gray-border-tables flex items-center'>
                    <div className="w-12 text-v2-blue-icons">
                        <FontAwesomeIcon icon={faUserCircle} className='leading-3 pl-1 text-3.5xl' />
                    </div>
                    <div className='xs:w-8/12 md:w-8/12 text-xl font-semibold text-v2-input-text'>
                        Datos del cliente
                    </div>
                    <div className={`${edit ? 'flex' : 'hidden'} w-4/12 justify-end items-center text-button-orange`}>
                        <div className="w-full flex items-center justify-end">
                            <span className="flex items-center text-v2-yellow-edit-info text-base cursor-pointer" onClick={() => setOpenClientInformation(!openClientInformation)}>
                                <FontAwesomeIcon icon={faPenToSquare} className="leading-3 text-2xl" />
                                <span className="underline pl-1 pt-1 xs:hidden md:block">
                                    Editar datos
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="w-full p-4">
                    <div className="w-full px-2 pt-1 text-xs text-v2-gray-title-client font-bold">
                        Cliente:
                        <span className={`${dataClient.first_name == undefined ? 'opacity-40' : 'opacity-100'} w-full text-v2-input-text text-base font-normal block`}>
                            {(() => {
                                if (edit) return dataClient.first_name == undefined ? 'Agregar nombre del cliente' : `${dataClient.first_name} ${dataClient.last_name}`;
                                return dataClient.first_name == undefined ? 'Nombre no añadido' : `${dataClient.first_name} ${dataClient.last_name}`;
                            })()}
                        </span>
                    </div>
                    <div className="w-full px-2 pt-3 text-xs text-v2-gray-title-client font-bold">
                        RFC:
                        <span className={`${dataClient.rfc == undefined ? 'opacity-40' : 'opacity-100'} w-full text-v2-input-text text-base font-normal block`}>
                            {edit ?
                                dataClient.rfc || 'Agregar RFC'
                                :
                                dataClient.rfc || 'RFC no añadido'
                            }
                        </span>
                    </div>
                    <div className="w-full px-2 pt-3 text-xs text-v2-gray-title-client font-bold">
                        Tipo de persona fiscal:
                        <span className={`${dataClient.legal_type == undefined ? 'opacity-40' : 'opacity-100'} w-full text-v2-input-text text-base font-normal block`}>
                            {(() => {
                                if (dataClient.legal_type) return dataClient.legal_type == 'personal' ? 'Persona Física' : 'Persona Moral';
                                return edit ? 'Agregar Tipo de persona' : 'Tipo de persona no añadida';
                            })()}
                        </span>
                    </div>
                    <div className="w-full px-2 pt-3 text-xs text-v2-gray-title-client font-bold">
                        Régimen fiscal:
                        <span className={`${dataClient.regime == undefined ? 'opacity-40' : 'opacity-100'} w-full text-v2-input-text text-base font-normal block`}>
                            {edit ?
                                dataClient.regime || 'Agregar Régimen fiscal'
                                :
                                dataClient.regime || 'Régimen fiscal no añadido'
                            }
                        </span>
                    </div>
                    <div className="w-full px-2 pt-3 text-xs text-v2-gray-title-client font-bold">
                        Correo:
                        <span className={`${dataClient.email == undefined ? 'opacity-40' : 'opacity-100'} w-full text-v2-input-text text-base font-normal block truncate`}>
                            {edit ?
                                dataClient.email || 'Agregar email'
                                :
                                dataClient.email || 'Email no añadido'
                            }
                        </span>
                    </div>
                    <div className="w-full px-2 pt-3 text-xs text-v2-gray-title-client font-bold">
                        Móvil:
                        <span className={`${dataClient.phone_number == undefined ? 'opacity-40' : 'opacity-100'} w-full text-v2-input-text text-base font-normal block`}>
                            {edit ?
                                dataClient.phone_number || 'Agregar número celular'
                                :
                                dataClient.phone_number || 'Número no añadido'
                            }
                        </span>
                    </div>
                    <div className="w-full px-2 pt-3 text-xs text-v2-gray-title-client font-bold">
                        Ciudad de residencia:
                        <span className={`${dataClient.city == undefined ? 'opacity-40' : 'opacity-100'} w-full text-v2-input-text text-base font-normal block`}>
                            {edit ?
                                dataClient.city || 'Agregar residencia'
                                :
                                dataClient.city || 'Ninguna residencia añadida'
                            }
                        </span>
                    </div>
                    <div className="w-full px-2 pt-3 text-xs text-v2-gray-title-client font-bold">
                        Ocupación:
                        <span className={`${dataClient.occupation == undefined ? 'opacity-40' : 'opacity-100'} w-full text-v2-input-text text-base font-normal block`}>
                            {edit ?
                                dataClient.occupation || 'Agregar ocupación'
                                :
                                dataClient.occupation || 'Ninguna ocupación añadida'
                            }
                        </span>
                    </div>
                    {!edit &&
                        <div className="w-full px-2 pt-3 text-xs text-v2-gray-title-client font-bold">
                            Documentos fiscales:
                            <span className="w-full text-v2-input-text text-base font-normal block py-2">
                                {selectedFiles?.length > 0 ?
                                    <div className="w-full flex items-center">
                                        <FontAwesomeIcon icon={faFileZipper} className="text-v2-gray-light-plus-circle text-3xl leading-3" />
                                        <button className={`${loading ? 'cursor-not-allowed opacity-50' : 'cursor-pointer opacity-100'} text-v2-text-bar-steps text-[15px] pl-7 underline border-none`} disabled={loading ? true : false} onClick={() => handleDownloadFile(nameFile)}>
                                            {nameFile}
                                        </button>
                                        {loading && <span className='w-7 h-7 inline-block pl-3'><svg role="status" className="w-7 h-7 mr-2 text-v2-blue-text-login animate-spin fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                        </svg></span>}
                                    </div>
                                    :
                                    <span className="w-full text-v2-input-text text-base font-normal block opacity-40">
                                        Archivo no añadido
                                    </span>
                                }
                            </span>
                        </div>
                    }
                </div>
            </div>
            <Modal title="Datos del cliente" modalOpen={openClientInformation} modalOpenChange={setOpenClientInformation} marginY='my-full'>
                <div className="w-full xs:px-6 md:px-10 xs:py-0 md:py-3 xs:mt-2 md:mt-2 xs:mb-2.5 md:mb-5 overflow-auto h-5/6 max-h-[400px]">
                    <div className="w-full grid xs:grid-cols-1 md:grid-cols-2 md:gap-5">
                        <div className="w-full text-xs text-v2-gray-title-client xs:mt-1 md:mt-4">
                            Cliente
                            <input
                                className={`${errors.clientName ? 'border border-v2-red-error-services-selected' : 'border border-v2-border-time focus:border-v2-blue-text-login focus:shadow-md focus:shadow-v2-blue-text-login/30'} w-full py-2 px-3 mt-1 bg-white text-base text-v2-input-text rounded-md outline-none flex items-center`}
                                defaultValue={dataClient.first_name == '' ? '' : `${dataClient.first_name} ${dataClient.last_name}`}
                                inputMode="text"
                                id="clientName"
                                name="clientName"
                                autoComplete='off'
                                placeholder='Agregar nombre del cliente'
                                type="text"
                                {...register("clientName", {
                                    required: true,
                                    minLength: 7
                                })}
                            />
                            <span className='block pt-0.5 text-v2-red-error-services-selected'>{errors.clientName && "Ingresa el nombre completo"}</span>
                        </div>
                        <div className="w-full text-xs text-v2-gray-title-client xs:mt-1 md:mt-4">
                            RFC
                            <input
                                className={`${errors.clientRfc ? 'border border-v2-red-error-services-selected' : 'border border-v2-border-time focus:border-v2-blue-text-login focus:shadow-md focus:shadow-v2-blue-text-login/30'} w-full py-2 px-3 mt-1 bg-white text-base text-v2-input-text rounded-md outline-none flex items-center`}
                                defaultValue={dataClient.rfc == '' ? '' : dataClient.rfc}
                                inputMode="text"
                                id="clientRfc"
                                name="clientRfc"
                                placeholder='RFC con homoclave'
                                type="text"
                                autoComplete='off'
                                maxLength={13}
                                onKeyUp={handleUpper}
                                onPaste={handlePaste}
                                {...register("clientRfc", {
                                    required: true,
                                    minLength: dataLegalType.prevLegal == 'personal' ? 13 : 12,
                                    pattern: dataLegalType.prevLegal == 'personal' ? /^[A-Z]{4}\d{6}[A-Z0-9]{3}$/i : /^([A-ZÑ&]{3,4})(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01]))([A-Z\d]{2})([A\d])$/i
                                })}
                            />
                            <span className='block pt-0.5 text-v2-red-error-services-selected'>{errors.clientRfc && "Ingresa el RFC correctamente"}</span>
                        </div>
                    </div>
                    <div className="w-full grid xs:grid-cols-1 md:grid-cols-2 md:gap-5">
                        <div className="w-full text-xs text-v2-gray-title-client xs:mt-1 md:mt-4">
                            Tipo de persona fiscal
                            {(() => {
                                let className = ref.current == '' ? 'border border-v2-border-time text-v2-input-text/60' : 'border border-v2-border-time focus:border-v2-blue-text-login focus:shadow-md focus:shadow-v2-blue-text-login/30';
                                if (errors.clientLegalType) className = 'border border-v2-red-error-services-selected';
                                let defaultOption = 'Selecciona un tipo';
                                if (dataLegalType.prevLegal) defaultOption = dataLegalType.prevLegal == 'personal' ? 'Persona Física' : 'Persona Moral';
                                return (
                                    <select
                                        className={`${className} w-full py-2 px-3 mt-1 bg-white text-base text-v2-input-text rounded-md outline-none flex items-center`}
                                        {...register("clientLegalType", { required: true, minLength: 1 })}
                                        onChange={e => handleChangeLegal(e.target.value)}>
                                        <option value={dataLegalType.prevLegal == undefined ? '' : dataLegalType.prevLegal} defaultValue>
                                            {defaultOption}
                                        </option>
                                        {dataLegalType.prevLegal == 'personal' && <option value='enterprise'>Persona Moral</option>}
                                        {dataLegalType.prevLegal == 'enterprise' && <option value='personal'>Persona Física</option>}
                                        {dataLegalType.prevLegal == undefined && <option value='personal'>Persona Física</option>}
                                        {dataLegalType.prevLegal == undefined && <option value='enterprise'>Persona Moral</option>}
                                    </select>
                                );
                            })()}
                            <span className='block pt-0.5 text-v2-red-error-services-selected'>{errors.clientLegalType && "Selecciona un tipo de persona fiscal"}</span>
                        </div>
                        <div className="w-full text-xs text-v2-gray-title-client xs:mt-1 md:mt-4">
                            Régimen fiscal
                            {(() => {
                                let className = regimeRef.current == '' ? 'border border-v2-border-time text-v2-input-text/60' : 'border border-v2-border-time focus:border-v2-blue-text-login focus:shadow-md focus:shadow-v2-blue-text-login/30 opacity-100'
                                if (errors.clientTaxRegime) className = 'border border-v2-red-error-services-selected';
                                return (
                                    <select className={`${className} w-full py-2 px-3 mt-1 bg-white text-base text-v2-input-text rounded-md outline-none flex items-center`}
                                        {...register("clientTaxRegime", { validate: val => val != '', onChange: (e) => handleChangeRegime(e.target.value) })}>
                                        <option value={dataLegalType.prevRegime == undefined ? '' : dataLegalType.prevRegime} defaultValue>{dataLegalType.prevRegime == undefined ? 'Selecciona un régimen' : dataLegalType.prevRegime}</option>
                                        {dataLegalType.prevLegal == 'personal' ?
                                            <>
                                                {listTaxRegime !== undefined && listTaxRegime.filter(item => item.fisica == 'Sí' && item.descripcion != dataLegalType.prevRegime).map((regime) => (
                                                    <option key={regime.id} value={regime.descripcion}>
                                                        {regime.descripcion}
                                                    </option>
                                                ))}
                                            </>
                                            :
                                            <>
                                                {listTaxRegime !== undefined && listTaxRegime.filter(item => item.moral == 'Sí' && item.descripcion != dataLegalType.prevRegime).map((regime) => (
                                                    <option key={regime.id} value={regime.descripcion}>
                                                        {regime.descripcion}
                                                    </option>
                                                ))}
                                            </>
                                        }
                                    </select>
                                );
                            })()}
                            <span className='block pt-0.5 text-v2-red-error-services-selected'>{errors.clientTaxRegime && "Selecciona un régimen"}</span>
                        </div>
                    </div>
                    <div className="w-full grid xs:grid-cols-1 md:grid-cols-2 md:gap-5">
                        <div className="w-full text-xs text-v2-gray-title-client xs:mt-1 md:mt-4">
                            Móvil
                            <input
                                className={`${errors.clientPhone ? 'border border-v2-red-error-services-selected' : 'border border-v2-border-time focus:border-v2-blue-text-login focus:shadow-md focus:shadow-v2-blue-text-login/30'} w-full py-2 px-3 mt-1 bg-white text-base text-v2-input-text rounded-md outline-none flex items-center`}
                                defaultValue={dataClient.phone_number == undefined ? dataClient.phone_number : dataClient.phone_number.substr(-10)}
                                inputMode="tel"
                                id="clientPhone"
                                name="clientPhone"
                                autoComplete='off'
                                placeholder='Número de teléfono'
                                maxLength={10}
                                type="tel"
                                {...register("clientPhone", {
                                    required: true,
                                    minLength: 10
                                })}
                            />
                            <span className='block pt-0.5 text-v2-red-error-services-selected'>{errors.clientPhone && "Ingresa el número completo"}</span>
                        </div>
                        <div className="w-full text-xs text-v2-gray-title-client xs:mt-1 md:mt-4">
                            Ciudad de residencia
                            <input
                                className={`${errors.clientCity ? 'border border-v2-red-error-services-selected' : 'border border-v2-border-time focus:border-v2-blue-text-login focus:shadow-md focus:shadow-v2-blue-text-login/30'} w-full py-2 px-3 mt-1 bg-white text-base text-v2-input-text rounded-md outline-none flex items-center`}
                                defaultValue={dataClient.city == '' ? '' : dataClient.city}
                                inputMode="text"
                                id="clientCity"
                                name="clientCity"
                                autoComplete='off'
                                placeholder='Ciudad de residencia'
                                type="text"
                                {...register("clientCity", { required: true })}
                            />
                            <span className='block pt-0.5 text-v2-red-error-services-selected'>{errors.clientCity && "Ingresa una ciudad correcta"}</span>
                        </div>
                    </div>
                    <div className="w-full grid xs:grid-cols-1 md:grid-cols-2 md:gap-5">
                        <div className="w-full text-xs text-v2-gray-title-client xs:mt-1 md:mt-4">
                            Ocupación
                            <input
                                className={`${errors.clientOccupation ? 'border border-v2-red-error-services-selected' : 'border border-v2-border-time focus:border-v2-blue-text-login focus:shadow-md focus:shadow-v2-blue-text-login/30'} w-full py-2 px-3 mt-1 bg-white text-base text-v2-input-text rounded-md outline-none flex items-center`}
                                defaultValue={dataClient.occupation == '' ? '' : dataClient.occupation}
                                inputMode="text"
                                id="clientOccupation"
                                name="clientOccupation"
                                autoComplete='off'
                                placeholder='Ocupación'
                                type="text"
                                {...register("clientOccupation", { required: true })}
                            />
                            <span className='block pt-0.5 text-v2-red-error-services-selected'>{errors.clientOccupation && "Ingresa una ocupación correcta"}</span>
                        </div>
                    </div>
                    <div className="w-full xs:mt-2 md:mt-6">
                        <div className="w-full xs:block md:flex items-center gap-32">
                            <div className="xs:w-full md:w-7/12 xs:text-center md:text-right xs:mb-4 md:mb-0">
                                <span className="w-full underline text-v2-blue-links text-base opacity-60 text-right cursor-pointer" onClick={handleCancelOperation}>
                                    Cancelar
                                </span>
                            </div>
                            <div className="xs:w-full md:w-5/12">
                                <Button heigth="h-12" bg="bg-v2-blue-text-login" onClick={handleSubmit(modifyDataClient)}>
                                    GUARDAR
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal hasTitle={false} modalOpen={openConfirmUpdate} modalOpenChange={setOpenConfirmUpdate} maxWidth="max-w-lg">
                <div className="w-full pt-2 pb-5 px-[9px]">
                    <div className="w-full flex justify-end">
                        <span className="w-full flex justify-end text-v2-gray-light-plus-circle leading-3 text-2xl cursor-pointer" onClick={() => setOpenConfirmUpdate(!openConfirmUpdate)}>
                            <FontAwesomeIcon icon={faCircleXmark} />
                        </span>
                    </div>
                    <div className="w-full mt-4">
                        <span className="flex justify-center text-v2-border-alert-success leading-3 text-5xl">
                            <FontAwesomeIcon icon={faCircleCheck} />
                        </span>
                    </div>
                    <div className="w-full mt-5">
                        <span className="w-full flex justify-center text-v2-gray-title-service-modal text-xl text-center font-bold">
                            <span className='xs:w-full md:w-2/4'>
                                Cambios aplicados correctamente
                            </span>
                        </span>
                        <span className="w-full flex justify-center text-v2-text-bar-steps mt-6">
                            <span className="text-center">
                                Tus cambios han sido aplicados.
                            </span>
                        </span>
                    </div>
                    <div className="mt-28 px-4">
                        <Button heigth="h-12" width="w-full" bg="bg-v2-blue-text-login" onClick={() => setOpenConfirmUpdate(!openConfirmUpdate)}>
                            Listo
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

GeneralDataClient.propTypes = {
    dataClient: PropTypes.any,
    setDataClient: PropTypes.any,
    edit: PropTypes.any,
    selectedFiles: PropTypes.any,
    setSecondaryLoader: PropTypes.any,
    setDataInvoicing: PropTypes.any,
    dataInvoicing: PropTypes.any
}

export default GeneralDataClient