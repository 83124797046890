import React, { useEffect, useState, useContext, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faPenToSquare, faReceipt, faCircleXmark, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { AlertContext } from '../context/AlertContext';
import { useForm } from 'react-hook-form';
import Modal from './Modal';
import Button from './Button';
import PropTypes from 'prop-types';
import { updateInvoicingData } from '../apiClient/operations/accountsOperations';
import { getTaxRegimeList } from '../apiClient/operations/sat';

function InvoicingData({ dataInvoicing, setDataInvoicing, edit, source, dataClient, setSecondaryLoader }) {
    const { setAlert } = useContext(AlertContext);
    const ref = useRef(dataInvoicing.legal_type == undefined ? '' : dataInvoicing.legal_type);
    const regimeRef = useRef(dataInvoicing.tax_regime == undefined ? '' : dataInvoicing.tax_regime);
    const [openModalInvoicer, setOpenModalInvoicer] = useState(false);
    const [openConfirmUpdate, setOpenConfirmUpdate] = useState(false);
    const [listTaxRegime, setListTaxRegime] = useState([]);
    const [dataLegalType, setDataLegalType] = useState({ prevLegal: dataInvoicing.legal_type == undefined ? null : dataInvoicing.legal_type, prevRegime: dataInvoicing.tax_regime == undefined ? '' : dataInvoicing.tax_regime });
    const { register: register2, handleSubmit: handleSubmit2, formState: { errors }, reset } = useForm();

    const chargeListTaxRegime = async () => {
        try {
            const getData = await getTaxRegimeList();
            setListTaxRegime(getData);
        } catch (error) {
            setAlert({ active: true, type: 'error', message: 'Error al procesar tu solicitud' })
        }
    }

    useEffect(() => {
        chargeListTaxRegime();
        return () => {
            setListTaxRegime([]);
        }
    }, []);

    useEffect(() => {
        reset();
    }, [openModalInvoicer]);

    const modifydataInvoicing = async (data) => {
        setOpenModalInvoicer(!openModalInvoicer);
        setSecondaryLoader(true)
        try {
            const { invoicingEmail, invoicingName, invoicingRfc, invoicingLegaltype, invoicingTaxRegime, invoicingCP } = data;
            setDataInvoicing({ businessName: invoicingName, rfcInvoicing: invoicingRfc, emailInvoicing: invoicingEmail, legal_type: invoicingLegaltype, tax_regime: invoicingTaxRegime, postal_code: invoicingCP });
            const obj = {
                tax_id: invoicingRfc,
                name: invoicingName,
                tax_regime: invoicingTaxRegime,
                legal_type: invoicingLegaltype,
                email: invoicingEmail,
                postal_code: invoicingCP
            }
            await updateInvoicingData(obj, dataClient.id);
            setSecondaryLoader(false);
            setOpenConfirmUpdate(!openConfirmUpdate);
        } catch (error) {
            setSecondaryLoader(false);
            setAlert({ active: true, type: 'error', message: 'Error al procesar tu solicitud' })
        }

    }

    const handleUpper = (e) => {
        const rfcUpper = document.querySelector('#invoicingRfc');
        let rfcInput = e.target.value;
        rfcUpper.value = rfcInput.toUpperCase();
    }

    const handlePaste = (e) => {
        const rfcUpper = document.querySelector('#invoicingRfc');
        const text = e.clipboardData.getData('Text');
        let rfcInput = text.split(" ").join("").toUpperCase();
        rfcUpper.value = rfcInput;
    }

    const handleChangeLegal = val => {
        ref.current = val;
        regimeRef.current = '';
        setDataLegalType({ ...dataLegalType, prevLegal: val, prevRegime: undefined });
    }

    const handleChangeRegime = val => {
        regimeRef.current = val;
        setDataLegalType({ ...dataLegalType, prevRegime: val });
    }

    const handleCancelOperation = () => {
        reset();
        setDataLegalType({ prevLegal: dataClient.legal_type, prevRegime: dataClient.regime });
        setOpenModalInvoicer(!openModalInvoicer);
    }

    return (
        <div className="w-full h-full border border-v2-gray-border-tables rounded-10 bg-white xs:mt-4 md:mt-0">
            <div className='w-full xs:px-2 md:px-4 py-[7px] border-b border-v2-gray-border-tables flex items-center'>
                {source == 'add' ?
                    <div className="w-12 text-v2-blue-icons">
                        <FontAwesomeIcon icon={faUserCircle} className='leading-3 pl-1 text-3.5xl' />
                    </div>
                    :
                    <div className="w-11 text-center">
                        <div className="w-9 h-9 bg-v2-blue-text-login text-white rounded-full">
                            <FontAwesomeIcon icon={faReceipt} className='leading-3 text-2xl pt-1.5' />
                        </div>
                    </div>
                }
                <div className='xs:w-8/12 md:w-8/12 text-xl font-semibold text-v2-input-text'>
                    Facturación
                </div>
                <div className={`${edit ? 'flex' : 'hidden'} xs:w-4/12 md:w-4/12 justify-end items-center text-button-orange`}>
                    <div className="w-full flex items-center justify-end">
                        <span className="flex items-center text-v2-yellow-edit-info text-base cursor-pointer" onClick={() => setOpenModalInvoicer(!openModalInvoicer)}>
                            <FontAwesomeIcon icon={faPenToSquare} className="leading-3 text-2xl" />
                            <span className="underline pl-1 pt-1 xs:hidden md:block">
                                Editar datos
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            <div className="w-full px-[18px] py-5">
                <div className="w-full text-xs text-v2-gray-title-client font-bold">
                    Razón social:
                    <span className={`${dataInvoicing.businessName == undefined ? 'opacity-40' : 'opacity-100'} w-full text-v2-input-text text-base font-normal block`}>
                        {edit ?
                            dataInvoicing.businessName || 'Agregar razón social'
                            :
                            dataInvoicing.businessName || 'Ninguna razón social añadida'
                        }
                    </span>
                </div>
                <div className="w-full pt-3 text-xs text-v2-gray-title-client font-bold">
                    RFC:
                    <span className={`${dataInvoicing.rfcInvoicing == undefined ? 'opacity-40' : 'opacity-100'} w-full text-v2-input-text text-base font-normal block`}>
                        {edit ?
                            dataInvoicing.rfcInvoicing || 'Agregar RFC'
                            :
                            dataInvoicing.rfcInvoicing || 'RFC no añadido'
                        }
                    </span>
                </div>
                <div className="w-full pt-3 text-xs text-v2-gray-title-client font-bold">
                    Tipo de persona fiscal:
                    <span className={`${dataInvoicing.legal_type == undefined ? 'opacity-40' : 'opacity-100'} w-full text-v2-input-text text-base font-normal block`}>
                        {(() => {
                            if (dataInvoicing.legal_type) return dataInvoicing.legal_type == 'personal' ? 'Persona Física' : 'Persona Moral';
                            return edit ? 'Agregar Tipo de persona' : 'Tipo de persona no añadida';
                        })()}
                    </span>
                </div>
                <div className="w-full pt-3 text-xs text-v2-gray-title-client font-bold">
                    Régimen fiscal:
                    <span className={`${dataInvoicing.tax_regime == undefined ? 'opacity-40' : 'opacity-100'} w-full text-v2-input-text text-base font-normal block`}>
                        {edit ?
                            dataInvoicing.tax_regime || 'Agregar régimen fiscal'
                            :
                            dataInvoicing.tax_regime || 'Régimen fiscal no añadido'
                        }
                    </span>
                </div>
                <div className="w-full pt-3 text-xs text-v2-gray-title-client font-bold">
                    Código postal:
                    <span className={`${dataInvoicing.postal_code == undefined ? 'opacity-40' : 'opacity-100'} w-full text-v2-input-text text-base font-normal block`}>
                        {edit ?
                            dataInvoicing.postal_code || 'Agregar código postal'
                            :
                            dataInvoicing.postal_code || 'Código postal no añadido'
                        }
                    </span>
                </div>
                <div className="w-full pt-3 text-xs text-v2-gray-title-client font-bold">
                    Correo:
                    <span className={`${dataInvoicing.emailInvoicing == undefined ? 'opacity-40' : 'opacity-100'} w-full text-v2-input-text text-base font-normal block truncate`}>
                        {edit ?
                            dataInvoicing.emailInvoicing || 'Agregar correo'
                            :
                            dataInvoicing.emailInvoicing || 'Ningún email añadido'
                        }
                    </span>
                </div>
            </div>
            <Modal title='Facturación' modalOpen={openModalInvoicer} modalOpenChange={setOpenModalInvoicer}>
                <div className="w-full xs:px-6 md:px-10 xs:py-0 md:py-3 xs:mt-2 md:mt-2 xs:mb-2.5 md:mb-6 overflow-auto h-5/6">
                    <div className="w-full grid xs:grid-cols-1 md:grid-cols-2 md:gap-5">
                        <div className='w-full text-xs text-v2-gray-title-client xs:mt-1 md:mt-4'>
                            Razón social
                            <input
                                className={`${errors.invoicingName ? 'border border-v2-red-error-services-selected' : 'border border-v2-border-time focus:border-v2-blue-text-login focus:shadow-md focus:shadow-v2-blue-text-login/30'} w-full py-2 px-3 mt-1 bg-white text-base text-v2-input-text rounded-md outline-none flex items-center`}
                                defaultValue={dataInvoicing.businessName == '' ? '' : dataInvoicing.businessName}
                                inputMode="text"
                                id="invoicingName"
                                name="invoicingName"
                                autoComplete='off'
                                placeholder='Agregar razón social'
                                type="text"
                                {...register2("invoicingName", {
                                    required: true,
                                    minLength: 7
                                })}
                            />
                            <span className='block pt-0.5 text-v2-red-error-services-selected'>{errors.invoicingName && "Ingresa la razón social completa"}</span>
                        </div>
                        <div className="w-full text-xs text-v2-gray-title-client xs:mt-1 md:mt-4">
                            RFC
                            <input
                                className={`${errors.invoicingRfc ? 'border border-v2-red-error-services-selected' : 'border border-v2-border-time focus:border-v2-blue-text-login focus:shadow-md focus:shadow-v2-blue-text-login/30'} w-full py-2 px-3 mt-1 bg-white text-base text-v2-input-text rounded-md outline-none flex items-center`}
                                defaultValue={dataInvoicing.rfcInvoicing == '' ? '' : dataInvoicing.rfcInvoicing}
                                inputMode="text"
                                id="invoicingRfc"
                                name="invoicingRfc"
                                type="text"
                                autoComplete='off'
                                onKeyUp={handleUpper}
                                onPaste={handlePaste}
                                placeholder='Agregar RFC con homoclave'
                                maxLength={13}
                                {...register2("invoicingRfc", {
                                    required: true,
                                    minLength: dataLegalType.prevLegal == 'personal' ? 13 : 12,
                                    pattern: dataLegalType.prevLegal == 'personal' ? /^[A-Z]{4}\d{6}[A-Z0-9]{3}$/i : /^([A-ZÑ&]{3})(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01]))([A-Z\d]{2})([A\d])$/i
                                })}
                            />
                            <span className='block pt-0.5 text-v2-red-error-services-selected'>{errors.invoicingRfc && "Ingresa el RFC correctamente"}</span>
                        </div>
                    </div>
                    <div className="w-full grid xs:grid-cols-1 md:grid-cols-2 md:gap-5">
                        <div className="w-full text-xs text-v2-gray-title-client xs:mt-1 md:mt-4">
                            Tipo de persona fiscal
                            {(() => {
                                let className = ref.current == '' ? 'border border-v2-border-time text-v2-input-text/60' : 'border border-v2-border-time focus:border-v2-blue-text-login focus:shadow-md focus:shadow-v2-blue-text-login/30 opacity-100';
                                if (errors.invoicingLegaltype) className = 'border border-v2-red-error-services-selected';
                                let defaultOption = 'Selecciona un tipo';
                                if (dataLegalType.prevLegal) defaultOption = dataLegalType.prevLegal == 'personal' ? 'Persona Física' : 'Persona Moral';
                                return (
                                    <select className={`${className} w-full py-2 px-3 mt-1 bg-white text-base text-v2-input-text rounded-md outline-none flex items-center`}
                                        {...register2("invoicingLegaltype", { required: true, minLength: 1 })}
                                        onChange={e => handleChangeLegal(e.target.value)}>
                                        <option value={dataLegalType.prevLegal == undefined ? '' : dataLegalType.prevLegal} defaultValue>
                                            {defaultOption}
                                        </option>
                                        {dataLegalType.prevLegal == 'personal' && <option value='enterprise'>Persona Moral</option>}
                                        {dataLegalType.prevLegal == 'enterprise' && <option value='personal'>Persona Física</option>}
                                        {dataLegalType.prevLegal == undefined && <option value='personal'>Persona Física</option>}
                                        {dataLegalType.prevLegal == undefined && <option value='enterprise'>Persona Moral</option>}
                                    </select>
                                );
                            })()}
                            <span className='block pt-0.5 text-v2-red-error-services-selected'>{errors.invoicingLegaltype && "Selecciona un tipo de persona fiscal"}</span>
                        </div>
                        <div className="w-full text-xs text-v2-gray-title-client xs:mt-1 md:mt-4">
                            Régimen fiscal
                            {(() => {
                                let className = regimeRef.current == '' ? 'border border-v2-border-time text-v2-input-text/60' : 'border border-v2-border-time focus:border-v2-blue-text-login focus:shadow-md focus:shadow-v2-blue-text-login/30 opacity-100';
                                if (errors.invoicingTaxRegime) className = 'border border-v2-red-error-services-selected';
                                return (
                                    <select className={`${className} w-full py-2 px-3 mt-1 bg-white text-base text-v2-input-text rounded-md outline-none flex items-center`} 
                                        {...register2("invoicingTaxRegime", { validate: val => val != '', onChange: (e) => handleChangeRegime(e.target.value) })} >
                                        <option value={dataLegalType.prevRegime == undefined ? '' : dataLegalType.prevRegime} defaultValue>{dataLegalType.prevRegime == undefined ? 'Selecciona un régimen' : dataLegalType.prevRegime}</option>
                                        {dataLegalType.prevLegal == 'personal' ?
                                            <>
                                                {listTaxRegime !== undefined && listTaxRegime.filter(item => item.fisica == 'Sí' && item.descripcion != dataLegalType.prevRegime).map((regime) => (
                                                    <option key={regime.id} value={regime.descripcion}>
                                                        {regime.descripcion}
                                                    </option>
                                                ))}
                                            </>
                                            :
                                            <>
                                                {listTaxRegime !== undefined && listTaxRegime.filter(item => item.moral == 'Sí' && item.descripcion != dataLegalType.prevRegime).map((regime) => (
                                                    <option key={regime.id} value={regime.descripcion}>
                                                        {regime.descripcion}
                                                    </option>
                                                ))}
                                            </>
                                        }
                                    </select>
                                );
                            })()}
                            <span className='block pt-0.5 text-v2-red-error-services-selected'>{errors.invoicingTaxRegime && "Selecciona un régimen"}</span>
                        </div>
                    </div>
                    <div className="w-full grid xs:grid-cols-1 md:grid-cols-2 md:gap-5">
                        <div className="w-full text-xs text-v2-gray-title-client xs:mt-1 md:mt-4">
                            Correo
                            <input
                                className={`${errors.invoicingEmail ? 'border border-v2-red-error-services-selected' : 'border border-v2-border-time focus:border-v2-blue-text-login focus:shadow-md focus:shadow-v2-blue-text-login/30'} w-full py-2 px-3 mt-1 bg-white text-base text-v2-input-text rounded-md outline-none flex items-center`}
                                defaultValue={dataInvoicing.emailInvoicing == '' ? '' : dataInvoicing.emailInvoicing}
                                inputMode="email"
                                id="invoicingEmail"
                                name="invoicingEmail"
                                autoComplete='off'
                                placeholder='Agregar correo electrónico'
                                type="email"
                                {...register2("invoicingEmail", {
                                    required: true,
                                    minLength: 10
                                })}
                            />
                            <span className='block pt-0.5 text-v2-red-error-services-selected'>{errors.invoicingEmail && "Ingresa el correo electrónico"}</span>
                        </div>
                        <div className="w-full text-xs text-v2-gray-title-client xs:mt-1 md:mt-4">
                            Código Postal:
                            <input
                                className={`${errors.invoicingCP ? 'border border-v2-red-error-services-selected' : 'border border-v2-border-time focus:border-v2-blue-text-login focus:shadow-md focus:shadow-v2-blue-text-login/30'} w-full py-2 px-3 mt-1 bg-white text-base text-v2-input-text rounded-md outline-none flex items-center`}
                                defaultValue={dataInvoicing.postal_code == undefined ? '' : dataInvoicing.postal_code}
                                inputMode='numeric'
                                id="invoicingCP"
                                name="invoicingCP"
                                autoComplete='off'
                                placeholder='Agregar código postal'
                                maxLength={5}
                                type="text"
                                {...register2("invoicingCP", {
                                    required: true,
                                    minLength: 5,
                                    maxLength: 5,
                                    pattern: /^\d{5}$/i
                                })}
                            />
                            <span className='block pt-0.5 text-v2-red-error-services-selected'>{errors.invoicingCP && "Ingresa el código postal"}</span>
                        </div>
                    </div>
                    <div className="w-full xs:mt-2 md:mt-6">
                        <div className="w-full xs:block md:flex items-center gap-32">
                            <div className="xs:w-full md:w-7/12 xs:text-center md:text-right xs:mb-2 md:mb-0">
                                <span className="w-full underline text-v2-blue-links text-base opacity-60 text-right cursor-pointer" onClick={handleCancelOperation}>
                                    Cancelar
                                </span>
                            </div>
                            <div className="xs:w-full md:w-5/12">
                                <Button heigth="h-12" bg="bg-v2-blue-text-login" onClick={handleSubmit2(modifydataInvoicing)}>
                                    GUARDAR
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal hasTitle={false} modalOpen={openConfirmUpdate} modalOpenChange={setOpenConfirmUpdate} maxWidth="max-w-lg">
                <div className="w-full pt-2 pb-5 px-[9px]">
                    <div className="w-full flex justify-end">
                        <span className="w-full flex justify-end text-v2-gray-light-plus-circle leading-3 text-2xl cursor-pointer" onClick={() => setOpenConfirmUpdate(!openConfirmUpdate)}>
                            <FontAwesomeIcon icon={faCircleXmark} />
                        </span>
                    </div>
                    <div className="w-full mt-4">
                        <span className="flex justify-center text-v2-border-alert-success leading-3 text-5xl">
                            <FontAwesomeIcon icon={faCircleCheck} />
                        </span>
                    </div>
                    <div className="w-full mt-5">
                        <span className="w-full flex justify-center text-v2-gray-title-service-modal text-xl text-center font-bold">
                            <span className='xs:w-full md:w-2/4'>
                                Cambios aplicados correctamente
                            </span>
                        </span>
                        <span className="w-full flex justify-center text-v2-text-bar-steps mt-6">
                            <span className="text-center">
                                Tus cambios han sido aplicados.
                            </span>
                        </span>
                    </div>
                    <div className="mt-28 px-4">
                        <Button heigth="h-12" width="w-full" bg="bg-v2-blue-text-login" onClick={() => setOpenConfirmUpdate(!openConfirmUpdate)}>
                            Listo
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

InvoicingData.propTypes = {
    dataInvoicing: PropTypes.any,
    setDataInvoicing: PropTypes.any,
    edit: PropTypes.bool,
    source: PropTypes.string,
    dataClient: PropTypes.any,
    setSecondaryLoader: PropTypes.any
}

export default InvoicingData