import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { AlertContext } from '../../context/AlertContext';
import Nav from '../../hooks/Nav';
import { getAccount } from '../../apiClient/operations/accountsOperations';
import { getActiveContract } from '../../apiClient/operations/contractsOperations';
import SkeletonLoader from '../../tailwindUI/SkeletonLoader';
import Badge from '../../tailwindUI/Badge';
import BillingInfo from '../contracts/ViewContract/BillingInfo';

const accountType = [
    {
        "name": "taxpayer",
        "label": "Contribuyente"
    },
    {
        "name": "customer",
        "label": "Cliente"
    }
]

const getAccountTypeLabel = (type) => (
    accountType.find((acc) => acc.name == type) != null ?
        accountType.find((acc) => acc.name == type).label : ''
)
function ViewBilling() {
    const { accountId } = useParams();
    const history = useHistory();
    const { state } = useLocation();
    const { setAlert } = useContext(AlertContext);
    const [isLoading, setIsloading] = useState(true);
    const [account, setAccount] = useState(null);
    const [contract, setContract] = useState(null);
    const [paymentBalance, setPaymentBalance] = useState(null);

    useEffect(() => {
        if (account) return;
        if (state?.account) {
            setAccount(state.account);
            setTimeout(() => {
                setIsloading(false);
            }, 500)
            return;
        }
        const fetchAccount = async () => {
            setIsloading(true);
            try {
                const account = await getAccount(accountId);
                setAccount(account);
            } catch (e) {
                console.log(e, isLoading)
                setAlert({ active: true, type: 'error', message: 'Error al obtener Account' })
            } finally {
                setIsloading(false)
            }
        }
        fetchAccount()
    }, [state, account]);

    useEffect(() => {
        const fetchActiveContract = async () => {
            if (!account) return;
            try {
                const res = await getActiveContract(accountId)
                setContract(res);
            } catch (e) {
                setAlert({ active: true, type: 'error', message: e.message || 'Hubo un error al obtener Active Contract' })
            }
        }
        fetchActiveContract();
    }, [account])


    const handleGoBack = () => {
        history.goBack();
    }

    return (
        <>
            <div className='w-full'>
                <div className="w-full">
                    <Nav>
                        <div className='text-white font-medium text-xl md:text-3xl xl:text-4xl flex gap-2 items-center'>
                            <ArrowLeftIcon className='w-10 md:w-12 h-10 md:h-12 cursor-pointer' onClick={handleGoBack} />
                            Contribuyente
                        </div>
                    </Nav>
                </div>
                <div className="w-full px-4">
                    <div className="md:block xs:block w-full">
                        <div className='md:w-full xs:hidden lg:block w-full'>
                            <div className="w-full text-3xl text-v2-input-text flex justify-between gap-4">
                                <div className="w-full mt-4 flex justify-between">
                                    <div className='text-gray-900 font-medium text-xl md:text-3xl xl:text-4xl flex gap-2'>
                                        <ArrowLeftIcon className='w-10 md:w-12 h-10 md:h-12 cursor-pointer' onClick={handleGoBack} />
                                        <div>
                                            {account?.name || <div className='w-[50vw]'><SkeletonLoader size='3xl' /></div>}
                                            <div className='flex flex-row gap-5'>
                                                <p className='text-lg text-gray-700 h-7'>{account?.taxpayer?.tax_id ? `RFC: ${account?.taxpayer?.tax_id}` : 'RFC: -'}</p>
                                                {(account?.account_type && (!(account?.account_type != 'taxpayer') || !(account?.account_type != 'customer'))) ? <Badge text={getAccountTypeLabel(account?.account_type)} /> : <></>}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="xs:w-full md:w-full xs:mt-20 lg:mt-0 mb-4 flex flex-col gap-4">
                            <div className='lg:hidden space-y-4'>
                                <div className="w-full space-y-4">
                                    <div className='text-gray-900 text-xl md:text-3xl xl:text-4xl'>
                                        {account?.name || <div className='w-[50vw]'><SkeletonLoader size='3xl' /></div>}
                                        {account?.taxpayer?.tax_id && (
                                            <p className='text-lg text-gray-700'>RFC: {account?.taxpayer?.tax_id}</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <BillingInfo account={account} contract={contract} accountId={accountId} paymentBalance={paymentBalance} setPaymentBalance={setPaymentBalance}/>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

ViewBilling.propTypes = {
    user: PropTypes.object
}

export default ViewBilling;