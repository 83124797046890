import apiClient from '../apiClient';
import { apiBaseUrl } from '../../config';

const translateEmail = email => {
    const formatedAtEmail = email ? email.replace('@', '%40') : '';
    const formatedPlusEmail = formatedAtEmail.replace('+', '%2B');
    return formatedPlusEmail
}

export const createPaymentLink = async (data) => {
    return apiClient.post(`${apiBaseUrl}/payment-links/`, data).then(response => {
        return response;
    });
}

export const getPaymentLinks = async (page, limit, search, sort, filters) => {
    const searchString = search?.includes('@') ? translateEmail(search) : search;
    const filterString = (filters.includes('recurrent') && filters.includes('|') && !filters.includes('status')) ? '' : filters.replace('recurrent~', 'recurrent=');
    const url = `${apiBaseUrl}/payment-links/?page=${page}&limit=${limit}&q=wildCard~${searchString || ''}${filterString || ''}&${ sort || 'field=created&order=-1'}`;
    return apiClient.get(url).then(response => {
        return response;
    });
}

export const getPaymentLinkById = async (linkId) => {
    return apiClient.get(`${apiBaseUrl}/payment-links/${linkId}`).then(response => {
        return response;
    });
}