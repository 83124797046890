import React, { useState, useEffect, useContext, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useHistory } from 'react-router-dom';
import { AlertContext } from '../../context/AlertContext';
import { getQuoteS3 } from '../../apiClient/operations/s3UploadOperations';
import { saveAs } from 'file-saver';
import { CSVLink } from "react-csv";
import { faArrowLeft, faTag, faCaretDown, faPenToSquare, faCircleXmark, faCartShopping, faDownload, faFileExport, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import Nav from '../../hooks/Nav';
import Button from '../../hooks/Button';
import useFormatterCurrency from '../../hooks/useFormatterCurrency';
import ResumeAmounts from '../../hooks/ResumeAmounts';
import Modal from '../../hooks/Modal';
import useConvertUnixToDate from '../../hooks/useConvertUnixToDate';
import AlertError from '../../hooks/Alert';
import InvoicingData from '../../hooks/InvoicingData';
import useConstructDates from '../../hooks/useConstructDates';
import useConstructDatesDisplay from '../../hooks/useConstructDatesDisplay';
import GeneralDataQuote from '../../hooks/GeneralDataQuote';
import QuoteValidity from '../../hooks/QuoteValidity';
import HeadersDataTables from '../../hooks/HeadersDataTables';
import useFormatDate from '../../hooks/useFormatDate';
import moment from 'moment';
import useTransformRfcToDate from '../../hooks/useTransformRfcToDate';
import useGetAge from '../../hooks/useGetAge';
import ModalV2 from '../../tailwindUI/Modal';
import RadioGroup from '../../tailwindUI/RadioGroup';
import PrimaryButton from '../../tailwindUI/PrimaryButton';
import useScrollPosition from '../../hooks/useScrollPosition';
import useGetStatusExpired from '../../hooks/useGetStatusExpired';
import BreadCrumb from '../../tailwindUI/BreadCrumb';
import PermissionsGate from '../../cognito/permissions/PermissionsGate';
import { ROLES } from '../../cognito/permissions/permission-maps';

function ViewQuote() {
    let history = useHistory();
    const user = JSON.parse(localStorage.getItem('user')) || {};
    const [quoteData] = useState(() => {
        const saved = localStorage.getItem("quote");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
    });
    const [accountData] = useState(() => {
        const saved = localStorage.getItem("account");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
    });
    const getTime = () => moment().format('MM/DD/YYYY H:mm:ss');
    const { getStatusExpired } = useGetStatusExpired();
    const { formatterCurrency } = useFormatterCurrency();
    const { formatDate } = useFormatDate();
    const { convertUnixToDate } = useConvertUnixToDate();
    const { getAge } = useGetAge();
    const setScrollPosition = useScrollPosition();
    const cycle = useRef('');
    const { setAlert } = useContext(AlertContext);
    const [step] = useState(1);
    const [dataClient] = useState({ id: accountData.id, first_name: accountData.first_name, last_name: accountData.last_name, rfc: accountData.taxpayer.tax_id, email: accountData.email, phone_number: accountData.phone_number, state: accountData.state, occupancy: accountData.occupancy, legal_type: accountData.legal_type, regime: accountData.taxpayer.regime });
    const [dataInvoicing, setDataInvoicing] = useState({ businessName: accountData.billing.name, rfcInvoicing: accountData.billing.tax_id, emailInvoicing: accountData.billing.email, legal_type: accountData.billing.legal_type, tax_regime: accountData.billing.tax_regime, postal_code: accountData.billing.postal_code });
    const [resumeAmounts] = useState({ subtotalAmount: (quoteData.price.subtotal_amount / 100), discountAmount: (quoteData.price.discount_amount / 100), totalAmount: (quoteData.price.total_amount / 100), numberServices: quoteData.service_items.data.length, totalPack: quoteData.price.special_price == undefined ? 0 : (quoteData.price.special_price.total_amount / 100), totalPackDiscount: quoteData.price.special_price == undefined ? 0 : (quoteData.price.special_price.discount_amount / 100) });
    const [selectedFiles] = useState([]);
    const [services, setServices] = useState([]);
    const [datesDisplay, setDatesDisplay] = useState([]);
    const [modalEditQuote, setModalEditQuote] = useState(false);
    const [loader, setLoader] = useState(false);
    const [finished, setFinished] = useState(false);
    const [dataCsv, setDataCsv] = useState([]);
    const [showSelectPrice, setShowSelectPrice] = useState(false);
    const [selectedPrice, setSelectedPrice] = useState(null);
    const [priceItems, setPriceItems] = useState(null);
    const roadMap = [
        { name: 'Cotizaciones', url: '/generated-quotes', current: false },
        { name: 'Detalle de cotización', url: '/view-quote', current: true },
    ];

    let headers = [
        'Folio de Cotización',
        'Timestamp',
        'Tipo Registro',
        'Tipo de Contribuyente',
        'Nombre',
        'Apellido',
        'Email',
        'Teléfono',
        'RFC',
        'Razón social',
        'Edad',
        'Fecha de Nacimiento',
        'Ciudad',
        'Ocupación',
        'CIEC',
        'FIEL',
        'Regimen Fiscal',
        'Carpeta de Trabajo',
        'Vendedor',
        '¿Requiere regularización fiscal?',
        'Descripción de la regularización',
        'Periodos',
        'Número de obligaciones',
        'Precio Unitario',
        'Costo final por el total de la regularización (calcular)',
        'Descuento Servicio',
        'Precio Con Desc Servicio',
        'Descuento Paquete',
        'Precio Con Desc Paquete',
        'RGLZ*Ejecutar',
        '¿Requieres Servicio Contable?',
        ' Descripción del servicio',
        'Periodos',
        'Numero de meses',
        'Precio Unitario',
        'Costo final por el total del Servicio Contable',
        'Descuento Servicio',
        'Precio Con Desc Servicio',
        'Descuento Paquete',
        'Precio Con Desc Paquete',
        '¿Requieres Servicio Especial?',
        'Descripción del servicio',
        'Periodos',
        'Numero de meses',
        'Precio Unitario',
        'Costo final por el total del servicio (calcular)',
        'Descuento Servicio',
        'Precio Con Desc Servicio',
        'Descuento Paquete',
        'Precio Con Desc Paquete',
        'Fecha de cotización',
        'Costo inicial de la cotización (Precio sin Descuento)',
        'Precio Servicio',
        'Precio Paquete',
        'Vendedor'
    ]

    const getPeriods = (i) => {
        let periods = [];
        const service = datesDisplay.filter(date => date.index == i);
        if (service.periodicity == 'yearly') {
            periods = service.flatMap(item => item.dates_display).map(d => {
                return d.year
            })
        } else {
            periods = service.flatMap(item => item.dates_display).map(d => {
                return d.tag
            })
        }
        const periodsUnited = periods.join(' - ');
        return periodsUnited
    }

    const getAllDescriptions = arr => {
        let doc = []
        for (let i = 0; i < arr.length; i++) {
            doc.push(`${i + 1}. ${arr[i].name}`);
        }
        const desc = doc.join('\n');
        return desc
    }

    const getAllPeriods = arr => {
        let periods = [];
        for (let i = 0; i < arr.length; i++) {
            periods.push(`${i + 1}. ${getPeriods(arr[i].index)}`)
        }
        const per = periods.join('\n');
        return per
    }

    const operateServices = (regularization, accounting, otherServices) => {
        let data = [];
        for (let i = 0; i < 1; i++) {
            const firstRow = [
                quoteData.folio,
                getTime(),
                'H',
                dataClient.legal_type == 'personal' ? 'Persona física' : 'Persona moral',
                dataClient.first_name,
                dataClient.last_name,
                dataClient.email,
                dataClient.phone_number,
                dataClient.rfc,
                dataInvoicing.businessName,
                getAge(useTransformRfcToDate(dataClient.rfc)),
                formatDate(useTransformRfcToDate(dataClient.rfc), 'DD/MM/YY'),
                dataClient.state,
                dataClient.occupancy,
                '',
                '',
                '',
                '',
                quoteData.seller.name,
                regularization.length > 0 ? 'Sí' : 'No',
                regularization.length > 0 ? getAllDescriptions(regularization) : '',
                regularization.length > 0 ? getAllPeriods(regularization) : '',
                regularization.length > 0 ? regularization.map(t => t.quantity).reduce((prev, curr) => prev + curr, 0) : '',
                '',
                regularization.length > 0 ? regularization.map(t => t.subtotal).reduce((prev, curr) => prev + curr, 0) : '',
                regularization.length > 0 ? regularization.map(t => t.discount_amount).reduce((prev, curr) => prev + curr, 0) : '',
                regularization.length > 0 ? regularization.map(t => t.total).reduce((prev, curr) => prev + curr, 0) : '',
                regularization.length == 0 ? '' : regularization.map(x => x.pack_discount_amount).every(y => y > 0) ? regularization.map(t => t.pack_discount_amount).reduce((prev, curr) => prev + curr, 0) : '',
                regularization.length == 0 ? '' : regularization.map(x => x.pack_discount_amount).every(y => y > 0) ? regularization.map(t => t.pack_total).reduce((prev, curr) => prev + curr, 0) : '',
                '',
                accounting.length > 0 ? 'Sí' : 'No',
                accounting.length > 0 ? getAllDescriptions(accounting) : '',
                accounting.length > 0 ? getAllPeriods(accounting) : '',
                accounting.length > 0 ? accounting.map(t => t.quantity).reduce((prev, curr) => prev + curr, 0) : '',
                '',
                accounting.length > 0 ? accounting.map(t => t.subtotal).reduce((prev, curr) => prev + curr, 0) : '',
                accounting.length > 0 ? accounting.map(t => t.discount_amount).reduce((prev, curr) => prev + curr, 0) : '',
                accounting.length > 0 ? accounting.map(t => t.total).reduce((prev, curr) => prev + curr, 0) : '',
                accounting.length == 0 ? '' : accounting.map(x => x.pack_discount_amount).every(y => y > 0) ? accounting.map(t => t.pack_discount_amount).reduce((prev, curr) => prev + curr, 0) : '',
                accounting.length == 0 ? '' : accounting.map(x => x.pack_discount_amount).every(y => y > 0) ? accounting.map(t => t.pack_total).reduce((prev, curr) => prev + curr, 0) : '',
                otherServices.length > 0 ? 'Sí' : 'No',
                otherServices.length > 0 ? getAllDescriptions(otherServices) : '',
                otherServices.length > 0 ? getAllPeriods(otherServices) : '',
                otherServices.length > 0 ? otherServices.map(t => t.quantity).reduce((prev, curr) => prev + curr, 0) : '',
                '',
                otherServices.length > 0 ? otherServices.map(t => t.subtotal).reduce((prev, curr) => prev + curr, 0) : '',
                otherServices.length > 0 ? otherServices.map(t => t.discount_amount).reduce((prev, curr) => prev + curr, 0) : '',
                otherServices.length > 0 ? otherServices.map(t => t.total).reduce((prev, curr) => prev + curr, 0) : '',
                otherServices.length == 0 ? '' : otherServices.map(x => x.pack_discount_amount).every(y => y > 0) ? otherServices.map(t => t.pack_discount_amount).reduce((prev, curr) => prev + curr, 0) : '',
                otherServices.length == 0 ? '' : otherServices.map(x => x.pack_discount_amount).every(y => y > 0) ? otherServices.map(t => t.pack_total).reduce((prev, curr) => prev + curr, 0) : '',
                formatDate(convertUnixToDate(quoteData.created), 'MM/DD/YYYY'),
                resumeAmounts.subtotalAmount,
                resumeAmounts.totalAmount,
                resumeAmounts.totalPack == 0 ? '' : resumeAmounts.totalPack,
                quoteData.seller.name,
            ];
            data.push(firstRow);
        }
        const maxN = Math.max(regularization.length, accounting.length, otherServices.length);
        for (let i = 0; i < maxN; i++) {
            const row = [
                quoteData.folio,
                getTime(),
                'S',
                dataClient.legal_type == 'personal' ? 'Persona física' : 'Persona moral',
                dataClient.first_name,
                dataClient.last_name,
                dataClient.email,
                dataClient.phone_number,
                dataClient.rfc,
                dataInvoicing.businessName,
                getAge(useTransformRfcToDate(dataClient.rfc)),
                formatDate(useTransformRfcToDate(dataClient.rfc), 'DD/MM/YY'),
                dataClient.state,
                dataClient.occupancy,
                '',
                '',
                '',
                '',
                quoteData.seller.name,
                regularization[i] == undefined ? 'No' : 'Sí',
                regularization[i] == undefined ? '' : regularization[i].name,
                regularization[i] == undefined ? '' : getPeriods(regularization[i].index),
                regularization[i] == undefined ? '' : regularization[i].quantity,
                regularization[i] == undefined ? '' : regularization[i].amount,
                regularization[i] == undefined ? '' : regularization[i].amount * regularization[i].quantity,
                regularization[i] == undefined ? '' : regularization[i].discount_amount,
                regularization[i] == undefined ? '' : regularization[i].total,
                regularization[i] == undefined ? '' : regularization[i].pack_discount_amount > 0 ? regularization[i].pack_discount_amount : '',
                regularization[i] == undefined ? '' : regularization[i].pack_discount_amount > 0 ? regularization[i].pack_total : '',
                '',
                accounting[i] == undefined ? 'No' : 'Sí',
                accounting[i] == undefined ? '' : accounting[i].name,
                accounting[i] == undefined ? '' : getPeriods(accounting[i].index),
                accounting[i] == undefined ? '' : accounting[i].quantity,
                accounting[i] == undefined ? '' : accounting[i].amount,
                accounting[i] == undefined ? '' : accounting[i].amount * accounting[i].quantity,
                accounting[i] == undefined ? '' : accounting[i].discount_amount,
                accounting[i] == undefined ? '' : accounting[i].total,
                accounting[i] == undefined ? '' : accounting[i].pack_discount_amount > 0 ? accounting[i].pack_discount_amount : '',
                accounting[i] == undefined ? '' : accounting[i].pack_discount_amount > 0 ? accounting[i].pack_total : '',
                otherServices[i] == undefined ? 'No' : 'Sí',
                otherServices[i] == undefined ? '' : otherServices[i].name,
                otherServices[i] == undefined ? '' : getPeriods(otherServices[i].index),
                otherServices[i] == undefined ? '' : otherServices[i].quantity,
                otherServices[i] == undefined ? '' : otherServices[i].amount,
                otherServices[i] == undefined ? '' : otherServices[i].amount * otherServices[i].quantity,
                otherServices[i] == undefined ? '' : otherServices[i].discount_amount,
                otherServices[i] == undefined ? '' : otherServices[i].total,
                otherServices[i] == undefined ? '' : otherServices[i].pack_discount_amount > 0 ? otherServices[i].pack_discount_amount : '',
                otherServices[i] == undefined ? '' : otherServices[i].pack_discount_amount > 0 ? otherServices[i].pack_total : '',
                formatDate(convertUnixToDate(quoteData.created), 'MM/DD/YYYY'),
                resumeAmounts.subtotalAmount,
                resumeAmounts.totalAmount,
                resumeAmounts.totalPack == 0 ? '' : resumeAmounts.totalPack,
                quoteData.seller.name,
            ]
            data.push(row);
        }
        setDataCsv([...data]);
    }

    const separateServices = () => {
        let regularization = [];
        let accounting = [];
        let otherServices = [];
        services.map(item => {
            const periodicity = item.periodicity;
            const category = item.category;
            if (category == 'regularization') {
                regularization.push(item);
            } else if (category == 'accounting' && (periodicity == 'monthly' || periodicity == 'bimonthly')) {
                accounting.push(item);
            } else {
                otherServices.push(item);
            }
        });
        operateServices(regularization, accounting, otherServices);
    }

    const getInfoServices = () => {
        const servicesItems = quoteData.service_items.data;
        const newServices = servicesItems.map((service, i) => {
            const { id, name, notes, quantity, periods, recurrent, price, category, periodicity } = service;
            const obj = {
                id: id,
                sku: i,
                index: i,
                name: name,
                amount: price.unit_price / 100,
                discount: price.discount_type == 'percent' ? price.discount : price.discount/100,
                discount_amount: price.discount_amount / 100,
                discount_option: price.discount_type,
                pack_discount_amount: price.special_price == undefined ? 0 : price.special_price.discount_amount / 100,
                pack_discount: price.special_price == undefined ? 0 : (price.special_price.discount_type == 'percent' ? price.special_price.discount : price.special_price.discount/100),
                pack_discount_option: price.special_price == undefined ? 'percent' : price.special_price.discount_type,
                pack_subtotal: price.special_price == undefined ? price.total_amount / 100 : price.special_price.subtotal_amount / 100,
                pack_total: price.special_price == undefined ? price.subtotal_amount / 100 : price.special_price.total_amount / 100,
                dates: useConstructDates(periods),
                total: price.total_amount / 100,
                notes: notes,
                subtotal: price.subtotal_amount / 100,
                quantity: quantity,
                resume_open: false,
                category: category,
                recurrent: recurrent,
                periodicity: periodicity,
                modal_delete_service: false,
                serie: false
            }
            return obj
        });
        setServices([...services, ...newServices]);
        return [...services, ...newServices]
    }

    const getContractServices = (special_price) => {
        const servicesItems = quoteData.service_items.data;
        const newServices = servicesItems.map((service, i) => {
            const { id, quote_service_id, name, notes, quantity, periods, recurrent, price, category, periodicity } = service;
            const obj = {
                id: id,
                quote_service_id: quote_service_id,
                sku: i,
                index: i,
                name: name,
                amount: price.unit_price / 100,
                discount: special_price ? (price.special_price == undefined ? 0 : price.special_price.discount) : price.discount,
                discount_amount: special_price ? (price.special_price == undefined ? 0 : price.special_price.discount_amount / 100) : price.discount_amount / 100,
                discount_option: special_price ? (price.special_price == undefined ? 'percent' : price.special_price.discount_type) : price.discount_type,
                dates: useConstructDates(periods),
                total: special_price ? (price.special_price == undefined ? price.subtotal_amount / 100 : price.special_price.total_amount / 100) : price.total_amount / 100,
                notes: notes,
                subtotal: special_price ? (price.special_price == undefined ? price.total_amount / 100 : price.special_price.subtotal_amount / 100) : price.subtotal_amount / 100,
                quantity: quantity,
                category: category,
                recurrent: recurrent,
                periodicity: periodicity,
                price_type: price?.special_price !== undefined ? 'special_price' : 'base_price'
            }
            return obj
        });
        return newServices
    }

    const getDatesDisplay = () => {
        const servicesItems = quoteData.service_items.data;
        const newDates = servicesItems.map((service, i) => {
            const { id, name, periods } = service;
            const obj = {
                id: id,
                sku: i,
                index: i,
                name: name,
                dates_display: useConstructDatesDisplay(periods),
            }
            return obj
        });
        setDatesDisplay([...datesDisplay, ...newDates]);
    }

    useEffect(() => {
        setAlert({ active: false, type: 'error', message: '' })
        getInfoServices();
        getDatesDisplay();
        return () => {
            setServices([]);
            setDatesDisplay([]);
        }
    }, []);

    useEffect(() => {
        separateServices();
    }, [datesDisplay])


    const handleOpenResumeServices = (index, status) => {
        const modifiedOpen = services.map(service => {
            if (service.index == index) {
                return {
                    ...service,
                    resume_open: status == false ? true : false
                }
            }
            return service
        });
        setServices(modifiedOpen);
    }

    const getDataToSend = () => {
        const data = {
            'id': quoteData.id,
            'account_info': accountData,
            'service_items': {
                'resume_amounts': resumeAmounts,
                'services': services,
                'dates_display': datesDisplay
            },
            ...(quoteData.payment_installments && { 'payment_installments': quoteData.payment_installments }),
            ...(quoteData.payment_partials && { 'payment_partials': quoteData.payment_partials })
        }
        localStorage.setItem('account', JSON.stringify(data));
    }

    const handleEditQuote = () => {
        setModalEditQuote(!modalEditQuote);
        getDataToSend();
        history.push('/new-quote');
    }

    const handleGenerateContract = () => {
        if (quoteData?.price?.special_price) {
            let prices = [
                { id: 1, title: 'Precios por paquete', description: formatterCurrency(services.map( service => service.pack_total ).reduce((prev, curr) => prev + curr, 0)) },
                { id: 2, title: 'Precios por servicios', description: formatterCurrency(quoteData.price.total_amount / 100) }
            ]
            setPriceItems(prices);
            setSelectedPrice(prices[0]);
            setShowSelectPrice(true);
        } else {
            navigateToCreateContract(false);
        }
    }

    const navigateToCreateContract = (specialPrice) => {
        setScrollPosition(0);
        history.push({
            pathname: `contracts/create`,
            state: {
                account: accountData,
                quoteId: quoteData.id,
                serviceItems: {
                    resumeAmounts: resumeAmounts,
                    services: getContractServices(specialPrice),
                    datesDisplay: datesDisplay
                },
                priceType: specialPrice ? 'special_price' : 'base_price'
            }
        })
    }

    const handleDownloadQuote = async () => {
        try {
            if (quoteData.document.key !== '') {
                setLoader(true);
                const getFile = await getQuoteS3({ 'id': quoteData.document.key, 'bucket': quoteData.document.bucket });
                if (getFile.size > 0) {
                    const name = quoteData.document.key.split('/').slice(-1)[0];
                    saveAs(getFile, name);
                    setLoader(false);
                } else {
                    let cont = 0;
                    setFinished(false);
                    const interval = setInterval(async () => {
                        const loopGetFile = await getQuoteS3({ 'id': quoteData.document.key, 'bucket': quoteData.document.bucket });
                        if (loopGetFile.size > 0 && cycle.current == '') {
                            const name = quoteData.document.key.split('/').slice(-1)[0];
                            saveAs(loopGetFile, name);
                            cycle.current = 'finish';
                            setFinished(true);
                            clearInterval(interval);
                            setLoader(false);
                        } else {
                            cont++;
                            if (cont == 30) {
                                setFinished(true)
                                clearInterval(interval)
                                setLoader(false);
                                setAlert({ active: true, type: 'error', message: 'Hubo un problema al obtener el archivo, vuelve a intentarlo' })
                            }
                            setFinished(false);
                        }
                    }, 1000);
                    finished && clearInterval(interval);
                    setFinished(false);
                }
            }
        } catch (e) {
            setAlert({ active: true, type: 'error', message: 'Error al descargar cotización' })
        }
    }

    return (
        <>
            <div className="w-full">
                <Nav user={user}>
                    <div className="w-full">
                        <Link to='/generated-quotes' onClick={() => history.push('/generated-quotes')}>
                            <span className="text-xl text-white font-bold flex items-center cursor-pointer w-full">
                                <span className="text-white opacity-50 text-4xl mr-2"><FontAwesomeIcon icon={faArrowLeft} /></span>
                                <span className="pr-1">
                                    Resumen
                                </span>
                            </span>
                        </Link>
                    </div>
                </Nav>
            </div>
            {alert.active &&
                <div className="xs:w-[102%] md:w-[94%] md:flex justify-center flex-col items-center">
                    <div className="w-full flex justify-center">
                        <AlertError type={alert.type} message={alert.message} setAlert={setAlert} />
                    </div>
                </div>
            }
            <div className="w-full mt-20 lg:mt-4 px-5">
                <BreadCrumb roadMap={roadMap} />
            </div>
            <div className='xs:hidden md:flex w-full px-5 items-center mt-5 mb-4'>
                <div className='w-1/2 text-3xl text-v2-input-text font-bold block leading-3 hidden lg:block'>
                    <div className="w-full">
                        <Link to='/generated-quotes' onClick={() => history.push('/generated-quotes')}>
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </Link>
                        <span className="pl-4">
                            Resumen
                        </span>
                    </div>
                </div>
                <div className="w-full lg:w-1/2 flex justify-center lg:justify-end items-center text-v2-yellow-edit-info text-base">
                    <div className="text-2.5xl leading-3 pr-2 cursor-pointer" onClick={() => setModalEditQuote(!modalEditQuote)}>
                        <FontAwesomeIcon icon={faPenToSquare} />
                    </div>
                    <div className="underline cursor-pointer" onClick={() => setModalEditQuote(!modalEditQuote)}>
                        Editar cotización
                    </div>
                </div>
            </div>
            <Modal hasTitle={false} modalOpen={modalEditQuote} modalOpenChange={setModalEditQuote} maxWidth="max-w-lg">
                <div className="w-full pt-2 pb-5 px-[9px]">
                    <div className="w-full flex justify-end">
                        <span className="w-full flex justify-end text-v2-gray-light-plus-circle leading-3 text-2xl cursor-pointer" onClick={() => setModalEditQuote(!modalEditQuote)}>
                            <FontAwesomeIcon icon={faCircleXmark} />
                        </span>
                    </div>
                    <div className="w-full mt-4">
                        <span className="flex justify-center text-v2-red-circle-delete-service-modal leading-3 text-7xl">
                            <FontAwesomeIcon icon={faPenToSquare} />
                        </span>
                    </div>
                    <div className="w-full mt-5">
                        <span className="w-full flex justify-center text-v2-gray-title-service-modal text-xl text-center">
                            <span className="w-3/4">
                                ¿Estás seguro que deseas editar esta cotización?
                            </span>
                        </span>
                        <span className="w-full flex justify-center text-v2-text-bar-steps mt-6">
                            <span className="text-center w-2/3">
                                Si la editas, afectará el periodo seleccionado.
                            </span>
                        </span>
                    </div>
                    <div className="w-full flex justify-center mt-12">
                        <span className="text-center text-v2-blue-text-login underline text-base cursor-pointer" onClick={() => setModalEditQuote(!modalEditQuote)}>
                            No, regresar
                        </span>
                    </div>
                    <div className="mt-10 px-4">
                        <Button heigth="h-12" width="w-full" bg="bg-v2-blue-text-login" onClick={handleEditQuote}>
                            Sí, editar
                        </Button>
                    </div>
                </div>
            </Modal>
            <ModalV2 show={showSelectPrice} setShow={setShowSelectPrice} className='w-full sm:max-w-[30rem]'>
                <div className='w-full'>
                    <label className="text-base font-medium text-gray-900">Elige el tipo de precios para el contrato</label>
                    <div className='mt-8'>
                        <RadioGroup items={priceItems} selectedItem={selectedPrice} setSelectedItem={setSelectedPrice} />
                    </div>
                    <div className='mt-8 flex flex-col sm:flex-row gap-4 justify-end'>
                        <button className="text-v2-blue-text-login" onClick={() => setShowSelectPrice(false)}>
                            Cancelar
                        </button>
                        <PrimaryButton onClick={() => navigateToCreateContract(selectedPrice?.id == 1)}>
                            Confirmar selección
                        </PrimaryButton>
                    </div>
                </div>
            </ModalV2>
            <div className="w-full px-4">
                {step == 1 &&
                    <div className="w-full xs:block md:flex gap-4">
                        <div className="xs:flex md:hidden w-full justify-center items-center text-v2-yellow-edit-info text-base pt-8 pb-6">
                            <div className="text-2.5xl leading-3 pr-2 cursor-pointer" onClick={() => setModalEditQuote(!modalEditQuote)}>
                                <FontAwesomeIcon icon={faPenToSquare} />
                            </div>
                            <div className="underline cursor-pointer" onClick={() => setModalEditQuote(!modalEditQuote)}>
                                Editar cotización
                            </div>
                        </div>
                        <div className="xs:w-full md:w-1/2">
                            <QuoteValidity creation={convertUnixToDate(quoteData.created)} date={quoteData.expires_at} folio={quoteData.folio} seller={quoteData.seller.name} />
                            <div className="mt-3">
                                <GeneralDataQuote dataClient={dataClient} edit={false} selectedFiles={selectedFiles} />
                            </div>
                            <div className="xs:mb-0 md:mb-4 mt-4">
                                <InvoicingData dataInvoicing={dataInvoicing} setDataInvoicing={setDataInvoicing} setAlert={setAlert} edit={false} source='edit' />
                            </div>
                        </div>
                        <div className="xs:w-full md:w-1/2">
                            <div className="w-full">
                                <div className="w-full bg-white border border-v2-gray-border-tables rounded-10 xs:mt-4 md:mt-0">
                                    <HeadersDataTables icon={faCartShopping} bgColor='bg-button-orange' textColor='text-white' background={true} text='Precio por servicio' />
                                    <div className="w-full px-4 py-3 flex font-semibold text-base text-v2-input-text bg-v2-gray-packs-services">
                                        <div className="xs:w-7/12 md:w-2/3">
                                            TICKET
                                        </div>
                                        <div className="xs:w-5/12 md:w-1/3">
                                            SUBTOTAL
                                        </div>
                                    </div>
                                    <div className="w-full">
                                        {services.map((service, i) => (
                                            <div key={i} className="w-full">
                                                <div className="w-full xs:px-3 md:px-4 py-2 border-t border-v2-gray-border-tables flex">
                                                    <div className="xs:w-7/12 md:w-2/3">
                                                        {service.name}
                                                    </div>
                                                    <div className="xs:w-5/12 md:w-1/3 flex items-center">
                                                        <div className='w-full flex gap-4 items-center'>
                                                            {service.total - (service.amount * service.quantity) == 0 ?
                                                                <div className="w-28 text-base">
                                                                    <div className="text-v2-input-text opacity-60">
                                                                        {formatterCurrency(service.amount * service.quantity)}
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="w-28 text-base">
                                                                    <div className="text-v2-red-discount-tag leading-6">
                                                                        {formatterCurrency(service.total)} <FontAwesomeIcon icon={faTag} />
                                                                    </div>
                                                                    <div className="text-v2-input-text opacity-60 line-through">
                                                                        {formatterCurrency(service.amount * service.quantity)}
                                                                    </div>
                                                                </div>
                                                            }
                                                            <div className="xs:w-5 md:w-10 flex justify-left">
                                                                <div className="text-v2-gray-caret-resume-services leading-3 text-4xl">
                                                                    <FontAwesomeIcon icon={faCaretDown} className={`${service.resume_open ? 'rotate-180' : ''} cursor-pointer`} onClick={() => handleOpenResumeServices(service.index, service.resume_open)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {service.resume_open &&
                                                    <div className="w-full px-4 py-5 border-t border-v2-gray-border-tables">
                                                        {service.periodicity == 'yearly' ?
                                                            <div className="w-full flex text-sm">
                                                                <div className="w-1/2 text-v2-gray-title-client">
                                                                    Periodo
                                                                </div>
                                                                <div className="w-1/2 flex justify-end text-v2-orange-text-dates-quantity">
                                                                    Cantidad: {service.quantity} periodos
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="w-full flex text-sm">
                                                                <div className="w-1/2 text-v2-gray-title-client">
                                                                    Periodo
                                                                </div>
                                                                <div className="w-1/2 flex justify-end text-v2-orange-text-dates-quantity">
                                                                    Cantidad: {service.quantity} periodos
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className="w-full my-2">
                                                            {service.periodicity == 'yearly' ?
                                                                <div>
                                                                    {datesDisplay.filter(date => date.index == service.index).map((service, i) => (
                                                                        <div key={i} className="w-full flex gap-2 flex-wrap max-w-[100%]">
                                                                            {service.dates_display.map((date, i) => (
                                                                                <div key={i} className="w-auto bg-accent-100 text-accent-base py-2 text-xs rounded flex items-center px-2">
                                                                                    <div className="w-full text-center min-w-[40px]">
                                                                                        {date.year}
                                                                                    </div>
                                                                                </div>
                                                                            ))
                                                                            }
                                                                        </div>
                                                                    ))
                                                                    }
                                                                </div>
                                                                :
                                                                <div>
                                                                    {datesDisplay.filter(date => date.index == service.index).map((service, i) => (
                                                                        <div key={i} className="w-full flex gap-2 flex-wrap max-w-[100%]">
                                                                            {service.dates_display.map((date, i) => (
                                                                                <div key={i} className="w-auto bg-accent-100 text-accent-base py-2 text-xs rounded flex items-center px-2">
                                                                                    <div className="w-full text-center min-w-[40px]">
                                                                                        {date.tag}
                                                                                    </div>
                                                                                </div>
                                                                            ))
                                                                            }
                                                                        </div>
                                                                    ))
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className="w-full text-v2-gray-title-client text-sm mt-4">
                                                            Notas
                                                        </div>
                                                        <div className="w-full text-[15px] mt-2">
                                                            {service.notes == '' ?
                                                                <span className='opacity-50'>
                                                                    Ninguna nota añadida.
                                                                </span>
                                                                :
                                                                <span>
                                                                    {service.notes}
                                                                </span>
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        ))}
                                    </div>
                                    <ResumeAmounts subtotal={resumeAmounts.subtotalAmount} discount={resumeAmounts.discountAmount} totalAmount={resumeAmounts.totalAmount} amountServices={resumeAmounts.numberServices} inside={true} />
                                </div>
                            </div>
                            {services.length > 1 && resumeAmounts.totalPackDiscount > 0 ?
                                <div className='w-full my-3 border border-v2-gray-border-tables rounded-10 bg-white'>
                                    <div className='w-full xs:px-2 md:px-4 py-2 border-b border-v2-gray-border-tables flex items-center'>
                                        <div className="xs:w-11 md:w-10">
                                            <div className='rounded-full w-8 h-8 bg-v2-red-discount-tag text-1.5xl text-white flex justify-center items-center'>
                                                <FontAwesomeIcon icon={faTag} className='leading-3 pl-0.5 pt-[1px]' />
                                            </div>
                                        </div>
                                        <div className='xs:w-8/12 md:w-7/12 text-xl font-semibold text-v2-input-text'>
                                            Precio paquete
                                        </div>
                                    </div>
                                    <div className='w-full'>
                                        <div className='w-full bg-v2-gray-packs-services px-4 py-3 flex items-center text-v2-input-text text-xl font-semibold'>
                                            <div className='xs:w-7/12 md:w-2/3'>
                                                Ticket
                                            </div>
                                            <div className='xs:w-5/12 md:w-1/3'>
                                                Total
                                            </div>
                                        </div>
                                        {services.map((service, i) => (
                                            <div key={i} className="w-full">
                                                <div className="w-full xs:px-3 md:px-4 py-2 border-t border-v2-gray-border-tables flex items-center">
                                                    <div className="xs:w-7/12 md:w-2/3 text-v2-text-bar-steps font-semibold">
                                                        {service.name}
                                                    </div>
                                                    <div className="xs:w-5/12 md:w-1/3 flex items-center">
                                                        {service.pack_total - (service.amount * service.quantity) == 0 ?
                                                            <div className="xs:w-5/6 md:w-2/3 text-base">
                                                                <div className="text-v2-input-text opacity-60">
                                                                    {formatterCurrency(service.amount * service.quantity)}
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="xs:w-5/6 md:w-2/3 text-base">
                                                                <div className="text-v2-red-discount-tag leading-6">
                                                                    {formatterCurrency(service.pack_total)} <FontAwesomeIcon icon={faTag} />
                                                                </div>
                                                                <div className="text-v2-input-text opacity-60 line-through">
                                                                    {formatterCurrency(service.amount * service.quantity)}
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        <ResumeAmounts subtotal={resumeAmounts.subtotalAmount} discount={resumeAmounts.totalPackDiscount} totalAmount={services.map( service => service.pack_total ).reduce((prev, curr) => prev + curr, 0)/*resumeAmounts.totalPack*/} amountServices={resumeAmounts.numberServices} inside={true} bg='bg-v2-red-discount-tag' />
                                    </div>
                                </div>
                                :
                                <></>
                            }
                            <div className="w-full flex items-center gap-2 md:gap-4 mt-8 md:mt-4">
                                <div className='w-auto min-w-[90px] md:w-1/3 rounded-md bg-white'>
                                    <PrimaryButton isTransparent isFullWidth>
                                        <CSVLink data={dataCsv} headers={headers} filename={`${quoteData.folio}.csv`}>
                                            <FontAwesomeIcon icon={faFileExport} className='leading-3' />
                                            <span className='pl-1'>csv</span>
                                        </CSVLink>
                                    </PrimaryButton>
                                </div>
                                <div className="w-full md:w-2/3 rounded-md bg-white">
                                    <PrimaryButton isTransparent isFullWidth disabled={loader ? true : false} onClick={handleDownloadQuote}>
                                        {loader ?
                                            <div className="w-6 h-6 rounded-full flex justify-center text-white">
                                                <svg role="status" className="inline w-7 h-7 animate-spin fill-v2-blue-text-login" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                                </svg>
                                            </div>
                                            :
                                            <span><FontAwesomeIcon icon={faDownload} className="pr-1" /> Descargar cotización</span>
                                        }
                                    </PrimaryButton>
                                </div>
                            </div>
                            <PermissionsGate allowedRoles={[ROLES.root, ROLES.help_desk, ROLES.administrators, ROLES.sales, ROLES.collections, ROLES.operationsAtc]}>
                                <div className="w-full flex justify-end pb-12">
                                    <div className="w-full my-4">
                                        <PrimaryButton disabled={getStatusExpired(quoteData?.expires_at) == 'expired'} isFullWidth onClick={handleGenerateContract}>
                                            <FontAwesomeIcon icon={faFileInvoiceDollar} className="pr-2" /> Generar contrato
                                        </PrimaryButton>
                                    </div>
                                </div>
                            </PermissionsGate>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

export default ViewQuote