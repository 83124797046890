import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { ChevronRightIcon, EllipsisVerticalIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { EnvelopeIcon } from '@heroicons/react/24/outline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import SlideOver from '../../../tailwindUI/SlideOver';
import Badge from '../../../tailwindUI/Badge';
import DocumentList from '../../../tailwindUI/DocumentList';
import MenuButton from '../../../tailwindUI/MenuButton';
import Modal from '../../../tailwindUI/Modal';
import Input from '../../../tailwindUI/Input';
import AccentButton from '../../../tailwindUI/AccentButton';
import SecondaryButton from '../../../tailwindUI/SecondaryButton';
import useSleep from '../../../hooks/useSleep';
import SecondaryLoader from '../../../hooks/SecondaryLoader';
import { AlertContext } from '../../../context/AlertContext';
import { emailRegex, phoneRegex } from '../../../hooks/useRegex';
import { getConnections } from '../../../apiClient/operations/connectionsOperations';
import { getTaxStatusList, getTaxComplianceList, updateTaxStatus, updateTaxCompliance, updateTaxStatusFrequency, updateTaxComplianceFrequency } from '../../../apiClient/operations/fiscalOperations';
import useFormatDate from '../../../hooks/useFormatDate';
import SkeletonLoader from '../../../tailwindUI/SkeletonLoader';
import EmptyState from '../../../tailwindUI/EmptyState';
import CollapsibleList from '../../../tailwindUI/CollapsibleList';
import SelectCustom from '../../../tailwindUI/SelectCustom';
import { AuthContext } from '../../../cognito/AuthProvider';
import { ROLES } from '../../../cognito/permissions/permission-maps';
import { Transition } from '@headlessui/react';
import PermissionsGate from '../../../cognito/permissions/PermissionsGate';

const outcomes = {
    'POSITIVE': 'Positivo',
    'NEGATIVE': 'Negativo',
    'NO_OBLIGATIONS': 'Sin obligaciones',
}

const outcomesType = {
    'POSITIVE': 'Success',
    'NEGATIVE': 'Error',
    'NO_OBLIGATIONS': 'Default',
}

const statusPadron = {
    'ACTIVO': 'Activo',
    'INACTIVO': 'Inactivo',
    'REACTIVADO': 'Reactivado'
}

const statusPadronType = {
    'ACTIVO': 'Success',
    'INACTIVO': 'Error',
    'REACTIVADO': 'Success'
}

const allowedRolesToUpdate = [ROLES.root, ROLES.help_desk, ROLES.administrators, ROLES.operations, ROLES.operationsAtc, ROLES.collections];

const defaultRefreshFrequencyList = [
    { id: 1, title: 'Diaramente', interval: 'days', description: 'diario' },
    { id: 2, title: 'Semanalmente', interval: 'weekly', description: 'cada semana' },
    { id: 3, title: 'Mensualmente', interval: 'monthly', description: 'cada mes' },
    { id: 4, title: 'Personalizado' }
]

const customRefreshFrequencyList = [
    { id: 1, title: 'Diaramente', interval: 'days', singularLabel: 'día', pluralLabel: 'días' },
    { id: 2, title: 'Semanalmente', interval: 'weekly', singularLabel: 'semana', pluralLabel: 'semanas' },
    { id: 3, title: 'Mensualmente', interval: 'monthly', singularLabel: 'mes', pluralLabel: 'meses' }
]

function TaxSituation({ account }) {

    const { sleep } = useSleep();
    const { setAlert } = useContext(AlertContext);
    const { formatDate } = useFormatDate();
    const { user } = useContext(AuthContext);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false);
    const [shareMethod, setShareMethod] = useState('email');
    const [isSendingDocument, setIsSendingDocument] = useState(false);
    const [errors, setErrors] = useState(null);
    const [documentToRefreshFrequency, setDocumentToRefreshFrequency] = useState(null);
    const [selectedRefreshFrequency, setSelectedRefreshFrequency] = useState(defaultRefreshFrequencyList[2].title);
    const [selectedCustomRefreshFrequency, setSelectedCustomRefreshFrequency] = useState(customRefreshFrequencyList[0].title);
    const [customRefreshFrequency, setCustomRefreshFrequency] = useState(1);
    const [connections, setConnections] = useState(null);
    //Tax compliance
    const [taxComplianceList, setTaxComplianceList] = useState(null);
    const [lastTaxCompliance, setLastTaxCompliance] = useState(null);
    const [taxComplianceRefreshFrequency, setTaxComplianceRefreshFrequency] = useState(null);
    const [taxComplianceDetails, setTaxComplianceDetails] = useState(null);
    const [showTaxCompliances, setShowTaxCompliances] = useState(false);
    const [showTaxComplianceDetails, setShowTaxComplianceDetails] = useState(false);
    const [updatingTaxCompliance, setUpdatingTaxCompliance] = useState(false);
    const [showUpdatingTaxComplianceModal, setShowUpdatingTaxComplianceModal] = useState(false);
    const [taxComplianceActions, setTaxComplianceActions] = useState(null);
    //Tax status
    const [taxStatusList, setTaxStatusList] = useState(null);
    const [lastTaxStatus, setLastTaxStatus] = useState(null);
    const [taxStatusRefreshFrequency, setTaxStatusRefreshFrequency] = useState(null);
    const [taxStatusDetails, setTaxStatusDetails] = useState(null);
    const [showTaxStatuses, setShowTaxStatuses] = useState(false);
    const [showTaxStatusDetails, setShowTaxStatusDetails] = useState(false);
    const [updatingTaxStatus, setUpdatingTaxStatus] = useState(false);
    const [showUpdatingTaxStatusModal, setShowUpdatingTaxStatusModal] = useState(false);
    const [taxStatusActions, setTaxStatusActions] = useState(null);

    useEffect(async () => {
        console.log(account)
        if (!account || !account.connections || account?.connections?.length == 0) return;

        setIsLoadingData(true);

        const connectionsResponse = await getConnections(account.id);
        const connections = connectionsResponse.data;
        setConnections(connections);
        if (!(connections?.some(connection => connection.status == 'active'))) {
            setIsLoadingData(false);
            return;
        }

        try {
            const taxComplianceList = await getTaxComplianceList(account.id);
            setTaxComplianceList(taxComplianceList?.data?.fis_account_tax_compliance);
            setLastTaxCompliance(taxComplianceList?.data?.fis_account_tax_compliance[0]);
            setTaxComplianceRefreshFrequency(taxComplianceList?.data?.tax_compliance_frequency);
        } catch (e) {
            setIsLoading(false);
            setAlert({ active: true, type: 'error', message: e.message });
        }

        try {
            const taxStatusList = await getTaxStatusList(account.id);
            setTaxStatusList(taxStatusList?.data?.fis_account_tax_status);
            setLastTaxStatus(taxStatusList?.data?.fis_account_tax_status[0]);
            setTaxStatusRefreshFrequency(taxStatusList?.data?.tax_status_frequency);
        } catch (e) {
            setIsLoadingData(false);
            setAlert({ active: true, type: 'error', message: e.message });
        }
        setIsLoadingData(false);
    }, [])

    useEffect(async () => {
        if (!taxComplianceList) return;
        setTaxComplianceActions([
            {
                id: 1,
                name: 'Ver historial',
                action: () => setShowTaxCompliances(true),
                hidden: taxComplianceList?.length <= 1
            },
            {
                id: 2,
                name: 'Enviar',
                action: () => setShowShareModal(true),
                hidden: true
            },
            {
                id: 3,
                name: 'Actualizar documento',
                action: () => updateTaxComplianceDocument(account?.id, user?.email),
                hidden: !user?.roles?.some(role => allowedRolesToUpdate.includes(role))
            },
            {
                id: 4,
                name: 'Frecuencia de actualización',
                action: () => setDocumentToRefreshFrequency("Opinión de cumplimiento")
            }
        ])
    }, [taxComplianceList, user])

    useEffect(async () => {
        if (!taxStatusList) return;
        setTaxStatusActions([
            {
                id: 1,
                name: 'Ver historial',
                action: () => setShowTaxStatuses(true),
                hidden: taxStatusList?.length <= 1
            },
            {
                id: 2,
                name: 'Enviar',
                action: () => setShowShareModal(true),
                hidden: true
            },
            {
                id: 3,
                name: 'Actualizar documento',
                action: () => updateTaxStatusDocument(account?.id, user?.email),
                hidden: !user?.roles?.some(role => allowedRolesToUpdate.includes(role))
            },
            {
                id: 4,
                name: 'Frecuencia de actualización',
                action: () => setDocumentToRefreshFrequency("Constancia de situación fiscal")
            }
        ])
    }, [taxStatusList, user])

    useEffect(() => {
        if (showShareModal && shareMethod != 'email') setShareMethod('email');
    }, [showShareModal])

    useEffect(() => {
        setErrors(null);
    }, [shareMethod])

    useEffect(() => {
        if (!documentToRefreshFrequency) return;
        let refreshConfig = {
            frequency: 1,
            interval: "monthly"
        };
        if (documentToRefreshFrequency === 'Opinión de cumplimiento' && taxComplianceRefreshFrequency) {
            refreshConfig = taxComplianceRefreshFrequency;
        } else if (documentToRefreshFrequency === 'Constancia de situación fiscal' && taxStatusRefreshFrequency) {
            refreshConfig = taxStatusRefreshFrequency;
        }
        const frequency = refreshConfig.frequency;
        const interval = refreshConfig.interval;
        let refreshFrequency = defaultRefreshFrequencyList.find(item => item.interval == interval).title;
        let customRefreshFrequency = defaultRefreshFrequencyList.find(item => item.interval == interval).title;
        if (frequency > 1) {
            refreshFrequency = defaultRefreshFrequencyList.find(item => item.id == 4).title;
        }
        setSelectedRefreshFrequency(refreshFrequency);
        setSelectedCustomRefreshFrequency(customRefreshFrequency);
        setCustomRefreshFrequency(frequency);
    }, [documentToRefreshFrequency])

    const sendDocument = async e => {
        e.preventDefault();
        if (isSendingDocument) return;
        const email = e.target.elements.email?.value;
        const phone = e.target.elements.phone?.value;
        let err = {};

        if (shareMethod == 'email' && !emailRegex(email)) {
            err.email = "Ingresa correctamente el email";
        } else if (shareMethod == 'whatsapp' && !phoneRegex(phone)) {
            err.phone = "Ingresa correctamente el número";
        }

        if (Object.keys(err).length > 0) {
            setErrors(err);
            return;
        }

        setIsSendingDocument(true);
        await sleep(2000);
        setIsSendingDocument(false);
        setShowShareModal(false);
        setAlert({ active: true, type: 'success', message: 'Documento enviado con éxito.' })
    }

    const updateTaxComplianceDocument = async (accountId, sendTo) => {
        if (account.status != 'active' && account.status != 'pending') {
            setAlert({ active: true, type: 'warning', message: 'No se puede actualizar el documento de un contribuyente no activo o pendiente.' });
            return;
        } else if (lastTaxCompliance) {
            const now = new Date();
            const taxComplianceDate = new Date(lastTaxCompliance?.collected_at);
            const hoursDifference = Math.abs(now?.getTime() - taxComplianceDate?.getTime()) / 36e5;
            if (hoursDifference < 24) {
                setAlert({ active: true, type: 'warning', message: 'El documento ya ha sido actualizado en las últimas 24 horas.' })
                return;
            }
        }
        try {
            setIsLoading(true);
            await updateTaxCompliance(accountId, sendTo);
            setIsLoading(false);
            setUpdatingTaxCompliance(true);
            setShowUpdatingTaxComplianceModal(true);
        } catch (e) {
            setIsLoading(false);
            setAlert({ active: true, type: 'error', message: e.message })
        }
    }

    const updateTaxStatusDocument = async (accountId, sendTo) => {
        if (account.status != 'active' && account.status != 'pending') {
            setAlert({ active: true, type: 'warning', message: 'No se puede actualizar el documento de un contribuyente no activo o pendiente.' })
            return;
        } else if (lastTaxStatus) {
            const now = new Date();
            const taxStatusDate = new Date(lastTaxStatus?.collected_at);
            const hoursDifference = Math.abs(now?.getTime() - taxStatusDate?.getTime()) / 36e5;
            if (hoursDifference < 24) {
                setAlert({ active: true, type: 'warning', message: 'El documento ya ha sido actualizado en las últimas 24 horas.' })
                return;
            }
        }
        try {
            setIsLoading(true);
            await updateTaxStatus(accountId, sendTo);
            setIsLoading(false);
            setUpdatingTaxStatus(true);
            setShowUpdatingTaxStatusModal(true);
        } catch (e) {
            setIsLoading(false);
            setAlert({ active: true, type: 'error', message: e.message })
        }
    }

    const showTaxCompliance = compliance => {
        setShowTaxComplianceDetails(true);
        setTaxComplianceDetails(compliance);
    }

    const showTaxStatus = status => {
        setShowTaxStatusDetails(true);
        setTaxStatusDetails(status);
    }

    const downloadError = e => {
        setAlert({ active: true, type: 'error', message: e.message })
    }

    const updateRefreshFrequency = async e => {
        e.preventDefault();
        if (isNaN(customRefreshFrequency) || customRefreshFrequency == '') {
            setErrors({
                ...errors,
                frequency: 'Ingresa correctamente el valor'
            });
            return;
        }
        setIsLoading(true);
        setErrors({
            ...errors,
            frequency: null
        });
        let frequency = 1;
        let interval = defaultRefreshFrequencyList.find(frequency => frequency.title == selectedRefreshFrequency)?.interval;
        if (selectedRefreshFrequency == 'Personalizado') {
            frequency = parseInt(customRefreshFrequency)
            interval = customRefreshFrequencyList.find(frequency => frequency.title == selectedCustomRefreshFrequency)?.interval;
        }
        const request = {
            frequency,
            interval
        }
        let updated;
        try {
            if (documentToRefreshFrequency === 'Opinión de cumplimiento') {
                updated = await updateTaxComplianceFrequency(account.id, request);
                setTaxComplianceRefreshFrequency(updated?.tax_compliance_frequency);
            } else if (documentToRefreshFrequency === 'Constancia de situación fiscal') {
                updated = await updateTaxStatusFrequency(account.id, request);
                setTaxStatusRefreshFrequency(updated?.tax_status_frequency);
            }
            setAlert({ active: true, type: 'success', message: 'Frecuencia de acualización modificada.' });
            setIsLoading(false);
            setDocumentToRefreshFrequency(null);
        } catch (e) {
            setIsLoading(false);
            setAlert({ active: true, type: 'error', message: e.message });
        }
    }

    return (
        <>
            <div className='space-y-4'>
                {isLoading && <SecondaryLoader />}
                {isLoadingData ? (
                    <>
                        <div className="overflow-hidden bg-white shadow rounded-lg border border-gray-200">
                            <div className="px-4 py-5 sm:px-6 flex gap-4">
                                <h3 className="text-lg font-medium leading-6 text-gray-900">Opinión de cumplimiento</h3>
                            </div>
                            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                    <div className="sm:col-span-1">
                                        <SkeletonLoader />
                                    </div>
                                    <div className="sm:col-span-1">
                                        <SkeletonLoader />
                                    </div>
                                    <div className="sm:col-span-2">
                                        <SkeletonLoader />
                                    </div>
                                </dl>
                            </div>
                        </div>

                        <div className="overflow-hidden bg-white shadow rounded-lg border border-gray-200">
                            <div className="px-4 py-5 sm:px-6 flex gap-4">
                                <h3 className="text-lg font-medium leading-6 text-gray-900">Constancia de situación fiscal</h3>
                            </div>
                            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                    <div className="sm:col-span-1">
                                        <SkeletonLoader />
                                    </div>
                                    <div className="sm:col-span-1">
                                        <SkeletonLoader />
                                    </div>
                                    <div className="sm:col-span-2">
                                        <SkeletonLoader />
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        {(!connections || connections?.length == 0 ) ? (
                            <EmptyState title='Cliente sin accesos' text='El cliente debe tener accesos al SAT registrados y validados para poder visualizar estos documentos.' />
                        ) : !(connections?.some(connection => connection.status == 'active' && connection?.credentials?.type == 'ciec')) ? <EmptyState title='Cliente sin CIEC' text='El cliente debe tener una CIEC registrada y validada para poder visualizar estos documentos.' />
                        : (
                            <>
                                {lastTaxCompliance ? (
                                    <div className="overflow-hidden bg-white shadow rounded-lg border border-gray-200">
                                        <div className="px-4 py-5 sm:px-6 flex flex-row gap-4 justify-between">
                                            <div>
                                                <h3 className="text-lg font-medium leading-6 text-gray-900">Opinión de cumplimiento</h3>
                                                <p className="mt-1 max-w-2xl text-sm text-gray-500">Información del último documento de opinión de cumplimiento</p>
                                            </div>
                                            <MenuButton
                                                items={taxComplianceActions}>
                                                <EllipsisVerticalIcon className="min-w-[1.25rem] w-6 h-6 text-gray-900" />
                                            </MenuButton>
                                        </div>
                                        <div className="border-t border-gray-200 divide-y divide-gray-200">
                                            <dl className="sm:divide-y sm:divide-gray-200 px-4 sm:px-0">
                                                <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5 sm:px-6">
                                                    <dt className="text-sm font-medium text-gray-500">Resultado</dt>
                                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">
                                                        <Badge
                                                            text={outcomes[lastTaxCompliance.outcome] || lastTaxCompliance.outcome}
                                                            type={outcomesType[lastTaxCompliance.outcome]} />
                                                    </dd>
                                                </div>
                                                <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5 sm:px-6">
                                                    <dt className="text-sm font-medium text-gray-500">Folio</dt>
                                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{lastTaxCompliance.internal_identification}</dd>
                                                </div>
                                                <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5 sm:px-6">
                                                    <dt className="text-sm font-medium text-gray-500">Fecha</dt>
                                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{formatDate(lastTaxCompliance.collected_at, 'DD MMM YYYY, HH:mm:ss')}</dd>
                                                </div>
                                                {lastTaxCompliance?.document && (
                                                    <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5 sm:px-6">
                                                        <dt className="text-sm font-medium text-gray-500">PDF</dt>
                                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">
                                                            <DocumentList
                                                                documents={[
                                                                    {
                                                                        bucket: lastTaxCompliance?.document.bucket,
                                                                        key: lastTaxCompliance?.document.path
                                                                    }
                                                                ]}
                                                                onError={downloadError} />
                                                        </dd>
                                                    </div>
                                                )}
                                            </dl>

                                            {lastTaxCompliance?.obligations && (
                                                <CollapsibleList.Item title="Cumplimiento de obligaciones">
                                                    <div className='divide-y divide-gray-200'>
                                                        <dl className="hidden sm:grid sm:grid-cols-12 sm:gap-4 py-3 sm:px-6 bg-gray-50">
                                                            <dt className="sm:col-span-4 text-sm font-medium text-gray-500">Obligación</dt>
                                                            <dt className="sm:col-span-8 text-sm font-medium text-gray-500">Periodos</dt>
                                                        </dl>
                                                        {lastTaxCompliance?.obligations?.map(obligation => (
                                                            <dl key={obligation.obligation} className="sm:grid sm:grid-cols-12 sm:gap-4 sm:py-5 sm:px-6">
                                                                <div className="sm:col-span-4 sm:gap-4 py-3 sm:py-0 px-6 sm:px-0">
                                                                    <dt className="text-sm font-medium text-gray-500 block sm:hidden">Obligación</dt>
                                                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{obligation.obligation}</dd>
                                                                </div>
                                                                <div className="sm:col-span-8 sm:gap-4 py-3 sm:py-0 px-6 sm:px-0">
                                                                    <dt className="text-sm font-medium text-gray-500 block sm:hidden">Periodos</dt>
                                                                    <dd className="mt-1 text-sm text-gray-900 col-span-1 sm:col-span-3 sm:-mt-2 -mx-2 space-y-2">
                                                                        {obligation.periods?.map((period) => (
                                                                            <span key={period.period} className="inline-flex items-center justify-center rounded-md bg-gray-100 px-2.5 py-1.5 text-sm font-medium text-gray-800 mx-2 min-w-[9rem]">
                                                                                {period.period}
                                                                            </span>
                                                                        ))}
                                                                    </dd>
                                                                </div>
                                                            </dl>
                                                        ))}
                                                    </div>
                                                </CollapsibleList.Item>
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="overflow-hidden bg-white shadow rounded-lg border border-gray-200">
                                        <div className="px-4 py-5 sm:px-6 flex flex-row gap-4 justify-between">
                                            <div>
                                                <h3 className="text-lg font-medium leading-6 text-gray-900">Opinión de cumplimiento</h3>
                                            </div>
                                        </div>
                                        {updatingTaxCompliance ? (
                                            <div className="border-t border-gray-200 px-4 py-5 sm:px-6 text-center ">
                                                <div className='text-gray-900 font-medium text-xl text-center'>
                                                    Actualizando documento...
                                                </div>
                                                <div className='text-gray-600 mt-2 md:mx-[30%]'>
                                                    La actualización puede tardar varios minutos, te notificaremos vía correo electrónico cuando esté listo.
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="border-t border-gray-200 px-4 py-5 sm:px-6 text-center ">
                                                <div className='text-gray-900 font-medium text-xl text-center'>
                                                    Sin opinión de cumplimiento
                                                </div>
                                                <div className='text-gray-600 mt-2'>
                                                    Actualiza el documento para obtener la información.
                                                </div>
                                                <PermissionsGate allowedRoles={allowedRolesToUpdate}>
                                                    <div className='mt-4'>
                                                        <AccentButton onClick={() => updateTaxComplianceDocument(account?.id, user?.email)}>
                                                            Actualizar documento
                                                        </AccentButton>
                                                    </div>
                                                </PermissionsGate>
                                            </div>
                                        )}
                                    </div>
                                )}

                                {lastTaxStatus ? (
                                    <>
                                        <CollapsibleList
                                            title="Constancia de situación fiscal"
                                            description="Información de la última constancia de situación fiscal"
                                            actions={taxStatusActions}>
                                            <dl className="sm:divide-y sm:divide-gray-200 px-4 sm:px-0">
                                                <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5 sm:px-6">
                                                    <dt className="text-sm font-medium text-gray-500">Estatus en el padrón</dt>
                                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">
                                                        <Badge
                                                            text={statusPadron[lastTaxStatus?.tax_payer_information?.status_padron] || lastTaxStatus?.tax_payer_information?.status_padron}
                                                            type={statusPadronType[lastTaxStatus?.tax_payer_information?.status_padron] || 'Error'} />
                                                    </dd>
                                                </div>
                                                <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5 sm:px-6">
                                                    <dt className="text-sm font-medium text-gray-500">Lugar y fecha de emisión</dt>
                                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{lastTaxStatus?.place_and_date_of_issuance}</dd>
                                                </div>
                                                {lastTaxStatus?.document && (
                                                    <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5 sm:px-6">
                                                        <dt className="text-sm font-medium text-gray-500">PDF</dt>
                                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">
                                                            <DocumentList
                                                                documents={[
                                                                    {
                                                                        bucket: lastTaxStatus?.document.bucket,
                                                                        key: lastTaxStatus?.document.path
                                                                    }
                                                                ]}
                                                                onError={downloadError} />
                                                        </dd>
                                                    </div>
                                                )}
                                            </dl>

                                            <CollapsibleList.Item title="Datos del contribuyente">
                                                <dl className="sm:divide-y sm:divide-gray-200 px-4 sm:px-0">
                                                    <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5 sm:px-6">
                                                        <dt className="text-sm font-medium text-gray-500">RFC</dt>
                                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{lastTaxStatus?.tax_payer_information?.rfc}</dd>
                                                    </div>
                                                    <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5 sm:px-6">
                                                        <dt className="text-sm font-medium text-gray-500">CURP</dt>
                                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{lastTaxStatus?.tax_payer_information?.curp}</dd>
                                                    </div>
                                                    <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5 sm:px-6">
                                                        <dt className="text-sm font-medium text-gray-500">Nombre(s)</dt>
                                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{lastTaxStatus?.tax_payer_information?.name}</dd>
                                                    </div>
                                                    <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5 sm:px-6">
                                                        <dt className="text-sm font-medium text-gray-500">Primer apellido</dt>
                                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{lastTaxStatus?.tax_payer_information?.first_last_name}</dd>
                                                    </div>
                                                    <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5 sm:px-6">
                                                        <dt className="text-sm font-medium text-gray-500">Segundo apellido</dt>
                                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{lastTaxStatus?.tax_payer_information?.second_last_name}</dd>
                                                    </div>
                                                    <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5 sm:px-6">
                                                        <dt className="text-sm font-medium text-gray-500">Fecha inicio de operaciones</dt>
                                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{formatDate(lastTaxStatus?.tax_payer_information?.start_operations_date, 'DD MMM YYYY, HH:mm:ss')}</dd>
                                                    </div>
                                                    <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5 sm:px-6">
                                                        <dt className="text-sm font-medium text-gray-500">Fecha de último cambio de estado</dt>
                                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{formatDate(lastTaxStatus?.tax_payer_information?.last_status_change_date, 'DD MMM YYYY, HH:mm:ss')}</dd>
                                                    </div>
                                                    <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5 sm:px-6">
                                                        <dt className="text-sm font-medium text-gray-500">Nombre comercial</dt>
                                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{lastTaxStatus?.tax_payer_information?.commercial_name}</dd>
                                                    </div>
                                                </dl>
                                            </CollapsibleList.Item>

                                            <CollapsibleList.Item title="Datos del domicilio registrado">
                                                <dl className="sm:divide-y sm:divide-gray-200 px-4 sm:px-0">
                                                    <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5 sm:px-6">
                                                        <dt className="text-sm font-medium text-gray-500">Código postal</dt>
                                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{lastTaxStatus?.address?.postal_code}</dd>
                                                    </div>
                                                    <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5 sm:px-6">
                                                        <dt className="text-sm font-medium text-gray-500">Tipo de calle</dt>
                                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{lastTaxStatus?.address?.street_type}</dd>
                                                    </div>
                                                    <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5 sm:px-6">
                                                        <dt className="text-sm font-medium text-gray-500">Calle</dt>
                                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{lastTaxStatus?.address?.street}</dd>
                                                    </div>
                                                    <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5 sm:px-6">
                                                        <dt className="text-sm font-medium text-gray-500">Número exterior</dt>
                                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{lastTaxStatus?.address?.exterior_number}</dd>
                                                    </div>
                                                    <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5 sm:px-6">
                                                        <dt className="text-sm font-medium text-gray-500">Número interior</dt>
                                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{lastTaxStatus?.address?.interior_number}</dd>
                                                    </div>
                                                    <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5 sm:px-6">
                                                        <dt className="text-sm font-medium text-gray-500">Colonia</dt>
                                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{lastTaxStatus?.address?.suburb}</dd>
                                                    </div>
                                                    <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5 sm:px-6">
                                                        <dt className="text-sm font-medium text-gray-500">Localidad</dt>
                                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{lastTaxStatus?.address?.locality}</dd>
                                                    </div>
                                                    <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5 sm:px-6">
                                                        <dt className="text-sm font-medium text-gray-500">Municipio</dt>
                                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{lastTaxStatus?.address?.municipality}</dd>
                                                    </div>
                                                    <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5 sm:px-6">
                                                        <dt className="text-sm font-medium text-gray-500">Estado</dt>
                                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{lastTaxStatus?.address?.state}</dd>
                                                    </div>
                                                    <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5 sm:px-6">
                                                        <dt className="text-sm font-medium text-gray-500">Entre calles</dt>
                                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{lastTaxStatus?.address?.between_street?.join(' y ')}</dd>
                                                    </div>
                                                </dl>
                                            </CollapsibleList.Item>

                                            {lastTaxStatus?.economic_activity && (
                                                <CollapsibleList.Item title="Actividades económicas">
                                                    <div className='divide-y divide-gray-200'>
                                                        <dl className="hidden sm:grid sm:grid-cols-12 sm:gap-4 py-3 sm:px-6 bg-gray-50">
                                                            <dt className="sm:col-span-1 text-sm font-medium text-gray-500">Orden</dt>
                                                            <dt className="sm:col-span-5 text-sm font-medium text-gray-500">Actividad económica</dt>
                                                            <dt className="sm:col-span-2 text-sm font-medium text-gray-500">Porcentaje</dt>
                                                            <dt className="sm:col-span-2 text-sm font-medium text-gray-500">Fecha inicio</dt>
                                                            <dt className="sm:col-span-2 text-sm font-medium text-gray-500">Fecha fin</dt>
                                                        </dl>
                                                        {lastTaxStatus?.economic_activity?.map(activity => (
                                                            <dl key={activity.order} className="sm:grid sm:grid-cols-12 sm:gap-4 sm:py-5 sm:px-6">
                                                                <div className="sm:col-span-1 sm:gap-4 py-3 sm:py-0 px-6 sm:px-0">
                                                                    <dt className="text-sm font-medium text-gray-500 block sm:hidden">Orden</dt>
                                                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{activity.order}</dd>
                                                                </div>
                                                                <div className="sm:col-span-5 sm:gap-4 py-3 sm:py-0 px-6 sm:px-0">
                                                                    <dt className="text-sm font-medium text-gray-500 block sm:hidden">Actividad económica</dt>
                                                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{activity.economic_activity}</dd>
                                                                </div>
                                                                <div className="sm:col-span-2 sm:gap-4 py-3 sm:py-0 px-6 sm:px-0">
                                                                    <dt className="text-sm font-medium text-gray-500 block sm:hidden">Porcentaje</dt>
                                                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{activity.percentage}</dd>
                                                                </div>
                                                                <div className="sm:col-span-2 sm:gap-4 py-3 sm:py-0 px-6 sm:px-0">
                                                                    <dt className="text-sm font-medium text-gray-500 block sm:hidden">Fecha inicio</dt>
                                                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{formatDate(activity.initial_date, 'DD/MM/YYYY')}</dd>
                                                                </div>
                                                                <div className="sm:col-span-2 sm:gap-4 py-3 sm:py-0 px-6 sm:px-0">
                                                                    <dt className="text-sm font-medium text-gray-500 block sm:hidden">Fecha fin</dt>
                                                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{activity.end_date && formatDate(activity.end_date, 'DD/MM/YYYY')}</dd>
                                                                </div>
                                                            </dl>
                                                        ))}
                                                    </div>
                                                </CollapsibleList.Item>
                                            )}

                                            {lastTaxStatus?.regimes && (
                                                <CollapsibleList.Item title="Regímenes">
                                                    <div className='divide-y divide-gray-200'>
                                                        <dl className="hidden sm:grid sm:grid-cols-12 sm:gap-4 py-3 sm:px-6 bg-gray-50">
                                                            <dt className="sm:col-span-8 text-sm font-medium text-gray-500">Regimen</dt>
                                                            <dt className="sm:col-span-2 text-sm font-medium text-gray-500">Fecha inicio</dt>
                                                            <dt className="sm:col-span-2 text-sm font-medium text-gray-500">Fecha fin</dt>
                                                        </dl>
                                                        {lastTaxStatus?.regimes?.map(regime => (
                                                            <dl key={regime.regime} className="sm:grid sm:grid-cols-12 sm:gap-4 sm:py-5 sm:px-6">
                                                                <div className="sm:col-span-8 sm:gap-4 py-3 sm:py-0 px-6 sm:px-0">
                                                                    <dt className="text-sm font-medium text-gray-500 block sm:hidden">Regimen</dt>
                                                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{regime.regime}</dd>
                                                                </div>
                                                                <div className="sm:col-span-2 sm:gap-4 py-3 sm:py-0 px-6 sm:px-0">
                                                                    <dt className="text-sm font-medium text-gray-500 block sm:hidden">Fecha inicio</dt>
                                                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{formatDate(regime.initial_date, 'DD/MM/YYYY')}</dd>
                                                                </div>
                                                                <div className="sm:col-span-2 sm:gap-4 py-3 sm:py-0 px-6 sm:px-0">
                                                                    <dt className="text-sm font-medium text-gray-500 block sm:hidden">Fecha fin</dt>
                                                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{regime.end_date && formatDate(regime.end_date, 'DD/MM/YYYY')}</dd>
                                                                </div>
                                                            </dl>
                                                        ))}
                                                    </div>
                                                </CollapsibleList.Item>
                                            )}

                                            {lastTaxStatus?.obligations && (
                                                <CollapsibleList.Item title="Obligaciones">
                                                    <div className='divide-y divide-gray-200'>
                                                        <dl className="hidden sm:grid sm:grid-cols-12 sm:gap-4 py-3 sm:px-6 bg-gray-50">
                                                            <dt className="sm:col-span-4 text-sm font-medium text-gray-500">Obligación</dt>
                                                            <dt className="sm:col-span-4 text-sm font-medium text-gray-500">Descripción vencimiento</dt>
                                                            <dt className="sm:col-span-2 text-sm font-medium text-gray-500">Fecha inicio</dt>
                                                            <dt className="sm:col-span-2 text-sm font-medium text-gray-500">Fecha fin</dt>
                                                        </dl>
                                                        {lastTaxStatus?.obligations?.map(obligation => (
                                                            <dl key={obligation.obligation} className="sm:grid sm:grid-cols-12 sm:gap-4 sm:py-5 sm:px-6">
                                                                <div className="sm:col-span-4 sm:gap-4 py-3 sm:py-0 px-6 sm:px-0">
                                                                    <dt className="text-sm font-medium text-gray-500 block sm:hidden">Obligación</dt>
                                                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{obligation.obligation}</dd>
                                                                </div>
                                                                <div className="sm:col-span-4 sm:gap-4 py-3 sm:py-0 px-6 sm:px-0">
                                                                    <dt className="text-sm font-medium text-gray-500 block sm:hidden">Descripción vencimiento</dt>
                                                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{obligation.expiration}</dd>
                                                                </div>
                                                                <div className="sm:col-span-2 sm:gap-4 py-3 sm:py-0 px-6 sm:px-0">
                                                                    <dt className="text-sm font-medium text-gray-500 block sm:hidden">Fecha inicio</dt>
                                                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{formatDate(obligation.initial_date, 'DD/MM/YYYY')}</dd>
                                                                </div>
                                                                <div className="sm:col-span-2 sm:gap-4 py-3 sm:py-0 px-6 sm:px-0">
                                                                    <dt className="text-sm font-medium text-gray-500 block sm:hidden">Fecha fin</dt>
                                                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{obligation.end_date && formatDate(obligation.end_date, 'DD/MM/YYYY')}</dd>
                                                                </div>
                                                            </dl>
                                                        ))}
                                                    </div>
                                                </CollapsibleList.Item>
                                            )}
                                        </CollapsibleList>
                                    </>
                                ) : (
                                    <div className="overflow-hidden bg-white shadow rounded-lg border border-gray-200">
                                        <div className="px-4 py-5 sm:px-6 flex flex-row gap-4 justify-between">
                                            <div>
                                                <h3 className="text-lg font-medium leading-6 text-gray-900">Constancia de situación fiscal</h3>
                                            </div>
                                        </div>
                                        {updatingTaxStatus ? (
                                            <div className="border-t border-gray-200 px-4 py-5 sm:px-6 text-center ">
                                                <div className='text-gray-900 font-medium text-xl text-center'>
                                                    Actualizando documento...
                                                </div>
                                                <div className='text-gray-600 mt-2 md:mx-[30%]'>
                                                    La actualización puede tardar varios minutos, te notificaremos vía correo electrónico cuando esté listo.
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="border-t border-gray-200 px-4 py-5 sm:px-6 text-center ">
                                                <div className='text-gray-900 font-medium text-xl text-center'>
                                                    Sin constancia de situación fiscal
                                                </div>
                                                <div className='text-gray-600 mt-2'>
                                                    Actualiza el documento para obtener la información.
                                                </div>
                                                <PermissionsGate allowedRoles={allowedRolesToUpdate}>
                                                    <div className='mt-4'>
                                                        <AccentButton onClick={() => updateTaxStatusDocument(account?.id, user?.email)}>
                                                            Actualizar documento
                                                        </AccentButton>
                                                    </div>
                                                </PermissionsGate>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </>
                        )}
                    </>
                )}
            </div>

            {/* SLIDE OVERS AND MODALS */}

            <SlideOver open={showTaxCompliances} setOpen={setShowTaxCompliances} title='Opinión de cumplimiento fiscal'>
                <div className="-mx-4 sm:-mx-6 overflow-hidden bg-white">
                    <p className="mx-4 sm:mx-6 truncate font-medium text-gray-500 mb-4">Historial de documentos</p>
                    <ul role="list" className="border-t border-b border-200 divide-y divide-gray-200 mb-20">
                        {taxComplianceList?.map((compliance) => (
                            <li key={compliance.id}>
                                <a className="block hover:bg-gray-50 cursor-pointer" onClick={() => showTaxCompliance(compliance)}>
                                    <div className="flex items-center px-4 py-4 sm:px-6">
                                        <div className="flex min-w-0 flex-1 items-center">
                                            <div className="min-w-0 flex-1 pr-4 md:grid md:grid-cols-2 md:gap-4">
                                                <div>
                                                    <p className="truncate text-sm font-medium">Folio: {compliance.internal_identification}</p>
                                                    <p className="text-sm text-gray-900">
                                                        <time dateTime={compliance.collected_at}>{formatDate(compliance.collected_at, 'DD MMM YYYY, HH:mm:ss')}</time>
                                                    </p>
                                                </div>
                                                <div className='flex items-center md:justify-end mt-2 md:mt-0'>
                                                    <Badge text={outcomes[compliance.outcome] || compliance.outcome} type={outcomesType[compliance.outcome]} />
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </div>
                                    </div>
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </SlideOver>

            <SlideOver open={showTaxComplianceDetails} setOpen={setShowTaxComplianceDetails} title='Detalles'>
                <div className="pb-20 -mx-4 sm:-mx-6">
                    <dl className="sm:divide-y sm:divide-gray-200 px-4 sm:px-0">
                        <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Resultado</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">
                                <Badge
                                    text={outcomes[taxComplianceDetails?.outcome] || taxComplianceDetails?.outcome}
                                    type={outcomesType[taxComplianceDetails?.outcome]} />
                            </dd>
                        </div>
                        <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Folio</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{taxComplianceDetails?.internal_identification}</dd>
                        </div>
                        <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Fecha</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{formatDate(taxComplianceDetails?.collected_at, 'DD MMM YYYY, HH:mm:ss')}</dd>
                        </div>
                        {taxComplianceDetails?.document && (
                            <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">PDF</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">
                                    <DocumentList
                                        documents={[
                                            {
                                                bucket: taxComplianceDetails?.document.bucket,
                                                key: taxComplianceDetails?.document.path
                                            }
                                        ]}
                                        onError={downloadError} />
                                </dd>
                            </div>
                        )}
                    </dl>

                    {taxComplianceDetails?.obligations && (
                        <CollapsibleList.Item title="Cumplimiento de obligaciones">
                            <div className='divide-y divide-gray-200'>
                                {taxComplianceDetails?.obligations?.map(obligation => (
                                    <>
                                        <dl key={obligation.obligation} className="sm:grid sm:grid-cols-1 sm:gap-4 sm:py-3 sm:px-6">
                                            <div className="sm:col-span-1 sm:gap-4 py-3 sm:py-0 px-6 sm:px-0">
                                                <dt className="text-sm font-medium text-gray-500 block">Obligación</dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{obligation.obligation}</dd>
                                            </div>
                                            <div className="sm:col-span-1 sm:gap-4 py-3 sm:py-0 px-6 sm:px-0">
                                                <dt className="text-sm font-medium text-gray-500 block">Periodos</dt>
                                                <dd className="mt-1 text-sm text-gray-900 col-span-1 sm:col-span-3 sm:-mt-2 -mx-2 space-y-2">
                                                    {obligation.periods?.map((period) => (
                                                        <span key={period.period} className="inline-flex items-center justify-center rounded-md bg-gray-100 px-2.5 py-1.5 text-sm font-medium text-gray-800 mx-2 min-w-[9rem]">
                                                            {period.period}
                                                        </span>
                                                    ))}
                                                </dd>
                                            </div>
                                        </dl>
                                    </>
                                ))}
                            </div>
                        </CollapsibleList.Item>
                    )}
                </div>
            </SlideOver>

            <SlideOver open={showTaxStatuses} setOpen={setShowTaxStatuses} title='Constancia de situación fiscal'>
                <div className="-mx-4 sm:-mx-6 overflow-hidden bg-white">
                    <p className="mx-4 sm:mx-6 truncate font-medium text-gray-500 mb-4">Historial de documentos</p>
                    <ul role="list" className="border-t border-b border-200 divide-y divide-gray-200 mb-20">
                        {taxStatusList?.map((status) => (
                            <li key={status.id}>
                                <a className="block hover:bg-gray-50 cursor-pointer" onClick={() => showTaxStatus(status)}>
                                    <div className="flex items-center px-4 py-4 sm:px-6">
                                        <div className="flex min-w-0 flex-1 items-center">
                                            <div className="min-w-0 flex-1 pr-4 md:grid md:grid-cols-2 md:gap-4">
                                                <div>
                                                    <p className="truncate text-sm font-medium">Folio: {status.id}</p>
                                                    <p className="text-sm text-gray-900">
                                                        <time dateTime={status.collected_at}>{formatDate(status.collected_at, 'DD MMM YYYY, HH:mm:ss')}</time>
                                                    </p>
                                                </div>
                                                <div className='flex items-center md:justify-end mt-2 md:mt-0'>
                                                    <Badge
                                                        text={statusPadron[status?.tax_payer_information?.status_padron] || status?.tax_payer_information?.status_padron}
                                                        type={statusPadronType[status?.tax_payer_information?.status_padron] || 'Error'} />
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </div>
                                    </div>
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </SlideOver>

            <SlideOver open={showTaxStatusDetails} setOpen={setShowTaxStatusDetails} title='Detalles'>
                <div className="pb-20 -mx-4 sm:-mx-6">
                    <div className="divide-y divide-gray-200">
                        <dl className="sm:divide-y sm:divide-gray-200 px-4 sm:px-0">
                            <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Estatus en el padrón</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">
                                    <Badge
                                        text={statusPadron[taxStatusDetails?.tax_payer_information?.status_padron] || taxStatusDetails?.tax_payer_information?.status_padron}
                                        type={statusPadronType[taxStatusDetails?.tax_payer_information?.status_padron] || 'Error'} />
                                </dd>
                            </div>
                            <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Lugar y fecha de emisión</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{taxStatusDetails?.place_and_date_of_issuance}</dd>
                            </div>
                            {taxStatusDetails?.document && (
                                <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">PDF</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">
                                        <DocumentList
                                            documents={[
                                                {
                                                    bucket: taxStatusDetails?.document.bucket,
                                                    key: taxStatusDetails?.document.path
                                                }
                                            ]}
                                            onError={downloadError} />
                                    </dd>
                                </div>
                            )}
                        </dl>

                        <CollapsibleList.Item title="Datos del contribuyente">
                            <dl className="sm:divide-y sm:divide-gray-200 px-4 sm:px-0">
                                <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">RFC</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{taxStatusDetails?.tax_payer_information?.rfc}</dd>
                                </div>
                                <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">CURP</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{taxStatusDetails?.tax_payer_information?.curp}</dd>
                                </div>
                                <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Nombre(s)</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{taxStatusDetails?.tax_payer_information?.name}</dd>
                                </div>
                                <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Primer apellido</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{taxStatusDetails?.tax_payer_information?.first_last_name}</dd>
                                </div>
                                <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Segundo apellido</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{taxStatusDetails?.tax_payer_information?.second_last_name}</dd>
                                </div>
                                <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Fecha inicio de operaciones</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{formatDate(taxStatusDetails?.tax_payer_information?.start_operations_date, 'DD MMM YYYY, HH:mm:ss')}</dd>
                                </div>
                                <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Fecha de último cambio de estado</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{formatDate(taxStatusDetails?.tax_payer_information?.last_status_change_date, 'DD MMM YYYY, HH:mm:ss')}</dd>
                                </div>
                                <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Nombre comercial</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{taxStatusDetails?.tax_payer_information?.commercial_name}</dd>
                                </div>
                            </dl>
                        </CollapsibleList.Item>

                        <CollapsibleList.Item title="Datos del domicilio registrado">
                            <dl className="sm:divide-y sm:divide-gray-200 px-4 sm:px-0">
                                <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Código postal</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{taxStatusDetails?.address?.postal_code}</dd>
                                </div>
                                <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Tipo de calle</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{taxStatusDetails?.address?.street_type}</dd>
                                </div>
                                <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Calle</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{taxStatusDetails?.address?.street}</dd>
                                </div>
                                <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Número exterior</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{taxStatusDetails?.address?.exterior_number}</dd>
                                </div>
                                <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Número interior</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{taxStatusDetails?.address?.interior_number}</dd>
                                </div>
                                <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Colonia</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{taxStatusDetails?.address?.suburb}</dd>
                                </div>
                                <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Localidad</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{taxStatusDetails?.address?.locality}</dd>
                                </div>
                                <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Municipio</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{taxStatusDetails?.address?.municipality}</dd>
                                </div>
                                <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Estado</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{taxStatusDetails?.address?.state}</dd>
                                </div>
                                <div className="py-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Entre calles</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{taxStatusDetails?.address?.between_street?.join(' y ')}</dd>
                                </div>
                            </dl>
                        </CollapsibleList.Item>

                        {taxStatusDetails?.economic_activity && (
                            <CollapsibleList.Item title="Actividades económicas">
                                <div className='divide-y divide-gray-200'>
                                    {taxStatusDetails?.economic_activity?.map(activity => (
                                        <dl key={activity.order} className="sm:grid sm:grid-cols-1 sm:gap-4 sm:py-3 sm:px-6">
                                            <div className="sm:col-span-1 sm:gap-4 py-3 sm:py-0 px-6 sm:px-0">
                                                <dt className="text-sm font-medium text-gray-500 block">Orden</dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{activity.order}</dd>
                                            </div>
                                            <div className="sm:col-span-1 sm:gap-4 py-3 sm:py-0 px-6 sm:px-0">
                                                <dt className="text-sm font-medium text-gray-500 block">Actividad económica</dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{activity.economic_activity}</dd>
                                            </div>
                                            <div className="sm:col-span-1 sm:gap-4 py-3 sm:py-0 px-6 sm:px-0">
                                                <dt className="text-sm font-medium text-gray-500 block">Porcentaje</dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{activity.percentage}</dd>
                                            </div>
                                            <div className="sm:col-span-1 sm:gap-4 py-3 sm:py-0 px-6 sm:px-0">
                                                <dt className="text-sm font-medium text-gray-500 block">Fecha inicio</dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{formatDate(activity.initial_date, 'DD/MM/YYYY')}</dd>
                                            </div>
                                            <div className="sm:col-span-1 sm:gap-4 py-3 sm:py-0 px-6 sm:px-0">
                                                <dt className="text-sm font-medium text-gray-500 block">Fecha fin</dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{activity.end_date && formatDate(activity.end_date, 'DD/MM/YYYY')}</dd>
                                            </div>
                                        </dl>
                                    ))}
                                </div>
                            </CollapsibleList.Item>
                        )}

                        {taxStatusDetails?.regimes && (
                            <CollapsibleList.Item title="Regímenes">
                                <div className='divide-y divide-gray-200'>
                                    {taxStatusDetails?.regimes?.map(regime => (
                                        <dl key={regime.regime} className="sm:grid sm:grid-cols-1 sm:gap-4 sm:py-3 sm:px-6">
                                            <div className="sm:col-span-1 sm:gap-4 py-3 sm:py-0 px-6 sm:px-0">
                                                <dt className="text-sm font-medium text-gray-500 block">Regimen</dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{regime.regime}</dd>
                                            </div>
                                            <div className="sm:col-span-1 sm:gap-4 py-3 sm:py-0 px-6 sm:px-0">
                                                <dt className="text-sm font-medium text-gray-500 block">Fecha inicio</dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{formatDate(regime.initial_date, 'DD/MM/YYYY')}</dd>
                                            </div>
                                            <div className="sm:col-span-1 sm:gap-4 py-3 sm:py-0 px-6 sm:px-0">
                                                <dt className="text-sm font-medium text-gray-500 block">Fecha fin</dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{regime.end_date && formatDate(regime.end_date, 'DD/MM/YYYY')}</dd>
                                            </div>
                                        </dl>
                                    ))}
                                </div>
                            </CollapsibleList.Item>
                        )}

                        {taxStatusDetails?.obligations && (
                            <CollapsibleList.Item title="Obligaciones">
                                <div className='divide-y divide-gray-200'>
                                    {taxStatusDetails?.obligations?.map(obligation => (
                                        <dl key={obligation.obligation} className="sm:grid sm:grid-cols-1 sm:gap-4 sm:py-3 sm:px-6">
                                            <div className="sm:col-span-1 sm:gap-4 py-3 sm:py-0 px-6 sm:px-0">
                                                <dt className="text-sm font-medium text-gray-500 block">Obligación</dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{obligation.obligation}</dd>
                                            </div>
                                            <div className="sm:col-span-1 sm:gap-4 py-3 sm:py-0 px-6 sm:px-0">
                                                <dt className="text-sm font-medium text-gray-500 block">Descripción vencimiento</dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{obligation.expiration}</dd>
                                            </div>
                                            <div className="sm:col-span-1 sm:gap-4 py-3 sm:py-0 px-6 sm:px-0">
                                                <dt className="text-sm font-medium text-gray-500 block">Fecha inicio</dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{formatDate(obligation.initial_date, 'DD/MM/YYYY')}</dd>
                                            </div>
                                            <div className="sm:col-span-1 sm:gap-4 py-3 sm:py-0 px-6 sm:px-0">
                                                <dt className="text-sm font-medium text-gray-500 block">Fecha fin</dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">{obligation.end_date && formatDate(obligation.end_date, 'DD/MM/YYYY')}</dd>
                                            </div>
                                        </dl>
                                    ))}
                                </div>
                            </CollapsibleList.Item>
                        )}
                    </div>
                </div>
            </SlideOver>

            <Modal show={showShareModal} setShow={setShowShareModal} className='min-w-full sm:min-w-[500px]'>
                <div>
                    <div className='relative'>
                        <XMarkIcon className='absolute w-7 h-7 right-0 sm:-right-3 sm:-top-3 cursor-pointer text-gray-500' onClick={() => setShowShareModal(false)} />
                    </div>
                    <div className="space-y-4">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                            Enviar documento
                        </h3>
                        <p className="max-w-xl text-sm text-gray-500">Envía el documento por correo electrónico</p>
                        <div className="hidden flex justify-center gap-4">
                            <div className={`${shareMethod == 'email' ? 'bg-v2-blue-text-login text-white' : 'border bg-white border-gray-400 text-gray-400 hover:bg-gray-100'} shadow-sm w-16 h-16 transition-all rounded-full flex items-center justify-center cursor-pointer`}
                                onClick={() => setShareMethod('email')}>
                                <EnvelopeIcon className='w-10 h-10' />
                            </div>
                            <div className={`${shareMethod == 'whatsapp' ? 'bg-v2-blue-text-login text-white' : 'border bg-white border-gray-400 text-gray-400 hover:bg-gray-100'} shadow-sm w-16 h-16 transition-all rounded-full flex items-center justify-center cursor-pointer`}
                                onClick={() => setShareMethod('whatsapp')}>
                                <FontAwesomeIcon className='text-4xl' icon={faWhatsapp} />
                            </div>
                        </div>
                        <form onSubmit={sendDocument} className="space-y-4">
                            <div className="max-w-xl text-sm text-gray-500">
                                {shareMethod == 'email' ? (
                                    <Input
                                        label='Correo electrónico'
                                        placeholder='Correo electrónico'
                                        type='email'
                                        id='email'
                                        needed
                                        onChange={(e) => (errors?.email !== '' && emailRegex(e.target.value)) && setErrors({ ...errors, email: null })}
                                        /*defaultValue={email}*/
                                        error={errors?.email} />
                                ) : (
                                    <Input
                                        label='Número de celular WhatsApp'
                                        type='tel'
                                        inputMode='tel'
                                        id='phone'
                                        needed
                                        maxLength={10}
                                        onInput={(e) => (errors?.phone !== '' && phoneRegex(e.target.value)) && setErrors({ ...errors, phone: null })}
                                        error={errors?.phone} />
                                )}
                            </div>
                            <div className="w-full flex flex-col sm:flex-row justify-end gap-4">
                                <SecondaryButton
                                    type="button"
                                    isFullWidth
                                    onClick={() => setShowShareModal(false)}>
                                    Cancelar
                                </SecondaryButton>
                                <AccentButton
                                    type='submit'
                                    isFullWidth
                                    isLoading={isSendingDocument}>
                                    {isSendingDocument ? 'Enviando' : 'Enviar'}
                                </AccentButton>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>

            <Modal show={showUpdatingTaxComplianceModal} setShow={setShowUpdatingTaxComplianceModal} className='min-w-full sm:min-w-[500px]'>
                <div>
                    <div className='relative'>
                        <XMarkIcon className='absolute w-7 h-7 right-0 sm:-right-3 sm:-top-3 cursor-pointer text-gray-500' onClick={() => setShowUpdatingTaxComplianceModal(false)} />
                    </div>
                    <div className="space-y-4 text-center pt-4">
                        <h3 className="text-lg font-medium leading-6 text-gray-900 mx-6">
                            Actualizando opinión de cumplimiento
                        </h3>
                        <p className="max-w-xl text-gray-500">La actualización puede tardar varios minutos, te notificaremos vía correo electrónico cuando esté listo.</p>
                        <div className="w-full flex flex-col sm:flex-row justify-end gap-4 lg:px-20 pt-2">
                            <AccentButton
                                type='button'
                                onClick={() => setShowUpdatingTaxComplianceModal(false)}
                                isFullWidth>
                                Aceptar
                            </AccentButton>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal show={showUpdatingTaxStatusModal} setShow={setShowUpdatingTaxStatusModal} className='min-w-full sm:min-w-[500px]'>
                <div>
                    <div className='relative'>
                        <XMarkIcon className='absolute w-7 h-7 right-0 sm:-right-3 sm:-top-3 cursor-pointer text-gray-500' onClick={() => setShowUpdatingTaxStatusModal(false)} />
                    </div>
                    <div className="space-y-4 text-center pt-4">
                        <h3 className="text-lg font-medium leading-6 text-gray-900 mx-6">
                            Actualizando constancia de situación fiscal
                        </h3>
                        <p className="max-w-xl text-gray-500">La actualización puede tardar varios minutos, te notificaremos vía correo electrónico cuando esté listo.</p>
                        <div className="w-full flex flex-col sm:flex-row justify-end gap-4 lg:px-20 pt-2">
                            <AccentButton
                                type='button'
                                onClick={() => setShowUpdatingTaxStatusModal(false)}
                                isFullWidth>
                                Aceptar
                            </AccentButton>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal show={documentToRefreshFrequency != null} setShow={() => setDocumentToRefreshFrequency(null)} className='min-w-full sm:min-w-[500px] overflow-visible'>
                <div>
                    <div className='relative'>
                        <XMarkIcon className='absolute w-7 h-7 right-0 sm:-right-3 sm:-top-3 cursor-pointer text-gray-500' onClick={() => setDocumentToRefreshFrequency(null)} />
                    </div>
                    <div className="space-y-4">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                            {documentToRefreshFrequency}
                        </h3>
                        <form onSubmit={updateRefreshFrequency} className="h-full">
                            <div className='w-full h-full flex flex-col justify-between gap-6'>
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-6">
                                        <SelectCustom
                                            label="Frecuencia de actualización"
                                            id="update_frequency"
                                            name="update_frequency"
                                            listOptions={defaultRefreshFrequencyList}
                                            value={selectedRefreshFrequency}
                                            setValue={value => setSelectedRefreshFrequency(value)}
                                        />
                                    </div>
                                    <Transition
                                        show={selectedRefreshFrequency == 'Personalizado'}
                                        enter="transition-all ease-in"
                                        enterFrom="max-h-0 opacity-0"
                                        enterTo="max-h-[10rem] opacity-100"
                                        leave="transition-all ease-out"
                                        leaveFrom="max-h-[10rem] opacity-100"
                                        leaveTo="max-h-0 opacity-0"
                                        className="col-span-6 flex flex-col sm:flex-row gap-4">
                                        <div className="w-full">
                                            <SelectCustom
                                                label="Frecuencia"
                                                id="custom_frequency"
                                                name="custom_frequency"
                                                listOptions={customRefreshFrequencyList}
                                                value={selectedCustomRefreshFrequency}
                                                setValue={value => setSelectedCustomRefreshFrequency(value)}
                                            />
                                        </div>
                                        <div className='w-full relative'>
                                            <Input
                                                label="Cada"
                                                placeholder="1"
                                                inputMode="number"
                                                id="frequency"
                                                name="frequency"
                                                autoComplete="off"
                                                maxLength={5}
                                                onChange={(e) => {
                                                    if (errors?.frequency) setErrors({ ...errors, frequency: null });
                                                    setCustomRefreshFrequency(e.target.value.replace(/\D/g, ""))
                                                }}
                                                value={customRefreshFrequency}
                                                type="text"
                                                error={errors?.frequency} />
                                            <span className={`${errors?.frequency ? 'right-8 text-red-600' : 'right-4 text-gray-600'} absolute top-[1.85rem]`}>
                                                {customRefreshFrequencyList.find(frequency => frequency.title == selectedCustomRefreshFrequency)[customRefreshFrequency > 1 ? 'pluralLabel' : 'singularLabel']}
                                            </span>
                                        </div>
                                    </Transition>
                                    <div className="col-span-6">
                                        {selectedRefreshFrequency != 'Personalizado' ? (
                                            <p className='text-sm text-gray-800'>
                                                El documento se actualizará {selectedRefreshFrequency != 'Personalizado' ? defaultRefreshFrequencyList.find(frequency => frequency.title == selectedRefreshFrequency).description : ''} automáticamente
                                            </p>
                                        ) : (
                                            <p className='text-sm text-gray-800'>
                                                El documento se actualizará cada {`${customRefreshFrequency > 1 ? customRefreshFrequency : ''} ${customRefreshFrequencyList.find(frequency => frequency.title == selectedCustomRefreshFrequency)[customRefreshFrequency > 1 ? 'pluralLabel' : 'singularLabel']}`} automáticamente
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className="w-full flex flex-col sm:flex-row justify-end gap-4">
                                    <SecondaryButton
                                        type="button"
                                        isFullWidth
                                        onClick={() => setDocumentToRefreshFrequency(null)}>
                                        Cancelar
                                    </SecondaryButton>
                                    <AccentButton
                                        type="submit"
                                        isFullWidth>
                                        Guardar
                                    </AccentButton>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
        </>
    )
}

TaxSituation.propTypes = {
    account: PropTypes.object
}

export default TaxSituation;