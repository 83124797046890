import React, { useState } from "react";
import PropTypes from "prop-types";

import { XMarkIcon } from "@heroicons/react/24/solid";

import Modal from "../../tailwindUI/Modal";
import PrimaryButton from "../../tailwindUI/PrimaryButton";
import SecondaryButton from "../../tailwindUI/SecondaryButton";

export function UpdateRegimeStatusModal({
  dataToUpdate,
  showModal,
  setShowModal,
  setAlert,
  updateStatus,
}) {
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);

  const translateStatus = {
    active: "Activo",
    inactive: "Inactivo",
  };

  const handleUpdateRegimeStatus = async (e) => {
    try {
      setIsUpdatingStatus(true);
      e.preventDefault();
      await updateStatus(dataToUpdate);
      setAlert({
        active: true,
        type: "success",
        message: "Estado del regímen actualizado correctamente",
      });
    } catch (error) {
      setAlert({ active: true, type: "error", message: error.message });
    } finally {
      setShowModal(false);
      setIsUpdatingStatus(false);
    }
  };

  return (
    <Modal
      show={showModal}
      setShow={setShowModal}
      className="min-w-full sm:min-w-[500px]"
    >
      <div className="py-2 px-2 space-y-3">
        <div className="relative">
          <XMarkIcon
            className="absolute w-7 h-7 -right-3 -top-3 cursor-pointer text-gray-500"
            onClick={() => setShowModal(false)}
          />
        </div>
        <form onSubmit={handleUpdateRegimeStatus}>
          <h3 className="text-lg text-center font-medium leading-6 text-gray-900">
            ¿Estás seguro que deseas cambiar a{" "}
            {translateStatus[dataToUpdate?.status]}?
          </h3>
          <div className="w-full lg:w-fit flex mt-6 ml-auto space-x-2">
            <SecondaryButton
              type="button"
              onClick={() => setShowModal(false)}
              disabled={isUpdatingStatus}
              isFullWidth
            >
              Cancelar
            </SecondaryButton>
            <PrimaryButton type="submit" isFullWidth disabled={isUpdatingStatus}>
              Confirmar
            </PrimaryButton>
          </div>
        </form>
      </div>
    </Modal>
  );
}

UpdateRegimeStatusModal.propTypes = {
  dataToUpdate: PropTypes.object,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  setAlert: PropTypes.func,
  updateStatus: PropTypes.func,
};
