import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { AuthContext } from '../AuthProvider';
import { ROLES, PERMISSIONS } from './permission-maps';

const hasPermission = ({ permissions, scopes }) => {
    const scopesMap = {};
    scopes.forEach((scope) => {
        scopesMap[scope] = true;
    });

    return permissions?.some((permission) => scopesMap[permission]) ?? true;
};

export default function PermissionsGate({
    children,
    RenderError = () => <></>,
    errorProps = null,
    scopes = [],
    allowedRoles = []
}) {
    const { user } = React.useContext(AuthContext);

    const roles = user?.roles;
    const permissions = [...new Set(roles?.map(role => PERMISSIONS[role])?.flat())];
    
    const permissionGranted = roles?.some(role => allowedRoles.includes(role)) || allowedRoles.includes(ROLES.all) || hasPermission({ permissions, scopes });

    if (!permissionGranted && !errorProps) return <RenderError />;

    if (!permissionGranted && errorProps)
        return cloneElement(children, { ...errorProps });

    return <>{children}</>;
}

PermissionsGate.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.func,
        PropTypes.any
    ]),
    RenderError: PropTypes.func,
    errorProps: PropTypes.object,
    scopes: PropTypes.array,
    allowedRoles: PropTypes.array
};