export const { 
    apiBaseUrl = '',
    apiNodeBaseUrl = '',
    apiKey = '',
    authorization = '',
    googleClientId = '',
    cognitoDomain = '',
    cognitoRedirectUri = '',
    cognitoLogoutUri = '',
    cognitoClientId = '',
    ENV = '',
    activeCampaignHost = '',
    activeCampaignKey = '',
    s3Bucket = '',
    bank_accounts = []
} = JSON.parse(process.env.REACT_APP_CONFIG || '{}');