import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Nav from '../../hooks/Nav';
import useFormatDate from '../../hooks/useFormatDate';
import SlideOver from '../../tailwindUI/SlideOver';
import Sort from '../../tailwindUI/Sort';
import Filters from '../../tailwindUI/Filters';
import SearchBar from '../../tailwindUI/SearchBar';
import EmptyState from '../../tailwindUI/EmptyState';
import Table from '../../tailwindUI/Table';
import { AdjustmentsHorizontalIcon, DocumentIcon, TrashIcon } from '@heroicons/react/24/outline';
import Pagination from '../../tailwindUI/Pagination';
import usePagination from '../../tailwindUI/usePagination';
import { getContracts } from '../../apiClient/operations/contractsOperations';
import useSort from '../../tailwindUI/useSort';
import LongAlert from '../../tailwindUI/LongAlert';
import useFilter from '../../tailwindUI/useFilter';
import useScrollPosition from '../../hooks/useScrollPosition';
import Notification from '../../tailwindUI/Notification';

const tableColumns = [
    { heading: 'Fecha de creación', value: 'created_formatted' },
    { heading: 'Estatus', value: 'status', badge: true },
    { heading: 'Fecha de activación', value: 'activation_date_formatted' },
    { heading: 'Folio', value: 'folio' },
    { heading: 'Nombre', value: 'account.name', subvalue: 'tax_id', main: true },
    //pasar a taxpayer
    { heading: 'Tipo persona', value: 'legal_type' }
    // { heading: 'Tipo persona', value: 'billing.legal_type_formatted' }
];

function Contracts() {
    const user = JSON.parse(localStorage.getItem('user')) || {};
    const paginate = usePagination();
    const { formatDate } = useFormatDate();
    const sortItem = useSort();
    const filterItem = useFilter();
    const setScrollPosition = useScrollPosition();
    const [sortOptions, setSortOptions] = useState([
        { id: 1, name: 'Los más nuevos', field: 'created', sort: 'reverse', active: false },
        { id: 2, name: 'Los más antiguos', field: 'created', sort: 'direct', active: false },
        { id: 3, name: 'Activos más nuevos', field: 'activation_date', sort: 'reverse', active: false },
        { id: 4, name: 'Activos más antiguos', field: 'activation_date', sort: 'direct', active: false },
        { id: 5, name: 'Nombre descendente', field: 'account.name', sort: 'reverse', active: false },
        { id: 6, name: 'Nombre ascendente', field: 'account.name', sort: 'direct', active: false }
    ]);
    const [filters, setFilters] = useState([
        {
            id: 1,
            name: 'Estatus',
            value: 'status',
            open: false,
            options: [
                { id: 1, value: 'pending', label: 'Pendiente', applied: false, checked: false, filter_id: 1 },
                { id: 2, value: 'active', label: 'Activo', applied: false, checked: false, filter_id: 1 },
                { id: 3, value: 'suspended', label: "Suspendido", applied: false, checked: false, filter_id: 1 },
                { id: 4, value: 'canceled', label: "Cancelado", applied: false, checked: false, filter_id: 1 },
                { id: 5, value: 'renewed', label: "Renovado", applied: false, checked: false, filter_id: 1 },
            ],
        },
    ]);
    const history = useHistory();
    const [searchInput, setSearchInput] = useState(null);
    const [sortString, setSortString] = useState('');
    const [filterString, setFilterString] = useState('');
    const [openSlide, setOpenSlide] = useState(false);
    const [pagination, setPagination] = useState(null);
    const [currentPage, setCurrenPage] = useState(0);
    const [filtersApplied, setFiltersApplied] = useState(false);
    const [contracts, setContracts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [notification, setNotification] = useState(false);

    const handleView = item => {
        history.push(`contracts/view/${item.id}`);
    }

    const columnActions = [
        {
            id: 1,
            name: 'Visualizar',
            type: 'primary',
            icon: <DocumentIcon className='w-5 h-5 text-gray-600 lg:text-white'/>,
            action: handleView,
        }
    ];

    const handleGetContracts = async (page) => {
        setIsLoading(true);
        try {
            setScrollPosition(0);
            setCurrenPage(page);
            const res = await getContracts(page, 10, searchInput, filterString, sortString);
            console.log(res)
            setPagination(paginate(res.total_items, 10, Math.ceil(res.total_items/res.limit)));
            const response = res.data.map(item => {
                return {
                    ...item,
                    ...( {activation_date_formatted: item.activation_date ? formatDate(item.activation_date, 'DD MMMM YYYY'):'-'}),
                    created_formatted: formatDate(item.created, 'DD MMMM YYYY'),
                    billing: {
                        ...item.billing,
                        legal_type_formatted: item.billing.legal_type && (item.billing.legal_type == 'personal' ? 'Persona Física' : 'Persona Moral') || ''
                    },
                    tax_id: item.account.taxpayer.tax_id, 
                    legal_type: item.account.taxpayer.legal_type && (item.account.taxpayer.legal_type == 'personal' ? 'Persona Física' : 'Persona Moral') || ''
                }
            });
            setContracts(response);
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            setError(e.message || 'Hubo un error al obtener la información.');
        }
    }

    const handleCleanFilters = () => {
        const newFilters = filters.map(filter => {
            const newOptions = filter.options.map(option => {
                return {
                    ...option,
                    applied: false,
                    checked: false
                }
            });
            return {
                ...filter,
                options: newOptions
            }
        });
        setFilters(newFilters);
        setFiltersApplied(false);
        setFilterString('');
        setNotification(true);
        setTimeout(() => {
            setNotification(false);
        }, 5000);
    }
    
    const handleSort = item => setSortString(sortItem(item));

    const handlePasteSearchInput = event => setSearchInput(event.clipboardData.getData('text'));

    const handleApplyFilters = () => {
        setFilterString(filterItem(filters));
        setFiltersApplied(false);
    }

    useEffect(() => {
    handleGetContracts(1);
        return () => {
            setContracts([]);
        }
    }, []);

    useEffect(() => {
    handleGetContracts(1);
    setScrollPosition(0);
        return () => {
            setContracts([]);
        }
    }, [filterString, sortString]);

    useEffect(() => {
        handleGetContracts(currentPage);
    setScrollPosition(0);
        return () => {
            setContracts([]);
        }
    }, [currentPage]);

    useEffect(() => {
    filtersApplied && handleApplyFilters();
    }, [filtersApplied]);

    useEffect(() => {
    searchInput !== null && handleGetContracts(1);
        return () => {
            setContracts([]);
        }
    }, [searchInput]);

    useEffect(() => {
    const activeOptions = sortOptions.filter(option => option.active);
    activeOptions.length > 0 && handleSort(activeOptions[0]);
    }, [sortOptions]);
    return (
        <>
            <div className="w-full">
                <Nav user={user}>
                    <div className="w-full">
                        <span className="text-3xl text-white font-bold">
                            Contratos
                        </span>
                    </div>
                </Nav>
            </div>
            <div className='min-h-full md:min-h-[calc(100vh-4rem)]'>
                {error &&
                    <div className='w-full sticky top-[68px] lg:top-1 mt-20 lg:mt-4 z-[35]'>
                        <LongAlert title={error} show={error != null} onClose={() => setError(null)} />
                    </div>
                }
                <Notification show={notification} message='Filtros limpiados correctamente' />
                <div className="w-full px-4 pt-4">
                    <div className="hidden lg:flex justify-between items-center w-full">
                        <h1 className="text-4xl font-bold text-left text-gray-900">
                            Contratos
                        </h1>
                    </div>
                </div>
                <div className={`w-full px-4 py-0 md:py-4 mb-16 lg:mb-8 ${error != null ? 'mt-0' : 'mt-16 lg:mt-0' } `}>
                    {isLoading ?
                        <>
                            <div className='w-full h-12 rounded-full bg-gray-300 animate-pulse'></div>
                            <div className='w-full flex justify-between'>
                                <div className='w-24 h-6 rounded-full bg-gray-300 animate-pulse my-4'/>
                                <div className='w-24 h-6 rounded-full bg-gray-300 animate-pulse my-4'/>
                            </div>
                        </>
                        :
                        <>
                            <SearchBar value={ searchInput } setValue={ setSearchInput } placeholder_mobile='Folio, nombre, correo o RFC' placeholder_desktop='Folio, nombre, correo o RFC' onPaste={ handlePasteSearchInput } />
                            <section aria-labelledby="filter-heading" className='pt-4'>
                                <div className="flex items-center justify-between">
                                    <Sort options={ sortOptions } title='Ordenar' setOptions={ setSortOptions } />
                                    <div className='flex items-center gap-6'>
                                        {filterString !== '' && <span className='cursor-pointer text-sm font-medium text-gray-700 hover:text-gray-900 flex gap-1 pt-0 md:pt-[1px]' onClick={handleCleanFilters}>Limpiar filtros <TrashIcon className='h-5 h-5' /></span>}
                                        <button type="button" className="flex items-center gap-1 text-sm font-medium text-gray-700 md:hidden" onClick={() => setOpenSlide(true)}>
                                            Filtros <span><AdjustmentsHorizontalIcon className='w-[18px] h-[18px]'/></span>
                                        </button>
                                        <Filters filters={ filters } setFilters={ setFilters } setFiltersApplied={setFiltersApplied} />
                                    </div>
                                </div>
                            </section>
                        </>
                    }
                    { isLoading ?
                            <Table title='Contratos' data={ contracts } isLoadingData={ isLoading } columns={ tableColumns } actions={ columnActions }/>
                        :
                        <>
                            {contracts.length > 0 ?
                                <div className='mt-4 space-y-4'>
                                    <Table title='Contratos' data={ contracts } isLoadingData={ isLoading } columns={ tableColumns } actions={ columnActions } />
                                    <Pagination pages={pagination?.pages?.length} currentPage={currentPage} setCurrentPage={setCurrenPage}/>
                                </div>
                                :
                                <div className='mt-4'>
                                    <EmptyState title='No se encontraron contratos' text='Para visualizar la información, deben existir primero en el sistema, verifícalo.' />
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
            <SlideOver open={ openSlide } setOpen={ setOpenSlide } title='Filtros'>
                <Filters filters={ filters } openSlide={ openSlide } setFilters={ setFilters } setOpenSlide={ setOpenSlide } setFiltersApplied={setFiltersApplied}/>
            </SlideOver>
        </>
    )
}

export default Contracts;