import apiClient from '../apiClient';
import { apiBaseUrl } from '../../config';

export const getBalanceOverview = async (accountId) => {
    return apiClient.get(`${apiBaseUrl}/balance-taxpayer/${accountId}`)
    .then(response => { return response })
}

export const getBalanceCharges = async (accountId, sort = 'desc') => {
    return apiClient.get(`${apiBaseUrl}/balance-taxpayer/charges/${accountId}?sort=${sort}&field=payment_date`)
    .then(response => { return response })
}