const useTransformRfcToDate = rfc => {
    if (rfc.length == 12) {
        const rfcF = rfc.toUpperCase();
        let m = rfcF.match(/^[&ñÑa-zA-Z0-9_]{3}(\w{2})(\w{2})(\w{2})/);
        let year = parseInt(m[1], 10) + 1900;
        if(year < 1950) year += 100;
        let month = parseInt(m[2], 10) - 1;
        let day = parseInt(m[3], 10);
        let dateBirth = new Date(year, month, day);
        return dateBirth
    } else {
        const rfcF = rfc.toUpperCase();
        let m = rfcF.match(/^\w{4}(\w{2})(\w{2})(\w{2})/);
        let year = parseInt(m[1], 10) + 1900;
        if(year < 1950) year += 100;
        let month = parseInt(m[2], 10) - 1;
        let day = parseInt(m[3], 10);
        let dateBirth = new Date(year, month, day);
        return dateBirth
    }
}

export default useTransformRfcToDate