import apiClient from "../apiClient";
import { apiBaseUrl } from "../../config";

export const getRegimes = async (accountId) => {
  return apiClient
    .get(`${apiBaseUrl}/taxpayer-account/regimes/${accountId}`)
    .then((response) => {
      return response;
    });
};

export const updateRegimeStatus = async (accountId, data) => {
  return apiClient.put(
    `${apiBaseUrl}/taxpayer-account/regime/${accountId}`,
    data
  );
};

export const createRegime = async (accountId, data) => {
  return apiClient.post(
    `${apiBaseUrl}/taxpayer-account/regimes/${accountId}`,
    data
  );
};
