import React, { useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faCircleXmark, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import Nav from '../../../hooks/Nav';
import { useForm } from 'react-hook-form';
import Button from '../../../hooks/Button';
import { createLead, createSession, getLeadByEmail } from '../../../apiClient/operations/leadsOperations';
import SecondaryLoader from '../../../hooks/SecondaryLoader';
import Modal from '../../../hooks/Modal';
import { AlertContext } from '../../../context/AlertContext';
import useSeparateFullname from '../../../hooks/useSeparateFullname';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import { useHistory } from 'react-router';

function NewLead() {
    const history = useHistory();
    const { separateFullname } = useSeparateFullname();
    const user = JSON.parse(localStorage.getItem('user')) || {};
    const [secondaryLoader, setSecondaryLoader] = useState(false);
    const [openConfirmUpdate, setOpenConfirmUpdate] = useState(false);
    const { setAlert } = useContext(AlertContext);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm();

    const leadError = () => {
        setSecondaryLoader(false);
        setAlert({ active: true, type: 'error', message: 'Error al procesar tu solicitud' })
    }

    const registerLead = (id, name, email, phone_number, origin) => {
        const nameObject = separateFullname(name);
        const firstName = nameObject.name;
        const lastName = `${nameObject.lastName} ${nameObject.secondLastName}`;
        const lead = {
            id: id,
            first_name: firstName,
            last_name: lastName,
            name: name,
            email: email,
            phone_number: phone_number,
            source: origin
        }
        createLead(lead).then(() => {
            reset();
            setSecondaryLoader(false);
            setOpenConfirmUpdate(!openConfirmUpdate);
        }).catch((e) => {
            console.log(e);
            leadError()
        });
    }

    const emailExisted = (error, data) => {
        if (error.name == "email_not_available") {
            console.log('Email no disponible');
            getLeadByEmail(data.email).then(res => {
                const { lead, name, email, phone_number } = res.data[0];
                registerLead(lead, name, email, phone_number, data.origin);
            }).catch(() => {
                setSecondaryLoader(false);
                setAlert({ active: true, type: 'error', message: `El usuario con el email ${data.email} ya existe, contacta a un administrador` })
            });
        } else {
            leadError();
        }
    }

    const onSubmit = async (data) => {
        setSecondaryLoader(true);
        const { name, email, phone_number, origin } = data;
        const phone = `+52${phone_number}`;
        const body = {
            name: name,
            email: email,
            phone_number: phone,
            source: origin
        }
        createSession(body).then((res) => {
            const { id, name, email, phone_number } = res;
            registerLead(id, name, email, phone_number, origin);
        }).catch(error => emailExisted(error, data));
    }

    return (
        <>
            {secondaryLoader && <SecondaryLoader />}
            <div className="w-full h-full">
                <div className="w-full xs:h-full md:h-full">
                    <div className="w-full">
                        <Nav user={user}>
                            <div className="w-full flex gap-2 text-white">
                                <ArrowLeftIcon className='w-10 md:w-12 h-10 md:h-12 cursor-pointer' onClick={() => history.goBack()} />
                                <span className="text-3xl text-white font-bold">
                                    Nuevo Lead
                                </span>
                            </div>
                        </Nav>
                    </div>
                    <div className="w-full px-5 py-3">
                        <div className="hidden md:flex gap-2 items-center w-full">
                            <ArrowLeftIcon className='w-10 md:w-12 h-10 md:h-12 cursor-pointer' onClick={() => history.goBack()} />
                            <h1 className="text-4xl font-bold text-left">
                                Nuevo Lead
                            </h1>
                        </div>
                    </div>
                    <div className='w-full px-6 mt-16 lg:mt-0'>
                        <div className="w-full h-full border border-v2-gray-border-tables rounded-10 bg-white xs:mt-0 md:mt-3">
                            <div className='w-full xs:px-2 lg:px-4 py-[7px] border-b border-v2-gray-border-tables flex items-center'>
                                <div className="w-12 text-v2-blue-icons">
                                    <FontAwesomeIcon icon={faUserCircle} className='leading-3 pl-1 text-3.5xl' />
                                </div>
                                <div className='xs:w-11/12 md:w-10/12 text-xl font-semibold text-v2-input-text'>
                                    Añadir un lead
                                </div>
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)} className="my-6 xs:px-3 md:px-4">
                                <div className='w-full grid xs:grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-3 xs:mt-0 xs:mb-10 md:my-6'>
                                    <div className="xs:mt-0 md:mt-2">
                                        <label htmlFor="name" className="text-base text-v2-input-text">
                                            Nombre completo
                                        </label>
                                        <input
                                            type="text"
                                            className={`w-full rounded-md px-2 h-[46px] py-2 border ${errors.name ? 'border-v2-red-error-services-selected' : 'border-v2-border-time focus:border-v2-blue-text-login focus:shadow-md focus:shadow-v2-blue-text-login/30'} text-v2-input-text outline-none`}
                                            placeholder="Ingresa el nombre"
                                            name="name"
                                            id="name"
                                            inputMode='text'
                                            autoComplete='off'
                                            {...register("name", {
                                                required: true,
                                                minLength: 8
                                            })}
                                        />
                                        {errors.name && <span className="block pt-0.5 text-v2-red-error-services-selected">Ingresa el nombre correctamente</span>}
                                    </div>
                                    <div className="xs:mt-0 md:mt-2">
                                        <label htmlFor="email" className="text-base text-v2-input-text">
                                            Correo electrónico
                                        </label>
                                        <input
                                            type='text'
                                            className={`w-full rounded-md px-2 h-[46px] py-2 border ${errors.email ? 'border-v2-red-error-services-selected' : 'border-v2-border-time focus:border-v2-blue-text-login focus:shadow-md focus:shadow-v2-blue-text-login/30'} text-v2-input-text outline-none`}
                                            placeholder="Correo electrónico"
                                            name="email"
                                            id="email"
                                            inputMode='email'
                                            autoComplete='off'
                                            {...register("email", {
                                                required: true,
                                                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                minLength: 11
                                            })}
                                        />
                                        {errors.email && <span className="block pt-0.5 text-v2-red-error-services-selected">Ingresa el email válido</span>}
                                    </div>
                                    <div className="xs:mt-0 md:mt-2">
                                        <label htmlFor="phone_number" className="text-base text-v2-input-text">
                                            Número celular
                                        </label>
                                        <input
                                            type="text"
                                            className={`w-full rounded-md px-2 h-[46px] py-2 border ${errors.phone_number ? 'border-v2-red-error-services-selected' : 'border-v2-border-time focus:border-v2-blue-text-login focus:shadow-md focus:shadow-v2-blue-text-login/30'} text-v2-input-text outline-none`}
                                            placeholder="Ingresa los 10 dígitos del teléfono"
                                            name="phone_number"
                                            id="phone_number"
                                            inputMode='tel'
                                            autoComplete='off'
                                            maxLength={10}
                                            {...register("phone_number", {
                                                required: true,
                                                pattern: /^\d{10}$/i
                                            })}
                                        />
                                        {errors.phone_number && <span className="block pt-0.5 text-v2-red-error-services-selected">Ingresa el número correctamente</span>}
                                    </div>
                                    <div className="xs:mt-0 md:mt-2">
                                        <label htmlFor="origin" className="text-base text-v2-input-text">
                                            Origen
                                        </label>
                                        <input
                                            type="text"
                                            className={`w-full rounded-md px-2 h-[46px] py-2 border ${errors.origin ? 'border-v2-red-error-services-selected' : 'border-v2-border-time focus:border-v2-blue-text-login focus:shadow-md focus:shadow-v2-blue-text-login/30'} text-v2-input-text outline-none`}
                                            placeholder="Ingresa el origen"
                                            defaultValue='intranet'
                                            name="origin"
                                            id="origin"
                                            inputMode='text'
                                            autoComplete='off'
                                            maxLength={50}
                                            {...register("origin", {
                                                required: true,
                                                minLength: 3
                                            })}
                                        />
                                        {errors.origin && <span className="block pt-0.5 text-v2-red-error-services-selected">Ingresa el origen del lead</span>}
                                    </div>
                                </div>
                                <div className='w-full flex justify-end'>
                                    <div className='xs:w-full md:w-1/2 lg:w-1/3'>
                                        <Button heigth='h-12' bg='bg-v2-blue-text-login'>
                                            Añadir lead
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Modal hasTitle={false} modalOpen={openConfirmUpdate} modalOpenChange={setOpenConfirmUpdate} maxWidth="max-w-lg">
                <div className="w-full pt-2 pb-5 px-[9px]">
                    <div className="w-full flex justify-end">
                        <span className="w-full flex justify-end text-v2-gray-light-plus-circle leading-3 text-2xl cursor-pointer" onClick={() => setOpenConfirmUpdate(!openConfirmUpdate)}>
                            <FontAwesomeIcon icon={faCircleXmark} />
                        </span>
                    </div>
                    <div className="w-full mt-4">
                        <span className="flex justify-center text-v2-border-alert-success leading-3 text-5xl">
                            <FontAwesomeIcon icon={faCircleCheck} />
                        </span>
                    </div>
                    <div className="w-full mt-5">
                        <span className="w-full flex justify-center text-v2-gray-title-service-modal text-xl text-center font-bold">
                            <span className='xs:w-full md:w-2/4'>
                                Lead añadido correctamente
                            </span>
                        </span>
                    </div>
                    <div className="mt-28 px-4">
                        <Button heigth="h-12" width="w-full" bg="bg-v2-blue-text-login" onClick={() => setOpenConfirmUpdate(!openConfirmUpdate)}>
                            Listo
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default NewLead