import React from 'react';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import useFormatDate from './useFormatDate';
import PropTypes from 'prop-types';
import HeadersDataTables from './HeadersDataTables';
import DateBadge from '../tailwindUI/DateBadge';

function QuoteValidity({ creation, date, folio, seller}) {
    const { formatDate } = useFormatDate();
    return (
        <div className="w-full border border-v2-gray-border-tables bg-white rounded-10">
            <HeadersDataTables icon={ faCalendar } text="Datos de cotización" background={ true } padding={ 0 } bgColor="bg-v2-blue-text-login" textColor="text-white"/>
            <div className='w-full xs:px-6 lg:px-4 pt-3 xs:block lg:flex items-center'>
                <div className='xs:w-full lg:w-1/2 text-xs font-bold text-v2-gray-title-client'>
                    Folio:
                    <span className='text-base font-normal block py-1 text-v2-input-text'>{ folio }</span>
                </div>
                <div className='xs:w-full lg:w-1/2 text-xs font-bold text-v2-gray-title-client'>
                    Vendedor:
                    <span className='text-base font-normal block py-1 text-v2-input-text'>{ seller }</span>
                </div>
            </div>
            <div className="w-full xs:px-6 lg:px-4 pb-2 pt-2 xs:block lg:flex items-center">
                <div className='xs:w-full lg:w-1/2 text-xs font-bold text-v2-gray-title-client'>
                    Fechas de creación:
                    <span className='text-base font-normal block py-2 text-v2-input-text'>{ formatDate(creation, 'DD [de] MMMM YYYY') }</span>
                </div>
                <div className='xs:w-3/4 lg:w-1/2 text-xs font-bold text-v2-gray-title-client'>
                    Vigencia de cotización:
                    <div className='py-2.5'>
                        <DateBadge date={date} />
                    </div>
                </div>
            </div>
        </div>
    )
}

QuoteValidity.propTypes = {
    date: PropTypes.any,
    creation: PropTypes.any,
    folio: PropTypes.any,
    seller: PropTypes.string
}

export default QuoteValidity