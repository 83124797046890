import React from 'react';
import PropTypes from 'prop-types';

function Button( { children, padding, disabled, width, heigth, bg, rounded, border, textColor, ...rest } ) {
  return (
    <button className={`${width} ${heigth} ${bg} ${disabled ? 'opacity-50 cursor-not-allowed' : 'opacity-100 cursor-pointer'} ${padding} text-base ${rounded} flex justify-center border ${border} items-center ${textColor} outline-none select-none`} disabled={disabled} {...rest}>
        { children } 
    </button>
  )
}

Button.propTypes = {
  children: PropTypes.any,
  padding: PropTypes.string,
  disabled: PropTypes.any,
  width: PropTypes.string,
  heigth: PropTypes.string,
  bg: PropTypes.string,
  rounded: PropTypes.string,
  border: PropTypes.string,
  textColor: PropTypes.string
}

Button.defaultProps = {
  padding: 'p-3',
  disabled: false,
  width: 'w-full',
  heigth: 'h-full',
  bg: 'bg-button-orange',
  rounded: 'rounded-5',
  border: 'border-transparent',
  textColor: 'text-white'
}

export default Button