import { useEffect, useState } from "react";
import { getOrder } from "../../apiClient/operations/ordersOperations";

import useFormatDate from "../useFormatDate";
import useFormatterCurrency from "../useFormatterCurrency";

export function useOrder({ orderId }) {
  const [order, setOrder] = useState(null);
  const [isLoadingOrder, setIsLoadingOrder] = useState(false);
  const [errorOrder, setErrorOrder] = useState(false);

  const { formatterCurrency } = useFormatterCurrency();
  const { formatDate } = useFormatDate();

  const getTransformedData = (data) => {
    const date = data.status === "paid" ? data?.charges?.[0]?.date : null;
    return {
      createdBy: data?.created_by,
      amount: formatterCurrency(data.price.total_amount / 100),
      date: date ? formatDate(new Date(date), "D MMMM YYYY, h:mm a") : null,
      status: data.status,
    };
  };

  const getAppointmentFormatted = async () => {
    try {
      setIsLoadingOrder(true);
      const response = await getOrder(orderId);
      const transformedData = getTransformedData(response);
      setOrder(transformedData);
    } catch (error) {
      setErrorOrder(error.message);
    } finally {
      setIsLoadingOrder(false);
    }
  };

  const updateOrder = (data) => {
    const transformedData = getTransformedData(data);
    setOrder(transformedData);
  };

  useEffect(() => {
    if (orderId) {
      getAppointmentFormatted();
    }
  }, [orderId]);

  return { order, updateOrder, isLoadingOrder, errorOrder };
}
