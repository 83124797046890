import React from 'react';
import PropTypes from 'prop-types';


function Select({ disabled, innerRef, children, className, ...rest }) {

    return (
        <>
            <div className="w-full">
                <div className="relative border-v2-gray-border-tables w-full my-1 px-2 border rounded-md text-base text-v2-input-text outline-none flex items-center justify-center">
                    <select className={`${disabled ? '' : 'cursor-pointer'} w-full py-2 bg-white focus:outline-none text-base font-normal border-none focus:ring-transparent ${className}`}
                        ref={innerRef}
                        disabled={disabled}
                        {...rest}
                        style={{
                            WebkitAppearance: "none"
                        }}>
                        {children}
                    </select>
                </div>
            </div>
        </>
    )
}

Select.propTypes = {
    disabled: PropTypes.bool,
    innerRef: PropTypes.any,
    children: PropTypes.any,
    className: PropTypes.string
}

export default Select;