import React, { useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faCircleXmark, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import Nav from '../../hooks/Nav';
import { useForm } from 'react-hook-form';
import Button from '../../hooks/Button';
import { createSessionAdmin } from '../../apiClient/operations/leadsOperations';
import SecondaryLoader from '../../hooks/SecondaryLoader';
import Modal from '../../hooks/Modal';
import { AlertContext } from '../../context/AlertContext';
import ReactTooltip from 'react-tooltip';
import ReactDOMServer from 'react-dom/server';
import { InformationCircleIcon } from '@heroicons/react/24/solid';

function NewAccess() {
    const [secondaryLoader, setSecondaryLoader] = useState(false);
    const [showTooltip, setShowTooltip] = useState(true);
    const [openConfirmUpdate, setOpenConfirmUpdate] = useState(false);
    const { setAlert } = useContext(AlertContext);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm();

    const leadError = () => {
        setSecondaryLoader(false);
        setAlert({ active: true, type: 'error', message: 'Error al procesar tu solicitud' })
    }

    const emailExisted = (error, data) => {
        console.log(error.name)
        if (error.name == "admin_not_available") {
            console.log('Admin no disponible');
            setSecondaryLoader(false);
            setAlert({ active: true, type: 'error', message: `El administrador con el email ${data.email} ya existe, verifica tu información` })
        } else {
            if (error.name == "badly_processed_request") {
                console.log('Contraseña inválida');
                setSecondaryLoader(false);
                setAlert({ active: true, type: 'error', message: `La contraseña que ingresaste no es válida, recuerda que debe contener una mayúscula, una minúscula, un carácter especial y un número ` })
            }
            else{
                leadError();
            }
        }
    }

    const onSubmit = async (data) => {
        setSecondaryLoader(true);
        const { name, email, phone_number, password } = data;
        const phone = `+52${phone_number}`;
        const body = {
            name: name,
            email: email,
            phone_number: phone,
            password: password
        }
        console.log(body)
        createSessionAdmin(body).then((res) => {
            console.log(res)
            reset();
            setSecondaryLoader(false);
            setOpenConfirmUpdate(!openConfirmUpdate);
        }).catch(error => emailExisted(error, data));
    }

    const tooltipHtml = () => (
        <>
            {showTooltip &&
                <ReactTooltip
                    id={"password"}
                    place="top"
                    effect='solid'/>
            }
            <InformationCircleIcon
                className='cursor-pointer w-5 h-5 text-gray-300'
                data-for={"password"}
                data-html={true}
                data-tip={ReactDOMServer.renderToString(<div>Mínimo 6 caracteres, una mayúscula, una minúscula, un número y<br></br> un carácter especial ({`^$*.[]{}()?-"!@#%&/,><':;|_~'+=`})</div>)}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => {
                    setShowTooltip(false);
                    setTimeout(() => setShowTooltip(true), 50);
                }} />
        </>
    )

    return (
        <>
            {secondaryLoader && <SecondaryLoader />}
            <div className="w-full h-full">
                <div className="w-full xs:h-full md:h-full">
                    <div className="w-full bg-white xs:h-auto md:h-1/12">
                        <Nav>
                            <div className="w-full">
                                <span className="text-3xl text-white font-bold">
                                    Acceso al portal
                                </span>
                            </div>
                        </Nav>
                    </div>
                    <div className="w-full px-5 py-5  xs:h-auto md:h-1/12">
                        <div className="hiddem lg:flex justify-between items-center w-full">
                            <h1 className="text-4xl font-bold text-left">
                                Acceso al portal
                            </h1>
                        </div>
                    </div>
                    <div className='w-full px-6'>
                        <div className="w-full h-full border border-v2-gray-border-tables rounded-10 bg-white xs:mt-0 md:mt-3">
                            <div className='w-full xs:px-2 lg:px-4 py-[7px] border-b border-v2-gray-border-tables flex items-center'>
                                <div className="w-12 text-v2-blue-icons">
                                    <FontAwesomeIcon icon={faUserCircle} className='leading-3 pl-1 text-3.5xl' />
                                </div>
                                <div className='xs:w-11/12 md:w-10/12 text-xl font-semibold text-v2-input-text'>
                                    Añadir un acceso de administrador al portal
                                </div>
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)} className="my-6 xs:px-3 md:px-4">
                                <div className='w-full grid xs:grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-3 xs:mt-0 xs:mb-10 md:my-6'>
                                    <div className="xs:mt-0 md:mt-2">
                                        <label htmlFor="name" className="text-base text-v2-input-text">
                                            Nombre completo
                                        </label>
                                        <input
                                            type="text"
                                            className={`w-full rounded-md px-2 h-[46px] py-2 border ${errors.name ? 'border-v2-red-error-services-selected' : 'border-v2-border-time focus:border-v2-blue-text-login focus:shadow-md focus:shadow-v2-blue-text-login/30'} text-v2-input-text outline-none`}
                                            placeholder="Ingresa el nombre"
                                            name="name"
                                            id="name"
                                            inputMode='text'
                                            autoComplete='off'
                                            {...register("name", {
                                                required: true,
                                                pattern: /^[A-Za-zÁÉÍÓÚáéíóúÜüÑñ,. -']{6,45}$|^$/,
                                                minLength: 8
                                            })}
                                        />
                                        {errors.name && <span className="block pt-0.5 text-v2-red-error-services-selected">Ingresa el nombre correctamente</span>}
                                    </div>
                                    <div className="xs:mt-0 md:mt-2">
                                        <label htmlFor="email" className="text-base text-v2-input-text">
                                            Correo electrónico
                                        </label>
                                        <input
                                            type='text'
                                            className={`w-full rounded-md px-2 h-[46px] py-2 border ${errors.email ? 'border-v2-red-error-services-selected' : 'border-v2-border-time focus:border-v2-blue-text-login focus:shadow-md focus:shadow-v2-blue-text-login/30'} text-v2-input-text outline-none`}
                                            placeholder="Correo electrónico"
                                            name="email"
                                            id="email"
                                            inputMode='email'
                                            autoComplete='off'
                                            {...register("email", {
                                                required: true,
                                                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                minLength: 11
                                            })}
                                        />
                                        {errors.email && <span className="block pt-0.5 text-v2-red-error-services-selected">Ingresa el email válido</span>}
                                    </div>
                                    <div className="xs:mt-0 md:mt-2">
                                        <label htmlFor="phone_number" className="text-base text-v2-input-text">
                                            Número celular
                                        </label>
                                        <input
                                            type="text"
                                            className={`w-full rounded-md px-2 h-[46px] py-2 border ${errors.phone_number ? 'border-v2-red-error-services-selected' : 'border-v2-border-time focus:border-v2-blue-text-login focus:shadow-md focus:shadow-v2-blue-text-login/30'} text-v2-input-text outline-none`}
                                            placeholder="Ingresa los 10 dígitos del teléfono"
                                            name="phone_number"
                                            id="phone_number"
                                            inputMode='tel'
                                            autoComplete='off'
                                            maxLength={10}
                                            {...register("phone_number", {
                                                required: true,
                                                pattern: /^\d{10}$/i
                                            })}
                                        />
                                        {errors.phone_number && <span className="block pt-0.5 text-v2-red-error-services-selected">Ingresa el número correctamente</span>}
                                    </div>
                                    <div className="xs:mt-0 md:mt-2">
                                        <label htmlFor="password" className="text-base  flex items-center gap-2 text-v2-input-text">
                                            Contraseña {tooltipHtml()}
                                        </label>
                                        <input
                                            type="password"
                                            className={`w-full rounded-md px-2 h-[46px] py-2 border ${errors.password ? 'border-v2-red-error-services-selected' : 'border-v2-border-time focus:border-v2-blue-text-login focus:shadow-md focus:shadow-v2-blue-text-login/30'} text-v2-input-text outline-none`}
                                            placeholder="Ingresa una contraseña"
                                            name="password"
                                            id="password"
                                            inputMode='text'
                                            autoComplete='off'
                                            maxLength={50}
                                            {...register("password", {
                                                required: true,
                                                minLength: 6,
                                                pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$*.\[\]\{\}\(\)?\-"!@#%&\/\^\,><':;|_~`+=])[A-Za-z\d$*.\[\]\{\}\(\)?\-"!@#%&\/\^\,><':;|_~`+=]{6,}$/i // eslint-disable-line
                                            })}
                                        />
                                        
                                        {errors.password && <div className='flex gap-2 items-center'><span className="w-7/12 block pt-0.5 text-v2-red-error-services-selected">Ingresa una contraseña válida</span>  </div>}
                                    </div>
                                </div>
                                <div className='w-full flex justify-end'>
                                    <div className='xs:w-full md:w-1/2 lg:w-1/3'>
                                        <Button heigth='h-12' bg='bg-v2-blue-text-login'>
                                            Añadir acceso
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Modal hasTitle={false} modalOpen={openConfirmUpdate} modalOpenChange={setOpenConfirmUpdate} maxWidth="max-w-lg">
                <div className="w-full pt-2 pb-5 px-[9px]">
                    <div className="w-full flex justify-end">
                        <span className="w-full flex justify-end text-v2-gray-light-plus-circle leading-3 text-2xl cursor-pointer" onClick={() => setOpenConfirmUpdate(!openConfirmUpdate)}>
                            <FontAwesomeIcon icon={faCircleXmark} />
                        </span>
                    </div>
                    <div className="w-full mt-4">
                        <span className="flex justify-center text-v2-border-alert-success leading-3 text-5xl">
                            <FontAwesomeIcon icon={faCircleCheck} />
                        </span>
                    </div>
                    <div className="w-full mt-5">
                        <span className="w-full flex justify-center text-v2-gray-title-service-modal text-xl text-center font-bold">
                            <span className='xs:w-full md:w-2/4'>
                                Acceso añadido correctamente
                            </span>
                        </span>
                    </div>
                    <div className="mt-28 px-4">
                        <Button heigth="h-12" width="w-full" bg="bg-v2-blue-text-login" onClick={() => setOpenConfirmUpdate(!openConfirmUpdate)}>
                            Listo
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default NewAccess