import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PrimaryButton from '../../../tailwindUI/PrimaryButton';
import { ReceiptRefundIcon } from '@heroicons/react/24/solid';
import LinkButton from '../../../tailwindUI/LinkButton';
import { sendReimbursment } from '../../../apiClient/operations/chargesOperations';
import SecondaryLoader from '../../../hooks/SecondaryLoader';
import LongAlert from '../../../tailwindUI/LongAlert';
import SlideOver from '../../../tailwindUI/SlideOver';
import { useForm } from 'react-hook-form';
import { AuthContext } from '../../../cognito/AuthProvider';
import DateTimePicker from '../../../tailwindUI/DateTimePicker';
import { bank_accounts } from '../../../config';

function ReimbursmentModal({account, showRefund, setShowRefund,handleUpdate}) {    
    const { user } = React.useContext(AuthContext);
    const [ alert, setAlert ] = useState();
    const [showAlert, setShowAlert] = useState(false);
    const [loading, setLoading] = useState(false);
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors }
    } = useForm();

    const [refundSelect, setRefundSelect] = useState(bank_accounts)
    
    useEffect(() => {
        setShowAlert(alert?.active)
    }, [alert]);
    const handlerRefund = async (data) => {
        try {
            const { bank_account, account_type, bank, return_date, auth_number, return_amount, return_reason } = data;
            setLoading(true)
            var request = {
                "returned_by":{
                    "name": user.name,
                    "email": user.email
                },
                "payment_method":{
                    "bank":bank,
                    "account_type":account_type,
                    "account":bank_account,
                },
                "auth_number":auth_number,
                "bank":refundSelect.find((item) => item.active === true).bank,
                "account":refundSelect.find((item) => item.active === true).account,
                "return_date":return_date.valueOf(),
                "amount":parseInt(return_amount*100),
                "status":"completed",
                "return_reason":return_reason
            }
            await sendReimbursment(account.id, request)
            setLoading(false)
            reset()
            handleUpdate()
            setAlert({ active: true, type: 'success', message: 'Reembolso exitoso' })
            setShowRefund(false)
        }catch(error){
            setAlert({ active: true, type: 'error', message: error.message })
            setLoading(false)
        }
        
    }

    return (<>
    
            <SlideOver open={showRefund} setOpen={setShowRefund} title={"Nueva devolución"} className='w-full sm:max-w-[45rem]'>
            <form onSubmit={handleSubmit(handlerRefund)} className="w-full divide-y divide-gray-200">

            <div className='pb-3'>
                                    <h3 className="pt-3 font-medium leading-6 text-gray-900 mb-2">Datos del cliente:</h3>
                                    <dl className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                                        <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">Nombre:</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">{account?.name}</dd>
                                        </div>
                                        <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">Correo:</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">{account?.email}</dd>
                                                </div>
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Cuenta destino:</dt>
                                                <input
                                                    onKeyDown={(event) => {
                                                        if (!/[0-9.]/.test(event.key) && event.key !== "Backspace") {
                                                        event.preventDefault();
                                                        }
                                                    }}
                                                    placeholder={"CLABE, tarjeta o cuenta"}
                                                    maxLength={18}                                
                                                    type="text"
                                                    inputMode='text'
                                                    className={`w-full rounded-md px-2 h-[46px] py-2 border ${errors.account ? 'border-v2-red-error-services-selected' : 'border-v2-border-time focus:border-v2-blue-text-login focus:shadow-md focus:shadow-v2-blue-text-login/30'} text-v2-input-text outline-none`}
                                                    {...register("bank_account", {
                                                        required: true,
                                                    })}
                                                />
                                                {errors.account?.type === 'required' && <span className='text-xs text-red-500'>El tipo de cuenta es un campo obligatorio</span>}
                                        </div>
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Tipo de cuenta:</dt>
                                                    <select
                                                        id="tabs"
                                                        name="tabs"
                                                        className="block h-[46px] w-full rounded-md border-v2-border-time focus:border-v2-blue-text-login focus:ring-v2-blue-text-login"
                                                        {...register("account_type", {
                                                            required: true,
                                                        })}
                                                            >
                                                            <option>CLABE</option>
                                                            <option>Cuenta</option>
                                                            <option>Tarjeta</option>
                                                    </select>
                                                    {errors.account_type?.type === 'required' && <span className='text-xs text-red-500'>El tipo de cuenta es un campo obligatorio</span>}

                                        </div>
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Banco destino:</dt>
                                                <input
                                                    placeholder={"Banco"}
                                                    {...register("bank", {
                                                        required: true,
                                                    })}                 
                                                    type="text"
                                                    inputMode='text'
                                                    className={`w-full rounded-md px-2 h-[46px] py-2 border ${errors.bank ? 'border-v2-red-error-services-selected' : 'border-v2-border-time focus:border-v2-blue-text-login focus:shadow-md focus:shadow-v2-blue-text-login/30'} text-v2-input-text outline-none`}
                                                />
                                                {errors.bank?.type === 'required' && <span className='text-xs text-red-500'>El banco del cliente es un campo obligatorio</span>}

                                        </div>
                                        <div className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-500">Fecha de devolución:</dt>
                                        <DateTimePicker setValue={setValue} register={register}/>
                                        {errors.return_date?.type === 'required' && <span className='text-xs text-red-500'>La fecha de devolucion es un campo obligatorio</span>}
                                </div>

                                    </dl>
                                    
                        </div> 
                                <div className='py-3'>
                                            <h3 className="pt-3 font-medium leading-6 text-gray-900 mb-2">Datos del emisor:</h3>
                                            <dl className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                                                <div className='col-span-2'>
                                                    <dt className="text-sm font-medium text-gray-500">Cuenta Origen:</dt>
                                                    <select
                                                        id="tabs"
                                                        name="tabs"
                                                        className="block h-[46px] w-full rounded-md border-v2-border-time focus:border-v2-blue-text-login focus:ring-v2-blue-text-login"
                                                        {...register("provider_account", {
                                                            required: true,
                                                        })}
                                                        value={refundSelect.find((tab) => tab.active)?.name}
                                                        onChange={e => {
                                                            var new_options = refundSelect.map((item)=>{
                                                                item.name == e.target.value ? (item.active = true):(item.active = false)
                                                                return item
                                                            })
                                                            setRefundSelect(new_options)}}
                                                            >
                                                        {refundSelect.map((tab) => (
                                                            <option key={tab.name}>{tab.name}</option>
                                                        ))}
                                                    </select>
                                                    {errors.provider_account?.type === 'required' && <span className='text-xs text-red-500'>La cuenta del proveedor es un campo obligatorio</span>}
                                                </div>
                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">Banco:</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">{refundSelect.find((tab) => tab.active)?.bank}</dd>
                                                </div>
                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">Cuenta:</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">{refundSelect.find((tab) => tab.active)?.account}</dd>
                                                </div>
                                            </dl>
                                    </div>
                                    <div className='py-3'>
                    <h3 className="pt-3 font-medium leading-6 text-gray-900 mb-2">Detalles de la devolución</h3>
                        <dl className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Monto de la devolución:</dt>
                                    <div className={`col-span-2 w-full px-2 h-[46px] border border-v2-border-time rounded-md text-base text-v2-text-bar-steps outline-none flex items-center justify-center ${errors.return_amount && 'border-red-400'}`}>
                                        <span className="text-v2-gray-title-client">$</span>
                                        <input
                                            {...register("return_amount", {
                                                required: true,
                                                min: 1,
                                            })}
                                            onKeyDown={(event) => {
                                                if (!/[0-9.]/.test(event.key) && event.key !== "Backspace") {
                                                  event.preventDefault();
                                                }
                                              }}
                                            placeholder={"Monto a devolver"}
                                            min={0}
                                            type="number"
                                            step={0.01}
                                            className="w-full border-none outline-none text-base pl-2 appearance-none"
                                        />
                                    </div>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Número de autorización:</dt>
                                        <input
                                            placeholder={"Número de autorización"}
                                            {...register("auth_number", {
                                                required: true,
                                            })}
                                            type="text"
                                            inputMode='text'
                                            className={`w-full rounded-md px-2 h-[46px] py-2 border ${errors.auth_number ? 'border-v2-red-error-services-selected' : 'border-v2-border-time focus:border-v2-blue-text-login focus:shadow-md focus:shadow-v2-blue-text-login/30'} text-v2-input-text outline-none`}
                                        />
                                        {errors.auth_number?.type === 'required' && <span className='text-xs text-red-500'>El numero de autorización es un campo obligatorio</span>}
                                </div>
                            </dl>
                        <div className="w-full py-2">
                            <div className="w-full text-sm font-medium text-gray-500">
                                Motivo de la devolución:
                                <textarea
                                    {...register("return_reason", {
                                        required: true,
                                    })}
                                    type="text"
                                    placeholder="Escribe el motivo de la devolución"
                                    maxLength={250}
                                    className={`w-full font-normal appearance-none h-[75px] py-2.5 mt-1.5 px-4 border rounded-md text-base text-v2-text-bar-steps outline-none flex items-center justify-center ${errors.return_reason ? "border-red-500":"border-v2-border-time"}`}
                                />
                                {errors.return_reason?.type === 'required' && <span className='text-xs text-red-500'>El motivo de la devolución es un campo obligatorio</span>}
                            </div>
                        </div>
                    <div className='w-full flex items-center justify-end'>
                    <div className='w-[7rem]'>
                                    <LinkButton onClick={() => {
                                        reset()
                                        setShowRefund(false)}}>
                                        Cancelar
                                    </LinkButton>
                    </div>
                    <div className='w-[15rem]'>
                                 <PrimaryButton  disabled={false} isFullWidth>
                                    Crear devolución<ReceiptRefundIcon className='w-5 h-5 ml-2 inline-block' />
                                </PrimaryButton>
                    </div>
                            </div>
                        
                    </div>
                                      
                             
                        </form>          
            </SlideOver>         
            {loading && <SecondaryLoader/>}
            {showAlert && <div className="w-1/2 h-screen fixed top-12 left-1/4 z-[60] ">
                <LongAlert show={ showAlert } onClose={()=>setShowAlert(false) } type={ alert.type } title={ alert.message } />
            </div> } 
            </>
    )
}


const RefundInput = ({item, setTotal, index})=>{
    const [refundAmount, setRefundAmount] = useState(item.price.total_amount/100)
    const [error, setError] = useState(false)

    useEffect(()=>{
        setTotal((total)=>({...total, [index]: parseFloat(refundAmount)}))
    },[refundAmount])
    
    
    useEffect(()=>{
            setError(refundAmount > item.price.total_amount/100)
    },[refundAmount])

    return <div className='grid grid-cols-5 gap-4 sm:grid-cols-5 items-center'>
        <div className='col-span-3 text-gray-500 text-sm font-medium'>
            {item.name}
        </div>
        <div className={`col-span-2 w-full px-2 h-[46px] mt-1.5 border border-v2-border-time rounded-md text-base text-v2-text-bar-steps outline-none flex items-center justify-center ${error && 'border-red-400'}`}>
            <span className="text-v2-gray-title-client">$</span>
            <input
                placeholder={item.price.formated_total}
                value={refundAmount}
                onInput={e => {setRefundAmount(e.target.value)}}
                type="number"
                min="0"
                className="w-full border-none outline-none text-base pl-2 appearance-none"
            />
        </div>
        {error && <>
            <span className='-mt-4 col-span-3 inline-block text-xs text-input-error'></span>
            <span className='-mt-4 col-span-2 inline-block text-xs text-input-error'>El monto no puede ser mayor al pagado</span>
        </>
            }
    </div>
}



RefundInput.propTypes = {
    item: PropTypes.object,
    setTotal: PropTypes.func,
    index: PropTypes.number
}
ReimbursmentModal.propTypes = {
    handleUpdate: PropTypes.func,
    account: PropTypes.object,
    paymentDetails: PropTypes.object,
    orderDetails: PropTypes.object,
    showRefund: PropTypes.bool,
    setShowRefund: PropTypes.func,
    setAlert: PropTypes.func,
    setPaymentDetails: PropTypes.func,
}

export default ReimbursmentModal;