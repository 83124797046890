import { useState } from 'react'
import { getQuoteS3 as downloadFile } from '../apiClient/operations/s3UploadOperations';
import useSleep from './useSleep';
import { saveAs } from 'file-saver';

const useDownloadFile = () => {

    const { sleep } = useSleep();
    const [documentsDownloading, setDocumentsDownloading] = useState([]);

    const download = async (key, bucket, documentName) => {

        if (!key || !bucket || documentsDownloading?.includes(key)) return;

        setDocumentsDownloading([
            ...documentsDownloading,
            key
        ]);
        try {
            const request = { 'id': key, 'bucket': bucket };
            let getFile = await downloadFile(request);
            let attemps = 0;
            let retry = getFile?.size <= 0;
            while (retry) {
                await sleep(1000);
                getFile = await downloadFile(request);
                attemps += 1;
                retry = getFile.size <= 0 && attemps < 30;
            }

            if (getFile.size > 0) {
                const name = documentName || key.split("/")?.pop();
                saveAs(getFile, name);
            } else {
                throw new Error('Hubo un problema al obtener el archivo, vuelve a intentarlo');
            }

            setDocumentsDownloading(prevDocuments => prevDocuments?.filter(doc => doc != key));
        } catch (error) {
            console.log(error);
            setDocumentsDownloading(prevDocuments => prevDocuments?.filter(doc => doc != key));
            throw new Error('Hubo un problema al obtener el archivo, vuelve a intentarlo');
        }
    }

    return [documentsDownloading, download]
}

export default useDownloadFile
