import React, { useEffect, useState, useContext } from 'react';
import { AlertContext } from '../context/AlertContext';
import Login from '../components/Login';
import Layout from '../components/Layout';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import SideBarRoutes from '../routes/SideBarRoutes';
import SideBar from '../components/SideBar';
import LongAlert from '../tailwindUI/LongAlert';
import { AuthContext } from '../cognito/AuthProvider';

const RoutesApp = () => {

    const { user } = useContext(AuthContext);
    const { alert } = useContext(AlertContext);
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        alert.active ? setShowAlert(true) : setShowAlert(false);
    }, [alert]);

    return (
        <>
            <BrowserRouter>
                <Layout>
                    <Switch>
                        <Route exact path="/login" component={Login} />
                        <div className="w-full h-full flex">
                            <SideBar user={user} />
                            <div className="bg-light-gray w-full min-h-screen">
                                <div className={`${showAlert ? 'lg:mt-0 mt-16' : ''} w-full sticky top-20 lg:top-3 z-[30]`}>
                                    <LongAlert show={showAlert} onClose={setShowAlert} type={alert.type} title={alert.message} />
                                </div>
                                <div className="w-full">
                                    <SideBarRoutes />
                                </div>
                            </div>
                        </div>
                    </Switch>
                </Layout>
            </BrowserRouter>
        </>
    );
}

export default RoutesApp;