import React, { useContext, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faCircleCheck, faCalendar, faTrash, faFileArchive } from '@fortawesome/free-solid-svg-icons';
import Button from '../../hooks/Button';
import SecondaryLoader from '../../hooks/SecondaryLoader';
import Modal from '../../hooks/Modal';
import { AlertContext } from '../../context/AlertContext';
import DatePicker, { utils } from 'react-modern-calendar-datepicker';
import CSVReader from 'react-csv-reader'
import ButtonOrange from '../../tailwindUI/ButtonOrange';
import { rfcRegex } from '../../hooks/useRegex';
import { CSVLink } from 'react-csv';
import PrimaryButton from '../../tailwindUI/PrimaryButton';
import { DocumentArrowDownIcon, InformationCircleIcon } from '@heroicons/react/24/solid';
import { updateNextDate, updateTaxCompliance, updateTaxStatus } from '../../apiClient/operations/fiscalStatusOperations';
import Dropdown from '../../hooks/Dropdown';
import useFormatDate from '../../hooks/useFormatDate';
import ReactTooltip from 'react-tooltip';
import ReactDOMServer from 'react-dom/server';

function UpdateDocumentsDate() {
    const [secondaryLoader, setSecondaryLoader] = useState(false);
    const [showTooltip, setShowTooltip] = useState(true);
    const [documentOption, setDocumentOption] = useState("32D");
    const [loadingCSV, setLoadingCSV] = useState(false);
    const [updatingTaxCompliance, setUpdatingTaxCompliance] = useState(false);
    const [updatingTaxStatus, setUpdatingTaxStatus] = useState(false);
    const fileInputRef = useRef();
    const [openConfirmUpdate, setOpenConfirmUpdate] = useState(false);
    const [selectedDay, setSelectedDay] = useState(utils().getToday());
    const [selectedFile, setSelectedFile] = useState([])
    const [taxpayerIds, setTaxpayerIds] = useState([])
    const [taxpayerIdsError, setTaxpayerIdsError] = useState([])
    const [errors, setErrors] = useState(null)
    const [tooltipTitle, setTooltipTitle] = useState("Ninguno")
    const [warning, setWarning] = useState(null)
    const [dataCsv, setDataCsv] = useState([]);
    const { formatDate } = useFormatDate();

    const headers = [
        'Email',
        'RFC',
        'Estado'
    ]
    const data = [
        "32D",
        "CSF",
        "32D y CSF"
    ]
    const castDocumentOption = {
        "32D": 0,
        "CSF": 1,
        "32D y CSF": 2
    }
    const castDocumentStatus = {
        "sucess": "Actualizado",
        "not_ciec": "Sin CIEC",
        "not_found_ciec": "Sin CIEC",
        "not_found": "No encontrado",
        "fail": "Error",
    }
    const { setAlert } = useContext(AlertContext);

    const verifyTaxIds = (data) => {
        const taxIdPosition = data[0].findIndex((header) => (header.toLowerCase() == "rfc"))
        let taxIds = []
        let taxIdsErr = []
        let errosTaxId = 0;
        for (let index = 1; index < data.length; index++) {
            const taxId = data[index][taxIdPosition];
            !rfcRegex(taxId) ? errosTaxId++ : taxIds.push(taxId)
            !rfcRegex(taxId) && taxIdsErr.push(taxId)
        }
        setTaxpayerIdsError(taxIdsErr)
        setTaxpayerIds(taxIds)
        return errosTaxId
    }

    useEffect(() => {
        if (taxpayerIdsError.length > 0) {
            let title = <> {taxpayerIdsError.map((item, index) =>(<>{index+1}.- {item}<br></br></>))}</>
            setTooltipTitle(title)
        }
    }, [taxpayerIdsError])
    const uploadFile = (data, fileInfo, originalFile) => {
        setSelectedFile([originalFile])
        console.log("fileInfo", fileInfo)

        const headers = data[0];

        if (data.length <= 1) {
            setErrors({ selectedFile: "El archivo no contiene información" })
        } else if (headers.find((header) => (header.toLowerCase() == "rfc")) == undefined) {
            setErrors({ selectedFile: "El archivo no contiene ningún campo RFC" })
        } else {
            const countErrors = verifyTaxIds(data)
            countErrors > 0 && setWarning({ selectedFile: `Hay ${countErrors} RFC incorrectos, considere esto si desea continuar con el proceso.` })
        }

    };

    const updateInformation = async () => {
        setSecondaryLoader(true);
        setLoadingCSV(true)
        try {
            const body = {
                tax_ids: taxpayerIds,
                next_date: formatDate({ ...selectedDay, month: selectedDay.month - 1 }, 'YYYY-MM-DD'),
                doc: castDocumentOption[documentOption]
            }
            const response = await updateNextDate(body)
            generateCSV(response)
            setSecondaryLoader(false)
            setOpenConfirmUpdate(true)
            console.log(body)
        } catch (error) {
            setSecondaryLoader(false)
            console.log(error)
            setAlert({ active: true, type: 'error', message: `Error al procesar tu solicitud` })
        }
    }

    const generateCSV = (data) => {
        let rows = []
        taxpayerIdsError.map((item) => {
            const row = [
                "",
                item,
                "RFC incorrecto"
            ]
            rows.push(row)
        })
        data.map((item, index) => {
            const row = [
                item?.email,
                item?.tax_id,
                castDocumentStatus[item?.status]
            ]
            rows.push(row)
            index == data.length - 1 && setLoadingCSV(false)
        })

        setDataCsv(rows)
    }
    const handleDeleteFile = () => {
        setSelectedFile([])
        setErrors(null)
        setWarning(null)
        setTaxpayerIds([])
        fileInputRef.current.value = ''
    }

    const updateDocumentsTaxCompliance = async () => {
        setSecondaryLoader(true)
        try {
            await updateTaxCompliance()
            setUpdatingTaxCompliance(true)
            setSecondaryLoader(false)
            setAlert({ active: true, type: 'success', message: `El proceso se ha iniciado correctamente` })
        } catch (error) {
            console.log(error)
            setAlert({ active: true, type: 'error', message: `Error al procesar tu solicitud` })
            setSecondaryLoader(false)
        }
    }

    const updateDocumentsTaxStatus = async () => {
        setSecondaryLoader(true)
        try {
            await updateTaxStatus()
            setUpdatingTaxStatus(true)
            setSecondaryLoader(false)
            setAlert({ active: true, type: 'success', message: `El proceso se ha iniciado correctamente` })
        } catch (error) {
            console.log(error)
            setAlert({ active: true, type: 'error', message: `Error al procesar tu solicitud` })
            setSecondaryLoader(false)
        }
    }

    const tooltipRFC = (title) => (
        <>
            {showTooltip &&
                <ReactTooltip
                    id={"errorRfc"}
                    place="top"
                    effect='solid'/>
            }
            <InformationCircleIcon
                className='cursor-pointer w-5 h-5 text-gray-300'
                data-for={"errorRfc"}
                data-html={true}
                data-tip={ReactDOMServer.renderToString(title)}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => {
                    setShowTooltip(false);
                    setTimeout(() => setShowTooltip(true), 50);
                }} />
        </>
    )
    return (
        <>
            {secondaryLoader && <SecondaryLoader />}
            <div className="w-full h-full">
                <div className="w-full xs:h-full md:h-full">
                    <div className="mt-10 md:mt-0 w-full px-5 py-5  xs:h-auto md:h-1/12">
                        <div className=" justify-between items-center w-full">
                            <h1 className="hidden md:flex text-4xl font-bold text-left">
                                Documentos Situación Fiscal
                            </h1>
                        </div>
                    </div>
                    <div className='w-full'>
                        <div className="w-full h-full border border-v2-gray-border-tables rounded-10 bg-white xs:mt-0 md:mt-3">
                            <div className='w-full xs:px-2 lg:px-4 py-4 border-b border-v2-gray-border-tables flex items-center '>
                                <div className='xs:w-11/12 md:w-10/12 text-xl font-semibold text-v2-input-text'>
                                    Fecha de actualización documentos situación fiscal
                                </div>
                            </div>
                            <div className="my-6 xs:px-3 md:px-4">
                                <div className='w-full flex gap-0 flex-col sm:flex-row sm:gap-3'>
                                    <div className="xs:mt-0 md:mt-2 w-full">
                                        <label htmlFor="name" className="text-base text-v2-input-text">
                                            Fecha de actualización
                                        </label>
                                        <div className='w-full relative my-1'>
                                            <DatePicker
                                                locale='en'
                                                minimumDate={utils().getToday()}
                                                wrapperClassName='block z-10 responsive-calendar'
                                                inputClassName='w-full z-10 appearance-none cursor-pointer text-left py-1 px-4 font-normal border border-v2-border-time rounded-md text-base text-v2-input-text outline-none responsive-calendar'
                                                calendarClassName='block font-normal text-v2-input-text'
                                                calendarPopperPosition='bottom'
                                                colorPrimary='#2169AC'
                                                colorPrimaryLight='#D9E2EC'
                                                value={selectedDay}
                                                onChange={setSelectedDay}
                                            />
                                            <FontAwesomeIcon icon={faCalendar} className='absolute w-5 z-10 h-5 right-2 top-[50%] -translate-y-1/2 text-primary' />
                                        </div>
                                    </div>
                                    <div className="xs:mt-0 md:mt-2 w-full flex flex-col">
                                        <label htmlFor="name" className="text-base text-v2-input-text">
                                            Documento de actualización
                                        </label>
                                        <Dropdown className={'mt-1'} defaultTitle={documentOption} data={data} dropChange={(value) => setDocumentOption(value)} />
                                    </div>
                                    <CSVReader onFileLoaded={uploadFile} ref={fileInputRef} cssClass='hidden' />
                                    {
                                        selectedFile.length == 0 ?
                                            <div className="xs:mt-0 md:mt-2 w-full flex flex-col gap-1">
                                                <label htmlFor="email" className="text-base text-v2-input-text">
                                                    Seleccionar csv
                                                </label>
                                                <ButtonOrange onClick={() => { fileInputRef.current.click() }}>
                                                    Subir CSV
                                                </ButtonOrange>
                                            </div> :
                                            <div className="w-full px-7 py-4 items-center mt-4">
                                                <div className="w-full flex items-center py-2">
                                                    <div className="w-3/4 flex items-center">
                                                        <FontAwesomeIcon icon={faFileArchive} className="text-v2-gray-light-plus-circle text-3xl leading-3 " />
                                                        <span className="text-v2-text-bar-steps text-[15px] pl-7">
                                                            {selectedFile[0].name}
                                                        </span>
                                                    </div>
                                                    <div className="w-1/4 flex justify-end text-v2-yellow-edit-info">
                                                        <FontAwesomeIcon icon={faTrash} className="leading-3 text-2xl cursor-pointer" onClick={() => handleDeleteFile()} />
                                                    </div>
                                                </div>
                                                {errors?.selectedFile && <span className="block pt-0.5 text-v2-red-error-services-selected">{errors?.selectedFile}</span>}
                                                {warning?.selectedFile && <div className="flex flex-row gap-1 pt-0.5 text-yellow-800"><span className='w-11/12'>{warning?.selectedFile}</span> {tooltipRFC(tooltipTitle)}</div>}
                                            </div>
                                    }
                                </div>
                                <div className='w-full flex justify-center mt-4'>
                                    <div className='xs:w-full md:w-1/2 lg:w-1/3'>
                                        <Button disabled={!(selectedFile.length > 0 && errors == null)} heigth='h-12' bg='bg-v2-blue-text-login' onClick={updateInformation}>
                                            Actualizar
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='w-full mt-4 md:mt-0'>
                        <div className="w-full h-full border border-v2-gray-border-tables rounded-10 bg-white xs:mt-0 md:mt-3">
                            <div className='w-full xs:px-2 lg:px-4 py-4 border-b border-v2-gray-border-tables flex items-center '>
                                <div className='xs:w-11/12 md:w-10/12 text-xl font-semibold text-v2-input-text'>
                                    Actualización de Documentos
                                </div>
                            </div>
                            <div className="my-6 xs:px-3 md:px-4 flex flex-col md:flex-row items-center justify-center gap-3 md:gap-10">
                                <div className='w-full flex justify-end '>
                                    {updatingTaxCompliance ? (
                                        <div className=" px-4 py-5 sm:px-6 text-center ">
                                            <div className='text-gray-900 font-medium text-xl text-center'>
                                                Actualizando opinión de cumplimiento...
                                            </div>
                                            <div className='text-gray-600 mt-2 md:mx-[20%]'>
                                                Se actualizaran los documentos fiscales con fecha de actualización de hoy.
                                            </div>
                                        </div>
                                    ) :
                                        (<div className='w-full md:w-3/4'>
                                            <Button heigth='h-12' bg='bg-v2-blue-text-login' onClick={updateDocumentsTaxCompliance}>
                                                Actualizar 32D
                                            </Button>
                                        </div>)
                                    }
                                </div>
                                <hr className='h-24 w-1 bg-gray-300 hidden md:block' />
                                <hr className='h-[2px] w-full bg-gray-300 block md:hidden' />
                                <div className='w-full flex justify-start'>
                                    {updatingTaxStatus ? (
                                        <div className="px-4 py-5 sm:px-6 text-center ">
                                            <div className='text-gray-900 font-medium text-xl text-center'>
                                                Actualizando constancia de situación fiscal...
                                            </div>
                                            <div className='text-gray-600 mt-2 md:mx-[20%]'>
                                                Se actualizaran los documentos fiscales con fecha de actualización de hoy.
                                            </div>
                                        </div>
                                    ) :
                                        (<div className='w-full md:w-3/4'>
                                            <Button heigth='h-12' bg='bg-v2-blue-text-login' onClick={updateDocumentsTaxStatus}>
                                                Actualizar CSF
                                            </Button>
                                        </div>)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >

            <Modal hasTitle={false} modalOpen={openConfirmUpdate} modalOpenChange={setOpenConfirmUpdate} maxWidth="max-w-lg">
                <div className="w-full pt-2 pb-5 px-[9px]">
                    <div className="w-full flex justify-end">
                        <span className="w-full flex justify-end text-v2-gray-light-plus-circle leading-3 text-2xl cursor-pointer" onClick={() => setOpenConfirmUpdate(!openConfirmUpdate)}>
                            <FontAwesomeIcon icon={faCircleXmark} />
                        </span>
                    </div>
                    <div className="w-full mt-4">
                        <span className="flex justify-center text-v2-border-alert-success leading-3 text-5xl">
                            <FontAwesomeIcon icon={faCircleCheck} />
                        </span>
                    </div>
                    <div className="w-full mt-5">
                        <span className="w-full flex justify-center text-v2-gray-title-service-modal text-xl text-center font-bold">
                            <span className='xs:w-full md:w-2/4'>
                                Fechas actualizadas correctamente
                            </span>
                        </span>
                        <span className="w-full flex justify-center text-v2-gray-title-service-modal text-base text-center font-normal mt-3">
                            <span className='sm:w-5/6 w-2/4'>
                                El proceso termino exitosamente, para ver si todos los correos fueron actualizados descarga el CSV.
                            </span>
                        </span>
                    </div>
                    <div className="mt-6 px-4">
                        <CSVLink data={dataCsv} headers={headers} filename={`Actualización_fecha(${selectedDay.day}-${selectedDay.month}-${selectedDay.year}).csv`}>
                            {!loadingCSV ?
                                <PrimaryButton isFullWidth>
                                    Descargar CSV <DocumentArrowDownIcon className='w-5 h-5 ml-2 inline-block' />
                                </PrimaryButton>
                                :
                                <PrimaryButton disabled isFullWidth>
                                    Cargando... <DocumentArrowDownIcon className='w-5 h-5 ml-2 inline-block' />
                                </PrimaryButton>
                            }

                        </CSVLink>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default UpdateDocumentsDate