import React from 'react';
import { Router } from 'react-router-dom';
import RoutesApp from './routes/Routes';
import { createBrowserHistory } from 'history';
import './index.css';
import { AuthProvider } from './cognito/AuthProvider';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ENV } from './config';
import { AlertProvider } from './context/AlertContext';

function App() {
    const history = createBrowserHistory();

    return (
        <HelmetProvider>
            <AuthProvider>
                <Router history={history}>
                    <AlertProvider>
                        <RoutesApp />
                    </AlertProvider>
                    <Helmet>
                        {ENV == 'PRODUCTION' ? (
                            <script type="text/javascript" src={`${process.env.PUBLIC_URL}/newRelicProduction.js`} />
                        ) : (
                            <>
                                <script type="text/javascript" src={`${process.env.PUBLIC_URL}/newRelicSandbox.js`} />
                                <script type="text/javascript" src="https://kea-collective.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/azc3hx/b/7/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=d691ba3b"></script>
                            </>
                        )}
                    </Helmet>
                </Router>
            </AuthProvider>
        </HelmetProvider>
    );
}

export default App;