import React, { useEffect, useState } from 'react';
import { CheckCircleIcon, ChevronDownIcon, ShoppingCartIcon } from '@heroicons/react/24/solid';
import Badge from '../../tailwindUI/Badge';
import { formatPeso } from '../../util';
import useDatesDisplay from '../../hooks/useDatesDisplay';
import { Transition } from '@headlessui/react';
import CustomAdvice from '../../hooks/CustomAdvice';
import PropTypes from 'prop-types';
import useFormatDate from '../../hooks/useFormatDate';

const paymentTypes = {
    "unique": "Pago único",
    "partial": "Cobros diferidos",
    "subscription": "Suscripción"
}

function AdditionalService({ service, onAdvancePaymentClick }) {

    const [isOpenDetails, setIsOpenDetails] = useState(false);
    const [coveredPeriods, setCoveredPeriods] = useState([]);
    const [periodsToCover, setPeriodsToCover] = useState([]);
    const { formatDate } = useFormatDate();

    useEffect(() => {
        const paidPeriods = service?.payments?.periods?.map(payment => payment.service_period);
        setCoveredPeriods(useDatesDisplay(paidPeriods));
        const remainingPeriods = service?.periods?.filter(period => !paidPeriods?.includes(period));
        setPeriodsToCover(useDatesDisplay(remainingPeriods));
    }, [service]);

    return (
        <>
            <div className='w-full bg-white rounded-md border border-gray-300 overflow-hidden'>
                <div className='flex flex-col md:flex-row justify-between gap-4 p-3 md:px-6'>
                    <div className='flex md:items-center gap-3'>
                        <div className='bg-gray-100 h-full rounded-lg p-2'>
                            <ShoppingCartIcon className='w-6 h-6 md:w-5 md:h-5 text-gray-500' />
                        </div>
                        <span className='text-gray-900'>
                            {service?.name}
                            <span className='block md:hidden text-sm text-gray-900 font-semibold'>
                                {formatPeso((service?.price?.total_amount || 0) / 100)}
                            </span>
                        </span>
                    </div>
                    <div className='flex items-center gap-4'>
                        <Badge text={service?.status} />
                        <span className='hidden md:block text-sm text-gray-900 font-semibold'>
                            {formatPeso((service?.price?.total_amount || 0) / 100)}
                        </span>
                    </div>
                </div>
                <div className='border-t border-gray-300 flex flex-col md:flex-row justify-between gap-4 p-3 md:p-6'>
                    <div className='w-full'>
                        <div className='flex justify-between items-end'>
                            <span className='text-gray-900 text-lg'>
                                {formatPeso((service?.payments?.total_paid || 0) / 100)}
                                <span className='text-xs'>
                                    {` / ${formatPeso((service?.price?.total_amount || 0) / 100)}`}
                                </span>
                            </span>
                            <span className='text-gray-900 text-sm'>
                                {Math.round((service?.payments?.compliance_percentage || 0) * 100)}%
                            </span>
                        </div>
                        <div className="overflow-hidden rounded-full bg-gray-200">
                            <div className="transition-all duration-300 h-2 rounded-full bg-blue-sky"
                                style={{
                                    maxWidth: `${Math.round((service?.payments?.compliance_percentage || 0) * 100)}%`
                                }} />
                        </div>
                    </div>
                    {/* {(onAdvancePaymentClick && service?.status != 'completed') && (
                        <button
                            className='min-w-[9rem] border border-blue-sky rounded-md px-3 py-1.5 text-blue-sky'
                            onClick={() => onAdvancePaymentClick && onAdvancePaymentClick(service)}>
                            Adelantar pago
                        </button>
                    )} */}
                </div>
                <div className=''>
                    <div
                        className='border-tb border-indigo-300 bg-indigo-50 flex items-center justify-between gap-4 p-3 md:px-6 cursor-pointer'
                        onClick={() => setIsOpenDetails(!isOpenDetails)}>
                        <span className='text-gray-900'>
                            Detalles
                        </span>
                        <ChevronDownIcon className={`${isOpenDetails ? 'rotate-180' : 'rotate-0'} transition-all w-5 h-5`} />
                    </div>
                    <Transition
                        show={isOpenDetails}
                        enter="transition-all ease-in"
                        enterFrom="max-h-0 opacity-0"
                        enterTo="max-h-[60rem] opacity-100"
                        leave="transition-all ease-out"
                        leaveFrom="max-h-[60rem] opacity-100"
                        leaveTo="max-h-0 opacity-0">
                        <div className='w-full p-3 md:p-6'>
                            {service.status == 'suspended' && (
                                <div className='mb-4'>
                                    <CustomAdvice
                                        type='Warning'
                                        message={
                                            <span>
                                                Tienes un adeudo de {service.payment_dates?.filter(date => date.status == 'pending')?.length || 0} periodos por lo cual este servicio está suspendido. &nbsp;
                                                <button
                                                    type='button'
                                                    onClick={() => onAdvancePaymentClick && onAdvancePaymentClick(service)}
                                                    className='underline'>
                                                    Realiza tu pago.
                                                </button>
                                            </span>
                                        }
                                    />
                                </div>
                            )}
                            <div className='flex justify-between'>
                                <span className='text-gray-900'>
                                    Periodos a cubrir
                                </span>
                                <span className='text-gray-900'>
                                    Cantidad {service?.quantity}
                                </span>
                            </div>
                            <div className="w-full mt-3">
                                <div className="w-full flex gap-3 flex-wrap max-w-full">
                                    {coveredPeriods?.map((date, i) => (
                                        <div key={i} className="w-auto border border-emerald-300 bg-emerald-100 font-medium py-1.5 text-xs rounded flex items-center px-3">
                                            <div className="w-full flex gap-2 items-center text-center min-w-[45px] text-emerald-800">
                                                {service?.periodicity == 'yearly' ? date.year : date.tag}
                                                <CheckCircleIcon className='w-4 h-4 text-emerald-400' />
                                            </div>
                                        </div>
                                    ))}
                                    {periodsToCover?.map((date, i) => (
                                        <div key={i} className="w-auto border border-indigo-300 bg-indigo-50 font-medium py-1.5 text-xs rounded flex items-center px-3">
                                            <div className="w-full text-center min-w-[45px] text-indigo-900">
                                                {service?.periodicity == 'yearly' ? date.year : date.tag}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className='mt-6'>
                                <span className='text-gray-900'>
                                    Acuerdos de pago
                                </span>
                                <div className='w-full mt-3 flex flex-col md:flex-row gap-4'>
                                    <dl className='w-full bg-indigo-50 border border-indigo-200 rounded-lg p-4 grid grid-cols-1 lg:grid-cols-2 gap-2 flex-grow'>
                                        <div className='col-span-1'>
                                            <dt className='text-indigo-900 text-sm'>
                                                Condiciones de cobro
                                            </dt>
                                            <dd className='text-indigo-300 text-sm'>
                                                {paymentTypes[service?.payment_type]}
                                            </dd>
                                        </div>
                                        {service?.payment_type == 'partial' && (
                                            <div className='col-span-1'>
                                                <dt className='text-indigo-900 text-sm'>
                                                    Instrucciones de cobro
                                                </dt>
                                                <dd className='text-indigo-300 text-sm'>
                                                    {service?.payment_dates?.length || 0} parcialidades
                                                </dd>
                                            </div>
                                        )}
                                        <div className='col-span-1'>
                                            <dt className='text-indigo-900 text-sm'>
                                                Fecha de contratación
                                            </dt>
                                            <dd className='text-indigo-300 text-sm'>
                                                {formatDate(service.created, 'DD MMMM, YYYY')}
                                            </dd>
                                        </div>
                                    </dl>
                                    {service?.payment_dates && (
                                        <dl className='w-full bg-indigo-50 border border-indigo-200 rounded-lg p-4'>
                                            <div className='w-full'>
                                                <dt className='text-indigo-900 text-sm'>
                                                    Fechas de cobro
                                                </dt>
                                                <dd className='text-indigo-300 text-sm'>
                                                    <ul role="list" className="mt-2 w-full space-y-4">
                                                        {service?.payment_dates?.map((payment, i) =>
                                                            <li key={i} className="flex flex-row gap-3 items-center text-indigo-900">
                                                                {payment.date == 'Now' ? 'Primer pago' : formatDate(payment.date, 'DD/MM/YY')} - {formatPeso(payment.amount / 100)}
                                                                <Badge text={payment.status} />
                                                            </li>
                                                        )}
                                                    </ul>
                                                </dd>
                                            </div>
                                        </dl>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Transition>
                </div>
            </div>
        </>
    )
}
AdditionalService.propTypes = {
    service: PropTypes.object,
    onAdvancePaymentClick: PropTypes.func
}

export default AdditionalService;