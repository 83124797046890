import apiClient from '../apiClient';
import { apiBaseUrl, apiNodeBaseUrl } from '../../config';

export const translateEmail = email => {
    const formatedAtEmail = email ? email.replace('@', '%40') : '';
    const formatedPlusEmail = formatedAtEmail.replace('+', '%2B');
    return formatedPlusEmail
}

export const getPlanService = async (accountId) => {
    return apiClient.get(`${apiBaseUrl}/taxpayer-account/plan/${accountId}`)
    .then(response => { return response })
}

export const getTaxpayers = async () => {
    return apiClient.get(`${apiNodeBaseUrl}accounts/taxpayers`, {})
    .then(response => { return response });
}

export const uploadTaxpayersWorkflow = async (taxpayers) => {
    return apiClient.post(`${apiBaseUrl}/taxpayer-account/service/taxpayer/service-workflow`, {
        "taxpayers": taxpayers
    })
    .then(response => { return response })
}

export const getAccounts = async (page, limit, search, sort, accountTypes) => {
    const searchString = search?.includes('@') ? translateEmail(search) : search;
    const accountTypesString = accountTypes ? ` $AND account_type~${accountTypes?.join('|')}` : '';
    const url = `${apiBaseUrl}/accounts/admin/search?page=${page}&limit=${limit}&q=wildCard~${searchString || ''}${accountTypesString ? accountTypesString : ''}&${ sort || 'field=created&order=-1'}`;
    return apiClient.get(url)
    .then(response => { return response });
}

export const updateGeneralData = async (data, id_account) => {
    return apiClient.put(`${apiBaseUrl}/accounts/admin/${id_account}`, data)
    .then(response => { return response })
}

export const updateInvoicingData = async (data, id_account) => {
    return apiClient.put(`${apiBaseUrl}/accounts/admin/${id_account}/billing`, data)
    .then(response => { return response })
}

export const updateDocument = async (data, id_account) => {
    return apiClient.put(`${apiBaseUrl}/accounts/admin/${id_account}/documents`, data)
    .then(response => { return response })
}

export const getAccount = async (id) => {
    return apiClient.get(`${apiBaseUrl}/accounts/admin/${id}`, {})
    .then(response => { return response });
}

export const searchAccountByEmail = async (email) => {
    return apiClient.get(`${apiBaseUrl}/accounts/admin/search?q=email=${encodeURIComponent(email)}`, {})
    .then(response => { return response });
}

export const searchOrderByAccount = async (accountId) => {
    return apiClient.get(`${apiBaseUrl}/orders/admin/search?q=account=${accountId}`, {})
    .then(response => { return response });
}

export const updateAccountStatus = async (accountId, status) => {
    return apiClient.post(`${apiBaseUrl}/accounts/status/${accountId}`, {
        "status": status
    })
    .then(response => { return response })
}
