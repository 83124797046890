import React, { useEffect, useState } from "react";
import {
  createRegime,
  getRegimes,
  updateRegimeStatus,
} from "../apiClient/operations/regimesOperations";
import { getTaxRegimeList } from "../apiClient/operations/sat";

import MenuButton from "../tailwindUI/MenuButton";
import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";

export function useRegime({ account }) {
  const accountId = account?.id;
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [regimes, setRegimes] = useState([]);
  const [regimesList, setRegimesList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [dataToUpdate, setDataToUpdate] = useState(null);

  const actionColums = [
    {
      id: 1,
      name: "Activar regímen",
      action: (item) => {
        if (item.status === "active") return;
        setShowModal(true);
        setDataToUpdate({
          regime_id: item?.regime_id,
          status: "active",
        });
      },
    },
    {
      id: 2,
      name: "Desactivar regímen",
      action: (item) => {
        if (item.status === "inactive") return;
        setShowModal(true);
        setDataToUpdate({
          regime_id: item?.regime_id,
          status: "inactive",
        });
      },
    },
  ];

  const getRegimeActions = (regime) => {
    const regimesActions = [
      {
        id: 1,
        name: "Activar regímen",
        hidden: regime?.status == "active",
        action: () => {
          setShowModal(true);
          setDataToUpdate({
            regime_id: regime?.tax_regime?.regime_id,
            status: "active",
          });
        },
      },
      {
        id: 2,
        name: "Desactivar regímen",
        hidden: regime?.status == "inactive",
        action: () => {
          setShowModal(true);
          setDataToUpdate({
            regime_id: regime?.tax_regime?.regime_id,
            status: "inactive",
          });
        },
      },
    ]

    return regimesActions;
  };

  const getRegimesData = async () => {
    try {
      setIsLoading(true);
      const response = await getRegimes(accountId);
      const regimes = response.data.map((regime) => ({
        ...regime.tax_regime,
        id: regime.id,
        type: (regime.tax_regime.type.find((type) =>
          type == account?.legal_type
        )) == "personal" ? "Persona Física" : "Persona Moral",
        status: regime?.status || "",
        actions: (
          <div className="flex lg:justify-end lg:pr-12">
            <MenuButton
              items={getRegimeActions(regime)}
              itemW={280}
            >
              <EllipsisVerticalIcon className="w-6 h-6 text-gray-900" />
            </MenuButton>
          </div>
        ),
      }));

      setRegimes(regimes);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getRegimesList = async () => {
    const response = await getTaxRegimeList();
    const list = response.map((regime) => ({
      id: regime.id,
      description: regime.descripcion,
    }));
    setRegimesList(list);
  };

  const updateRegime = async (data) => {
    try {
      await updateRegimeStatus(accountId, data);
      await getRegimesData();
    } catch (error) {
      setError(error);
    }
  };

  const addNewRegime = async (regimeId) => {
    try {
      setIsLoading(true);
      await createRegime(accountId, {
        regime_id: regimeId,
      });
      await getRegimesData();
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (accountId) {
      getRegimesData();
    }
  }, [accountId]);

  useEffect(() => {
    getRegimesList();
  }, []);

  return {
    regimes,
    regimesList,
    actionColums,
    dataToUpdate,
    getRegimeActions,
    error,
    isLoading,
    updateRegime,
    addNewRegime,
    showModal,
    setShowModal,
  };
}
