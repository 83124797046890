import React, { useState, useContext, useRef } from 'react'
import { getQuoteS3 } from '../apiClient/operations/s3UploadOperations';
import { useHistory } from 'react-router-dom';
import { saveAs } from 'file-saver';
import PropTypes from 'prop-types';
import Button from './Button';
import { AlertContext } from '../context/AlertContext';
import SecondaryLoader from './SecondaryLoader';
import { getAllQuotes } from '../apiClient/operations/quotes';
import { getAccount } from '../apiClient/operations/accountsOperations';
import useScrollPosition from './useScrollPosition';
import useFormatDate from './useFormatDate';
import useConvertUnixToDate from './useConvertUnixToDate';
import useFormatterCurrency from './useFormatterCurrency';

function ThankYouPageReadOnly({quoteDocument, quote}) {
    let history = useHistory();
    const { formatterCurrency } = useFormatterCurrency();
    const { formatDate } = useFormatDate();
    const { convertUnixToDate } = useConvertUnixToDate();
    const setScrollPosition = useScrollPosition();
    const cycle = useRef('');
    const { setAlert } = useContext(AlertContext);
    const [secondaryLoader, setSecondaryLoader] = useState(false);
    const [finished, setFinished] = useState(false);
    const [loader, setLoader] = useState(false);
    const handleGetQuote = async () => {
        try {
            if (quoteDocument.key !== '') {
                setLoader(true);
                setFinished(false);
                const getFile = await getQuoteS3({'id': quoteDocument.key, 'bucket': quoteDocument.bucket});
                if (getFile.size > 0) {
                    const name = quoteDocument.key.split('/').slice(-1)[0];
                    saveAs(getFile, name);
                    setLoader(false);
                } else {
                    let cont = 0;
                    const interval = setInterval(async () => {
                        const loopGetFile = await getQuoteS3({'id': quoteDocument.key, 'bucket': quoteDocument.bucket});
                        if(loopGetFile.size > 0 && cycle.current == ''){
                            setFinished(true);
                            const name = quoteDocument.key.split('/').slice(-1)[0];
                            saveAs(loopGetFile, name);
                            cycle.current = 'finish';
                            clearInterval(interval);
                            setLoader(false);
                        } else {
                            cont++;
                            if (cont == 30) {
                                setFinished(true)
                                clearInterval(interval)
                                setLoader(false);
                                setAlert({active: true, type: 'error', message: 'Hubo un problema al obtener el archivo, vuelve a intentarlo'})
                            }
                            setFinished(false);
                        }
                    }, 1000);
                    finished && clearInterval(interval);
                    setFinished(false);
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleClick = async () => {
        try {
            setSecondaryLoader(true);
            const res = await getAllQuotes();
            const selected = res.data.filter(x => x.id == quote.id)[0];
            localStorage.setItem("quote", JSON.stringify(selected));
            const account = await getAccount(selected.account.id);
            localStorage.setItem("account", JSON.stringify(account));
            history.push('/view-quote');
            setScrollPosition(0);
            setSecondaryLoader(false);
        } catch (error) {
            setAlert({active: true, type: 'error', message: 'Error al procesar tu solicitud'})
        }
    }

    return (
        <div className="w-full">
            {secondaryLoader && <SecondaryLoader />}
            <div className="w-full border border-v2-gray-border-tables rounded-10 py-6 px-2 bg-white flex items-center">
                <div className="xs:w-full md:w-7/12 px-4">
                    <div className="w-full xs:text-center md:text-left text-v2-border-alert-success text-1.5xl font-bold leading-8 tracking-wide">
                        ¡Felicidades!
                        <span className="block">
                            Terminaste el proceso.
                        </span>
                    </div>
                    <div className="w-full xs:text-center md:text-left text-base text-v2-input-text opacity-60 leading-4 py-2">
                        Aquí tienes un resúmen de la cotización generada. 
                    </div>
                    <div className='xs:w-full lg:w-4/6 mt-4 border border-dashed border-v2-gray-border-tables rounded-10 p-4'>
                        <div className='text-v2-input-text opacity-60'>
                            Prospecto:
                            <span className='pl-1 font-semibold'>
                                { quote.account_info == undefined ? '' : quote.account_info.name }
                            </span>
                        </div>
                        <div className='text-v2-input-text opacity-60 pt-1'>
                            Folio:
                            <span className='pl-1 font-semibold'>
                                { quote.folio == undefined ? '' : quote.folio }
                            </span>
                        </div>
                        <div className='text-v2-input-text opacity-60 pt-1'>
                            Fecha de vencimiento:
                            <span className='pl-1 font-semibold'>
                                { quote.expires_at == undefined ? '' : formatDate(convertUnixToDate(quote.expires_at), 'DD MMMM YYYY') }
                            </span>
                        </div>
                        <div className='text-v2-input-text opacity-60 pt-1'>
                            Monto total:
                            <span className='pl-1 font-semibold'>
                            { quote.price == undefined ? '' : formatterCurrency((quote.price.total_amount)/100) }
                            </span>
                        </div>
                    </div>
                    <div className='xs:w-full lg:w-4/6 mt-4'>
                        <Button heigth='h-12' bg='bg-v2-blue-text-login' disabled={ loader ? true : false } onClick={ handleGetQuote }>
                            { loader ?
                                <div className="w-7 h-7 rounded-full flex justify-center text-white">
                                    <svg role="status" className="inline w-7 h-7 animate-spin fill-v2-blue-text-login" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                    </svg>
                                </div>
                                :
                                <span>Descargar cotización</span>
                            }
                        </Button>
                        <div className='w-full text-center mt-3 text-v2-blue-text-login underline cursor-pointer' onClick={ handleClick }>
                            Ver cotización
                        </div>
                    </div>
                </div>
                <div className="xs:hidden md:block w-5/12 text-center">
                    <div className="w-full flex justify-center">
                        <div className="w-80 text-center">
                            <img 
                            src="https://cdn-fixat-mx.s3.amazonaws.com/intranet/fixi_thankyou.png"
                            alt="Fixi - Thank you"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

ThankYouPageReadOnly.propTypes = {
    quoteDocument: PropTypes.any,
    quote: PropTypes.any
}

export default ThankYouPageReadOnly