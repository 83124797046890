import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCircleUser, faLink, faCopy, faCreditCard, faMoneyBill1Wave, faMoneyBillTransfer, faFileInvoiceDollar, faReceipt } from '@fortawesome/free-solid-svg-icons';
import { faPaypal } from '@fortawesome/free-brands-svg-icons';
import { getPaymentLinkById } from '../../apiClient/operations/paymentLinksOperations';
import Nav from '../../hooks/Nav';
import HeadersDataTables from '../../hooks/HeadersDataTables';
import useConvertUnixToDate from '../../hooks/useConvertUnixToDate';
import useGetStatusExpired from '../../hooks/useGetStatusExpired';
import useFormatDate from '../../hooks/useFormatDate';
import SecondaryLoader from '../../hooks/SecondaryLoader';
import { AlertContext } from '../../context/AlertContext';
import useFormatterCurrency from '../../hooks/useFormatterCurrency';
import BreadCrumb from '../../tailwindUI/BreadCrumb';
import { getOrder } from '../../apiClient/operations/ordersOperations';
import { getAccountSubs, getBalances } from '../../apiClient/operations/subscriptionOperations';

function ViewLinks() {

    const linkStatus = {
        "pending": "Activo",
        "paid": "Pagado",
        "expired": "Expirado",
        "canceled": "Cancelado"
    }

    const linkStatusColor = {
        "pending": "bg-v2-blue-status",
        "paid": "bg-v2-green-status",
        "expired": "bg-v2-gray-status",
        "canceled": "bg-v2-red-status"
    }

    const paymentInterval = {
        'week': {
            1: 'Semanal'
        },
        'half_month': {
            1: 'Quincenal'
        },
        'month': {
            1: 'Mensual',
            2: 'Bimestral',
            3: 'Trimestral',
            6: 'Semestral',
            12: 'Anual',
        },
        'year': {
            1: 'Anual'
        }
    }

    const roadMap = [
        { name: 'Links de cobro', url: '/payment-links', current: false },
        { name: 'Detalle de link de cobro', url: '/payment-links/view', current: true },
    ];

    const paymentMethods = [
        {
            id: 1,
            value: "card",
            name: "Tarjeta de crédito o débito",
            shortName: "Tarjeta",
            icon: faCreditCard,
            imageUrl: "https://cdn.fixat.mx/assets/img/no-webp/aceptamos-pago-con-tarjeta.png"
        },
        {
            id: 2,
            value: "paypal",
            name: "Pago con Paypal",
            shortName: "Paypal",
            icon: faPaypal,
            imageUrl: "https://cdn.fixat.mx/assets/img/no-webp/paypal.png"
        },
        {
            id: 3,
            value: "cash",
            name: "Pago en fectivo",
            shortName: "Efectivo",
            icon: faMoneyBill1Wave,
            imageUrl: "https://cdn.fixat.mx/assets/img/no-webp/oxxo_short.svg"
        },
        {
            id: 4,
            value: "transfer",
            name: "Transferencia bancaria",
            shortName: "Transferencia",
            icon: faMoneyBillTransfer,
            imageUrl: "https://cdn.fixat.mx/assets/img/no-webp/spei.png"
        }
    ];

    let history = useHistory();
    const user = JSON.parse(localStorage.getItem('user')) || {};
    const { formatterCurrency } = useFormatterCurrency();
    const { formatDate } = useFormatDate();
    const { convertUnixToDate } = useConvertUnixToDate();
    const { getStatusExpired } = useGetStatusExpired();
    const { id } = useParams();
    const [paymentLink, setPaymentLink] = useState(null);
    const [orden, setOrden] = useState({ paymentDay: '', payment: '', idOrder: '' });
    const [linkCopied, setLinkCopied] = useState(false);
    const [shortLinkCopied, setShortLinkCopied] = useState(false);
    const [loading, setLoading] = useState(false);
    const { setAlert } = useContext(AlertContext);

    useEffect(async () => {
        setLoading(true);
        try {
            const response = await getPaymentLinkById(id);
            setPaymentLink(response);
            if (response?.status == 'paid') {
                if (response?.source?.id.includes('plan')) {
                    const responseSubs = await getAccountSubs(response?.account?.id)
                    if (responseSubs?.find((element) => element.plan_id == response?.source?.id) != undefined) {
                        const responseOrden = await getOrder(responseSubs[0]?.order_id)
                        const charges = await getBalances(responseOrden?.account?.id,responseOrden?.id);
                        setOrden({
                            paymentDay: formatDate(responseOrden?.charges[0].date, 'DD MMM YYYY, h:mm:ss a'),
                            payment: paymentMethods.find((element) => element.value == responseOrden?.charges[0].payment_method)?.name,
                            idOrder: responseOrden?.id,
                            paymentType: responseOrden?.monthly_installments_enabled,
                            months: responseOrden?.charges[0]?.balance_id == charges?.data[0]?.id ? charges?.data[0]?.monthly_installments : '1'
                        })
                    }

                    setLoading(false);
                } else {
                    const responseOrden = await getOrder(response?.source?.id)
                    const charges = await getBalances(responseOrden?.account?.id,responseOrden?.id);
                    setOrden({
                        paymentDay: formatDate(responseOrden?.charges[0].date, 'DD MMM YYYY, h:mm:ss a'),
                        payment: paymentMethods.find((element) => element.value == responseOrden?.charges[0].payment_method)?.name,
                        idOrder: responseOrden?.id,
                        paymentType: responseOrden?.monthly_installments_enabled,
                        months: responseOrden?.charges[0]?.balance_id == charges?.data[0]?.id ? charges?.data[0]?.monthly_installments : '1'
                    })
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }

        } catch (e) {
            setLoading(false);
            setAlert({ active: true, type: 'error', message: e.message })
        }
    }, []);

    const copyLinkToClipboard = async (text) => {
        if ('clipboard' in navigator) {
            return navigator.clipboard.writeText(text);
        }
    }

    const handleCopyLink = () => {
        copyLinkToClipboard(paymentLink.url).then(() => {
            setLinkCopied(true);
            setTimeout(() => {
                setLinkCopied(false);
            }, 5000);
        })
    }

    const handleCopyShortLink = () => {
        copyLinkToClipboard(paymentLink.short_url).then(() => {
            setShortLinkCopied(true);
            setTimeout(() => {
                setShortLinkCopied(false);
            }, 5000);
        })
    }

    return (
        <>
            {loading && <SecondaryLoader />}
            <div className="w-full">
                <Nav user={user}>
                    <div className="w-full">
                        <Link to='/payment-links'>
                            <span className="text-2xl text-white font-bold flex items-center cursor-pointer">
                                <span className="text-white opacity-50 text-4xl mr-2"><FontAwesomeIcon icon={faArrowLeft} /></span>
                                <span className="text-lg pr-1">
                                    Detalles
                                </span>
                            </span>
                        </Link>
                    </div>
                </Nav>
            </div>
            <div className="w-full px-4">
                <div className="w-full mt-20 md:mt-4">
                    <BreadCrumb roadMap={roadMap} />
                </div>
                <div className="w-full hidden md:flex text-3xl text-v2-input-text font-bold mt-5 justify-between">
                    <a>
                        <FontAwesomeIcon className='cursor-pointer' icon={faArrowLeft} onClick={() => history.goBack()} />
                    </a>
                    <span className="pl-4 w-full">
                        Detalles
                    </span>
                </div>
                {paymentLink && (
                    <div className="w-full mt-4 flex xs:flex-col lg:flex-row gap-4">
                        <div className='w-full lg:max-w-[30rem] flex flex-col gap-4'>
                            <div className="w-full border border-v2-gray-border-tables bg-white rounded-10 pb-4">
                                <HeadersDataTables icon={faLink} text="Link de cobro" background={true} bgColor="bg-button-orange" textColor='text-white' />
                                <div className='px-4 mt-2 text-v2-input-text text-base font-semibold'>
                                    {paymentLink.name}
                                    <span className='text-button-orange block text-2xl font-bold'>
                                        {formatterCurrency(paymentLink.price.total_amount / 100)}
                                        {paymentLink.recurrent && (
                                            <span className='pl-1 text-button-orange text-base font-bold lowercase'>
                                                / {paymentInterval[paymentLink.subscription_info.interval][paymentLink.subscription_info.frequency]}
                                            </span>
                                        )}
                                    </span>
                                </div>
                                <div className='mx-4 mt-4 h-8'>
                                    <span className={`${paymentLink.status !== 'paid' && paymentLink.status !== 'canceled' && getStatusExpired(convertUnixToDate(paymentLink.expires_at)) == 'expired' ? linkStatusColor['expired'] : linkStatusColor[paymentLink.status]} w-auto px-4 py-1 rounded-full text-white`}>
                                        {paymentLink.status !== 'paid' && paymentLink.status !== 'canceled' && getStatusExpired(convertUnixToDate(paymentLink.expires_at)) == 'expired' ? linkStatus['expired'] : linkStatus[paymentLink.status]}
                                    </span>
                                </div>
                                <div className='px-4 mt-2 text-v2-input-text'>
                                    <span className="w-auto py-1 rounded-full text-v2-input-text">
                                        Tipo de link:{paymentLink.recurrent ? " Suscripción" : " Pago único"}
                                    </span>
                                </div>
                                <div className='mx-4 mt-1'>
                                    <span className='text-v2-input-text'>
                                        {getStatusExpired(convertUnixToDate(paymentLink.expires_at)) == 'expired' ? 'Venció:' : 'Vence:'} {formatDate(convertUnixToDate(paymentLink.expires_at), 'DD MMM YYYY, h:mm:ss a')} {'(GMT-5)'}
                                    </span>
                                </div>
                                {paymentLink.recurrent && (
                                    <div className='mx-4 mt-1'>
                                        <span className='text-v2-input-text'>
                                            Inicio de pago: {formatDate(convertUnixToDate(paymentLink.subscription_info.start_date), 'DD MMM YYYY')}
                                        </span>
                                    </div>
                                )}
                                <div className='mx-4 mt-4'>
                                    Métodos de pago
                                    <div className='flex gap-4 mt-1'>
                                        {paymentLink.allowed_payment_methods.map((method, i) =>
                                            <div key={i} className='w-1/4 flex justify-center'>
                                                <img src={paymentMethods.find(paymentMethod => paymentMethod.value == method).imageUrl} alt={paymentMethods.find(paymentMethod => paymentMethod.value == method).name} className="object-contain" />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='w-full mt-6 px-4'>
                                    <div className="w-full border border-dashed border-v2-gray-border-dashed-amount flex justify-between items-center p-2">
                                        <div className="text-xs text-v2-gray-title-client font-bold w-10/12 truncate">
                                            Link corto:
                                            <input className="block text-base text-v2-input-text font-normal outline-none w-full" value={paymentLink.short_url} readOnly />
                                        </div>
                                        <div className="cursor-pointer h-10 w-14 rounded-5 bg-button-orange text-white flex justify-center items-center" onClick={handleCopyShortLink}>
                                            <FontAwesomeIcon icon={faCopy} />
                                        </div>
                                    </div>
                                    <div className='w-full h-6'>
                                        {shortLinkCopied && (
                                            <span className='text-base text-v2-green-message-copied'>Link copiado con éxito</span>
                                        )}
                                    </div>
                                    <div className="w-full border border-dashed border-v2-gray-border-dashed-amount flex justify-between items-center p-2 mt-1">
                                        <div className="text-xs text-v2-gray-title-client font-bold w-10/12 truncate">
                                            Link:
                                            <input className="block text-base text-v2-input-text font-normal outline-none w-full" value={paymentLink.url} readOnly />
                                        </div>
                                        <div className="cursor-pointer h-10 w-14 rounded-5 bg-button-orange text-white flex justify-center items-center" onClick={handleCopyLink}>
                                            <FontAwesomeIcon icon={faCopy} />
                                        </div>
                                    </div>
                                    <div className='w-full h-6'>
                                        {linkCopied && (
                                            <span className='text-base text-v2-green-message-copied'>Link copiado con éxito</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='xs:w-full md:flex-row gap-4'>
                            <div className="w-full border border-v2-gray-border-tables bg-white rounded-10 pb-4 mb-4">
                                <HeadersDataTables icon={faCircleUser} text="Datos del cliente" background={false} bgColor="bg-white" color='text-v2-blue-icons' />
                                <div className='w-full px-4 pt-3 items-center text-xs font-bold text-v2-gray-title-client'>
                                    Cliente:
                                    <span className='text-base font-normal block text-v2-input-text'>{paymentLink.account?.name}</span>
                                </div>
                                <div className='w-full px-4 pt-3 items-center text-xs font-bold text-v2-gray-title-client'>
                                    Correo:
                                    <span className='text-base font-normal block text-v2-input-text'>{paymentLink.account?.email}</span>
                                </div>
                                <div className='w-full px-4 pt-3 items-center text-xs font-bold text-v2-gray-title-client'>
                                    Móvil:
                                    <span className='text-base font-normal block text-v2-input-text'>{paymentLink.account?.phone_number}</span>
                                </div>
                            </div>
                            <div className="w-full border border-v2-gray-border-tables bg-white rounded-10 pb-4 mb-4">
                                <HeadersDataTables icon={faFileInvoiceDollar} text="Servicios" background={true} padding={0} bgColor="bg-v2-blue-text-login" textColor="text-white" />
                                <div className='w-full px-4 pt-3 items-center text-xs font-bold'>
                                    {paymentLink.order_template.line_items.map((service, i) => (
                                        <div key={i} className={i == 0 ? 'pt-1' : 'pt-3'}>
                                            <div className='flex justify-between text-v2-input-text'>
                                                <span className='text-base leading-snug font-normal block'>{service.name}</span>
                                                <span className='text-base leading-snug font-normal block ml-4'>{formatterCurrency((service.price.total_amount / 100))}</span>
                                            </div>
                                            {service.quantity > 1 && (
                                                <div className='flex justify-between -mt-1'>
                                                    <span className='text-sm font-normal block text-v2-gray-title-client'>Cantidad: {service.quantity}</span>
                                                    <span className='text-sm font-normal block text-v2-gray-title-client ml-4'>{formatterCurrency(service.price.unit_price / 100)} c/u</span>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {paymentLink.status == 'paid' && (
                                <div className="w-full border border-v2-gray-border-tables bg-white rounded-10 pb-4 mb-4">
                                    <HeadersDataTables icon={faReceipt} text="Información de pago" background={true} padding={0} bgColor="bg-v2-blue-icons" textColor="text-white" />
                                    <div className='w-full px-4 pt-3 items-center text-xs font-bold text-v2-gray-title-client'>
                                        Fecha de pago:
                                        <span className='text-base font-normal block text-v2-input-text'>{orden?.paymentDay}</span>
                                    </div>
                                    <div className='w-full px-4 pt-3 items-center text-xs font-bold text-v2-gray-title-client'>
                                        Método de pago:
                                        <span className='text-base font-normal block text-v2-input-text'>{orden?.payment}</span>
                                    </div>
                                    <div className='w-full px-4 pt-3 items-center text-xs font-bold text-v2-gray-title-client'>
                                        Tipo de pago:
                                        <span className='text-base font-normal block text-v2-input-text'>{(orden?.paymentType && orden?.months > 1)? `${orden?.months} Meses sin intereses` : 'Pago en una sola exhibición'} </span>
                                    </div>
                                    <div className='w-full px-4 pt-3 items-center text-xs font-bold text-v2-gray-title-client'>
                                        Id de orden:
                                        <span className='text-base font-normal block text-v2-input-text'>{orden?.idOrder}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export default ViewLinks