import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { ROLES, PERMISSIONS } from "./permission-maps";
import { AuthContext } from '../AuthProvider';
import { useLocation } from 'react-router-dom';

const hasPermission = ({ permissions, scopes }) => {
    const scopesMap = {};
    scopes.forEach((scope) => {
        scopesMap[scope] = true;
    });

    return permissions?.some((permission) => scopesMap[permission]) ?? true;
};

const PermissionsRoute = ({ component: RouteComponent, scopes = [], allowedRoles = [], ...rest }) => {
    const location = useLocation();
    const { user, error } = React.useContext(AuthContext);

    const roles = user?.roles;
    const permissions = [...new Set(roles?.map(role => PERMISSIONS[role])?.flat())];
    
    const permissionGranted = roles?.some(role => allowedRoles.includes(role)) || allowedRoles.includes(ROLES.all) || hasPermission({ permissions, scopes });

    if (!user){
        localStorage.setItem("current_location", `${location.pathname}${location.hash}`);
        return (
            <Redirect to={`/login${error ? "?error="+error : ""}`} />
        )
    }

    return (
            <Route
                {...rest}
                render={routeProps =>
                    permissionGranted ? (
                        <RouteComponent user={user} {...routeProps} />
                    ) : (
                        <div className="w-full h-screen flex flex-col justify-center items-center">
                            <h1 className='text-v2-blue-text-login text-9xl font-black'>403</h1>
                            <h1 className='text-v2-input-text text-2xl'>Acceso denegado</h1>
                        </div>
                    )
                }
            />
    );
};

PermissionsRoute.propTypes = {
    component: PropTypes.func,
    scopes: PropTypes.array,
    allowedRoles: PropTypes.array
}

export default PermissionsRoute;