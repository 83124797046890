import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

function ModalDeleteService({ title, children, modalOpen, services, setServices, hasTitle, maxWidth, marginY }) {

    const handleCloseServiceModal = index => {
        const modifiedDeleteModal = services.map( service => {
            if(service.index == index){
                return {
                    ...service,
                    modal_delete_service: false
                }
            }
            return service
        });
        setServices(modifiedDeleteModal);
    }
    return (
        <>
            {modalOpen.modal_delete_service &&
                <div className="w-screen h-screen fixed top-0 left-0 flex justify-center flex-row items-center z-50">
                    <div className="w-full h-full fixed bg-black bg-opacity-40 top-0 left-0 flex justify-center flex-row items-center z-50 backdrop-filter backdrop-blur" onClick={ () => handleCloseServiceModal(modalOpen.index) }/>
                    <div className={`bg-white relative rounded-2xl shadow-2xl xs:mx-auto xs:${marginY} xs:mb-2 md:m-auto z-50 ${maxWidth} xs:w-11/12 md:w-5/6 xs:h-auto md:h-auto`}>
                        {hasTitle &&
                        <div className="w-full border-b border-v2-gray-border-tables flex items-center xs:px-4 md:px-4 xs:py-2 md:py-3">
                            <div className="w-12">
                                <div className='xs:text-xl md:text-2xl bg-v2-blue-icons text-white rounded-full xs:w-8 xs:h-8 md:w-10 md:h-10 flex justify-center items-center leading-3'>
                                    <FontAwesomeIcon icon={ faUser } className='leading-3' />
                                </div>
                            </div>
                            <div className='w-5/6 text-xl font-semibold text-v2-input-text'>
                                { title }
                            </div>
                        </div>
                        }
                        {children}
                    </div>
                </div>
            }
        </>
    )
}

ModalDeleteService.propTypes = {
    title: PropTypes.string,
    children: PropTypes.any,
    modalOpen: PropTypes.any,
    services: PropTypes.any,
    setServices: PropTypes.any,
    hasTitle: PropTypes.bool,
    maxWidth: PropTypes.any,
    marginY: PropTypes.any
}

ModalDeleteService.defaultProps = {
    hasTitle: true,
    maxWidth: 'max-w-screen-xl',
    marginY: 'mt-auto'
}

export default ModalDeleteService