import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Nav from '../../../hooks/Nav';
import useFormatDate from '../../../hooks/useFormatDate';
import Sort from '../../../tailwindUI/Sort';
import SearchBar from '../../../tailwindUI/SearchBar';
import EmptyState from '../../../tailwindUI/EmptyState';
import Table from '../../../tailwindUI/Table';
import { DocumentMagnifyingGlassIcon, PlusIcon } from '@heroicons/react/24/solid';
import Pagination from '../../../tailwindUI/Pagination';
import usePagination from '../../../tailwindUI/usePagination';
import { getAccounts } from '../../../apiClient/operations/accountsOperations';
import useSort from '../../../tailwindUI/useSort';
import LongAlert from '../../../tailwindUI/LongAlert';
import useScrollPosition from '../../../hooks/useScrollPosition';
import PrimaryButton from '../../../tailwindUI/PrimaryButton';
import useFormatPhoneNumber from '../../../hooks/useFormatPhoneNumber';
import AccentButton from '../../../tailwindUI/AccentButton';
import PermissionsGate from '../../../cognito/permissions/PermissionsGate';
import { ROLES } from '../../../cognito/permissions/permission-maps';

const tableColumns = [
    { heading: 'Fecha', value: 'created_formatted' },
    { heading: 'Cliente', value: 'name' },
    { heading: 'Contacto', value: 'email', subvalue: 'phone_number_formated', main: true }
];
const itemsLimitInTable = 10;

function Leads() {

    const paginate = usePagination();
    const { formatDate } = useFormatDate();
    const sortItem = useSort();
    const setScrollPosition = useScrollPosition();
    const { formatPhoneNumber } = useFormatPhoneNumber();
    const history = useHistory();
    const [searchInput, setSearchInput] = useState(null);
    const [sortString, setSortString] = useState('');
    const [pagination, setPagination] = useState(null);
    const [currentPage, setCurrenPage] = useState(1);
    const [accounts, setAccounts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [sortOptions, setSortOptions] = useState([
        { id: 1, name: 'Los más nuevos', field: 'created', sort: 'reverse', active: false },
        { id: 2, name: 'Los más antiguos', field: 'created', sort: 'direct', active: false },
        { id: 3, name: 'Nombre descendente', field: 'name', sort: 'direct', active: false },
        { id: 4, name: 'Nombre ascendente', field: 'name', sort: 'reverse', active: false }
    ]);

    useEffect(() => {
        if(searchInput == ''  ||  searchInput == null){
            handleGetAccounts(currentPage, itemsLimitInTable, searchInput, sortString);
        }else{
            setCurrenPage(1)
            handleGetAccounts(1, itemsLimitInTable, searchInput, sortString);
        }
        
    }, [searchInput]);

    useEffect(() => {
            handleGetAccounts(currentPage, itemsLimitInTable, searchInput, sortString);
    }, [currentPage, sortString]);

    useEffect(() => {
        const activeOptions = sortOptions?.filter(option => option.active);
        if (activeOptions.length > 0) {
            const sortedItem = sortItem(activeOptions[0]);
            setSortString(sortedItem);
        }
    }, [sortOptions]);

    const handleGetAccounts = async (page, limit, search, sort) => {
        setIsLoading(true);
        try {
            const res = await getAccounts(page, limit, search, sort, ['lead']);
            setPagination(paginate(res.total_items, itemsLimitInTable, Math.ceil(res.total_items / res.limit)));
            const response = res.data.map(item => {
                return {
                    ...item,
                    created_formatted: formatDate(item.created, 'DD MMMM YYYY'),
                    phone_number_formated: formatPhoneNumber(item.phone_number),
                    billing: {
                        ...item.billing,
                        legal_type_formatted: item.billing?.legal_type && (item.billing.legal_type == 'personal' ? 'Persona Física' : 'Persona Moral') || ''
                    }
                }
            });
            setAccounts(response);
            setIsLoading(false);
        } catch (e) {
            setAccounts([]);
            setIsLoading(false);
            setError('No se pudieron obtener los leads');
        }
    }

    const handleView = item => {
        setScrollPosition(0);
        history.push({
            pathname: `leads/view/${item.id}#info`,
            state: {
                account: item
            }
        })
    }

    const columnActions = [
        {
            id: 1,
            name: 'Visualizar',
            type: 'primary',
            icon: <DocumentMagnifyingGlassIcon className='w-6 h-6 text-gray-600 lg:text-white' />,
            action: handleView,
        }
    ];

    const seeFullAccountList = () => {
        setCurrenPage(1);
        setSearchInput(null);
        setSortString('');
    }

    return (
        <>
            <div className="w-full">
                <Nav>
                    <div className="w-full">
                        <span className="text-3xl text-white font-bold">
                            Leads
                        </span>
                    </div>
                </Nav>
            </div>
            <div className='min-h-full md:min-h-[calc(100vh-4rem)]'>
                {error &&
                    <div className='w-full sticky top-[68px] md:top-1 mt-20 md:mt-4 z-[35]'>
                        <LongAlert title={error} show={error != null} onClose={() => setError(null)} />
                    </div>
                }
                <div className="w-full px-4 pt-4">
                    <div className="lg:flex justify-between items-center w-full">
                        <h1 className="hidden lg:block text-4xl font-bold text-left text-gray-900">
                            Leads
                        </h1>
                        <div className="mt-16 lg:mt-0 w-full lg:w-auto max-w-full lg:max-w-[150px]">
                            {!isLoading && (
                                <PermissionsGate allowedRoles={[ROLES.root, ROLES.help_desk, ROLES.administrators, ROLES.sales, ROLES.collections, ROLES.customerService, ROLES.acquisitions, ROLES.operationsAtc]}>
                                    <PrimaryButton isFullWidth onClick={() => history.push('/leads/create')}>
                                        <span className='text-[15px] md:text-sm py-0.5 md:py-1 font-medium'>Crear lead</span>
                                        <PlusIcon className='w-5 h-5 font-bold ml-2' />
                                    </PrimaryButton>
                                </PermissionsGate>
                            )}
                        </div>
                    </div>
                </div>
                <div className={`w-full px-4 py-0 md:py-4 ${error != null ? 'mt-0' : 'mt-4 md:mt-0'} `}>
                    {isLoading ?
                        <>
                            <div className='w-full h-12 rounded-full bg-gray-300 animate-pulse'></div>
                            <div className='w-full flex justify-between'>
                                <div className='w-24 h-6 rounded-full bg-gray-300 animate-pulse my-4' />
                                <div className='w-24 h-6 rounded-full bg-gray-300 animate-pulse my-4' />
                            </div>
                            <Table title='Leads' data={accounts} isLoadingData={isLoading} columns={tableColumns} actions={columnActions} />
                        </>
                        :
                        <>
                            {accounts.length > 0 ?
                                <>
                                    <SearchBar value={searchInput} setValue={setSearchInput} placeholder_desktop="Nombre, correo o RFC" />
                                    <section aria-labelledby="filter-heading" className='pt-4'>
                                        <div className="flex items-center justify-between">
                                            <Sort options={sortOptions} title='Ordenar' setOptions={setSortOptions} />
                                        </div>
                                    </section>
                                    <div className='mt-4'>
                                        <Table title='Leads' data={accounts} isLoadingData={isLoading} columns={tableColumns} actions={columnActions} />
                                    </div>
                                    <div className='mt-4'>
                                        <Pagination pages={pagination?.pages?.length} currentPage={currentPage} setCurrentPage={setCurrenPage} />
                                    </div>
                                </>
                                :
                                <>
                                    <div className='mb-4'>
                                        <SearchBar value={searchInput} setValue={setSearchInput} />
                                    </div>
                                    <EmptyState title='No se encontraron leads' text='No se encontraron resultados para esta búsqueda, verifica la información o intenta con otra.'>
                                        <div className='mt-6 hidden md:block'>
                                            <AccentButton onClick={seeFullAccountList}>
                                                Ver la lista completa
                                            </AccentButton>
                                        </div>
                                        <div className='mt-6 w-full md:hidden'>
                                            <AccentButton isFullWidth onClick={seeFullAccountList}>
                                                Ver la lista completa
                                            </AccentButton>
                                        </div>
                                    </EmptyState>
                                </>
                            }
                        </>
                    }
                </div>
            </div>
        </>
    )
}

export default Leads;