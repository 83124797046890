import React, { useContext, useEffect, useState } from 'react';
import { login } from '../apiClient/operations/sessionOperations';
import { AlertContext } from '../context/AlertContext';
import LongAlert from '../tailwindUI/LongAlert';

const Login = () => {
    var error = null;
    const { alert, setAlert } = useContext(AlertContext);
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        error = query.get('error');
        setAlert({active: error != null, type: 'error', message: 'Error al iniciar sesión'});
        return () => {
            localStorage.removeItem('current_location');
            setAlert({active: false, type: '', message: ''})
        }
    }, []);

    useEffect(() => {
        alert.active ? setShowAlert(true) : setShowAlert(false)
    }, [alert]);

    return (
        <>
            {showAlert &&
                <div className="w-full sticky top-2 z-[30] mt-0">
                    <LongAlert show={ showAlert } onClose={ setShowAlert } type={ alert.type } title={ alert.message } />
                </div>
            }
            <div className="w-full h-screen bg-light-gray p-3 flex justify-center flex-col items-center">
                <div className={`${error ? '-mt-12' : 'mt-0'} w-full h-full flex justify-center flex-col items-center`}>
                    <div className="w-full max-w-screen-lg max-h-screen overflow-hidden xs:h-full xs:flex md:block md:h-auto">
                        <div className="w-full bg-white border border-v2-border-login rounded-lg flex">
                            <div className="xs:w-full md:w-1/2 xs:px-6 md:pl-16 py-6 flex">
                                <div className="w-full h-full xs:text-center md:text-left">
                                    <div className="w-full h-2/6 flex justify-start flex-col items-start">
                                        <div className="w-full text-left xs:flex justify-center md:block">
                                            <img
                                                src='https://cdn-fixat-mx.s3.amazonaws.com/intranet/fxt_logo@2x.png'
                                                alt="Logo principal de Fixat®" 
                                                className="w-[212px] h-14 xs:-ml-0 md:-ml-3"
                                            />
                                        </div>
                                        <div className="w-full mt-6">
                                            <h3 className="text-4.5xl font-bold text-v2-input-text leading-10">
                                                Bienvenido <span className="block">a FIXAT</span>
                                            </h3>
                                        </div>
                                        <div className="w-full mt-1">
                                            <p className="text-lg text-v2-input-text">
                                                Comienza a gestionar clientes
                                            </p>
                                        </div>
                                    </div>
                                    <div className="w-full h-2/6 flex justify-center items-center">
                                        <div className="w-full mb-4 md:mt-10 lg:mt-0 flex xs:justify-center md:justify-start">
                                            <button className="border border-v2-border-login rounded-5 py-2.5 px-2 text-v2-input-text flex items-center" onClick={login}>
                                                <span className='inline-block mr-2'>
                                                    <img 
                                                    src="https://cdn-fixat-mx.s3.amazonaws.com/intranet/g_logo.png"
                                                    alt="Fixi - inicia sesión"
                                                    className='w-6 h-6'
                                                    />
                                                </span>
                                                <span>
                                                    Iniciar sesión con Google
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="w-full h-2/6 flex justify-end flex-col items-end">
                                        {/*<div className="w-full">
                                            <span className="text-base text-v2-blue-text-login">
                                                ¿Aún no tienes accesos? <span className="block pt-1 font-bold text-lg underline cursor-pointer text-v2-blue-light-text-login">Solicitar aquí</span>
                                            </span>
                                        </div>*/}
                                        <div className="w-full mt-10">
                                            <span className="text-v2-gray-footer-kea text-xs">Proudly by KEA&copy; 2022</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="xs:hidden w-1/2 h-auto md:flex justify-center items-center pr-6 pb-4">
                                <img 
                                src="https://cdn-fixat-mx.s3.amazonaws.com/intranet/fxt_login.jpg"
                                alt="Fixi - inicia sesión"
                                className="mt-40"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;