const formatterPeso = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
});

const useFormatterCurrency = () => {
    const formatterCurrency = value => formatterPeso.format(value);
    return { formatterCurrency }
}

export default useFormatterCurrency;