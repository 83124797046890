import moment from 'moment';
import 'moment/locale/es';
moment().local('es');

const useNextBusinessDay = (date, days) => {
    date = moment(date);
        while (days > 0) {
            date = date.add(1, 'days');
            if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
            days -= 1;
            }
        }
    return date;
}

export default useNextBusinessDay

