import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { AlertContext } from '../../../context/AlertContext';
import useFormatterCurrency from '../../../hooks/useFormatterCurrency';
import useFormatDate from '../../../hooks/useFormatDate';
import Stats from '../../../tailwindUI/Stats';
import Table from '../../../tailwindUI/Table';
import Sort from '../../../tailwindUI/Sort';
import SlideOver from '../../../tailwindUI/SlideOver';
import Badge from '../../../tailwindUI/Badge';
import EmptyState from '../../../tailwindUI/EmptyState';
import { getBalanceOverview, getBalanceCharges } from '../../../apiClient/operations/balanceOperations';
import PrimaryButton from '../../../tailwindUI/PrimaryButton';
import { DocumentArrowDownIcon, ReceiptRefundIcon } from '@heroicons/react/24/solid';
import { CSVLink } from "react-csv";
import useConstructDatesDisplay from '../../../hooks/useConstructDatesDisplay';
import useGetAge from '../../../hooks/useGetAge';
import useTransformRfcToDate from '../../../hooks/useTransformRfcToDate';
import { transformNumber } from '../../../tools/csvNumberFormatter';
import { retrieveContactByEmail } from '../../../apiClient/operations/activeCampaignOperations';
import { ENV } from '../../../config';
import moment from 'moment';
import { useLocation } from 'react-router';
import { getOrder } from '../../../apiClient/operations/ordersOperations';
import LinkButton from '../../../tailwindUI/LinkButton';
import RefundModal from './RefundModal';
import ReimbursmentModal from './ReimbursmentModal';
import PermissionsGate from '../../../cognito/permissions/PermissionsGate';
import { ROLES } from '../../../cognito/permissions/permission-maps';
import { getAccountRegimes } from '../../../apiClient/operations/fiscalOperations';

const tableColumns = [
    { heading: 'Fecha', value: 'formatted_paid_at', main: true },
    { heading: 'Tipo', value: 'translated_type' },
    { heading: 'Estatus', value: 'status', badge: true },
    { heading: 'Método', value: 'translated_method' },
    { heading: 'Monto', value: 'formatted_amount', align: 'right' }
]
const orderColumns = [
    { heading: 'Cantidad', value: 'quantity', align: 'center' },
    { heading: 'Nombre', value: 'name' },
    { heading: 'Monto', value: 'price.formated_total', align: 'center' },
];
const paymentTypes = {
    'unique': 'Pago único',
    'reimbursement': 'Devolución',
    'subscription': 'Suscripción',
    'deferred': 'Pago diferido'
}

const paymentMethods = {
    'credit': 'Tarjeta de crédito',
    'debit': 'Tarjeta de débito',
    'card': 'Tarjeta de crédito/débito',
    'paypal': 'Paypal',
    'cash': 'Efectivo',
    'oxxo': 'Efectivo',
    'transfer': 'Transferencia',
    'spei': 'Transferencia'
}

function PaymentsBalance({ account, contract, accountId, paymentBalance, setPaymentBalance }) {
    const { formatterCurrency } = useFormatterCurrency();
    const { formatDate } = useFormatDate();
    const { getAge } = useGetAge();
    const { state } = useLocation();
    const getTime = () => moment().format('MM/DD/YYYY H:mm:ss');
    const { setAlert } = useContext(AlertContext);
    const [isLoading, setIsLoading] = useState(false);
    const [balance, setBalance] = useState(null);
    const [openSlide, setOpenSlide] = useState(false);
    const [onUpdate, setOnUpdate] = useState(false);
    const [loadingCSV, setLoadingCSV] = useState(false);
    const [errorCSV, setErrorCSV] = useState(false);
    const [paymentDetails, setPaymentDetails] = useState(null);
    const [orderDetails, setOrderDetails] = useState(null);
    const [orderLoading, setOrderLoading] = useState(false);
    const [showRefund, setShowRefund] = useState(false);
    const [showReimbursment, setShowReimbursment] = useState(false);
    const [dataCsv, setDataCsv] = useState([]);
    const [refundAllowed, setRefundAllowed] = useState(false);
    const [regimeList, setRegimeList] = useState(null);
    const [stats, setStats] = useState([
        { id: 1, name: 'Monto a pagar', stat: '' },
        { id: 2, name: 'Monto pagado', stat: '' },
        { id: 3, name: 'Monto restante', stat: '' }
    ]);
    const [periodStats, setPeriodStats] = useState([
        { id: 1, name: 'Monto a pagar del mes', stat: '' },
        { id: 2, name: 'Monto pagado del mes', stat: '' },
        { id: 3, name: 'Monto restante del mes', stat: '' }
    ]);
    const [sortOptions, setSortOptions] = useState([
        { id: 1, name: 'Los más recientes', field: 'payment_date', sort: 'desc', active: false },
        { id: 2, name: 'Los más antiguos', field: 'payment_date', sort: 'asc', active: false }
    ]);

    useEffect(async () => {
        if (state?.charge) {
            var find_charge = paymentBalance?.charges.find(item => item.id === state?.charge?.charge_id || state?.charge?.id);
            if (find_charge) {
                setPaymentDetails(find_charge);
                setOpenSlide(true);
            }
            return;
        }
    }, [state, paymentBalance]);

    const getCharges = async (accountId, sort) => {
        let charges = await getBalanceCharges(accountId, sort);
        return charges?.data?.map(item => {
            return {
                ...item,
                formatted_paid_at: formatDate(item.payment_date, 'DD/MM/YY HH:mm:ss'),
                formatted_created_at: formatDate(item.created_at, 'DD/MM/YY HH:mm:ss'),
                formatted_amount: formatterCurrency(item.amount / 100),
                translated_type: paymentTypes[item.type],
                translated_method: paymentMethods[item.payment_method_type]
            }
        });
    }

    const handleUpdate = () => {
        setOnUpdate(!onUpdate);
    }

    useEffect(async () => {
        try {
            if (paymentDetails?.order_id) {
                setOrderLoading(true)
                var order_info = await getOrder(paymentDetails?.order_id)
                order_info?.line_items?.data.forEach(element => {
                    element.price = {
                        ...element.price,
                        formated_total: formatterCurrency(element.price.total_amount / 100),
                        formated_unit: formatterCurrency(element.price.unit_price / 100)
                    }
                });
                setOrderDetails(order_info)
                setOrderLoading(false)
            }

        } catch (error) {
            console.log(error);
            setOrderLoading(false)
            setOrderDetails(null)
        }
    }, [paymentDetails])

    useEffect(async () => {
        if (!accountId) return;

        if (paymentBalance) {
            setBalance(paymentBalance);
            return;
        }

        try {
            setIsLoading(true);
            const overview = account.account_type == 'taxpayer' ? await getBalanceOverview(accountId) : {}
            const charges = await getCharges(accountId);
            const balance = {
                overview,
                charges
            };
            if (setPaymentBalance) setPaymentBalance(balance);
            else setBalance(balance);
            setIsLoading(false);

        } catch (e) {
            console.log(e);
            setIsLoading(false);
            setAlert({ active: true, type: 'error', message: 'Error al procesar tu solicitud' })
        }

    }, [accountId, paymentBalance, onUpdate]);

    useEffect(async () => {
        if (isLoading || sortOptions?.every(option => !(option.active))) return;
        const sort = sortOptions?.find(option => option.active)?.sort;

        try {
            setIsLoading(true);
            const charges = await getCharges(accountId, sort);
            const paymentBalance = {
                ...balance,
                charges
            };
            if (setPaymentBalance) setPaymentBalance(paymentBalance);
            else setBalance(paymentBalance);

            setIsLoading(false);
        } catch (e) {
            console.log(e);
            setIsLoading(false);
            setAlert({ active: true, type: 'error', message: 'Error al procesar tu solicitud' })
        }
    }, [sortOptions, onUpdate]);

    useEffect(() => {
        if (!balance) return;
        const totalAmount = (balance?.overview?.amount?.total_amount / 100) || 0;
        const paidAmount = (balance?.overview?.amount?.total_paid_amount / 100) || 0;
        const dueAmount = (balance?.overview?.amount?.total_due_amount / 100) || 0;
        setStats([
            { id: 1, name: 'Monto a pagar', stat: formatterCurrency(totalAmount) },
            { id: 2, name: 'Monto pagado', stat: formatterCurrency(paidAmount) },
            { id: 3, name: 'Monto restante', stat: formatterCurrency(dueAmount) }
        ])

        const totalPeriodAmount = (balance?.overview?.amount?.current_period_amount / 100) || 0;
        const periodPaidAmount = (balance?.overview?.amount?.current_period_paid_amount / 100) || 0;
        const periodDueAmount = (balance?.overview?.amount?.current_due_amount / 100) || 0;
        setPeriodStats([
            { id: 1, name: 'Monto a pagar del mes', stat: formatterCurrency(totalPeriodAmount) },
            { id: 2, name: 'Monto pagado del mes', stat: formatterCurrency(periodPaidAmount) },
            { id: 3, name: 'Monto restante del mes', stat: formatterCurrency(periodDueAmount) }
        ])
    }, [balance]);

    const handleView = item => {
        setPaymentDetails(item);
        setOpenSlide(true);
    }

    const columnActions = [
        {
            id: 1,
            name: 'Detalles',
            type: 'secondary',
            action: handleView,
        }
    ];

    const headers = [
        'Folio',
        'Timestamp',
        'Tipo Registro',
        'Tipo de Contribuyente',
        'Nombre',
        'Apellido',
        'Email',
        'Teléfono',
        'RFC',
        'Razón social',
        'Edad',
        'Fecha de Nacimiento',
        'Ciudad',
        'Ocupación',

        '¿Requiere regularización fiscal?',
        'Descripción de la regularización',
        'Periodos',
        'Número de obligaciones',
        'Precio de lista',
        'Costo final por el total de servicios de regularización',
        'Costo final con descuento por el total de servicios de regularización',
        'Descuento',
        'RGLZ*Ejecutar',

        '¿Requieres Servicio Contable?',
        'Descripción del servicio',
        'Periodos',
        'Numero de meses',
        'Precio de lista',
        'Costo final por el total de servicios contables',
        'Costo final con descuento por el total de servicios contables',
        'Descuento',

        '¿Requieres Servicio Especial?',
        'Descripción del servicio',
        'Periodos',
        'Precio de lista',
        'Costo final por el total de servicios especiales',
        'Costo final con descuento por el total de servicios especiales',
        'Descuento',

        'Vendedor',
        'Fecha de contrato',
        'Costo inicial del contrato (Precio sin Descuento)',
        'Costo Final del contrato (Con Descuentos) ',
        'Contrato de (Servicios Individuales / Paquete de Servicios)',
        'Comprobantes de Pago',

        'Monto pagado',
        '% de descuento',
        '% Cumplimiento',

        'Fecha de pago.',
        'Forma de Pago (Unico, Parcial o Recurrente)',
        'Medio de Pago (SPEI-Link-Transferencia)',
        'Fechas y Montos Compromiso',
        '¿Requiere Factura?',

        'Liga de activehosted',
        "",
        "Regimen fiscal"
    ]

    const handleGetPaymentDates = item => {
        let dates;
        switch (item.payment_type) {
            case 'unique':
                dates = `Fecha límite de pago: ${formatDate(item.expires_at, 'DD [de] MMMM YYYY')}`
                break;
            case 'subscription':
                dates = `Fecha de próximo pago: ${formatDate(item.next_payment_date == 'Now' ? new Date() : item.next_payment_date, 'DD [de] MMMM YYYY')}`
                break;
            case 'partial': {
                const partialDate = item.payment_dates.map(pay => {
                    return `${formatDate(pay.date == 'Now' ? new Date() : pay.date, 'DD [de] MMMM YYYY')} - ${pay.amount / 100}`
                })
                dates = `Fechas: \n ${partialDate.map(d => d).join('\n ')}`
                break;
            }
            default:
                break;
        }
        return dates
    }

    const getInfoServices = () => {
        if (!contract) return [];
        let ser = [];
        if (contract.payment_instructions.find(item => Object.keys(item).includes('services'))) {
            contract.payment_instructions.forEach((item, i) => {
                const servicesInfo = item.services.map(service => {
                    return {
                        ...service,
                        index_instruction: i + 1,
                        dates_display: useConstructDatesDisplay(service.periods)
                    }
                })
                ser.push(...servicesInfo)
            })
        }
        return ser
    }

    const getPeriods = (ser) => {
        let periods = [];
        if (ser.periodicity === 'yearly') {
            ser.dates_display.forEach(per => {
                periods.push(per.year)
            })
        } else {
            ser.dates_display.forEach(per => {
                periods.push(per.tag)
            })
        }
        const periodsUnited = periods.join(' - ');
        return periodsUnited
    }

    const handleGetNamesServices = (arr) => {
        let doc = []
        for (let i = 0; i < arr.length; i++) {
            doc.push(`${arr[i].name}`);
        }
        const desc = doc.join(', ');
        return desc
    }

    const getRegime = (name) => {
        var regimes_list = JSON.parse(process.env.REACT_APP_REGIMES_LIST || '{}')
        var regime = regimes_list.list.find(item => item.descripcion == name)
        var buildstr = regime?.id + ' - ' + regime?.descripcion
        return buildstr
    }

    const handleConstructInfo = (item, i) => {
        const services_names = handleGetNamesServices(item?.services);
        const dates = handleGetPaymentDates(item);
        const price = item?.price?.total_amount / 100;
        const finalString = `${i + 1}. Servicios: ${services_names} \n ${dates} \n Precio total: ${price}`;
        return finalString
    }

    const getInstructions = () => {
        let datesInfo = [];
        contract?.payment_instructions.forEach((item, i) => {
            const str = handleConstructInfo(item, i);
            datesInfo.push(str);
        })
        const datesString = datesInfo.join('\n');
        return datesString
    }

    const getAllDescriptions = arr => {
        let doc = []
        for (let i = 0; i < arr.length; i++) {
            doc.push(`${i + 1}. ${arr[i].name}`);
        }
        const desc = doc.join('\n');
        return desc
    }

    const getAllPeriods = arr => {
        let periods = [];
        for (let i = 0; i < arr.length; i++) {
            periods.push(`${i + 1}. ${getPeriods(arr[i])}`)
        }
        const per = periods.join('\n');
        return per
    }

    const operateServices = async (regularization, accounting, otherServices, instructionInfo, link) => {
        if (!contract){
            setErrorCSV(true)
            return;
        } 
        let regimes = regimeList;
        if (!regimes) {
            try {
                const res = await getAccountRegimes(accountId);
                regimes = res.tax_regimes;
                setRegimeList(regimes);
            } catch (error) {
                console.log(error)
            }
        }
        let data = [];
        var payment = paymentBalance?.charges?.filter(charge => charge.status === 'paid');
        if (payment?.length > 1) {
            payment = payment?.sort((a, b) => a?.payment_date - b?.payment_date);
            payment.shift()
        }
        for (let i = 0; i < 1; i++) {
            const firstRow = [
                contract.folio,
                getTime(),
                'H',
                contract.contractor.legal_type == 'personal' ? 'Persona física' : 'Persona moral',
                contract.contractor.first_name,
                contract.contractor.last_name,
                contract.contractor.email,
                contract.contractor.phone_number,
                contract.account.taxpayer.tax_id,
                contract.contractor.name,
                getAge(useTransformRfcToDate(contract.account.taxpayer.tax_id)),
                formatDate(useTransformRfcToDate(contract.account.taxpayer.tax_id), 'DD/MM/YYYY'),
                contract.contractor.state,
                contract.contractor.occupancy,

                regularization.length > 0 ? 'Sí' : 'No',
                regularization.length > 0 ? getAllDescriptions(regularization) : '',
                regularization.length > 0 ? getAllPeriods(regularization) : '',
                regularization.length > 0 ? regularization.map(t => t.quantity).reduce((prev, curr) => prev + curr, 0) : '',
                '',
                regularization.length > 0 ? transformNumber(regularization.map(t => t.price.subtotal_amount / 100).reduce((prev, curr) => prev + curr, 0)) : '',
                regularization.length > 0 ? transformNumber(regularization.map(t => t.price.total_amount / 100).reduce((prev, curr) => prev + curr, 0)) : '',
                regularization.length > 0 ? transformNumber(regularization.map(t => t.price.discount_amount / 100).reduce((prev, curr) => prev + curr, 0)) : '',
                '',
                accounting.length > 0 ? 'Sí' : 'No',
                accounting.length > 0 ? getAllDescriptions(accounting) : '',
                accounting.length > 0 ? getAllPeriods(accounting) : '',
                accounting.length > 0 ? accounting.map(t => t.quantity).reduce((prev, curr) => prev + curr, 0) : '',
                '',
                accounting.length > 0 ? transformNumber(accounting.map(t => t.price.subtotal_amount / 100).reduce((prev, curr) => prev + curr, 0)) : '',
                accounting.length > 0 ? transformNumber(accounting.map(t => t.price.total_amount / 100).reduce((prev, curr) => prev + curr, 0)) : '',
                accounting.length > 0 ? transformNumber(accounting.map(t => t.price.discount_amount / 100).reduce((prev, curr) => prev + curr, 0)) : '',
                otherServices.length > 0 ? 'Sí' : 'No',
                otherServices.length > 0 ? getAllDescriptions(otherServices) : '',
                otherServices.length > 0 ? getAllPeriods(otherServices) : '',
                '',
                otherServices.length > 0 ? transformNumber(otherServices.map(t => t.price.subtotal_amount / 100).reduce((prev, curr) => prev + curr, 0)) : '',
                otherServices.length > 0 ? transformNumber(otherServices.map(t => t.price.total_amount / 100).reduce((prev, curr) => prev + curr, 0)) : '',
                otherServices.length > 0 ? transformNumber(otherServices.map(t => t.price.discount_amount / 100).reduce((prev, curr) => prev + curr, 0)) : '',
                contract.seller.name,
                formatDate(contract.created, 'MM/DD/YYYY H:mm:ss'),
                transformNumber(contract.price.subtotal_amount / 100),
                transformNumber(contract.price.total_amount / 100),
                contract?.price?.price_type == 'special_price' ? 'Paquete de servicios' : 'Servicios individuales',
                '',
                payment == undefined || payment.length == 0 ? '' : transformNumber(payment?.sort((a, b) => a.payment_date - b.payment_date)[0]?.amount / 100),
                '',
                '',
                payment == undefined || payment.length == 0 ? '' : formatDate(payment?.sort((a, b) => a.payment_date - b.payment_date)[0]?.payment_date, 'MM/DD/YYYY'),
                payment == undefined || payment.length == 0 ? '' : payment?.sort((a, b) => a.payment_date - b.payment_date)[0]?.translated_type,
                payment == undefined || payment.length == 0 ? '' : payment?.sort((a, b) => a.payment_date - b.payment_date)[0]?.translated_method,
                instructionInfo,
                'Sí',
                link?.contacts?.[0]?.id == undefined || link?.contacts?.[0]?.id == null ? '' : `https://${ENV == 'PRODUCTION' ? 'fixat' : 'fixat1634581619'}.activehosted.com/app/contacts/${link?.contacts[0]?.id}`,
                "",
                regimes?.length > 0 ? regimes.map(regime => `${regime.regime_id} - ${regime.name}`)?.join('|') : getRegime(contract?.taxpayer?.regime),
            ];
            data = [...data, firstRow];
        }
        const maxN = Math.max(regularization.length, accounting.length, otherServices.length);
        if (maxN == 0) {
            setErrorCSV(true)
        } else {
            setErrorCSV(false)
        }
        for (let i = 0; i < maxN; i++) {
            const row = [
                contract.folio,
                getTime(),
                'S',
                contract.contractor.legal_type == 'personal' ? 'Persona física' : 'Persona moral',
                contract.contractor.first_name,
                contract.contractor.last_name,
                contract.contractor.email,
                contract.contractor.phone_number,
                contract.account.taxpayer.tax_id,
                contract.contractor.name,
                getAge(useTransformRfcToDate(contract.account.taxpayer.tax_id)),
                formatDate(useTransformRfcToDate(contract.account.taxpayer.tax_id), 'DD/MM/YYYY'),
                contract.contractor.state,
                contract.contractor.occupancy,
                regularization[i] == undefined ? 'No' : 'Sí',
                regularization[i] == undefined ? '' : regularization[i].name,
                regularization[i] == undefined ? '' : getPeriods(regularization[i]),
                regularization[i] == undefined ? '' : regularization[i].quantity,
                regularization[i] == undefined ? '' : transformNumber(regularization[i].price.unit_price / 100),
                regularization[i] == undefined ? '' : transformNumber(regularization[i].price.subtotal_amount / 100),
                regularization[i] == undefined ? '' : transformNumber(regularization[i].price.total_amount / 100),
                regularization[i] == undefined ? '' : transformNumber(regularization[i].price.discount_amount / 100),
                '',
                accounting[i] == undefined ? 'No' : 'Sí',
                accounting[i] == undefined ? '' : accounting[i].name,
                accounting[i] == undefined ? '' : getPeriods(accounting[i]),
                accounting[i] == undefined ? '' : accounting[i].quantity,
                accounting[i] == undefined ? '' : transformNumber(accounting[i].price.unit_price / 100),
                accounting[i] == undefined ? '' : transformNumber(accounting[i].price.subtotal_amount / 100),
                accounting[i] == undefined ? '' : transformNumber(accounting[i].price.total_amount / 100),
                accounting[i] == undefined ? '' : transformNumber(accounting[i].price.discount_amount / 100),
                otherServices[i] == undefined ? 'No' : 'Sí',
                otherServices[i] == undefined ? '' : otherServices[i].name,
                otherServices[i] == undefined ? '' : getPeriods(otherServices[i]),
                otherServices[i] == undefined ? '' : transformNumber(otherServices[i].price.unit_price / 100),
                otherServices[i] == undefined ? '' : transformNumber(otherServices[i].price.subtotal_amount / 100),
                otherServices[i] == undefined ? '' : transformNumber(otherServices[i].price.total_amount / 100),
                otherServices[i] == undefined ? '' : transformNumber(otherServices[i].price.discount_amount / 100),
                contract.seller.name,
                formatDate(contract.created, 'MM/DD/YYYY H:mm:ss'),
                transformNumber(contract.price.subtotal_amount / 100),
                transformNumber(contract.price.total_amount / 100),
                contract?.price?.price_type == 'special_price' ? 'Paquete de servicios' : 'Servicios individuales',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
            ]
            data = [...data, row]
            if (i - 1 < maxN) {
                setLoadingCSV(false)
            }
        }
        setDataCsv([...data]);
    }

    const separateServices = async () => {
        const allServices = getInfoServices();
        let instructionInfo = "";
        if (contract != null && contract.payment_instructions.find(item => Object.keys(item).includes('services'))) {
            instructionInfo = getInstructions();
        }
        const acc = await retrieveContactByEmail(contract?.account?.email);
        let regularization = [];
        let accounting = [];
        let otherServices = [];
        if (allServices.length > 0) {
            allServices.forEach(item => {
                const category = item.category;
                if (category == 'regularization') {
                    regularization.push(item);
                } else if (category == 'accounting') {
                    accounting.push(item);
                } else {
                    otherServices.push(item);
                }
            });
        }
        operateServices(regularization, accounting, otherServices, instructionInfo, acc);
    }

    useEffect(async () => {
        if (paymentBalance != null) {
            setLoadingCSV(true);
            await separateServices()
        }
    }, [paymentBalance, contract]);

    useEffect(() => {
        if (paymentDetails != null) {
            var final_date = moment(paymentDetails.payment_date).add(30, 'days').valueOf();
            var current_date = moment().valueOf();
            setRefundAllowed(final_date > current_date)
        }

    }, [paymentDetails])

    const onclickCSV = () => {
        console.log(dataCsv)
    }

    return (
        <>
            <div className='space-y-4'>
                {
                    account?.account_type == "taxpayer" &&
                    (
                        <>
                            <Stats items={stats} cols='sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3' isLoading={!(balance?.overview) && isLoading} />
                            <Stats items={periodStats} cols='sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3' isLoading={!(balance?.overview) && isLoading} />
                        </>
                    )
                }
                <div className='space-y-4'>
                    <div className='flex flex-row justify-between items-center'>
                        <h2 className='text-gray-900 font-medium text-xl flex gap-2 items-center'>
                            Historial de pagos
                        </h2>
                        <PermissionsGate allowedRoles={[ROLES.collections_manager, ROLES.root]}>
                            <div className='w-auto'>
                                <PrimaryButton onClick={() => { setShowReimbursment(true) }} disabled={false} isFullWidth>
                                    Crear devolución<ReceiptRefundIcon className='w-5 h-5 ml-2 inline-block' />
                                </PrimaryButton>
                            </div>
                        </PermissionsGate>
                    </div>

                    {(!isLoading && (!paymentBalance?.charges || paymentBalance?.charges?.length == 0)) &&
                        <EmptyState title='Sin pagos' text='No se ha realizado ningún pago todavía.' />
                    }
                    {(isLoading || paymentBalance?.charges?.length > 0) &&
                        <>
                            <section aria-labelledby="filter-heading" className=''>
                                <div className="flex items-center justify-between">
                                    <Sort options={sortOptions} title='Ordenar' setOptions={setSortOptions} />
                                </div>
                            </section>
                            <Table
                                title='Pagos'
                                data={paymentBalance?.charges || []}
                                isLoadingData={isLoading}
                                columns={tableColumns}
                                actions={columnActions} />
                        </>
                    }
                </div>
                {((paymentBalance?.charges.some(charge => charge.status === 'paid')) && contract != null) &&
                    <div className='w-full flex justify-end py-4'>
                        <div className='w-full max-w-md'>
                            <CSVLink data={dataCsv} headers={headers} filename={`${contract?.folio}.csv`}>
                                {!loadingCSV ?
                                    <PrimaryButton onClick={onclickCSV} isFullWidth>
                                        Descargar CSV <DocumentArrowDownIcon className='w-5 h-5 ml-2 inline-block' />
                                    </PrimaryButton>
                                    :
                                    <PrimaryButton disabled isFullWidth>
                                        {
                                            errorCSV ? 
                                            "Error al cargar CSV" 
                                            : <>Cargando... <DocumentArrowDownIcon className='w-5 h-5 ml-2 inline-block' /></>
                                        }
                                    </PrimaryButton>
                                }

                            </CSVLink>

                        </div>
                    </div>
                }
            </div>
            <SlideOver open={openSlide} setOpen={setOpenSlide} title="Detalles del pago">
                <div className='w-full divide-y divide-gray-200'>
                    <div className='pb-3'>
                        <dl className="grid grid-cols-1 gap-4 sm:grid-cols-1">
                            <div className="flex flex-row items-center">
                                <dd className="mr-2 text-xl font-bold text-gray-900">{paymentDetails?.formatted_amount}</dd>
                                <Badge text={paymentDetails?.status} />
                            </div>
                        </dl>

                        {paymentDetails?.failure && (
                            <dl className="grid grid-cols-1 gap-4 sm:grid-cols-1 pt-3">
                                <div className="sm:col-span-2">
                                    {paymentDetails?.status == "failed" && <dt className="text-sm font-medium text-gray-500">Descripción de fallo:</dt>}
                                    {paymentDetails?.status == "failed" && <dd className="mt-1 text-sm text-gray-900">{paymentDetails?.failure?.message}</dd>}
                                </div>

                            </dl>
                        )}
                    </div>
                    <div className='py-3'>
                        <div className='w-full flex flex-row items-center justify-between'>
                            <h3 className="font-medium leading-6 text-gray-900 mb-2">Cargo:</h3>
                            <PermissionsGate allowedRoles={[ROLES.collections_manager, ROLES.root]}>
                                {refundAllowed && paymentDetails?.status == 'paid' &&
                                    (paymentDetails?.payment_method_type == 'credit' || paymentDetails.payment_method_type == 'debit' || paymentDetails.payment_method_type == 'card' || paymentDetails.payment_method_type == 'paypal') && <div className=''>
                                        <LinkButton onClick={() => { setShowRefund(true) }}>
                                            Reembolsar<ReceiptRefundIcon className='w-5 h-5 ml-2 inline-block' />
                                        </LinkButton>
                                    </div>
                                }
                            </PermissionsGate>
                        </div>
                        <dl className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                            <div className="sm:col-span-2">
                                <dt className="text-sm font-medium text-gray-500">ID</dt>
                                <dd className="mt-1 text-sm text-gray-900">{paymentDetails?.id}</dd>
                            </div>
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Tipo</dt>
                                <dd className="mt-1 text-sm text-gray-900">{paymentDetails?.translated_type}</dd>
                            </div>
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Fecha de pago</dt>
                                <dd className="mt-1 text-sm text-gray-900">{paymentDetails?.formatted_paid_at}</dd>
                            </div>
                            {paymentDetails?.monthly_installments && paymentDetails?.type != "reimbursement" && <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Mensualidades</dt>
                                <dd className="mt-1 text-sm text-gray-900">{paymentDetails?.monthly_installments == 1 ? "Pago de contado" : paymentDetails?.monthly_installments + " Mensualidades"}</dd>
                            </div>}
                        </dl>
                    </div>
                    {(paymentDetails?.type == "reimbursement") && <div className='py-3'>
                        <h3 className="pt-3 font-medium leading-6 text-gray-900 mb-2">Devolución:</h3>
                        <dl className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Monto de la devolución:</dt>
                                <dd className="mt-1 text-sm text-gray-900">{formatterCurrency(paymentDetails?.amount / 100)}</dd>
                            </div>
                            {paymentDetails?.returned_at && <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Fecha de la devolución:</dt>
                                <dd className="mt-1 text-sm text-gray-900">{formatDate(paymentDetails?.returned_at, 'DD/MM/YY HH:mm:ss')}</dd>
                            </div>}
                            {paymentDetails?.return_reason && <div className="sm:col-span-2">
                                <dt className="text-sm font-medium text-gray-500">Motivo de la devolución:</dt>
                                <dd className="mt-1 text-sm text-gray-900">{paymentDetails?.return_reason}</dd>
                            </div>}

                        </dl>
                    </div>}
                    {(paymentDetails?.status == "partial_refund" || paymentDetails?.status == "refund") && <div className='py-3'>
                        <h3 className="pt-3 font-medium leading-6 text-gray-900 mb-2">Reembolso:</h3>
                        <dl className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Monto reembolsado:</dt>
                                <dd className="mt-1 text-sm text-gray-900">{formatterCurrency(paymentDetails?.refund_amount / 100)}</dd>
                            </div>
                            {paymentDetails?.refunded_at && <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Fecha de reembolso:</dt>
                                <dd className="mt-1 text-sm text-gray-900">{formatDate(paymentDetails?.refunded_at, 'DD/MM/YY HH:mm:ss')}</dd>
                            </div>}
                            {paymentDetails?.refund_reason && <div className="sm:col-span-2">
                                <dt className="text-sm font-medium text-gray-500">Motivo de reembolso:</dt>
                                <dd className="mt-1 text-sm text-gray-900">{paymentDetails?.refund_reason}</dd>
                            </div>}

                        </dl>
                    </div>}
                    {(paymentDetails?.status == "chargeback") && <div className='py-3'>
                        <h3 className="pt-3 font-medium leading-6 text-gray-900 mb-2">Contracargo:</h3>
                        <dl className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                            {paymentDetails?.status == "chargeback" && <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Monto reembolsado:</dt>
                                <dd className="mt-1 text-sm text-gray-900">{formatterCurrency(paymentDetails?.refund_amount / 100)}</dd>
                            </div>}
                            {paymentDetails?.chargeback_at && <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Fecha de contracargo:</dt>
                                <dd className="mt-1 text-sm text-gray-900">{formatDate(paymentDetails?.chargeback_at, 'DD/MM/YY HH:mm:ss')}</dd>
                            </div>}
                            {paymentDetails?.chargeback_reason && <div className="sm:col-span-2">
                                <dt className="text-sm font-medium text-gray-500">Motivo de contracargo:</dt>
                                <dd className="mt-1 text-sm text-gray-900">{paymentDetails?.chargeback_reason || "Motivo no especificado"}</dd>
                            </div>}

                        </dl>
                    </div>}
                    {(paymentDetails?.type == "reimbursement") && <div className='py-3'>
                        <h3 className="pt-3 font-medium leading-6 text-gray-900 mb-2">Cuenta del cliente:</h3>
                        <dl className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                            {paymentDetails?.payment_method?.bank && <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Banco:</dt>
                                <dd className="mt-1 text-sm text-gray-900">{paymentDetails?.payment_method?.bank}</dd>
                            </div>}
                            {paymentDetails?.payment_method?.auth_number && <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Número de autorización:</dt>
                                <dd className="mt-1 text-sm text-gray-900">{paymentDetails?.payment_method?.auth_number}</dd>
                            </div>}
                            {paymentDetails?.payment_method?.account && <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Numero de cuenta:</dt>
                                <dd className="mt-1 text-sm text-gray-900">{paymentDetails?.payment_method?.account}</dd>
                            </div>}
                            {paymentDetails?.payment_method?.account_type && <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Tipo de cuenta:</dt>
                                <dd className="mt-1 text-sm text-gray-900">{paymentDetails?.payment_method?.account_type}</dd>
                            </div>}


                        </dl>
                    </div>}
                    {(paymentDetails?.type == "reimbursement") && <div className='py-3'>
                        <h3 className="pt-3 font-medium leading-6 text-gray-900 mb-2">Cuenta de origen:</h3>
                        <dl className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                            {paymentDetails?.payment_method?.bank && <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Banco:</dt>
                                <dd className="mt-1 text-sm text-gray-900">{paymentDetails?.provider?.bank}</dd>
                            </div>}
                            {paymentDetails?.payment_method?.auth_number && <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Número de cuenta:</dt>
                                <dd className="mt-1 text-sm text-gray-900">{paymentDetails?.provider?.account}</dd>
                            </div>}


                        </dl>
                    </div>}

                    {paymentDetails?.type != "reimbursement" && <div className='py-3'>
                        <h3 className="font-medium leading-6 text-gray-900 mb-2">Método:</h3>
                        <dl className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Método de pago</dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                    {paymentDetails?.translated_method || paymentDetails?.provider.name}
                                </dd>
                            </div>
                            {(paymentDetails?.payment_method_type == 'credit' || paymentDetails?.payment_method_type == 'debit' || paymentDetails?.payment_method_type == 'card') &&
                                <>
                                    <div className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-500">Número de tarjeta</dt>
                                        <dd className="mt-1 text-sm text-gray-900">
                                            {paymentDetails?.payment_method?.card_number?.length > 4 ?
                                                paymentDetails?.payment_method?.card_number
                                                :
                                                `XXXXXXXXXXXX${paymentDetails?.payment_method?.card_number}`
                                            }
                                        </dd>
                                    </div>
                                    <div className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-500">Banco</dt>
                                        <dd className="mt-1 text-sm text-gray-900 capitalize">{paymentDetails?.payment_method?.bank}</dd>
                                    </div>
                                    <div className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-500">Proveedor</dt>
                                        <dd className="mt-1 text-sm text-gray-900 capitalize">{paymentDetails?.provider?.name}</dd>
                                    </div>
                                </>
                            }
                            {(paymentDetails?.payment_method_type == 'cash' || paymentDetails?.payment_method_type == 'oxxo') &&
                                <>
                                    <div className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-500">Número de referencia</dt>
                                        <dd className="mt-1 text-sm text-gray-900">{paymentDetails?.payment_method?.reference}</dd>
                                    </div>
                                    <div className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-500">Proveedor</dt>
                                        <dd className="mt-1 text-sm text-gray-900 capitalize">{paymentDetails?.provider?.name}</dd>
                                    </div>
                                </>
                            }
                            {(paymentDetails?.payment_method_type == 'transfer' || paymentDetails?.payment_method_type == 'spei') &&
                                <>
                                    <div className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-500">CLABE</dt>
                                        <dd className="mt-1 text-sm text-gray-900">{paymentDetails?.payment_method?.clabe}</dd>
                                    </div>
                                    <div className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-500">Banco</dt>
                                        <dd className="mt-1 text-sm text-gray-900 capitalize">{paymentDetails?.payment_method?.bank}</dd>
                                    </div>
                                    <div className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-500">Proveedor</dt>
                                        <dd className="mt-1 text-sm text-gray-900 capitalize">{paymentDetails?.provider?.name}</dd>
                                    </div>
                                </>
                            }
                            {paymentDetails?.payment_method_type == 'paypal' &&
                                <>
                                    <div className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-500">Número de referencia</dt>
                                        <dd className="mt-1 text-sm text-gray-900 md:truncate">{paymentDetails?.provider?.charge_id}</dd>
                                    </div>
                                    <div className="sm:col-span-2">
                                        <dt className="text-sm font-medium text-gray-500">Correo</dt>
                                        <dd className="mt-1 text-sm text-gray-900 md:truncate">{paymentDetails?.payment_method?.email}</dd>
                                    </div>
                                </>
                            }
                        </dl>
                    </div>}
                    {orderDetails && orderDetails?.line_items?.data.length != 0 && <div className='py-3'>
                        <h3 className="font-medium leading-6 text-gray-900 mb-2">Servicios pagados:</h3>
                        <dl className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                            <div className="sm:col-span-2">
                                <Table title='Cargos' data={orderDetails?.line_items?.data} isLoadingData={orderLoading} columns={orderColumns} />
                            </div>

                        </dl>
                    </div>}
                </div>
            </SlideOver>
            {paymentDetails && <RefundModal handleUpdate={handleUpdate} showRefund={showRefund} setShowRefund={setShowRefund} paymentDetails={paymentDetails} setPaymentDetails={setPaymentDetails} orderDetails={orderDetails} />}
            <ReimbursmentModal handleUpdate={handleUpdate} account={account} showRefund={showReimbursment} setShowRefund={setShowReimbursment} />

        </>
    )
}

PaymentsBalance.propTypes = {
    account: PropTypes.object,
    contract: PropTypes.object,
    accountId: PropTypes.string,
    paymentBalance: PropTypes.object,
    setPaymentBalance: PropTypes.func
}

PaymentsBalance.defaultProps = {
    contract: null,
}

export default PaymentsBalance;