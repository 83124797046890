import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBill1Wave } from '@fortawesome/free-solid-svg-icons';
import useFormatterCurrency from '../hooks/useFormatterCurrency';
import PropTypes from 'prop-types';

function ResumeAmounts({ subtotal, discount, totalAmount, amountServices, inside, bg }) {
    const { formatterCurrency } = useFormatterCurrency();
    return (
        <div className={`${ inside ? 'rounded-b-[9px]' : 'rounded-10' } w-full ${bg} h-40`}>
            <div className="w-full h-full">
            <div className="w-full border-b border-white py-[13px] flex items-center px-7 text-white">
                    <div className="w-3/4 text-base leading-3">
                        Cantidad de servicios
                    </div>
                    <div className="w-1/4">
                        <div className="w-full text-white text-base flex justify-end leading-3">
                            { amountServices }
                        </div>
                    </div>
                </div>
                <div className="w-full border-b border-dashed border-white py-[13px] flex items-center px-7 text-white">
                    <div className="w-3/4 text-base leading-3">
                        Tarifa base
                    </div>
                    <div className="w-1/4">
                        { subtotal >= 0 &&
                            <div className="w-full text-white text-base flex justify-end leading-3">
                                { isNaN(subtotal) ? 0 : formatterCurrency(subtotal) }
                            </div>
                        }
                    </div>
                </div>
                <div className="w-full border-b border-dashed border-white py-[13px] flex items-center px-7 text-white">
                    <div className="w-3/4 text-base leading-3">
                        Descuento total
                    </div>
                    <div className="w-1/4">
                        { discount >= 0 &&
                            <div className="w-full text-white text-base flex justify-end leading-3">
                                { isNaN(discount) ? 0 : formatterCurrency(discount) }
                            </div>
                        }
                    </div>
                </div>
                <div className="w-full py-3 flex items-center px-7 text-white">
                    <div className="w-3/4 text-base leading-3">
                        <span className="pr-2">Total</span><FontAwesomeIcon icon={ faMoneyBill1Wave } className="leading-3 text-lg"/> 
                    </div>
                    <div className="w-1/4 flex items-center">
                        <div className="w-full text-white text-base font-bold flex justify-end leading-3">
                            { isNaN(totalAmount) ? 0 : formatterCurrency(totalAmount) }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
ResumeAmounts.propTypes = {
    subtotal: PropTypes.any,
    discount: PropTypes.any,
    totalAmount: PropTypes.any,
    amountServices: PropTypes.any,
    inside: PropTypes.any,
    bg: PropTypes.any
}

ResumeAmounts.defaultProps = {
    inside: false,
    bg: 'bg-button-orange'
}

export default ResumeAmounts