import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faCalendar, faCheck, faX } from '@fortawesome/free-solid-svg-icons';
import useFormatDate from './useFormatDate';
import useGetDate from './useGetDate';
import Button from '../hooks/Button';
import Modal from './Modal';
import PropTypes from 'prop-types';
import useGetMonthName from './useGetMonthName';

function DateRangePicker({ sku, setServices, services, servicesDisplay, setServicesDisplay, resumeAmounts, setResumeAmounts, time }) {
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    const { formatDate } = useFormatDate();
    const { getDate } = useGetDate();
    const { getMonthName } = useGetMonthName();
    const [isOpen, setIsOpen] = useState(false);
    const [year, setYear] = useState(parseInt(formatDate(getDate(), 'YYYY')));
    const [dates, setDates] = useState([]);
    const [datesDisplay, setDatesDisplay] = useState([]);
    const [serie, setSerie] = useState([]);
    const [timePeriods, setTimePeriods] = useState(time);
    const day = parseInt(formatDate(getDate(), 'D'));

    const timeMonths = {
        'lt': {month: 2, months_blocked: 0, month_all_selected: 1},
        'lte': {month: 1, months_blocked: 1, month_all_selected: 0},
        'gt': {month: 0, months_blocked: 0, month_all_selected: 1},
        'gte': {month: 1, months_blocked: 1, month_all_selected: 0}
    }

    useEffect(() => {
        if( day <= 15 && (services.filter(service => service.index == sku)[0].periodicity === 'monthly' && services.filter(service => service.index == sku)[0].category === 'accounting')){
            setTimePeriods(`${time}e`);
        } else if( day > 15 && (services.filter(service => service.index == sku)[0].periodicity === 'monthly' && services.filter(service => service.index == sku)[0].category === 'regularization')){
            setTimePeriods(`${time}e`);
        }
    }, []);

    const chargeDates = () => {
        if (services != '') {
            const serviceId = services.filter(service => service.index == sku);
            const serviceDisplayId = servicesDisplay.filter(service => service.index == sku);
            setDates(serviceId[0].dates);
            setDatesDisplay(serviceDisplayId[0].dates_display);
        }
    }

    useEffect(() => {
        chargeDates();
        return () => {
            setDates();
            setDatesDisplay();
        }
    }, [services, servicesDisplay]);


    const createID = (yearID, monthID) => {
        let monthId = monthID < 10 ? `0${monthID}` : `${monthID}`;
        const id = (`${yearID}-${monthId}-01`);
        return id;
    }

    const createRange = (start, end) => {
        let ans = [];
        for (let i = start; i <= end; i++) {
            ans.push(i);
        }
        return ans;
    }

    const updateDisp = arr => {
        const totalDisplay = servicesDisplay.map(date => {
            if (sku == date.index) {
                return {
                    ...date,
                    dates_display: arr
                }
            }
            return date
        });
        setServicesDisplay(totalDisplay);
    }

    const calculateDiscount = discount => {
        const totalDiscount = discount.map(service => service.discount_amount).reduce((prev, curr) => prev + curr, 0);
        const total = discount.map(service => service.total).reduce((prev, curr) => prev + curr, 0);
        const sub = discount.map(service => service.subtotal).reduce((prev, curr) => prev + curr, 0);
        const pack_total_discount = discount.map(service => service.pack_discount_amount).reduce((prev, curr) => prev + curr, 0);
        const pack_total = discount.map(service => service.pack_total).reduce((prev, curr) => prev + curr, 0);
        setResumeAmounts({ ...resumeAmounts, discountAmount: totalDiscount, totalAmount: total, subtotalAmount: sub, totalPack: pack_total, totalPackDiscount: pack_total_discount });
    }

    const updateDates = (arrDates, number, series) => {
        const totalDates = services.map(date => {
            if (sku == date.index) {
                return {
                    ...date,
                    dates: arrDates,
                    quantity: number,
                    total: (date.discount_option == 'direct' ? ((date.amount * number) - date.discount) : ((date.amount * number) - ((date.amount * number) * (date.discount / 100)))),
                    subtotal: date.amount * number,
                    discount_amount: date.discount_option == 'direct' ? parseFloat(date.discount) : ((date.amount * number) * (date.discount / 100)),
                    pack_total: (date.pack_discount_option == 'direct' ? ((date.amount * number) - date.pack_discount) : ((date.amount * number) - ((date.amount * number) * (date.pack_discount / 100)))),
                    pack_discount_amount: date.pack_discount_option == 'direct' ? parseFloat(date.pack_discount) : ((date.amount * number) * (date.pack_discount / 100)),
                    pack_subtotal: (date.amount * number),
                    serie: series
                }
            }
            return date
        });
        setServices(totalDates);
        calculateDiscount(totalDates);
    }

    const handleInsertDisplay = list => {
        const others = datesDisplay.filter(date => date.year != year);
        setDatesDisplay([...others, ...list]);
        updateDisp([...others, ...list]);
    }

    const handleUpdateStateDates = (arr, series) => {
        const others = dates.filter(date => date.year != year);
        setDates([...others, ...arr]);
        updateDates([...others, ...arr], [...others, ...arr].length, series);
    }

    const handleGetVariety = arr => {
        const others = serie.filter(date => date.year != year && date.year != year + 1);
        setSerie([...others, ...arr]);
        return [...others, ...arr];
    }

    const handleGetVarietyPast = arr => {
        const others = serie.filter(date => date.year != year && date.year != year - 1 && date.year != year + 1);
        setSerie([...others, ...arr]);
        return [...others, ...arr];
    }

    const handleGetVarietyPastDates = arr => {
        const others = serie.filter(date => date.year != year);
        setSerie([...others, ...arr]);
        return [...others, ...arr];
    }

    const handleUpdateSome = (arr, series) => {
        let newDates = [];
        for (const element of arr) {
            if (element.range != undefined) {
                for (const monthNumber of element.range) {
                    newDates.push({ id: createID(year, monthNumber), month: getMonthName(monthNumber), monthNumber: monthNumber, year: year, active: true, tag: element.tag })
                }
            } else {
                newDates.push(element);
            }
        }
        handleUpdateStateDates(newDates, series);
    }

    const handleEvaluateVariety = arr => {
        const actualYear = arr.sort((a, b) => a.monthNumber - b.monthNumber).map(ser => ser.monthNumber);
        if (actualYear[0] < actualYear[1]) {
            actualYear.reverse();
        }

        const operation = actualYear.reduce((acum, el, index, array) => {
            if (index == 1) {
                acum = 0;
            }
            return +acum + array[index - 1] - (el + 1);
        });
        return operation
    }

    const handleValidateVariety = arr => {
        try {
            if ((timePeriods === 'gt' || timePeriods === 'gte') && services.filter(service => service.index == sku)[0].periodicity !== 'unique') {
                let ope;
                const actualYear = arr.filter(date => date.year == year).sort((a, b) => a.monthNumber - b.monthNumber).map(ser => ser.monthNumber);
                const nextYear = dates.filter(date => date.year == year + 1);
                const pastYear = dates.filter(date => date.year == year - 1);
                const resul1 = pastYear.length > 0 ? (pastYear.length == 1 ? (pastYear.map(ser => ser.monthNumber).some(x => x == 12) ? false : true) : (handleEvaluateVariety(pastYear) == 0 ? false : true)) : false;
                const resul2 = nextYear.length > 0 ? (nextYear.length == 1 ? (nextYear.map(ser => ser.monthNumber).some(x => x == 1) ? false : true) : (handleEvaluateVariety(nextYear) == 0 ? false : true)) : false;
                if (actualYear.length <= 1) {
                    if (year == formatDate(getDate(), 'YYYY')) {
                        return handleGetVariety([{ year: year, serie: nextYear.length > 0 ? (actualYear.some(x => x == 12) && nextYear.map(ser => ser.monthNumber).some(x => x == 1) || actualYear.length == 0 ? false : true) : false }, { year: year + 1, serie: resul2 }]);
                    } else {
                        return handleGetVarietyPast([{ year: year, serie: pastYear.length > 0 ? (actualYear.some(x => x == 1) && pastYear.map(ser => ser.monthNumber).some(x => x == 12) || actualYear.length == 0 ? false : true) : false }, { year: year - 1, serie: resul1 }, { year: year + 1, serie: resul2 }]);
                    }
                }

                if (actualYear[0] < actualYear[1]) {
                    actualYear.reverse();
                }

                const operation = actualYear.reduce((acum, el, index, array) => {
                    let accumulator = index == 1 ? 0 : acum;
                    return +accumulator + array[index - 1] - (el + 1);
                });
                if (year == formatDate(getDate(), 'YYYY')) {
                    ope = handleGetVariety([{ year: year, serie: operation == 0 && (nextYear.length > 0 ? actualYear.some(x => x == 12) && nextYear.map(ser => ser.monthNumber).some(x => x == 1) : true) ? false : true }, { year: year + 1, serie: resul2 }]);
                } else {
                    ope = handleGetVarietyPast([{ year: year, serie: operation == 0 && (pastYear.length > 0 ? actualYear.some(x => x == 1) && pastYear.map(ser => ser.monthNumber).some(x => x == 12) : true) && (nextYear.length > 0 ? actualYear.some(x => x == 12) && nextYear.map(ser => ser.monthNumber).some(x => x == 1) : true) ? false : true }, { year: year - 1, serie: resul1 }, { year: year + 1, serie: resul2 }]);
                }
                return ope
            } else if (timePeriods === 'lt' || timePeriods === 'lte' || services.filter(service => service.index == sku)[0].periodicity === 'unique') {
                let ope;
                ope = handleGetVarietyPastDates([{ year: year, serie: false }]);
                return ope
            }
        } catch (e) {
            console.log(e);
        }
    }

    const handleCreateRange = (arr, series) => {
        try {
            const actualYear = arr.filter(date => date.year == year).sort((a, b) => a.monthNumber - b.monthNumber);
            let length = 1;
            let list = [];
            for (let i = 1; i <= actualYear.length; i++) {
                if (i == actualYear.length || actualYear[i].monthNumber - actualYear[i - 1].monthNumber != 1) {
                    if (length == 1) {
                        list.push({ id: `${actualYear[i - length].id}`, monthNumber: actualYear[i - length].monthNumber, month: actualYear[i - length].month, year: year, active: true, tag: `${actualYear[i - length].month} ${year}` });

                    } else {
                        list.push({ id: `${actualYear[i - length].id}${actualYear[i - 1].id}`, tag: `${actualYear[i - length].month} a ${actualYear[i - 1].month} ${year}`, year: year, range: createRange(parseInt(actualYear[i - length].monthNumber), parseInt(actualYear[i - 1].monthNumber)) });
                    }
                    length = 1;
                } else {
                    length++;
                }
            }
            handleInsertDisplay(list);
            handleUpdateSome(list, series);
        } catch (e) {
            console.log(e);
        }
    }

    const handleSelectMonth = (month, id) => {
        const newMonth = dates.find(date => date.monthNumber == id && date.year == year);
        const addDate = { id: createID(year, id), month: month, monthNumber: id, year: year, active: true, tag: `${month} ${year}` };
        if (newMonth === undefined) {
            if (timePeriods === 'lt' || timePeriods === 'lte') {
                if (!(year == formatDate(getDate(), 'YYYY') && id - timeMonths[timePeriods].months_blocked >= formatDate(getDate(), 'M'))) {
                    const operation = handleValidateVariety([...dates, addDate]);
                    const isSerie = operation.some(ser => ser.serie ? true : false);
                    handleCreateRange([...dates, addDate], isSerie);
                }
            } else if (timePeriods === 'gt' || timePeriods === 'gte') {
                if (!(year == formatDate(getDate(), 'YYYY') && (services.filter(service => service.index == sku)[0].periodicity != 'bimonthly' ? (id <= (formatDate(getDate(), 'M') - timeMonths[timePeriods].months_blocked)) : (formatDate(getDate(), 'M') % 2 == 0 ? id < formatDate(getDate(), 'M') - 1 : id < formatDate(getDate(), 'M') - 2) ))) {
                    const operation = handleValidateVariety([...dates, addDate]);
                    const isSerie = operation.some(ser => ser.serie ? true : false);
                    handleCreateRange([...dates, addDate], isSerie);
                }
            } else {
                const operation = handleValidateVariety([...dates, addDate]);
                const isSerie = operation.some(ser => ser.serie ? true : false);
                handleCreateRange([...dates, addDate], isSerie);
            }
        } else {
            const monthId = id < 10 ? `0${id}` : id;
            const removeDate = dates.filter(date => date.id != `${year}-${monthId}-01`);
            const operation = handleValidateVariety(removeDate);
            const isSerie = operation.some(ser => ser.serie ? true : false);
            handleCreateRange(removeDate, isSerie);
        }
    }

    const handleSelectAllMonths = () => {
        if (year == formatDate(getDate(), 'YYYY') && (timePeriods === 'gt' || timePeriods === 'gte')) {
            let addYear = [];
            const actualMonth = services.filter(service => service.index == sku)[0].periodicity == 'bimonthly' ? (formatDate(getDate(), 'M') % 2 == 0 ? (parseInt(formatDate(getDate(), 'M')) - 1) : (parseInt(formatDate(getDate(), 'M')))) : (parseInt(formatDate(getDate(), 'M')) + timeMonths[timePeriods].month_all_selected );
            const actualNameMonth = getMonthName(parseInt(actualMonth));
            const others = dates.filter(date => date.year != year);
            const otherDates = datesDisplay.filter(date => date.year != year);
            if (dates.filter(x => x.year == year).length < (12 - (actualMonth))) {
                for (let i = actualMonth; i < months.length + 1; i++) {
                    addYear.push({ id: createID(year, i), month: getMonthName(i), monthNumber: i, year: year, active: true, tag: `${actualNameMonth} a Diciembre ${year}` });
                }
                setDates([...others, ...addYear]);
                setDatesDisplay([...otherDates, { id: parseInt(`${year}0${year}11`), tag: `${actualNameMonth} a Diciembre ${year}`, year: year, range: createRange(actualMonth, 12) }]);
                updateDates([...others, ...addYear], [...others, ...addYear].length);
                updateDisp([...otherDates, { id: parseInt(`${year}0${year}11`), tag: `${actualNameMonth} a Diciembre ${year}`, year: year, range: createRange(actualMonth, 12) }])
            } else {
                setDates([...others]);
                setDatesDisplay([...otherDates]);
                updateDates([...others], [...others].length);
                updateDisp([...otherDates])
            }
        } else if (year == formatDate(getDate(), 'YYYY') && (timePeriods === 'lt' || timePeriods === 'lte')) {
            let addYear = [];
            const actualMonth = parseInt(formatDate(getDate(), 'M') - timeMonths[timePeriods].month_all_selected);
            const actualNameMonth = getMonthName(actualMonth);
            const others = dates.filter(date => date.year != year);
            const otherDates = datesDisplay.filter(date => date.year != year);
            if (dates.filter(x => x.year == year).length < (12 - (12 - actualMonth))) {
                for (let i = 1; i < actualMonth + 1; i++) {
                    addYear.push({ id: createID(year, i), month: getMonthName(i), monthNumber: i, year: year, active: true, tag: `Enero a ${actualNameMonth} ${year}` });
                }
                setDates([...others, ...addYear]);
                setDatesDisplay([...otherDates, { id: parseInt(`${year}0${year}11`), tag: `${actualMonth !== 1 ? 'Enero a ' : ''}${actualNameMonth} ${year}`, year: year, range: createRange(1, actualMonth) }]);
                updateDates([...others, ...addYear], [...others, ...addYear].length);
                updateDisp([...otherDates, { id: parseInt(`${year}0${year}11`), tag: `${actualMonth !== 1 ? 'Enero a ' : ''}${actualNameMonth} ${year}`, year: year, range: createRange(1, actualMonth) }])
            } else {
                setDates([...others]);
                setDatesDisplay([...otherDates]);
                updateDates([...others], [...others].length);
                updateDisp([...otherDates])
            }
        } else {
            let addYear = [];
            const others = dates.filter(date => date.year != year);
            const otherDates = datesDisplay.filter(date => date.year != year);
            if (dates.filter(y => y.year == year).length < 12) {
                for (let i = 1; i < months.length + 1; i++) {
                    addYear.push({ id: createID(year, i), month: getMonthName(i), monthNumber: i, year: year, active: true, tag: `Enero a Diciembre ${year}` });
                }
                setDates([...others, ...addYear]);
                setDatesDisplay([...otherDates, { id: parseInt(`${year}0${year}11`), tag: `Enero a Diciembre ${year}`, year: year, range: createRange(1, 12) }]);
                updateDates([...others, ...addYear], [...others, ...addYear].length);
                updateDisp([...otherDates, { id: parseInt(`${year}0${year}11`), tag: `Enero a Diciembre ${year}`, year: year, range: createRange(1, 12) }])
            } else {
                setDates([...others]);
                setDatesDisplay([...otherDates]);
                updateDates([...others], [...others].length);
                updateDisp([...otherDates])
            }
        }
    }

    const handleDeleteDate = tag => {
        const deleteDate = dates.filter(date => date.tag != tag);
        const deleteDisplay = datesDisplay.filter(date => date.tag != tag);
        const operation = handleValidateVariety(deleteDate);
        const isSerie = operation.some(ser => ser.serie ? true : false);
        setDates([...deleteDate]);
        setDatesDisplay(deleteDisplay);
        updateDates([...deleteDate], [...deleteDate].length, isSerie);
        updateDisp(deleteDisplay);
    }

    return (
        <>
            <div className={`${services.filter(service => service.index == sku)[0].periodicity == 'bimonthly' && services.filter(service => service.index == sku)[0].quantity % 2 != 0 || services.filter(service => service.index == sku)[0].serie ? 'border border-v2-red-error-services-selected' : 'border border-v2-border-time'} w-full appearance-none py-2.5 mt-1.5 px-4 rounded-md text-base text-v2-text-bar-steps outline-none flex cursor-pointer`} onClick={() => setIsOpen(!isOpen)}>
                <div className="xs:w-10/12 md:w-11/12">
                    <div className="w-full flex flex-wrap gap-2">
                        {datesDisplay.map((date) => (
                            <div key={date.id} className="w-auto cursor-default" onClick={e => e.stopPropagation()}>
                                {datesDisplay.filter(date => date.year == year).length < 12 &&
                                    <div key={date.id} className="w-full bg-accent-100 text-accent-base py-2 text-2xs rounded flex items-center px-4">
                                        <div className="w-5/6 text-center w-full min-w-fit pr-3">
                                            <span className="w-full">
                                                {date.tag}
                                            </span>
                                        </div>
                                        <div className="w-1/6 cursor-pointer text-center text-xs font-bolder" onClick={() => handleDeleteDate(date.tag)}>
                                            <FontAwesomeIcon icon={faX} className="leading-3 font-bold" />
                                        </div>
                                    </div>
                                }
                            </div>
                        ))}
                    </div>
                </div>
                <div className={`${services.filter(service => service.index == sku)[0].periodicity == 'bimonthly' && services.filter(service => service.index == sku)[0].quantity % 2 != 0 ? 'text-v2-red-error-services-selected' : 'text-v2-yellow-edit-info'} xs:w-2/12 md:w-1/12 text-2xl leading-3 text-center cursor-pointer xs:pt-0 md:pt-1 flex justify-end`} onClick={() => setIsOpen(!isOpen)}>
                    <FontAwesomeIcon icon={faCalendar} />
                </div>
            </div>
            <div className="w-full flex justify-end">
                {isOpen &&
                    <>
                        <div className="xs:block md:hidden">
                            <Modal hasTitle={false} modalOpen={isOpen} modalOpenChange={setIsOpen} marginY='my-full' >
                                <div className="h-full">
                                    <div className="w-full border border-v2-border-time rounded-md py-3.5 px-4 bg-white">
                                        <div className="w-full flex items-center border-b border-dashed border-v2-border-time pb-3 select-none">
                                            <div className="w-1/6 flex justify-start">
                                                <Button width="w-7" heigth="h-6 shadow-lg" bg="bg-white" disabled={timePeriods === 'lt' || timePeriods === 'lte' ? false : ((timePeriods === 'gt' || timePeriods === 'gte') && year > formatDate(getDate(), 'YYYY')) ? false : true} onClick={() => setYear(prev => prev - 1)}>
                                                    <FontAwesomeIcon icon={faAngleLeft} className="text-v2-gray-intense-years" />
                                                </Button>
                                            </div>
                                            <div className="w-4/6 text-center text-v2-gray-intense-years text-base select-none">
                                                {year}
                                            </div>
                                            <div className="w-1/6 flex justify-end">
                                                <Button width="w-7" heigth="h-6 shadow-lg" bg="bg-white" disabled={timePeriods === 'gt' || timePeriods === 'gte' ? false : ((timePeriods === 'lt' || timePeriods === 'lte') && year < formatDate(getDate(), 'YYYY')) ? false : true} onClick={() => setYear(prev => prev + 1)}>
                                                    <FontAwesomeIcon icon={faAngleRight} className="text-v2-gray-intense-years" />
                                                </Button>
                                            </div>
                                        </div>
                                        <div className="w-full flex justify-end pt-1.5 pb-3.5 px-2">
                                            <div className="w-4/5 flex items-center justify-end">
                                            <div className={`${dates.filter(y => y.year == year).length == 12 || (year == formatDate(getDate(), 'YYYY') && (timePeriods === 'gt' || timePeriods === 'gte') && ( services.filter(service => service.index == sku)[0].periodicity == 'bimonthly' ? ( formatDate(getDate(), 'M') % 2 == 0 ? dates.filter(y => y.year == year).length == (12 - (formatDate(getDate(), 'M') - 2)) : dates.filter(y => y.year == year).length == (12 - (formatDate(getDate(), 'M') - 1))) : dates.filter(y => y.year == year).length == (12 - (parseInt(formatDate(getDate(), 'M') - timeMonths[timePeriods].months_blocked))))  ) || (year == formatDate(getDate(), 'YYYY') && (timePeriods === 'lt' || timePeriods === 'lte') && dates.filter(y => y.year == year).length == (12 - (12 - parseInt(formatDate(getDate(), 'M') - timeMonths[timePeriods].month_all_selected)))) ? 'bg-v2-green-status text-white border-v2-green-border-checkbox' : 'bg-v2-gray-all-months border-v2-gray-radio-border text-v2-gray-all-months'} w-4 h-4 border rounded-sm cursor-pointer flex items-center justify-center text-xs leading-3 pl-0.5`} onClick={handleSelectAllMonths}>
                                                    <FontAwesomeIcon icon={faCheck} />
                                                </div>
                                                <span className={`text-v2-gray-title-client text-xs underline pl-1`}>
                                                    Seleccionar todo
                                                </span>
                                            </div>
                                        </div>
                                        <div className="w-full grid grid-cols-3 gap-2 text-2xs text-center uppercase">
                                            {months.map((month, id) => (
                                                <div key={id} className={`${dates.some(date => date.monthNumber - 1 == id && date.year == year && date.active) ? 'bg-v2-blue-text-login text-white' : 'bg-accent-100 text-accent-base'} rounded  ${((timePeriods === 'lt' || timePeriods === 'lte') && year == formatDate(getDate(), 'YYYY') && id > formatDate(getDate(), 'M') - timeMonths[timePeriods].month) ? 'bg-v2-gray-bg-discount opacity-40 text-v2-input-text cursor-not-allowed' : ((timePeriods === 'gt' || timePeriods === 'gte') && year == formatDate(getDate(), 'YYYY') && (services.filter(service => service.index == sku)[0].periodicity == 'bimonthly' ? ( formatDate(getDate(), 'M') % 2 == 0 ? id < formatDate(getDate(), 'M') - 2 : id < formatDate(getDate(), 'M') - 1) : (id < formatDate(getDate(), 'M') - timeMonths[timePeriods].month ))) ? 'bg-v2-gray-bg-discount opacity-40 text-v2-input-text cursor-not-allowed' : ''} flex justify-center items-center p-3 cursor-pointer`} onClick={() => handleSelectMonth(month, id + 1)}>
                                                    {month}
                                                </div>
                                            ))}
                                        </div>
                                        <div className='w-full flex justify-end mt-2'>
                                            <div className='w-1/2'>
                                                <Button heigth='h-10' onClick={() => setIsOpen(!isOpen)}>
                                                    Aceptar
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                        </div>
                        <div className="xs:hidden md:block max-w-full w-1/2 absolute border border-v2-border-time rounded-md py-3.5 px-4 bg-white mt-1 z-50" >
                            <div className="w-full flex items-center border-b border-dashed border-v2-border-time pb-3 select-none">
                                <div className="w-1/6 flex justify-start">
                                    <Button width="w-7" heigth="h-6 shadow-lg" bg="bg-white" disabled={timePeriods === 'lt' || timePeriods === 'lte' || timePeriods === 'all' ? false : ((timePeriods === 'gt' || timePeriods === 'gte') && year > formatDate(getDate(), 'YYYY')) ? false : true} onClick={() => setYear(prev => prev - 1)}>
                                        <FontAwesomeIcon icon={faAngleLeft} className="text-v2-gray-intense-years" />
                                    </Button>
                                </div>
                                <div className="w-4/6 text-center text-v2-gray-intense-years text-base select-none">
                                    {year}
                                </div>
                                <div className="w-1/6 flex justify-end">
                                    <Button width="w-7" heigth="h-6 shadow-lg" bg="bg-white" disabled={timePeriods === 'gt' || timePeriods === 'gte' || timePeriods === 'all' ? false : ((timePeriods === 'lt' || timePeriods === 'lte') && year < formatDate(getDate(), 'YYYY')) ? false : true} onClick={() => setYear(prev => prev + 1)}>
                                        <FontAwesomeIcon icon={faAngleRight} className="text-v2-gray-intense-years" />
                                    </Button>
                                </div>
                            </div>
                            <div className="w-full flex justify-end pt-1.5 pb-3.5 px-2">
                                <div className="w-4/5 flex items-center justify-end">
                                <div className={`${dates.filter(y => y.year == year).length == 12 || (year == formatDate(getDate(), 'YYYY') && (timePeriods === 'gt' || timePeriods === 'gte') && ( services.filter(service => service.index == sku)[0].periodicity == 'bimonthly' ? ( formatDate(getDate(), 'M') % 2 == 0 ? dates.filter(y => y.year == year).length == (12 - (formatDate(getDate(), 'M') - 2)) : dates.filter(y => y.year == year).length == (12 - (formatDate(getDate(), 'M') - 1))) : dates.filter(y => y.year == year).length == (12 - (parseInt(formatDate(getDate(), 'M') - timeMonths[timePeriods].months_blocked))))  ) || (year == formatDate(getDate(), 'YYYY') && (timePeriods === 'lt' || timePeriods === 'lte') && dates.filter(y => y.year == year).length == (12 - (12 - parseInt(formatDate(getDate(), 'M') - timeMonths[timePeriods].month_all_selected)))) ? 'bg-v2-green-status text-white border-v2-green-border-checkbox' : 'bg-v2-gray-all-months border-v2-gray-radio-border text-v2-gray-all-months'} w-4 h-4 border rounded-sm cursor-pointer flex items-center justify-center text-xs leading-3 pl-0.5`} onClick={handleSelectAllMonths}>
                                        <FontAwesomeIcon icon={faCheck} />
                                    </div>
                                    <span className='text-v2-gray-title-client text-xs underline pl-1 select-none'>
                                        Seleccionar todo
                                    </span>
                                </div>
                            </div>
                            <div className="w-full grid grid-cols-3 lg:grid-cols-4 gap-2 text-2xs text-center uppercase">
                                {months.map((month, id) => (
                                    <div key={id} className={`${dates.some(date => date.monthNumber - 1 == id && date.year == year && date.active) ? 'bg-v2-blue-text-login text-white' : 'bg-accent-100 text-accent-base'} rounded  ${((timePeriods === 'lt' || timePeriods === 'lte') && year == formatDate(getDate(), 'YYYY') && id > formatDate(getDate(), 'M') - timeMonths[timePeriods].month) ? 'bg-v2-gray-bg-discount opacity-40 text-v2-input-text cursor-not-allowed' : ((timePeriods === 'gt' || timePeriods === 'gte') && year == formatDate(getDate(), 'YYYY') && (services.filter(service => service.index == sku)[0].periodicity == 'bimonthly' ? ( formatDate(getDate(), 'M') % 2 == 0 ? id < formatDate(getDate(), 'M') - 2 : id < formatDate(getDate(), 'M') - 1) : (id < formatDate(getDate(), 'M') - timeMonths[timePeriods].month ))) ? 'bg-v2-gray-bg-discount opacity-40 text-v2-input-text cursor-not-allowed' : ''} flex justify-center items-center p-3 cursor-pointer`} onClick={() => handleSelectMonth(month, id + 1)}>
                                        {month}
                                    </div>
                                ))}
                            </div>
                            <div className='w-full flex justify-end mt-2'>
                                <div className='w-1/2'>
                                    <Button heigth='h-10' onClick={() => setIsOpen(!isOpen)}>
                                        Aceptar
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </>
    )
}

DateRangePicker.propTypes = {
    sku: PropTypes.any,
    setServices: PropTypes.any,
    services: PropTypes.any,
    setServicesDisplay: PropTypes.any,
    servicesDisplay: PropTypes.any,
    resumeAmounts: PropTypes.any,
    setResumeAmounts: PropTypes.any,
    time: PropTypes.string
}

DateRangePicker.defaultProps = {
    time: 'gte'
}

export default DateRangePicker