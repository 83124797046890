import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard, faMoneyBill1Wave, faMoneyBillTransfer } from '@fortawesome/free-solid-svg-icons';
import { faPaypal } from '@fortawesome/free-brands-svg-icons'
import PrimaryButton from '../../../tailwindUI/PrimaryButton';
import Input from '../../../tailwindUI/Input';
//import InputWithLabel from '../../../tailwindUI/InputWithLabel';
import Select from '../../../tailwindUI/Select';
import SolidRadioGroup from '../../../tailwindUI/SolidRadioGroup';
import CheckboxGroup from '../../../tailwindUI/CheckboxGroup';
import useFormatterCurrency from '../../../hooks/useFormatterCurrency';
import useFormatDate from '../../../hooks/useFormatDate';
import DatePicker, { utils } from '../../../tailwindUI/DatePicker';
import Tooltip from '../../../tailwindUI/Tooltip';
//import Alert from '../../../tailwindUI/Alert';
import { createUUID } from '../../../hooks/useUUID';
import usePaymentRules from '../../../hooks/usePaymentRules';

const { getToday, toNativeDate, isBeforeDate, addDays, addMonths, getFirstDayOfNextMonth, getLastDayOfMonth } = utils();

const paymentTypes = [
    { id: 1, title: 'Cobro único', allowedPaymentMethods: ['card', 'paypal', 'cash', 'transfer'] },
    { id: 2, title: 'Cobro diferido', allowedPaymentMethods: ['card'] },
    { id: 3, title: 'Suscripción', allowedPaymentMethods: ['card'] }
]

const paymentMethods = [
    {
        id: 1,
        title: "Tarjeta",
        value: "card",
        icon: <FontAwesomeIcon icon={faCreditCard} />,
        disabled: false
    },
    {
        id: 2,
        title: "Paypal",
        value: "paypal",
        icon: <FontAwesomeIcon icon={faPaypal} />,
        disabled: false
    },
    {
        id: 3,
        title: "Efectivo",
        value: "cash",
        icon: <FontAwesomeIcon icon={faMoneyBill1Wave} />,
        disabled: false
    },
    {
        id: 4,
        title: "Transferencia",
        value: "transfer",
        icon: <FontAwesomeIcon icon={faMoneyBillTransfer} />,
        disabled: false
    }
];

const installments = [
    {
        id: 1,
        title: "Un solo pago",
        values: [1],
        minimumAmount: 0
    },
    {
        id: 2,
        title: "Hasta 3 meses sin intereses",
        values: [1, 3],
        minimumAmount: 2500
    },
    {
        id: 3,
        title: "Hasta 6 meses sin intereses",
        values: [1, 3, 6],
        minimumAmount: 6000
    },
    {
        id: 4,
        title: "Hasta 9 meses sin intereses",
        values: [1, 3, 6, 9],
        minimumAmount: 6000
    },
    {
        id: 5,
        title: "Hasta 12 meses sin intereses",
        values: [1, 3, 6, 9, 12],
        minimumAmount: 6000
    }
];

const accountingServicesID = JSON.parse(process.env.REACT_APP_ACCOUNTING_SERVICES_IDS) || [];

const paymentInterval = [
    {
        id: 1,
        title: "Mensual",
        value: "month",
        frequency: 1
    },
    /*{
        id: 2,
        title: "Semanal",
        value: "week",
        frequency: 1
    },
    {
        id: 3,
        title: "Quincenal",
        value: "half_month",
        frequency: 1
    },*/
    {
        id: 4,
        title: "Bimestral",
        value: "month",
        frequency: 2
    },
    {
        id: 5,
        title: "Trimestral",
        value: "month",
        frequency: 3
    },
    {
        id: 6,
        title: "Semestral",
        value: "month",
        frequency: 6
    },
    {
        id: 7,
        title: "Anual",
        value: "year",
        frequency: 1
    }
];

function PaymentAgreements({ servicePeriods, setStep, setPaymentInstruction, servicesToPay }) {

    const useUUID = createUUID();
    const [errors, setErrors] = useState(null);
    const [selectedPaymentType, setSelectedPaymentType] = useState(paymentTypes[0]);
    const [paymentMethodsItems, setPaymentMethodsItems] = useState(paymentMethods);
    const [selectedPaymentMethods, setSelectedPaymentMethods] = useState([paymentMethods[0]]);
    const [paymentConditions, setPaymentConditions] = useState(null);
    const [buttonIsDisabled, setButtonIsDisabled] = useState(false);
    const totalAmount = servicesToPay.map(service => service.total).reduce((prev, curr) => prev + curr, 0);

    useEffect(() => {
        setPaymentMethodsItems(paymentMethods.map(method => {
            return {
                ...method,
                disabled: !selectedPaymentType.allowedPaymentMethods.includes(method.value)
            }
        }))
    }, [selectedPaymentType]);

    useEffect(() => {
        if (!errors || !errors?.methods) return;
        else if (errors?.methods && selectedPaymentMethods?.length > 0) {
            setErrors({ ...errors, methods: null });
        }
    }, [selectedPaymentMethods]);

    useEffect(() => {
        let disabled = paymentConditions == null || selectedPaymentMethods.length == 0;
        setButtonIsDisabled(disabled);
    }, [paymentConditions, selectedPaymentMethods]);

    const handleNextStep = (e) => {
        e.preventDefault();

        if (!paymentConditions) return;
        if (!selectedPaymentMethods || selectedPaymentMethods.length == 0) {
            setErrors({ ...errors, methods: 'Selecciona al menos un método de pago' });
            return;
        }

        const completedServiceToPay = servicesToPay.map(service => {
            return {
                ...service,
                completed: true
            }
        });

        let instruction = {
            id: `ins_${useUUID}`,
            draft: true,
            ...paymentConditions,
            services: completedServiceToPay,
            allowed_payment_methods: selectedPaymentMethods.sort((a, b) => a.id - b.id ).map(method => method.value)
        }

        if (setPaymentInstruction) setPaymentInstruction(instruction);
        if (setStep) setStep(4);
    }

    return (
        <div className='flex flex-col gap-4'>
            <div className='flex gap-4 flex-col lg:flex-row'>
                <div className='w-full lg:w-2/5 h-full'>
                    <ServicesResume services={servicesToPay} servicePeriods={servicePeriods} />
                </div>
                <div className="w-full lg:w-3/5 h-full bg-white shadow rounded-lg ring-1 ring-gray-200">
                    <div className="px-4 py-5 sm:px-6">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Condiciones de cobro</h3>
                    </div>
                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div className='w-full h-full flex flex-col justify-between gap-5'>
                            <div className="-mx-6 px-6 pb-5 border-b border-gray-200">
                                <h3 className="font-medium leading-6 text-gray-900 mb-2">Tipo de cobro:</h3>
                                <div className="w-full hidden md:block">
                                    <SolidRadioGroup
                                        items={paymentTypes}
                                        selectedItem={selectedPaymentType}
                                        setSelectedItem={setSelectedPaymentType}
                                        horizontal />
                                </div>
                                <div className="w-full block md:hidden">
                                    <SolidRadioGroup
                                        items={paymentTypes}
                                        selectedItem={selectedPaymentType}
                                        setSelectedItem={setSelectedPaymentType} />
                                </div>
                            </div>

                            <div className="-mx-6 px-6 pb-5 border-b border-gray-200">
                                <h3 className="font-medium leading-6 text-gray-900 mb-2">Métodos de pago permitidos:</h3>
                                <div className="col-span-6">
                                    <div className="hidden md:block">
                                        <CheckboxGroup
                                            items={paymentMethodsItems}
                                            selectedItems={selectedPaymentMethods}
                                            setSelectedItems={setSelectedPaymentMethods}
                                            error={errors?.methods} />
                                    </div>
                                    <div className="block md:hidden">
                                        <CheckboxGroup
                                            items={paymentMethodsItems}
                                            selectedItems={selectedPaymentMethods}
                                            setSelectedItems={setSelectedPaymentMethods}
                                            horizontal={false}
                                            error={errors?.methods} />
                                    </div>
                                </div>
                            </div>

                            <div className='w-full'>
                                <h3 className="font-medium leading-6 text-gray-900  mb-2">Condiciones de pago:</h3>
                                {selectedPaymentType.id == 1 && (
                                    <SinglePaymentForm
                                        totalAmount={totalAmount}
                                        setPaymentConditions={setPaymentConditions} />
                                )}
                                {selectedPaymentType.id == 2 && (
                                    <DeferredPaymentForm
                                        totalAmount={totalAmount}
                                        services={servicesToPay}
                                        setPaymentConditions={setPaymentConditions} />
                                )}
                                {selectedPaymentType.id == 3 && (
                                    <RecurrentPaymentForm
                                        setPaymentConditions={setPaymentConditions} />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex justify-end pb-12 md:pb-4'>
                <div className='w-full md:w-1/3'>
                    <PrimaryButton disabled={buttonIsDisabled} isFullWidth onClick={handleNextStep}>
                        Siguiente
                    </PrimaryButton>
                </div>
            </div>
        </div>
    )
}

const SinglePaymentForm = ({ totalAmount, setPaymentConditions }) => {

    const { installments: getInstallmentsEnabled } = usePaymentRules();
    const [installmentsEnabled, setInstallmentsEnabled] = useState(installments);
    const [selectedMsi, setSelectedMsi] = useState(installments[0]?.title || null);
    const [expirationDate, setExpirationDate] = useState(addDays(7));

    useEffect(() => {
        setInstallmentsEnabled(getInstallmentsEnabled(totalAmount));
    }, [totalAmount]);

    useEffect(() => {
        if (!setPaymentConditions) return;
        let installment = installments.find(installment => installment.title == selectedMsi);
        let enabled = installment.id != 1;
        setPaymentConditions({
            payment_type: "unique",
            recurrent: false,
            expires_at: toNativeDate(expirationDate).getTime(),
            monthly_installments_enabled: enabled,
            ...(enabled && {
                monthly_installments_options: installment.values
            })
        });
    }, [selectedMsi, expirationDate]);

    return (
        <div className='w-full grid grid-cols-6 gap-4'>
            <div className="col-span-6 md:col-span-3">
                <Select
                    label="Meses sin intereses"
                    listOptions={installmentsEnabled}
                    value={selectedMsi}
                    setValue={setSelectedMsi}
                    needed={installmentsEnabled.length > 1}
                    isDisabled={installmentsEnabled.length <= 1} />
            </div>

            <div className="col-span-6 md:col-span-3">
                <DatePicker
                    label="Fecha límite de pago"
                    placeholder="Fecha límite de pago"
                    needed
                    value={expirationDate}
                    onChange={setExpirationDate}
                    minimumDate={getToday()} />
            </div>
        </div>
    )
}

const DeferredPaymentForm = ({ totalAmount, services, setPaymentConditions }) => {
    const { formatDate } = useFormatDate();
    const { formatterCurrency } = useFormatterCurrency();
    const { partialities: getPartialitiesEnabled } = usePaymentRules();
    const [errors, setErrors] = useState(null);
    const [startDate, setStartDate] = useState(getFirstDayOfNextMonth());
    const [partialitiesNumber, setPartialitiesNumber] = useState(2);
    const [applyChargeDisabled, setApplyChargeDisabled] = useState(false);
    const [applyCharge, setApplyCharge] = useState(true);
    const [selectedInterval, setSelectedInterval] = useState(paymentInterval[0]?.title || null);
    const [minimumExpirationDate, setMinimumExpirationDate] = useState(getToday());
    const [expirationDate, setExpirationDate] = useState(addDays(7));
    const [partialities, setPartialities] = useState([]);
    const [partialsList, setPartialsList] = useState([]);
    const hasServiceAccounting = services.some(service => accountingServicesID.includes(service.service_id||service.id));
    const numberOfPeriods = services.filter(service => accountingServicesID.includes(service.service_id||service.id)).map(service => (service.periods||service.dates).length).reduce((acc, cur) => acc + cur, 0);
    useEffect(() => {
        setPartialsList(getPartialitiesEnabled(hasServiceAccounting, numberOfPeriods));
        if(hasServiceAccounting){
            setPartialitiesNumber(3);
        } 
        return () => {
            setPartialsList([]);
        }
    }, [totalAmount]);
    
    useEffect(() => {
        //Creation of partialities array to render inputs
        let partialities = [];
        const total = (totalAmount / partialitiesNumber);
        const amount = (total * 100);
        const interval = paymentInterval.find(interval => interval.title == selectedInterval);

        for (let i = 0; i < partialitiesNumber; i++) {
            let date = 'Now';
            let prevPartiality = partialities.at(-1);
            let partialityNumber = applyCharge ? i - 1 : i;

            if (applyCharge && i == 0) {
                partialities.push({
                    id: 1,
                    date,
                    amount,
                    total
                })
                continue;
            }

            if (interval.value == 'month') {
                date = addMonths(partialityNumber * interval.frequency, toNativeDate(startDate));
                if (startDate.day > 28 && prevPartiality && date.day < prevPartiality?.date?.day) {
                    let year = date.year > prevPartiality?.date?.year ? prevPartiality?.date?.year : date.year
                    let nextDate = { year: year, month: prevPartiality?.date.month + interval.frequency, day: 1 };
                    date = getLastDayOfMonth(toNativeDate(nextDate))
                }
            } else {
                let days = interval.value == 'week' ? 7 : 15//15 for half_month 
                date = addDays(partialityNumber * days, toNativeDate(startDate));
            }

            partialities.push({
                id: partialityNumber + 1,
                date,
                amount,
                total
            })
        }
        setPartialities(partialities);
    }, [partialitiesNumber, startDate, selectedInterval, applyCharge]);

    useEffect(() => {
        let isBefore = isBeforeDate(getToday(), startDate);
        setApplyChargeDisabled(!isBefore);
        if (applyCharge && !isBefore) {
            setApplyCharge(false);
            return;
        }

        let nativeStartDate = toNativeDate(startDate);
        let minimumDate = applyCharge ? getToday() : startDate;
        let expirationDate = applyCharge ? addDays(7) : addDays(7, nativeStartDate);
        setMinimumExpirationDate(minimumDate);
        setExpirationDate(expirationDate);
    }, [applyCharge, startDate]);

    useEffect(() => {
        let err = {}

        if (!partialitiesNumber || partialitiesNumber === "") err.partialitiesNumber = "Ingresa el número de parcialidades";

        let interval = paymentInterval.find(interval => interval.title == selectedInterval);
        if (!interval) err.interval = "Selecciona el intervalo de cobro";

        if (Object.keys(err).length > 0) {
            setErrors(err);
            return null;
        }

        setErrors(null);
        if (!setPaymentConditions) return;

        const paymentDates = partialities.map(partiality => {
            return {
                date: partiality.date == "Now" ? partiality.date  : toNativeDate(partiality.date).getTime(),
                amount: partiality.amount,
                total: partiality.total
            }
        });

        setPaymentConditions({
            expires_at: toNativeDate(expirationDate).getTime(),
            subscription_info: {
                start_date: toNativeDate(startDate).getTime(),
                interval: interval.value,
                name: "Fixat - Servicios contables",
                frequency: interval.frequency,
                expiry_count: parseInt(partialitiesNumber),
                apply_charge: applyCharge,
                currency: "mxn",
                status_after_retry: "unpaid"
            },
            payment_type: "partial",
            recurrent: true,
            payment_dates: paymentDates
        });
    }, [startDate, applyCharge, partialitiesNumber, selectedInterval, expirationDate, partialities]);

    useEffect(() => {
        if (!errors) return;
        let errorValues = Object.values(errors);
        if (errorValues?.some(value => value != null || value != undefined)
            && setPaymentConditions) setPaymentConditions(null);
    }, [errors]);

    return (
        <div className='w-full grid grid-cols-6 gap-4'>
            <div className="col-span-6 md:col-span-3">
                <DatePicker
                    label="Fecha de inicio de cobro"
                    placeholder="Fecha"
                    needed
                    value={startDate}
                    onChange={setStartDate}
                    minimumDate={getToday()} />
                <div className="relative flex items-start mt-1">
                    <div className="flex h-5 items-center">
                        <input
                            id="apply-charge"
                            name="apply-charge"
                            type="checkbox"
                            disabled={applyChargeDisabled}
                            checked={applyCharge}
                            onChange={(e) => setApplyCharge(e.target.checked)}
                            className={`${!applyChargeDisabled && 'cursor-pointer'} h-4 w-4 rounded border-gray-300 text-v2-blue-text-login focus:ring-0 focus:ring-transparent`}
                        />
                    </div>
                    <div className="ml-2 text-sm">
                        <label htmlFor="apply-charge" className={`${applyChargeDisabled ? 'text-gray-300' : 'text-gray-700 cursor-pointer'} font-medium  select-none`}>
                            Realizar cobro al suscribir
                        </label>
                    </div>
                </div>
            </div>

            <div className="col-span-6 md:col-span-3">
                <Select
                    label="Número de parcialidades"
                    listOptions={ partialsList }
                    value={partialitiesNumber.toString()}
                    isDisabled={ partialsList.length === 0 }
                    setValue={(value) => setPartialitiesNumber(parseInt(value))}
                    needed />
            </div>

            <div className="col-span-6 md:col-span-3">
                <Select
                    label="Frecuencia de cobro"
                    listOptions={paymentInterval}
                    value={selectedInterval}
                    setValue={setSelectedInterval}
                    needed />
            </div>

            <div className="col-span-6 md:col-span-3">
                <DatePicker
                    label="Fecha límite de pago"
                    placeholder="Fecha límite de pago"
                    needed
                    value={expirationDate}
                    onChange={setExpirationDate}
                    minimumDate={minimumExpirationDate} />
            </div>

            <div className='col-span-6'>
                <h3 className="font-medium leading-6 text-gray-900 mb-2 mt-1">Parcialidades:</h3>
                <div className='divide-y'>
                    {partialities?.map((partiality, i) => (
                        <div key={i} className='grid grid-cols-2 gap-4 py-2 first:pt-0 last:pb-0'>
                            <div className="col-span-1">
                                <span className="block text-sm font-medium text-gray-700 block">Fecha</span>
                                <span className="block sm:text-sm text-gray-800">
                                    {partiality.date == 'Now' ? 'Al suscribir' : (formatDate(toNativeDate(partiality.date), 'DD MMMM YYYY') || '')}
                                </span>
                            </div>

                            <div className="col-span-1">
                                <span className="block text-sm font-medium text-gray-700 block">Monto</span>
                                <span className="block sm:text-sm text-gray-800">
                                    {formatterCurrency(partiality.total)}
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

/*const DeferredPaymentForm = ({ totalAmount, setPaymentConditions }) => {

    const { formatterCurrency } = useFormatterCurrency();
    const [errors, setErrors] = useState(null);
    const [partialitiesNumber, setPartialitiesNumber] = useState(2);
    const [validDays, setValidDays] = useState(7);
    const [partialities, setPartialities] = useState([]);

    useEffect(() => {
        //Creation of partialities array to render inputs
        let partialities = [];
        const total = (totalAmount / partialitiesNumber);
        const amount = (total * 100);
        for (let i = 0; i < partialitiesNumber; i++) {
            let date = addMonths(i);
            let minimumDate = addDays(i);
            partialities.push({
                id: i + 1,
                date,
                minimumDate,
                amount,
                total
            })
        }
        setPartialities(partialities);
    }, [partialitiesNumber]);

    useEffect(() => {

        let err = {};

        if (partialitiesNumber === "" || partialitiesNumber < 2) err.partialities = 'Ingresa al menos 2 parcialidades';

        if (validDays === "" || validDays <= 0) err.validDays = 'Ingresa al menos 1 día de vigencia';

        if (!partialities || partialities.length == 0) return;

        if (partialities.some(partiality => partiality.dateError != null || partiality.dateError != undefined)) err.dateError = 'Ingresa una fecha menor a la siguiente';
        else if (partialities.some(partiality => partiality.amountError != null || partiality.amountError != undefined)) err.amountError = 'Ingresa correctamente el precio';

        let amountError;
        const amount = partialities.map(partiality => parseFloat(partiality.amount)).reduce((prev, curr) => prev + curr, 0);
        const diff = Math.abs(totalAmount - amount);

        if (isNaN(diff)) amountError = 'Verifica los montos agregados';
        else if (amount > totalAmount) amountError = `Ojo: te pasaste por ${formatterCurrency(diff)}`;
        else if (amount < totalAmount) amountError = `Ojo: faltan ${formatterCurrency(diff)} por agregar`;
        if (amountError) err.amount = amountError;

        if (Object.values(err).length > 0) {
            setErrors(err);
            return;
        }

        const paymentDates = partialities.map(partiality => {
            return {
                date: toNativeDate(partiality.date).getTime(),
                amount: partiality.amount,
                total: (partiality.amount / 100)
            }
        });

        setErrors(null);
        setPaymentConditions({
            payment_type: "partial",
            recurrent: false,
            valid_days: validDays,
            payment_dates: paymentDates,
            links: paymentDates.map((_, i) => {
                return {
                    title: `Pago ${i+1}`,
                    url: 'https://sandbox.fixat.mx/payment-link/pay_4bbd12f3-1318-4d8a-aef3-ecd0674abc64',
                    short_url: 'https://cutt.ly/60j6Tj8'
                }
            })
        });

    }, [partialitiesNumber, validDays, partialities]);

    useEffect(() => {
        if (!errors) return;
        let errorValues = Object.values(errors);
        if (errorValues?.some(value => value != null || value != undefined)
            && setPaymentConditions) setPaymentConditions(null);
    }, [errors]);

    const handleModifyDateOf = (partiality, date) => {
        const tempPartialities = Array.from(partialities).map(item => {
            if (item.id == partiality.id) {
                return { ...item, date: date };
            }
            return item;
        });

        setPartialities(tempPartialities.map((partiality, i) => {
            let dateError;
            if (i >= 0 && i < partialities.length - 1) {
                const nextPartiality = tempPartialities[i + 1];
                dateError = isBeforeDate(nextPartiality.date, partiality.date) ? 'Ingresa una fecha menor a la siguiente' : null;
            }
            return { ...partiality, dateError };
        }));
    }

    const handleModifyAmountOf = (partiality, e) => {
        const value = e.target.value.replace(/[^0-9.]/g, "");
        setPartialities(partialities.map(item => {
            if (item.id == partiality.id) {
                const amountError = (value === '' || isNaN(value)) ? 'Ingresa correctamente el precio' : null;
                return { ...item, amount: value, amountError };
            }
            return item;
        }));
    }

    return (
        <div className='w-full grid grid-cols-6 gap-4'>
            <div className="col-span-6 md:col-span-3">
                <Input
                    label="Número de parcialidades"
                    placeholder="Número de parcialidades"
                    inputMode="numeric"
                    type="text"
                    value={partialitiesNumber}
                    onChange={(e) => setPartialitiesNumber(e.target.value.replace(/\D/g, ""))}
                    autoComplete="off"
                    needed
                    maxLength={2}
                    error={errors?.partialities} />
            </div>

            <div className="col-span-6 md:col-span-3">
                <Input
                    label="Días de vigencia"
                    placeholder="Días de vigencia"
                    inputMode="numeric"
                    type="text"
                    value={validDays}
                    onChange={(e) => setValidDays(e.target.value.replace(/\D/g, ""))}
                    autoComplete="off"
                    needed
                    maxLength={3}
                    error={errors?.validDays} />
            </div>

            {partialities?.map((partiality, i) => (
                <div key={i} className='col-span-6 grid grid-cols-6 gap-4 border-t border-dashed pt-4'>
                    <div className="col-span-6 md:col-span-3">
                        <DatePicker
                            label={`Fecha de cobro ${i + 1}`}
                            placeholder="Fecha"
                            needed
                            value={partiality.date}
                            onChange={(date) => handleModifyDateOf(partiality, date)}
                            error={partiality.dateError}
                            minimumDate={partiality.minimumDate} />
                    </div>

                    <div className="col-span-6 md:col-span-3">
                        <InputWithLabel
                            label="Precio"
                            inputMode="numeric"
                            type="text"
                            leftLabel="$"
                            value={partiality.amount}
                            onChange={(e) => handleModifyAmountOf(partiality, e)}
                            autoComplete="off"
                            needed
                            error={partiality.amountError} />
                    </div>
                </div>
            )
            )}

            <div className='col-span-6'>
                <Alert title={errors?.amount} show={errors?.amount != null} />
            </div>
        </div>
    )
}*/

const RecurrentPaymentForm = ({ setPaymentConditions }) => {

    const [errors, setErrors] = useState(null);
    const [startDate, setStartDate] = useState(getFirstDayOfNextMonth());
    const [paymentsNumber, setPaymentsNumber] = useState("0");
    const [applyChargeDisabled, setApplyChargeDisabled] = useState(false);
    const [applyCharge, setApplyCharge] = useState(true);
    const [selectedInterval, setSelectedInterval] = useState(paymentInterval[0]?.title || null);
    const [minimumExpirationDate, setMinimumExpirationDate] = useState(getToday());
    const [expirationDate, setExpirationDate] = useState(addDays(7));

    useEffect(() => {
        let err = {}

        if (!paymentsNumber || paymentsNumber === "") err.paymentsNumber = "Ingresa el número de cobros";

        let interval = paymentInterval.find(interval => interval.title == selectedInterval);
        if (!interval) err.interval = "Selecciona el intervalo de cobro";

        if (Object.keys(err).length > 0) {
            setErrors(err);
            return null;
        }

        setErrors(null);
        if (!setPaymentConditions) return;
        setPaymentConditions({
            payment_type: "subscription",
            recurrent: true,
            expires_at: toNativeDate(expirationDate).getTime(),
            subscription_info: {
                start_date: toNativeDate(startDate).getTime(),
                interval: interval.value,
                name: "Fixat - Servicios contables",
                frequency: interval.frequency,
                expiry_count: parseInt(paymentsNumber),
                apply_charge: applyCharge,
                currency: "mxn",
                status_after_retry: "unpaid"
            }
        });
    }, [startDate, applyCharge, paymentsNumber, selectedInterval, expirationDate]);

    useEffect(() => {
        let isBefore = isBeforeDate(getToday(), startDate);
        setApplyChargeDisabled(!isBefore);
        if (applyCharge && !isBefore) {
            setApplyCharge(false);
            return;
        }
        let nativeStartDate = toNativeDate(startDate);
        let minimumDate = applyCharge ? getToday(): startDate;
        let expirationDate = applyCharge ? addDays(7) : addDays(7, nativeStartDate);
        setMinimumExpirationDate(minimumDate);
        setExpirationDate(expirationDate);
    }, [applyCharge, startDate]);

    useEffect(() => {
        if (!errors) return;
        let errorValues = Object.values(errors);
        if (errorValues?.some(value => value != null || value != undefined)
            && setPaymentConditions) setPaymentConditions(null);
    }, [errors]);

    return (
        <div className='w-full grid grid-cols-6 gap-4'>
            <div className="col-span-6 md:col-span-3">
                <DatePicker
                    label="Fecha de inicio de cobro"
                    placeholder="Fecha"
                    needed
                    value={startDate}
                    onChange={setStartDate}
                    minimumDate={getToday()} />
                <div className="relative flex items-start mt-1">
                    <div className="flex h-5 items-center">
                        <input
                            id="apply-charge"
                            name="apply-charge"
                            type="checkbox"
                            disabled={applyChargeDisabled}
                            checked={applyCharge}
                            onChange={(e) => setApplyCharge(e.target.checked)}
                            className={`${!applyChargeDisabled && 'cursor-pointer'} h-4 w-4 rounded border-gray-300 text-v2-blue-text-login focus:ring-0 focus:ring-transparent`}
                        />
                    </div>
                    <div className="ml-2 text-sm">
                        <label htmlFor="apply-charge" className={`${applyChargeDisabled ? 'text-gray-300' : 'text-gray-700 cursor-pointer'} font-medium  select-none`}>
                            Realizar cobro al suscribir
                        </label>
                    </div>
                </div>
            </div>

            <div className="col-span-6 md:col-span-3">
                <Input
                    label="Número de cobros"
                    placeholder="Número de cobros"
                    rightIcon={<Tooltip title="Número total de cobros que se realizarán. 0 es indefinido" />}
                    inputMode="numeric"
                    type="text"
                    autoComplete="off"
                    needed
                    value={paymentsNumber}
                    onChange={(e) => setPaymentsNumber(e.target.value.replace(/\D/g, ""))}
                    maxLength={2}
                    error={errors?.paymentsNumber} />
            </div>

            <div className="col-span-6 md:col-span-3">
                <Select
                    label="Frecuencia de cobro"
                    listOptions={paymentInterval}
                    value={selectedInterval}
                    setValue={setSelectedInterval}
                    needed />
            </div>

            <div className="col-span-6 md:col-span-3">
                <DatePicker
                    label="Fecha límite de pago"
                    placeholder="Fecha límite de pago"
                    needed
                    value={expirationDate}
                    onChange={setExpirationDate}
                    minimumDate={minimumExpirationDate} />
            </div>
        </div>
    )
}

export function ServicesResume({ services, servicePeriods }) {

    const { formatterCurrency } = useFormatterCurrency();
    const [serviceSetails, setServiceSetails] = useState(services);

    const openServiceDetails = index => {
        setServiceSetails(serviceSetails.map((service, i) => {
            let open = !service.resume_open && index == i
            return {
                ...service,
                resume_open: open
            }
        }))
    }

    return (
        <div className='overflow-hidden w-full h-full bg-white shadow rounded-lg ring-1 ring-gray-200 transition-all'>
            <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg font-medium leading-6 text-gray-900">Servicios seleccionados</h3>
            </div>
            <div className="border-t border-gray-200">
                <dl className="space-y divide-y divide-gray-200">
                    {serviceSetails.map((service, i) => (
                        <div key={service.sku} className="px-4 py-4 sm:px-6">
                            <>
                                <dt className="text-base">
                                    <button onClick={() => openServiceDetails(i)} className="flex w-full items-start justify-between text-left text-gray-400 gap-2">
                                        <span className="text-gray-900">{service.name}</span>
                                        <div className='flex gap-2'>
                                            <div className='text-right'>
                                                <span className={`${service.discount > 0 ? 'text-v2-blue-text-login' : 'text-gray-500'} font-medium `}>
                                                    {formatterCurrency(service.total)}
                                                </span>
                                                {service.discount > 0 && (
                                                    <span className='block text-gray-500 line-through'>
                                                        {formatterCurrency(service.subtotal)}
                                                    </span>
                                                )}
                                            </div>
                                            <span className="flex h-7 items-center -mt-1 -mr-1">
                                                <ChevronDownIcon
                                                    className={`${service.resume_open ? '-rotate-180' : 'rotate-0'} h-6 w-6 transform transition`}
                                                    aria-hidden="true"
                                                />
                                            </span>
                                        </div>
                                    </button>
                                </dt>
                                <dd className={`${service.resume_open ? 'max-h-[30rem]' : 'max-h-0'} mt-2 overflow-hidden`}>
                                    <div className="w-full py-2 mt-2">
                                        <div className="w-full flex text-sm">
                                            <div className="w-1/2 text-gray-600">
                                                Periodos
                                            </div>
                                            <div className="w-1/2 flex justify-end text-v2-orange-text-dates-quantity">
                                                Cantidad: {service.quantity} periodos
                                            </div>
                                        </div>
                                        <div className="w-full mt-1">
                                            <div className='flex flex-wrap gap-2 mt-2'>
                                                {servicePeriods.find(period => period.sku == service.sku)?.dates_display?.map(date => (
                                                    <div key={date.id} className="w-auto bg-v2-blue-text-login/20 text-v2-blue-text-login py-2 text-xs rounded flex items-center px-2">
                                                        <div className="w-full text-center min-w-[40px]">
                                                            {service.periodicity == 'yearly' ? date.year : date.tag}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        {service.notes && service.notes != '' &&
                                            <>
                                                <div className="w-full text-gray-600 text-sm mt-3">
                                                    Notas:
                                                </div>
                                                <div className="w-full text-sm text-gray-600">
                                                    {service.notes}
                                                </div>
                                            </>
                                        }
                                    </div>
                                </dd>
                            </>
                        </div>
                    ))}
                    <div className="px-4 py-4 sm:px-6 bg-button-orange flex justify-between text-white font-medium text-lg">
                        <p>Total</p>
                        <p>{formatterCurrency(services.map(service => service.total).reduce((prev, curr) => prev + curr, 0))}</p>
                    </div>
                </dl>
            </div>
        </div>
    )
}

ServicesResume.propTypes = {
    services: PropTypes.array,
    servicePeriods: PropTypes.array
}

ServicesResume.defaultProps = {
    services: [],
    servicePeriods: []
}


PaymentAgreements.propTypes = {
    servicePeriods: PropTypes.array,
    setStep: PropTypes.func,
    paymentInstruction: PropTypes.object,
    setPaymentInstruction: PropTypes.func,
    servicesToPay: PropTypes.array
}

PaymentAgreements.defaultProps = {
    servicesToPay: [],
    servicePeriods: []
}


SinglePaymentForm.propTypes = {
    totalAmount: PropTypes.number,
    setPaymentConditions: PropTypes.func
}

DeferredPaymentForm.propTypes = {
    totalAmount: PropTypes.number,
    services: PropTypes.array,
    setPaymentConditions: PropTypes.func
}

RecurrentPaymentForm.propTypes = {
    setPaymentConditions: PropTypes.func
}

export default PaymentAgreements;