export const services = {
    "object": "list",
    "data": [
        {
            "status": "active",
            "object": "service",
            "created": 1691781988650,
            "id": "pis_7f56dc8a-2d74-4768-b089-2e6ebb4aaca9",
            "payment_type": "partial",
            "service_id": "srv_f5fb443a-370f-4757-afea-1f78cc1caecb",
            "instruction_id": "pa_5ec00b6e-ebfd-4ec1-b20a-a5d680205f8b",
            "contract_id": "ct_035722f7-482a-4b66-9011-b75718cf0dfb",
            "price_type": "base_price",
            "quantity": 11,
            "payments": {
                "periods": [
                    {
                        "id": "per_67410364-9930-4db8-81d7-ad45b124aa7d",
                        "service_period": "2023-08-01",
                        "payment_date": 1691868278036,
                        "payment_amount": 64077
                    },
                    {
                        "id": "per_5d43f52d-3e6b-4bfa-b1b0-457b05a8e34c",
                        "service_period": "2023-09-01",
                        "payment_date": 1694129661386,
                        "payment_amount": 64077
                    },
                    {
                        "id": "per_28466065-b00d-42bf-9d64-feb4c6fbe277",
                        "service_period": "2023-10-01",
                        "payment_date": 1694132280818,
                        "payment_amount": 64077
                    },
                    {
                        "id": "per_23a764e0-ffc2-4cb7-b588-155dba2ca0f1",
                        "service_period": "2023-11-01",
                        "payment_date": 1694134221768,
                        "payment_amount": 64077
                    },
                    {
                        "id": "per_d5ac1ba3-da36-463b-97f4-1af96bb5bd39",
                        "service_period": "2023-12-01",
                        "payment_date": 1694189704979,
                        "payment_amount": 64077
                    },
                    {
                        "id": "per_eb8b15a4-8f6b-46e4-83a4-0beb569de25f",
                        "service_period": "2024-01-01",
                        "payment_date": 1694215404517,
                        "payment_amount": 64077
                    },
                    {
                        "id": "per_8750599a-40a0-4ef3-aa73-392c08d49c45",
                        "service_period": "2024-02-01",
                        "payment_date": 1694218278502,
                        "payment_amount": 64077
                    },
                    {
                        "id": "per_22710062-2d7c-4dfa-9c9a-0a39f9530e24",
                        "service_period": "2024-03-01",
                        "payment_date": 1694218493278,
                        "payment_amount": 64077
                    },
                    {
                        "id": "per_a4f413f7-d8a4-4ef2-a706-eadf0cdb9f5f",
                        "service_period": "2024-04-01",
                        "payment_date": 1694218548075,
                        "payment_amount": 64077
                    },
                    {
                        "id": "per_1c36ebf7-6614-4f27-93e6-70b2194f14a7",
                        "service_period": "2024-05-01",
                        "payment_date": 1694563701618,
                        "payment_amount": 64077
                    },
                    {
                        "id": "per_37c2ae8a-71b0-455c-a001-fe4b7de39464",
                        "service_period": "2024-06-01",
                        "payment_date": 1694568527016,
                        "payment_amount": 64077
                    }
                ],
                "total_paid": 2055799,
                "compliance_percentage": "2.80",
                "remaining_amount": -1350952
            },
            "name": "Servicio Contable Mensual",
            "periods": [
                "2023-08-01",
                "2023-09-01",
                "2023-10-01",
                "2023-11-01",
                "2023-12-01",
                "2024-01-01",
                "2024-02-01",
                "2024-03-01",
                "2024-04-01",
                "2024-05-01",
                "2024-06-01"
            ],
            "notes": "",
            "recurrent": true,
            "category": "accounting",
            "periodicity": "monthly",
            "price": {
                "unit_price": 68900,
                "discount": 7,
                "discount_type": "percent",
                "discount_amount": 53053,
                "subtotal_amount": 757900,
                "total_amount": 704847,
                "price_type": "base_price"
            },
            "sku": "SCM-001",
            "required": true,
            "next_payment": {
                "status": "pending",
                "date": 1691820000000,
                "amount": 58737.25,
                "remaining_amount": 0.25
            },
            "payment_dates": [
                {
                    "status": "completed",
                    "date": 1691820000000,
                    "amount": 58737.25
                },
                {
                    "status": "completed",
                    "date": 1694498400000,
                    "amount": 58737.25
                },
                {
                    "status": "completed",
                    "date": 1697090400000,
                    "amount": 58737.25
                },
                {
                    "status": "completed",
                    "date": 1699768800000,
                    "amount": 58737.25
                },
                {
                    "status": "completed",
                    "date": 1702360800000,
                    "amount": 58737.25
                },
                {
                    "status": "completed",
                    "date": 1705039200000,
                    "amount": 58737.25
                },
                {
                    "status": "completed",
                    "date": 1707717600000,
                    "amount": 58737.25
                },
                {
                    "status": "completed",
                    "date": 1710223200000,
                    "amount": 58737.25
                },
                {
                    "status": "completed",
                    "date": 1712901600000,
                    "amount": 58737.25
                },
                {
                    "status": "completed",
                    "date": 1715493600000,
                    "amount": 58737.25
                },
                {
                    "status": "completed",
                    "date": 1718172000000,
                    "amount": 58737.25
                },
                {
                    "status": "completed",
                    "date": 1720764000000,
                    "amount": 58737.25
                }
            ],
            "activation_date": 1691782087814,
            "updated": 1691782087814,
            "orders": [
                {
                    "id": "ord_69b574b9-b883-4f89-9a8d-bfd34f8f2447",
                    "status": "completed"
                },
                {
                    "id": "ord_8969c7ef-c21c-4752-a722-bd489efb92ff",
                    "status": "completed"
                }
            ],
            "subscriptions": [
                {
                    "id": "sub_8c5fc3f1-8b72-4149-adaa-d1c82e943fac",
                    "status": "paused"
                }
            ],
            "valid_until": 1691820000000,
            "subscription_status": "subscribed",
            "service_info": {
                "minimum_periods": 1,
                "priority": 0
            },
            "account": {
                "id": "ac_ed537158-92b5-43b5-a359-a7e82b7b24c2",
                "name": "Lorena Paredes Rodríguez",
                "email": "acovarrubias+lpr@kea.mx"
            }
        },
        {
            "status": "completed",
            "object": "service",
            "created": 1691781988648,
            "id": "pis_739a73ee-ca50-4720-a7e5-42db318a8555",
            "payment_type": "partial",
            "service_id": "srv_f325dc07-b31c-4b69-acaf-aea730b17f0f",
            "instruction_id": "pa_5ec00b6e-ebfd-4ec1-b20a-a5d680205f8b",
            "contract_id": "ct_035722f7-482a-4b66-9011-b75718cf0dfb",
            "price_type": "base_price",
            "quantity": 10,
            "payments": {
                "periods": [
                    {
                        "id": "per_4d89bd18-bd9b-4715-b53c-c4076dee68db",
                        "service_period": "2021-01-01",
                        "payment_date": 1691790052415,
                        "payment_amount": 29627
                    },
                    {
                        "id": "per_7a13f56b-3f0c-413a-a890-6954d7f9e721",
                        "service_period": "2022-02-01",
                        "payment_date": 1691790052416,
                        "payment_amount": 29627
                    },
                    {
                        "id": "per_f7e38aa2-f941-47e5-b07e-0bb7e9d60a60",
                        "service_period": "2022-05-01",
                        "payment_date": 1691790052416,
                        "payment_amount": 29627
                    },
                    {
                        "id": "per_57e888d1-e8ef-4a85-af0f-c3b9cb0f1e81",
                        "service_period": "2022-07-01",
                        "payment_date": 1691790052416,
                        "payment_amount": 29627
                    },
                    {
                        "id": "per_0761e49a-34eb-4838-a7a3-ddcde8703ea8",
                        "service_period": "2022-09-01",
                        "payment_date": 1691790052416,
                        "payment_amount": 29627
                    },
                    {
                        "id": "per_e2b0749e-e267-48ca-aeac-09872de77207",
                        "service_period": "2022-10-01",
                        "payment_date": 1691790052416,
                        "payment_amount": 29627
                    },
                    {
                        "id": "per_31f1d210-0541-4c7d-94e3-aaeb9b0ce62e",
                        "service_period": "2023-01-01",
                        "payment_date": 1691790052416,
                        "payment_amount": 29627
                    },
                    {
                        "id": "per_06b4163a-bf39-4fef-8523-0c355e6fa3da",
                        "service_period": "2023-02-01",
                        "payment_date": 1691790052416,
                        "payment_amount": 29627
                    },
                    {
                        "id": "per_9889b6ee-344a-4e92-ac59-f5ad56422f49",
                        "service_period": "2023-03-01",
                        "payment_date": 1691790052416,
                        "payment_amount": 29627
                    },
                    {
                        "id": "per_dcffd732-7acd-4de0-a6e2-96303574e21e",
                        "service_period": "2023-04-01",
                        "payment_date": 1691790052416,
                        "payment_amount": 29627
                    }
                ],
                "total_paid": 1135698,
                "compliance_percentage": "3.72",
                "remaining_amount": -839428
            },
            "name": "Regularización Contable Mensual",
            "periods": [
                "2021-01-01",
                "2022-02-01",
                "2022-05-01",
                "2022-07-01",
                "2022-09-01",
                "2022-10-01",
                "2023-01-01",
                "2023-02-01",
                "2023-03-01",
                "2023-04-01"
            ],
            "notes": "",
            "recurrent": true,
            "category": "regularization",
            "periodicity": "monthly",
            "price": {
                "unit_price": 68900,
                "discount": 57,
                "discount_type": "percent",
                "discount_amount": 392730,
                "subtotal_amount": 689000,
                "total_amount": 296270,
                "price_type": "base_price"
            },
            "sku": "RCM-001",
            "required": false,
            "next_payment": {
                "status": "completed",
                "date": 1691820000000,
                "amount": 24689.166666666668,
                "remaining_amount": 0.16666666666787933
            },
            "payment_dates": [
                {
                    "status": "completed",
                    "date": 1691820000000,
                    "amount": 24689.166666666668
                },
                {
                    "status": "completed",
                    "date": 1694498400000,
                    "amount": 24689.166666666668
                },
                {
                    "status": "completed",
                    "date": 1697090400000,
                    "amount": 24689.166666666668
                },
                {
                    "status": "completed",
                    "date": 1699768800000,
                    "amount": 24689.166666666668
                },
                {
                    "status": "completed",
                    "date": 1702360800000,
                    "amount": 24689.166666666668
                },
                {
                    "status": "completed",
                    "date": 1705039200000,
                    "amount": 24689.166666666668
                },
                {
                    "status": "completed",
                    "date": 1707717600000,
                    "amount": 24689.166666666668
                },
                {
                    "status": "completed",
                    "date": 1710223200000,
                    "amount": 24689.166666666668
                },
                {
                    "status": "completed",
                    "date": 1712901600000,
                    "amount": 24689.166666666668
                },
                {
                    "status": "completed",
                    "date": 1715493600000,
                    "amount": 24689.166666666668
                },
                {
                    "status": "completed",
                    "date": 1718172000000,
                    "amount": 24689.166666666668
                },
                {
                    "status": "completed",
                    "date": 1720764000000,
                    "amount": 24689.166666666668
                }
            ],
            "activation_date": 1691782087814,
            "updated": 1691782087814,
            "orders": [
                {
                    "id": "ord_69b574b9-b883-4f89-9a8d-bfd34f8f2447",
                    "status": "completed"
                },
                {
                    "id": "ord_015b068a-37a7-4a78-be26-9da186dd488d",
                    "status": "completed"
                },
                {
                    "id": "ord_8969c7ef-c21c-4752-a722-bd489efb92ff",
                    "status": "completed"
                }
            ],
            "subscriptions": [
                {
                    "id": "sub_8c5fc3f1-8b72-4149-adaa-d1c82e943fac",
                    "status": "paused"
                }
            ],
            "valid_until": 1691820000000,
            "subscription_status": "subscribed",
            "service_info": {
                "minimum_periods": 1,
                "priority": 1
            },
            "account": {
                "id": "ac_ed537158-92b5-43b5-a359-a7e82b7b24c2",
                "name": "Lorena Paredes Rodríguez",
                "email": "acovarrubias+lpr@kea.mx"
            }
        },
        {
            "status": "completed",
            "object": "service",
            "created": 1691781988650,
            "id": "pis_0487b848-6d9a-4af1-97f5-0671f48fe595",
            "payment_type": "partial",
            "service_id": "srv_2e8ea5a9-6c75-4f21-83e5-6149b7737b4f",
            "instruction_id": "pa_5ec00b6e-ebfd-4ec1-b20a-a5d680205f8b",
            "contract_id": "ct_035722f7-482a-4b66-9011-b75718cf0dfb",
            "price_type": "base_price",
            "quantity": 1,
            "payments": {
                "periods": [
                    {
                        "id": "per_25dba223-d0c7-4cf9-bfda-b65ea07ad2bc",
                        "service_period": "2024-01-01",
                        "payment_date": 1693000362401,
                        "payment_amount": 63120
                    }
                ],
                "total_paid": 236700,
                "compliance_percentage": "3.63",
                "remaining_amount": -173580
            },
            "name": "Declaración Anual",
            "periods": [
                "2024-01-01"
            ],
            "notes": "",
            "recurrent": true,
            "category": "accounting",
            "periodicity": "yearly",
            "price": {
                "unit_price": 78900,
                "discount": 20,
                "discount_type": "percent",
                "discount_amount": 15780,
                "subtotal_amount": 78900,
                "total_amount": 63120,
                "price_type": "base_price"
            },
            "sku": "DCA-001",
            "required": false,
            "next_payment": {
                "status": "completed",
                "date": 1691820000000,
                "amount": 5260,
                "remaining_amount": 0
            },
            "payment_dates": [
                {
                    "status": "completed",
                    "date": 1691820000000,
                    "amount": 5260
                },
                {
                    "status": "completed",
                    "date": 1694498400000,
                    "amount": 5260
                },
                {
                    "status": "completed",
                    "date": 1697090400000,
                    "amount": 5260
                },
                {
                    "status": "completed",
                    "date": 1699768800000,
                    "amount": 5260
                },
                {
                    "status": "completed",
                    "date": 1702360800000,
                    "amount": 5260
                },
                {
                    "status": "completed",
                    "date": 1705039200000,
                    "amount": 5260
                },
                {
                    "status": "completed",
                    "date": 1707717600000,
                    "amount": 5260
                },
                {
                    "status": "completed",
                    "date": 1710223200000,
                    "amount": 5260
                },
                {
                    "status": "completed",
                    "date": 1712901600000,
                    "amount": 5260
                },
                {
                    "status": "completed",
                    "date": 1715493600000,
                    "amount": 5260
                },
                {
                    "status": "completed",
                    "date": 1718172000000,
                    "amount": 5260
                },
                {
                    "status": "completed",
                    "date": 1720764000000,
                    "amount": 5260
                }
            ],
            "activation_date": 1691782087814,
            "updated": 1691782087814,
            "orders": [
                {
                    "id": "ord_69b574b9-b883-4f89-9a8d-bfd34f8f2447",
                    "status": "completed"
                },
                {
                    "id": "ord_82e49af1-1aec-4ea2-b7f7-471d903430de",
                    "status": "completed"
                },
                {
                    "id": "ord_8969c7ef-c21c-4752-a722-bd489efb92ff",
                    "status": "completed"
                },
                {
                    "id": "ord_11108a6c-6090-4427-9e73-ab8aafa6d54c",
                    "status": "completed"
                }
            ],
            "subscriptions": [
                {
                    "id": "sub_8c5fc3f1-8b72-4149-adaa-d1c82e943fac",
                    "status": "paused"
                }
            ],
            "valid_until": 1691820000000,
            "subscription_status": "subscribed",
            "service_info": {
                "minimum_periods": 1,
                "priority": 4
            },
            "account": {
                "id": "ac_ed537158-92b5-43b5-a359-a7e82b7b24c2",
                "name": "Lorena Paredes Rodríguez",
                "email": "acovarrubias+lpr@kea.mx"
            }
        }
    ]
}

export const serviceItem = {
    "services": [
        {
            checked: true,
            resume_open: true,
            "status": "active",
            "object": "service",
            "created": 1719947641581,
            "id": "pis_9cceed3e-93b5-4d10-bf91-11f7a34c9235",
            "payment_type": "subscription",
            "service_id": "srv_f5fb443a-370f-4757-afea-1f78cc1caecb",
            "instruction_id": "pa_69185d33-cd20-458f-8fd5-e252a3a154df",
            "contract_id": "ct_701726fb-fe4b-4708-bb7a-5c94ad9a8fa3",
            "account": {
                "id": "ac_20658bc3-d9a7-4ac7-b052-3aaeb793926a",
                "name": "Angel Mireles ",
                "phone_number": "525611612734",
                "email": "amireles+20240329@kea.mx",
                "taxpayer": {
                    "tax_id": "XAXX010101000",
                    "regime": "Personas Físicas con Actividades Empresariales y Profesionales",
                    "legal_type": "personal"
                },
                "first_name": "Angel",
                "last_name": "Mireles ",
                "status": "active"
            },
            "price_type": "base_price",
            "quantity": 6,
            "payments": {
                "periods": [
                    {
                        "id": "per_7f032eda-77fa-4b29-bb33-8bd7daea98f4",
                        "service_period": "2024-07-01",
                        "payment_date": 1719948148013,
                        "payment_amount": 68900
                    },
                    {
                        "id": "per_c7e40860-cdc6-4a35-9069-c58f482d098d",
                        "service_period": "2024-08-01",
                        "payment_date": 1719948148013,
                        "payment_amount": 68900
                    },
                    {
                        "id": "per_30a8d831-1777-4728-b023-464cceb4bec0",
                        "service_period": "2024-09-01",
                        "payment_date": 1719948148013,
                        "payment_amount": 68900
                    },
                    {
                        "id": "per_ca1381d5-fe4a-4575-8068-83102dd6f1f8",
                        "service_period": "2024-10-01",
                        "payment_date": 1719948148013,
                        "payment_amount": 68900
                    },
                    {
                        "id": "per_48bd9299-b516-4652-9cf7-0beb6f72c9b8",
                        "service_period": "2024-11-01",
                        "payment_date": 1719948148013,
                        "payment_amount": 68900
                    },
                    {
                        "id": "per_f8459370-4a78-410d-841d-79fcc817fad9",
                        "service_period": "2024-12-01",
                        "payment_date": 1719948148013,
                        "payment_amount": 68900
                    }
                ],
                "total_paid": 413400,
                "compliance_percentage": 1,
                "remaining_amount": 0
            },
            "name": "Servicio Contable Mensual",
            "periods": [
                "2024-07-01",
                "2024-08-01",
                "2024-09-01",
                "2024-10-01",
                "2024-11-01",
                "2024-12-01"
            ],
            "notes": "",
            "recurrent": true,
            "category": "accounting",
            "price": {
                "unit_price": 68900,
                "discount": 0,
                "discount_type": "percent",
                "discount_amount": 0,
                "subtotal_amount": 413400,
                "total_amount": 413400,
                "price_type": "base_price"
            },
            qty: 2,
            "price_value": 413400,
            "service_info": {
                "description": "Es la declaración de impuestos de una Persona Física de un Ejercicio Fiscal Mensual que se realizará en tiempo y forma ante el SAT.",
                "service_reach": [
                    "Se hace el cálculo de los ingresos y gastos acumulados dentro del Ejercicio Fiscal mensual con base en todos los CFDI's del contribuyente, registrados en el SAT, que no fueron cancelados.",
                    "Se calculan todos los impuestos aplicables de acuerdo a sus Regímenes Fiscales activos con base en el cálculo de ingresos y gastos mencionados en el punto anterior ( Enunciativo más no limitativo: IVA, ISR, IEPS, IVA retenido, ISR retenido,etc).",
                    "Una vez realizado los cálculos anteriores se presentan al contribuyente y se solicita su autorización para presentar la Declaración Mensual ante el SAT.",
                    "Se presenta la Declaración Mensual sólo hasta tener el consentimiento del contribuyente respecto del cálculo de sus impuestos y su autorización para presentarla.",
                    "Se envía por correo electrónico el acuse de la declaración.",
                    "En caso de haber impuestos a pagar hacia el SAT, se envía la línea de captura vía correo electrónico y el contribuyente es el único responsable de realizar dicho pago de impuestos."
                ],
                "terms_and_conditions": [
                    "El contribuyente debe proporcionarnos sus Datos Fiscales ( RFC, Contraseña CIEC, E.Firma).",
                    "Se realizará la declaración de impuestos mensual si se determina que el contribuyente, de acuerdo a las Leyes Fiscales actuales, tenía la obligación de presentar la declaración fiscal mensual.",
                    "Si el contribuyente tiene declaraciones mensuales pendientes por declarar se puede presentar la declaración pero se sugiere que se realice hasta regularizar las obligaciones pendientes.",
                    "Para poder realizar este servicio, el pago del mismo debió de ser recibido el mes anterior."
                ],
                "cancelation_clauses": [
                    "Si se determina que el contribuyente no tiene la obligación fiscal de presentar la declaración mensual, se reembolsará el monto del costo del servicio no ejecutado.",
                    "Si el contribuyente cancela el servicio hasta 24 horas después de haber realizado el pago del servicio, se hace un reembolso completo del monto pagado.",
                    "Si cancela después de 24 horas y no se ha presentado la declaración ante el SAT, se reembolsará el monto pagado descontando el costo actualizado del ASF."
                ],
                "priority": 0,
                "minimum_periods": 1
            },
            "sku": "SCM-001",
            "required": true,
            "periodicity": "monthly",
            "next_payment": {
                "status": "pending",
                "date": 1722626548013,
                "amount": 413400
            },
            "activation_date": 1719948120671,
            "plan_id": "sp_ca84a020-be31-4f59-9a4c-5e33c7fee45d",
            "updated": 1719948120321,
            "orders": [
                {
                    "id": "ord_cda5ffdc-0771-46ba-a8e4-9784206f97f0",
                    "status": "completed"
                }
            ],
            "payment_dates": null,
            "subscription_status": "subscribed",
            "subscriptions": [
                {
                    "id": "sub_ca7e0cac-d421-49fe-871f-21918775d7f4",
                    "status": "active"
                }
            ],
            "valid_until": 1722626548013
        },
        {
            checked: true,
            resume_open: true,
            "status": "pending",
            "object": "service",
            "created": 1719947641581,
            "id": "pis_5d8653db-a003-4d30-ad71-6f0e468493fe",
            "payment_type": "subscription",
            "service_id": "srv_f325dc07-b31c-4b69-acaf-aea730b17f0f",
            "instruction_id": "pa_69185d33-cd20-458f-8fd5-e252a3a154df",
            "contract_id": "ct_701726fb-fe4b-4708-bb7a-5c94ad9a8fa3",
            "account": {
                "id": "ac_20658bc3-d9a7-4ac7-b052-3aaeb793926a",
                "name": "Angel Mireles ",
                "phone_number": "525611612734",
                "email": "amireles+20240329@kea.mx",
                "taxpayer": {
                    "tax_id": "XAXX010101000",
                    "regime": "Personas Físicas con Actividades Empresariales y Profesionales",
                    "legal_type": "personal"
                },
                "first_name": "Angel",
                "last_name": "Mireles ",
                "status": "active"
            },
            "price_type": "base_price",
            "quantity": 6,
            "payments": {
                "periods": [],
                "has_advaced_payment": false,
                "remaining_amount": 413400,
                "total_paid": 0,
                "compliance_percentage": 0
            },
            "name": "Regularización Contable Mensual",
            "periods": [
                "2024-01-01",
                "2024-02-01",
                "2024-03-01",
                "2024-04-01",
                "2024-05-01",
                "2024-06-01"
            ],
            "notes": "",
            "recurrent": true,
            "category": "regularization",
            "price": {
                "unit_price": 68900,
                "discount": 0,
                "discount_type": "percent",
                "discount_amount": 0,
                "subtotal_amount": 413400,
                "total_amount": 413400,
                "price_type": "base_price"
            },
            qty: 3,
            "price_value": 413400,
            "service_info": {
                "description": "Es la declaración de impuestos de una Persona Física de un Ejercicio Fiscal Mensual que No se hizo en tiempo y forma ante el SAT.",
                "service_reach": [
                    "Se hace el cálculo de los ingresos y gastos acumulados dentro del Ejercicio Fiscal mensual con base en todos los CFDI's del contribuyente, registrados en el SAT, que no fueron cancelados.",
                    "Se calculan todos los impuestos aplicables de acuerdo a sus Regímenes Fiscales activos con base en el cálculo de ingresos y gastos mencionados en el punto anterior ( Enunciativo más no limitativo: IVA, ISR, IEPS, IVA retenido, ISR retenido,etc).",
                    "Una vez realizado los cálculos anteriores se presentan al contribuyente y se solicita su autorización para presentar la Declaración Mensual ante el SAT.",
                    "Se presenta la Declaración Mensual sólo hasta tener el consentimiento del contribuyente respecto del cálculo de sus impuestos y su autorización para presentarla.",
                    "Se envía por correo electrónico el acuse de la declaración.",
                    "En caso de haber impuestos a pagar hacia el SAT, se envía la línea de captura vía correo electrónico y el contribuyente es el único responsable de realizar dicho pago de impuestos."
                ],
                "terms_and_conditions": [
                    "El contribuyente debe proporcionarnos sus Datos Fiscales ( RFC, Contraseña CIEC, E.Firma).",
                    "Se realizará la declaración de impuestos mensual si se determina que el contribuyente, de acuerdo a las Leyes Fiscales actuales, tenía la obligación de presentar la declaración fiscal mensual.",
                    "Si el contribuyente tiene declaraciones mensuales pendientes por declarar se puede presentar la declaración pero se sugiere que se realice hasta regularizar las obligaciones pendientes.",
                    "Para poder realizar este servicio, el pago del mismo debió de ser recibido el mes anterior."
                ],
                "cancelation_clauses": [
                    "Si se determina que el contribuyente no tiene la obligación fiscal de presentar la declaración mensual, se reembolsará el monto del costo del servicio no ejecutado.",
                    "Si el contribuyente cancela el servicio hasta 24 horas después de haber realizado el pago del servicio, se hace un reembolso completo del monto pagado.",
                    "Si cancela después de 24 horas y no se ha presentado la declaración ante el SAT, se reembolsará el monto pagado descontando el costo actualizado del ASF."
                ],
                "priority": 1,
                "minimum_periods": 1
            },
            "sku": "RCM-001",
            "required": false,
            "periodicity": "monthly",
            "next_payment": {
                "status": "pending",
                "date": 1719947641582,
                "amount": 413400
            },
            "activation_date": 1719948120671,
            "plan_id": "sp_ca84a020-be31-4f59-9a4c-5e33c7fee45d",
            "updated": 1719948120321
        },
        {
            checked: true,
            resume_open: true,
            "status": "pending",
            "object": "service",
            "created": 1719947641582,
            "id": "pis_b1b6077b-683b-46e7-b554-eb55c4991c6a",
            "payment_type": "subscription",
            "service_id": "srv_a214e92e-6347-42f9-b8df-6808f6ffb594",
            "instruction_id": "pa_69185d33-cd20-458f-8fd5-e252a3a154df",
            "contract_id": "ct_701726fb-fe4b-4708-bb7a-5c94ad9a8fa3",
            "account": {
                "id": "ac_20658bc3-d9a7-4ac7-b052-3aaeb793926a",
                "name": "Angel Mireles ",
                "phone_number": "525611612734",
                "email": "amireles+20240329@kea.mx",
                "taxpayer": {
                    "tax_id": "XAXX010101000",
                    "regime": "Personas Físicas con Actividades Empresariales y Profesionales",
                    "legal_type": "personal"
                },
                "first_name": "Angel",
                "last_name": "Mireles ",
                "status": "active"
            },
            "price_type": "base_price",
            "quantity": 3,
            "payments": {
                "periods": [],
                "has_advaced_payment": false,
                "remaining_amount": 236700,
                "total_paid": 0,
                "compliance_percentage": 0
            },
            "name": "Regularización Declaración Anual",
            "periods": [
                "2024-01-01",
                "2023-01-01",
                "2022-01-01"
            ],
            "notes": "",
            "recurrent": true,
            "category": "regularization",
            "price": {
                "unit_price": 78900,
                "discount": 0,
                "discount_type": "percent",
                "discount_amount": 0,
                "subtotal_amount": 236700,
                "total_amount": 236700,
                "price_type": "base_price"
            },
            qty: 1,
            price_value: 236700,
            "service_info": {
                "description": "Es la declaración de impuestos de una Persona Física de un Ejercicio Fiscal Anual que No se hizo en tiempo y forma ante el SAT.",
                "service_reach": [
                    "Se hace el cálculo de los ingresos y gastos acumulados dentro del Ejercicio Fiscal con base en todos los CFDI's del contribuyente, registrados en el SAT, que no fueron cancelados.",
                    "Se calculan todos los impuestos aplicables de acuerdo a sus Regímenes Fiscales activos con base en el cálculo de ingresos y gastos mencionados en el punto anterior.",
                    "Una vez realizado los cálculos anteriores se presentan al contribuyente y se solicita su autorización para presentar la Declaración Anual ante el SAT.",
                    "Se presenta la Declaración Anual sólo hasta tener el consentimiento del contribuyente respecto del cálculo de sus impuestos y su autorización para presentarla.",
                    "Se envía por correo electrónico el acuse de la declaración.",
                    "En en caso de haber impuestos a pagar hacia el SAT, se envía la línea de captura vía correo electrónico y el contribuyente es el único responsable de realizar dicho pago de impuestos.",
                    "En caso de que existan impuestos a favor, y el contribuyente haya elegido la devolución, este servicio no incluye el seguimiento de este proceso con el SAT hasta su devolución efectiva hacia el contribuyente."
                ],
                "terms_and_conditions": [
                    "El contribuyente debe proporcionarnos sus Datos Fiscales ( RFC, Contraseña CIEC, E.Firma).",
                    "Para poder dar este servicio, el contribuyente no debe tener ninguna declaración de impuestos mensual pendiente ante el SAT del mismo Ejercicio Fiscal anual.",
                    "Se realizará la regularización contable anual si se determina que el contribuyente, de acuerdo a las Leyes Fiscales actuales, tenía la obligación de presentar la declaración fiscal anual.",
                    "Para poder dar este servicio al contribuyente, se tiene que realizar previamente un Análisis de Situación Fiscal.",
                    "Si el contribuyente solicita la devolución de sus impuestos, será necesario que nos proporcione la carátula de su cuenta bancaria y su CLABE interbancaria."
                ],
                "cancelation_clauses": [
                    "Si se determina que no se puede brindar el servicio del contribuyente, se reembolsará el monto pagado descontando el costo actualizado del ASF.",
                    "Si el contribuyente cancela el servicio hasta 24 horas después de haber realizado el pago del servicio, se hace un reembolso completo del monto pagado.",
                    "Si cancela después de 24 horas y no se ha presentado la declaración ante el SAT, se reembolsará el monto pagado descontando el costo actualizado del ASF."
                ],
                "priority": 3,
                "minimum_periods": 1
            },
            "sku": "RCA-001",
            "required": false,
            "periodicity": "monthly",
            "next_payment": {
                "status": "pending",
                "date": 1719947641582,
                "amount": 236700
            },
            "activation_date": 1719948120671,
            "plan_id": "sp_ca84a020-be31-4f59-9a4c-5e33c7fee45d",
            "updated": 1719948120321
        }
    ],
}

export const datesDisplayArray = [
    {
        "id": "pis_9cceed3e-93b5-4d10-bf91-11f7a34c9235",
        "sku": "SCM-001",
        "index": 0,
        "name": "Servicio Contable Mensual",
        "dates_display": [
            {
                "id": 202407202412,
                "tag": "Julio a Diciembre 2024",
                "year": 2024,
                "range": [
                    7,
                    8,
                    9,
                    10,
                    11,
                    12
                ]
            }
        ]
    },
    {
        "id": "pis_5d8653db-a003-4d30-ad71-6f0e468493fe",
        "sku": "RCM-001",
        "index": 1,
        "name": "Regularización Contable Mensual",
        "dates_display": [
            {
                "id": 202401202406,
                "tag": "Enero a Junio 2024",
                "year": 2024,
                "range": [
                    1,
                    2,
                    3,
                    4,
                    5,
                    6
                ]
            }
        ]
    },
    {
        "id": "pis_b1b6077b-683b-46e7-b554-eb55c4991c6a",
        "sku": "RCA-001",
        "index": 2,
        "name": "Regularización Declaración Anual",
        "dates_display": [
            {
                "id": 202401,
                "monthNumber": 1,
                "month": "Enero",
                "year": 2024,
                "active": true,
                "tag": "Enero 2024"
            },
            {
                "id": 202301,
                "monthNumber": 1,
                "month": "Enero",
                "year": 2023,
                "active": true,
                "tag": "Enero 2023"
            },
            {
                "id": 202201,
                "monthNumber": 1,
                "month": "Enero",
                "year": 2022,
                "active": true,
                "tag": "Enero 2022"
            }
        ]
    }
]