import apiClient from '../apiClient';
import { apiBaseUrl, cognitoDomain, cognitoRedirectUri, cognitoLogoutUri, cognitoClientId } from '../../config';
import cookie from "js-cookie";

export const validateSession = async () => {
    return apiClient.get(`${apiBaseUrl}/sessions/admin`, {})
    .then(response => { return response });
}

export const login = async () => {
    window.location.href = `${cognitoDomain}/oauth2/authorize?identity_provider=Google&redirect_uri=${cognitoRedirectUri}&response_type=TOKEN&client_id=${cognitoClientId}&scope=email openid profile`;
}

export const logout = async (showError = false) => {
    cookie.remove("id_token");
    cookie.remove("access_token");
    window.location.href = `${cognitoDomain}/logout?client_id=${cognitoClientId}&logout_uri=${cognitoLogoutUri}${showError ? "?error=not_authorized" : ""}`
    localStorage.setItem("user", JSON.stringify(''));
}