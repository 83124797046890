const useConvertDatePicker = () => {
    const convertDatePicker = date => {
        const year = date.split('-')[0];
        const month = date.split('-')[1];
        const day = date.split('-')[2];
        return {
            year: parseInt(year),
            month: parseInt(month),
            day: parseInt(day)
        }
    }
    return { convertDatePicker }
}

export default useConvertDatePicker;