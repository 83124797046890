const installmentsRules = [
    {
        id: 1,
        title: "Un solo pago",
        shortTile: "1",
        values: [1],
        minimumAmount: 0
    },
    {
        id: 2,
        title: "Hasta 3 meses sin intereses",
        shortTile: "3",
        values: [1, 3],
        minimumAmount: 2067
    },
    {
        id: 3,
        title: "Hasta 6 meses sin intereses",
        shortTile: "6",
        values: [1, 3, 6],
        minimumAmount: 4134
    },
    {
        id: 4,
        title: "Hasta 9 meses sin intereses",
        shortTile: "9",
        values: [1, 3, 6, 9],
        minimumAmount: 6201
    },
    {
        id: 5,
        title: "Hasta 12 meses sin intereses",
        shortTile: "12",
        values: [1, 3, 6, 9, 12],
        minimumAmount: 8268
    }
]

const partialitiesRules = [
    {
        id: 0,
        value: 1,
        title: 1,
        minimumPeriods: 1,
        allowAccountingServices: true,
        allowOtherServices: true
    },
    {
        id: 1,
        value: 2,
        title: 2,
        minimumPeriods: 1,
        allowAccountingServices: true,
        allowOtherServices: true
    },
    {
        id: 2,
        value: 3,
        title: 3,
        minimumPeriods: 1,
        allowAccountingServices: true,
        allowOtherServices: true
    },
    {
        id: 3,
        value: 4,
        title: 4,
        minimumPeriods: 1,
        allowAccountingServices: true,
        allowOtherServices: true
    },
    {
        id: 4,
        value: 6,
        title: 6,
        minimumPeriods: 6,
        allowAccountingServices: true,
        allowOtherServices: false
    },
    {
        id: 5,
        value: 9,
        title: 9,
        minimumPeriods: 9,
        allowAccountingServices: true,
        allowOtherServices: false
    },
    {
        id: 6,
        value: 12,
        title: 12,
        minimumPeriods: 10,
        allowAccountingServices: true,
        allowOtherServices: false
    }
];

const usePaymentRules = () => {

    const installments = amount => {
        return installmentsRules.filter(installment => installment.minimumAmount <= (amount || 0))
    };

    const partialities = (hasServiceAccounting, numberOfPeriods, amount) => {
        let partialities;
        if (amount < 2067) {
            partialities = [partialitiesRules[0]]
        } else {
            if (hasServiceAccounting) {
                partialities = partialitiesRules.filter(partial => (partial.minimumPeriods <= numberOfPeriods) && partial.allowAccountingServices);
            } else {
                partialities = partialitiesRules.filter(partial => partial.allowOtherServices);
            }
        }

        return partialities;
    };

    return { installments, partialities }
}

export default usePaymentRules
