import apiClient from '../apiClient';
import { apiBaseUrl } from '../../config';
import { translateEmail } from './accountsOperations';

export const sendQuote = async (data) => {
    return apiClient.post(`${apiBaseUrl}/quotes/`, data).then(response => {
        return response;
    });
}

export const getAllQuotes = async (page, limit, search, sort, filters) => {
    const searchString = search?.includes('@') ? translateEmail(search) : search;
    const url = `${apiBaseUrl}/quotes/search?page=${page}&limit=${limit}&q=wildCard~${searchString || ''}${filters || ''}&${ sort || 'field=created&order=-1'}`;
    return apiClient.get(url, {})
    .then(response => { return response });
}

export const getQuoteById = async (quoteId) => {
    return apiClient.get(`${apiBaseUrl}/quotes/${quoteId}`, {})
    .then(response => { return response });
}

export const updateQuote = async (data, quoteId) => {
    return apiClient.put(`${apiBaseUrl}/quotes/${quoteId}`, data).then(response => {
        return response;
    });
}