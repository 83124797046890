import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { AlertContext } from '../context/AlertContext';

function Alert() {

    const { alert, setAlert } = useContext(AlertContext);
    
    const handleClick = () => {
        const alertError = document.querySelector('#alert-error');
        alertError.classList.add('alert_error_hidden');
        setTimeout(() => {
            alertError.classList.add('hidden');
            setAlert({ active: false, type: '', message: '' });
        }, 300);
    }

    setTimeout(() => {
        const alertError = document.querySelector('#alert-error');
        alertError != null && alertError.classList.add('hidden');
        setAlert({ active: false, type: '', message: '' });
    }, 5000);

    const alertStyle = {
        'success': 'bg-v2-bg-alert-success border-v2-border-alert-success',
        'error': 'bg-bg-error border-v2-border-alert-error'
    }

    const iconStyle = {
        'success': 'text-v2-border-alert-success',
        'error': 'text-alert-error'
    }

    const messageStyle = {
        'success': 'text-v2-border-alert-success',
        'error': 'text-input-error'
    }

    return (
        <div className="w-full p-4 sticky top-4 z-40">
            <div className="w-full flex justify-center flex-wrap">
                <div className={`${alertStyle[alert.type] || ''} py-1.5 border-l-8 w-full flex items-center`} role="alert" id="alert-error">
                    <span className={`${iconStyle[alert.type] || ''} text-base ml-2 w-12 flex justify-center`} role="button" onClick={handleClick}>
                        {alert.type == 'error' && <FontAwesomeIcon icon={faCircleXmark} className="text-2xl text-input-error leading-3" />}
                        {alert.type == 'success' && <FontAwesomeIcon icon={faCircleCheck} className="text-2xl text-v2-border-alert-success leading-3" />}
                    </span>
                    <span className={`${messageStyle[alert.type] || ''} w-11/12 inline-block xs:text-base md:text-lg leading-3`}>
                        {alert.message}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default Alert;
