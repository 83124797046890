import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import Modal from './Modal';

function HeadersDataTables({ icon, text, background, padding, bgColor, textColor, edit, children, title  }) {
    const [openModal, setOpenModal] = useState(false);
    let iconStyles = background ? `rounded-full w-8 h-8 ${bgColor} text-lg ${textColor}` : `${bgColor} text-3.5xl ${textColor} w-8 h-8`;
    return (
        <div className="w-full xs:px-2 md:px-4 py-2 border-b border-v2-gray-border-tables flex items-center">
            <div className='w-11'>
                <div className={`${iconStyles} flex justify-center items-center`}>
                    <FontAwesomeIcon icon={ icon } className={`leading-3 pt-${padding}`} />
                </div>
            </div>
            <div className={`${ edit ? 'xs:w-8/12 md:w-8/12' : 'xs:w-11/12 md:w-8/12' } text-xl font-semibold text-v2-input-text truncate`}>
                { text }
            </div>
            <div className={`${ edit ? 'flex' : 'hidden' } w-4/12 justify-end items-center text-v2-yellow-edit-info`}>
                <span className="cursor-pointer flex items-center" onClick={ () => setOpenModal(true) }>
                    <FontAwesomeIcon icon={ faPenToSquare } className="leading-3 text-2xl" /> <span className="underline pl-1 pt-1 xs:hidden md:block">Editar datos</span>
                </span>
            </div>
            <Modal 
                title={title}
                modalOpen={openModal}
                modalOpenChange={setOpenModal}>
                { children }
            </Modal>
        </div>
    )
}

HeadersDataTables.propTypes = {
    icon: PropTypes.any,
    text: PropTypes.string,
    background: PropTypes.bool,
    padding: PropTypes.any,
    bgColor: PropTypes.string,
    textColor: PropTypes.string,
    edit: PropTypes.bool,
    children: PropTypes.any,
    title: PropTypes.string
}

HeadersDataTables.defaultProps = {
    icon: 'faLink',
    text: 'Texto',
    background: false,
    padding: 0,
    bgColor: 'bg-white',
    textColor: 'text-v2-blue-icons',
    edit: false,
}

export default HeadersDataTables