import apiClient from '../apiClient';
import { apiBaseUrl } from '../../config';

export const getsubs = async () => {
    return apiClient.get(`${apiBaseUrl}/subscriptions/`)
    .then(response => { return response })
}

export const searchSubscription = async (page=1, limit=10, search="", filters="", sort="") => {
    const escapedSearch = encodeURIComponent(search);
    return apiClient.get(`${apiBaseUrl}/subscriptions/search?page=${page}&limit=${limit}&q=wildCard~${escapedSearch!="null" ? escapedSearch:''}${filters || ''}&${ sort || 'field=created&order=-1'}`)
    .then(response => { return response })
}

export const getAccountSubs = async (accountId) => {
    return apiClient.get(`${apiBaseUrl}/subscriptions/${accountId}`)
    .then(response => { return response })
}

export const getBalances = async (accountId, orderId) => {
    return apiClient.get(`${apiBaseUrl}/balance-taxpayer/${accountId}/order/${orderId}`)
    .then(response => { return response })
}

export const getReport = async () => {
    return apiClient.get(`${apiBaseUrl}/subscriptions/report`)
    .then(response => { return response })
}

export const cancelSubscription = async (request) => {
    return apiClient.post(`${apiBaseUrl}/subscriptions/cancel`, request)
    .then(response => { return response })
}