const useDateInterval = () => {
    const filterItems = (filters) => {
        var range_field = filters?.start ? "&range_field="+filters.value:""
        var range_start = filters?.start ? "&"+"range_start="+filters.start:""
        var range_end = filters?.end ? "&"+"range_end="+filters.end:""
        const filterString = range_field+range_start+range_end;
        return filterString
    }
    return filterItems
}

export default useDateInterval