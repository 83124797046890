import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, Link } from 'react-router-dom';
import { faArrowLeft, faCartShopping, faCirclePlus, faDollarSign, faPercent, faPlus, faTag, faTrash, faCaretDown, faCircleXmark, faCalculator, faSquareArrowUpRight, faCalendar } from '@fortawesome/free-solid-svg-icons';
import { sendQuote, updateQuote } from '../../apiClient/operations/quotes';
import { getServices } from '../../apiClient/operations/services';
import Nav from '../../hooks/Nav';
import useGetDate from '../../hooks/useGetDate';
import Dropdown from '../../hooks/Dropdown';
import useNextBusinessDay from '../../hooks/useNextBusinessDate';
import ServiceValidity from '../../hooks/ServiceValidity';
import HeadersDataTables from '../../hooks/HeadersDataTables';
import Button from '../../hooks/Button';
import DateRangePicker from '../../hooks/DateRangePicker';
import DateYearRangePicker from '../../hooks/DataYearRangePicker';
import Modal from '../../hooks/Modal';
import ResumeAmounts from '../../hooks/ResumeAmounts';
import { AlertContext } from '../../context/AlertContext';
import useFormatDate from '../../hooks/useFormatDate';
import useFormatterCurrency from '../../hooks/useFormatterCurrency';
import useConvertUnixTime from '../../hooks/useConvertUnixTime';
import useGetInfoServices from '../../hooks/useGetInfoServices';
import useScrollPosition from '../../hooks/useScrollPosition';
import ThankYouPageReadOnly from '../../hooks/ThankYouPageReadOnly';
import UploadFileS3 from '../../hooks/UploadFileS3';
import GeneralDataClient from '../../hooks/GeneralDataClient';
import InvoicingData from '../../hooks/InvoicingData';
import SecondaryLoader from '../../hooks/SecondaryLoader';
import PacksServices from '../../hooks/PacksServices';
import ModalDeleteService from '../../hooks/ModalDeleteService';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import useConvertDatePicker from '../../hooks/useConvertDatePicker';
import DatePicker from 'react-modern-calendar-datepicker';
import useReverseDatepicker from '../../hooks/useReverseDatepicker';
import BreadCrumb from '../../tailwindUI/BreadCrumb';
import usePaymentRules from '../../hooks/usePaymentRules';

const accountingServicesID = JSON.parse(process.env.REACT_APP_ACCOUNTING_SERVICES_IDS) || [];

function NewQuote({ user }) {
    const [account] = useState(() => {
        const saved = localStorage.getItem("account");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
    });
    const [userSeller] = useState(user);
    const { formatterCurrency } = useFormatterCurrency();
    const { convertDatePicker } = useConvertDatePicker();
    const { reverseDatePicker } = useReverseDatepicker();
    const { formatDate } = useFormatDate();
    const { getDate } = useGetDate();
    const { convertUnixTime } = useConvertUnixTime();
    const { getInfoServices } = useGetInfoServices();
    const setScrollPosition = useScrollPosition();
    const { setAlert } = useContext(AlertContext);
    const nextBusinessDate = useNextBusinessDay(getDate(), 3)._d;
    let history = useHistory();
    let sendData = {};
    const [infoServices, setInfoServices] = useState([]);
    const [secondaryLoader, setSecondaryLoader] = useState(false);
    const [dataClient, setDataClient] = useState({ id: account.account_info.id, first_name: account.account_info.first_name, last_name: account.account_info.last_name, rfc: account.account_info.taxpayer?.tax_id, email: account.account_info.email, phone_number: account.account_info.phone_number, city: account.account_info.state, occupation: account.account_info.occupancy, legal_type: account.account_info.legal_type, regime: account.account_info.taxpayer?.regime });
    const [dataInvoicing, setDataInvoicing] = useState({
        businessName: account.account_info?.billing?.name || `${account.account_info.first_name} ${account.account_info.last_name}`,
        rfcInvoicing: account.account_info?.billing?.tax_id || account.account_info.taxpayer?.tax_id,
        emailInvoicing: account.account_info?.billing?.email || account.account_info.email,
        tax_regime: account.account_info?.billing?.tax_regime || account.account_info.taxpayer?.regime,
        legal_type: account.account_info?.billing?.legal_type || account.account_info.legal_type,
        postal_code: account.account_info?.billing?.postal_code
    });
    const [resumeAmounts, setResumeAmounts] = useState({ subtotalAmount: 0, discountAmount: 0, totalAmount: 0, numberServices: 0, totalPack: 0, totalPackDiscount: 0 });
    const { installments: getInstallmentsEnabled, partialities: getPartialitiesEnabled } = usePaymentRules();
    const [step, setStep] = useState(1);
    const [installmentsEnabled, setInstallmentsEnabled] = useState([])
    const [installments, setInstallments] = useState(account.payment_installments ? account.payment_installments : 1);
    const [partialsEnabled, setPartialsEnabled] = useState(getPartialitiesEnabled(resumeAmounts?.totalPack)?.map(installment => installment.shortTile))
    const [partials, setPartials] = useState(account.payment_partials ? account.payment_partials : 3);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [serviceDate, setServiceDate] = useState(convertDatePicker(formatDate(nextBusinessDate, 'YYYY-MM-DD')));
    const [openModal, setOpenModal] = useState(false);
    const [modalServiceID, setModalServiceID] = useState(0);
    const [modalServices, setModalServices] = useState([]);
    const [deleteServiceID, setDeleteServiceID] = useState(0);
    const [services, setServices] = useState([]);
    const [index, setIndex] = useState(account.id === undefined ? 0 : 30);
    const [servicesDisplay, setServicesDisplay] = useState([]);
    const [quoteDocument, setQuoteDocument] = useState({ key: '', bucket: '' });
    const [packActive, setPackActive] = useState(account.id === undefined ? false : true);
    const [quote, setQuote] = useState(null);
    const [isRenewal, setIsRenewal] = useState(false);
    const roadMap = [
        { name: 'Cotizador', url: '/', current: false },
        { name: 'Generando cotización', url: '/new-quote', current: true },
    ];

    useEffect(() => {
        const hasServiceAccounting = services.some(service => accountingServicesID.includes(service.service_id || service.id));
        const numberOfPeriods = services.filter(service => accountingServicesID.includes(service.service_id || service.id)).map(service => (service.periods || service.dates).length).reduce((acc, cur) => acc + cur, 0) || 3;
        setInstallmentsEnabled(getInstallmentsEnabled(resumeAmounts?.totalPack)?.map(installment => installment.shortTile));
        setPartialsEnabled(getPartialitiesEnabled(hasServiceAccounting, numberOfPeriods, resumeAmounts?.totalPack)?.map(partial => partial.value));
    }, [resumeAmounts, services]);

    const handleAddService = () => {
        const nameService = infoServices.filter(service => service.sku == modalServiceID);
        setServices([...services, { id: nameService[0].id, sku: nameService[0].sku, name: nameService[0].name, category: nameService[0].category, index: index, amount: nameService[0].price / 100, subtotal: 0, discount: 0, discount_amount: 0, discount_option: 'percent', total: 0, notes: '', dates: [], quantity: 0, resume_open: false, recurrent: nameService[0].periodicity != "unique" ? true : false, periodicity: nameService[0].periodicity, pack_discount_option: 'percent', pack_discount: 0, pack_discount_amount: 0, pack_subtotal: 0, pack_total: 0, modal_delete_service: false, serie: false, time_periods: nameService[0].time_periods }]);
        setServicesDisplay([...servicesDisplay, { sku: nameService[0].sku, index: index, name: nameService[0].name, dates_display: [] }]);
        setOpenModal(false);
        calculateTotalAmountSpecial();
        setIndex(index + 1);
        setModalServiceID(0);
    }

    const handleDeleteService = index => {
        const modifiedDeleteModal = services.map(service => {
            if (service.index == index) {
                return {
                    ...service,
                    modal_delete_service: false
                }
            }
            return service
        });
        setServices(modifiedDeleteModal);
        const finalServices = modifiedDeleteModal.filter(service => service.index != index);
        const finalDisplay = servicesDisplay.sort((a, b) => a.index - b.index).filter(service => service.index != index);
        setServices(finalServices);
        setServicesDisplay(finalDisplay);
        reCalculateServices(finalServices);
        setDeleteServiceID(0);
        setOpenModal(false);
    }

    const calculateTotalAmountSpecial = () => {
        setResumeAmounts({ ...resumeAmounts, numberServices: resumeAmounts.numberServices + 1 });
    }

    const reCalculateServices = discount => {
        const totalDiscount = discount.map(service => service.discount_amount).reduce((prev, curr) => prev + curr, 0);
        const total = discount.map(service => service.total).reduce((prev, curr) => prev + curr, 0);
        const sub = discount.map(service => service.subtotal).reduce((prev, curr) => prev + curr, 0);
        const pack_total_discount = discount.map(service => service.pack_discount_amount).reduce((prev, curr) => prev + curr, 0);
        const pack_total = discount.map(service => service.pack_total).reduce((prev, curr) => prev + curr, 0);
        setResumeAmounts({ ...resumeAmounts, discountAmount: totalDiscount, totalAmount: total, subtotalAmount: sub, numberServices: resumeAmounts.numberServices - 1, totalPack: pack_total, totalPackDiscount: pack_total_discount });
    }

    const calculateDiscount = discount => {
        const totalDiscount = discount.map(service => service.discount_amount).reduce((prev, curr) => prev + curr, 0);
        const total = discount.map(service => service.total).reduce((prev, curr) => prev + curr, 0);
        const sub = discount.map(service => service.subtotal).reduce((prev, curr) => prev + curr, 0);
        const pack_total_discount = discount.map(service => service.pack_discount_amount).reduce((prev, curr) => prev + curr, 0);
        const pack_total = discount.map(service => service.pack_total).reduce((prev, curr) => prev + curr, 0);
        setResumeAmounts({ ...resumeAmounts, discountAmount: totalDiscount, totalAmount: total, subtotalAmount: sub, totalPack: pack_total, totalPackDiscount: pack_total_discount });
    }

    const calculateTotalAmountPerService = (index, modifier) => {
        const totalAmount = modifier.map(service => {
            if (index == service.index) {
                return {
                    ...service,
                    total: (service.discount_option == 'direct' ? ((service.amount * service.quantity) - service.discount) : ((service.amount * service.quantity) - ((service.amount * service.quantity) * (service.discount / 100)))),
                    discount_amount: service.discount_option == 'direct' ? parseFloat(service.discount) : ((service.amount * service.quantity) * (service.discount / 100)),
                    subtotal: (service.amount * service.quantity),
                    pack_total: (service.pack_discount_option == 'direct' ? ((service.amount * service.quantity) - service.pack_discount) : ((service.amount * service.quantity) - ((service.amount * service.quantity) * (service.pack_discount / 100)))),
                    pack_discount_amount: service.pack_discount_option == 'direct' ? parseFloat(service.pack_discount) : ((service.amount * service.quantity) * (service.pack_discount / 100)),
                    pack_subtotal: (service.amount * service.quantity)
                }
            }
            return service
        });
        setServices(totalAmount);
        calculateDiscount(totalAmount);
    }

    const handleUpdateAmount = (amount, index) => {
        const modifiedAmount = services.map(service => {
            if (index == service.index) {
                return {
                    ...service,
                    amount: isNaN(amount) ? '' : parseFloat(amount),
                    subtotal: isNaN(amount) ? '' : (parseFloat(amount) * service.quantity)
                }
            }
            return service
        });
        setServices(modifiedAmount);
        calculateTotalAmountPerService(index, modifiedAmount);
    }

    const handleUpdateDiscount = (discount, index) => {
        const modifiedDiscount = services.map(service => {
            if (index == service.index) {
                return {
                    ...service,
                    discount: isNaN(discount) ? '' : parseFloat(discount)
                }
            }
            return service
        });
        setServices(modifiedDiscount);
        calculateTotalAmountPerService(index, modifiedDiscount);
    }

    const handleUpdateNotes = (notes, index) => {
        const modifiedNotes = services.map(service => {
            if (index == service.index) {
                return {
                    ...service,
                    notes: notes
                }
            }
            return service
        });
        setServices(modifiedNotes);
    }

    const handleChangeDiscountOption = (option, index) => {
        const modifiedOption = services.map(service => {
            if (service.index == index) {
                return {
                    ...service,
                    discount_option: option == 'direct' ? 'percent' : 'direct'
                }
            }
            return service
        });
        setServices(modifiedOption);
        calculateTotalAmountPerService(index, modifiedOption);
    }

    const handleOpenResumeServices = (index, status) => {
        const modifiedOpen = services.map(service => {
            if (service.index == index) {
                return {
                    ...service,
                    resume_open: !status
                }
            }
            return service
        });
        setServices(modifiedOpen);
    }

    const handleEditRecurringCharge = (index, status) => {
        const modifiedRecurringPayment = services.map(service => {
            if (service.index == index) {
                return {
                    ...service,
                    recurrent: !status
                }
            }
            return service
        });
        setServices(modifiedRecurringPayment);
    }

    const handleDeleteServiceID = index => {
        setDeleteServiceID(index);
        const modifiedDeleteModal = services.map(service => {
            if (service.index == index) {
                return {
                    ...service,
                    modal_delete_service: true
                }
            }
            return service
        });
        setServices(modifiedDeleteModal);
    }

    const handleCloseServiceModal = index => {
        const modifiedDeleteModal = services.map(service => {
            if (service.index == index) {
                return {
                    ...service,
                    modal_delete_service: false
                }
            }
            return service
        });
        setServices(modifiedDeleteModal);
    }

    const getInfoServicesApi = async () => {
        try {
            const response = await getServices();
            const info = response.data.filter(item => item.legal_type == dataClient.legal_type);
            setInfoServices(info);
            getServicesPreData(info);
            setModalServices(info);
        } catch (error) {
            setAlert({ active: true, type: 'error', message: 'Error al obtener servicios.' })
        }
    }

    const getServicesPreData = (servicesData) => {
        if (account.service_items !== undefined && services.length == 0) {
            const newServices = account.service_items.services.map(service => {
                const time_periods = servicesData?.filter(ser => ser.id === service.id)[0]?.time_periods || '';
                return {
                    ...service,
                    time_periods: time_periods
                }
            });
            setServices(newServices);
        }
    }

    const getPreDatesDisplay = () => {
        if (account.service_items !== undefined && services.length == 0) {
            setResumeAmounts(account.service_items.resume_amounts);
            setServicesDisplay(account.service_items.dates_display);
        }
    }

    const handleNextStep2 = () => {
        setAlert({ active: false, type: 'error', message: '' })
        try {
            setSecondaryLoader(true);
            getPreDatesDisplay();
            getInfoServicesApi();
            setStep(2);
            setScrollPosition(0);
            setSecondaryLoader(false);
        } catch (e) {
            setAlert({ active: true, type: 'error', message: 'Error al procesar tu solicitud.' })
        }
    }

    const handleNextStep3 = step => {
        setAlert({ active: false, type: 'error', message: '' })
        setSecondaryLoader(true);
        setScrollPosition(0);
        setStep(step);
        setSecondaryLoader(false);
    }

    const handleSendData = async () => {
        setAlert({ active: false, type: 'error', message: '' })
        try {
            setSecondaryLoader(true);
            sendData = {
                expires_at: convertUnixTime(reverseDatePicker(serviceDate)),
                payment_installments: installments,
                payment_partials: partials,
                service_items: getInfoServices(services, packActive),
                // account_info: {
                //     account_id: dataClient.id,
                //     name: `${dataClient.first_name} ${dataClient.last_name}`
                // },
                account: {
                    id: dataClient.id,
                    name: `${dataClient.first_name} ${dataClient.last_name}`
                },
                seller: {
                    name: userSeller.name,
                    email: userSeller.email
                },
                is_renewal: isRenewal
            }
            if (account.id === undefined) {
                const postQuote = await sendQuote(sendData);
                setQuote(postQuote);
                setQuoteDocument({ key: postQuote.document.key, bucket: postQuote.document.bucket });
                setTimeout(() => {
                    setStep(4);
                    setScrollPosition(0);
                    setSecondaryLoader(false);
                    setAlert({ active: true, type: 'success', message: 'Cotización generada.' });
                }, 5000);
            } else {
                const putQuote = await updateQuote(sendData, account.id);
                setQuote(putQuote);
                setQuoteDocument({ key: putQuote.document.key, bucket: putQuote.document.bucket });
                setTimeout(() => {
                    setStep(4);
                    setScrollPosition(0);
                    setSecondaryLoader(false);
                    setAlert({ active: true, type: 'success', message: 'Cotización generada.' });
                }, 5000);
            }
        } catch (error) {
            console.log(error)
            setSecondaryLoader(false);
            setStep(3);
            setAlert({ active: true, type: 'error', message: 'Error al procesar tu solicitud.' });
        }
    }

    const handlePrevious = () => {
        if (step == 2) setStep(1);
        else if (step == 3) setStep(2);
        else history.push(account.id === undefined ? '/' : '/generated-quotes');
    }

    const handleOpenAddServiceModal = () => {
        setModalServiceID(0);
        setOpenModal(true);
    }

    const handleRenewed = () => setIsRenewal(!isRenewal)

    return (
        <>
            {secondaryLoader && <SecondaryLoader />}
            <div className="w-full">
                <Nav user={userSeller}>
                    <div className="w-full">
                        <span className="text-lg text-white font-bold flex items-center w-full">
                            <span className="text-white opacity-50 text-4xl mr-2">
                                {step < 4 &&
                                    <span className='cursor-pointer' onClick={handlePrevious}>
                                        <FontAwesomeIcon icon={faArrowLeft} />
                                    </span>
                                }
                            </span>
                            <span className={`${step == 4 ? 'text-2xl' : 'text-lg'} pr-1`}>
                                {(() => {
                                    if (step < 3 && step > 0) return 'Nueva cotización';
                                    else if (step == 3) return 'Resumen';
                                    return step == 4 ? 'Gracias' : '';
                                })()}
                            </span>
                        </span>
                    </div>
                </Nav>
            </div>
            <div className="w-full px-4">
                <div className="w-full mt-20 lg:mt-4 px-2">
                    <BreadCrumb roadMap={roadMap} />
                </div>
                <div className={`${(() => {
                    if (step < 2) return 'md:block';
                    return step == 4 ? 'block' : 'lg:flex justify-between gap-0';
                })()} xs:block w-full mt-5`}>
                    <div className='md:w-full hidden lg:block w-full px-2'>
                        <div className={`w-full text-3xl text-v2-input-text font-bold ${step < 2 ? 'flex' : 'block'} justify-between`}>
                            <div className="w-full">
                                {step < 4 ?
                                    <span className='cursor-pointer' onClick={handlePrevious}>
                                        <FontAwesomeIcon icon={faArrowLeft} />
                                    </span>
                                    :
                                    <>
                                        {step == 4 &&
                                            <div className="w-full font-normal text-base text-v2-yellow-edit-info flex justify-end items-center mt-2">
                                                <Link to='/generated-quotes' onClick={() => history.push('/generated-quotes')} className='flex items-center'>
                                                    <FontAwesomeIcon icon={faSquareArrowUpRight} className="text-2xl mr-2" /><span className="underline cursor-pointer">Ver todas cotizaciones</span>
                                                </Link>
                                            </div>
                                        }
                                    </>
                                }
                                <span className="pl-3">
                                    {(() => {
                                        if (step < 3 && step > 0) return 'Nueva cotización';
                                        return step == 3 ? 'Resumen' : '';
                                    })()}
                                </span>
                            </div>
                            <div className={`${step < 2 ? 'block' : 'hidden'} w-full`}>
                                <div className="w-full flex">
                                    {step < 4 &&
                                        <div className='w-full'>
                                            <div className="w-full mx-auto text-white text-sm font-normal flex">
                                                <span className={`float-left w-1/3 relative text-center ${step > 1 ? 'before:cursor-pointer' : 'before:cursor-default'} before:content-['1'] before:w-9 before:h-9 before:border-2 before:block before:mx-auto before:mt-0 before:mb-2.5 before:rounded-full before:leading-9 before:text-center before:bg-accent-base before:text-white before:border-white before:text-xs before:z-10 before:relative after:content-[''] after:absolute after:w-full after:h-1.5 ${step > 0 ? 'after:bg-v2-blue-text-login' : 'after:bg-v2-gray-bar-base'} after:top-4 after:-left-1/2 after:z-0 after:content-[none]`} onClick={() => setStep(1)} ></span>
                                                <span className={`float-left w-1/3 relative text-center ${step == 3 ? 'before:cursor-pointer' : 'before:cursor-default'} before:content-['2'] before:w-9 before:h-9 before:border-2 before:block before:mx-auto before:mt-0 before:mb-2.5 before:rounded-full before:leading-9 before:text-center ${step > 1 ? 'before:bg-accent-base before:text-white before:border-white' : 'before:bg-white before:text-v2-gray-headers-tables before:border-v2-border-time'} before:text-xs before:z-10 before:relative after:content-[''] after:absolute after:w-full after:h-1.5 ${step > 1 ? 'after:bg-v2-blue-text-login' : 'after:bg-v2-gray-bar-base'} after:top-4 after:-left-1/2 after:z-0`} onClick={() => step == 1 ? setStep(1) : setStep(2)}></span>
                                                <span className={`float-left w-1/3 relative text-center before:content-['3'] before:w-9 before:h-9 before:border-2 before:block before:mx-auto before:mt-0 before:mb-2.5 before:rounded-full before:leading-9 before:text-center ${step > 2 ? 'before:bg-accent-base before:text-white before:border-white' : 'before:bg-white before:text-v2-gray-headers-tables before:border-v2-border-time'} before:text-xs before:z-10 before:relative after:content-[''] after:absolute after:w-full after:h-1.5 ${step > 2 ? 'after:bg-v2-blue-text-login' : 'after:bg-v2-gray-bar-base'} after:top-4 after:-left-1/2 after:z-0`}></span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full">
                        {step < 4 &&
                            <div>
                                <div className={`${step < 2 ? 'lg:hidden' : 'lg:flex'} w-full mt-0`}>
                                    {step < 4 &&
                                        <div className='w-full'>
                                            <div className="w-full mx-auto text-white text-sm font-normal flex">
                                                <span className={`float-left w-1/3 relative text-center ${step > 1 ? 'before:cursor-pointer' : 'before:cursor-default'} before:cursor-pointer before:content-['1'] before:w-9 before:h-9 before:border-2 before:block before:mx-auto before:mt-0 before:mb-2.5 before:rounded-full before:leading-9 before:text-center before:bg-accent-base before:text-white before:border-white before:text-xs before:z-10 before:relative after:content-[''] after:absolute after:w-full after:h-1.5 ${step > 0 ? 'after:bg-accent-base' : 'after:bg-v2-gray-bar-base'} after:top-4 after:-left-1/2 after:z-0 after:content-[none]`} onClick={() => setStep(1)} ></span>
                                                <span className={`float-left w-1/3 relative text-center ${step == 3 ? 'before:cursor-pointer' : 'before:cursor-default'} before:content-['2'] before:w-9 before:h-9 before:border-2 before:block before:mx-auto before:mt-0 before:mb-2.5 before:rounded-full before:leading-9 before:text-center ${step > 1 ? 'before:bg-accent-base before:text-white before:border-white' : 'before:bg-white before:text-v2-gray-headers-tables before:border-v2-border-time'} before:text-xs before:z-10 before:relative after:content-[''] after:absolute after:w-full after:h-1.5 ${step > 1 ? 'after:bg-accent-base' : 'after:bg-v2-gray-bar-base'} after:top-4 after:-left-1/2 after:z-0`} onClick={() => step == 1 ? setStep(1) : setStep(2)}></span>
                                                <span className={`float-left w-1/3 relative text-center before:content-['3'] before:w-9 before:h-9 before:border-2 before:block before:mx-auto before:mt-0 before:mb-2.5 before:rounded-full before:leading-9 before:text-center ${step > 2 ? 'before:bg-accent-base before:text-white before:border-white' : 'before:bg-white before:text-v2-gray-headers-tables before:border-v2-border-time'} before:text-xs before:z-10 before:relative after:content-[''] after:absolute after:w-full after:h-1.5 ${step > 2 ? 'after:bg-accent-base' : 'after:bg-v2-gray-bar-base'} after:top-4 after:-left-1/2 after:z-0`}></span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
                {step === 1 &&
                    <div className="w-full xs:mt-2 md:mt-4">
                        <div className="w-full xs:block md:flex gap-4">
                            <div className="xs:w-full md:w-1/2">
                                <GeneralDataClient dataClient={dataClient} setDataClient={setDataClient} edit={true} setSecondaryLoader={setSecondaryLoader} dataInvoicing={dataInvoicing} setDataInvoicing={setDataInvoicing} />
                            </div>
                            <div className="xs:w-full md:w-1/2">
                                <InvoicingData dataInvoicing={dataInvoicing} setDataInvoicing={setDataInvoicing} edit={true} source="add" dataClient={dataClient} setSecondaryLoader={setSecondaryLoader} />
                            </div>
                        </div>
                        <div className="w-full my-3">
                            <UploadFileS3 selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} rfc={dataClient.rfc} dataClient={dataClient} />
                        </div>
                        <div className="w-full mt-4 mb-4 md:flex md:justify-end">
                            <Button heigth="h-12" width="xs:w-full md:w-2/6" bg="bg-v2-blue-text-login" disabled={Object.entries(dataClient).some(([key, value]) => key == '' || value == undefined) || Object.entries(dataInvoicing).some(([key, value]) => key == '' || value == undefined) ? true : false} onClick={() => handleNextStep2()}>
                                Siguiente
                            </Button>
                        </div>
                    </div>
                }
                {step === 2 &&
                    <div className="w-full xs:mt-2 md:mt-4 block">
                        <div className="w-full xs:block md:flex gap-4 xs:pt-0 md:pt-0">
                            <div className="xs:w-full md:w-1/2 border border-v2-gray-border-tables bg-white rounded-10">
                                <HeadersDataTables icon={faCalendar} text="Vigencia" background={true} padding={0.5} bgColor="bg-v2-blue-icons" textColor="text-white" />
                                <div className="w-full px-4 gap-1 py-2 flex flex-col items-center justify-center lg:flex-row lg:gap-8">
                                    <div className="w-full xs:pb-3 md:py-3 text-xs text-v2-gray-title-client font-bold xs:cols-span-3 md:cols-span-1">
                                        Límite de pago:
                                        <div className='w-full relative mt-2.5'>
                                            <DatePicker
                                                value={serviceDate}
                                                onChange={setServiceDate}
                                                locale='en'
                                                wrapperClassName='block z-10 responsive-calendar'
                                                inputClassName='w-full z-10 appearance-none cursor-pointer text-left py-2 px-4 font-normal border border-v2-border-time rounded-md text-base text-v2-input-text outline-none responsive-calendar'
                                                calendarClassName='block font-normal text-v2-input-text'
                                                calendarPopperPosition='bottom'
                                                colorPrimary='#2169AC'
                                                minimumDate={convertDatePicker(formatDate(getDate(), 'YYYY-MM-DD'))}
                                            />
                                            <FontAwesomeIcon icon={faCalendar} className='absolute w-5 z-10 h-5 right-2 top-[50%] -translate-y-1/2 text-v2-yellow-edit-info' />
                                        </div>
                                        <input
                                            type="text"
                                            readOnly={true}
                                            value={formatDate(getDate(), 'DD [de] MMMM YYYY')}
                                            className="hidden w-full appearance-none xs:pt-2 md:pt-5 opacity-60 select-none text-base text-v2-text-bar-steps outline-none items-center justify-center font-normal"
                                        />
                                    </div>
                                    {/*<div className="w-full xs:pb-3 md:py-3 text-xs text-v2-gray-title-client font-bold xs:cols-span-2 md:cols-span-1">
                                            Límite de pago:
                                            <input 
                                            type="date"
                                            min={ getDate() }
                                            value = { serviceDate }
                                            onInput={ e => setServiceDate(e.target.value) }
                                            className="w-full appearance-none block py-2.5 mt-1.5 px-4 border border-v2-border-time rounded-md text-base text-v2-text-bar-steps outline-none flex items-center justify-center"
                                            />
                                        </div>*/}
                                    <div className="w-full xs:pb-3 md:py-3 text-xs text-v2-input-text font-bold xs:cols-span-3 md:cols-span-1 mt-0 lg:mt-6 ">
                                        <div className="relative flex items-start">
                                            <div className="flex h-6 items-center">
                                                <input
                                                    id="renewal"
                                                    value={isRenewal}
                                                    onChange={handleRenewed}
                                                    aria-describedby="renewal-description"
                                                    name="renewal"
                                                    type="checkbox"
                                                    className="h-4 w-4 rounded border-gray-300 text-v2-orange-text-dates-quantity focus:text-v2-orange-text-dates-quantity"
                                                />
                                            </div>
                                            <div className="ml-3 text-sm leading-6">
                                                <label htmlFor="renewal" >
                                                    Cotización de renovación
                                                </label>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="xs:w-full md:w-1/2 h-full xs:mt-4 md:mt-0">
                                <ResumeAmounts subtotal={resumeAmounts.subtotalAmount} discount={resumeAmounts.discountAmount} totalAmount={resumeAmounts.totalAmount} amountServices={resumeAmounts.numberServices} />
                            </div>
                        </div>
                        <div className="w-full mt-2.5 mb-6">
                            <div className="w-full h-auto border border-v2-gray-border-tables rounded-10 bg-white xs:mt-4 md:mt-0">
                                <div className={`w-full xs:px-4 md:px-4 py-2 ${services.length == 0 ? 'border-b border-v2-gray-border-tables' : ''} flex items-center`}>
                                    <div className="xs:w-12 md:w-11">
                                        <div className='rounded-full w-8 h-8 bg-v2-blue-icons text-lg text-white flex justify-center items-center'>
                                            <FontAwesomeIcon icon={faCartShopping} className='leading-3 pt-0.5' />
                                        </div>
                                    </div>
                                    <div className='xs:w-8/12 md:w-8/12 text-xl font-semibold text-v2-input-text'>
                                        Agrega servicios
                                    </div>
                                    <div className="w-4/12 justify-end items-center text-button-orange">
                                        <div className="flex items-center justify-end gap-2">
                                            <Button heigth="h-5" width="w-5" rounded="rounded-full" bg="bg-v2-yellow-edit-info" onClick={handleOpenAddServiceModal}>
                                                <FontAwesomeIcon icon={faPlus} className="leading-3" />
                                            </Button>
                                            <span className='xs:hidden underline md:inline-block text-v2-yellow-edit-info text-base cursor-pointer' onClick={handleOpenAddServiceModal}>
                                                Agregar servicio
                                            </span>
                                        </div>
                                    </div>
                                    <Modal modalOpen={openModal} modalOpenChange={setOpenModal} hasTitle={false} maxWidth='md:max-w-lg 2xl:max-w-3xl' marginY='my-full'>
                                        <div className="w-full h-72 flex justify-center items-center">
                                            <div className="xs:w-5/6 md:w-full text-center">
                                                <div className="font-semibold text-xl text-v2-input-text">
                                                    ¿Qué servicio agregarás?
                                                </div>
                                                <div className="block mt-7 w-full flex justify-center">
                                                    <select className="xs:w-full md:w-3/4 form-select cursor-pointer block w-full py-2 px-1.5 text-base text-gray-700 bg-white border border-solid border-light-gray rounded-5 transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" onChange={e => setModalServiceID(e.target.value)}>
                                                        <option value={0} defaultValue>Selecciona un servicio</option>
                                                        {modalServices.map((service, i) => (
                                                            <option key={i} value={service.sku} >{service.name}</option>
                                                        ))
                                                        }
                                                    </select>
                                                </div>
                                                <div className="block mt-10 w-full flex justify-center">
                                                    <Button heigth="h-12" width="xs:w-full md:w-3/4 lg:w-1/2" disabled={modalServiceID == 0 ? true : false} onClick={handleAddService}>
                                                        Agregar
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Modal>
                                </div>
                                {services.length == 0 &&
                                    <>
                                        <div className="w-full h-[350px] flex justify-center items-center">
                                            <div className="xs:w-5/6 md:w-1/2 text-center">
                                                <div className="text-v2-gray-light-plus-circle text-4.5xl leading-3">
                                                    <FontAwesomeIcon icon={faCirclePlus} />
                                                </div>
                                                <div className="text-button-orange text-xl font-bold opacity-60 text-center mt-6">
                                                    ¡Uy! No tienes ningún servicio agregado.
                                                </div>
                                                <div className="block mt-8 text-base text-v2-text-bar-steps">
                                                    Agrega el primer servicio dando clic al botón.
                                                </div>
                                                <div className="block mt-8 w-full flex justify-center">
                                                    <Button heigth="h-12" width="xs:w-full md:w-3/4 lg:w-4/6" bg="bg-white" border='border-v2-blue-text-login' textColor="text-v2-blue-text-login" onClick={handleOpenAddServiceModal}>
                                                        Agregar
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                                {services.length > 0 &&
                                    services.map((service, i) => (
                                        <div key={i} className="w-full">
                                            <div className="w-full border-t border-v2-gray-border-tables bg-v2-gray-intense-bg-services flex items-center py-3.5 xs:px-5 md:px-12">
                                                <div className="w-5/6 font-semibold text-base text-v2-text-bar-steps">
                                                    {service.name}
                                                </div>
                                                <div className="w-1/6 flex justify-end text-v2-yellow-edit-info text-2xl leading-3">
                                                    <FontAwesomeIcon icon={faTrash} className="cursor-pointer" onClick={() => handleDeleteServiceID(service.index)} />
                                                </div>
                                                <ModalDeleteService hasTitle={false} modalOpen={service} services={services} setServices={setServices} maxWidth="max-w-lg" marginY='my-full'>
                                                    <div className="w-full pt-2 pb-5 px-[9px]">
                                                        <div className="w-full flex justify-end">
                                                            <span className="w-full flex justify-end text-v2-gray-light-plus-circle leading-3 text-2xl cursor-pointer" onClick={() => handleCloseServiceModal(service.index)}>
                                                                <FontAwesomeIcon icon={faCircleXmark} />
                                                            </span>
                                                        </div>
                                                        <div className="w-full mt-4">
                                                            <span className="flex justify-center text-v2-red-circle-delete-service-modal leading-3 text-7xl">
                                                                <FontAwesomeIcon icon={faCircleXmark} />
                                                            </span>
                                                        </div>
                                                        <div className="w-full mt-5">
                                                            <span className="w-full flex justify-center text-v2-gray-title-service-modal text-xl text-center">
                                                                ¿Quieres eliminar el servicio?
                                                            </span>
                                                            <span className="w-full flex justify-center text-v2-text-bar-steps mt-6">
                                                                <span className="text-center">
                                                                    Si lo eliminas tendrás que volver a empezar.
                                                                    <span className="xs:inline-block xs:pl-1 md:pl-0 md:block md:text-center">
                                                                        ¿Estás seguro?
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                        <div className="w-full flex justify-center mt-16">
                                                            <span className="text-center text-v2-blue-text-login underline text-base cursor-pointer" onClick={() => handleCloseServiceModal(service.index)}>
                                                                No, me equivoqué
                                                            </span>
                                                        </div>
                                                        <div className="mt-10 px-4">
                                                            <Button heigth="h-12" width="w-full" bg="bg-v2-blue-text-login" onClick={() => handleDeleteService(deleteServiceID)}>
                                                                Sí, estoy seguro
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </ModalDeleteService>
                                            </div>
                                            <div className="w-full py-3.5 xs:px-5 md:pl-12 md:pr-7 border-t border-v2-gray-light-border">
                                                <div className="w-full pb-2.5">
                                                    {service.periodicity == 'yearly' ?
                                                        <div className={`${service.periodicity != "unique" ? 'xs:w-full md:w-full xs:block md:flex items-center' : 'w-full'}`}>
                                                            <div className="w-full text-xs text-v2-gray-title-client">
                                                                <div className="w-full xs:block md:flex items-center">
                                                                    <div className="w-4/6 text-xs text-v2-gray-title-client">
                                                                        {(() => {
                                                                            if (service.category == 'accounting') return 'Años de trabajo:';
                                                                            return service.category == 'regularization' ? 'Años fiscales:' : 'Periodos:';
                                                                        })()}
                                                                    </div>
                                                                    <div className="xs:w-full md:w-2/6 flex xs:justify-start md:justify-end xs:mt-1.5 md:mt-0 text-button-orange md:text-sm opacity-70">
                                                                        Cantidad: {service.quantity} periodos
                                                                    </div>
                                                                </div>
                                                                <DateYearRangePicker index={service.index} setServices={setServices} services={services} servicesDisplay={servicesDisplay} setServicesDisplay={setServicesDisplay} setResumeAmounts={setResumeAmounts} resumeAmounts={resumeAmounts} time={service.time_periods} />
                                                            </div>
                                                            {service.periodicity != "unique" &&
                                                                <div className="xs:w-full md:w-3/5 flex justify-center items-center gap-6 xs:pt-3 md:pt-6">
                                                                    <div className="text-v2-gray-title-client text-sm">
                                                                        Cobro recurrente
                                                                    </div>
                                                                    <div className="inline-block">
                                                                        <div className={`${service.recurrent ? 'bg-v2-blue-text-login' : 'bg-white'} w-16 h-7 flex items-center border border-v2-border-time rounded-full p-1 cursor-pointer mt-1`} onClick={() => handleEditRecurringCharge(service.index, service.recurrent)}>
                                                                            <div className={`${service.recurrent ? 'transform translate-x-8 duration-300 bg-white' : 'bg-v2-blue-text-login transform -translate-x-0.5 duration-300'}  h-6 w-6 rounded-full shadow-md`}>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                        :
                                                        <div className={`${service.periodicity != 'unique' ? 'xs:w-full md:w-full xs:block md:flex items-center' : 'w-full'}`}>
                                                            <div className={`w-full text-xs text-v2-gray-title-client`}>
                                                                <div className="w-full flex items-center">
                                                                    <div className="w-1/2 text-xs text-v2-gray-title-client">
                                                                        {(() => {
                                                                            if (service.category == 'accounting') return 'Meses de trabajo:';
                                                                            return service.category == 'regularization' ? 'Meses fiscales:' : 'Periodos:';
                                                                        })()}
                                                                    </div>
                                                                    <div className="w-1/2 flex justify-end xs:mt-1.5 md:mt-0 text-button-orange md:text-sm opacity-70">
                                                                        Cantidad: {service.quantity} periodos
                                                                    </div>
                                                                </div>
                                                                <DateRangePicker sku={service.index} setServices={setServices} services={services} servicesDisplay={servicesDisplay} setServicesDisplay={setServicesDisplay} time={service.time_periods} future={service.name.toUpperCase().includes('REGULARIZACIÓN') ? false : true} past={service.name.toUpperCase().includes('REGULARIZACIÓN') ? true : false} resumeAmounts={resumeAmounts} setResumeAmounts={setResumeAmounts} />
                                                                {service.periodicity == 'bimonthly' && service.quantity % 2 != 0 && <div className='mt-1 text-sm text-v2-red-error-services-selected'>Selecciona otro periodo para continuar</div>}
                                                                {service.serie && <div className='mt-1 text-sm text-v2-red-error-services-selected'>¡Ojo!, selecciona solo periodos continuos.</div>}
                                                            </div>
                                                            {service.periodicity != 'unique' ?
                                                                <div className="xs:w-full md:w-3/5 flex justify-center items-center gap-6 xs:pt-3 md:pt-6">
                                                                    <div className="text-v2-gray-title-client text-sm">
                                                                        Cobro recurrente
                                                                    </div>
                                                                    <div className="inline-block">
                                                                        <div className={`${service.recurrent ? 'bg-v2-blue-text-login' : 'bg-white'} w-16 h-7 flex items-center border border-v2-border-time rounded-full p-1 cursor-pointer mt-1`} onClick={() => handleEditRecurringCharge(service.index, service.recurrent)}>
                                                                            <div className={`${service.recurrent ? 'transform translate-x-8 duration-300 bg-white' : 'bg-v2-blue-text-login transform -translate-x-0.5 duration-300'}  h-6 w-6 rounded-full shadow-md`}>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <></>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                                <div className="w-full grid xs:grid-cols-1 md:grid-cols-9 xs:gap-0 md:gap-2.5">
                                                    <div className="w-full xs:col-span-2 md:col-span-3 text-xs text-v2-gray-title-client xs:mt-3 md:mt-0">
                                                        Precio unitario:
                                                        <div className="w-full px-2 h-[46px] mt-1.5 border border-v2-border-time rounded-md text-base text-v2-text-bar-steps outline-none flex items-center justify-center">
                                                            <span className="text-v2-gray-title-client">$</span>
                                                            <input
                                                                type="number"
                                                                value={service.amount}
                                                                onInput={e => handleUpdateAmount(e.target.value, service.index)}
                                                                className="w-full outline-none text-base pl-2"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="w-full text-xs text-v2-gray-title-client xs:col-span-1 md:col-span-4 leading-3 xs:mt-3 md:mt-0 xs:mr-0 md:ml-1">
                                                        <div className="flex items-center leading-none">
                                                            Descuento: <span><FontAwesomeIcon icon={faTag} className="leading-none text-v2-gray-title-client text-base pl-1" /></span>
                                                        </div>
                                                        <div className="w-full h-[46px] mt-1.5 text-base text-v2-text-bar-steps outline-none flex">
                                                            <div className={`${service.discount_option == 'percent' ? 'bg-v2-blue-text-login text-white' : 'bg-white text-v2-gray-text-amount cursor-pointer border-l border-y border-v2-border-time'} w-1/6 text-2xl leading-3 rounded-l-md flex items-center justify-center`} onClick={() => handleChangeDiscountOption('direct', service.index)}>
                                                                <FontAwesomeIcon icon={faPercent} />
                                                            </div>
                                                            <div className={`${service.discount_option == 'direct' ? 'bg-v2-blue-text-login text-white z-10' : 'bg-white text-v2-gray-text-amount cursor-pointer border-y border-r border-v2-border-time z-10'} w-1/6 text-2xl leading-3 flex items-center justify-center`} onClick={() => handleChangeDiscountOption('percent', service.index)}>
                                                                <FontAwesomeIcon icon={faDollarSign} />
                                                            </div>
                                                            <div className={`${service.discount_option == 'percent' && service.discount > 100 || service.discount_option == 'direct' && service.discount > (service.amount * service.quantity) || service.discount < 0 ? 'border-2 border-v2-red-error-services-selected z-20 pl-2 -ml-0.5' : 'border-r border-y border-v2-border-time -ml-1.5 pl-3'} w-4/6 flex items-center rounded-r-md`}>
                                                                <span className="text-v2-gray-title-client pl-2 text-base">{service.discount_option == 'direct' ? '$' : '%'}</span>
                                                                <input
                                                                    type='number'
                                                                    inputMode='numeric'
                                                                    value={isNaN(service.discount) ? '' : service.discount}
                                                                    onInput={e => handleUpdateDiscount(e.target.value, service.index)}
                                                                    className='w-full outline-none text-base pl-2'
                                                                />
                                                            </div>
                                                        </div>
                                                        {service.discount_option == 'percent' && service.discount > 100 && <span className='block mt-1 text-sm text-v2-red-error-services-selected'>¡Ojo!, el descuento máximo es el total del servicio</span>}
                                                        {service.discount_option == 'direct' && service.discount > (service.amount * service.quantity) && <span className='block mt-1 text-sm text-v2-red-error-services-selected'>¡Ojo!, el descuento máximo es el total del servicio</span>}
                                                        {service.discount < 0 && <span className='block mt-1 text-sm text-v2-red-error-services-selected'>¡Ojo!, no puedes ingresar descuentos negativos.</span>}
                                                    </div>
                                                    <div className="w-full xs:col-span-2 md:col-span-2 text-xs text-v2-gray-title-client xs:mt-3 md:mt-0">
                                                        Precio total:
                                                        <div className="w-full px-1 h-[46px] mt-1.5 bg-v2-gray-bg-discount border border-v2-border-time/30 rounded-md text-base text-v2-text-bar-steps outline-none flex items-center">
                                                            {service.amount > 0 && service.discount != 0 &&
                                                                <div className="text-v2-border-login line-through">
                                                                    <span className="outline-none text-base pl-1">
                                                                        {formatterCurrency(service.amount * service.quantity)}
                                                                    </span>
                                                                </div>
                                                            }
                                                            <div className="text-button-orange pl-2 font-semibold">
                                                                <span className="outline-none text-base pl-0.5">
                                                                    {(() => {
                                                                        if (isNaN(service.total) && isNaN(service.discount) && isNaN(service.amount)) return 0;
                                                                        else if (isNaN(service.total) && isNaN(service.discount)) return formatterCurrency(service.amount);
                                                                        return isNaN(service.amount) ? formatterCurrency(-service.discount) : formatterCurrency(service.total);
                                                                    })()}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="w-full py-2">
                                                    <div className="w-full text-xs text-v2-gray-title-client">
                                                        Notas:
                                                        <textarea
                                                            type="text"
                                                            placeholder="Escribe una nota"
                                                            value={service.notes}
                                                            onInput={e => handleUpdateNotes(e.target.value, service.index)}
                                                            className="w-full appearance-none block h-[75px] py-2.5 mt-1.5 px-4 border border-v2-border-time rounded-md text-base text-v2-text-bar-steps outline-none flex items-center justify-center"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            {services.length > 0 &&
                                <div className="w-full">
                                    <div className='w-full h-auto xs:block md:flex items-center xs:mb-0 md:my-4 gap-20'>
                                        <div className="xs:w-full md:w-2/3 flex xs:justify-center md:justify-end gap-2 xs:py-6 md:py-0">
                                            <Button heigth="h-5" width="w-5" rounded="rounded-full" bg="bg-v2-yellow-edit-info" onClick={handleOpenAddServiceModal}>
                                                <FontAwesomeIcon icon={faPlus} className="leading-3" />
                                            </Button>
                                            <span className='underline md:inline-block text-v2-yellow-edit-info text-base cursor-pointer' onClick={handleOpenAddServiceModal}>
                                                Agregar servicio
                                            </span>
                                        </div>
                                        <div className="xs:w-full md:w-1/3">
                                            <Button
                                                heigth="h-12"
                                                bg="bg-v2-blue-text-login"
                                                onClick={() => handleNextStep3(3)}
                                                disabled={(() => {
                                                    let disabled = resumeAmounts.totalAmount > 0 &&
                                                        services.map(service => service).every(x => x.discount >= 0) &&
                                                        services.map(service => service).every(ser => ser.quantity > 0) &&
                                                        services.map(service => service).every(i => i.total >= 0)
                                                    if (services.map(x => x).some(ser => ser.periodicity == 'bimonthly')) disabled = disabled && services.map(x => x).some(ser => ser.periodicity == 'bimonthly' && ser.quantity % 2 == 0);
                                                    return disabled && services.map(service => service).every(ser => !ser.serie) ? false : true
                                                })()}>
                                                Siguiente
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }
                {step == 3 &&
                    <div className="w-full xs:mt-2 md:mt-4 xs:block md:flex gap-4">
                        <div className="xs:w-full md:w-1/2">
                            <ServiceValidity date={reverseDatePicker(serviceDate)} />
                            <div className="my-3">
                                <GeneralDataClient dataClient={dataClient} setDataClient={setDataClient} setAlert={setAlert} edit={false} selectedFiles={selectedFiles} />
                            </div>
                            <div className="w-full xs:mb-0 md:mb-4">
                                <InvoicingData dataInvoicing={dataInvoicing} setDataInvoicing={setDataInvoicing} setAlert={setAlert} edit={false} source="add" />
                            </div>
                        </div>
                        <div className="xs:w-full md:w-1/2">
                            <div className="w-full">
                                <div className="w-full bg-white border border-v2-gray-border-tables rounded-10 xs:mt-4 md:mt-0">
                                    <HeadersDataTables icon={faCartShopping} bgColor='bg-button-orange' textColor='text-white' background={true} text='Precio por servicio' />
                                    <div className="w-full px-4 py-3 flex font-semibold text-xl text-v2-input-text bg-v2-gray-packs-services">
                                        <div className="xs:w-7/12 md:w-2/3">
                                            Ticket
                                        </div>
                                        <div className="xs:w-5/12 md:w-1/3">
                                            Total
                                        </div>
                                    </div>
                                    <div className="w-full">
                                        {services.map((service, i) => (
                                            <div key={i} className="w-full">
                                                <div className="w-full xs:px-3 md:px-4 py-2 border-t border-v2-gray-border-tables flex items-center">
                                                    <div className="xs:w-7/12 md:w-2/3 font-semibold text-v2-text-bar-steps">
                                                        {service.name}
                                                    </div>
                                                    <div className="xs:w-5/12 md:w-1/3 flex items-center">
                                                        <div className='w-full flex gap-4 items-center'>
                                                            {service.total - (service.amount * service.quantity) == 0 ?
                                                                <div className="w-28 text-base">
                                                                    <div className="text-v2-input-text opacity-60">
                                                                        {formatterCurrency(service.amount * service.quantity)}
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="w-28 text-base">
                                                                    <div className="text-v2-red-discount-tag leading-6">
                                                                        {formatterCurrency(service.total)} <FontAwesomeIcon icon={faTag} />
                                                                    </div>
                                                                    <div className="text-v2-input-text opacity-60 line-through">
                                                                        {formatterCurrency(service.amount * service.quantity)}
                                                                    </div>
                                                                </div>
                                                            }
                                                            <div className="xs:w-5 md:w-10 flex justify-left">
                                                                <div className="text-v2-gray-caret-resume-services leading-3 text-4xl">
                                                                    <FontAwesomeIcon icon={faCaretDown} className={`${service.resume_open ? 'rotate-180' : ''} cursor-pointer`} onClick={() => handleOpenResumeServices(service.index, service.resume_open)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {service.resume_open &&
                                                    <div className="w-full px-4 py-5 border-t border-v2-gray-border-tables">
                                                        <div className="w-full flex text-sm">
                                                            <div className="w-1/2 text-v2-gray-title-client">
                                                                Periodo
                                                            </div>
                                                            <div className="w-1/2 flex justify-end text-v2-orange-text-dates-quantity">
                                                                Cantidad: {service.quantity} periodos
                                                            </div>
                                                        </div>
                                                        <div className="w-full my-2">
                                                            {service.periodicity == 'yearly' ?
                                                                <div>
                                                                    {servicesDisplay.filter(date => date.index == service.index).map((service, i) => (
                                                                        <div key={i} className="w-full flex gap-2 flex-wrap max-w-[100%]">
                                                                            {service.dates_display.map((date, i) => (
                                                                                <div key={i} className="w-auto bg-accent-100 text-accent-base py-2 text-xs rounded flex items-center px-2">
                                                                                    <div className="w-full text-center min-w-[40px]">
                                                                                        {date.year}
                                                                                    </div>
                                                                                </div>
                                                                            ))
                                                                            }
                                                                        </div>
                                                                    ))
                                                                    }
                                                                </div>
                                                                :
                                                                <div>
                                                                    {servicesDisplay.filter(date => date.index == service.index).map((service, i) => (
                                                                        <div key={i} className="w-full flex gap-2 flex-wrap max-w-[100%]">
                                                                            {service.dates_display.map((date, i) => (
                                                                                <div key={i} className="w-auto bg-accent-100 text-accent-base py-2 text-xs rounded flex items-center px-2">
                                                                                    <div className="w-full text-center min-w-[40px]">
                                                                                        {date.tag}
                                                                                    </div>
                                                                                </div>
                                                                            ))
                                                                            }
                                                                        </div>
                                                                    ))
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className="w-full text-v2-gray-title-client text-sm mt-4">
                                                            Notas
                                                        </div>
                                                        <div className="w-full text-[15px] mt-2">
                                                            {service.notes == '' ?
                                                                <span className='opacity-50'>
                                                                    Ninguna nota añadida.
                                                                </span>
                                                                :
                                                                <span>
                                                                    {service.notes}
                                                                </span>
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        ))
                                        }
                                    </div>
                                    <ResumeAmounts subtotal={resumeAmounts.subtotalAmount} discount={resumeAmounts.discountAmount} totalAmount={resumeAmounts.totalAmount} amountServices={resumeAmounts.numberServices} inside={true} />
                                </div>
                            </div>
                            <div className="w-full border border-v2-gray-border-tables bg-white rounded-10 xs:mt-4 md:mt-4">
                                <HeadersDataTables icon={faCartShopping} text="Condiciones de pago" background={true} padding={0.5} bgColor="bg-v2-blue-icons" textColor="text-white" />
                                <div className="flex flex-row justify-start px-4 py-2 gap-4 w-full xs:py-2.5 md:py-3 text-xs xs:cols-span-2 md:cols-span-1 select-none">
                                    <div className='w-1/2'>
                                        <span className='flex w-auto text-v2-gray-title-client font-bold text-block truncate'>No. de MSI:</span>
                                        <Dropdown defaultTitle={installments} data={installmentsEnabled} dropChange={(value) => { setInstallments(parseInt(value)) }} />
                                    </div>
                                    <div className='w-1/2'>
                                        <span className='flex w-auto text-v2-gray-title-client font-bold text-block truncate'>No. de Parcialidades:</span>
                                        <Dropdown defaultTitle={partials} data={partialsEnabled} dropChange={(value) => { setPartials(parseInt(value)) }} />
                                    </div>
                                    <input
                                        type="text"
                                        readOnly={true}
                                        value={formatDate(getDate(), 'DD [de] MMMM YYYY')}
                                        className="hidden w-full appearance-none xs:pt-2 md:pt-5 opacity-60 select-none text-base text-v2-text-bar-steps outline-none items-center justify-center font-normal"
                                    />
                                </div>
                            </div>
                            {services.length > 1 &&
                                <div className='mt-4'>
                                    <PacksServices
                                        services={services}
                                        setServices={setServices}
                                        resumeAmounts={resumeAmounts}
                                        setResumeAmounts={setResumeAmounts}
                                        packActive={packActive}
                                        setPackActive={setPackActive}
                                        installmentsEnabled={installmentsEnabled}
                                        installments={installments}
                                        setInstallments={setInstallments}
                                        partialsEnabled={partialsEnabled}
                                        partials={partials}
                                        setPartials={setPartials} />
                                </div>
                            }
                            <div className="w-full flex justify-end">
                                <div className="w-full my-4">
                                    <Button heigth="h-12" bg="bg-v2-blue-text-login" onClick={handleSendData}>
                                        <FontAwesomeIcon icon={faCalculator} className="pr-2" /> Generar cotización
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {step == 4 &&
                    <div className="w-full xs:mt-24 md:mt-0">
                        <div className="xs:flex md:hidden w-full font-normal text-base text-v2-yellow-edit-info justify-center items-center my-8">
                            <Link to='/generated-quotes' onClick={() => history.push('/generated-quotes')} className='flex items-center'>
                                <FontAwesomeIcon icon={faSquareArrowUpRight} className="text-2xl mr-2" /><span className="underline cursor-pointer">Ver todas cotizaciones</span>
                            </Link>
                        </div>
                        <ThankYouPageReadOnly quoteDocument={quoteDocument} quote={quote} />
                    </div>
                }
            </div>
        </>
    )
}

NewQuote.propTypes = {
    user: PropTypes.object
}

export default NewQuote