import useFormatDate from "./useFormatDate";

const useGetStatusExpired = () => {
    const { formatDate } = useFormatDate();
    const getStatusExpired = date => {
        let status;
        const mexicoDate = (dateM) => (dateM.toLocaleString("en-US", {timeZone: "America/Mexico_City"}))
        const today = formatDate( mexicoDate(new Date()), 'YYYY-MM-DD');
        const dateUnix = formatDate( mexicoDate(new Date(date)), 'YYYY-MM-DD');
        const dateFuture = formatDate(mexicoDate(new Date().setDate(new Date().getDate() + 1)), 'YYYY-MM-DD');
        // console.log(formatDate( new Date().toLocaleString("en-US", {timeZone: "America/Mexico_City"})))
        if (dateUnix < today) {
            status = 'expired';
        } else if (today == dateUnix) {
            status = 'expires-soon'
        } else if (dateFuture <= dateUnix) {
            status = 'up-to-date'
        }
        return status
    }
    return { getStatusExpired }
}

export default useGetStatusExpired;