const useGetAge = () => {
    const getAge = date => {
        let today = new Date()
        let dateBirth = new Date(date)
        let age = today.getFullYear() - dateBirth.getFullYear()
        let monthsDifference = today.getMonth() - dateBirth.getMonth()
        if (
            monthsDifference < 0 ||
            (monthsDifference === 0 && today.getDate() < dateBirth.getDate())
        ) {
            age--
        }
        return age
    }
    return { getAge }
}

export default useGetAge