import React, { useState, useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, Link } from 'react-router-dom';
import { faArrowLeft, faFileInvoiceDollar, faCartShopping, faCircleXmark, faCircleCheck, faCreditCard, faMoneyBill1Wave, faMoneyBillTransfer, faCalendar, faTriangleExclamation, faCopy, faCircleQuestion, faMagnifyingGlass, faFaceGrinBeamSweat } from '@fortawesome/free-solid-svg-icons';
import { faPaypal } from '@fortawesome/free-brands-svg-icons';
import { AccountContext } from '../../context/AccountContext';
import Nav from '../../hooks/Nav';
import HeadersDataTables from '../../hooks/HeadersDataTables';
import useFormatterCurrency from '../../hooks/useFormatterCurrency';
import { getServices } from '../../apiClient/operations/services';
import Button from '../../hooks/Button';
import DatePicker from 'react-modern-calendar-datepicker';
import useConvertDatePicker from '../../hooks/useConvertDatePicker';
import useReverseDatePicker from '../../hooks/useReverseDatepicker';
import useFormatDate from '../../hooks/useFormatDate';
import useGetDate from '../../hooks/useGetDate';
import SecondaryLoader from '../../hooks/SecondaryLoader';
import Tooltip from '../../hooks/Tooltip';
import moment from 'moment';
import { createPaymentLink } from '../../apiClient/operations/paymentLinksOperations';
import { useForm } from 'react-hook-form';
import { searchAccountByEmail } from '../../apiClient/operations/accountsOperations';
import { AlertContext } from '../../context/AlertContext';
import Select from '../../hooks/Select';
import { AuthContext } from '../../cognito/AuthProvider';
import { capitalize } from '../../tools/stringFormatter';
import BreadCrumb from '../../tailwindUI/BreadCrumb';
import usePaymentRules from '../../hooks/usePaymentRules';

const roadMap = [
    { name: 'Links de cobro', url: '/payment-links', current: false },
    { name: 'Generando link de cobro', url: '/payment-links/create', current: true },
];

const periodicity = {
    "unique": " pago único",
    "yearly": " anual",
    "monthly": " mensual",
    "bimonthly": " bimestral"
};

const paymentMethods = [
    {
        id: 1,
        value: "card",
        name: "Tarjeta de crédito o débito",
        shortName: "Tarjeta",
        icon: faCreditCard,
        imageUrl: "https://cdn.fixat.mx/assets/img/no-webp/aceptamos-pago-con-tarjeta.png"
    },
    {
        id: 2,
        value: "paypal",
        name: "Pago con Paypal",
        shortName: "Paypal",
        icon: faPaypal,
        imageUrl: "https://cdn.fixat.mx/assets/img/no-webp/paypal.png"
    },
    {
        id: 3,
        value: "cash",
        name: "Pago en fectivo",
        shortName: "Efectivo",
        icon: faMoneyBill1Wave,
        imageUrl: "https://cdn.fixat.mx/assets/img/no-webp/oxxo_short.svg"
    },
    {
        id: 4,
        value: "transfer",
        name: "Transferencia bancaria",
        shortName: "Transferencia",
        icon: faMoneyBillTransfer,
        imageUrl: "https://cdn.fixat.mx/assets/img/no-webp/spei.png"
    }
];

const paymentInterval = [
    {
        id: 1,
        name: "Mensual",
        interval: "month",
        frequency: 1
    },
    {
        id: 2,
        name: "Semanal",
        interval: "week",
        frequency: 1
    },
    {
        id: 3,
        name: "Quincenal",
        interval: "half_month",
        frequency: 1
    },
    {
        id: 4,
        name: "Bimestral",
        interval: "month",
        frequency: 2
    },
    {
        id: 5,
        name: "Trimestral",
        interval: "month",
        frequency: 3
    },
    {
        id: 6,
        name: "Semestral",
        interval: "month",
        frequency: 6
    },
    {
        id: 7,
        name: "Anual",
        interval: "year",
        frequency: 1
    }
];

const paymentTypes = [
    {
        id: 1,
        name: "Cobro único",
        allowedPaymentMethods: ["card", "paypal", "cash", "transfer"]
    },
    {
        id: 2,
        name: "Cobro recurrente",
        allowedPaymentMethods: ["card"]
    }
]

function CreatePaymentLink() {

    const { user } = React.useContext(AuthContext);

    let history = useHistory();
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors }
    } = useForm();
    const { formatterCurrency } = useFormatterCurrency();
    const { convertDatePicker } = useConvertDatePicker();
    const { reverseDatePicker } = useReverseDatePicker();
    const { getDate } = useGetDate();
    const { formatDate } = useFormatDate();
    const { installments: getInstalmentsEnabled } = usePaymentRules();
    const { setAlert } = useContext(AlertContext);
    const { account, setAccount } = useContext(AccountContext);
    const [concept, setConcept] = useState("");
    const [services, setServices] = useState([]);
    const [selectedServices, setSelectedServices] = useState([]);
    const [selectedPaymentMethods, setSelectedPaymentMethods] = useState([paymentMethods[0]]);
    const [msi, setMsi] = useState([]);
    const [selectedMsi, setSelectedMsi] = useState(getInstalmentsEnabled(0)[0]);
    const [serviceDate, setServiceDate] = useState(convertDatePicker(formatDate(moment().add(7, 'days'), 'YYYY-MM-DD')));
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [paymentLink, setPaymentLink] = useState(null);
    const [linkCopied, setLinkCopied] = useState(false);
    const [shortLinkCopied, setShortLinkCopied] = useState(false);
    const [selectedPaymentType, setSelectedPaymentType] = useState(paymentTypes[0]);
    const [selectedPaymentInterval, setSelectedPaymentInterval] = useState(paymentInterval[0]);
    const [subscriptionDate, setSubscriptionDate] = useState(convertDatePicker(formatDate(moment().add(1, 'M').startOf('month'), 'YYYY-MM-DD')));
    const [numberOfPayments, setNumberOfPayments] = useState(0);
    const [showTooltip, setShowTooltip] = useState(false);
    const [applyCharge, setApplyCharge] = useState(true);
    const [applyChargeDisabled, setApplyChargeDisabled] = useState(false);
    const [totalAmount, setTotalAmount] = useState(0);

    useEffect(async () => {
        if (account) {
            try {
                const response = await getServices();
                setServices(
                    response.data.filter(item => item.legal_type == account?.legal_type)
                        .map(service => {
                            service.price = service.price / 100
                            return service;
                        }))
            } catch (e) {
                setAlert({ active: true, type: 'error', message: 'Error al obtener servicios.' })
            }
        }
    }, [account]);



    const searchAccount = async (e) => {
        setLoading(true);
        try {
            const { data: [account] } = await searchAccountByEmail(e.email);
            if(account.length === 0){
                setError('email', { message: 'No se encontró ninguna cuenta asociada a este email' });
            }
            setPaymentLink(null);
            setAccount(account);
            setLoading(false);
        } catch {
            setLoading(false);
            setError('email', { message: 'No se encontró ninguna cuenta asociada a este email' });
        }
    }

    const selectService = (e) => {
        e.preventDefault();
        if (e.target.value == 0) return;
        const selectedService = services.find(service => service.sku == e.target.value)
        setSelectedServices([
            ...selectedServices,
            {
                "id": selectedService.id,
                "sku": selectedService.sku,
                "name": selectedService.name,
                "price": selectedService.price,
                "currency": selectedService.currency,
                "legal_type": selectedService.legal_type,
                "category": selectedService.category,
                "periodicity": selectedService.periodicity,
                "qty": 1
            }
        ]);
        e.target.value = 0;
    }

    const removeService = (indexToRemove) => {
        setSelectedServices(selectedServices.filter((_, i) => i != indexToRemove));
    }

    const changeServicePrice = (index, e) => {
        const value = e.target.value.replace(/[^0-9.]/g, "");
        setSelectedServices(
            selectedServices.map((selectedService, i) => {
                if (i == index) {
                    selectedService.price = value;
                }
                return selectedService
            })
        );
    }

    const changeServiceQty = (index, e) => {
        const value = e.target.value.replace(/[^0-9.]/g, "");
        setSelectedServices(
            selectedServices.map((selectedService, i) => {
                if (i == index) {
                    selectedService.qty = value;
                }
                return selectedService
            })
        );
    }

    const selectPaymentMethod = (paymentMethod) => {
        const selectedPaymentMethod = selectedPaymentMethods.find(method => method.id == paymentMethod.id);
        if (selectedPaymentMethod) {
            setSelectedPaymentMethods(selectedPaymentMethods.filter((method => method.id != paymentMethod.id)));
        } else {
            setSelectedPaymentMethods([
                ...selectedPaymentMethods,
                paymentMethod
            ]);
        }
    }

    useEffect(() => {
        const hour = { hour: 23, minute: 59, second: 59, millisecond: 59 }
        if (moment(reverseDatePicker(subscriptionDate)).set(hour).diff(moment().set(hour), 'days') > 0) {
            setApplyChargeDisabled(false);
            setApplyCharge(true);
        } else {
            setApplyChargeDisabled(true);
            setApplyCharge(false);
        }
    }, [subscriptionDate]);

    const createLink = async () => {
        setLoading(true);
        const request = {
            "created_by": {
                "name": user.name,
                "email": user.email
            },
            "account_id": account?.id,
            "name": concept,
            "expires_at": moment(reverseDatePicker(serviceDate)).set({ hour: 23, minute: 59, second: 59, millisecond: 59 }).valueOf(),
            "allowed_payment_methods": selectedPaymentMethods.map(method => method.value),
            "monthly_installments_enabled": selectedMsi.id != 1 && selectedPaymentType.id != 2,
            ...(selectedMsi.id != 1 && selectedPaymentType.id != 2 && {
                "monthly_installments_options": selectedMsi.values,
            }),
            "order_template": {
                "line_items": selectedServices.map(service => {
                    return {
                        "id": service.id,
                        "sku": service.sku,
                        "name": service.name,
                        "quantity": parseInt(service.qty),
                        "price": {
                            "unit_price": parseFloat(service.price) * 100,
                            "total_amount": (parseFloat(service.price) * parseInt(service.qty) * 100),
                            "discount": 0,
                            "discount_type": "direct",
                            "discount_amount": 0
                        },
                    }
                }),
                "currency": "MXN"
            },
            "recurrent": selectedPaymentType.id == 2,
            ...(selectedPaymentType.id == 2 && {
                "subscription_info": {
                    "name": concept,
                    "start_date": moment(reverseDatePicker(subscriptionDate)).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).valueOf(),
                    "interval": selectedPaymentInterval?.interval,
                    "frequency": selectedPaymentInterval?.frequency,
                    "expiry_count": parseInt(numberOfPayments),
                    "apply_charge": applyCharge,
                    "amount": totalAmount * 100,
                    "status_after_retry": "unpaid",
                    "currency": "mxn"
                }
            })
        }
        try {
            const response = await createPaymentLink(request);
            setLoading(false);
            setPaymentLink(response);
        } catch (e) {
            setLoading(false);
            setAlert({ active: true, type: 'error', message: e.message })
        }
    }

    const copyLinkToClipboard = async (text) => {
        if ('clipboard' in navigator) {
            return navigator.clipboard.writeText(text);
        }
    }

    const handleCopyLink = (url) => {
        copyLinkToClipboard(url).then(() => {
            setLinkCopied(true);
            setTimeout(() => {
                setLinkCopied(false);
            }, 5000);
        })
    }

    const handleCopyShortLink = (url) => {
        copyLinkToClipboard(url).then(() => {
            setShortLinkCopied(true);
            setTimeout(() => {
                setShortLinkCopied(false);
            }, 5000);
        })
    }

    useEffect(() => {
        setDisabled(
            account == null ||
            concept == null || concept === "" ||
            selectedServices.length == 0 ||
            selectedPaymentMethods.length == 0 ||
            selectedServices.find(service =>
                service.price == null || service.price === "" || service.price < 1 ||
                service.qty == null || service.qty === "" || service.qty < 1
            ) ||
            selectedPaymentType.id == 2 && (
                numberOfPayments == null || numberOfPayments === ""
            ) ||
            isNaN(totalAmount)
        )
    }, [account, concept, selectedServices, selectedPaymentMethods, numberOfPayments, selectedPaymentType, totalAmount]);

    useEffect(() => {
        setTotalAmount(selectedServices.reduce((accumulator, currentService) => accumulator + (currentService.qty * currentService.price), 0));
    }, [selectedServices]);

    useEffect(() => {
        setMsi(getInstalmentsEnabled(totalAmount));
    }, [totalAmount]);

    return (
        <>
            {loading && <SecondaryLoader />}
            <div className="w-full">
                <Nav user={user}>
                    <div className="w-full">
                        <Link to='/payment-links'>
                            <span className="text-2xl text-white font-bold flex items-center cursor-pointer">
                                <span className="text-white opacity-50 text-4xl mr-2"><FontAwesomeIcon icon={faArrowLeft} /></span>
                                <span className="text-lg pr-1">
                                    Crear link de cobro
                                </span>
                            </span>
                        </Link>
                    </div>
                </Nav>
            </div>
            <div className="w-full px-4">
                <div className="w-full mt-20 md:mt-4">
                    <BreadCrumb roadMap={roadMap} />
                </div>
                <div className="w-full hidden md:flex text-3xl text-v2-input-text font-bold mt-5 justify-between">
                    <a >
                        <FontAwesomeIcon className='cursor-pointer' icon={faArrowLeft} onClick={() => history.goBack()} />
                    </a>
                    <span className="pl-4 w-full">
                        Crear link de cobro
                    </span>
                </div>
                {!paymentLink && (
                    <div className='w-full mt-3'>
                        <form onSubmit={handleSubmit(searchAccount)}>
                            <div className='w-full text-v2-gray-title-client text-xs'>
                                Busca el contacto
                            </div>
                            <div className='w-full flex xs:gap-2 md:gap-4 items-center'>
                                <div className='xs:w-10/12 md:w-9/12'>
                                    <input
                                        type='text'
                                        className={`w-full font-normal delete_outline rounded-md px-2 h-[46px] outline-transparent py-2 ${errors.email ? 'border border-v2-red-error-services-selected focus:border focus:border-v2-red-error-services-selected' : 'border border-v2-gray-border-tables'} text-v2-input-text`}
                                        placeholder="Ingresa el correo electrónico"
                                        name="email"
                                        id="email"
                                        inputMode='email'
                                        {...register("email", {
                                            required: true,
                                            pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            minLength: 11
                                        })}
                                    />
                                </div>
                                <div className='xs:w-2/12 md:w-3/12'>
                                    <Button type="submit" heigth='h-11' bg='bg-v2-blue-text-login'>
                                        <span className='xs:hidden md:block'>Buscar</span>
                                        <span className='xs:block md:hidden text-2xl leading-3'><FontAwesomeIcon icon={faMagnifyingGlass} /></span>
                                    </Button>
                                </div>
                            </div>
                            <span className="block pt-0.5 text-v2-red-error-services-selected h-7">{errors.email && (errors?.email?.message || "Ingresa un email válido")}</span>
                        </form>
                    </div>
                )}
                {!account &&
                    <div className='w-full h-[60vh] flex justify-center items-center px-1'>
                        <div className='w-full text-center text-v2-input-text opacity-50'>
                            <span className='text-center text-6xl'>
                                <FontAwesomeIcon icon={faFaceGrinBeamSweat} />
                            </span>
                            <span className='w-full font-bold text-2.5xl block mt-4'>
                                Primero agrega un contacto
                                <span className='block font-normal text-xl mt-4'>
                                    Para crear un link de cobro es necesario <span className='xs:inline-block md:block'>agregar un contacto.</span>
                                </span>
                            </span>
                        </div>
                    </div>
                }
                {account && !paymentLink && (
                    <>
                        <div className="w-full mt-1 flex xs:flex-col lg:flex-row gap-4">
                            <div className='xs:w-full lg:w-3/5 transition-all ease-out duration-400'>
                                <div className="w-full border border-v2-gray-border-tables bg-white rounded-10 pb-4 lg:mb-4">
                                    <HeadersDataTables icon={faFileInvoiceDollar} text="Datos del cobro" background={true} padding={0} bgColor="bg-v2-blue-text-login" textColor="text-white" />
                                    <div className='w-full px-4 pt-4 items-center text-xs font-bold text-v2-gray-title-client'>
                                        Concepto:
                                        <input
                                            type="text"
                                            className="font-normal border-v2-gray-border-tables text-v2-text-bar-steps focus:ring-v2-blue-text-login focus:border-v2-blue-text-login transition-all block w-full px-4 my-1 rounded-md"
                                            placeholder="Agrega un concepto"
                                            onBlur={(e) => setConcept(e.target.value)}
                                            defaultValue={concept}
                                            autoComplete='off' />
                                    </div>
                                    <div className='w-full px-4 pt-4 items-center text-xs font-bold text-v2-gray-title-client'>
                                        Servicios:
                                        <Select onChange={selectService}>
                                            <option value={0} defaultValue>Selecciona un servicio</option>
                                            {services.sort((a, b) => a.name.localeCompare(b.name)).map((service, i) => (
                                                <option key={i} value={service.sku} >{service.name} - {formatterCurrency(service.price)}</option>
                                            ))}
                                        </Select>
                                        <div>
                                            {selectedServices.map((service, i) => {
                                                const priceError = service.price == null || service.price === "" || service.price < 1 || isNaN(service.price);
                                                const qtyError = service.qty == null || service.qty === "" || service.qty < 1;
                                                return (
                                                    <div key={i} className={`${priceError || qtyError ? 'border-v2-red-error-services-selected' : 'border-v2-border-time'} border transition-all ease-in-out w-full relative w-full bg-white rounded-5 mt-3 p-2`}>
                                                        <div className='text-2xl text-v2-input-text absolute -right-2 -top-3'>
                                                            <FontAwesomeIcon icon={faCircleXmark} className='cursor-pointer bg-white' onClick={() => removeService(i)} />
                                                        </div>
                                                        <div className='w-full flex flex-row'>
                                                            <div className='w-16 h-16 hidden md:flex mr-2 rounded-5 border border-v2-border-time text-3xl text-gray items-center justify-center'>
                                                                <FontAwesomeIcon icon={faFileInvoiceDollar} />
                                                            </div>
                                                            <div className='flex flex-col justify-between'>
                                                                <span className='text-base font-normal text-v2-input-text overflow-x-auto'>{service.name}</span>
                                                                <div className='xs:block md:flex items-center'>
                                                                    <div className='flex items-center'>
                                                                        <div className="w-28 text-base text-v2-text-bar-steps outline-none flex items-center justify-center relative">
                                                                            <div className='absolute flex items-center top-0 left-0 bottom-0 text-sm mx-2'>
                                                                                <span className="font-normal block text-v2-gray-title-client">$</span>
                                                                            </div>
                                                                            <input
                                                                                type="text"
                                                                                inputMode='number'
                                                                                value={service.price}
                                                                                onChange={(e) => changeServicePrice(i, e)}
                                                                                className="font-normal border-v2-gray-border-tables text-v2-text-bar-steps focus:ring-v2-blue-text-login focus:border-v2-blue-text-login transition-all block w-full pl-6 pr-4 py-1 rounded-md"
                                                                            />
                                                                        </div>
                                                                        <span className='text-sm font-normal text-v2-gray-title-client ml-2 min-w-[6rem]'>{periodicity[service.periodicity]}</span>
                                                                    </div>
                                                                    <div className='w-full flex flex-row items-center xs:mt-2 md:mt-0'>
                                                                        <div className="w-14 text-base text-v2-text-bar-steps outline-none flex items-center justify-center">
                                                                            <input
                                                                                type="text"
                                                                                inputMode='number'
                                                                                className="font-normal border-v2-gray-border-tables text-v2-text-bar-steps focus:ring-v2-blue-text-login focus:border-v2-blue-text-login transition-all block w-full px-4 py-1 rounded-md"
                                                                                value={service.qty}
                                                                                onChange={(e) => changeServiceQty(i, e)}
                                                                                autoComplete='off'
                                                                                maxLength={2} />
                                                                        </div>
                                                                        <span className='text-sm font-normal block text-v2-input-text ml-2'>Cantidad</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {(priceError || qtyError) && (
                                                            <div className='w-full text-sm text-v2-red-error-services-selected flex flex-row items-center mt-2 font-normal'>
                                                                <FontAwesomeIcon className='' icon={faTriangleExclamation} />
                                                                <span className='ml-2'>{priceError ? 'Ingresa correctamente el precio' : 'La cantidad debe ser entre 1 y 99'}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div className='w-full px-4 pt-4 items-center text-xs font-bold text-v2-gray-title-client'>
                                        Tipo de cobro:
                                        <div className='py-1 flex justify-between gap-4 mt-1 text-base'>
                                            {paymentTypes.map((type, i) => (
                                                <div key={i}
                                                    className={`${type.id == selectedPaymentType.id ? 'shadow-lg border-primary bg-primary/10' : 'border-v2-border-time'} select-none transition-all ease-in-out duration-200 w-full relative border bg-white rounded-5 px-2 py-3 cursor-pointer font-normal text-center text-v2-input-text flex flex-col items-center justify-center`}
                                                    onClick={() => {
                                                        setSelectedPaymentType(type);
                                                        setSelectedPaymentMethods(selectedPaymentMethods.filter(method => type.allowedPaymentMethods.includes(method.value)))
                                                        if (type.id == 2) {
                                                            setSelectedMsi(msi.find(msi => msi.id == 1));
                                                        }
                                                    }}>
                                                    <div className={`${type.id == selectedPaymentType.id ? 'visible' : 'invisible'}  w-6 h-6 rounded-full bg-white text-2xl text-primary absolute -right-2 -top-3`}>
                                                        <FontAwesomeIcon icon={faCircleCheck} />
                                                    </div>
                                                    <span>{type.name}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    {selectedPaymentType.id == 2 && (
                                        <>
                                            <div className='w-full px-4 items-center text-xs font-bold text-v2-gray-title-client block xl:flex gap-4'>
                                                <div className='flex w-full gap-4 pt-4'>
                                                    <div className='w-full'>
                                                        Inicio de cobro:
                                                        <div className='w-full relative my-1'>
                                                            <DatePicker
                                                                value={subscriptionDate}
                                                                onChange={setSubscriptionDate}
                                                                locale='en'
                                                                wrapperClassName='block z-10 responsive-calendar'
                                                                inputClassName='w-full z-10 appearance-none cursor-pointer text-left py-2 px-1.5 font-normal border border-v2-gray-border-tables rounded-5 text-base text-v2-input-text outline-none responsive-calendar'
                                                                calendarClassName='block font-normal text-v2-input-text'
                                                                calendarPopperPosition='top'
                                                                colorPrimary='#2169AC'
                                                                minimumDate={convertDatePicker(formatDate(getDate(), 'YYYY-MM-DD'))}
                                                            />
                                                            <FontAwesomeIcon icon={faCalendar} className='absolute w-5 z-10 h-5 right-2 top-[50%] -translate-y-1/2 text-primary' />
                                                        </div>
                                                    </div>
                                                    <div className='w-full'>
                                                        Número de cobros:
                                                        <div className="relative my-1 w-full text-base text-v2-text-bar-steps outline-none flex items-center justify-center">
                                                            <input
                                                                type="text"
                                                                inputMode='number'
                                                                className={`${numberOfPayments == null || numberOfPayments === "" ? 'border-v2-red-error-services-selected focus:ring-v2-red-error-services-selected focus:border-v2-red-error-services-selected' : 'border-v2-gray-border-tables focus:ring-v2-blue-text-login focus:border-v2-blue-text-login'} font-normal  text-v2-text-bar-steps transition-all block w-full pl-4 pr-6 rounded-md`}
                                                                value={numberOfPayments}
                                                                onChange={(e) => setNumberOfPayments(e.target.value.replace(/\D/g, ""))}
                                                                autoComplete='off'
                                                                maxLength={2} />
                                                            <div className='absolute flex items-center top-0 right-0 bottom-0 text-sm mx-2'>
                                                                <FontAwesomeIcon
                                                                    onMouseEnter={() => setShowTooltip(true)}
                                                                    onMouseLeave={() => setShowTooltip(false)}
                                                                    onClick={() => setShowTooltip(true)}
                                                                    icon={faCircleQuestion}
                                                                    className='absolute w-5 z-10 h-5 right-2 top-[50%] -translate-y-1/2 text-v2-input-text cursor-pointer' />
                                                                <div className="absolute w-28 bottom-12 xs:-right-2 lg:-right-7">
                                                                    <Tooltip show={showTooltip} onClose={() => setShowTooltip(false)} arrowPosition={{ "xs": "right", "lg": "middle" }} message="Número total de cobros que se realizarán. 0 es indefinido" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='w-full pt-4'>
                                                    Intervalo de cobro:
                                                    <div className='md:flex gap-2'>
                                                        <Select
                                                            onChange={(e) => setSelectedPaymentInterval(paymentInterval.find(interval => interval.name == e.target.value))}
                                                            value={selectedPaymentInterval.name} >
                                                            {paymentInterval.map((interval, i) => (
                                                                <option key={i} value={interval.name}>{interval.name}</option>
                                                            ))}
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='w-full px-4 items-center text-sm font-normal text-v2-input-text'>
                                                <div className='flex items-center min-w-[7.5rem]'>
                                                    <label className={`${applyChargeDisabled ? 'opacity-60' : 'opacity-100 cursor-pointer'} flex items-start select-none mt-1`}>
                                                        <input disabled={applyChargeDisabled} type="checkbox" checked={applyCharge} onChange={() => setApplyCharge(!applyCharge)} className='mt-1 mr-1 accent-primary' />
                                                        Realizar cobro al suscribir
                                                    </label>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    <div className='w-full px-4 pt-4 items-center text-xs font-bold text-v2-gray-title-client'>
                                        Métodos de pago permitidos:
                                        <div className='py-1 flex flex-col md:flex-row justify-between gap-4 mt-1'>
                                            {paymentMethods.map((paymentMethod, i) => {
                                                const enabled = selectedPaymentType.allowedPaymentMethods.includes(paymentMethod.value);
                                                const selected = enabled && selectedPaymentMethods.find(method => method.id == paymentMethod.id) != null;
                                                const noneSelected = enabled && selectedPaymentMethods.length == 0;
                                                let borderStyle = selected ? 'shadow-lg border-primary bg-primary/10' : 'border-v2-border-time';
                                                if (noneSelected) borderStyle = 'border-v2-red-error-services-selected';
                                                return (
                                                    <div key={i}
                                                        onClick={() => {
                                                            if (enabled) selectPaymentMethod(paymentMethod)
                                                        }}
                                                        className={`${enabled ? 'opacity-100 cursor-pointer' : 'opacity-50'} ${borderStyle} select-none transition-all ease-in-out duration-200 w-full relative border bg-white rounded-5 px-2 py-3 text-sm font-normal text-center text-v2-input-text flex flex-col items-center justify-center`}>
                                                        <div className={`${selected ? 'visible' : 'invisible'} w-6 h-6 rounded-full text-2xl text-primary absolute -right-2 -top-3 bg-white`}>
                                                            <div></div>
                                                            <FontAwesomeIcon icon={faCircleCheck} />
                                                        </div>
                                                        {(typeof paymentMethod.icon === 'string') ? (
                                                            <img src={paymentMethod.icon} className='h-8 object-contain' />
                                                        ) : (
                                                            <FontAwesomeIcon className='text-4xl' icon={paymentMethod.icon} />
                                                        )}
                                                        <span className='mt-1'>{paymentMethod.shortName}</span>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        {(selectedPaymentMethods.length == 0) && (
                                            <div className='w-full text-sm text-v2-red-error-services-selected flex flex-row items-center mt-1 font-normal'>
                                                <FontAwesomeIcon className='' icon={faTriangleExclamation} />
                                                <span className='ml-2'>Selecciona al menos un método de pago</span>
                                            </div>
                                        )}
                                    </div>
                                    <div className='w-full px-4 items-center text-xs font-bold text-v2-gray-title-client block md:flex gap-4'>
                                        <div className='w-full pt-4'>
                                            Meses sin intereses:
                                            <Select
                                                onChange={(e) => setSelectedMsi(msi?.find(msi => msi.id == e.target.value))}
                                                value={selectedMsi?.id}
                                                disabled={selectedPaymentType.id == 2 || totalAmount < 1500}>
                                                {msi.map((msi, i) => (
                                                    <option key={i} value={msi.id}>{msi.title}</option>
                                                ))}
                                            </Select>
                                        </div>
                                        <div className='w-full pt-4'>
                                            Fecha de caducidad:
                                            <div className='w-full relative my-1'>
                                                <DatePicker
                                                    value={serviceDate}
                                                    onChange={setServiceDate}
                                                    locale='en'
                                                    wrapperClassName='block z-10 responsive-calendar'
                                                    inputClassName='w-full z-10 appearance-none cursor-pointer text-left py-2 px-1.5 font-normal border border-v2-gray-border-tables rounded-5 text-base text-v2-input-text outline-none responsive-calendar'
                                                    calendarClassName='block font-normal text-v2-input-text'
                                                    calendarPopperPosition='top'
                                                    colorPrimary='#2169AC'
                                                    minimumDate={convertDatePicker(formatDate(getDate(), 'YYYY-MM-DD'))}
                                                />
                                                <FontAwesomeIcon icon={faCalendar} className='absolute w-5 z-10 h-5 right-2 top-[50%] -translate-y-1/2 text-primary' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='xs:w-full lg:w-2/4'>
                                <div className="w-full border border-v2-gray-border-tables bg-white rounded-10 pb-4 mb-4">
                                    <HeadersDataTables icon={faCartShopping} text="Resumen del cobro" background={true} padding={0} bgColor="bg-button-orange" textColor="text-white" />
                                    {account && (
                                        <>
                                            <div className='w-full px-4 pt-3 items-center text-xs font-bold text-v2-gray-title-client'>
                                                Cliente:
                                                <span className='text-base font-normal block text-v2-input-text'>{account?.first_name} {account?.last_name}</span>
                                            </div>
                                            <div className="w-full px-4 xs:block lg:flex items-center text-xs font-bold text-v2-gray-title-client gap-2">
                                                <div className='xs:w-full lg:w-3/5 xl:w-1/2 text-xs font-bold text-v2-gray-title-client pt-3'>
                                                    Correo:
                                                    <span className='text-base font-normal block text-v2-input-text overflow-x-auto'>{account?.email}</span>
                                                </div>
                                                <div className='xs:w-full lg:w-2/5 xl:w-1/2 text-xs font-bold text-v2-gray-title-client pt-3'>
                                                    Móvil:
                                                    <span className='text-base font-normal block text-v2-input-text'>{account?.phone_number}</span>
                                                </div>
                                            </div>
                                            <div className='w-full px-4 mt-3'>
                                                <div className='border-t border-dashed border-v2-gray-border-tables' />
                                            </div>
                                        </>
                                    )}
                                    {concept && concept != "" && (
                                        <div className='w-full px-4 pt-4 items-center text-xs font-bold text-v2-gray-title-client'>
                                            Concepto:
                                            <div className='flex justify-between text-v2-input-text'>
                                                <span className='text-base leading-snug font-normal block'>{concept}</span>
                                            </div>
                                        </div>
                                    )}
                                    {selectedServices.length > 0 && (
                                        <>
                                            <div className='w-full px-4 pt-5 items-center text-xs font-bold text-v2-gray-title-client'>
                                                Servicios seleccionados:
                                                {selectedServices.map((service, i) => (
                                                    <div key={i} className={i == 0 ? 'pt-1' : 'pt-2'}>
                                                        <div className='flex justify-between text-v2-input-text'>
                                                            <span className='text-base leading-snug font-normal block'>{service.name}</span>
                                                            <span className='text-base leading-snug font-normal block ml-4'>{isNaN(service.price) ? formatterCurrency(0) : formatterCurrency((service.price * service.qty))}</span>
                                                        </div>
                                                        <div className='flex justify-between -mt-1'>
                                                            <span className='text-sm font-normal block text-v2-gray-title-client'>Cantidad: {service.qty}</span>
                                                            {service.qty > 1 && (
                                                                <span className='text-sm font-normal block text-v2-gray-title-client ml-4'>{isNaN(service.price) ? formatterCurrency(0) : formatterCurrency(service.price)} c/u</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className='w-full px-4 mt-3'>
                                                <div className='border-t border-dashed border-v2-gray-border-tables' />
                                            </div>
                                        </>
                                    )}
                                    {selectedPaymentMethods.length > 0 && (
                                        <>
                                            <div className='w-full px-4 pt-4 items-center text-xs font-bold text-v2-gray-title-client'>
                                                Métodos de pago:
                                                {selectedPaymentMethods.sort((a, b) => a.id - b.id).map((paymentMethod, i) => (
                                                    <div key={i} className="pt-2 w-full flex flex-row items-center justify-between text-v2-input-text">
                                                        <div className='flex'>
                                                            <div className='bg-v2-input-text w-1 h-1 rounded-full mt-2 mr-1' />
                                                            <div>
                                                                <span className='text-base font-normal block leading-snug'>{paymentMethod.name}</span>
                                                                {selectedMsi.id && selectedMsi.id != 1 && paymentMethod.id == 1 && (
                                                                    <span className='text-sm font-normal block text-v2-gray-title-client -mt-1'>{selectedMsi.name}</span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <img src={paymentMethod.imageUrl} alt={paymentMethod.name} className="h-5" />
                                                    </div>
                                                ))}
                                            </div>
                                        </>
                                    )}
                                    <div className='w-full px-4 pt-4 items-center text-xs font-bold text-v2-gray-title-client'>
                                        Fecha de caducidad:
                                        <div className='flex justify-between text-v2-input-text'>
                                            <span className='text-base leading-snug font-normal block'>{capitalize(formatDate(reverseDatePicker(serviceDate), 'dddd, DD MMMM YYYY'))}</span>
                                        </div>
                                    </div>
                                    {selectedPaymentType.id == 2 && (
                                        <div className='w-full px-4 pt-4 items-center text-xs font-bold text-v2-gray-title-client'>
                                            Primer cobro:
                                            <div className='flex justify-between text-v2-input-text'>
                                                <span className='text-base leading-snug font-normal block'>{capitalize(formatDate(reverseDatePicker(subscriptionDate), 'dddd, DD MMMM YYYY'))}</span>
                                            </div>
                                        </div>
                                    )}
                                    <div className='w-full px-4 mt-4'>
                                        <div className='w-full text-xl p-4 font-bold border border-dashed border-v2-gray-border-tables rounded-b-md items-center flex flex-col justify-center'>
                                            <span className='text-v2-input-text'>Total:</span>
                                            <span className='block text-button-orange'>
                                                {formatterCurrency(isNaN(totalAmount) ? 0 : totalAmount)}
                                            </span>
                                            {selectedPaymentType.id == 2 && (
                                                <span className='text-v2-input-text text-sm font-normal'>Facturación {selectedPaymentInterval.name.toLowerCase()}</span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='w-full mt-3 mb-4'>
                                    <Button
                                        disabled={disabled}
                                        onClick={createLink}
                                        heigth='h-11'>
                                        Crear link
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {paymentLink &&
                    <div className="w-full border border-v2-gray-border-tables rounded-10 pt-6 pb-4 mt-3 bg-white flex xs:flex-col md:flex-row">
                        <div className="xs:w-full md:w-7/12 px-4">
                            <div className="w-full xs:text-center md:text-left text-v2-border-alert-success text-1.5xl font-bold leading-8 tracking-wide">
                                ¡Felicidades!
                                <span className="block">
                                    Terminaste el proceso.
                                </span>
                            </div>
                            <div className="w-full xs:text-center md:text-left text-base text-v2-input-text opacity-60 leading-4 pt-2">
                                Ahora podrás enviarle este link de cobro al cliente
                            </div>
                            <div className='xs:w-full lg:w-4/6 mt-4 border border-dashed border-v2-gray-border-tables rounded-10 p-4'>
                                <div className='text-v2-input-text text-base font-semibold'>
                                    {concept}
                                    <span className='pl-1 text-button-orange block text-2xl font-bold'>
                                        {formatterCurrency(totalAmount)}
                                    </span>
                                </div>
                                <div className='text-v2-input-text opacity-60'>
                                    Vence el
                                    <span className='pl-1 font-semibold'>
                                        {formatDate(reverseDatePicker(serviceDate), 'dddd, DD MMMM YYYY')}
                                    </span>
                                </div>
                                <div className='w-full mt-3'>
                                    <div className='border-t border-dashed border-v2-gray-border-tables' />
                                </div>
                                <div className='text-v2-input-text opacity-60 mt-3'>
                                    Cliente:
                                    <span className='pl-1 font-semibold'>
                                        {account?.first_name} {account?.last_name}
                                    </span>
                                </div>
                                <div className='text-v2-input-text opacity-60 pt-1'>
                                    Correo:
                                    <span className='pl-1 font-semibold'>
                                        {account?.email}
                                    </span>
                                </div>
                                <div className='text-v2-input-text opacity-60 pt-1'>
                                    Móvil:
                                    <span className='pl-1 font-semibold'>
                                        {account?.phone_number}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="xs:w-full md:w-5/12 px-4">
                            <div className="w-full flex flex-col justify-center items-center">
                                <div className="w-80 xs:hidden md:block">
                                    <img
                                        src="https://cdn-fixat-mx.s3.amazonaws.com/intranet/fixi_thankyou.png"
                                        alt="Fixi - Thank you"
                                    />
                                </div>
                                <div className='w-full mt-4'>
                                    <div className="w-full border border-dashed border-v2-gray-border-dashed-amount flex justify-between items-center p-2">
                                        <div className="text-xs text-v2-gray-title-client font-bold w-10/12 truncate">
                                            Link corto:
                                            <input className="block text-base font-normal text-v2-input-text font-normal outline-none w-full" value={paymentLink.short_url} readOnly />
                                        </div>
                                        <div className="cursor-pointer h-10 w-14 rounded-5 bg-button-orange text-white flex justify-center items-center" onClick={() => handleCopyShortLink(paymentLink.short_url)}>
                                            <FontAwesomeIcon icon={faCopy} />
                                        </div>
                                    </div>
                                    <div className='w-full h-6'>
                                        {shortLinkCopied && (
                                            <span className='text-base text-v2-green-message-copied'>Link copiado con éxito</span>
                                        )}
                                    </div>
                                    <div className="w-full border border-dashed border-v2-gray-border-dashed-amount flex justify-between items-center p-2 mt-1">
                                        <div className="text-xs text-v2-gray-title-client font-bold w-10/12 truncate">
                                            Link:
                                            <input className="block text-base text-v2-input-text font-normal outline-none w-full" value={paymentLink.url} readOnly />
                                        </div>
                                        <div className="cursor-pointer h-10 w-14 rounded-5 bg-button-orange text-white flex justify-center items-center" onClick={() => handleCopyLink(paymentLink.url)}>
                                            <FontAwesomeIcon icon={faCopy} />
                                        </div>
                                    </div>
                                    <div className='w-full h-6'>
                                        {linkCopied && (
                                            <span className='text-base text-v2-green-message-copied'>Link copiado con éxito</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

export default CreatePaymentLink;