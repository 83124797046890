import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

import { updateAppointmentStatus } from "../../apiClient/operations/selfServiceWorkflowOperations";

import { XMarkIcon } from "@heroicons/react/24/solid";

import Modal from "../../tailwindUI/Modal";
import PrimaryButton from "../../tailwindUI/PrimaryButton";
import SecondaryButton from "../../tailwindUI/SecondaryButton";

import { AuthContext } from "../../cognito/AuthProvider";

export function UpdateAppointmentStatusModal({
  status,
  appointmentId,
  showModal,
  setShowModal,
  setAlert,
  setAppointment,
}) {
  const { user } = useContext(AuthContext);
  const [reason, setReason] = useState("");
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);

  const translateStatus = {
    active: "Activo",
    completed: "Completado",
    pending: "Pendiente",
    canceled: "Cancelado",
    no_show: "No asistió",
  };

  const handleUpdateAppointment = async (e) => {
    try {
      setIsUpdatingStatus(true);
      e.preventDefault();
      let data = {
        status,
        updated_by: {
          name: user.name,
          email: user.email,
        },
      };

      data = status === "canceled" ? { ...data, reason } : data;

      await updateAppointmentStatus(appointmentId, data);
      setAppointment((prev) => ({ ...prev, status }));
      setAlert({
        active: true,
        type: "success",
        message: "Estado de la cita actualizado correctamente",
      });
    } catch (error) {
      console.log("UPDATE APPOINTMENT ERROR", error);
      setAlert({ active: true, type: "error", message: error.message });
    } finally {
      setShowModal(false);
      setIsUpdatingStatus(false);
    }
  };

  return (
    <Modal
      show={showModal}
      setShow={setShowModal}
      className="min-w-full sm:min-w-[500px]"
    >
      <div className="py-2 px-2 space-y-3">
        <div className="relative">
          <XMarkIcon
            className="absolute w-7 h-7 -right-3 -top-3 cursor-pointer text-gray-500"
            onClick={() => setShowModal(false)}
          />
        </div>
        <form onSubmit={handleUpdateAppointment}>
          <h3 className="text-lg text-center font-medium leading-6 text-gray-900">
            ¿Estás seguro de que deseas marcar como {translateStatus[status]}?
          </h3>

          {status === "canceled" ? (
            <>
              <div className="flex flex-col gap-2 my-4">
                <label
                  htmlFor="reason"
                  className="text-base text-v2-input-text"
                >
                  Motivo de la cancelación
                </label>
                <textarea
                  id="reason"
                  type="text"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  placeholder="Escribe el motivo de la cancelación"
                  maxLength={250}
                  className={`w-full font-normal appearance-none h-[75px] py-2.5 mt-1.5 px-4 border rounded-md text-base text-v2-text-bar-steps outline-none flex items-center justify-center`}
                  required
                />
              </div>
            </>
          ) : null}
          <div className="w-fit mt-6 ml-auto space-x-2">
            <SecondaryButton
              type="button"
              onClick={() => setShowModal(false)}
              disabled={isUpdatingStatus}
            >
              Cancelar
            </SecondaryButton>
            <PrimaryButton type="submit" disabled={isUpdatingStatus}>
              Confirmar
            </PrimaryButton>
          </div>
        </form>
      </div>
    </Modal>
  );
}

UpdateAppointmentStatusModal.propTypes = {
  appointmentId: PropTypes.string,
  status: PropTypes.string,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  setAlert: PropTypes.func,
  setAppointment: PropTypes.func,
};
