import apiClient from '../apiClient';
import { apiBaseUrl } from '../../config';

const translateEmail = email => {
    const formatedAtEmail = email ? email.replace('@', '%40') : '';
    const formatedPlusEmail = formatedAtEmail.replace('+', '%2B');
    return formatedPlusEmail
}

export const getStages = async (page, limit, search, sort, accountTypes, filters) => {
    const searchString = search?.includes('@') ? translateEmail(search) : search;
    const accountTypesString = accountTypes ? ` $AND stage~${accountTypes?.join('|')}` : '';
    const url = `${apiBaseUrl}/account-stages/search?page=${page}&limit=${limit}&q=wildCard~${searchString || ''}${accountTypesString ? accountTypesString : ''}${filters || ''}&${ sort || 'field=created&order=-1'}`;
    return apiClient.get(url)
    .then(response => { return response });
}

export const changeStage = async (accountId, stage) => {
    const url = `${apiBaseUrl}/account-stages/`;
    return apiClient.put(url, {
        "account_id": accountId, 
        "stage": stage
    })
    .then(response => { return response });
}
