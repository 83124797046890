import React, { useState, useEffect, useContext, useRef } from 'react';
import { getQuoteS3 } from '../../apiClient/operations/s3UploadOperations';
import { saveAs } from 'file-saver';
import { useHistory } from 'react-router-dom';
import { AlertContext } from '../../context/AlertContext';
import Nav from '../../hooks/Nav';
import { getAllQuotes } from '../../apiClient/operations/quotes';
import useFormatDate from '../../hooks/useFormatDate';
import useFormatterCurrency from '../../hooks/useFormatterCurrency';
import SecondaryLoader from '../../hooks/SecondaryLoader';
import { getAccount } from '../../apiClient/operations/accountsOperations';
import useScrollPosition from '../../hooks/useScrollPosition';
import SearchBar from '../../tailwindUI/SearchBar';
import Table from '../../tailwindUI/Table';
import EmptyState from '../../tailwindUI/EmptyState';
import { EyeIcon, ArrowDownTrayIcon, AdjustmentsHorizontalIcon, TrashIcon } from '@heroicons/react/24/outline';
import Pagination from '../../tailwindUI/Pagination';
import usePagination from '../../tailwindUI/usePagination';
import useSort from '../../tailwindUI/useSort';
import Sort from '../../tailwindUI/Sort';
import SlideOver from '../../tailwindUI/SlideOver';
import Filters from '../../tailwindUI/Filters';
import useFilter from '../../tailwindUI/useFilter';
import Notification from '../../tailwindUI/Notification';

const tableColumns = [
    { heading: 'Fecha', value: 'created_formated' },
    { heading: 'Folio', value: 'folio', main: true},
    { heading: 'Cliente', value: 'account.name'},
    { heading: 'Vencimiento', value: 'expires_at', dateBadge: true},
    { heading: 'Monto', value: 'total_amount_formated'},
];

function GeneratedQuotes() {
    let history = useHistory();
    const user = JSON.parse(localStorage.getItem('user')) || {};
    const { formatterCurrency } = useFormatterCurrency();
    const { formatDate } = useFormatDate();
    const setScrollPosition = useScrollPosition();
    const paginate = usePagination();
    const sortItem = useSort();
    const filterItem = useFilter();
    const serviceRef = useRef('');
    const cycle = useRef('');
    const { setAlert } = useContext(AlertContext);
    const [secondaryLoader, setSecondaryLoader] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [term, setTerm] = useState(null);
    const [openSlide, setOpenSlide] = useState(false);
    const [loader, setLoader] = useState(false);
    const [finished, setFinished] = useState(false);
    const [quoteId, setQuoteId] = useState('');
    const [quotes, setQuotes] = useState([]);
    const [filtersApplied, setFiltersApplied] = useState(false);
    const [filterString, setFilterString] = useState('');
    const [pagination, setPagination] = useState(null);
    const [sortString, setSortString] = useState('');
    const [notification, setNotification] = useState(false);
    const [sortOptions, setSortOptions] = useState([
        { id: 1, name: 'Los más nuevos', field: 'created', sort: 'reverse', active: false },
        { id: 2, name: 'Los más antiguos', field: 'created', sort: 'direct', active: false }
    ]);
    const [filters, setFilters] = useState([
        {
            id: 1,
            name: 'Vencimiento',
            value: 'status',
            open: false,
            options: [
                { id: 1, value: 'pending', label: "Activas", applied: false, checked: false, filter_id: 1 },
                { id: 2, value: 'expires-soon', label: 'Por expirar', applied: false, checked: false, filter_id: 1 },
                { id: 3, value: 'expired', label: 'Expiradas', applied: false, checked: false, filter_id: 1 },
            ],
        },
    ]);

    const handleClick = async (item) => {
        try {
            setSecondaryLoader(true);
            localStorage.setItem("quote", JSON.stringify(item));
            const account = await getAccount(item.account.id);
            localStorage.setItem("account", JSON.stringify(account));
            history.push('/view-quote');
            setScrollPosition(0);
            setSecondaryLoader(false);
        } catch (error) {
            setSecondaryLoader(false);
            setAlert({active: true, type: 'error', message: 'Error al procesar tu solicitud'})
        }
    }

    const handleDownloadQuote = async (item) => {
        try {
            setQuoteId(item.id)
            serviceRef.current = item.id;
            if (item.document.key !== '') {
                setLoader(true);
                const getFile = await getQuoteS3({'id': item.document.key, 'bucket': item.document.bucket});
                if (getFile.size > 0 && cycle.current == '') {
                    const name = item.document.key.split('/').slice(-1)[0];
                    saveAs(getFile, name);
                    cycle.current = 'finish';
                    setLoader(false);
                } else {
                    let cont = 0;
                    setFinished(false);
                    const interval = setInterval(async () => {
                        const loopGetFile = await getQuoteS3({'id': item.document.key, 'bucket': item.document.bucket});
                        if(loopGetFile.size > 0){
                            const name = item.document.key.split('/').slice(-1)[0];
                            saveAs(loopGetFile, name);
                            setFinished(true);
                            clearInterval(interval);
                            setLoader(false);
                        } else {
                            cont++;
                            if (cont == 30) {
                                setFinished(true)
                                clearInterval(interval)
                                setLoader(false);
                                setAlert({active: true, type: 'error', message: 'Hubo un problema al obtener el archivo, vuelve a intentarlo'})
                            }
                            setFinished(false);
                        }
                    }, 1000);
                    finished && clearInterval(interval);
                    setFinished(false);
                }
            }
        } catch (e) {
            setSecondaryLoader(false);
            setAlert({active: true, type: 'error', message: 'Error al descargar cotización'})
        }
    }

    const handleCleanFilters = () => {
        const newFilters = filters.map(filter => {
            const newOptions = filter.options.map(option => {
                return {
                    ...option,
                    applied: false,
                    checked: false
                }
            });
            return {
                ...filter,
                options: newOptions
            }
        });
        setFilters(newFilters);
        setFiltersApplied(false);
        setFilterString('');
        setNotification(true);
        setTimeout(() => {
            setNotification(false);
        }, 5000);
    }

    const columnActions = [
        {
            id: 1,
            name: 'Visualizar',
            type: 'primary',
            icon: <EyeIcon className='w-5 h-5 text-gray-600 lg:text-white'/>,
            action: handleClick,
        },
        {
            id: 2,
            name: 'Descargar',
            type: 'secondary',
            loading: loader,
            itemId: quoteId,
            icon: <ArrowDownTrayIcon className='w-5 h-5 text-gray-600 lg:text-gray-600'/>,
            action: handleDownloadQuote,
        },
    ];

    const getData = async (page) => {
        try {
            setIsLoading(true);
            setScrollPosition(0);
            setCurrentPage(page);
            const res = await getAllQuotes(page, 10, term, sortString, filterString);
            setPagination(paginate(res.total_items, 10, Math.ceil(res.total_items/res.limit)));
            const data = res.data.map (obj => {
                return {
                    ...obj,
                    created_formated: formatDate(obj.created, 'DD MMMM YYYY'),
                    total_amount_formated: formatterCurrency(obj.price.total_amount/100)
                }
            });
            setQuotes(data);
            setIsLoading(false);
        } catch (e) {
            setAlert({active: true, type: 'error', message: 'Error al procesar tu solicitud'})
        }
    }
    const handleSort = item => setSortString(sortItem(item));

    const handlePasteSearchInput = event => setTerm(event.clipboardData.getData('text'));

    const handleApplyFilters = () => {
        setFilterString(filterItem(filters));
        setFiltersApplied(false);
    }
    
    useEffect(() => {
        getData(1);
        return () => {
            setQuotes([]);
        }
    }, []);

    useEffect(() => {
        getData(1);
        return () => {
            setQuotes([]);
        }
    }, [sortString, filterString]);

    useEffect(() => {
        getData(currentPage);
        return () => {
            setQuotes([]);
        }
    }, [currentPage]);

    useEffect(() => {
        term !== null && getData(1);
        return () => {
            setQuotes([]);
        }
    }, [term]);

    useEffect(() => {
        const activeOptions = sortOptions.filter(option => option.active);
        activeOptions.length > 0 && handleSort(activeOptions[0]);
    }, [sortOptions]);

    useEffect(() => {
        filtersApplied && handleApplyFilters();
    }, [filtersApplied]);

    return (
        <>
            <div className="w-full h-full">
                {secondaryLoader && <SecondaryLoader />}
                <div className="w-full xs:h-full md:h-full">
                    <div className="w-full bg-white xs:h-auto md:h-1/12">
                        <Nav user={user}>
                            <div className="w-full">
                                <span className="text-3xl text-white font-bold">
                                    Cotizaciones
                                </span>
                            </div>
                        </Nav>
                    </div>
                    <Notification show={notification} message='Filtros limpiados correctamente' />
                    <div className="w-full px-5 py-3">
                        <div className="hidden lg:flex justify-start">
                            <h1 className="text-4xl font-bold">
                                Todas las cotizaciones
                            </h1>
                        </div>
                    </div>
                    {isLoading ?
                        <div className='px-4 mt-16 lg:mt-2'>
                            <div className='w-full h-10 rounded-full bg-gray-300 animate-pulse'></div>
                            <div className='w-full flex justify-between'>
                                <div className='w-24 h-6 rounded-full bg-gray-300 animate-pulse mt-4'/>
                                <div className='w-24 h-6 rounded-full bg-gray-300 animate-pulse mt-4'/>
                            </div>
                        </div>
                        :
                        <div className='w-full px-4 mt-16 lg:mt-2 space-y-3'>
                            <SearchBar value={ term } setValue={ setTerm } placeholder_mobile='Folio, nombre, correo o RFC' placeholder_desktop='Folio, nombre, correo o RFC' onPaste={ handlePasteSearchInput } />
                            <div className='w-full flex justify-between gap-4'>
                                <Sort options={sortOptions}  title='Ordenar' setOptions={setSortOptions}/>
                                <div className='flex items-center gap-6'>
                                    {filterString !== '' && <span className='cursor-pointer text-sm font-medium text-gray-700 hover:text-gray-900 flex gap-1 pt-0 md:pt-[1px]' onClick={handleCleanFilters}>Limpiar filtros <TrashIcon className='h-5 h-5' /></span>}
                                    <button type="button" className="flex items-center gap-1 text-sm font-medium text-gray-700 md:hidden" onClick={() => setOpenSlide(true)}>
                                        Filtros <span><AdjustmentsHorizontalIcon className='w-[18px] h-[18px]'/></span>
                                    </button>
                                    <Filters filters={ filters } setFilters={ setFilters } setFiltersApplied={setFiltersApplied} />
                                </div>
                            </div>
                        </div>
                    }
                    <div className='w-full p-4 mb-12 lg:mb-8'>
                        { isLoading ?
                            <Table title='Cotizaciones' data={ quotes } isLoadingData={ isLoading } columns={ tableColumns } actions={ columnActions } />
                            :
                            <>
                                { quotes.length > 0 ?
                                    <div className='space-y-4'>
                                        <Table title='Cotizaciones' data={ quotes } isLoadingData={ isLoading } columns={ tableColumns } actions={ columnActions } />
                                        <Pagination pages={ pagination?.pages?.length  } currentPage={ currentPage } setCurrentPage={ setCurrentPage } />
                                    </div>
                                    :
                                    <EmptyState title='No se encontraron contribuyentes' text='Verifica la información antes de continuar.' />
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
            <SlideOver open={ openSlide } setOpen={ setOpenSlide } title='Filtros'>
                <Filters filters={ filters } openSlide={ openSlide } setFilters={ setFilters } setOpenSlide={ setOpenSlide } setFiltersApplied={setFiltersApplied}/>
            </SlideOver>
        </>
    )
}

export default GeneratedQuotes