import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ButtonOrange from '../../../tailwindUI/ButtonOrange';
import Modal from '../../../tailwindUI/Modal';
import PrimaryButton from '../../../tailwindUI/PrimaryButton';
import PaymentInstruction from '../../../tailwindUI/PaymentInstruction';
import { XMarkIcon } from '@heroicons/react/24/outline';

function PaymentInstructions({ data, setData, servicesDisplay, services, setServices, setNumberServicesAdded, numberServicesAdded, setServicesToPay, setStep, onCreate, isNewContract }) {
    const [openModal, setOpenModal] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [editId, setEditId] = useState(null);
    const [createContractModal, setCreateContractModal] = useState(false);
    
    const handleNewInstruction = () => {
		const leftServices = services.filter(x => !x.completed);
		const servicesCompleted = services.filter(x => x.completed);
		const formatServices = leftServices.map(service => {
                return {
                    ...service,
                    selected: true,
                    resume_open: false,
                }
		});
        const servicesAdded = servicesCompleted.map(service => {
            return {
                ...service,
                selected: true,
                resume_open: false
            }
        })
        setData(data.map(instruction => { return { ...instruction, draft: false } }));
		setServices([...servicesAdded, ...formatServices]);
		setServicesToPay(leftServices);
		setStep(2);
	}

    useEffect(() => {
        !openModal && setDeleteId('');
    }, [openModal]);

    useEffect(() => {
        !openEditModal && setEditId('');
    }, [openEditModal]);

    const deleteInstruction = () => {
        const newPaymentPlans = data.filter(payment => payment.id !== deleteId);
        const paymentServices = data.filter(payment => payment.id == deleteId)[0].services;
        const servicesIncluded = services.filter(service => paymentServices.some(ser => ser.sku == service.sku));
		const servicesExcluded = services.filter(service => !paymentServices.some(ser => ser.sku == service.sku));
        const newServs = servicesIncluded.map(item => {
            return {
                ...item,
                completed: false,
                selected: true
            }
        });
        const modifiedServices = [...newServs, ...servicesExcluded];
        setData(newPaymentPlans);
        setServices(modifiedServices);
        setNumberServicesAdded(modifiedServices.filter(service => service.completed).length);
        setOpenModal(false);
    }

    const editInstruction = () => {
		const newPaymentPlans = data.filter(payment => payment.id !== editId);
        const paymentServices = data.filter(payment => payment.id == editId)[0].services;
        const servicesIncluded = services.filter(service => paymentServices.some(ser => ser.sku == service.sku));
		const servicesExcluded = services.filter(service => !paymentServices.some(ser => ser.sku == service.sku));
        const newServs = servicesIncluded.map(item => {
            return {
                ...item,
                completed: false,
                selected: true
            }
        });
        const modifiedServices = [...newServs, ...servicesExcluded];
        setData(newPaymentPlans);
        setServices(modifiedServices);
        setNumberServicesAdded(modifiedServices.filter(service => service.completed).length);
        setOpenEditModal(false);
        setServicesToPay(newServs);
		setStep(3);
	}

    const createContract = () => {
        setCreateContractModal(false);
        if (onCreate) onCreate();
    }

    return (
        <>
            <div className='w-full h-full'>
                <div className='w-full rounded-lg'>
                    <div className="mb-4 w-full">
                        {data.length === 0 ? (
                            <div className='w-full bg-white mb-4 border border-gray-200 p-4 text-center rounded-md'>
                                <div className='mt-4'>
                                    <img 
                                    alt='Botxi - crea una incidencia'
                                    src='https://cdn.fixat.mx/intranet/botxi.png'
                                    className='w-[105px] h-[132px] mx-auto'
                                    />
                                </div>
                                <div className='text-gray-900 font-medium text-2xl text-center mt-4'>
                                    Aún no hay instrucciones generadas.
                                </div>
                                <div className='text-gray-600 font-medium mt-2'>
                                    Puedes comenzar a generar instrucciones de cobro en el siguiente botón: 
                                </div>
                                <div className='w-full flex justify-center my-8'>
                                    <div className='xs:w-full md:w-1/2 max-w-[380px]'>
                                        { numberServicesAdded == services.length ?
                                            <PrimaryButton isFullWidth={true} onClick={ () => setCreateContractModal(true) }>
                                                Generar contrato
                                            </PrimaryButton>
                                            :
                                            <PrimaryButton isFullWidth={true} onClick={ handleNewInstruction }>
                                                Generar nuevo acuerdo
                                            </PrimaryButton>
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                        :
                            <>
                                <PaymentInstruction 
                                    data={ data } 
                                    setData={ setData } 
                                    setDeleteId={ setDeleteId } 
                                    setOpenModal={ setOpenModal } 
                                    setEditId={ setEditId } 
                                    setOpenEditModal={ setOpenEditModal } 
                                    servicesDisplay={ servicesDisplay } />
                            </>
                        }
                    </div>
                    {data.length > 0 &&
                        <div className='w-full flex justify-end mt-4 pb-12 md: pb-4'>
                            <div className='xs:w-full md:w-1/2'>
                                { numberServicesAdded == services.length ?
                                    <PrimaryButton isFullWidth={true} onClick={ () => setCreateContractModal(true) }>
                                        {isNewContract ? 'Generar contrato' : 'Editar contrato'}
                                    </PrimaryButton>
                                    :
                                    <PrimaryButton isFullWidth={true} onClick={ handleNewInstruction }>
                                        Generar nuevo acuerdo
                                    </PrimaryButton>
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
            <Modal show={ openModal } setShow={ setOpenModal } className='min-w-full sm:min-w-[500px]'>
                <div className="py-2 px-2 text-center">
                    <div className='w-full text-gray-400 flex justify-end'><XMarkIcon className='w-7 h-7 cursor-pointer' onClick={ () => setOpenModal(false) }/></div>
                    <h3 className="text-lg font-medium leading-6 text-gray-900">Eliminar acuerdo de pago</h3>
                    <div className="mt-2 max-w-xl text-sm text-gray-500">
                        <p>Si eliminas este acuerdo de pago, deberás volver a configurarlo para generar el contrato. ¿Quieres proceder a eliminarlo?</p>
                    </div>
                    <div className="mt-6">
                        <div className='w-full md:w-3/5 mx-auto text-center'>
                            <ButtonOrange isFullWidth={true} onClick={ deleteInstruction }>
                                Sí, eliminar acuerdo
                            </ButtonOrange>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal show={ openEditModal } setShow={ setOpenEditModal } className='min-w-full sm:min-w-[500px]'>
                <div className="py-2 px-2 text-center">
                    <div className='w-full text-gray-400 flex justify-end'><XMarkIcon className='w-7 h-7 cursor-pointer' onClick={ () => setOpenEditModal(false) }/></div>
                    <h3 className="text-lg font-medium leading-6 text-gray-900">Editar acuerdo de pago</h3>
                    <div className="mt-2 max-w-xl text-sm text-gray-500">
                        <p>¿Quieres editar este acuerdo de pago?</p>
                    </div>
                    <div className="mt-6">
                        <div className='w-full md:w-3/5 mx-auto text-center'>
                            <ButtonOrange isFullWidth={true} onClick={ editInstruction }>
                                Sí, editar acuerdo
                            </ButtonOrange>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal show={ createContractModal } setShow={ setCreateContractModal } className='min-w-full sm:min-w-[500px]'>
                <div className="py-2 px-2 text-center">
                    <div className='w-full text-gray-400 flex justify-end'><XMarkIcon className='w-7 h-7 cursor-pointer' onClick={ () => setCreateContractModal(false) }/></div>
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                        {isNewContract ? 'Generar contrato' : 'Editar contrato'}
                    </h3>
                    <div className="mt-2 max-w-xl text-sm text-gray-500">
                        <p>
                            {isNewContract ? '¿Estás seguro que deseas generar este contrato?' : '¿Estás seguro que deseas editar este contrato?'}
                        </p>
                    </div>
                    <div className="mt-6">
                        <div className='w-full md:w-3/5 mx-auto text-center'>
                            <PrimaryButton isFullWidth={true} onClick={ createContract }>
                                {isNewContract ? 'Sí, generar contrato' : 'Sí, editar contrato'}
                            </PrimaryButton>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

PaymentInstructions.propTypes = {
    data: PropTypes.array,
    setData: PropTypes.func,
    servicesDisplay: PropTypes.array,
    setServices: PropTypes.func,
    services: PropTypes.array,
    setNumberServicesAdded: PropTypes.func,
    numberServicesAdded: PropTypes.number,
    setServicesToPay: PropTypes.func,
    setStep: PropTypes.func,
    onCreate: PropTypes.func,
    isNewContract: PropTypes.bool
}

export default PaymentInstructions