import { useContext, useEffect, useState } from "react";
import { getConnections } from "../../apiClient/operations/connectionsOperations";
import { updateConnectionConfirmation } from "../../apiClient/operations/selfServiceWorkflowOperations";
import { AuthContext } from "../../cognito/AuthProvider";
import { invalidateConnectionOperation } from "../../apiClient/operations/linksOperations";

export function useConnection({ accountId, initialStatus }) {
  const [isLoadingConnection, setIsLoadingConnection] = useState(false);
  const [errorConnection, setErrorConnection] = useState(false);
  const [connections, setConnections] = useState(null);
  const [status, setStatus] = useState(initialStatus);
  const { user } = useContext(AuthContext);
  
  const confirmedBy = connections ? {
    name: connections[connections.length - 1]?.confirmation?.confirmed_by?.name,
    updated: connections[connections.length - 1]?.confirmation?.updated,
    confirmed: connections[connections.length - 1]?.confirmation?.confirmed
  }: null

  const efirma = connections?.findLast(
    (connection) =>
      connection?.credentials?.type == "efirma" &&
      (connection?.status == "active" ||
        connection?.status == "pending" ||
        connection?.status == "invalid" ||
        connection?.status == "expired" ||
        connection?.status == "invalid_provider")
  );

  const ciec = connections?.findLast(
    (connection) =>
      connection?.credentials?.type == "ciec" &&
      (connection?.status == "active" ||
        connection?.status == "pending" ||
        connection?.status == "invalid" ||
        connection?.status == "invalid_provider")
  );

  const getSatCredentials = async () => {
    try {
      setIsLoadingConnection(true);
      const res = await getConnections(accountId);
      setConnections(res.data);
    } catch (error) {
      setErrorConnection(error);
    } finally {
      setIsLoadingConnection(false);
    }
  };

  const updateConnectionStatus = async (newStatus) => {
    try {
      const connectionId = connections[connections.length - 1]?.id;
      if (newStatus === "confirmed" || newStatus === "in_review") {
        await updateConnectionConfirmation(accountId, connectionId, {
          confirmed: newStatus === "confirmed",
          connection_type: "asf",
          confirmed_by: {
            name: user.name,
            email: user.email,
          },
        });
      } else if (newStatus === "invalid") {
        await invalidateConnectionOperation(accountId, connectionId, "asf");
      }
      setStatus(newStatus);
      getSatCredentials()
    } catch (error) {
      setErrorConnection(error);
    }
  };

  useEffect(() => {
    if (accountId) {
      getSatCredentials();
    }
  }, [accountId]);

  useEffect(() => {
    setStatus(initialStatus);
  }, [initialStatus]);

  return {
    efirma,
    ciec,
    confirmedBy,
    statusCredentials: status,
    setStatusCredentials: setStatus,
    updateConnectionStatus,
    isLoadingConnection,
    errorConnection,
  };
}
