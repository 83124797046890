import React, { useState, useEffect } from 'react';
import Toogle from '../../tailwindUI/Toogle';
import PropTypes from 'prop-types';
import { formatPeso } from '../../util';
import SkeletonLoader from '../../tailwindUI/SkeletonLoader';
import useConstructDatesDisplay from '../../hooks/useConstructDatesDisplay';

function TableBillingLink({ services, selectedServices, enabledDisccount, setSelectedServices, loading }) {
    const [updatedService, setUpdatedService] = useState(null);
    useEffect(() => {
        if (updatedService != null) {
            const updatedServices = selectedServices.map((selectedServ) => {
                if (selectedServ.id === updatedService.id) {
                    return updatedService;
                } else {
                    return selectedServ;
                }
            });
            setSelectedServices(updatedServices);
        }
    }, [updatedService]);
    return (
        <>
            <div className='w-full px-4 pt-4 items-center text-xs font-bold text-v2-gray-title-client'>
                <div className='flex flex-col w-full'>
                    <div className='flex bg-blue-50 text-gray-700 px-4 py-4 rounded-t-lg'>
                        <span className='w-5/12 2xl:w-3/6 text-sm font-semibold'>Servicio</span>
                        <span className='w-1/5 text-sm font-semibold'>Cantidad </span>
                        <span className='w-1/5 text-sm font-semibold'>{!enabledDisccount && 'Precio Unitario'}</span>
                        <span className='w-1/5 text-sm font-semibold text-center 2xl:text-right'>Total</span>
                    </div>

                    {loading ?
                        <TableRowLoadingBillingLink /> :
                        <>
                            {services?.map((item, index) => (
                                <TableRowBillingLink
                                    key={index}
                                    selectedServiceItem={selectedServices[index]}
                                    service={item}
                                    enabledDisccount={enabledDisccount}
                                    setUpdatedService={setUpdatedService}
                                />
                            ))
                            }
                        </>
                    }

                </div>
            </div>
        </>
    )
}

export default TableBillingLink;

function TableRowBillingLink({ selectedServiceItem, service, enabledDisccount, setUpdatedService }) {
    const [selectedService, setSelectedService] = useState(null)
    const [selected, setSelected] = useState(true)
    const [renovation, setRenovation] = useState(false)
    const [quantity, setQuantity] = useState(service?.qty)

    const total = quantity * service?.price

    const discountList = [
        { type: "direct", symbol: "$" },
        { type: "percent", symbol: "%" }
    ]
    const [selectedDisccount, setSelectedDisccount] = useState(discountList[0])

    const handleSelectService = (e) => {
        const value = e.target.checked;
        setSelected(value)
        setSelectedService(selectedServ => ({
            ...selectedServ,
            selected: value
        }))
    }

    const changeServicePriceDiscount = (e) => {
        const value = e.target.value.replace(/[^0-9.]/g, "");
       
        if (value == "") {
            setSelectedService(selectedServ => ({
                ...selectedServ,
                discount_error: "Campo obligatorio"
            }))
        }else{
            setSelectedService(selectedServ => ({
                ...selectedServ,
                discount_error: null
            }))
        }
        if (value * 100 <= selectedService.remaining_amount) {
            setSelectedService(selectedServ => ({
                ...selectedServ,
                discount: value,
                discount_value: selectedServ.disccount_type == "direct" ? value : (selectedServ.remaining_amount / 100 * value / 100),
            }))
        }
    }

    const handleSelectedDisccount = (e) => {
        const value = e.target.value
        setSelectedDisccount(value)
        setSelectedService(selectedServ => ({
            ...selectedServ,
            disccount_type: value,
            discount: value == "direct" ? 0 : 10,
            discount_value: value == "direct" ? 0 : (selectedServ.remaining_amount / 100 * 0.1),
        }))
    }

    const handleSetRenovation = (e) => {
        const value = e;
        setRenovation(value)
        setSelectedService(selectedServ => ({
            ...selectedServ,
            renovation: value
        }))
    }

    const handleQuantityChange = (e) => {
        const newQuantity = e.target.value

        if(0 < newQuantity && newQuantity <= service?.periods.length || e.target.value.length === 0) {
            setQuantity(newQuantity)
        }
    }

    useEffect(() => {
        setSelectedService(selectedServ => ({
            ...selectedServ,
            qty: quantity,
            remaining_amount: total,
            dates_display: useConstructDatesDisplay(selectedServ?.periods.filter((_, index) => index < quantity)),
            discount: 0,
            discount_value: 0
        })) 
    }, [quantity])

    useEffect(() => {
        setUpdatedService(selectedService)
    }, [selectedService]);

    useEffect(() => {
        setSelectedService(selectedServiceItem)
    }, [selectedServiceItem]);

    const changeServicePrice = (e) => {
        const value = e.target.value.replace(/[^0-9.]/g, "");
        setSelectedService(selectedServ => ({
            ...selectedServ,
            subscription_price: value,
        }))
    }

    return (
        <div className={`${!selected && 'opacity-50'}`}>
            <div className={`flex items-start md:items-center flex-col md:flex-row `}>
                <div className='flex items-center w-3/6 gap-2 px-3 py-2 text-sm text-gray-900 font-normal capitalize text-left'>
                    <div className="flex w-fit h-6 items-center">
                        <input
                            id={`servicio`}
                            name={`servicio`}
                            type="checkbox"
                            checked={selected}
                            onChange={(e) => handleSelectService(e)}
                            aria-describedby="comments-description"
                            className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                        />
                    </div>
                    <span>{service.name}</span>
                </div>
                <div className='w-1/5 px-3 py-2 text-sm text-gray-900 sm:table-cell capitalize text-left'>
                    <input
                        type="text"
                        inputMode='number'
                        className="font-normal w-20 text-v2-text-bar-steps border-v2-gray-border-tables focus:ring-v2-blue-text-login focus:border-v2-blue-text-login transition-all py-1 rounded-md"
                        value={quantity}
                        onChange={handleQuantityChange}
                        maxLength={5}
                    />
                </div>
                <div className='2xl:w-3/12 w-4/12 px-3 py-2 text-sm font-normal text-gray-900 sm:table-cell capitalize text-left'>
                    {enabledDisccount ? (
                        <div className='flex flex-col'>
                            <div className='flex w-full'>
                                <div className="w-full text-base text-v2-text-bar-steps outline-none flex items-center justify-center relative">
                                    <div className={`${selectedService.disccount_type != "direct" ? 'hidden' : 'absolute'}  flex items-center top-0 left-0 bottom-0 text-sm mx-2`}>
                                        <span className="font-normal block text-v2-gray-title-client">$</span>
                                    </div>
                                    <input
                                        type="text"
                                        inputMode='number'
                                        placeholder='0'
                                        disabled={!selected}
                                        value={selectedService?.discount}
                                        onChange={(e) => changeServicePriceDiscount(e)}
                                        className={`font-normal border-v2-gray-border-tables border-r-0 text-v2-text-bar-steps  transition-all block w-full ${selectedService.disccount_type != "direct" ? "pl-2" : "pl-6 "} pr-4 py-1 rounded-l-md ${selectedService?.error_discount ? 'ring-error border-error' : 'focus:ring-v2-blue-text-login focus:border-v2-blue-text-login'}`}
                                    />
                                </div>
                                <select
                                    id="disccount_type"
                                    name='disccount_type'
                                    className='w-fit rounded-r-md py-0 px-4 border-none bg-v2-blue-text-login text-white'
                                    onChange={(e) => handleSelectedDisccount(e)}
                                    defaultValue={selectedDisccount?.type}
                                    disabled={!selected}
                                >
                                    {discountList.map((disccount_type, i) => (
                                        <option key={i} value={disccount_type.type}>
                                            {disccount_type?.symbol}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {selectedService?.discount_error && <span className='text-error text-xs'>
                                {selectedService?.discount_error}
                            </span>}

                        </div>

                    ) : formatPeso(service?.price / 100)}

                </div>
                <div className='w-1/5 px-3 py-2 text-sm font-normal text-gray-900 sm:table-cell capitalize text-right'>
                    {
                        enabledDisccount ?
                            <div className='flex flex-col'>
                                <span className='text-gray-500 '><del>{formatPeso((total) / 100)}</del></span>
                                <span className='text-gray-900 font-semibold'>{formatPeso((total) / 100 - (selectedService?.discount_value))}</span>
                            </div>
                            :
                            <span className='text-gray-900 font-semibold'>{formatPeso((total) / 100)}</span>
                    }
                </div>
            </div>

            <div className={`flex flex-col w-full gap-2 px-4 py-4 rounded-lg transition-colors ${renovation ? 'bg-gray-200 my-3' : 'bg-white'} `}>
                <div className='flex w-full justify-between'>
                    <div className='flex items-center gap-2'>
                        <Toogle disabled={!selected} blue={true} enabled={renovation} setEnabled={(e) => handleSetRenovation(e)} />
                        <span className='text-sm font-normal text-gray-900'>Reactivar suscripción</span>
                    </div>
                    {
                        renovation && (
                            <div className="w-28 text-base text-v2-text-bar-steps outline-none flex items-center justify-center relative">
                                <div className='absolute flex items-center top-0 left-0 bottom-0 text-sm mx-2'>
                                    <span className="font-normal block text-v2-gray-title-client">$</span>
                                </div>
                                <input
                                    type="text"
                                    inputMode='number'
                                    disabled={!selected}
                                    value={selectedService?.subscription_price}
                                    onChange={(e) => changeServicePrice(e)}
                                    className="font-normal border-v2-gray-border-tables text-v2-text-bar-steps focus:ring-v2-blue-text-login focus:border-v2-blue-text-login transition-all block w-full pl-6 pr-4 py-1 rounded-md"
                                />
                            </div>
                        )
                    }
                </div>
                {
                    renovation &&
                    (<>
                        <p className='text-gray-900 text-sm font-normal italic'>Al pago del link se recuperará la suscripción con este monto</p>
                    </>)
                }
            </div>
            <hr className='w-full bg-primary' />
        </div>
    )
}

function TableRowLoadingBillingLink() {
    return (
        <>
            <div className='flex text-gray-700 px-4 py-4 rounded-t-lg gap-2'>
                <span className='w-2/5'><SkeletonLoader /></span>
                <span className='w-1/5 text-sm font-semibold'><SkeletonLoader /> </span>
                <span className='w-1/5 text-sm font-semibold'><SkeletonLoader /></span>
                <span className='w-1/5 text-sm font-semibold text-center'><SkeletonLoader /></span>
            </div>
            <div className='flex text-gray-700 px-4 py-4 rounded-t-lg gap-2'>
                <span className='w-2/5'><SkeletonLoader /></span>
                <span className='w-1/5 text-sm font-semibold'><SkeletonLoader /> </span>
                <span className='w-1/5 text-sm font-semibold'><SkeletonLoader /></span>
                <span className='w-1/5 text-sm font-semibold text-center'><SkeletonLoader /></span>
            </div>
            <div className='flex text-gray-700 px-4 py-4 rounded-t-lg gap-2'>
                <span className='w-2/5'><SkeletonLoader /></span>
                <span className='w-1/5 text-sm font-semibold'><SkeletonLoader /> </span>
                <span className='w-1/5 text-sm font-semibold'><SkeletonLoader /></span>
                <span className='w-1/5 text-sm font-semibold text-center'><SkeletonLoader /></span>
            </div>

        </>
    )
}

TableBillingLink.propTypes = {
    services: PropTypes.array,
    selectedServices: PropTypes.array,
    enabledDisccount: PropTypes.bool,
    setSelectedServices: PropTypes.func,
    loading: PropTypes.bool
}

TableRowBillingLink.propTypes = {
    service: PropTypes.object,
    selectedServiceItem: PropTypes.object,
    enabledDisccount: PropTypes.bool,
    setUpdatedService: PropTypes.func
}