import apiClient from '../apiClient';
import { apiBaseUrl } from '../../config';

export const getOrder = async (order_id="") => {
    return apiClient.get(`${apiBaseUrl}/orders/admin/${order_id}`)
    .then(response => { return response })
}

export const createTaxpayerAnualDeclaration = async (accountId) => {
    return apiClient.post(`${apiBaseUrl}/tax-annual-statement/taxpayer/yearly/declaration/create/${accountId}`, {}).then(response => { return response });
}

export const getTaxpayerDeclarations = async (accountId, req) => {
    return apiClient.get(`${apiBaseUrl}/tax-annual-statement/taxpayer/yearly/declarations/${accountId}`, {}, req)
    .then(response => { return response });
}