import apiClient from '../apiClient';
import { apiBaseUrl } from '../../config';

export const getLead = async (leadId, req) => {
    return apiClient.get(`${apiBaseUrl}/leads/admin/${leadId}`, {}, req)
    .then(response => { return response });
}

export const createSession = async (data) => {
    return apiClient.post(`${apiBaseUrl}/sessions/`, data).then(response => {
        return response;
    });
}

export const createSessionAdmin = async (data) => {
    return apiClient.post(`${apiBaseUrl}/sessions/admin`, data).then(response => {
        return response;
    });
}

export const createLead = async (data) => {
    return apiClient.post(`${apiBaseUrl}/leads/admin`, data).then(response => {
        return response;
    });
}

export const getLeadByEmail = async (email) => {
    return apiClient.get(`${apiBaseUrl}/accounts/admin/search?q=email='${email}'`, {})
    .then(response => { return response });
}