import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faPenToSquare, faFileZipper } from '@fortawesome/free-solid-svg-icons';
import { downloadObjectS3, getObjectS3 } from '../apiClient/operations/s3UploadOperations';
import PropTypes from 'prop-types';

function GeneralDataQuote({ dataClient, edit }) {
    const [openClientInformation, setOpenClientInformation] = useState(false);
    const [nameFile, setNameFile] = useState('');

    const getObject = async () => {
        try {
            const resS3 = await getObjectS3({ 'name': dataClient.rfc == undefined ? 'RFCX009900ABC' : dataClient.rfc });
            if (resS3.fileName != undefined) {
                setNameFile(resS3.fileName)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getObject();
    }, [])

    const handleDownloadFile = async (name) => {
        await downloadObjectS3(name);
    }
    return (
        <>
            <div className="w-full h-full border border-v2-gray-border-tables rounded-10 bg-white xs:mt-4 md:mt-0">
                <div className='w-full xs:px-2 md:px-4 py-[7px] border-b border-v2-gray-border-tables flex items-center'>
                    <div className="w-12 text-v2-blue-text-login">
                        <FontAwesomeIcon icon={faUserCircle} className='leading-3 pl-1 text-3.5xl' />
                    </div>
                    <div className='xs:w-8/12 lg:w-7/12 text-xl font-semibold text-v2-input-text'>
                        Contratante
                    </div>
                    <div className={`${edit ? 'flex' : 'hidden'} xs:w-4/12 md:w-4/12 justify-end items-center text-button-orange`}>
                        <div className="w-full flex items-center justify-end">
                            <span className="flex items-center text-v2-yellow-edit-info text-base cursor-pointer" onClick={() => setOpenClientInformation(!openClientInformation)}>
                                <FontAwesomeIcon icon={faPenToSquare} className="leading-3 text-2xl" />
                                <span className="underline pl-1 pt-1 xs:hidden md:block">
                                    Editar datos
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="w-full p-4">
                    <div className="w-full px-2 pt-1 text-xs text-v2-gray-title-client font-bold">
                        Cliente:
                        <span className={`${dataClient.first_name == undefined ? 'opacity-40' : 'opacity-100'} w-full text-v2-input-text text-base font-normal block`}>
                            {(() => {
                                if (edit) return dataClient.first_name == undefined ? 'Agregar nombre del cliente' : `${dataClient.first_name} ${dataClient.last_name}`;
                                return dataClient.first_name == undefined ? 'Nombre no añadido' : `${dataClient.first_name} ${dataClient.last_name}`;
                            })()}
                        </span>
                    </div>
                    <div className="w-full px-2 pt-3 text-xs text-v2-gray-title-client font-bold">
                        RFC:
                        <span className={`${dataClient.rfc == undefined ? 'opacity-40' : 'opacity-100'} w-full text-v2-input-text text-base font-normal block`}>
                            {edit ?
                                dataClient.rfc || 'Agregar RFC'
                                :
                                dataClient.rfc || 'RFC no añadido'
                            }
                        </span>
                    </div>
                    <div className="w-full px-2 pt-3 text-xs text-v2-gray-title-client font-bold">
                        Tipo de persona fiscal:
                        <span className={`${dataClient.legal_type == undefined ? 'opacity-40' : 'opacity-100'} w-full text-v2-input-text text-base font-normal block`}>
                            {(() => {
                                if (dataClient.legal_type) return dataClient.legal_type == 'personal' ? 'Persona Física' : 'Persona Moral';
                                return edit ? 'Agregar Tipo de persona' : 'Tipo de persona no añadida';
                            })()}
                        </span>
                    </div>
                    <div className="w-full px-2 pt-3 text-xs text-v2-gray-title-client font-bold">
                        Régimen fiscal:
                        <span className={`${dataClient.regime == undefined ? 'opacity-40' : 'opacity-100'} w-full text-v2-input-text text-base font-normal block`}>
                            {edit ?
                                dataClient.regime || 'Agregar Régimen fiscal'
                                :
                                dataClient.regime || 'Régimen fiscal no añadido'
                            }
                        </span>
                    </div>
                    <div className="w-full px-2 pt-3 text-xs text-v2-gray-title-client font-bold">
                        Correo:
                        <span className={`${dataClient.email == undefined ? 'opacity-40' : 'opacity-100'} w-full text-v2-input-text text-base font-normal block`}>
                            {edit ?
                                dataClient.email || 'Agregar email'
                                :
                                dataClient.email || 'Email no añadido'
                            }
                        </span>
                    </div>
                    <div className="w-full px-2 pt-3 text-xs text-v2-gray-title-client font-bold">
                        Móvil:
                        <span className={`${dataClient.phone_number == undefined ? 'opacity-40' : 'opacity-100'} w-full text-v2-input-text text-base font-normal block`}>
                            {edit ?
                                dataClient.phone_number || 'Agregar número celular'
                                :
                                dataClient.phone_number || 'Número no añadido'
                            }
                        </span>
                    </div>
                    <div className="w-full px-2 pt-3 text-xs text-v2-gray-title-client font-bold">
                        Ciudad de residencia:
                        <span className={`${dataClient.state == undefined ? 'opacity-40' : 'opacity-100'} w-full text-v2-input-text text-base font-normal block`}>
                            {edit ?
                                dataClient.state || 'Agregar residencia'
                                :
                                dataClient.state || 'Ninguna residencia añadida'
                            }
                        </span>
                    </div>
                    <div className="w-full px-2 pt-3 text-xs text-v2-gray-title-client font-bold">
                        Ocupación:
                        <span className={`${dataClient.occupancy == undefined ? 'opacity-40' : 'opacity-100'} w-full text-v2-input-text text-base font-normal block`}>
                            {edit ?
                                dataClient.occupancy || 'Agregar ocupación'
                                :
                                dataClient.occupancy || 'Ninguna ocupación añadida'
                            }
                        </span>
                    </div>
                    {!edit &&
                        <div className="w-full px-2 pt-3 text-xs text-v2-gray-title-client font-bold">
                            Documentos fiscales:
                            <span className="w-full text-v2-input-text text-base font-normal block py-2">
                                {nameFile != '' ?
                                    <div className="w-full flex items-center">
                                        <FontAwesomeIcon icon={faFileZipper} className="text-v2-gray-light-plus-circle text-3xl leading-3 " />
                                        <span className="text-v2-text-bar-steps text-[15px] pl-7 underline cursor-pointer" onClick={() => handleDownloadFile(nameFile)}>
                                            {nameFile}
                                        </span>
                                    </div>
                                    :
                                    <span className="w-full text-v2-input-text text-base font-normal block opacity-40">
                                        Archivo no añadido
                                    </span>
                                }
                            </span>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

GeneralDataQuote.propTypes = {
    dataClient: PropTypes.any,
    edit: PropTypes.any,
    selectedFiles: PropTypes.any
}

export default GeneralDataQuote