const useSeparateFullname = () => {
    const separateFullname = fullname => {
        let fullName = fullname || "";
        let result = {};

        if (fullName.length > 0) {
            let nameTokens = fullName.split(' ');
            if (nameTokens.length <= 3) {
                result.name = nameTokens.slice(0, 1).join(' ');
                result.lastName = nameTokens.slice(1-nameTokens.length).join(' ');
                result.secondLastName = "";
            } else if (nameTokens.length == 4) {
                result.name = nameTokens.slice(0, 2).join(' ');
                result.lastName = nameTokens.slice(-2, -1).join(' ');
                result.secondLastName = nameTokens.slice(-1).join(' ');
            }else if (nameTokens.length >= 5) {
                result.name = nameTokens.slice(0, 3).join(' ');
                result.lastName = nameTokens.slice(3-nameTokens.length, -1).join(' ');
                result.secondLastName = nameTokens.slice(-1).join(' ');
            }
        }
    
        return result;
    }

    return { separateFullname }
}

export default useSeparateFullname