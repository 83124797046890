import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useFormatterCurrency from '../../../hooks/useFormatterCurrency';
import PrimaryButton from '../../../tailwindUI/PrimaryButton';
import { ReceiptRefundIcon } from '@heroicons/react/24/solid';
import ModalV2 from '../../../tailwindUI/Modal';
import LinkButton from '../../../tailwindUI/LinkButton';
import { sendRefund } from '../../../apiClient/operations/chargesOperations';
import SecondaryLoader from '../../../hooks/SecondaryLoader';
import { AuthContext } from '../../../cognito/AuthProvider';
import LongAlert from '../../../tailwindUI/LongAlert';

function RefundModal({showRefund, setShowRefund, paymentDetails, orderDetails, setPaymentDetails, handleUpdate}) {
    const { formatterCurrency } = useFormatterCurrency();
    
    const { user } = React.useContext(AuthContext);
    const [ alert, setAlert ] = useState();
    const [showAlert, setShowAlert] = useState(false);
    const [refundAmount, setRefundAmount] = useState(paymentDetails.amount/100);
    const [refundItems, setRefundItems] = useState([]);
    const [refundReason, setRefundReason] = useState();
    const [refundError, setRefundError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [refundSelect, setRefundSelect] = useState([
        { id: 1, name: 'Reembolso total', active: true },
        { id: 2, name: 'Reembolso parcial', active: false },
    ]);

    useEffect(()=>{
        refundSelect.find((tab) => tab.name== 'Reembolso parcial').active == true &&
        setRefundAmount(refundItems.reduce((acc, item) => acc + parseFloat(item.amount), 0))
    },[refundItems])

    useEffect(() => {
        setRefundError(refundReason=='')
    }, [refundReason]);

    useEffect(() => {
        setShowAlert(alert?.active)
    }, [alert]);


    const handlerRefund = async () => {
        try {
            setLoading(true)
            var charge_id =  paymentDetails.id
            var request = {
                "refunded_by":{
                    "name": user.name,
                    "email": user.email
                },
                'provider':paymentDetails.provider.name,
                'refund_amount':refundAmount*100,
                'refund_reason':refundReason
            }
            request['services'] = refundSelect.find((tab) => tab.name== 'Reembolso total').active == true ? orderDetails?.line_items?.data.map((item)=>{
                return {
                    "service_id": item.id,
                    "amount": item.price.total_amount/100
                }
            }):refundItems
            var refund = await sendRefund(charge_id, request)
            setLoading(false)
            setAlert({ active: true, type: 'success', message: 'Reembolso exitoso' })
            handleUpdate()
            setPaymentDetails({ ...paymentDetails, status:refund?.status, refund_amount:refund?.refund_amount, refund_reason:refund?.refund_reason, refunded_by:refund?.refunded_by })
            setShowRefund(false)
        }catch(error){
            setAlert({ active: true, type: 'error', message: error.message })
            setLoading(false)
        }
        
    }

    return (<>
    
            <ModalV2 show={showRefund} setShow={setShowRefund} className='w-full sm:max-w-[30rem]'>
            <div className='w-full divide-y divide-gray-200'>
                    <div className='pb-3'>
                        <dl className="grid grid-cols-1 gap-4 sm:grid-cols-1">
                            <div className="flex flex-row items-center">
                                <dd className="mr-2 text-xl font-bold text-gray-900">Reembolsar cobro</dd>
                            </div>
                        </dl>
                    </div>
                    <div className='py-3'>
                        <dl className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Fecha de pago</dt>
                                <dd className="mt-1 text-sm text-gray-900">{paymentDetails?.formatted_paid_at}</dd>
                            </div>
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Total pagado</dt>
                                <dd className="mt-1 text-sm text-gray-900">{paymentDetails?.formatted_amount}</dd>
                            </div>
                        </dl>
                    </div>
                    <div className='py-3'>
                        <select
                            id="tabs"
                            name="tabs"
                            className="block w-full rounded-md border-gray-300 focus:border-v2-blue-text-login focus:ring-v2-blue-text-login"
                            value={refundSelect.find((tab) => tab.active)?.name}
                            onChange={e => {
                                var new_options = refundSelect.map((item)=>{
                                    item.name == e.target.value ? (item.active = true):(item.active = false)
                                    return item
                                })
                                setRefundSelect(new_options)}}
                                >
                            {refundSelect.map((tab) => (
                                <option key={tab.name}>{tab.name}</option>
                            ))}
                        </select>
                        <dl className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                            <div className="col-span-2">
                                {refundSelect.find((tab) => tab.name== 'Reembolso parcial').active == true &&
                                <>
                                <div className='pt-5 grid grid-cols-5 gap-4 sm:grid-cols-5 items-center'>
                                    <div className='col-span-3 text-gray-900 text-sm font-normal'>
                                        Servicios pagados
                                    </div>
                                    <div className='col-span-2 text-gray-900 text-sm font-normal'>
                                        Monto a reembolsar
                                    </div>
                                </div>
                                {orderDetails?.line_items?.data.map((item, index)=>{
                                    return <RefundInput key={index} index={index} setRefundItems={setRefundItems} item={item}/>
                                })}
                                </>
                                }
                            <div className='grid grid-cols-5 gap-4 sm:grid-cols-5 items-center'>
                                    <div className=' col-span-3 text-gray-500 font-medium text-sm font-normal'>
                                    Total a reembolsar
                                    </div>
                                    <div className="col-span-2 w-full px-2 h-[46px] mt-1.5  rounded-md text-base text-v2-text-bar-steps outline-none flex items-center justify-center">
                                        <span className="text-gray-900">{
                                        refundSelect.find((tab) => tab.name== 'Reembolso parcial').active == true && !isNaN(refundAmount)  ?
                                        formatterCurrency(refundAmount):
                                        paymentDetails?.formatted_amount
                                    }
                                        </span>
                                    </div>
                            </div>
                        </div>

                        
                        </dl>
                        <div className="w-full py-2">
                            <div className="w-full text-sm font-medium text-gray-500">
                                Motivo del reembolso:
                                <textarea
                                    type="text"
                                    placeholder="Escribe el motivo del rembolso"
                                    maxLength={250}
                                    value={refundReason}
                                    onInput={e=>setRefundReason(e.target.value)}
                                    className={`w-full font-normal appearance-none h-[75px] py-2.5 mt-1.5 px-4 border rounded-md text-base text-v2-text-bar-steps outline-none flex items-center justify-center ${refundError ? "border-red-500":"border-v2-border-time"}`}
                                />
                                {refundError && <span className='text-xs text-red-500'>El motivo del reembolso es un campo obligatorio</span>}
                            </div>
                        </div>
                    <div className='w-full flex items-center justify-end'>
                    <div className='w-[7rem]'>
                                    <LinkButton type="button" onClick={() => {setShowRefund(false)}}>
                                        Cancelar
                                    </LinkButton>
                    </div>
                    <div className='w-[10rem]'>
                                 <PrimaryButton onClick={handlerRefund}  disabled={(refundReason=='') || (refundReason==null) || (refundAmount<=0) || isNaN(refundAmount) || (refundAmount>(paymentDetails.amount/100)) } isFullWidth>
                                    Reembolsar<ReceiptRefundIcon className='w-5 h-5 ml-2 inline-block' />
                                </PrimaryButton>
                    </div>
                    </div>
                    </div>
                    
                </div>
                
            </ModalV2>         
            {loading && <SecondaryLoader/>}
            {showAlert && <div className="w-1/2 h-screen fixed top-12 left-1/4 z-[60] ">
                <LongAlert show={ showAlert } onClose={()=>setShowAlert(false) } type={ alert.type } title={ alert.message } />
            </div> } 
            </>
    )
}


const RefundInput = ({item, setRefundItems, index})=>{
    const [refundAmount, setRefundAmount] = useState(item.price.total_amount/100)
    const [error, setError] = useState(false)

      useEffect(() => {
        setRefundItems((prevTotal) => {
          const updatedTotal = [...prevTotal];
          updatedTotal[index] = {
            "service_id": item.id,
            "amount": parseFloat(refundAmount)
          };
          return updatedTotal;
        });
      }, [index, item.id, refundAmount]);
    
    
    useEffect(()=>{
            setError(refundAmount > item.price.total_amount/100)
    },[refundAmount])

    return <div className='grid grid-cols-5 gap-4 sm:grid-cols-5 items-center'>
        <div className='col-span-3 text-gray-500 text-sm font-normal'>
            {item.name}
        </div>
        <div className={`col-span-2 w-full px-2 h-[46px] mt-1.5 border border-v2-border-time rounded-md text-base text-v2-text-bar-steps outline-none flex items-center justify-center ${error && 'border-red-400'}`}>
            <span className="text-v2-gray-title-client">$</span>
            <input
                placeholder={item.price.formated_total}
                value={refundAmount}
                onInput={e => {setRefundAmount(isNaN(e.target.value) ? 0 : e.target.value)}}
                type="number"
                style={{"-webkit-appearance": "none",
                "margin": 0,
                "-moz-appearance": "textfield"}}
                min="0"
                className="w-full border-none outline-none text-base pl-2 appearance-none"
            />
        </div>
        {error && <>
            <span className='-mt-4 col-span-3 inline-block text-xs text-input-error'></span>
            <span className='-mt-4 col-span-2 inline-block text-xs text-input-error'>El monto no puede ser mayor al pagado</span>
        </>
            }
    </div>
}



RefundInput.propTypes = {
    item: PropTypes.object,
    index: PropTypes.number,
    refundItems: PropTypes.func, 
    setRefundItems: PropTypes.array
}
RefundModal.propTypes = {
    handleUpdate: PropTypes.func,
    paymentDetails: PropTypes.object,
    orderDetails: PropTypes.object,
    showRefund: PropTypes.bool,
    setShowRefund: PropTypes.func,
    setAlert: PropTypes.func,
    setPaymentDetails: PropTypes.func,
}

export default RefundModal;