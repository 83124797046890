import React from 'react';
import TableWithCheckBoxes from '../../../tailwindUI/TableWithCheckBoxes';
import PrimaryButton from '../../../tailwindUI/PrimaryButton';
import PropTypes from 'prop-types';

function SelectServices({ services, setServices, setStep, setServicesToPay }) {
    return (
        <div className='w-full'>
            <div className='w-full'>
                <span className='w-full block text-sm font-medium text-gray-900 pl-0.5 pb-2.5'>
                    Selecciona los servicios que deseas agregar en una misma orden de cobro
                </span>
                <div>
                    <TableWithCheckBoxes total={ services.filter(service => service.selected && !service.completed).map( service => service.total ).reduce((prev, curr) => prev + curr, 0) } services={ services } setServices={ setServices } setServicesToPay={ setServicesToPay } />
                </div>
            </div>
            <div className='w-full mt-4 flex justify-end items-center'>
                <div className='w-full md:w-1/3 md:pt-0'>
                    <PrimaryButton isFullWidth={true} bg='bg-v2-blue-text-login' disabled={services.filter(service => !service.completed).every(service => !service.selected) ? true : false} onClick={ () => setStep(3) }>
                        Siguiente
                    </PrimaryButton>
                </div>
            </div>
        </div>
    )
}

SelectServices.propTypes = {
    services: PropTypes.array,
    setServices: PropTypes.func,
    setStep: PropTypes.func,
    setServicesToPay: PropTypes.func
}

export default SelectServices